/* eslint-disable sonarjs/cognitive-complexity */

import React, {
  useEffect,
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@naadi/framework';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {
  setOrderSrcId,
  setOrderSrcType,
} from '../../../store/order/ordersList/actions';
import {
  setBomIDinEdit,
  resetGroupByCols,
  getSourcesForOrderSync,
} from '../../../store/order/bom/bomDataDuck';
import { CSVTableMenu } from '../create_order_views/csvDataTable';
import { DownloadIcon, KebabIconLarge } from '../../../assets/icons';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {
  closeConfirmScreen,
  displayConfirmScreen,
} from '../../../store/app/screenoverlay/actions';
import NaadiConfirmScreen from '../../../components/common/naadi-confirm';
import {
  deleteOrderSrcService,
  getGoogleDriveLink,
  getOrderSrcFormatDownloadUrlService,
  recallOrderSrcService,
  retriggerOrderSrcEvent,
  retriggerOrderSrcPendingTaskEvents,
  reUploadFromSheet,
} from '../../../services/order';
import { ResponseCodes } from '@naadi/framework';
import { getState } from '../../../store';
import EditBOMSlectionComponent from '../bom_views/editBomTable';
import { downloadOrderAttachment } from '../../../helpers/order/orderHelper';
import {
  toastError,
  toastMessage,
  toastWarning,
} from '../../../helpers/packing/packingHelper';
import { toastResponseError } from '../../../helpers/common/helper';
import useMobile from '../../../hooks/useMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faUpload } from '@fortawesome/free-solid-svg-icons';
import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import { cloneDeep, indexOf, isEqual } from 'lodash';
import { ScreenLoaderLogo } from '../../../components/common/screen-loader';
import moment from 'moment/moment';
export const BillOfOrderMenu = ({
  order_id,
  release_count,
  is_disabled,
  showBomMenu,
  showCsvEditModal,
  setShowCsvEditModal,
  closeCsvEditModal,
  selectedBoms,
  setSelectedBoms,
  selectedBomOrd,
  setSelectedBomOrd,
  setSelectedBomsCb,
  orderSelectedTrigger,
  bomLoader,
  setBomLoader,
}) => {
  const dispatch = useDispatch();

  const [isMobile] = useMobile();
  const lastOrderSelectedRef = useRef(false);

  const showEditBomFileModal = useCallback(
    source_info => {
      dispatch(setBomIDinEdit(source_info.uuid));
      dispatch(resetGroupByCols(source_info.packing_group_fields || []));
      dispatch(setOrderSrcId(source_info.uuid));
      dispatch(setOrderSrcType(source_info.order_src_type));
      //dispatch(showCSVEditTableModal(dispatch));
      setShowCsvEditModal('EDIT');
    },
    [dispatch, setShowCsvEditModal],
  );

  const sources = useSelector(state => state.bom_data.sources);
  const download_sources = useSelector(
    state => state.ordersList.order_download_formats,
  );
  const [sources_for_order, setSourcesForOrder] = useState([]);

  const refreshOrderSrcRef = useRef({});
  const refreshOrderSrcList = useCallback(
    async showLoader => {
      if (order_id) {
        try {
          await getSourcesForOrderSync(null, order_id, false, dispatch);
        } finally {
        }
      }
    },
    [dispatch, order_id],
  );

  useEffect(() => {
    const sources_for_order = sources[`${order_id}`] || [];
    setSourcesForOrder(sources_for_order);
  }, [sources, setSourcesForOrder, order_id]);
  useEffect(() => {
    if (order_id) {
      if (
        refreshOrderSrcRef.current.order_id === order_id &&
        refreshOrderSrcRef.current.release_count === release_count
      ) {
        return;
      }
      refreshOrderSrcRef.current.order_id = order_id;
      refreshOrderSrcRef.current.release_count = release_count;

      refreshOrderSrcList(false);
    }
  }, [refreshOrderSrcList, order_id, release_count]);
  useEffect(() => {
    if (
      lastOrderSelectedRef.current === orderSelectedTrigger ||
      orderSelectedTrigger === undefined
    ) {
      return;
    }
    if (
      lastOrderSelectedRef.current !== orderSelectedTrigger &&
      sources_for_order
    ) {
      if (orderSelectedTrigger === true) {
        const selBom = cloneDeep(selectedBoms);
        const selBomOrd = cloneDeep(selectedBomOrd);
        sources_for_order.forEach(val => {
          if (selBom.indexOf(val.uuid) === -1) {
            selBom.push(val.uuid);
            selBomOrd.push(val);
          }
        });
        if (!isEqual(selBom, selectedBoms)) {
          setSelectedBomOrd(selBomOrd);
          return setSelectedBoms(selBom);
        }
      } else {
        const selBom = cloneDeep(selectedBoms);
        const selBomOrd = cloneDeep(selectedBomOrd);
        sources_for_order.forEach(val => {
          if (selBom.indexOf(val.uuid) !== -1) {
            selBom.splice(selBom.indexOf(val.uuid), 1);
            selBomOrd.splice(selBomOrd.indexOf(val), 1);
          }
        });
        if (!isEqual(selBom, selectedBoms)) {
          setSelectedBomOrd(selBomOrd);
          return setSelectedBoms(selBom);
        }
      }
    }
    lastOrderSelectedRef.current = orderSelectedTrigger;
  }, [
    orderSelectedTrigger,
    sources_for_order,
    selectedBoms,
    setSelectedBoms,
    selectedBomOrd,
    setSelectedBomOrd,
  ]);

  const renderTooltip = useMemo(props => {
    const _props = props ? props : {};
    return (
      <Tooltip id='button-tooltip' {..._props}>
        There are some errors in this BOM
      </Tooltip>
    );
  }, []);

  const renderTooltipPending = useMemo(props => {
    const _props = props ? props : {};
    return (
      <Tooltip id='button-tooltip' {..._props}>
        The BOM is being Validated
      </Tooltip>
    );
  }, []);

  const renderTooltipReleased = useMemo(
    props => (
      <Tooltip id='button-tooltip' {...props}>
        BOM has been Released
      </Tooltip>
    ),
    [],
  );

  const renderTooltipReadyToReleased = useMemo(
    props => (
      <Tooltip id='button-tooltip' {...props}>
        BOM is Ready to be Released
      </Tooltip>
    ),
    [],
  );
  const downloadOrderSrcFormatAttachment = useCallback(
    async (order_src, format_id) => {
      const orderSrcFormatResp = await getOrderSrcFormatDownloadUrlService(
        order_src.uuid,
        format_id,
      );
      if (orderSrcFormatResp.status !== ResponseCodes.SUCCESS) {
        const err =
          orderSrcFormatResp.err && orderSrcFormatResp.err.err
            ? orderSrcFormatResp.err.err
            : 'Unable to create the attachment to download';
        toastError(err);
        return;
      }
      await downloadOrderAttachment(orderSrcFormatResp.payload);
    },
    [],
  );
  const onDownloadClick = useCallback(
    async (order_src, download_source) => {
      console.log(download_source, 'download this file');
      await downloadOrderSrcFormatAttachment(order_src, download_source.uuid);
    },
    [downloadOrderSrcFormatAttachment],
  );
  const downloadOrderSrcAttachment = useCallback(async order_src => {
    await downloadOrderAttachment(order_src.attachment_id);
  }, []);

  const retriggerOrderSrcEventHandler = useCallback(
    async order_src => {
      const { busy } = getState();
      try {
        if (busy.includes('RETRIGGER_ORDER_SRC')) {
          return;
        }
        dispatch(actions.busy.add('RETRIGGER_ORDER_SRC'));
        const job = order_src.order_src_status.job;
        if (job.job_status === 'SAVED') {
          const retriggerResult = await retriggerOrderSrcEvent(order_src.uuid);
          if (retriggerResult.status !== ResponseCodes.SUCCESS) {
            toastResponseError(retriggerResult, 'Failed to Trigger');
            return;
          }
          toastMessage({
            type: 'success',
            message: 'Retiggered Upload Process',
          });
        }
        const pendingTasks = order_src.order_src_status.pending_tasks;
        if (pendingTasks.length > 0) {
          const retriggerTaskResult = await retriggerOrderSrcPendingTaskEvents(
            order_src.uuid,
          );
          if (retriggerTaskResult.status !== ResponseCodes.SUCCESS) {
            toastResponseError(
              retriggerTaskResult,
              'Failed to Retrigger Pending Tasks',
            );
            return;
          }
          toastMessage({
            type: 'success',
            message: retriggerTaskResult.payload + ' Pending Tasks Retiggered',
          });
        }
      } finally {
        dispatch(actions.busy.remove('RETRIGGER_ORDER_SRC'));
      }
    },
    [dispatch],
  );
  const deleteOrderSrcHandler = useCallback(
    order_src => {
      const _confirmDeleteOrderSrc = async () => {
        const { busy } = getState();
        try {
          if (busy.includes('DELETE_ORDER_SRC')) {
            return;
          }
          dispatch(actions.busy.add('DELETE_ORDER_SRC'));
          const result = await deleteOrderSrcService(order_src.uuid);
          if (result.status !== ResponseCodes.SUCCESS) {
            const err =
              result.err && result.err.err
                ? result.err.err
                : 'Unable to Delete the Order Src';
            dispatch(closeConfirmScreen());
            dispatch(
              displayConfirmScreen({
                content: confirmScreen('error', err),
                boundingClass: null,
                boundingStyle: null,
              }),
            );
            return;
          }
          dispatch(closeConfirmScreen());
          refreshOrderSrcList(true);
          toastMessage({
            type: 'success',
            message: 'BOM deleted',
          });
        } finally {
          dispatch(actions.busy.remove('DELETE_ORDER_SRC'));
        }
        //console.log(result);
      };

      const cancelDeleteOrderSrc = () => {
        dispatch(closeConfirmScreen());
      };
      const confirmScreen = (state, error) => {
        const secondaryButton = (
          <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
        );
        return (
          <NaadiConfirmScreen
            message={<div>Do you really want to delete this BOM?</div>}
            state={state}
            primaryButton={<div>DELETE</div>}
            primaryAction={_confirmDeleteOrderSrc}
            secondaryButton={secondaryButton}
            secondaryAction={cancelDeleteOrderSrc}
            error={error}
          />
        );
      };
      dispatch(
        displayConfirmScreen({
          content: confirmScreen('initial', null),
          boundingClass: null,
          boundingStyle: null,
        }),
      );
    },
    [dispatch, refreshOrderSrcList],
  );

  const recallOrderSrcHandler = useCallback(
    order_src => {
      const _confirmRecallOrderSrc = async () => {
        let tm = {
          type: 'error',
          message: 'Unable to Recall',
        };
        try {
          dispatch(closeConfirmScreen());
          setBomLoader(order_src.uuid, true, {});
          const result = await recallOrderSrcService(order_src.uuid);
          if (result.status !== ResponseCodes.SUCCESS) {
            const err =
              result.err && result.err.err
                ? result.err.err
                : 'Unable to Recall the Order Src';
            tm = {
              type: 'error',
              message: err,
            };
            return;
          }

          refreshOrderSrcList();
          tm = {
            type: 'success',
            message: 'BOM Recalled',
          };
        } finally {
          setBomLoader(order_src.uuid, false, tm);
        }
        //console.log(result);
      };

      const cancelRecallOrderSrc = () => {
        dispatch(closeConfirmScreen());
      };
      const confirmScreen = (state, error) => {
        const secondaryButton = (
          <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
        );
        return (
          <NaadiConfirmScreen
            message={<div>Do you really want to recall this BOM?</div>}
            state={state}
            primaryButton={<div>RECALL</div>}
            primaryAction={_confirmRecallOrderSrc}
            secondaryButton={secondaryButton}
            secondaryAction={cancelRecallOrderSrc}
            error={error}
          />
        );
      };
      dispatch(
        displayConfirmScreen({
          content: confirmScreen('initial', null),
          boundingClass: null,
          boundingStyle: null,
        }),
      );
    },
    [dispatch, refreshOrderSrcList, setBomLoader],
  );
  const openGoogleSheetForUpdate = useCallback(
    async orderSrcId => {
      const { busy } = getState();
      if (busy.includes('UPDATE_FROM_GOOGLE_LINK')) {
        return;
      }
      try {
        dispatch(actions.busy.add('UPDATE_FROM_GOOGLE_LINK'));
        const reUploadResp = await reUploadFromSheet(orderSrcId);
        if (reUploadResp.status !== ResponseCodes.SUCCESS) {
          toastError('Unable to Update the Order From Sheet');
          return;
        }
        const rowsUpdated = reUploadResp.payload;
        if (rowsUpdated > 0) {
          toastMessage('BOM Updated from Sheet');
        } else {
          toastWarning('There was nothing to Update');
        }
      } finally {
        dispatch(actions.busy.remove('UPDATE_FROM_GOOGLE_LINK'));
      }
    },
    [dispatch],
  );
  const openGoogleSheetForEdit = useCallback(
    async orderSrcId => {
      const { busy } = getState();
      if (busy.includes('GENERATE_GOOGLE_LINK')) {
        return;
      }
      try {
        dispatch(actions.busy.add('GENERATE_GOOGLE_LINK'));
        const googleLinkResp = await getGoogleDriveLink(orderSrcId);
        if (googleLinkResp.status !== 200) {
          toastResponseError(googleLinkResp, 'Unable to Fetch the Edit Link');
          return;
        }
        /* eslint-disable security/detect-non-literal-fs-filename */
        window.open(googleLinkResp.payload, '_blank')?.focus();
        /* eslint-enable security/detect-non-literal-fs-filename */
      } finally {
        dispatch(actions.busy.remove('GENERATE_GOOGLE_LINK'));
      }
      //console.log(orderSrcId);
    },
    [dispatch],
  );

  return (
    <div className='order-left-list-item ps-0'>
      <Accordion defaultActiveKey='0'>
        <Accordion.Collapse eventKey='0'>
          <Fragment>
            {sources_for_order.map(source_info => {
              const order_src_status = source_info.order_src_status;
              const has_job_errors = order_src_status.job_errors
                ? order_src_status.job_errors.length
                : 0;
              const has_task_errors = order_src_status.error_tasks
                ? order_src_status.error_tasks.length
                : 0;
              const has_validation_errors =
                order_src_status.validation_fail || 0;
              const has_job_pending = order_src_status.pending_tasks
                ? order_src_status.pending_tasks.length
                : 0;
              const job_status_pending =
                order_src_status.job &&
                order_src_status.job.job_status === 'SAVED';
              const release_job_pending =
                order_src_status.release_job &&
                order_src_status.release_job.job_status === 'SAVED';
              const job_status_error =
                order_src_status.job &&
                ['FAIL', 'ERROR'].indexOf(order_src_status.job.job_status) >= 0;
              const is_src_released =
                source_info.released === true &&
                has_job_errors === 0 &&
                has_task_errors === 0 &&
                has_job_pending === 0 &&
                !job_status_error &&
                !release_job_pending;
              const is_ready_for_release =
                !is_src_released &&
                has_job_errors === 0 &&
                has_task_errors === 0 &&
                has_job_pending === 0 &&
                !job_status_error &&
                !job_status_pending;
              const releaseInProgressClass =
                is_ready_for_release || release_job_pending
                  ? 'release-in-progress'
                  : 'release-in-progress-na';
              const isBusy =
                bomLoader[`${source_info.uuid}`] &&
                bomLoader[`${source_info.uuid}`].busy;
              const actionMessage =
                bomLoader[`${source_info.uuid}`] &&
                bomLoader[`${source_info.uuid}`].msg
                  ? bomLoader[`${source_info.uuid}`].msg
                  : {};
              return (
                <div
                  className={`position-relative order-list-item${
                    isMobile ? ' ms-3' : ''
                  }`}
                  key={source_info.uuid}
                >
                  {isBusy && (
                    <div
                      className='d-flex justify-content-center align-items-center'
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: '1000',
                        background: 'rgba(0,0,0,0.4)',
                      }}
                    >
                      <ScreenLoaderLogo animate={true} />
                    </div>
                  )}
                  <div className='d-flex justify-content-between'>
                    <div className='fs-14 d-flex align-items-center'>
                      <div
                        style={
                          isMobile ? { maxWidth: 'calc(100vw - 100px)' } : {}
                        }
                        className='text-wrap text-break bold d-flex'
                      >
                        <input
                          type='checkbox'
                          id={`bomchk${source_info.name}`}
                          name={`bomchk${source_info.name}`}
                          checked={selectedBoms.find(
                            O => O === source_info.uuid,
                          )}
                          onChange={e => {
                            let checked = e.target.checked;
                            const selBom = cloneDeep(selectedBoms);
                            checked
                              ? selBom.push(source_info.uuid)
                              : selBom.splice(
                                  indexOf(
                                    selBom.find(
                                      O => O.value === source_info.uuid,
                                    ),
                                  ),
                                  1,
                                );
                            setSelectedBomsCb(selBom, checked);
                          }}
                        />
                        <div style={{ width: '10px' }}></div>
                        {source_info.name}
                      </div>
                      <div className='mx-2 d-inline-flex'>
                        {has_job_errors > 0 ||
                        has_task_errors > 0 ||
                        job_status_error ||
                        has_validation_errors > 0 ? (
                          <OverlayTrigger
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <div className='bom-item-error'></div>
                          </OverlayTrigger>
                        ) : null}
                        {has_job_pending > 0 || job_status_pending ? (
                          <OverlayTrigger
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipPending}
                          >
                            <div className='bom-item-pending'></div>
                          </OverlayTrigger>
                        ) : null}
                        {is_src_released ? (
                          <OverlayTrigger
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipReleased}
                          >
                            <div className='bom-item-success'></div>
                          </OverlayTrigger>
                        ) : null}
                        {is_ready_for_release ? (
                          <OverlayTrigger
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipReadyToReleased}
                          >
                            <div
                              className={`bom-item-ready-to-release ${releaseInProgressClass}`}
                            ></div>
                          </OverlayTrigger>
                        ) : null}
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      {!has_job_errors && (
                        <div
                          className='clickable'
                          onClick={refreshOrderSrcList}
                        >
                          <FontAwesomeIcon icon={faSync} />
                        </div>
                      )}
                      <div
                        className='ms-3 clickable'
                        onClick={() => openGoogleSheetForEdit(source_info.uuid)}
                      >
                        <FontAwesomeIcon icon={faGoogleDrive} />
                      </div>
                      <div
                        className='ms-3 clickable'
                        onClick={() =>
                          openGoogleSheetForUpdate(source_info.uuid)
                        }
                      >
                        <FontAwesomeIcon icon={faUpload} />
                      </div>
                      <div className='mx-3 clickable'>
                        <Dropdown className='page-size-selector'>
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id='dropdown-custom-components'
                          >
                            <DownloadIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              eventKey='1'
                              onClick={() =>
                                downloadOrderSrcAttachment(source_info)
                              }
                            >
                              Attachment
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey='2'
                              onClick={() =>
                                downloadOrderSrcFormatAttachment(
                                  source_info,
                                  null,
                                )
                              }
                            >
                              Default Format
                            </Dropdown.Item>
                            {is_src_released &&
                              download_sources.map(download_source => (
                                <Dropdown.Item
                                  onClick={() =>
                                    onDownloadClick(
                                      source_info,
                                      download_source,
                                    )
                                  }
                                  eventKey={download_source.uuid}
                                  key={download_source.uuid}
                                >
                                  {download_source.name}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className='clickable'>
                        <Dropdown className='page-size-selector'>
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id='dropdown-custom-components'
                          >
                            <div className='bom-more-action-selector'>
                              <KebabIconLarge />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {(has_job_pending > 0 || job_status_pending) && (
                              <Dropdown.Item
                                eventKey='reprocess'
                                onClick={() =>
                                  retriggerOrderSrcEventHandler(source_info)
                                }
                              >
                                ReTrigger Upload
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              eventKey='edit'
                              onClick={() => showEditBomFileModal(source_info)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey='recall'
                              onClick={() => recallOrderSrcHandler(source_info)}
                            >
                              Recall
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey='delete'
                              onClick={() => deleteOrderSrcHandler(source_info)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '10px' }}></div>
                  <div className='d-flex justify-content-start ps-4'>
                    <div style={{ fontSize: '12px' }}>
                      <div className='d-flex'>
                        <div style={{ width: '150px' }}>Added On</div>
                        <div>
                          {moment(source_info.created_on).format(
                            'DD-MM-YYYY HH:mm',
                          )}
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div style={{ width: '150px' }}>Items Count</div>
                        <div>{order_src_status.total_item_count}</div>
                      </div>
                      <div className='d-flex'>
                        <div style={{ width: '150px' }}>Total Item Qty</div>
                        <div>{order_src_status.total_item_qty}</div>
                      </div>
                      <div
                        className={`d-flex ${
                          order_src_status.released_item_qty >=
                          order_src_status.total_item_qty
                            ? 'success'
                            : 'error'
                        }`}
                      >
                        <div style={{ width: '150px' }}>Released Item Qty</div>
                        <div>{order_src_status.released_item_qty}</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '10px' }}></div>
                  {actionMessage && (
                    <div
                      className={`py-1 ${
                        actionMessage.type === 'success' ? 'success' : 'error'
                      }`}
                    >
                      {actionMessage.message} - {actionMessage.type}
                    </div>
                  )}
                </div>
              );
            })}
          </Fragment>
        </Accordion.Collapse>
      </Accordion>
      {showCsvEditModal === 'ADD' && (
        <CSVTableMenu
          order_id={order_id}
          showModal={true}
          closeModal={() => {
            closeCsvEditModal(null);
          }}
        />
      )}
      {showCsvEditModal === 'EDIT' && (
        <EditBOMSlectionComponent
          order_id={order_id}
          show_modal={true}
          closeModal={() => {
            closeCsvEditModal(null);
          }}
        />
      )}
    </div>
  );
};

function ContextAwareToggle({ children, eventKey, callback }) {
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );
  return <div onClick={decoratedOnClick}>{children}</div>;
}

ContextAwareToggle.propTypes = {
  children: PropTypes.any,
  eventKey: PropTypes.string,
  callback: PropTypes.func,
};

BillOfOrderMenu.propTypes = {
  is_disabled: PropTypes.bool,
  order_id: PropTypes.string,
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className='clickable'
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

CustomToggle.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
};
export default BillOfOrderMenu;
