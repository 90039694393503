/* eslint-disable sonarjs/cognitive-complexity */
import { cloneDeep } from 'lodash';
import {
  archiveCurrentOrder,
  resetOrderArchive,
} from '../../store/packing/orderarchive/actions';
import { addPrinters } from '../../store/printers/connectedprinters/reducer';
import { actions } from '@naadi/framework';
import { archiveOrgReportFilters } from '../../store/report/reportfilters';
import { resetReportResult } from '../../store/report/reportresult';
import { toastMessage } from '../packing/packingHelper';
import { getState } from '../../store';
import { resetTrackTrace } from '../../store/tracktrace/tracktrace';
import { isEqual } from 'lodash';
import {
  resetStationState,
  setOrderItemSyncCtxPersist,
} from '../../store/tracktrace/stationstate';
import { resetItemsList } from '../../store/packing/itemslist/actions';
import { resetBoxAndItems } from '../../store/packing/boxAndItems/actions';
import { resetSelection } from '../../store/packing/selection/actions';
import { updateCgMultiAccess } from '../../store/cutguide/cgstationstate';
export const logout = () => (dispatch, getState) => {
  dispatch(archiveCurrentOrder());
  dispatch(addPrinters([]));

  dispatch(resetReportResult());
  dispatch(setOrderItemSyncCtxPersist(null));
  dispatch(resetTrackTrace());
  dispatch(resetStationState());
  dispatch(resetOrderArchive());
  dispatch(resetItemsList());
  dispatch(resetBoxAndItems());
  dispatch(resetSelection());
  dispatch(updateCgMultiAccess({}));
  dispatch(actions.token.set(null));
  const user = getState().user;
  if (user && user.org && user.org.uuid) {
    dispatch(archiveOrgReportFilters(user.org.uuid));
  }
  dispatch(actions.user.reset());
};
export const selectCurrentOrg = org => (dispatch, getState) => {
  const user = getState().user;
  if (!org || !org.uuid) return;
  const _user = cloneDeep(user);
  _user.org = org;
  if (!_user.branch || _user.branch.org_id !== org.uuid) {
    _user.branch = null;
    _user.branches = [];
    if (_user.access && _user.access[`${org.uuid}`]) {
      const branches = _user.access[`${org.uuid}`] || [];
      const _branches = branches
        .filter(val => val.org_id === org.uuid)
        .map(val => ({
          branch_id: val.branch_id,
          branch_name: val.branch_name,
          org_id: val.org_id,
        }));
      _user.branches = _branches || [];
      _user.branch = _branches[0] || {};
      dispatch(actions.user.set(_user));
    }
  }
  if (user.org && user.org.uuid === org.uuid) {
    return;
  }
  dispatch(archiveCurrentOrder());
  dispatch(addPrinters([]));
  dispatch(actions.user.set(_user));
  dispatch(archiveOrgReportFilters(org.uuid));
  dispatch(resetReportResult());
  dispatch(setOrderItemSyncCtxPersist(null));
  dispatch(resetTrackTrace());
};

export const getOrgBranch = branch_id => {
  const branches = getState().user.branches;
  if (!branches) return null;
  const branch = branches.filter(val => val.branch_id === branch_id);
  return branch.length > 0 ? branch[0] : null;
};
export const selectCurrentBranch = branch_id => (dispatch, getState) => {
  const user = getState().user;
  debugger;
  if (!user.org) return;
  if (user.branch && user.branch.branch_id === branch_id) {
    return;
  }
  const _branch = getOrgBranch(branch_id);
  if (!_branch) {
    return;
  }
  const _user = cloneDeep(user);
  _user.branch = cloneDeep(_branch);
  dispatch(actions.user.set(_user));
};

export const selectBranch = (user, branch) => (dispatch, getState) => {
  if (!branch || !branch.branch_id) {
    return;
  }
  const branchExists = user.branches.find(
    val => val.branch_id === branch.branch_id,
  );
  if (!branchExists) {
    return;
  }
  if (user.branch && user.branch.branch_id === branch.branch_id) {
    return;
  }
  const _user = cloneDeep(user);
  if (!_user || !_user.branches) {
    return;
  }
  _user.branch = cloneDeep(branchExists);
  dispatch(actions.user.set(_user));
  dispatch(archiveCurrentOrder());
  dispatch(addPrinters([]));
  dispatch(resetReportResult());
  dispatch(setOrderItemSyncCtxPersist(null));
  dispatch(resetTrackTrace());
};

export const OrgFilters = [
  { id: 1, type: 'active' },
  { id: 2, type: 'inactive' },
];

export const AppFilters = [
  { id: 1, type: 'active' },
  { id: 2, type: 'inactive' },
];

export const prefixImageUrl = size => {
  const imagePrefix = process.env.REACT_APP_IMAGE_PROXY_URL;
  return `${imagePrefix}/insecure/rs:fit:${size}:${size}:1/g:so/plain/`;
};

export const paramValidation = obj => {
  const paramAvailable = ['active', 'query'];
  let validated = false;
  for (var i = 0; i < paramAvailable.length; i++) {
    if (obj.hasOwnProperty(paramAvailable[`${i}`])) validated = true;
  }
  return validated;
};

export const imageModalContents = ['view', 'upload new', 'remove'];

export const listStyle = (isMobile, list) => {
  return {
    paddingLeft: isMobile ? '20px' : '35px',
    marginTop: '20px',
    paddingBottom: isMobile ? '200px' : '300px',
    paddingTop: isMobile && '20px',
    paddingRight: isMobile && '20px',
    width: !isMobile ? '80%' : list.length === 1 && '100%',
    top: !isMobile ? '200px' : '150px',
  };
};

export const getEntityName = (el, name) => {
  switch (name) {
    case 'Organisations':
      return el.name;
    case 'Profiles':
      return el.profile_name;
    case 'Groups':
      return el.group_name;
    case 'Group':
      return el.groups_name;
    default:
      return null;
  }
};

export const getUserCount = name => {
  let showUserCount = false;
  if (name === 'Profiles' || name === 'Groups') showUserCount = true;
  return showUserCount;
};

export const handleWindowSizeChange = setWidth => {
  setWidth(window.innerWidth);
};

export const widthInit = (width, setIsMobile, setIsTab) => {
  //is mobile?
  if (width <= 900) setIsMobile(true);
  if (width > 900) setIsMobile(false);
  //or is tablet?
  if (width > 900 && width < 1310) setIsTab(true);
  if (width > 1310 || width <= 900) setIsTab(false);
};

export const createPageColStyle = (isMobile, profile = '', activePage) => {
  return {
    paddingTop: isMobile ? '10px' : '30px',
    minHeight: isHeightFixed(profile, activePage) && 'calc(100vh - 80px)',
    paddingRight: isMobile && '20px',
    paddingLeft: isMobile && '20px',
  };
};

const isHeightFixed = (profile, activePage) => {
  let show = true;
  // eslint-disable-next-line sonarjs/no-collapsible-if
  if (profile) {
    if (activePage.includes('Profiles')) {
      show = false;
    }
  }
  return show;
};

export const activePageSection = {
  p1: 'Profiles',
  p2: 'View Users',
  p3: 'Report settings',
};

export const dummyProfileArr = [
  {
    id: 1,
    checked: true,
    name: 'Orders',
  },
  {
    id: 2,
    checked: false,
    name: 'Release',
  },
  {
    id: 3,
    checked: true,
    name: 'Views',
  },
  {
    id: 4,
    checked: false,
    name: 'Views',
  },
];

export const checkMobileVisibility = (activePage, isMobile) => {
  let mobile = false;
  if (
    (activePage.includes('Users') || activePage.includes('Report')) &&
    isMobile
  )
    mobile = true;
  return mobile;
};

export const getCheckedOutput = data => {
  return data.map(res => ({
    ...res,
    checked: false,
  }));
};

// eslint-disable-next-line sonarjs/no-identical-functions
export const getCheckedOutput1 = data => {
  return data.map(res => ({
    ...res,
    checked: false,
  }));
};

export const handleCancel = (e, history) => {
  e.preventDefault();
  history.goBack();
};

export const keyDown = (e, data, setData, updateHandler, type) => {
  const { value } = e.target;
  const ENTERKEY = 13;
  if (e.keyCode === ENTERKEY && e.shiftKey) {
    e.preventDefault();
    setData(data + ' ');
  } else if (e.keyCode === ENTERKEY && !e.shiftKey) {
    e.preventDefault();
    updateHandler(value, type);
  }
};

export const isChecked = data => {
  let checked = false;
  let count = 0;

  // eslint-disable-next-line sonarjs/no-unused-collection
  let temp = [];
  data.forEach(el => {
    if (el.checked || el.active) {
      temp.push(el);
      count = count + 1;
    }
  });
  if (temp.length > 0) checked = true;
  return {
    checked,
    count,
  };
};

export const rowBorderStyle = '1px solid #DEDEDE';

export const antdCollapseStyle = isMobile => {
  return {
    width: '100%',
    marginBottom: '15px',
    borderTop: '1px solid #C0C0C0',
    // borderBottom: '1px solid #C0C0C0',
    borderLeft: 'none',
    borderRight: 'none',
    background: isMobile ? '#fff' : 'transparent',
  };
};

export const goToListPage = (name, history, clone = false) => {
  toastMessage({
    type: 'success',
    message: `${name} ${!clone ? 'Created' : 'Cloned'}`,
  });
  setTimeout(() => {
    history.push(
      `/app/${name.includes('Org') ? 'org' : name.toLowerCase()}/list`,
    );
  }, 2000);
};

export const hasRequiredData = data => {
  let checked = false;
  // eslint-disable-next-line sonarjs/no-unused-collection
  let temp = [];
  data.forEach(el => {
    if (el.first_name && el.last_name) {
      temp.push(el);
    }
  });
  if (temp.length > 0) checked = true;
  return checked;
};

export const getCheckedCount = data => {
  // console.log('data', data)
  let count = 0;
  let idx = 0;
  data.forEach((el, i) => {
    if (el.checked || el.active) {
      count = count + 1;
      idx = i;
    }
  });
  // console.log('count', count)
  return {
    count,
    idx,
  };
};

export const handleMenuClick = (e, setData) => {
  if (e.key < 10) setData(false);
};

export const fetchCorrectProfileModules = (
  orgModules,
  profileModules,
  setOrgModules,
) => {
  // console.log(profileModules);
  let temp = [...orgModules];
  for (var i = 0; i < temp.length; i++) {
    for (var j = 0; j < profileModules.length; j++) {
      if (
        temp[`${i}`].uuid === profileModules[`${j}`].org_module.uuid &&
        profileModules[`${j}`].active
      ) {
        temp[`${i}`].checked = true;
        temp[`${i}`].user_profile_module_id =
          profileModules[`${j}`].user_profile_module_id;
      }
    }
  }
  // console.log('temp ========>', temp);
  if (setOrgModules) setOrgModules([...temp]);
  return temp;
};

export const fetchCorrectModuleActions = (
  moduleActions,
  currentActionStatusList,
  setModuleActions,
) => {
  // console.log('Here');
  // console.log(moduleActions);
  // console.log('currentActionStatusList =======>', currentActionStatusList);
  let temp = [...moduleActions];
  for (var i = 0; i < temp.length; i++) {
    for (var j = 0; j < currentActionStatusList.length; j++) {
      if (
        temp[`${i}`].uuid === currentActionStatusList[`${j}`].action.uuid &&
        currentActionStatusList[`${j}`].active
      ) {
        temp[`${i}`].checked = true;
      }
    }
  }
  // console.log('temp ========>', temp);
  if (setModuleActions) setModuleActions([...temp]);
  return temp;
};

export const fetchCorrectProfileReports = (
  reportNames,
  profileReportNames,
  setReportNames,
) => {
  // console.log('Here');
  // console.log('verify ====>', reportNames, profileReportNames);
  let temp = [...reportNames];
  for (var i = 0; i < reportNames.length; i++) {
    for (var j = 0; j < profileReportNames.length; j++) {
      if (
        temp[`${i}`].uuid === profileReportNames[`${j}`].report_id &&
        profileReportNames[`${j}`].active
      )
        temp[`${i}`].checked = true;
    }
  }
  if (!isEqual(temp, reportNames) && setReportNames) {
    setReportNames([...temp]);
  }
  return temp;
};

export const fetchCorrectProfileBranches = (
  branches,
  profileBranches,
  setBranches,
  setSelectedBranch,
) => {
  // console.log('Here');
  // console.log(branches, profileBranches);
  let temp = [...branches];
  for (var i = 0; i < temp.length; i++) {
    temp[`${i}`].checked = false;
    for (var j = 0; j < profileBranches.length; j++) {
      if (temp[`${i}`].uuid === profileBranches[`${j}`].branch_id) {
        temp[`${i}`].checked = true;
        temp[`${i}`].user_profile_branch_id = profileBranches[`${j}`].uuid;
      }
    }
  }
  if (!isEqual(temp, branches) && setBranches) {
    setBranches([...temp]);
    // setSelectedBranch(temp[0].uuid);
  }
  return temp;
};

export const fetchBranchUsers = async (
  profileBranches,
  getUsersList,
  toastError,
  apiFetchErrorMsg,
  setUsers,
  dispatch,
  actions,
) => {
  let temp = [...profileBranches];
  try {
    dispatch(actions.busy.add('FETCH_PROFILE'));
    const profileBranchIds = temp.map(val => val.org_user_id);
    const userIdMap = {};
    if (profileBranchIds.length > 0) {
      const usersRes = await getUsersList({
        req_payload: { uuid: profileBranchIds },
      });
      if (usersRes.status !== 200) {
        return toastError(apiFetchErrorMsg);
      }
      usersRes.payload.map(val => {
        userIdMap[`${val.uuid}`] = val;
        return val;
      });
      for (let i = 0; i < temp.length; i++) {
        const profileBranch = temp[`${i}`];
        if (userIdMap[`${profileBranch.org_user_id}`]) {
          const userIdVal = userIdMap[`${profileBranch.org_user_id}`];
          profileBranch.user_active = userIdVal.active;
          profileBranch.first_name = userIdVal.first_name;
          profileBranch.middle_name = userIdVal.middle_name;
          profileBranch.last_name = userIdVal.last_name;
        } else {
          //toastError('Unable to fetch the User');
        }
      }
    }
    if (setUsers) setUsers([...temp]);
    // console.log('temp ====>', temp);
    return temp;
  } finally {
    dispatch(actions.busy.remove('FETCH_PROFILE'));
  }
};

export const fetchCorrectUsers = (branches, users, setBranches) => {
  let branchesTemp = [...branches];
  for (let i = 0; i < branchesTemp.length; i++) {
    let temp = [];
    for (var j = 0; j < users.length; j++) {
      if (branchesTemp[`${i}`].uuid === users[`${j}`].branch_id) {
        temp.push(users[`${j}`]);
      }
    }
    branchesTemp[`${i}`].users = temp;
  }
  if (!isEqual(branches, branchesTemp) && setBranches) {
    setBranches([...branchesTemp]);
  }
  return branchesTemp;
};

export const handleProfileCreate = async (
  name,
  description,
  dispatch,
  actions,
  updateProfile,
  orgModules,
  updateProfileModules,
  moduleActions,
  updateProfileActions,
  reportNames,
  updateProfileReports,
  branches,
  updateBranchProfileUsers,
  history,
  toastError,
  apiFetchErrorMsg,
  isClone,
) => {
  // console.log('here');
  let body = {
    active: true,
    approved: true,
  };
  let req_payload = {
    profile_name: name,
    profile_desc: description,
  };
  body.req_payload = req_payload;
  try {
    dispatch(actions.busy.add('CREATE_PROFILE'));
    const updateProfileRes = await updateProfile(body);
    if (updateProfileRes.status !== 200) return toastError(apiFetchErrorMsg);
    delete body.req_payload;
    if (orgModules && orgModules.length > 0 && isChecked(orgModules).checked) {
      orgModules.forEach(async module => {
        if (module.checked) {
          let req_payload = {
            user_profile_id: updateProfileRes.payload.uuid,
            org_module_id: module.uuid,
          };
          body.req_payload = req_payload;
          const profileModulesRes = await updateProfileModules(body);
          if (profileModulesRes.status !== 200)
            return toastError(apiFetchErrorMsg);
          delete body.req_payload;
          if (
            moduleActions &&
            moduleActions.length > 0 &&
            isChecked(moduleActions).checked
          ) {
            moduleActions
              .filter(el => {
                if (el.module.uuid === module.module.uuid) {
                  return el;
                }
                return false;
              })
              .forEach(async action => {
                if (action.checked) {
                  let req_payload = {
                    user_profile_module_id: profileModulesRes.payload.uuid,
                    action_id: action.uuid,
                  };
                  body.req_payload = req_payload;
                  const profileActionsRes = await updateProfileActions(body);
                  if (profileActionsRes.status !== 200)
                    return toastError(apiFetchErrorMsg);
                  delete body.req_payload;
                }
              });
          }
        }
      });
    }
    delete body.req_payload;
    body.deleted = false;
    if (
      reportNames &&
      reportNames.length > 0 &&
      isChecked(reportNames).checked
    ) {
      reportNames.forEach(async report => {
        if (report.checked) {
          let req_payload = {
            report_id: report.uuid,
            user_profile_id: updateProfileRes.payload.uuid,
          };
          body.req_payload = req_payload;
          const profileReports = await updateProfileReports(body);
          if (profileReports.status !== 200)
            return toastError(apiFetchErrorMsg);
          delete body.req_payload;
        }
      });
    }
    delete body.req_payload;
    if (branches && branches.length > 0 && isChecked(branches).checked) {
      branches.forEach(branch => {
        if (branch.checked && branch.users.length > 0) {
          branch.users.forEach(async user => {
            let req_payload = {
              user_profile_id: updateProfileRes.payload.uuid,
              branch_id: branch.uuid,
              org_user_id: isClone ? user.org_user_id : user.uuid,
            };
            body.req_payload = req_payload;
            const updateBranchRes = await updateBranchProfileUsers(body);
            if (updateBranchRes.status !== 200)
              return toastError(apiFetchErrorMsg);
            delete body.req_payload;
          });
        }
      });
    }
  } finally {
    dispatch(actions.busy.remove('CREATE_PROFILE'));
    goToListPage('Profiles', history, isClone);
  }
};
export const handleGroupsCreate = async (
  name,
  description,
  dispatch,
  actions,
  updateGroup,
  history,
  toastError,
  apiFetchErrorMsg,
  isClone,
) => {
  // console.log('here');
  let body = {
    active: true,
    approved: true,
  };
  let req_payload = {
    group_name: name,
    group_desc: description,
  };
  body.req_payload = req_payload;
  try {
    dispatch(actions.busy.add('CREATE_GROUP'));
    const updateProfileRes = await updateGroup(body);
    if (updateProfileRes.status !== 200) return toastError(apiFetchErrorMsg);
  } finally {
    dispatch(actions.busy.remove('CREATE_GROUP'));
    goToListPage('Groups', history, isClone);
  }
};
export const removeDuplicatesFromArr = arr => {
  return arr.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      arr.findIndex(obj => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
};

export const MenuContent = {
  organisations: ['edit', 'mark as'],
  profile: ['edit', 'clone profile', 'delete'],
  users: ['edit', 'delete'],
};

export const colStyle = (idx, arr) => {
  return {
    borderBottom: idx !== arr.length - 1 ? rowBorderStyle : '',
    padding: '15px',
    textAlign: 'center',
  };
};

export const collapsableMenu = [
  {
    id: 1,
    name: 'Dashboard',
    link: '/',
  },
  {
    id: 2,
    name: 'Users',
    link: '#Users',
  },
  {
    id: 3,
    name: 'Profiles',
    link: '/profiles/list',
  },
  {
    id: 4,
    name: 'Groups',
    link: '#Groups',
  },
];

export const getActiveStatus = current => {
  let active = false;
  const obj = collapsableMenu.find(menu => menu.link === current);
  if (obj) active = true;
  return active;
};

export const getXSDeviceStatus = width => {
  let isXSDevice = false;
  if (width <= 380) isXSDevice = true;
  return isXSDevice;
};
