import {
  DEFAULT_ITEM_FEATURES,
  getItemsWithFilter,
  updateBulkOperationStatus,
} from '../../services/order';
import { getResponseErrorMessage, toastResponseError } from '../common/helper';
import { uploadScanImages } from '../tracktrace/orderItemHelper';
import { actions } from '@naadi/framework';
import { cloneDeep } from 'lodash';
import { getWoPatternBoard } from '../../services/cutsmart';
import { getState } from '../../store';
import { toastError } from '../packing/packingHelper';

/* eslint-disable sonarjs/cognitive-complexity */
export const patternPartScan = async (
  orderItemIds,
  manualEntry,
  rejection,
  images,
  dispatch,
  current_station,
  printer_id,
) => {
  //const { org, branch } = getState().user;
  const { station_location } = getState().stationstate;
  const location =
    (station_location ? station_location[current_station.uuid] : '') || '';
  const rejectmode = false;
  if (!current_station || !current_station.uuid) {
    return {
      error: 'Select the Station Before Scanning',
      success: false,
    };
  }
  try {
    dispatch(actions.busy.add('CUT_GUIDE_SCAN'));
    const rejected = rejection
      ? rejection.rejected === true
      : rejectmode === true;
    if (orderItemIds.length === 0) {
      return {
        error: 'Atleast 1 part has to be selected',
        success: false,
      };
    }
    const orgItemFilter = {
      deleted: [false],
      released: [true],
      uuid: orderItemIds,
      only_visible_features: true,
      fetch_order_item_status: true,
      features: DEFAULT_ITEM_FEATURES,
      scan_status_operation_codes: current_station.operations,
      checklist_stations: current_station.operations,
    };
    const itemFilterResp = await getItemsWithFilter({
      req_payload: orgItemFilter,
    });
    if (itemFilterResp.status !== 200) {
      return {
        error: getResponseErrorMessage(
          itemFilterResp,
          'Unable to Check the Item',
        ),
        success: false,
      };
    }
    if (itemFilterResp.payload.length === 0) {
      return {
        error: 'Item Does not Exist',
        success: false,
      };
    }
    let operationCodes = ['CUTTING'];
    const orderIds = itemFilterResp.payload
      .map(val => val.order_id)
      .filter((val, index, arr) => arr.indexOf(val) === index);
    const orderImageAttachments = {};
    for (let i = 0; i < orderIds.length; i++) {
      const imageAttachments = await uploadScanImages(images, orderIds[`${i}`]);
      if (!imageAttachments.success) {
        return {
          error: imageAttachments.err,
          success: false,
        };
      }
      const orderId = orderIds[`${i}`];
      orderImageAttachments[`${orderId}`] = imageAttachments;
    }

    const reqList = itemFilterResp.payload.map(match => {
      const images = orderImageAttachments[`${match.order_id}`]
        ? orderImageAttachments[`${match.order_id}`].images
        : [];
      return {
        scan_station: current_station.station_code,
        //scan_id: getUuid(dispatch),
        scan_code: match.uuid,
        pod: current_station.uuid,
        pod_name: current_station.instance_name,
        manual_entry: manualEntry,
        scan_context_type: 'ORDER',
        scan_context_1: match.order_id,
        scan_context_2: match.uuid,
        scan_context_3: match.code,
        station_operation_codes: current_station.operations,
        req_payload: {
          order_item_id: match.uuid,
          scan_record_id: match.uuid,
          status: rejected ? 'REJECTED' : 'SUCCESS',
          operation_codes: operationCodes,
          processed_on: new Date(),
          rejected: rejected,

          comment: rejection && rejection.comment ? rejection.comment : '',
          location: location,
          images: images,
        },
        rejection_list: rejection && rejection.reasons ? rejection.reasons : [],
      };
    });

    const operationStatusResp = await updateBulkOperationStatus({
      printer_id: printer_id,
      req_payload: reqList,
    });
    if (operationStatusResp.status !== 200) {
      return {
        error: getResponseErrorMessage(operationStatusResp, 'Unable to Update'),
        success: false,
      };
    }
    return {
      print_images: operationStatusResp.print_images || {},
      print_errors: operationStatusResp.print_errors || {},
      success: true,
      payload: operationStatusResp.payload,
    };
  } finally {
    dispatch(actions.busy.remove('CUT_GUIDE_SCAN'));
  }
};
/* eslint-enable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/cognitive-complexity */
export const updatePartScanToResult = (
  woResult,
  matId,
  patternId,
  parts,
  isRejected,
) => {
  let totalProcessedBrdQty = 0;
  let totalProcessedQty = 0;
  let totalRejectedQty = 0;
  const partUuidList = parts.map(val => val.uuid);
  const _woResult = cloneDeep(woResult);
  _woResult.wo_optimization_materials.forEach(woMat => {
    if (woMat.uuid === matId) {
      let totalMatProcessedBrdQty = 0;
      let totalMatProcessedQty = 0;
      let totalMatRejectedQty = 0;
      woMat.patterns.forEach(pattern => {
        if (pattern.uuid === patternId) {
          let totalPatternProcessedBrdQty = 0;
          let totalPatternProcessedQty = 0;
          let totalPatternRejectedQty = 0;
          pattern.pattern_boards.forEach(board => {
            let processedQty = 0;
            let rejectedQty = 0;
            board.parts.forEach(part => {
              if (partUuidList.indexOf(part.uuid) !== -1) {
                if (isRejected === true) {
                  part.processed_qty = 0;
                  part.rejected_qty = 1;
                } else {
                  part.processed_qty = 1;
                  part.rejected_qty = 0;
                }
              }
              processedQty += part.processed_qty;
              rejectedQty += part.rejected_qty;
            });
            board.processed_qty = processedQty;
            board.rejected_qty = rejectedQty;
            if (board.processed_qty >= board.total_qty) {
              totalPatternProcessedBrdQty++;
            }
            totalPatternProcessedQty += board.processed_qty;
            totalPatternRejectedQty += board.rejected_qty;
          });
          pattern.processed_board_qty = totalPatternProcessedBrdQty;
          pattern.processed_qty = totalPatternProcessedQty;
          pattern.rejected_qty = totalPatternRejectedQty;
        }
        totalMatProcessedBrdQty += pattern.processed_board_qty;
        totalMatProcessedQty += pattern.processed_qty;
        totalMatRejectedQty += pattern.rejected_qty;
      });
      woMat.processed_board_qty = totalMatProcessedBrdQty;
      woMat.processed_qty = totalMatProcessedQty;
      woMat.rejected_qty = totalMatRejectedQty;
    }
    totalProcessedBrdQty += woMat.processed_board_qty;
    totalProcessedQty += woMat.processed_qty;
    totalRejectedQty += woMat.rejected_qty;
  });
  _woResult.rejected_qty = totalRejectedQty;
  _woResult.processed_qty = totalProcessedQty;
  _woResult.processed_board_qty = totalProcessedBrdQty;
  return _woResult;
};
/* eslint-enable sonarjs/cognitive-complexity */

export const getNormalizedPattern = pattern => {
  const boards = pattern.pattern_boards || [];
  const parts = [];
  const remnants = [];
  if (boards.length > 0) {
    const firstBoard = boards[0];
    firstBoard.parts.forEach(val => {
      const part = cloneDeep(val);
      part.others = [];
      parts.push(part);
    });
    firstBoard.remnants.forEach(val => {
      const remnant = cloneDeep(val);
      remnant.others = [];
      remnants.push(remnant);
    });

    boards.forEach((board, bIndex) => {
      if (bIndex === 0) {
        return;
      }
      board.parts.forEach((val, index) => {
        const mainPart = parts[`${index}`];
        mainPart.others.push(val);
      });
      board.remnants.forEach((val, index) => {
        const mainOffcut = remnants[`${index}`];
        mainOffcut.others.push(val);
      });
    });
    pattern.parts = parts;
    pattern.remnants = remnants;
    pattern.pressed_location = firstBoard.pressed_location;
  }
  const _pattern = cloneDeep(pattern);
  _pattern.parts = parts;
  _pattern.remnants = remnants;
  return _pattern;
};

const isYCut = cut => {
  return cut.x1 === cut.x2 ? true : false;
};

/* eslint-disable sonarjs/cognitive-complexity */
export const getPartCutSequence = (
  kerf,
  sortedCuts,
  pattern,
  parts,
  remnants,
) => {
  const cutPieces = [];
  const partRemnants = [...parts, ...remnants];
  for (let i = 0; i < sortedCuts.length; i++) {
    const currentCut = { ...sortedCuts[`${i}`] };
    currentCut.parts = [];
    currentCut.x1 = Math.round(currentCut.x1 * 10.0) / 10.0;
    currentCut.x2 = Math.round(currentCut.x2 * 10.0) / 10.0;
    currentCut.y1 = Math.round(currentCut.y1 * 10.0) / 10.0;
    currentCut.y2 = Math.round(currentCut.y2 * 10.0) / 10.0;
    for (let j = 0; j < partRemnants.length; j++) {
      const part = partRemnants[`${j}`];
      const partX1 = Math.round(part.x * 10) / 10.0;
      const partX2 =
        Math.round(
          (part.x + (part.rotated ? part.width : part.length)) * 10.0,
        ) / 10.0;
      const partY1 = Math.round(part.y * 10.0) / 10.0;
      const partY2 =
        Math.round(
          (part.y + (part.rotated ? part.length : part.width)) * 10.0,
        ) / 10.0;
      const yCut = isYCut(currentCut);
      if (
        (yCut &&
          partY1 === currentCut.y1 &&
          partY2 === currentCut.y2 &&
          (partX1 === currentCut.x1 || partX2 === currentCut.x2)) ||
        (!yCut &&
          partX1 === currentCut.x1 &&
          partX2 === currentCut.x2 &&
          (partY1 === currentCut.y1 || partY2 === currentCut.y2))
      ) {
        currentCut.parts.push(part);
        //console.log('Added Part');
        partRemnants.splice(j, 1);
        //console.log(partRemnants.length);
        j--;
      }
    }
    cutPieces.push(currentCut);
  }
  cutPieces.push({ x1: 0, x2: 0, y1: 0, y2: 0, parts: partRemnants });
  return cutPieces;
};
/* eslint-enable sonarjs/cognitive-complexity */

export const onBoardScanWrapper = async (scanCode, wo, woResult, history) => {
  const currentWoId = wo && wo.uuid ? wo.uuid : null;
  if (scanCode.startsWith('#') && !currentWoId) {
    toastError('Work Order is not selected');
    return;
  } else if (scanCode.startsWith('#') && woResult) {
    const _scanCode = scanCode.toLowerCase();
    woResult.wo_optimization_materials.forEach(mat => {
      mat.patterns.forEach(pattern => {
        if (pattern.pattern_boards.length > 0) {
          const patternCode = (
            '#' +
            mat.material_index +
            '' +
            pattern.pattern_index
          ).toLowerCase();
          if (patternCode === _scanCode) {
            scanCode = pattern.pattern_boards[0].uuid;
          }
        }
      });
    });
  }

  const boardResp = await getWoPatternBoard(scanCode);
  if (boardResp.status !== 200) {
    toastResponseError(boardResp, 'Unable to Fetch the Board Detail');
    return;
  }
  const board = boardResp.payload;
  const { user } = getState();
  const { branch, org } = user;

  if (
    !branch ||
    board.deleted ||
    !board.wo_pattern ||
    !board.wo_pattern.wo_optimization_mat ||
    board.wo_pattern.wo_optimization_mat.deleted ||
    board.wo_pattern.deleted ||
    branch.branch_id !== board?.work_order?.branch_id
  ) {
    toastError('Pattern Not Found');
    return;
  }
  const orgId = org.uuid;
  const woId = board.work_order.uuid;
  if (currentWoId && currentWoId !== '' && currentWoId !== woId) {
    toastError('The Board Does not Belong to the Selected Work Order');
    return;
  }
  const patternId = board.wo_pattern.uuid;
  const matId = board.wo_pattern.wo_optimization_mat.uuid;
  const uri = `/app/cutguide/materials/${orgId}/${woId}/pattern/${matId}/${patternId}`;
  history.push(uri);
};
