import { cloneDeep } from 'lodash';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import { StrictModeDroppable } from '../../../views/report/customize-report';
import { useEffect } from 'react';
import { getOrgConfig, updateOrgConfigs } from '../../../services/org';
import { PlusIcon } from '../../../assets/icons';
import {
  toastError,
  toastSuccess,
} from '../../../helpers/packing/packingHelper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../../common/header';
import { useDispatch } from 'react-redux';
import { fetchOperations } from '../../../services/operation';
import { actions } from '@naadi/framework';
import { ToastContainer } from 'react-toastify';
import ScreenLoader from '../../common/screen-loader';

const DeleteSelectedColumnIcon = () => {
  //prettier-ignore
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="6.5" fill="white" stroke="#979797"/>
      <path d="M10 4.60429L9.39571 4L7 6.39571L4.60429 4L4 4.60429L6.39571 7L4 9.39571L4.60429 10L7 7.60429L9.39571 10L10 9.39571L7.60429 7L10 4.60429Z" fill="#027AFF"/>
    </svg>
  );
};

function CustomizeOperationSequence() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [selected_columns, setSelectedColumns] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [searchColumns, setSearchColumns] = useState('');
  const fetchOperation = useCallback(async () => {
    const req = {
      approved: true,
      active: true,
      deleted: false,
      page: 0,
      size: 100,
      sort: [{ column: 'operation_code', asc: true }],
      req_payload: {
        deleted: [false],
        active: [true],
      },
    };

    try {
      dispatch(actions.busy.add('FETCH_OPERATION'));
      const fetchOp = await fetchOperations(req);
      if (fetchOp && fetchOp.status !== 200) {
        toastError('Unable to fetch data!');
        return;
      }

      if (fetchOp && fetchOp.payload.length > 0) {
        const operationCode = fetchOp.payload.map(item => item.operation_code);
        setColumns([...operationCode, 'Shipment_Date']);
      } else {
        setColumns([]);
      }
    } finally {
      dispatch(actions.busy.remove('FETCH_OPERATION'));
    }
  }, [dispatch]);
  useEffect(() => {
    fetchOperation();
  }, [fetchOperation]);

  const fecthSatationSequence = useCallback(async () => {
    try {
      dispatch(actions.busy.add('STATION_SEQUENCE'));
      const orgConfigResp = await getOrgConfig('STATION_SEQUENCE');
      if (orgConfigResp && orgConfigResp.status !== 200) {
        toastError('Unable to fetch data!');
        return;
      }

      if (orgConfigResp.payload) {
        setSelectedColumns(orgConfigResp.payload.value.val);
      }
    } finally {
      dispatch(actions.busy.remove('STATION_SEQUENCE'));
    }
  }, [dispatch]);

  useEffect(() => {
    fecthSatationSequence();
  }, [fecthSatationSequence]);
  const onBackFnc = useCallback(async () => {
    history.push(`/app/orders/progress`);
  }, [history]);

  useEffect(() => {
    setAvailableColumns(columns.filter(col => !selected_columns.includes(col)));
  }, [columns, selected_columns]);

  const onConfigSave = useCallback(async () => {
    try {
      const res = await updateOrgConfigs('STATION_SEQUENCE', selected_columns);
      if (res.status === 200) {
        toastSuccess('Configuration saved successfully!');
      } else {
        toastError('Unable to update  the stations configuration!');
      }
    } catch (error) {
      toastError('Unable to update  the stations configuration!');
    }
  }, [selected_columns]);

  const deleteSelected = async column => {
    const selectedColumns = cloneDeep(selected_columns);
    const columnIndex = selectedColumns.indexOf(column);
    if (columnIndex >= 0) {
      selectedColumns.splice(columnIndex, 1);
    }
    setSelectedColumns(selectedColumns);
  };

  const addSelected = async column => {
    if (selected_columns.indexOf(column) >= 0) return;
    const selectedColumns = cloneDeep(selected_columns);
    selectedColumns.push(column);
    setSelectedColumns(selectedColumns);
  };
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgba(151,151,151,0.2)' : '',
    marginRight: isDraggingOver ? '32px' : '0px',
  });

  const renderSelectedColumns = () => {
    return (
      <StrictModeDroppable droppableId='selectedcolumns'>
        {(provided, snapshot) => (
          <div
            style={getListStyle(snapshot.isDraggingOver)}
            ref={provided.innerRef}
          >
            {selected_columns.map((column, index) => {
              return (
                <Draggable
                  key={`available-column-${column}-${index}`}
                  draggableId={`${column}-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div
                        className='naadi-report-customize-selected ps-2'
                        key={`selected-column-${column}`}
                      >
                        <div>{column}</div>
                        <div
                          onClick={() => deleteSelected(column)}
                          className='naadi-report-customize-delete'
                        >
                          <DeleteSelectedColumnIcon />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    );
  };
  const renderAvailableColumns = () => {
    return (
      <StrictModeDroppable droppableId='availablecolumns'>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className='h-100'
          >
            {availableColumns &&
              availableColumns
                .filter(column => {
                  const txt = column || '';
                  return txt.toLowerCase().includes(searchColumns.toLowerCase())
                    ? true
                    : false;
                })
                .map((column, index) => {
                  return (
                    <Draggable
                      key={`available-column-${column}-${index}`}
                      draggableId={`${column}-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={`available-column-${column}`}
                        >
                          <div
                            className='naadi-report-customize-selected me-0 ps-2 '
                            key={`available-column2-${column}`}
                          >
                            <div>{column}</div>
                            <div
                              onClick={() => addSelected(column)}
                              className='naadi-report-customize-add'
                            >
                              <PlusIcon fill={'#027AFF'} />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    );
  };
  const onDragEnd = async result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === 'availablecolumns' &&
      source.droppableId === 'availablecolumns'
    ) {
      return;
    }
    const selectedColumns = cloneDeep(selected_columns);
    const availableColumn = cloneDeep(availableColumns);
    const dropColumn =
      source.droppableId === 'availablecolumns'
        ? availableColumn[source.index]
        : selected_columns[source.index];
    if (!dropColumn) return;

    if (destination.droppableId === 'availablecolumns') {
      availableColumn.splice(destination.index, 0, dropColumn);
      setAvailableColumns(availableColumn);
      const columnIndex = source.index;
      if (columnIndex >= 0) {
        selectedColumns.splice(columnIndex, 1);
      }
      setSelectedColumns(selectedColumns);
      return;
    }

    if (source.index < destination.index) {
      selectedColumns.splice(destination.index + 1, 0, dropColumn);
    } else {
      selectedColumns.splice(destination.index, 0, dropColumn);
    }
    if (source.droppableId === 'selectedcolumns') {
      if (source.index > destination.index) {
        selectedColumns.splice(source.index + 1, 1);
      } else {
        selectedColumns.splice(source.index, 1);
      }
    }
    setSelectedColumns(selectedColumns);
  };

  const onDragUpdate = useCallback(update => {
    if (!update.destination) {
      return;
    }

    const draggableId = update.draggableId;
    const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    if (draggedDOM) {
      draggedDOM.style.opacity = '0.8';
    }
  }, []);
  return (
    <Fragment>
      <div className='overflow-x-hidden h-100 bg-white'>
        <div style={{ display: 'block', overflow: 'hidden' }}>
          <ScreenLoader />
        </div>
        <ToastContainer />

        <Header type='form' name='Settings' />
        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className=' ms-3 naadi-report-customize-header-main'>
                AVAILABLE COLUMNS
              </div>
              <div className='naadi-report-customize-input-wrp'>
                <div>
                  <input
                    placeholder='Search ..'
                    className='form-control form-control-sm w-auto m-2 rounded-pill'
                    onChange={e => setSearchColumns(e.target.value)}
                  />
                </div>
                {renderAvailableColumns()}
              </div>
            </div>
            <div className='col-12 col-md-6 '>
              <div className='ms-3 naadi-report-customize-header-main'>
                SELECTED COLUMNS
              </div>
              <div className='naadi-report-customize-input-wrp'>
                <div>{renderSelectedColumns()}</div>
              </div>
            </div>
          </div>
        </DragDropContext>
        <div className='d-flex justify-content-end m-4'>
          <Button
            onClick={onBackFnc}
            variant='outline-primary'
            type='submit'
            className='ms-2 '
            size='lg'
          >
            Cancel
          </Button>
          <Button
            onClick={onConfigSave}
            variant='outline-primary'
            type='submit'
            className='ms-2 outline-primary'
            size='lg'
          >
            Save
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default CustomizeOperationSequence;
