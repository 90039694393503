import React, { Fragment } from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';

const TrackTraceRejectToggle = ({
  rejectmode,
  onRejectModeToggle,
  hideLable,
}) => {
  return (
    <Fragment>
      {hideLable !== true && <div className=''>REJECT</div>}
      <div
        className={rejectmode === true ? 'toggled' : ''}
        style={{
          paddingTop: '6px',
          paddingLeft: '16px',
        }}
      >
        <Switch
          onChange={onRejectModeToggle}
          checked={rejectmode}
          boxShadow={'0px 0px 2px #027AFF'}
          height={24}
          width={48}
          handleDiameter={20}
          offColor={'#fff'}
          onColor={'#FA0000'}
          checkedIcon={false}
          borderRadius={37}
          uncheckedIcon={false}
        ></Switch>
      </div>
    </Fragment>
  );
};
TrackTraceRejectToggle.propTypes = {
  rejectmode: PropTypes.bool.isRequired,
  onRejectModeToggle: PropTypes.func,
};
export default TrackTraceRejectToggle;
