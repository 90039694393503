import React from 'react';
import { Drawer } from 'antd';

const BottomDrawer = ({
  drawerTitle,
  handleClose,
  popUp,
  entity,
  drawerBody,
}) => {
  return (
    <Drawer
      title={drawerTitle}
      placement={'bottom'}
      closable={false}
      onClose={handleClose}
      visible={popUp}
      key={'bottom'}
      style={{
        borderRadius: '16px 16px 0px 0px',
      }}
      className={`bottomDrawer ${entity}`}
    >
      {drawerBody}
    </Drawer>
  );
};

export default BottomDrawer;
