/* eslint-disable sonarjs/cognitive-complexity */
import React, { Fragment, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../../assets/icons';
import { setPsShowChart } from '../../../store/order/orderstate/orderstate';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  LabelList,
} from 'recharts';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import { getBarGraphSeries } from '../../../helpers/order/orderHelper';
const TABLE_ROW_BORDER = 'p-summary-table-row-border';

const convertUoM = (qty, ps_unit_type) => {
  if (ps_unit_type === 'FEET') {
    return qty.toFixed(2);
  } else {
    return qty;
  }
};
const convertToFeet = (qty, ps_unit_type) => {
  if (ps_unit_type === 'FEET') {
    return (qty * 3.2808).toFixed(2);
  } else {
    return qty;
  }
};
const PsGroupSummaryLine = ({ filterSummery, psContainer }) => {
  const { ps_unit_type } = useSelector(state => state.orderstate);
  return (
    <>
      {filterSummery.map(summary => (
        <Fragment key={summary.key}>
          <div
            className={`d-flex bg-white pt-0 pb-0 flex-column flex-md-row ${TABLE_ROW_BORDER}`}
            style={{
              left: '0px',
              width: '1350px',
            }}
          >
            <div
              className='d-flex flex-column flex-lg-row bg-white'
              style={{
                width: '150px',
                position: 'sticky',
                left: 0,
                background: 'white',
                zIndex: '1',
                borderRight: '1px solid #027aff',
              }}
            >
              <div
                className='w-100 ps-2 pt-2 pb-3 text-wrap'
                style={{
                  width: '150px',
                }}
              >
                <div>{summary.label}</div>
                <div>{convertUoM(summary.qty, ps_unit_type)}</div>
              </div>
            </div>
            <div
              className='d-flex col-lg-2  col-md-2  p-summary-table-col-l-border p-summary-table-col-r-border'
              style={{ width: '1200px' }}
            >
              {summary?.list.map((itemTypeSummary, itemTypeSummaryIndex) => {
                const itemSummaryBC =
                  itemTypeSummaryIndex < summary?.list.length - 1
                    ? TABLE_ROW_BORDER
                    : '';
                return (
                  <div
                    key={itemTypeSummary.key}
                    className={`w-100 d-flex py-0 justify-content-md-start text-truncate ${itemSummaryBC}`}
                  >
                    <div
                      style={{ width: '200px' }}
                      className='d-flex flex-column'
                    >
                      <div
                        className='pb-2 ps-2 pt-2 text-wrap'
                        style={{ width: '200px', position: 'sticky', left: 0 }}
                      >
                        <div>{itemTypeSummary.key}</div>
                        <div>
                          {convertUoM(itemTypeSummary.qty, ps_unit_type)}
                        </div>
                      </div>
                    </div>
                    <div className='p-summary-table-col-l-border'>
                      {itemTypeSummary.list.map((groupSummary, gsIndex) => {
                        const gsBC =
                          gsIndex < itemTypeSummary?.list.length - 1
                            ? TABLE_ROW_BORDER
                            : '';
                        return (
                          <div
                            className={`d-flex w-100 py-0  ${gsBC}`}
                            key={groupSummary.key}
                          >
                            <div
                              style={{ width: '200px' }}
                              className='d-flex justify-content-start '
                            >
                              <div
                                className='pt-4  px-2 py-2 text-wrap'
                                style={{ width: '200px' }}
                              >
                                {groupSummary.key ? groupSummary.key : '-'}
                              </div>
                            </div>
                            <div className='p-summary-table-col-l-border d-flex flex-column p-summary-table-col-r-border'>
                              {groupSummary.list.map(
                                (group2Summary, gs2Index) => {
                                  const gs2BC =
                                    gs2Index < groupSummary?.list.length - 1
                                      ? TABLE_ROW_BORDER
                                      : '';
                                  return (
                                    <div
                                      className={`w-100  h-100 ${gs2BC}`}
                                      key={gs2Index}
                                    >
                                      <div className='d-flex'>
                                        <div
                                          style={{
                                            width: '200px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                          className='pt-4 px-2 py-2 text-wrap'
                                        >
                                          {' '}
                                          {group2Summary.key
                                            ? group2Summary.key
                                            : '-'}
                                        </div>
                                        <div
                                          style={{
                                            width: '200px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                          className='pt-4 px-2 py-2 text-wrap p-summary-table-col-l-border'
                                        >
                                          {' '}
                                          {group2Summary.qty
                                            ? group2Summary.qty
                                            : '-'}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        width: '200px',
                      }}
                      className='d-flex p-summary-table-col-l-border
                      p-summary-table-col-r-border justify-content-center py-2 col-lg-2 col-md-2 '
                    >
                      {convertUoM(itemTypeSummary.qty, ps_unit_type)}
                    </div>
                    <div>
                      <div className='bg-white h-100 d-flex '>
                        <div className='pt-2 pb-3 p-summary-table-col-r-border justify-content-end mx-auto'>
                          <div
                            style={{
                              width: '200px',
                            }}
                            className=' d-flex justify-content-center text-wrap'
                          >
                            {convertUoM(summary.qty, ps_unit_type)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      ))}
    </>
  );
};

const PsSummaryTable = ({
  itemType,
  group,
  group2,
  showChart,
  groupSummary,
  consolidationTypes,
  onDetail,
  psContainer,
}) => {
  const [periodInput, setPeriodInput] = useState('');
  const [itemCodeInput, setItemCodeInput] = useState('');
  const [groupInput, setGroupInput] = useState('');
  const [group2Input, setGroup2Input] = useState('');
  const [qtyInput, setQtyInput] = useState('');
  const [totalInput, setTotalInput] = useState('');
  const [filterSummery, setFilterSummery] = useState(groupSummary);
  const [term, setTerm] = useState('');
  const { ps_unit_type } = useSelector(state => state.orderstate);
  useEffect(() => {
    const input =
      periodInput ||
      itemCodeInput ||
      groupInput ||
      group2Input ||
      qtyInput ||
      totalInput;
    if (!input) {
      setFilterSummery(groupSummary);
    }
    const searchTerm = input.toLowerCase();
    if (term === 'PERIOD' || term === 'TOTAL') {
      setFilterSummery(
        groupSummary.filter(itemTypeSummary => {
          const searchKey = itemTypeSummary.key
            ? itemTypeSummary.key.toLowerCase()
            : '';
          const searchQty = itemTypeSummary.qty
            ? itemTypeSummary.qty.toString()
            : '';
          return term === 'TOTAL'
            ? searchQty.toLowerCase().includes(searchTerm.toLowerCase())
            : searchKey.toLowerCase().includes(searchTerm.toLowerCase()) ||
                searchQty.toLowerCase().includes(searchTerm.toLowerCase());
        }),
      );
    } else if (term === 'ITEM-CODE') {
      setFilterSummery(
        groupSummary.filter(summary =>
          summary.list.some(itemTypeSummary => {
            const searchQty = itemTypeSummary.qty
              ? itemTypeSummary.qty.toString()
              : '';
            const searchEdge = itemTypeSummary.edge
              ? itemTypeSummary.edge.toString()
              : '';
            return (
              searchQty
                .trim()
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              searchEdge.trim().toLowerCase().includes(searchTerm.toLowerCase())
            );
          }),
        ),
      );
    } else if (term === group) {
      setFilterSummery(
        groupSummary.filter(summary =>
          summary.list.some(summary =>
            summary.list.some(itemTypeSummary => {
              const searchKey = itemTypeSummary.key ? itemTypeSummary.key : '';

              return searchKey
                .trim()
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            }),
          ),
        ),
      );
    } else if (term === group2) {
      setFilterSummery(
        groupSummary.filter(summary =>
          summary.list.some(summary =>
            summary.list.some(summary =>
              summary.list.some(itemTypeSummary => {
                const searchQty = itemTypeSummary.key
                  ? itemTypeSummary.key.toString()
                  : '';

                return searchQty
                  .trim()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase());
              }),
            ),
          ),
        ),
      );
    } else if (term === 'QTY') {
      setFilterSummery(
        groupSummary.filter(summary =>
          summary.list.some(summary =>
            summary.list.some(itemTypeSummary => {
              const searchQty = itemTypeSummary.qty
                ? itemTypeSummary.qty.toString()
                : '';

              return searchQty
                .trim()
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            }),
          ),
        ),
      );
    }
  }, [
    group,
    group2,
    group2Input,
    groupInput,
    groupSummary,
    itemCodeInput,
    periodInput,
    psContainer,
    qtyInput,
    setFilterSummery,
    term,
    totalInput,
  ]);

  return (
    <Fragment>
      <div style={{ width: '1150px' }}>
        <div
          className='rubik-font d-flex bg-white pt-2 pb-0 header-container'
          style={{
            zIndex: '2',
            position: 'sticky',
            top: '0px',
            borderBottom: '1px solid #027aff',
            fontWeight: 'bold',
            width: '1350px',
          }}
        >
          <div
            className='table-column'
            style={{
              width: '150px',
              position: 'sticky',
              left: 0,
              background: 'white',
            }}
          >
            <span>Period</span>
            <form>
              <input
                type='search'
                className='table-search-input mb-1'
                placeholder='Search'
                onChange={e => setPeriodInput(e.target.value)}
                onClick={() => setTerm('PERIOD')}
              />
            </form>
          </div>

          <div className='table-column' style={{ width: '200px' }}>
            <span>{psContainer === 'RFT' ? 'EDGE-CODE' : 'ITEM-CODE'}</span>
            <form>
              <input
                type='search'
                className='table-search-input mb-1'
                placeholder='Search'
                onChange={e => setItemCodeInput(e.target.value)}
                onClick={() => setTerm('ITEM-CODE')}
              />
            </form>
          </div>

          <div className='table-column' style={{ width: '200px' }}>
            <span>{group}</span>
            <form onSubmit={e => e.preventDefault()}>
              <input
                type='search'
                className='table-search-input mb-1'
                placeholder={'Search'}
                name={group}
                onChange={e => setGroupInput(e.target.value)}
                onClick={() => setTerm(group)}
              />
            </form>
          </div>

          <div className='table-column' style={{ width: '200px' }}>
            <span>{group2}</span>
            <form onSubmit={e => e.preventDefault()}>
              <input
                type='search'
                className='table-search-input mb-1'
                placeholder={'Search'}
                name={group2}
                onChange={e => setGroup2Input(e.target.value)}
                onClick={() => setTerm(group2)}
              />
            </form>
          </div>

          <div className='table-column' style={{ width: '200px' }}>
            <span>
              QTY{' '}
              {psContainer === 'RFT'
                ? ps_unit_type === 'FEET'
                  ? '(ft)'
                  : '(m)'
                : ''}
            </span>

            <form onSubmit={e => e.preventDefault()}>
              <input
                type='search'
                className='table-search-input mb-1'
                placeholder={'Search'}
                name={'QTY'}
                onChange={e => setQtyInput(e.target.value)}
                onClick={() => setTerm('QTY')}
              />
            </form>
          </div>

          <div className='table-column' style={{ width: '200px' }}>
            <span>
              Group Total{' '}
              {psContainer === 'RFT'
                ? ps_unit_type === 'FEET'
                  ? '(ft)'
                  : '(m)'
                : ''}
            </span>
            <form>
              <input
                type='search'
                className='table-search-input mb-1'
                placeholder={'Search'}
                name={'TOTAL'}
                onChange={e => setTotalInput(e.target.value)}
                onClick={() => setTerm('TOTAL')}
              />
            </form>
          </div>

          <div className='table-column' style={{ width: '200px' }}>
            <span>Total</span>
            <form>
              <input
                type='search'
                className='table-search-input mb-1'
                placeholder={'Search'}
                name={'_TOTAL'}
                onChange={e => setTotalInput(e.target.value)}
                onClick={() => setTerm('TOTAL')}
              />
            </form>
          </div>
        </div>
        <PsGroupSummaryLine
          filterSummery={filterSummery}
          psContainer={psContainer}
        />
      </div>
    </Fragment>
  );
};

export const getGroupDesc = (ps, group) => {
  switch (group) {
    case 'ORDER':
      return ps.order_ref_code;
    case 'PARENT_ORDER':
      return ps.parent_order_ref_code;
    case 'EXT_REFERENCE':
      return ps.order_ext_ref_code;
    case 'SECONDARY_CUSTOMER':
      return ps.order_secondary_customer;
    case 'CUSTOMER':
      return ps.entity_name;
    case 'POD':
      return ps.pod_name;
    case 'ITEM_CODE':
      return ps.item_code;
    case 'MATERIAL':
      return ps.material;
    case 'EDGE':
      return ps.edge;
    default:
      return '';
  }
};

const ProductionSummaryBarGraph = ({ showChart, summary }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <div className='d-flex justify-content-end'>
        <div
          onClick={() => {
            dispatch(setPsShowChart(showChart !== true));
          }}
        >
          {showChart === true ? (
            <ChevronUpIcon fill={'#006EED'} />
          ) : (
            <ChevronDownIcon fill={'#006EED'} />
          )}
        </div>
      </div>
      {showChart === true && (
        <Fragment>
          <div className='d-flex'>
            <Chart
              height='450px'
              width='100%'
              chartType='ComboChart'
              data={summary.graph_summary}
              options={{
                title: 'Planned Vs Actual Production Summary',
                //colors: colors,
                hAxis: {
                  title: 'Period',
                  slantedText: true,
                  slantedTextAngle: 45,
                  showTextEvery: 1,
                },
                bar: {
                  groupWidth: '95%',
                },
                seriesType: 'bars',
                isStacked: true,
                series: getBarGraphSeries(summary.bar_groups),
              }}
            ></Chart>
          </div>
          <div
            className='justify-content-end w-100'
            style={{ color: '#8884d8', fontSize: '24px' }}
          >
            <div className='d-flex justify-content-center pt-2 pb-2'>
              {summary.summary_total} - Actual
            </div>
            <div className='d-flex justify-content-center pt-2'>
              {summary.planned_total} - Planned
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

/* eslint-disable */
const ProductionSummaryChart = ({ showChart, summaries }) => {
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const valTotal = summaries.map(val => val.qty).reduce((a, b) => a + b, 0);
    setTotal(valTotal);
  }, [summaries]);
  return (
    <div>
      <div className='d-flex justify-content-end'>
        <div
          onClick={() => {
            dispatch(setPsShowChart(showChart !== true));
          }}
        >
          {showChart === true ? (
            <ChevronUpIcon fill={'#006EED'} />
          ) : (
            <ChevronDownIcon fill={'#006EED'} />
          )}
        </div>
      </div>
      {showChart === true && (
        <Fragment>
          <div className='d-flex'>
            <ResponsiveContainer width='100%' minHeight='300px'>
              <BarChart
                width={500}
                height={300}
                data={summaries}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='desc' />
                <YAxis />
                <Tooltip formatter={value => value.toFixed(0)} />
                <Bar dataKey='qty' stackId='a' fill='#8884d8'>
                  <LabelList
                    dataKey='qty'
                    position='top'
                    formatter={value => value.toFixed(0)}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div
            className='d-flex justify-content-center'
            style={{ color: '#8884d8', fontSize: '24px' }}
          >
            TOTAL {total}
          </div>
        </Fragment>
      )}
    </div>
  );
};
/* eslint-enable */
export const ProductionSummaryContainer = ({
  group,
  group2,
  graphSummary,
  psSummaries,
  showChart,
  operationCode,
  onDetail,
  consolidationTypes,
  itemType,
  psContainer,
}) => {
  //eslint-disable-next-line
  const [chartSummary, setChartSummary] = useState([]);
  const [groupSummary, setGroupSummary] = useState([]);
  const { ps_unit_type } = useSelector(state => state.orderstate);
  useEffect(() => {
    const descMap = {};
    const groupSummaryMap = {};

    psSummaries
      .filter(v => {
        if (psContainer === 'RFT') {
          return !v.edge?.includes('NO_EDGE');
        }
        return true;
      })
      .forEach(val => {
        const convertedQty = convertToFeet(val.qty, ps_unit_type);
        if (!descMap[`${val.desc}`]) {
          descMap[`${val.desc}`] = {
            desc: val.desc,
            qty: 0,
          };
        }
        descMap[`${val.desc}`].qty += parseFloat(convertedQty);
        if (!groupSummaryMap[`${val.period}`]) {
          groupSummaryMap[`${val.period}`] = {
            key: val.period,
            label: val.desc,
            qty: 0,
            list: {},
          };
        }

        const periodSummary = groupSummaryMap[`${val.period}`];
        periodSummary.qty += parseFloat(convertedQty);
        const _itemType = itemType === 'ALL' ? itemType : val.item_code;
        if (!periodSummary.list[`${_itemType}`]) {
          periodSummary.list[`${_itemType}`] = {
            key: _itemType,
            qty: 0,
            list: {},
            edge: val.edge,
          };
        }
        const _group = group ? getGroupDesc(val, group) : 'NONE';
        const _group2 = group2 ? getGroupDesc(val, group2) : 'NONE';
        const itemTypeSummary = periodSummary.list[`${_itemType}`];
        itemTypeSummary.qty += parseFloat(convertedQty);
        if (!itemTypeSummary.list[`${_group}`]) {
          itemTypeSummary.list[`${_group}`] = {
            key: _group,
            qty: 0,
            list: {},
          };
        }
        const groupSummary = itemTypeSummary.list[`${_group}`];
        groupSummary.qty += parseFloat(convertedQty);
        if (!groupSummary.list[`${_group2}`]) {
          groupSummary.list[`${_group2}`] = {
            key: _group2,
            qty: 0,
            list: {},
          };
        }
        const groupSummary2 = groupSummary.list[`${_group2}`];
        groupSummary2.qty += parseFloat(convertedQty);
      });
    const gSum = Object.values(descMap);
    setChartSummary(gSum);
    const gSummary = Object.keys(groupSummaryMap).map(periodKey => {
      const periodSummary = groupSummaryMap[`${periodKey}`];
      const periodList = Object.keys(periodSummary.list).map(itemTypeKey => {
        const itemTypeSummary = periodSummary.list[`${itemTypeKey}`];
        const itemSummaryList = Object.keys(itemTypeSummary.list).map(
          groupSummaryKey => {
            const groupSummary = itemTypeSummary.list[`${groupSummaryKey}`];
            const groupSummaryList = Object.keys(groupSummary.list).map(
              group2SummaryKey => {
                const group2Summary = groupSummary.list[`${group2SummaryKey}`];
                return { ...group2Summary, list: [] };
              },
            );
            groupSummaryList.sort((a, b) => {
              return a.key.localeCompare(b.key);
            });
            return { ...groupSummary, list: groupSummaryList };
          },
        );
        itemSummaryList.sort((a, b) => {
          return a.key.localeCompare(b.key);
        });
        return { ...itemTypeSummary, list: itemSummaryList };
      });
      periodList.sort((a, b) => {
        return a.key.localeCompare(b.key);
      });
      return { ...periodSummary, list: periodList };
    });
    gSummary.sort((a, b) => {
      return moment(b.key).isAfter(moment(a.key));
    });

    setGroupSummary(gSummary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    psSummaries,
    setChartSummary,
    itemType,
    group,
    group2,
    setGroupSummary,
    psContainer,
    ps_unit_type,
  ]);
  return (
    <Fragment>
      <div
        className=''
        style={{
          paddingTop: '0px !important',
          width: '100%',
          position: 'relative',
          height: 'calc(100vh - 200px)',
          overflow: 'scroll',
        }}
      >
        <ProductionSummaryBarGraph
          showChart={showChart}
          summary={graphSummary}
        />
        <div className='font-weight-bold pt-3'>
          <PsSummaryTable
            group={group}
            group2={group2}
            itemType={itemType}
            showChart={showChart}
            groupSummary={groupSummary}
            consolidationTypes={consolidationTypes}
            onDetail={onDetail}
            psContainer={psContainer}
          />
        </div>
      </div>
    </Fragment>
  );
};
