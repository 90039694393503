export const TYPES = {
  SET_LABEL_PRINT_RECORDS: 'SET_LABEL_PRINT_RECORDS',
  SET_ITEMS_PAGE_PARAMAS: 'SET_ITEMS_PAGE_PARAMAS',
  SET_ITEMS_PAGE_FILTER_PARAMS: 'SET_ITEMS_PAGE_FILTER_PARAMS',
};

export const DEFAULT = {
  label_print_records: [],
  pageParams: {
    page_size: 50,
    page_no: 0,
  },
  filterParams: {
    from_date: null,
    to_date: null,
    pre_defined_time: '',
  },
};
