"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WSS_URL = exports.BASE_URL = void 0;
var API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api.prod.naadi.io';
var WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT || 'wss://api.staging.naadi.io';
var BASE_URL = API_ENDPOINT;
exports.BASE_URL = BASE_URL;
var WSS_URL = WSS_ENDPOINT;
exports.WSS_URL = WSS_URL;