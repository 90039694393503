import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOperationsInitData } from '../../helpers/order/progressHelper';
import { toastError } from '../../helpers/packing/packingHelper';
import ScreenModal from '../../components/common/screen-modal';
import ScreenLoader from '../../components/common/screen-loader';
import Header from './header';
import Container from 'react-bootstrap/Container';
import { ToastContainer } from 'react-toastify';
import { actions, ResponseCodes } from '@naadi/framework';
import { ProductionSummaryViewModes } from '../../components/order/productionsummary/production-summary-view-modes';
import moment from 'moment/moment';
import { getProductionSummary } from '../../services/order';
import {
  getGroupDesc,
  ProductionSummaryContainer,
} from '../../components/order/productionsummary/production-summary-container';
import * as XLSX from 'xlsx';
import {
  getPeriod,
  getPeriodDesc,
  prepareProductionSummaryChartData,
} from '../../helpers/order/orderHelper';
const CONSOLIDATION_TYPES = [
  {
    key: 'MATERIAL',
    label: 'Material',
  },
  {
    key: 'ITEM_CODE',
    label: 'Item Code',
  },
  {
    key: 'EDGE',
    label: 'Edgebands',
  },
  {
    key: 'POD',
    label: 'Station',
  },
  {
    key: 'ORDER',
    label: 'Order',
  },
  {
    key: 'PARENT_ORDER',
    label: 'Parent Order',
  },
  {
    key: 'CUSTOMER',
    label: 'Customer',
  },
  {
    key: 'EXT_REFERENCE',
    label: 'External Reference',
  },
  {
    key: 'SECONDARY_CUSTOMER',
    label: 'Secondary Customer',
  },
];

const getSummaryDesc = summary => {
  switch (summary.consolidation_type) {
    case 'TOTAL':
      return getPeriodDesc(summary);
    case 'MATERIAL':
      return summary.material;
    case 'EDGE':
      return summary.edges;
    case 'ORDER':
    case 'PARENT_ORDER':
    case 'EXT_REFERENCE':
    case 'SECONDARY_CUSTOMER':
      return summary.order_key ? summary.order_key : 'DEFAULT';
    case 'CUSTOMER':
      return summary.desc;
    case 'ITEM_CODE':
      return summary.item_code;
    case 'POD':
      return summary.pod_name;
    default:
      return 'TO_BE DEVELOPED';
  }
};
const attachSummaryChildren = (psSummary, parent, summaries) => {
  return psSummary.map(val => {
    if (val.seq === parent.seq) {
      val.children = summaries;
      return val;
    }
    val.children = attachSummaryChildren(val.children, parent, summaries);
    return val;
  });
};

export const downloadProductionSummary = ({
  group,
  group2,
  group3,
  itemType,
  summaryList,
}) => {
  const headerCols = ['Period', 'Item Code', group, group2, 'Qty'];
  const rows = [];
  summaryList.forEach(ps => {
    const ref1 = getGroupDesc(ps, group);
    const ref2 = getGroupDesc(ps, group2);

    const row = [ps.desc, ps.item_code, ref1, ref2, ps.qty];
    rows.push(row);
  });
  const worksheet = XLSX.utils.aoa_to_sheet([headerCols], {
    type: 'string',
  });
  XLSX.utils.sheet_add_aoa(worksheet, rows, {
    origin: 'A2',
  });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Production Summary');

  // eslint-disable-next-line
  XLSX.writeFile(workbook, 'ProductionSummary.xlsx');
};

const ProductionSummary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [operations, setOperations] = useState([]);
  //eslint-disable-next-line
  const [searchTerm, setSearchTerm] = useState('');
  const [psSummary, setPsSummary] = useState([]);
  const [graphSummary, setGraphSummary] = useState({});
  const summaryIdSeq = useRef(0);
  const {
    production_summary_group,
    production_summary_group2,
    ps_item_type,
    ps_filter_custom_date_range,
    ps_operation_code,
    ps_show_chart,
  } = useSelector(state => state.orderstate);
  useEffect(() => {
    const fn = async () => {
      try {
        dispatch(actions.busy.add('INIT_ORDER_PROGRESS'));
        const _operations = await getOperationsInitData();
        if (!_operations) {
          toastError('Unable to Initialize. Please try again');
          return;
        }
        setOperations(_operations.filter(val => val.visible));
      } finally {
        dispatch(actions.busy.remove('INIT_ORDER_PROGRESS'));
      }
    };
    fn();
  }, [dispatch, setOperations]);
  const fetchState = useRef({ fetching: false });
  /* eslint-disable sonarjs/cognitive-complexity */
  const onDetailCb = useCallback(
    async (summary, consolidationType) => {
      const startPeriod = summary.filter.period_start;
      const endPeriod = summary.filter.period_end;
      const period = summary.period_type;
      const payload = {};
      let hour = null;
      if (summary.level >= 1) {
        Object.keys(summary.filter).forEach(key => {
          if (
            key !== 'period_start' &&
            key !== 'period_end' &&
            key !== 'hour'
          ) {
            payload[`${key}`] = summary.filter[`${key}`];
          }
        });
      }
      let consolidationKey = consolidationType.key;
      let _period = period;
      let consolidatePeriod = true;
      if (consolidationType.key === 'TOTAL') {
        consolidatePeriod = false;
        _period = consolidationType.period;
      }
      if (period === 'HOUR') {
        hour = 0;
        if (summary.filter.hour && !isNaN(summary.filter.hour + '')) {
          hour = summary.filter.hour;
        }
      }
      const prodSummaryResp = await getProductionSummary(
        ps_operation_code,
        _period,
        consolidationKey,
        startPeriod,
        endPeriod,
        hour,
        consolidatePeriod,
        payload,
      );
      if (prodSummaryResp.status !== ResponseCodes.SUCCESS) {
        toastError(
          prodSummaryResp.err && prodSummaryResp.err.err
            ? prodSummaryResp.err.err
            : 'Unable to fetch the summary',
        );
        return;
      }
      const graphSummary = prepareProductionSummaryChartData(prodSummaryResp);
      setGraphSummary(graphSummary);
      const summarries = prodSummaryResp.payload.map(val => {
        summaryIdSeq.current++;
        val.seq = summaryIdSeq.current;
        val.level = summary.level + 1;
        val.desc = getSummaryDesc(val);
        val.children = [];
        val.ct_parents = [...summary.ct_parents, consolidationType.key];
        val.filter = { ...summary.filter };
        if (consolidationType.key === 'TOTAL') {
          const pE =
            val.period_type === 'HOUR'
              ? moment(val.period_end)
              : moment(val.period_end).add(-1, 'day');
          val.filter.period_start = moment(val.period).format('YYYYMMDD');
          val.filter.period_end = moment(pE).format('YYYYMMDD');
          val.filter.hour = val.period_hour;
        }
        //look at implementing this level filter later
        switch (consolidationType.key) {
          case 'MATERIAL':
            val.filter.material = [val.material];
            break;
          case 'ITEM_CODE':
            val.filter.item_code = [val.item_code];
            break;
          case 'EDGE':
            val.filter.edges = [val.edges];
            break;
          case 'POD':
            val.filter.pod_name = [val.pod_name];
            break;
          case 'ORDER':
            val.filter.order_code = [val.order_key];
            break;
          case 'PARENT_ORDER':
            val.filter.parent_order = [val.order_key];
            break;
          case 'CUSTOMER':
            val.filter.entity_id = [val.order_key];
            break;
          case 'SECONDARY_CUSTOMER':
            val.filter.secondary_customer = [val.order_key];
            break;
          case 'EXT_REFERENCE':
            val.filter.ext_code = [val.order_key];
            break;
          default:
        }

        return val;
      });
      setPsSummary(psSummary => {
        return attachSummaryChildren(psSummary, summary, summarries);
      });
    },
    [ps_operation_code, setPsSummary, setGraphSummary],
  );
  /* eslint-enable sonarjs/cognitive-complexity */
  /* eslint-disable sonarjs/cognitive-complexity */

  const fetchProductionSummary = useCallback(async () => {
    let fromDate = moment().startOf('day').toDate();
    let toDate = moment().endOf('day').toDate();

    const customDateRange = ps_filter_custom_date_range || { preset: 'today' };
    switch (customDateRange.preset) {
      case 'today':
        break;
      case 'yesterday':
        fromDate = moment().startOf('day').add(-1, 'day').toDate();
        toDate = moment().endOf('day').add(-1, 'day').toDate();
        break;
      case 'this_week':
        fromDate = moment().startOf('week').toDate();
        break;
      case 'this_month':
        fromDate = moment().startOf('month').toDate();
        break;
      case 'last_month':
        fromDate = moment().startOf('month').add(-1, 'month').toDate();
        toDate = moment().startOf('month').toDate();
        break;
      case 'last_two_months':
        fromDate = moment().startOf('month').add(-2, 'month').toDate();
        break;
      case 'last_six_months':
        fromDate = moment().startOf('month').add(-6, 'month').toDate();
        break;
      case 'this_year':
        fromDate = moment().startOf('year').toDate();
        break;
      case 'last_year':
        fromDate = moment().startOf('year').add(-1, 'year').toDate();
        toDate = moment().startOf('year').add(-1, 'day').toDate();
        break;
      case 'last_2_year':
        fromDate = moment().startOf('year').add(-1, 'year').toDate();
        break;
      case 'begining':
        fromDate = moment('20200101').toDate();
        break;
      default:
        fromDate = customDateRange.from
          ? moment(customDateRange.from).startOf('day').toDate()
          : fromDate;
        toDate = customDateRange.to
          ? moment(customDateRange.to).endOf('day').toDate()
          : toDate;
    }

    let startPeriod = moment(fromDate).toDate();
    let endPeriod = moment(toDate).toDate();

    const period = getPeriod(startPeriod, endPeriod);

    switch (period) {
      case 'WEEK':
        startPeriod = moment(startPeriod)
          .startOf('week')
          .set({ hour: 6, minute: 30, second: 0 })
          .toDate();
        endPeriod = moment(endPeriod)
          .endOf('week')
          .set({ hour: 18, minute: 30, second: 0 })
          .toDate();
        break;
      case 'MONTH':
        startPeriod = moment(startPeriod)
          .startOf('month')
          .set({ hour: 6, minute: 30, second: 0 })
          .toDate();
        endPeriod = moment(endPeriod)
          .endOf('month')
          .set({ hour: 18, minute: 30, second: 0 })
          .toDate();
        break;
      case 'YEAR':
        startPeriod = moment(startPeriod)
          .startOf('year')
          .set({ hour: 6, minute: 30, second: 0 })
          .toDate();
        endPeriod = moment(endPeriod)
          .endOf('year')
          .set({ hour: 18, minute: 30, second: 0 })
          .toDate();
        break;
      default:
    }

    if (fetchState.current.fetching) {
      return;
    }

    try {
      fetchState.current.fetching = true;
      dispatch(actions.busy.add('FETCH_PRODUCTION_SUMMARY'));

      const groupColumns = [];
      let consolidationType = 'QTY';
      switch (ps_item_type) {
        case 'PART':
        case 'HARDWARE':
        case 'BOARD':
        case 'GLASS':
          consolidationType = 'QTY';
          break;
        case 'EDGE':
          consolidationType = 'EDGE';
          break;
        case 'BOX':
          consolidationType = 'BOX';
          break;
        default:
          consolidationType = 'QTY';
      }
      const filter = {
        fetch_planned: true,
        operation_code: [ps_operation_code],
        period_type: [period],
        period_start: moment(startPeriod).startOf('day'),
        period_end: moment(endPeriod).startOf('day').add(1, 'day'),

        consolidation_type: [consolidationType],
      };
      if (
        ps_item_type === 'PART' ||
        ps_item_type === 'HARDWARE' ||
        ps_item_type === 'BOARD' ||
        ps_item_type === 'BOX' ||
        ps_item_type === 'GLASS' ||
        ps_item_type === 'PROFILE'
      ) {
        groupColumns.push('ITEM_CODE');
        filter.item_code = [ps_item_type];
      } else if (ps_item_type === 'PART_BOARD') {
        groupColumns.push('ITEM_CODE');
        filter.item_code = ['PART', 'BOARD'];
      } else if (ps_item_type === 'PART_BOX') {
        groupColumns.push('ITEM_CODE');
        filter.item_code = ['PART', 'BOX'];
        filter.consolidation_type = ['BOX', 'QTY'];
        filter.item_code = ['PART', 'BOARD'];
      }

      if (production_summary_group && production_summary_group !== 'NONE') {
        groupColumns.push(production_summary_group);
      }
      if (production_summary_group2 && production_summary_group2 !== 'NONE') {
        groupColumns.push(production_summary_group2);
      }
      if (
        production_summary_group2 === 'EDGE' ||
        production_summary_group === 'EDGE'
      ) {
        filter.consolidation_type = ['EDGE'];
      }
      const prodSummaryResp = await getProductionSummary(groupColumns, filter);
      if (prodSummaryResp.status !== ResponseCodes.SUCCESS) {
        toastError(prodSummaryResp.err?.err || 'Unable to fetch the summary');
        return;
      }

      const summary = prodSummaryResp.payload.map(val => {
        summaryIdSeq.current++;
        val.seq = summaryIdSeq.current;
        val.level = 0;
        val.desc = getPeriodDesc(val);
        val.qty =
          val.consolidation_type === 'EDGE'
            ? Math.round(val.qty / 1000)
            : val.qty;
        return val;
      });
      const graphSummary = prepareProductionSummaryChartData(
        prodSummaryResp.payload,
        prodSummaryResp.planned,
      );
      setGraphSummary(graphSummary);
      setPsSummary(summary);
    } finally {
      dispatch(actions.busy.remove('FETCH_PRODUCTION_SUMMARY'));
      fetchState.current.fetching = false;
    }
  }, [
    dispatch,
    ps_operation_code,
    ps_filter_custom_date_range,
    ps_item_type,
    production_summary_group,
    production_summary_group2,
    setPsSummary,
    setGraphSummary,
  ]);

  /* eslint-enable sonarjs/cognitive-complexity */
  useEffect(() => {
    fetchProductionSummary();
  }, [fetchProductionSummary]);
  return (
    <Fragment>
      <div className='height-100-percent width-100-percent overflow-hidden'>
        <ScreenModal />
        <ScreenLoader />
        <Header history={history} title={'Production Summary'} />
        <div className='content bg-white' style={{ padding: '16px' }}>
          <Container fluid>
            <div className='bg-white' style={{ zIndex: '1' }}>
              <ProductionSummaryViewModes
                filterCb={setSearchTerm}
                operations={operations}
              />
              <ProductionSummaryContainer
                itemType={ps_item_type}
                group={production_summary_group}
                group2={production_summary_group2}
                showChart={ps_show_chart}
                psSummaries={psSummary}
                graphSummary={graphSummary}
                operationCode={ps_operation_code}
                consolidationTypes={CONSOLIDATION_TYPES}
                onDetail={onDetailCb}
              />
            </div>
            <div
              className='row pb-3 align-items-center'
              style={{ minHeight: '80px' }}
            >
              <div className='justify-content-end '>
                <div className='d-flex justify-content-end'>
                  <div
                    className='btn btn-outline-primary'
                    onClick={() => {
                      downloadProductionSummary({
                        group: production_summary_group,
                        group2: production_summary_group2,
                        summaryList: psSummary,
                        itemType: ps_item_type,
                      });
                    }}
                  >
                    Download
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <ToastContainer />
      </div>
    </Fragment>
  );
};
export default ProductionSummary;
ProductionSummary.propTypes = {};
