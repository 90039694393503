import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import BlackClose from '../../assets/icons/black-close.svg';
const customStyles = {
  control: provided => ({
    ...provided,
    border: 'none',
    fontSize: '14px',
    backgroundColor: 'transparent',
    padding: '0',
    boxShadow: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 !important',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
};
const month = 'month';
const Month = 'MONTH';
const timePeriodOptions = [
  { value: 'today', label: 'TODAY' },
  { value: 'yesterday', label: 'YESTERDAY' },
  { value: 'this_week', label: 'THIS WEEK' },
  { value: `this_${month}`, label: `THIS ${Month}` },
  { value: `last_${month}`, label: `LAST ${Month}` },
  { value: `last_2${month}`, label: `LAST 2${Month}` },
  { value: `last_6${month}`, label: 'LAST 6 MONTH' },
  { value: 'this_year', label: 'THIS YEAR' },
  { value: 'last_year', label: 'LAST YEAR' },
  { value: 'last_2year', label: 'LAST 2 YEAR' },
];

export const HourlySelector = ({ hoursOptions, onTimeChange, cronHour }) => {
  const [additionalRanges, setAdditionalRanges] = useState([
    { startTime: '00:00', endTime: '00:00' },
  ]);
  useEffect(() => {
    if (cronHour) {
      const hoursArray = cronHour.split(',').map(hour => hour.padStart(2, '0'));
      const startTime = hoursArray[0];
      const endTime = hoursArray[hoursArray.length - 1];
      setAdditionalRanges([{ startTime, endTime }]);
      const hoursRange = hoursArray.map(hour => parseInt(hour, 10));
      onTimeChange(hoursRange);
    }
  }, [cronHour, onTimeChange]);
  const handleTimeChangeForRange = (value, type, index) => {
    const updatedRanges = [...additionalRanges];
    updatedRanges[`${index}`][`${type}`] = value;
    setAdditionalRanges(updatedRanges);

    // Generate hours range and send it to parent component
    const startHour = parseInt(
      updatedRanges[`${index}`].startTime.split(':')[0],
      10,
    );
    const endHour = parseInt(
      updatedRanges[`${index}`].endTime.split(':')[0],
      10,
    );
    const hoursRange = generateCronHours(startHour, endHour);
    onTimeChange(hoursRange);
  };
  const generateCronHours = (startHour, endHour) => {
    let hours = [];

    // If startHour <= endHour (e.g., 12 to 15), just iterate from start to end
    if (startHour <= endHour) {
      for (let i = startHour; i <= endHour; i++) {
        hours.push(i);
      }
    } else {
      // If startHour > endHour (e.g., 23 to 02), we wrap around midnight
      for (let i = startHour; i <= 23; i++) {
        hours.push(i);
      }
      for (let i = 0; i <= endHour; i++) {
        hours.push(i);
      }
    }

    return hours;
  };

  return (
    <div className='row'>
      {additionalRanges.map((range, index) => (
        <div className='row mb-3 col-sm-10 col-md-10' key={index}>
          <div className='col-md-2 col-sm-2 mx-4'>
            <Form.Group
              className='form-group mb-1 cs-box-width'
              style={{ height: '58px', width: '132px' }}
            >
              <Form.Label>Start Time</Form.Label>
              <Select
                value={hoursOptions.find(
                  option => option.value === range.startTime,
                )}
                onChange={option =>
                  handleTimeChangeForRange(option.value, 'startTime', index)
                }
                options={hoursOptions}
                placeholder='00:00'
                styles={customStyles}
              />
            </Form.Group>
          </div>

          {/* <div className='col-md-1 col-sm-1 d-flex align-items-center justify-content-center ms-2'>
            <span>--</span>
          </div> */}

          <div className='col-md-2 col-sm-2 col-xxl-2'>
            <Form.Group
              className='form-group mb-1 cs-box-width ms-4'
              style={{ height: '58px', width: '132px' }}
            >
              <Form.Label>End Time</Form.Label>
              <Select
                value={hoursOptions.find(
                  option => option.value === range.endTime,
                )}
                onChange={option =>
                  handleTimeChangeForRange(option.value, 'endTime', index)
                }
                options={hoursOptions}
                placeholder='00:00'
                styles={customStyles}
              />
            </Form.Group>
          </div>
        </div>
      ))}
    </div>
  );
};
export const ChipSelect = ({
  options,
  selectedItems,
  setSelectedItems,
  placeholder,
}) => {
  const handleSelectChange = selectedOption => {
    if (!selectedOption) return;

    if (selectedOption.value === '*') {
      setSelectedItems([selectedOption]);
    } else {
      if (selectedItems.some(item => item.value === '*')) return;
      setSelectedItems(prevItems => {
        if (!prevItems.some(item => item.value === selectedOption.value)) {
          return [...prevItems, selectedOption];
        }
        return prevItems;
      });
    }
  };

  return (
    <div>
      <Select
        options={options}
        onChange={handleSelectChange}
        placeholder={placeholder || 'Select options'}
        styles={customStyles}
        isSearchable
      />
      <div
        className='d-flex flex-wrap mt-2'
        style={{
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        {selectedItems.length > 0 &&
          selectedItems.map((item, index) => (
            <div
              key={index}
              className='filter-btns mb-1'
              style={{
                border: '1px solid #027aff',
                backgroundColor: '#afd6ff',
                display: 'flex',
                alignItems: 'center',
                padding: '3px 8px',
                borderRadius: '10px',
                marginRight: '10px',
                marginBottom: '5px',
                boxSizing: 'border-box',
                transition: 'all .3s linear',
              }}
            >
              <div style={{ marginRight: '12px' }}>{item.label}</div>{' '}
              <div>
                <img
                  src={BlackClose}
                  alt='Remove'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedItems(prevItems =>
                      prevItems.filter((_, i) => i !== index),
                    );
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

function TimePeriod({ onDatesChange, onPeriodSelect, selectedPeriod }) {
  const [timePeriod, setTimePeriod] = useState(null);
  const [previousDates, setPreviousDates] = useState({
    start: null,
    end: null,
  });
  useEffect(() => {
    if (selectedPeriod) {
      setTimePeriod(selectedPeriod.value); // Set the initial value from the prop
    }
  }, [selectedPeriod]);

  useEffect(() => {
    const calculateDates = period => {
      const now = new Date();
      let start = null;
      let end = null;

      switch (period) {
        case 'today':
          start = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            0,
            0,
            0,
          );
          end = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            23,
            59,
            59,
            999,
          );
          break;
        case 'yesterday':
          start = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 1,
            0,
            0,
            0,
          );
          end = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 1,
            23,
            59,
            59,
            999,
          );
          break;
        case 'this_week':
          const dayOfWeek = now.getUTCDay();
          start = new Date(now);
          start.setUTCDate(now.getUTCDate() - dayOfWeek);
          start.setUTCHours(0, 0, 0, 0);
          // End of the week (Saturday)
          end = new Date(start);
          end.setUTCDate(start.getUTCDate() + 6);
          end.setUTCHours(23, 59, 59, 999);
          break;
        case 'this_month':
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          end = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            23,
            59,
            59,
            999,
          );
          break;
        case 'last_month':
          start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999);
          break;
        case 'last_2month':
          start = new Date(now.getFullYear(), now.getMonth() - 2, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999);
          break;
        case 'last_6month':
          start = new Date(now.getFullYear(), now.getMonth() - 6, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999);
          break;
        default:
          start = null;
          end = null;
      }
      return {
        start: start ? start.toISOString() : null,
        end: end ? end.toISOString() : null,
      };
    };

    if (timePeriod) {
      const { start, end } = calculateDates(timePeriod);
      if (
        start &&
        end &&
        (!previousDates.start ||
          start !== previousDates.start ||
          end !== previousDates.end)
      ) {
        onDatesChange(start, end);
        // onPeriodSelect(timePeriod);
        setPreviousDates({ start, end });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePeriod]);

  const handleTimePeriodChange = option => {
    setTimePeriod(option.value);
    onPeriodSelect(option);
  };
  const selectedOption = timePeriodOptions.find(
    option => option.value === timePeriod,
  );

  return (
    <Select
      value={selectedOption}
      onChange={handleTimePeriodChange}
      options={timePeriodOptions}
      placeholder='Select period'
      className='pl-0'
      styles={customStyles}
    />
  );
}

export default TimePeriod;
