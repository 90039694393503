"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var KEYCLOAK_ENDPOINT = process.env.REACT_APP_KEYCLOAK_ENDPOINT || 'https://keycloak.naadi.io';
var keycloak = {
  url: KEYCLOAK_ENDPOINT,
  realm: 'naadi',
  clientId: 'naadi-jwt'
};
var _default = keycloak;
exports.default = _default;