import service from './index';
import { getState } from '../store';

export const getReportTypes = async () => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'GET',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/reports/group`,
  });
};

export const getReports = filter => {
  const {
    user: { org },
  } = getState();
  filter = filter ? filter : {};
  filter.org_id = org.uuid;
  filter.req_payload = filter.req_payload ? filter.req_payload : {};
  filter.req_payload.org_id = org.uuid;
  if (!filter.req_payload.visible) {
    filter.req_payload.visible = [true];
  }
  return service({
    method: 'POST',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/reports/filter`,
    data: filter,
  });
};

export const getReportByCode = code => {
  const {
    user: { org },
  } = getState();

  return service({
    method: 'GET',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/reports/code/${code}`,
    data: {},
  });
};

export const getReportColumns = report_id => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'GET',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/columns/report/${report_id}`,
  });
};

export const getReportFilters = report_id => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'GET',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/filterfield/report/${report_id}`,
  });
};

export const generateReport = filter => {
  const {
    user: { org, branch },
  } = getState();
  filter.org_id = org.uuid;
  filter.req_payload.org_id = org.uuid;
  filter.req_payload.branch_id = branch ? branch.branch_id : '';
  return service({
    method: 'POST',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/reports/generate/report`,
    data: filter,
  });
};

export const submitGenerateReportRequest = reqData => {
  const {
    user: { org, user_id },
  } = getState();
  const req = {
    org_id: org.uuid,
    user_id: user_id,
    req_payload: reqData,
  };
  reqData.org_id = org.uuid;
  reqData.user_id = user_id;
  return service({
    method: 'POST',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/userdownload/create`,
    data: req,
  });
};
export const getReportDefaultUserConfig = report_id => {
  return service({
    method: 'POST',
    url: `/naadi-app/api/web/v1/user/currentuserconfig/get`,
    data: {
      req_payload: {
        config_type: 'REPORT_DEFAULT_USER_CONFIG',
        config_key: report_id,
      },
    },
  });
};
export const updateReportDefaultUserConfig = (report_id, code) => {
  return service({
    method: 'POST',
    url: `/naadi-app/api/web/v1/user/currentuserconfig/update`,
    data: {
      active: true,
      approved: true,
      deleted: false,
      req_payload: {
        config_type: 'REPORT_DEFAULT_USER_CONFIG',
        config_key: report_id,
        config_val: code,
      },
    },
  });
};
export const getReportCustomConfig = report_id => {
  return service({
    method: 'POST',
    url: `/naadi-app/api/web/v1/user/currentuserconfig/get`,
    data: {
      req_payload: {
        config_type: 'REPORT_CUSTOM_COLUMNS',
        config_key: report_id,
      },
    },
  });
};

export const updateReportCustomConfig = (
  report_id,
  selectedColumns,
  freezeColumns,
) => {
  return service({
    method: 'POST',
    url: `/naadi-app/api/web/v1/user/currentuserconfig/update`,
    data: {
      active: true,
      approved: true,
      deleted: false,
      req_payload: {
        config_type: 'REPORT_CUSTOM_COLUMNS',
        config_key: report_id,
        config_val: {
          selected_columns: selectedColumns,
          freeze_columns: freezeColumns,
        },
      },
    },
  });
};

export const getReportColumnLink = filter => {
  const {
    user: { org, user_id },
  } = getState();
  filter.org_id = org.uuid;
  return service({
    method: 'POST',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/columns/columnlink?user_id=${user_id}`,
    data: {
      user_id: user_id,
      org_id: org.uuid,
      req_payload: filter,
    },
  });
};

export const getReportUserDownloads = filter => {
  const {
    user: { org, user_id },
  } = getState();
  filter.org_id = org.uuid;
  filter.req_payload = filter.req_payload ? filter.req_payload : {};
  filter.req_payload.org_id = org.uuid;
  filter.req_payload.user_id = [user_id];
  filter.sort = filter.sort ? filter.sort : [{ column: 'id', asc: false }];
  return service({
    method: 'POST',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/userdownload/filter`,
    data: filter,
  });
};

export const generateUserDownloadReport = downloadId => {
  const {
    user: { org },
  } = getState();

  return service({
    method: 'GET',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/userdownload/generatereport/${downloadId}`,
    data: {},
  });
};

export const getWidgets = filter => {
  const {
    user: { org, user_id },
  } = getState();
  filter.org_id = org.uuid;
  filter.req_payload = filter.req_payload ? filter.req_payload : {};
  filter.req_payload.org_id = org.uuid;
  filter.req_payload.user_id = [user_id];
  filter.req_payload.deleted = [false];
  filter.sort = filter.sort ? filter.sort : [{ column: 'id', asc: false }];
  return service({
    method: 'POST',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/widgets/filter`,
    data: filter,
  });
};

export const retriggerOrderSrcPendingTaskEvents = order_src_id => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'PUT',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrc/job/pendingtasks/retrigger/${order_src_id}?user_id=${user_id}`,
    data: {},
  });
};

export const executeWidgetQuery = widgetQueryInput => {
  const {
    user: { org, user_id, branch },
  } = getState();
  widgetQueryInput.org_id = org.uuid;
  widgetQueryInput.user_id = user_id;
  widgetQueryInput.req_payload.org_id = org.uuid;
  if (!widgetQueryInput.req_payload.widget_parameters) {
    widgetQueryInput.req_payload.widget_parameters = {};
  }
  if (!widgetQueryInput.req_payload.widget_parameters.filter) {
    widgetQueryInput.req_payload.widget_parameters.filter = {};
  }
  widgetQueryInput.req_payload.widget_parameters.filter.branch_id =
    branch && branch.branch_id ? [branch.branch_id] : [];
  return service({
    method: 'POST',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/widgets/executequery?user_id=${user_id}`,
    data: widgetQueryInput,
  });
};
export const getReportConfigs = report_id => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/userconfig/filter`,
    data: {
      sort: [
        {
          column: 'id',
          asc: false,
        },
      ],
      req_payload: {
        deleted: [false],
        report_id: [report_id],
      },
    },
  });
};

export const getReportConfigByCode = (report_id, code) => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'GET',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/userconfig/code/${report_id}/${code}`,
    data: {},
  });
};

export const updateReportConfig = payload => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/userconfig/update`,
    data: {
      org_id: org.uuid,
      deleted: false,
      approved: true,
      active: true,
      req_payload: payload,
    },
  });
};

export const deleteReportUserConfig = (reportId, code) => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'DELETE',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/userconfig/code/${reportId}/${code}`,
    data: {
      req_payload: {},
    },
  });
};

export const getReportTemplates = filterReq => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-report-service/v1/naadi/report/${org.uuid}/template/filter`,
    data: filterReq,
  });
};
export const newSubscribe = reqData => {
  const {
    user: { org, user_id },
  } = getState();
  // const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const req = {
    req_payload: reqData,
    org_user_id: user_id,
  };

  return service({
    method: 'POST',
    url: `/naadi-org-service2/v1/naadi/org2/${org.uuid}/alertsubscription/create`,
    data: req,
  });
};
export const subscribeFilter = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-org-service2/v1/naadi/org2/${org.uuid}/alertsubscription/filter`,
    data: req,
  });
};
export const deleteSubscribe = dltUuid => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'DELETE',
    url: `/naadi-org-service2/v1/naadi/org2/${org.uuid}/alertsubscription/id/${dltUuid}`,
    data: {},
  });
};
