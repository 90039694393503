import { createReducer, createAction } from '@reduxjs/toolkit';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('REJECT_IMAGES_STATE', type, verb);

export const TYPES = {
  CURRENT_STATE: 'CURRENT_STATE',
  COMMENT: 'COMMENT',
};
export const setCurrentState = createAction(
  getActionName(TYPES.CURRENT_STATE, 'CURRENT_STATE'),
);
export const setComment = createAction(getActionName(TYPES.COMMENT, 'COMMENT'));

export const DEFAULT = {
  currentState: [],
  comment: '',
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setCurrentState, (state, { payload }) => {
    state.currentState = payload;
  });
  builder.addCase(setComment, (state, { payload }) => {
    state.comment = payload;
  });
});

export default reducer;
