import React from 'react';

const LinkedOrderIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='17'
      fill='none'
      viewBox='0 0 20 17'
    >
      <path
        fill='#001714'
        d='M15.5.759c1.25 0 2.313.437 3.188 1.312S20 4.01 20 5.26c0 1.25-.438 2.312-1.313 3.187l-3.53 3.531c-.876.875-1.938 1.313-3.188 1.313-1.25 0-2.313-.438-3.188-1.313S7.47 10.04 7.47 8.79c0-.687.166-1.365.5-2.031h.062c.521 0 .98.166 1.375.5-.291.479-.437.99-.437 1.531 0 .833.291 1.542.875 2.125.562.563 1.27.844 2.125.844.854 0 1.562-.282 2.125-.844l3.531-3.531a2.893 2.893 0 00.875-2.125c0-.834-.292-1.542-.875-2.125a2.893 2.893 0 00-2.125-.875c-.833 0-1.542.291-2.125.875l-1.594 1.594a4.488 4.488 0 00-1.25-.875l1.781-1.782C13.188 1.196 14.25.76 15.5.76zM6.625 14.384l1.594-1.594c.437.396.854.688 1.25.875l-1.781 1.781c-.875.875-1.938 1.313-3.188 1.313-1.25 0-2.313-.438-3.188-1.313S0 13.51 0 12.26c0-1.25.438-2.313 1.313-3.188l3.53-3.531c.876-.875 1.938-1.312 3.188-1.312 1.25 0 2.313.437 3.188 1.312s1.312 1.938 1.312 3.188c0 .687-.166 1.364-.5 2.03h-.062c-.521 0-.98-.166-1.375-.5.291-.478.437-.989.437-1.53 0-.834-.291-1.542-.875-2.125-.562-.563-1.27-.844-2.125-.844-.854 0-1.562.281-2.125.844l-3.531 3.53A2.893 2.893 0 001.5 12.26c0 .833.292 1.541.875 2.125a2.893 2.893 0 002.125.875c.833 0 1.542-.292 2.125-.875z'
      ></path>
    </svg>
  );
};

const ChildOrderIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='17'
      fill='none'
      viewBox='0 0 20 17'
    >
      <path
        fill='#001714'
        d='M3.25 8.571v.75a.723.723 0 01-.219.532.723.723 0 01-.531.218.723.723 0 01-.531-.218.723.723 0 01-.219-.532V8.26c0-.334.115-.615.344-.844.229-.23.51-.344.844-.344H9.25v-2H8c-.25 0-.49-.093-.719-.281C7.094 4.56 7 4.321 7 4.071v-3c0-.291.094-.531.281-.718A.973.973 0 018 .07h4c.292 0 .531.094.719.282A.974.974 0 0113 1.07v3a.974.974 0 01-.281.719.974.974 0 01-.719.281h-1.25v2h6.313c.333 0 .614.115.843.344.23.23.344.51.344.844V9.32a.723.723 0 01-.219.532.723.723 0 01-.531.218.723.723 0 01-.531-.218.723.723 0 01-.219-.532v-.75h-6v.75a.723.723 0 01-.219.532.723.723 0 01-.531.218.723.723 0 01-.531-.218.723.723 0 01-.219-.532v-.75h-6zm5.25-5h3v-2h-3v2zm10.5 7.5c.292 0 .531.094.719.281a.974.974 0 01.281.72v3a.974.974 0 01-.281.718.974.974 0 01-.719.281h-3a.974.974 0 01-.719-.281.974.974 0 01-.281-.719v-3c0-.291.094-.531.281-.719a.974.974 0 01.719-.28h3zm-.5 3.5v-2h-2v2h2zm-7-3.5c.292 0 .531.094.719.281a.974.974 0 01.281.72v3a.974.974 0 01-.281.718.974.974 0 01-.719.281h-3a.973.973 0 01-.719-.281.973.973 0 01-.281-.719v-3c0-.291.094-.531.281-.719a.973.973 0 01.719-.28h3zm-.5 3.5v-2H9v2h2zm-7-3.5c.292 0 .531.094.719.281a.973.973 0 01.281.72v3a.973.973 0 01-.281.718.973.973 0 01-.719.281H1a.973.973 0 01-.719-.281.973.973 0 01-.281-.719v-3c0-.291.094-.531.281-.719a.973.973 0 01.719-.28h3zm-.5 3.5v-2h-2v2h2z'
      ></path>
    </svg>
  );
};

const BOMIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='17'
      fill='none'
      viewBox='0 0 12 17'
    >
      <path
        fill='#001714'
        d='M2.719 8.04a.723.723 0 01.531-.219c.208 0 .385.073.531.219A.723.723 0 014 8.571a.723.723 0 01-.219.532.723.723 0 01-.531.218.723.723 0 01-.531-.218.723.723 0 01-.219-.532c0-.208.073-.385.219-.531zm0 3a.723.723 0 01.531-.219c.208 0 .385.073.531.219a.724.724 0 01.219.531.723.723 0 01-.219.531.723.723 0 01-.531.22.723.723 0 01-.531-.22.723.723 0 01-.219-.53c0-.209.073-.386.219-.532zM9 11.071c.333 0 .5.167.5.5 0 .334-.167.5-.5.5H5.5c-.333 0-.5-.166-.5-.5 0-.333.167-.5.5-.5H9zm1-9c.542 0 1.01.198 1.406.594.396.396.594.865.594 1.406v10c0 .542-.198 1.01-.594 1.406a1.922 1.922 0 01-1.406.594H2c-.542 0-1.01-.198-1.406-.594A1.922 1.922 0 010 14.071v-10c0-.541.198-1.01.594-1.406A1.922 1.922 0 012 2.071h1.563A2.331 2.331 0 014.405.634C4.865.259 5.396.07 6 .07c.604 0 1.135.188 1.594.563.458.375.74.854.843 1.437H10zM6.531 1.79A.723.723 0 006 1.571a.723.723 0 00-.531.219.723.723 0 00-.219.531c0 .209.073.386.219.532A.723.723 0 006 3.07a.723.723 0 00.531-.218.723.723 0 00.219-.532.723.723 0 00-.219-.531zM10.5 14.071v-10c0-.333-.167-.5-.5-.5h-.563c.042.209.063.375.063.5v.5c0 .334-.167.5-.5.5H3c-.333 0-.5-.166-.5-.5v-.5c0-.125.02-.291.063-.5H2c-.333 0-.5.167-.5.5v10c0 .334.167.5.5.5h8c.333 0 .5-.166.5-.5zm-1.5-6c.333 0 .5.167.5.5 0 .334-.167.5-.5.5H5.5c-.333 0-.5-.166-.5-.5 0-.333.167-.5.5-.5H9z'
      ></path>
    </svg>
  );
};

const AttachmentIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='17'
      fill='none'
      viewBox='0 0 11 17'
    >
      <path
        fill='#001714'
        d='M11 4.071v6.5c0 1.604-.594 2.948-1.781 4.031C8.03 15.707 6.625 16.186 5 16.04c-1.417-.125-2.604-.74-3.563-1.844C.48 13.113 0 11.842 0 10.384V6.82c0-.208.073-.385.219-.531a.723.723 0 01.531-.219c.208 0 .385.073.531.219a.723.723 0 01.219.531v3.594c0 1.042.365 1.98 1.094 2.813.75.833 1.635 1.28 2.656 1.343 1.146.063 2.135-.302 2.969-1.094.854-.791 1.281-1.76 1.281-2.906V4.196c0-.666-.219-1.25-.656-1.75-.438-.52-.98-.812-1.625-.875a2.396 2.396 0 00-1.907.657A2.411 2.411 0 004.5 4.07v6.5c0 .292.115.542.344.75.229.209.5.292.812.25a.873.873 0 00.594-.375c.167-.208.25-.437.25-.687V4.82c0-.208.073-.385.219-.531a.723.723 0 01.531-.219c.208 0 .385.073.531.219A.723.723 0 018 4.821v5.625c0 .667-.219 1.26-.656 1.781-.438.5-.98.782-1.625.844a2.396 2.396 0 01-1.907-.656A2.411 2.411 0 013 10.571V4.228c0-1.042.365-1.98 1.094-2.813C4.844.582 5.729.134 6.75.071 7.896.01 8.885.373 9.719 1.165 10.573 1.957 11 2.925 11 4.071z'
      ></path>
    </svg>
  );
};

const EditIcon = ({ fill = '#001714', onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill={fill}
        d='M15.5 1.531c.333.354.5.771.5 1.25 0 .459-.167.865-.5 1.219l-6.781 6.781c-.25.25-.531.406-.844.469L4.531 12h-.093a.416.416 0 01-.344-.156.469.469 0 01-.094-.375l.75-3.344c.083-.333.24-.615.469-.844L12 .5c.313-.333.719-.5 1.219-.5.5 0 .916.167 1.25.5L15.5 1.531zm-1.063 1.407c.126-.126.126-.24 0-.344l-1.03-1.031c-.042-.042-.105-.063-.188-.063-.084 0-.146.02-.188.063l-6.75 6.75-.062.124-.375 1.75c.187-.041.479-.104.875-.187.396-.104.666-.177.812-.219.042 0 .084-.02.125-.062l6.782-6.781zm-1.718 6.28A.723.723 0 0113.25 9c.208 0 .385.073.531.219A.723.723 0 0114 9.75v4a2.17 2.17 0 01-.656 1.594A2.17 2.17 0 0111.75 16h-9.5a2.17 2.17 0 01-1.594-.656A2.17 2.17 0 010 13.75v-9.5c0-.625.219-1.156.656-1.594A2.17 2.17 0 012.25 2h4.5c.208 0 .385.073.531.219a.723.723 0 01.219.531.723.723 0 01-.219.531.723.723 0 01-.531.219h-4.5a.723.723 0 00-.531.219.723.723 0 00-.219.531v9.5c0 .208.073.385.219.531a.723.723 0 00.531.219h9.5a.723.723 0 00.531-.219.723.723 0 00.219-.531v-4c0-.208.073-.385.219-.531z'
      ></path>
    </svg>
  );
};

const DeleteIcon = ({ fill, onClick = () => {} }) => {
  const _fill = fill ? fill : '#001714';
  return (
    <div className='cursor-pointer' onClick={onClick}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='16'
        fill='none'
        viewBox='0 0 14 16'
      >
        <path
          fill={_fill}
          d='M13.25 2.5c.208 0 .385.073.531.219A.723.723 0 0114 3.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H13l-.656 10.594c-.042.396-.209.729-.5 1a1.36 1.36 0 01-1 .406H3.156c-.396 0-.74-.135-1.031-.406a1.61 1.61 0 01-.469-1L1 4H.75a.723.723 0 01-.531-.219A.723.723 0 010 3.25c0-.208.073-.385.219-.531A.723.723 0 01.75 2.5h2.313L4.125.719C4.438.239 4.875 0 5.438 0h3.125c.562 0 1 .24 1.312.719L10.938 2.5h2.312zm-7.813-1l-.625 1h4.375l-.624-1H5.437zm5.407 13L11.5 4h-9l.656 10.5h7.688z'
        ></path>
      </svg>
    </div>
  );
};

const DownloadIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='16'
      fill='none'
      viewBox='0 0 10 16'
    >
      <path
        fill='#001714'
        d='M0 11c0-.208.073-.385.219-.531a.723.723 0 01.531-.219c.208 0 .385.073.531.219l2.969 2.969V.75c0-.208.073-.385.219-.531A.723.723 0 015 0c.208 0 .385.073.531.219A.723.723 0 015.75.75v12.688l2.969-2.97a.723.723 0 01.531-.218c.208 0 .385.073.531.219A.723.723 0 0110 11a.723.723 0 01-.219.531l-4.25 4.25A.723.723 0 015 16a.723.723 0 01-.531-.219l-4.25-4.25A.723.723 0 010 11z'
      ></path>
    </svg>
  );
};

const EditIconLarge = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#001714'
        d='M23.25 2.297c.5.531.75 1.156.75 1.875 0 .687-.25 1.297-.75 1.828L13.078 16.172c-.375.375-.797.61-1.265.703L6.796 18h-.14a.624.624 0 01-.516-.234.703.703 0 01-.141-.563l1.125-5.015c.125-.5.36-.922.703-1.266L18 .75c.469-.5 1.078-.75 1.828-.75s1.375.25 1.875.75l1.547 1.547zm-1.594 2.11c.188-.188.188-.36 0-.516L20.11 2.344c-.062-.063-.156-.094-.28-.094-.126 0-.22.031-.282.094L9.422 12.469l-.094.187-.562 2.625c.28-.062.718-.156 1.312-.281.594-.156 1-.266 1.219-.328.062 0 .125-.031.187-.094L21.656 4.406zm-2.578 9.421c.219-.219.485-.328.797-.328.313 0 .578.11.797.328.219.219.328.485.328.797v6c0 .938-.328 1.734-.984 2.39-.657.657-1.453.985-2.391.985H3.375c-.938 0-1.734-.328-2.39-.984C.327 22.359 0 21.563 0 20.625V6.375c0-.938.328-1.734.984-2.39C1.641 3.327 2.438 3 3.375 3h6.75c.313 0 .578.11.797.328.219.219.328.485.328.797 0 .313-.11.578-.328.797a1.085 1.085 0 01-.797.328h-6.75c-.313 0-.578.11-.797.328a1.085 1.085 0 00-.328.797v14.25c0 .313.11.578.328.797.219.219.485.328.797.328h14.25c.313 0 .578-.11.797-.328.219-.219.328-.485.328-.797v-6c0-.313.11-.578.328-.797z'
      ></path>
    </svg>
  );
};

const CopyIconLarge = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#001714'
        d='M23.578 3.328c.281.281.422.625.422 1.031V15c0 .813-.297 1.516-.89 2.11-.594.593-1.297.89-2.11.89h-9c-.813 0-1.516-.297-2.11-.89C9.298 16.515 9 15.812 9 15V3c0-.813.297-1.516.89-2.11C10.485.298 11.188 0 12 0h7.64c.407 0 .75.14 1.032.422l2.906 2.906zM21.75 15V6H19.5c-.438 0-.797-.14-1.078-.422C18.14 5.297 18 4.938 18 4.5V2.25h-6c-.5 0-.75.25-.75.75v12c0 .5.25.75.75.75h9c.5 0 .75-.25.75-.75zm-9 6v-1.5H15V21c0 .813-.297 1.516-.89 2.11-.594.593-1.297.89-2.11.89H3c-.813 0-1.516-.297-2.11-.89C.298 22.515 0 21.812 0 21V9c0-.813.297-1.516.89-2.11C1.485 6.298 2.188 6 3 6h4.5v2.25H3c-.5 0-.75.25-.75.75v12c0 .5.25.75.75.75h9c.5 0 .75-.25.75-.75z'
      ></path>
    </svg>
  );
};

const KebabIconLarge = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='6'
      height='24'
      fill='none'
      viewBox='0 0 6 24'
    >
      <path
        fill='#001714'
        d='M4.594 4.594A2.17 2.17 0 013 5.25a2.17 2.17 0 01-1.594-.656A2.17 2.17 0 01.75 3c0-.625.219-1.156.656-1.594A2.17 2.17 0 013 .75a2.17 2.17 0 011.594.656c.437.438.656.969.656 1.594a2.17 2.17 0 01-.656 1.594zM1.406 19.406A2.17 2.17 0 013 18.75a2.17 2.17 0 011.594.656c.437.438.656.969.656 1.594a2.17 2.17 0 01-.656 1.594A2.17 2.17 0 013 23.25a2.17 2.17 0 01-1.594-.656A2.17 2.17 0 01.75 21c0-.625.219-1.156.656-1.594zm0-9A2.17 2.17 0 013 9.75a2.17 2.17 0 011.594.656c.437.438.656.969.656 1.594a2.17 2.17 0 01-.656 1.594A2.17 2.17 0 013 14.25a2.17 2.17 0 01-1.594-.656A2.17 2.17 0 01.75 12c0-.625.219-1.156.656-1.594z'
      ></path>
    </svg>
  );
};

const PencilIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      fill='none'
      viewBox='0 0 17 16'
    >
      <path
        fill='#001714'
        d='M15.25.594l1.156 1.156c.396.396.594.875.594 1.438 0 .541-.198 1.01-.594 1.406L6.375 14.625c-.25.25-.563.427-.938.531L1.595 16H1.5a.504.504 0 01-.406-.188.566.566 0 01-.094-.406l.875-3.844a2.09 2.09 0 01.531-.968l10-10A1.922 1.922 0 0113.812 0c.563 0 1.042.198 1.438.594zM5.312 13.563l7.594-7.594-1.875-1.875-7.562 7.562c-.084.084-.125.167-.125.25l-.531 2.281c.208-.041.968-.208 2.28-.5a.67.67 0 00.22-.124z'
      ></path>
    </svg>
  );
};

const CopyIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#001714'
        d='M15.719 2.219a.936.936 0 01.281.687V10c0 .542-.198 1.01-.594 1.406A1.922 1.922 0 0114 12H8c-.542 0-1.01-.198-1.406-.594A1.922 1.922 0 016 10V2c0-.542.198-1.01.594-1.406A1.922 1.922 0 018 0h5.094c.27 0 .5.094.687.281L15.72 2.22zM14.5 10V4H13a.974.974 0 01-.719-.281A.974.974 0 0112 3V1.5H8c-.333 0-.5.167-.5.5v8c0 .333.167.5.5.5h6c.333 0 .5-.167.5-.5zm-6 4v-1H10v1c0 .542-.198 1.01-.594 1.406A1.922 1.922 0 018 16H2c-.542 0-1.01-.198-1.406-.594A1.922 1.922 0 010 14V6c0-.542.198-1.01.594-1.406A1.922 1.922 0 012 4h3v1.5H2c-.333 0-.5.167-.5.5v8c0 .333.167.5.5.5h6c.333 0 .5-.167.5-.5z'
      ></path>
    </svg>
  );
};

const ChevronDownIcon = ({ fill }) => {
  fill = fill ? fill : '#007BFF';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='8'
      fill='none'
      viewBox='0 0 12 8'
    >
      <path fill={fill} d='M1.41.59L6 5.17 10.59.59 12 2 6 8 0 2 1.41.59z' />
    </svg>
  );
};

const MinusCircleIcon = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill={color || '#001714'}
        d='M2.688 2.688C4.146 1.228 5.917.5 8 .5s3.854.73 5.313 2.188C14.77 4.146 15.5 5.917 15.5 8s-.73 3.854-2.188 5.313C11.854 14.77 10.084 15.5 8 15.5s-3.854-.73-5.313-2.188C1.23 11.854.5 10.084.5 8s.73-3.854 2.188-5.313zM3.75 12.25C4.917 13.417 6.333 14 8 14c1.667 0 3.083-.583 4.25-1.75C13.417 11.083 14 9.667 14 8c0-1.667-.583-3.083-1.75-4.25C11.083 2.583 9.667 2 8 2c-1.667 0-3.083.583-4.25 1.75C2.583 4.917 2 6.333 2 8c0 1.667.583 3.083 1.75 4.25zm7.25-5c.208 0 .385.073.531.219A.723.723 0 0111.75 8a.723.723 0 01-.219.531.723.723 0 01-.531.219H5a.723.723 0 01-.531-.219A.723.723 0 014.25 8c0-.208.073-.385.219-.531A.723.723 0 015 7.25h6z'
      ></path>
    </svg>
  );
};

const PlusCircleIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#001714'
        d='M2.688 2.688C4.146 1.228 5.917.5 8 .5s3.854.73 5.313 2.188C14.77 4.146 15.5 5.917 15.5 8s-.73 3.854-2.188 5.313C11.854 14.77 10.084 15.5 8 15.5s-3.854-.73-5.313-2.188C1.23 11.854.5 10.084.5 8s.73-3.854 2.188-5.313zM3.75 12.25C4.917 13.417 6.333 14 8 14c1.667 0 3.083-.583 4.25-1.75C13.417 11.083 14 9.667 14 8c0-1.667-.583-3.083-1.75-4.25C11.083 2.583 9.667 2 8 2c-1.667 0-3.083.583-4.25 1.75C2.583 4.917 2 6.333 2 8c0 1.667.583 3.083 1.75 4.25zm7.25-5c.208 0 .385.073.531.219A.723.723 0 0111.75 8a.723.723 0 01-.219.531.723.723 0 01-.531.219H8.75V11a.723.723 0 01-.219.531.723.723 0 01-.531.219.723.723 0 01-.531-.219A.723.723 0 017.25 11V8.75H5a.723.723 0 01-.531-.219A.723.723 0 014.25 8c0-.208.073-.385.219-.531A.723.723 0 015 7.25h2.25V5c0-.208.073-.385.219-.531A.723.723 0 018 4.25c.208 0 .385.073.531.219A.723.723 0 018.75 5v2.25H11z'
      ></path>
    </svg>
  );
};

const ErrorIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='10'
      fill='none'
      viewBox='0 0 10 10'
    >
      <path
        fill='#C00000'
        d='M5 .563A4.676 4.676 0 00.312 5.25 4.676 4.676 0 005 9.938 4.676 4.676 0 009.688 5.25 4.676 4.676 0 005 .562zm-.469 2.656c0-.254.215-.469.469-.469s.469.215.469.469v2.5A.475.475 0 015 6.187a.475.475 0 01-.469-.468v-2.5zM5 8.063a.609.609 0 01-.605-.606c0-.352.273-.625.605-.625.332 0 .605.273.605.625A.609.609 0 015 8.063z'
      ></path>
    </svg>
  );
};

const ChevronUpIcon = ({ fill }) => {
  fill = fill ? fill : '#027AFF';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='8'
      fill='none'
      viewBox='0 0 14 8'
    >
      <path
        fill={fill}
        d='M.469 6.54l6-5.75A.723.723 0 017 .571c.208 0 .385.073.531.219l6 5.75c.354.354.354.708 0 1.063-.333.333-.687.333-1.062 0L7 2.353l-5.469 5.25c-.375.333-.729.333-1.062 0-.354-.355-.354-.709 0-1.063z'
      ></path>
    </svg>
  );
};

function FileIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='11'
      fill='none'
      viewBox='0 0 14 11'
    >
      <path
        fill='#001714'
        d='M13.68 5.828l-2.25 4.5c-.157.281-.383.422-.68.422h-9c-.406 0-.758-.148-1.055-.445A1.442 1.442 0 01.25 9.25v-7.5c0-.406.148-.758.445-1.055A1.442 1.442 0 011.75.25h2.766c.406 0 .757.148 1.054.445L6.72 1.75H10c.406 0 .758.148 1.055.445.297.297.445.649.445 1.055V4h-1.125v-.75c0-.25-.125-.375-.375-.375H6.25L4.773 1.492a.358.358 0 00-.257-.117H1.75c-.25 0-.375.125-.375.375V8.5l1.664-3.328c.156-.281.383-.422.68-.422H13a.69.69 0 01.633.352.68.68 0 01.047.726z'
      ></path>
    </svg>
  );
}

function FileClosedIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='11'
      fill='none'
      viewBox='0 0 12 11'
    >
      <path
        fill='#001714'
        d='M10.5 1.75c.406 0 .758.148 1.055.445.297.297.445.649.445 1.055v6c0 .406-.148.758-.445 1.055a1.442 1.442 0 01-1.055.445h-9c-.406 0-.758-.148-1.055-.445A1.442 1.442 0 010 9.25v-7.5C0 1.344.148.992.445.695A1.442 1.442 0 011.5.25h2.766c.406 0 .757.148 1.054.445L6.47 1.75H10.5zm-9-.375c-.25 0-.375.125-.375.375V4h9.75v-.75c0-.25-.125-.375-.375-.375H6L4.523 1.492a.358.358 0 00-.257-.117H1.5zm9 8.25c.25 0 .375-.125.375-.375V5.125h-9.75V9.25c0 .25.125.375.375.375h9z'
      ></path>
    </svg>
  );
}

const PlusIcon = ({ fill }) => {
  const _fill = fill ? fill : '#001714';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='11'
      fill='none'
      viewBox='0 0 10 11'
    >
      <path
        fill={_fill}
        d='M9.71 5.102c.11.109.165.242.165.398 0 .156-.055.29-.164.398a.542.542 0 01-.399.165h-3.75v3.75c0 .156-.054.289-.164.398a.542.542 0 01-.398.164.542.542 0 01-.398-.164.542.542 0 01-.165-.399v-3.75H.688a.542.542 0 01-.398-.164.542.542 0 01-.164-.398c0-.156.055-.29.164-.398a.542.542 0 01.399-.165h3.75v-3.75c0-.156.054-.289.164-.398A.542.542 0 015 .625c.156 0 .29.055.398.164.11.11.165.242.165.398v3.75h3.75c.156 0 .289.055.398.165z'
      ></path>
    </svg>
  );
};

export {
  LinkedOrderIcon,
  ChildOrderIcon,
  BOMIcon,
  AttachmentIcon,
  EditIcon,
  DownloadIcon,
  DeleteIcon,
  EditIconLarge,
  CopyIconLarge,
  KebabIconLarge,
  PencilIcon,
  CopyIcon,
  ChevronDownIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  ErrorIcon,
  ChevronUpIcon,
  FileIcon,
  FileClosedIcon,
  PlusIcon,
};
