import React, { Fragment, useEffect, useState } from 'react';
import { calculateDPodStat } from '../../helpers/digitalpod/digitalPodHelper';
import { CutGuideWoCardStat } from '../cutguide/common';
import { StatusComponent } from '../../components/common/status-component';
import { DigitalPodBreadCrumb } from './common';
import { cloneDeep } from 'lodash';

const getDPodShipmentOrders = (dPodResult, shipmentId, pendingItems) => {
  if (
    !dPodResult ||
    !dPodResult.result ||
    dPodResult.result.length === 0 ||
    !shipmentId
  ) {
    return [];
  }
  const shipments = dPodResult.shipments || {};
  const orders = dPodResult.orders;
  const result = dPodResult?.result || [];
  const shipmentOrderMap = {};
  result
    .filter(item => item.shipment_id === shipmentId)
    .forEach(item => {
      if (!shipmentOrderMap[`${item.order_id}`]) {
        shipmentOrderMap[`${item.order_id}`] = {
          orders: { ...orders },
          shipments: { ...shipments },
          result: [],
        };
      }
      shipmentOrderMap[`${item.order_id}`].result.push(cloneDeep(item));
    });

  return Object.keys(shipmentOrderMap)
    .map(orderId => {
      const orderResult = shipmentOrderMap[`${orderId}`];
      const shipment = { ...(dPodResult.shipments[`${shipmentId}`] || {}) };
      const order = { ...(dPodResult.orders[`${orderId}`] || {}) };
      orderResult.shipment = shipment || {
        code: 'UNKNOWN',
      };
      orderResult.order = order || {
        code: 'UNKNOWN',
        ext_code: 'UNKNOWN',
        ref_code: 'UNKNOWN',
      };
      return calculateDPodStat(
        orderResult,
        {
          type: { value: 'SHIPMENT' },
          value: { key: shipmentId, value: shipment.code || shipmentId },
        },
        pendingItems,
      );
    })
    .sort((a, b) => {
      return b.order.ref_code.localeCompare(a.order.ref_code);
    });
};

const DPodShipmentProgress = ({ shipment }) => {
  const [inProgress, setInProgress] = useState(0);
  useEffect(() => {
    const _total = shipment.stat.box_total + shipment.stat.item_total;
    const _inProgress =
      shipment.stat.box_shipped_processed +
      shipment.stat.item_shipped_processed;
    setInProgress(_total === 0 ? 0 : (_inProgress * 100) / _total);
  }, [shipment, setInProgress]);

  const pending = Math.max(0, 100 - inProgress);
  return (
    <div className='d-flex w-100 h-100'>
      <div
        className='h-100 success-background-color'
        style={{ width: inProgress + '%' }}
      ></div>
      <div
        className='h-100 pending-background-color'
        style={{ width: pending + '%' }}
      ></div>
    </div>
  );
};

export const DPodOrderCard = ({ order, onCardClick, onRefresh }) => {
  const [status, setStatus] = useState('PENDING');
  useEffect(() => {
    let s = 'PENDING';
    if (order.stat.total_processed > 0) {
      if (
        order.stat.total_processed > 0 &&
        order.stat.total_processed < order.result.length
      ) {
        s = 'IN_PROGRESS';
      } else if (order.stat.total_processed >= order.result.length) {
        s = 'COMPLETED';
      }
    }
    setStatus(s);
  }, [order, setStatus]);
  return (
    <Fragment>
      <div
        className={`position-relative track-trace-card-wrp overflow-hidden`}
        style={{ animationDuration: '1s', overflow: 'hidden' }}
        onClick={() => onCardClick(order.shipment, order.order)}
      >
        <div className={'track-trace-part-name'}>{order.order.ref_code}</div>
        <div className={'d-flex '}>
          <div className={'track-trace-scanned-on'}></div>
        </div>
        <div className='rubik-font pt-3 pb-1 d-flex'>
          <CutGuideWoCardStat
            statCount={order.stat.box_shipped_processed}
            totalCount={order.stat.box_total}
            label={'BOXES'}
          />
          <CutGuideWoCardStat
            statCount={order.stat.item_shipped_processed}
            totalCount={order.stat.item_total}
            label={'ITEMS'}
          />
        </div>
        <div className='pb-2'>
          <div className='d-flex justify-content-end'>
            <StatusComponent
              statusMap={{
                IN_PROGRESS: 'YELLOW',
                PENDING: 'BLUE_DOT',
                COMPLETED: 'GREEN',
              }}
              statusText={status}
              statusKey={status}
            />
          </div>
        </div>
        <div className='pipeline-progress'>
          <DPodShipmentProgress shipment={order} />{' '}
        </div>
      </div>
    </Fragment>
  );
};
export const DigitalPodOrderListContainer = ({
  dPodResult,
  pendingItems,
  shipmentId,
  modes,
  onSelect,
}) => {
  const [orders, setOrders] = useState(
    getDPodShipmentOrders(dPodResult, shipmentId, pendingItems),
  );
  useEffect(() => {
    setOrders(getDPodShipmentOrders(dPodResult, shipmentId, pendingItems));
  }, [dPodResult, setOrders, pendingItems, shipmentId]);
  return (
    <Fragment>
      <div>
        <DigitalPodBreadCrumb modes={modes} />
      </div>
      <div className='row py-2'>
        {orders.map(order => {
          return (
            <div
              className='col-12 col-md-12 col-lg-6 col-xl-6'
              key={order.order.ref_code}
              style={{ minWidth: '280px' }}
            >
              <div>
                <DPodOrderCard
                  order={order}
                  onCardClick={() => {
                    onSelect(order);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default DigitalPodOrderListContainer;
