import { DEFAULT } from './const';
import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  setCategory,
  setSelection,
  toggleNotification,
  setItemLeftDetail,
  setShowCurrentBoxDetail,
  setItemSyncDate,
  setPackingOperationSyncDate,
  setBoxSyncDate,
  setBoxItemSyncDate,
  resetSelection,
} from './actions';
import { cloneDeep } from 'lodash';
import Update from 'immutability-helper';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('PACKING_STATE', type, verb);

export const TYPES = {
  CURRENT_STATION: 'CURRENT_STATION',
};

export const setPackingCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'SET'),
);

export const setPackingBranchCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'BRANCHSET'),
);

export const resetPackingCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'RESET'),
);
const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetSelection, state => {
    state.category = { label: 'Order', value: 'ORDER' };
    state.selection = null;
    state.notification = false;
    state.itemLeftDetail = false;
    state.showCurrentBoxDetail = false;
    state.itemSyncDate = null;
    state.packingOperationSyncDate = null;
    state.boxSyncDate = null;
    state.boxItemSyncDate = null;
  });
  builder.addCase(setCategory, (state, action) => {
    state.selection = null;
    state.category = action.payload;
  });
  builder.addCase(setSelection, (state, action) => {
    state.selection = action.payload;
  });
  builder.addCase(toggleNotification, (state, action) => {
    state.notification = !state.notification;
  });
  builder.addCase(setItemLeftDetail, (state, action) => {
    state.itemLeftDetail = !state.itemLeftDetail;
  });
  builder.addCase(setShowCurrentBoxDetail, (state, action) => {
    state.showCurrentBoxDetail = !state.showCurrentBoxDetail;
  });
  builder.addCase(setItemSyncDate, (state, action) => {
    state.itemSyncDate = action.payload;
  });
  builder.addCase(setPackingOperationSyncDate, (state, action) => {
    state.packingOperationSyncDate = action.payload;
  });
  builder.addCase(setBoxSyncDate, (state, action) => {
    state.boxSyncDate = action.payload;
  });
  builder.addCase(setBoxItemSyncDate, (state, action) => {
    state.boxItemSyncDate = action.payload;
  });
  builder.addCase(resetPackingCurrentStation, state => {
    const branch_stations = cloneDeep(state.branch_stations);
    const current_station = state.current_station;
    if (current_station && branch_stations[`${current_station.branch_id}`]) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    return Update(state, {
      current_station: { $set: null },
      branch_stations: { $set: branch_stations },
    });
  });
  builder.addCase(setPackingCurrentStation, (state, { payload }) => {
    const branch_stations = cloneDeep(state.branch_stations || {});
    const current_station = state.current_station || {};
    if (
      !payload &&
      current_station &&
      branch_stations[`${current_station.branch_id}`]
    ) {
      delete branch_stations[`${current_station.branch_id}`];
    }

    if (payload && payload.branch_id) {
      branch_stations[`${payload.branch_id}`] = payload;
    }

    return Update(state, {
      current_station: { $set: payload },
      branch_stations: { $set: branch_stations },
    });
  });
  builder.addCase(setPackingBranchCurrentStation, state => {
    const branch_stations = state.branch_stations;
    const user = state.user;
    let current_station = null;
    if (
      user &&
      user.branch &&
      user.branch.branch_id &&
      branch_stations[user.branch.branch_id]
    ) {
      current_station = branch_stations[user.branch.branch_id];
    }
    return Update(state, {
      current_station: { $set: current_station },
    });
  });
});

export default reducer;
