import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

const ScreenModal = () => {
  const { confirmScreen } = useSelector(state => state.screenoverlay);
  if (!confirmScreen) {
    return <Fragment></Fragment>;
  }
  if (!confirmScreen.show) {
    return <Fragment></Fragment>;
  }
  const boundingClass = !confirmScreen.boundingClass
    ? 'naadi-modal-default-bound'
    : confirmScreen.boundingClass;
  const boundingStyle = !confirmScreen.boundingStyle
    ? {}
    : confirmScreen.boundingStyle;
  return (
    <Fragment>
      <div className='naadi-screen-loader confirm-box'>
        <div className={boundingClass} style={boundingStyle}>
          {confirmScreen.content ? confirmScreen.content : <div></div>}
        </div>
      </div>
    </Fragment>
  );
};

ScreenModal.displayName = 'Screen Modal';
export default ScreenModal;
