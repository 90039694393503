/* eslint-disable security/detect-object-injection */
import React, { useState, useEffect, useCallback } from 'react';
import '../../scss/components/_dropdown.scss';
import { useLocation, useHistory } from 'react-router-dom';
import UploadInput from './uploadinput';
import { ToastContainer } from 'react-toastify';
import UploadHeader from './uploadheader';
import SampleFile from './samplefile';
import ScreenLoader from '../../components/common/screen-loader';
import useMobile from '../../hooks/useMobile';
import Mapping from './mapping';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';
import { getItemCode, createUploadFormat } from '../../services/uploadformat';
import { toastError } from '../../helpers/packing/packingHelper';

const AddItemFeatures = () => {
  const [fileExtFocus, setFileExtFocus] = useState(false);
  const [delimiterFocus, setDelimiterFocus] = useState(false);
  const [tableStartFocus, setTableStartFocus] = useState(false);
  const [mapTypeFocus, setMapTypeFocus] = useState(false);
  const [customFocus, setCustomFocus] = useState(false);
  const [fileExtError, setFileExtError] = useState(false);
  const [delimiterError, setDelimiterError] = useState(false);
  const [tableStartError, setTableStartError] = useState(false);
  const [mapTypeError, setMapTypeError] = useState(false);
  const [customError, setCustomError] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemCode, setSelectedItemCode] = useState();
  const [searchText, setSearchText] = useState('');
  const [selMapping, setSelMapping] = useState('COLUMN');
  const [selDelimiter, setSelDelimiter] = useState('');
  const [samplefiles, setSampleFiles] = useState([]);
  const [headerList, setHeaderList] = useState([]);
  const [mappedFields, setMappedFields] = useState({});
  const [errors] = useState({});
  const [selectedFeature, setSelectedFeature] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    name: '',
  });
  const [isMobile, isTab] = useMobile();
  const onChange = useCallback(e => {
    const target = e.target;
    setForm(o => ({ ...o, [target.getAttribute('name')]: target.value }));
  }, []);

  const populateSelectedItem = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async code => {
      const itemBody = {
        req_payload: {
          active: [true],
          deleted: [false],
          code: [code],
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_ITEMLIST'));
        const iList = await getItemCode(itemBody);
        if (iList.status !== 200) {
          toastError('Unable to Fetch Items');
          return null;
        }
        if (iList.payload && iList.payload.length > 0) {
          setSelectedItem(iList.payload[0]);
          const formVal = {};
          Object.values(iList.payload[0].definition).forEach(definition => {
            if (definition.feature_code === 'UPLOAD_FORMAT_EXTN') {
              formVal.fileext = definition.value?.val;
            }
            if (definition.feature_code === 'UPLOAD_FORMAT_TABLE_START_ROW') {
              formVal.tablestart = definition.value?.val;
            }
            if (definition.feature_code === 'UPLOAD_FORMAT_MAPPING_TYPE') {
              formVal.maptype = definition.value?.val;
            }
            if (definition.feature_code === 'UPLOAD_FORMAT_DELIM') {
              formVal.delimiter = definition.value?.val;
            }
            if (definition.feature_code === 'ITEM_PRE_UPLOAD_HOOK') {
              formVal.customhook = definition.value?.val;
            }
            if (definition.feature_code === 'UPLOAD_FORMAT_FIELDS') {
              setMappedFields(definition.value?.val || []);
            }
          });
          setForm(formVal);
        }
      } finally {
        dispatch(actions.busy.remove('FETCH_ITEMLIST'));
      }
    },
    [dispatch, setSelectedItem, setForm, setMappedFields],
  );
  useEffect(() => {
    const search = location.search;
    const urlSearchParams = new URLSearchParams(search);
    const _params = Object.fromEntries(urlSearchParams.entries());
    setSelectedItemCode(_params.item);
    populateSelectedItem(_params.item);
  }, [location, populateSelectedItem]);
  useEffect(() => {
    setSampleFiles(() => {
      return [
        {
          data: null,
          file_name: null,
        },
      ];
    });
  }, [setSampleFiles]);

  const saveChanges = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async e => {
      if (!form || !form.fileext) {
        toastError('Extension is required');
        return;
      }
      if (!form || !form.delimiter) {
        toastError('Delimiter is required');
        return;
      }
      if (!form || !form.tablestart) {
        toastError('Table start row is required');
        return;
      }
      if (!form || !selMapping) {
        toastError('Mapping Type is required');
        return;
      }
      const fieldMap = {};
      if (headerList.length > 0 && selectedFeature.length > 0) {
        let i = 0;
        selectedFeature.map(sf => {
          fieldMap[headerList[i++]] = sf;
          return fieldMap;
        });
      }

      /*  let totSelected = 0;
      for (let i = 0; i < selectedFeature.length; i++) {
        totSelected = totSelected + selectedFeature[i].length;
      }
      if (Object.values(selectedItem.definition).length !== totSelected) {
        toastError('Please Select all features');
        return;
      }*/

      const dataExtn = {
        page: 0,
        size: 1,
        active: true,
        approved: true,
        deleted: false,

        req_payload: {
          org_item_id: selectedItem.uuid,
          feature_code: 'UPLOAD_FORMAT_EXTN',
          value: {
            default_val: 'csv',
            val: form.fileext,
          },
        },
      };
      try {
        dispatch(actions.busy.add('CREATE_UPLOADFORMAT'));
        const createUFRespExtn = await createUploadFormat(dataExtn);
        if (createUFRespExtn.status !== 200) {
          toastError(
            createUFRespExtn.err && createUFRespExtn.err.err
              ? createUFRespExtn.err.err
              : 'Unable to create the Extension',
          );
          return;
        }
        const dataDelim = {
          page: 0,
          size: 1,
          active: true,
          approved: true,
          deleted: false,

          req_payload: {
            org_item_id: selectedItem.uuid,
            feature_code: 'UPLOAD_FORMAT_DELIM',
            value: {
              default_val: '|',
              val: form.delimiter,
            },
          },
        };
        const createUFRespDelimiter = await createUploadFormat(dataDelim);
        if (createUFRespDelimiter.status !== 200) {
          toastError(
            createUFRespDelimiter.err && createUFRespDelimiter.err.err
              ? createUFRespDelimiter.err.err
              : 'Unable to create the Delimiter',
          );
          return;
        }
        const dataStartrow = {
          page: 0,
          size: 1,
          active: true,
          approved: true,
          deleted: false,

          req_payload: {
            org_item_id: selectedItem.uuid,
            feature_code: 'UPLOAD_FORMAT_TABLE_START_ROW',
            value: {
              min: 0,
              max: 10,
              default_val: 1,
              val: Number(form.tablestart),
            },
          },
        };
        const createUFRespStartrow = await createUploadFormat(dataStartrow);
        if (createUFRespStartrow.status !== 200) {
          toastError(
            createUFRespStartrow.err && createUFRespStartrow.err.err
              ? createUFRespStartrow.err.err
              : 'Unable to create the Start Row',
          );
          return;
        }
        const dataMapType = {
          page: 0,
          size: 1,
          active: true,
          approved: true,
          deleted: false,

          req_payload: {
            org_item_id: selectedItem.uuid,
            feature_code: 'UPLOAD_FORMAT_MAPPING_TYPE',
            name: 'Upload Format Mapping Type',
            value: {
              list: ['HEADER', 'COLUMN'],
              default_val: 'HEADER',
              val: form.maptype,
            },
          },
        };
        const createUFRespMaptype = await createUploadFormat(dataMapType);
        if (createUFRespMaptype.status !== 200) {
          toastError(
            createUFRespMaptype.err && createUFRespMaptype.err.err
              ? createUFRespMaptype.err.err
              : 'Unable to create the Map Type',
          );
          return;
        }
        const dataCustomHook = {
          page: 0,
          size: 1,
          active: true,
          approved: true,
          deleted: false,

          req_payload: {
            org_item_id: selectedItem.uuid,
            feature_code: 'ITEM_PRE_UPLOAD_HOOK',
            name: 'Item Pre Upload Hook',
            mandatory: false,
            instance_extendable: true,
            value_type: 'STRING',
            extendable: true,
            visible: false,
            value: {
              val: form.customhook,
            },
          },
        };
        const createUFRespCustomhook = await createUploadFormat(dataCustomHook);
        if (createUFRespCustomhook.status !== 200) {
          toastError(
            createUFRespCustomhook.err && createUFRespCustomhook.err.err
              ? createUFRespCustomhook.err.err
              : 'Unable to create the Custom Hook',
          );
          return;
        }

        const dataFields = {
          page: 0,
          size: 1,
          active: true,
          approved: true,
          deleted: false,

          req_payload: {
            org_item_id: selectedItem.uuid,
            feature_code: 'UPLOAD_FORMAT_FIELDS',
            name: 'Upload Format Fields',
            value: {
              default_val: {},
              val: fieldMap,
            },
          },
        };
        const createUFRespFields = await createUploadFormat(dataFields);
        if (createUFRespFields.status !== 200) {
          toastError(
            createUFRespFields.err && createUFRespFields.err.err
              ? createUFRespFields.err.err
              : 'Unable to create the Fields Mapping',
          );
          return;
        }
      } finally {
        dispatch(actions.busy.remove('CREATE_UPLOADFORMAT'));
      }
    },
    [dispatch, form, selectedItem, selectedFeature, headerList, selMapping],
  );
  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ToastContainer />

      <div>
        <div className='container-fluid position-relative px-0'>
          <UploadHeader />
          <UploadInput
            fileExtFocus={fileExtFocus}
            setFileExtFocus={setFileExtFocus}
            delimiterFocus={delimiterFocus}
            setDelimiterFocus={setDelimiterFocus}
            tableStartFocus={tableStartFocus}
            setTableStartFocus={setTableStartFocus}
            mapTypeFocus={mapTypeFocus}
            setMapTypeFocus={setMapTypeFocus}
            customFocus={customFocus}
            setCustomFocus={setCustomFocus}
            fileExtError={fileExtError}
            setFileExtError={setFileExtError}
            delimiterError={delimiterError}
            setDelimiterError={setDelimiterError}
            tableStartError={tableStartError}
            setTableStartError={setTableStartError}
            mapTypeError={mapTypeError}
            setMapTypeError={setMapTypeError}
            customError={customError}
            setCustomError={setCustomError}
            form={form}
            onChange={onChange}
            errors={errors}
            isMobile={isMobile}
            isTab={isTab}
            selMapping={selMapping}
            setSelMapping={setSelMapping}
            selDelimiter={selDelimiter}
            setSelDelimiter={setSelDelimiter}
            samplefiles={samplefiles}
            selectedItem={selectedItem}
            selectedItemCode={selectedItemCode}
            setSelectedItem={sItem => {
              setSelectedItem(sItem);
            }}
            setSelectedItemCode={setSelectedItemCode}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          <div style={{ height: '22px' }}></div>
          <SampleFile
            isMobile={isMobile}
            onChangeCb={(samplefiles, fileData, fileName) => {
              setSampleFiles(samplefiles => {
                return samplefiles.map(s => {
                  s.data = fileData;
                  s.file_name = fileName;
                  return s;
                });
              });
            }}
            samplefiles={samplefiles}
            headerList={headerList}
            setHeaderList={setHeaderList}
          />
          <Mapping
            isMobile={isMobile}
            isTab={isTab}
            headerList={headerList}
            setHeaderList={setHeaderList}
            selMapping={selMapping}
            selectedItem={selectedItem}
            selectedFeature={selectedFeature}
            setSelectedFeature={setSelectedFeature}
            mappedFields={mappedFields}
          />
          <div className='d-flex'>
            <div className='floating-div'>
              <button
                className='btn btn-outline-primary btn-md btn-save-changes'
                style={{ marginRight: '12px', background: 'white' }}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary btn-save-changes'
                onClick={() => saveChanges()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItemFeatures;
