import { TYPES } from './const';
import { createAction } from '@reduxjs/toolkit';
import {
  syncPackingBoxItems,
  syncOfflineOrderBoxesUpdates,
  syncPackingBox,
  syncOfflineOrderBoxItemUpdates,
} from '../../../helpers/packing/packingOfflineSync';
import { actions } from '@naadi/framework';
import { getState } from '../../';
import { createOrAddNewBox } from '../../../services/packing';
import { getResponseErrorMessage } from '../../../helpers/common/helper';

export const resetItemsList = createAction('RESET_ALL');
export const addItem = createAction('ADD_ITEM');
export const setItems = createAction('SET_ITEMS');
export const setBoxes = createAction(TYPES.SET_BOXES);
export const addItemBox = createAction('ADD_ITEM_BOX');
export const fetchBoxes = createAction(TYPES.FETCH_BOXES);
export const fetchBoxesRestore = createAction('FETCH_BOXES_RESTORE');
export const fetchBoxItems = createAction(TYPES.FETCH_BOX_ITEMS);
export const fetchBoxItemsRestore = createAction('FETCH_BOX_ITEMS_RESTORE');
export const addBoxItemAsync = createAction(TYPES.ADD_BOX_ITEM);
export const removeBoxItem = createAction('REMOVE_BOX_ITEM');
export const syncBoxItem = createAction('SYNC_BOX_ITEM');
export const removeBoxItemHistory = createAction('REMOVE_BOX_ITEM_HISTORY');
export const addBoxAsync = createAction(TYPES.ADD_BOX);
export const deleteBox = createAction('DELETE_BOX');

export const addBoxItem = payload => dispatch => {
  const boxItem = payload.boxItem;
  dispatch(addBoxItemAsync(payload));
  const match = getState().itemslist.fetchBoxItems.find(
    val => val.uuid === boxItem.uuid,
  );
  if (payload.skipPosting === true) return;
  if (match && match.synced === false) return;
  syncPackingBoxItems(dispatch);
};

export const addBox = (box, showLoader) => async dispatch => {
  try {
    if (showLoader) {
      dispatch(actions.busy.add('ADD_BOX'));
      await syncOfflineOrderBoxesUpdates(dispatch);
    }
    //await syncOfflineOrderBoxesUpdates(dispatch);
    dispatch(addBoxAsync(box));
    if (showLoader) {
      await syncPackingBox(dispatch, box);
      await syncPackingBoxItems(dispatch);
      await syncOfflineOrderBoxItemUpdates(dispatch);
    }
  } finally {
    if (showLoader) dispatch(actions.busy.remove('ADD_BOX'));
  }
};

export const addBoxSync = async (box, dispatch) => {
  try {
    dispatch(actions.busy.add('ADD_BOX'));
    const boxResp = await createOrAddNewBox(box);
    if (boxResp.status !== 200) {
      throw Error(getResponseErrorMessage(boxResp, 'Unable to Add Box'));
    }
    const boxPayload = boxResp.payload;
    boxPayload.synced = true;
    dispatch(addBoxAsync(boxPayload));
    return boxPayload;
  } finally {
    dispatch(actions.busy.remove('ADD_BOX'));
  }
};
