import React, { memo, useState } from 'react';

import useMobile from '../../../hooks/useMobile';
import StatusBox from '../../../components/common/status-box';
import { SelectContext } from '../../../components/common/select-context';
import { useSelector } from 'react-redux';
import Status from '../../../components/packing/common/status';
const WoMatProgress = memo(({ onClick, woMat }) => {
  return (
    <div className='w-100 pb-2'>
      {woMat && woMat.uuid && (
        <Status
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          label={''}
          completed={woMat.processed_board_qty}
          total={woMat.total_board_qty}
        />
      )}
    </div>
  );
});

const WoPatternSubHeader = ({
  onCtxTypeSelect,
  onCtxValueSelect,
  ctx,
  woResult,
  woMat,
}) => {
  const [isMobile] = useMobile();
  const [ctxTypes] = useState(['MAT']);
  const { busy } = useSelector(state => state.tracktracestateless);
  return (
    <div
      className={`row sub-header  mx-1 pb-4 bg-white`}
      style={{ minHeight: '140px' }}
    >
      <div className='col-sm-5 col-xs-12 col-lg-6 p-0 d-inline-block'>
        <div className={isMobile ? 'pe-5' : ''}>
          <div
            className='order-select ms-4 position-relative d-flex align-content-center flex-column'
            style={{ zIndex: 9 }}
          >
            <SelectContext
              types={ctxTypes}
              onCtxTypeSelect={onCtxTypeSelect}
              onCtxValueSelect={onCtxValueSelect}
              ctxType={ctx ? ctx.type : null}
              ctxValue={ctx ? ctx.value : null}
              showBusy={busy && busy.length > 0}
              getOptions={async term => {
                if (!woResult || !woResult.wo_optimization_materials) {
                  return [];
                }
                return woResult.wo_optimization_materials
                  .filter(val => {
                    return (
                      !term ||
                      term.length === 0 ||
                      val.material.toLowerCase().indexOf(term.toLowerCase())
                    );
                  })
                  .map(val => {
                    return {
                      key: val.uuid,
                      label: val.material,
                      org_id: val.org_id,
                    };
                  });
              }}
            />
          </div>
        </div>
      </div>
      <div className='col-sm-7 col-lg-6 col-xs-12 p-0 d-inline-block '>
        <div className='px-32'>
          <div className='d-flex w-100  pb-2'>
            <WoMatProgress label={'Progress'} onClick={null} woMat={woMat} />
          </div>
          <div className='d-flex justify-content-start'>
            <div className='track-trace-status-box-wrapper'>
              <StatusBox
                onClick={null}
                count={
                  woMat.id
                    ? woMat.total_board_qty - woMat.processed_board_qty
                    : 0
                }
                label={'BOARDS PENDING'}
                color={'blue'}
              ></StatusBox>
            </div>
            <div className='track-trace-status-box-wrapper'>
              <StatusBox
                onClick={null}
                count={woMat.id ? woMat.total_qty - woMat.processed_qty : 0}
                label={'PANELS PENDING'}
                color={'blue'}
              ></StatusBox>
            </div>
            <div className='track-trace-status-box-wrapper'>
              <StatusBox
                onClick={null}
                count={woMat.rejected_qty || 0}
                label={'PANELS REJECTED'}
                color={'red'}
              ></StatusBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
WoPatternSubHeader.propTypes = {};
export default WoPatternSubHeader;
