import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import Header from '../wolist/header';

import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import WoPatternSubHeader from './wo-pattern-sub-header';
import WoPatternListContainer from './wo-pattern-list';
const MATERIALS = 'MATERIALS';
const MAT = 'MAT';
const MATERIALS_BASE_URL = '/app/cutguide/materials/';
const CutGuidePatterns = ({ wo, woResult, matId, displayTemplate }) => {
  const history = useHistory();
  const location = useLocation();
  const [woMat, setWoMat] = useState({});

  const [ctx, setCtx] = useState({
    type: {
      label: MATERIALS,
      value: MAT,
    },
    value: null,
  });
  const { current_station } = useSelector(state => state.cgstationstate);

  useEffect(() => {
    if (!woResult || !woResult.wo_optimization_materials || !matId) {
      setWoMat({});
      return;
    }
    const woMatList = woResult.wo_optimization_materials.filter(
      val => val.uuid === matId,
    );
    if (!woMatList || woMatList.length === 0) {
      setWoMat({});
      return;
    }
    const woMat = woMatList[0];
    if (!woMat.patterns) {
      woMat.patterns = [];
    }
    woMat.patterns.sort((a, b) => {
      return a.pattern_index - b.pattern_index;
    });
    setWoMat(woMat);
  }, [matId, woResult, setWoMat]);
  useEffect(() => {
    if (!woMat.id) {
      setCtx({
        type: {
          label: MATERIALS,
          value: MAT,
        },
        value: null,
      });
    } else {
      setCtx({
        type: {
          label: MATERIALS,
          value: MAT,
        },
        value: {
          label: woMat.material,
          value: woMat.uuid,
        },
      });
    }
  }, [woMat]);
  return (
    <Fragment>
      <div className='h-100 overflow-scroll'>
        <Header
          location={location}
          history={history}
          onBack={() => {
            if (wo && wo.uuid) {
              history.push(MATERIALS_BASE_URL + wo.org_id + '/' + wo.uuid);
            } else {
              history.push('/app/cutguide/home');
            }
          }}
          label={'Patterns'}
        />
        <WoPatternSubHeader
          woMat={woMat}
          currentStation={current_station}
          onCtxTypeSelect={() => {
            setCtx({
              type: {
                label: MATERIALS,
                value: MAT,
              },
              value: null,
            });
          }}
          onCtxValueSelect={ctxVal => {
            if (wo && wo.uuid) {
              history.push(
                MATERIALS_BASE_URL +
                  wo.org_id +
                  '/' +
                  wo.uuid +
                  '/pattern/' +
                  ctxVal.key,
              );
            }
          }}
          ctx={ctx}
          woResult={woResult}
        />
        <div className='px-4'>
          <WoPatternListContainer
            wo={wo}
            woMat={woMat}
            onCardClick={pattern => {
              if (pattern && pattern.uuid) {
                history.push(
                  MATERIALS_BASE_URL +
                    wo.org_id +
                    '/' +
                    wo.uuid +
                    '/pattern/' +
                    woMat.uuid +
                    '/' +
                    pattern.uuid,
                );
              }
            }}
            woPatterns={woMat.patterns || []}
            optimization={woResult}
            displayTemplate={displayTemplate}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CutGuidePatterns;

CutGuidePatterns.propTypes = {
  mode: PropTypes.string,
};
