import { createAction, createReducer } from '@reduxjs/toolkit';
import Update from 'immutability-helper';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}
const getActionName = (type, verb) => {
  return actionTypeName('WO_ORDER_STATE', type, verb);
};

export const TYPES = {
  CUT_SMART_PATTERN_TEMPLATE: 'CUT_SMART_PATTERN_TEMPLATE',
};

export const setCutSmartPatternTemplate = createAction(
  getActionName(TYPES.CUT_SMART_PATTERN_TEMPLATE, 'SET'),
);
export const DEFAULT = {
  cut_smart_pattern_template: null,
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setCutSmartPatternTemplate, (state, { payload }) => {
    return Update(state, {
      cut_smart_pattern_template: {
        $set: payload,
      },
    });
  });
});

export default reducer;
