import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('DIGITALPOD_STATE', type, verb);

export const TYPES = {
  DIGITALPOD: 'DIGITALPOD',
};
export const DEFAULT = {
  printer: null,
};

export const setDPodPrinter = createAction(
  getActionName(TYPES.DIGITALPOD, 'SET_PRINTER'),
);

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setDPodPrinter, (state, { payload }) => {
    return Update(state, {
      printer: { $set: payload },
    });
  });
});

export default reducer;
