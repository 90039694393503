import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeScreen } from '../../store/app/screenoverlay/actions';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const ScreenOverlay = ({ module }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { screens } = useSelector(state => state.screenoverlay);
  const goBack = useCallback(() => {
    try {
      if (screens.length > 0) {
        const screen = screens[screens.length - 1];
        if (typeof screen.closeCb === 'function') {
          screen.closeCb();
        }
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(closeScreen());
  }, [dispatch, screens]);
  useEffect(() => {
    const pathname = location?.pathname;
    if (!pathname.startsWith('/app/' + module)) {
      dispatch(closeScreen());
    }
  }, [location, dispatch, module]);
  const renderScreen = useCallback(
    (currentScreen, index) => {
      const renderStyle = {
        display: 'none',
      };
      if (index + 1 === screens.length) {
        renderStyle['display'] = 'block';
      }
      return (
        <div key={index} style={renderStyle}>
          <div className='screen-overlay-header-row'>
            <div
              style={{ paddingRight: '12px' }}
              className={'cursor-pointer'}
              onClick={goBack}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15 19L16.41 17.59L11.83 13L10.8294 12.0357L11.83 11L16.42 6.41L15 5L8 12L15 19Z'
                  fill='black'
                />
              </svg>
            </div>
            <div className='cursor-pointer' onClick={goBack}>
              {currentScreen.header}
            </div>
          </div>
          <div className='screen-overlay-content'>{currentScreen.screen}</div>
        </div>
      );
    },
    [goBack, screens],
  );

  //const currentScreen = screens[screens.length - 1];

  return (
    <Fragment>
      {screens.map((screen, index) => renderScreen(screen, index))}
    </Fragment>
  );
};
ScreenOverlay.displayName = 'Screen Overlay';
ScreenOverlay.propTypes = {
  module: PropTypes.string.isRequired,
};

export default ScreenOverlay;
