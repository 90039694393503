import { useState, useLayoutEffect, useRef } from 'react';

export const useMobile = () => {
  const [mobile, setMobile] = useState(false);
  const [tab, setTab] = useState(false);
  const [tabLandscape, setTabLandscape] = useState(false);
  const [lg, setLg] = useState(false);
  const resizeState = useRef({ default: false });
  useLayoutEffect(() => {
    new ResizeObserver(() => {
      if (resizeState.current.default) {
        return;
      }
      resizeState.current.default = true;
      setMobile(window.innerWidth < 576);
      setTab(window.innerWidth < 768);
      setTabLandscape(window.innerWidth < 992);
      setLg(window.innerWidth < 1200);
      resizeState.current.default = false;
    }).observe(document.body);
  }, []);

  return [mobile, tab, tabLandscape, lg];
};
export const useXl = () => {
  const [large, setLarge] = useState(false);
  const resizeState = useRef({ default: false });
  useLayoutEffect(() => {
    new ResizeObserver(() => {
      if (resizeState.current.default) {
        return;
      }
      resizeState.current.default = true;
      setLarge(window.innerWidth > 1200);
      resizeState.current.default = false;
    }).observe(document.body);
  }, []);
  return [large];
};
export default useMobile;
