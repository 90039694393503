import service from './index';
import { getState } from '../store';

export const createRules = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operationrules/update`,
    data: req,
  });
};

export const getRules = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operationrules/filter`,
    data: req,
  });
};

export const getRuleValue = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operationrulevalue/filter`,
    data: req,
  });
};

export const createRuleValue = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operationrulevalue/update`,
    data: req,
  });
};
export const deleteRuleValue = tid => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'DELETE',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operationrulevalue/id/${tid}`,
  });
};

export const deleteRule = tid => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'DELETE',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operationrules/id/${tid}`,
  });
};
