import { useDispatch, useSelector } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { setGroupByFilter } from '../../../store/tracktrace/tracktrace';
import { getOrderItemSelectedFieldValues } from '../../../helpers/tracktrace/orderItemHelper';
const ChevronDownIcon = memo(() => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='8'
      fill='none'
      viewBox='0 0 12 8'
    >
      <path fill='#007BFF' d='M1.41.59L6 5.17 10.59.59 12 2 6 8 0 2 1.41.59z' />
    </svg>
  );
});
const GroupFilterDropdown = memo(({ order_items }) => {
  const dispatch = useDispatch();
  const [is_dropdown_open, setDropdownState] = useState(false);
  const { group_by } = useSelector(state => state.tracktrace);
  const [filters, setFilters] = useState([]);
  useEffect(() => {
    const filterMap = {};
    order_items.map(val => {
      const fields = getOrderItemSelectedFieldValues(val);
      fields.map(field => {
        if (!filterMap[`${field.key}`]) {
          filterMap[`${field.key}`] = field;
        }
        return field;
      });
      return val;
    });
    const _filters = Object.keys(filterMap).map(field => {
      return filterMap[`${field}`];
    });
    setFilters(_filters);
  }, [order_items, setFilters]);
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display ${
          is_dropdown_open ? 'active' : ''
        }`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );

  const onDropdownSelect = data => {
    dispatch(setGroupByFilter(data));
  };

  const onDropdownToggle = status => {
    setDropdownState(status);
  };

  return (
    <div className='d-flex'>
      <div id='track-trace-group-filter-wrp' style={{ maxWidth: '300px' }}>
        <Dropdown
          id='track-trace-group-filter'
          className='page-size-selector'
          onToggle={onDropdownToggle}
          drop={'right'}
          flip={'false'}
        >
          <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
            <span className={`btn-label text-primary pe-2 `}>
              {group_by && group_by.key ? group_by.label : 'Select'}
            </span>
            <ChevronDownIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu align={{ sm: 'right' }}>
            {filters.map(filter => (
              <Dropdown.Item
                onClick={e => onDropdownSelect(filter)}
                key={filter.key}
                eventKey={filter.key}
                active={group_by && filter.key === group_by.key}
                className='track-trace-grp-dropdown-item'
              >
                {filter.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
});

GroupFilterDropdown.propTypes = {};

export default GroupFilterDropdown;
