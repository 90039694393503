import React, { Fragment } from 'react';
import '../../scss/components/_dropdown.scss';
import IconMenu from '../../assets/icons/icon-menu.svg';
import { toggleSideMenu } from '../../store/app/nav/actions';
import TemplateImage from '../../assets/img/template-img.svg';
import ScreenLoader from '../../components/common/screen-loader';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { FaAngleDown } from 'react-icons/fa';
const TXT_COL = 'rgba(50, 50, 50, 0.4)';

const BotsTypeMenu = () => {
  const history = useHistory();
  return (
    <Menu
      className='menuItems'
      style={{
        padding: 0,
        background: '#ffffff',
        border: '1px solid #c0c0c0',
        boxSizing: 'borderBox',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
        borderRadius: '8px',
      }}
    >
      <Menu.Item
        key='0'
        onClick={() => history.push(`/app/bot/box?type=DESIGN_TOOL`)}
      >
        DESIGN_TOOL
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='1'
        onClick={() => history.push(`/app/bot/box?type=SCAN_CLIENT`)}
      >
        PRINTER
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='2'
        onClick={() => history.push(`/app/bot/box?type=CNC_CLIENT`)}
      >
        CNC
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='3'
        onClick={() => history.push(`/app/bot/box?type=PRINT_INTERCEPT_CLIENT`)}
      >
        BEAM SAW
      </Menu.Item>
    </Menu>
  );
};

const UserInputListHeader = () => {
  const dispatch = useDispatch();
  const handleMenuToggle = () => {
    dispatch(toggleSideMenu());
  };
  return (
    <Fragment>
      <div
        className='flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 '
        style={{ zIndex: 10, width: '100%' }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          type='button'
        >
          <img
            src={IconMenu}
            alt='Menu'
            onClick={() => handleMenuToggle()}
          ></img>
        </button>
        <div className='ms-2 page-header'>
          <div className='page-title d-flex align-items-center'>
            <div className='ps-2'>Naadi Bots</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ImageDisplay = () => {
  return (
    <Fragment>
      <div style={{ textAlign: 'center' }}>
        <img src={TemplateImage} alt=''></img>
      </div>
      <div style={{ height: '24px' }}></div>
      <div
        style={{
          font: 'Roboto',
          fontSize: '16px',
          color: TXT_COL,
          background: 'white',
          textAlign: 'center',
        }}
      >
        Select a Bot type to begin
      </div>
    </Fragment>
  );
};

const Bots = () => {
  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ToastContainer />
      <div>
        <div className='container-fluid position-relative px-0'>
          <UserInputListHeader />

          <Fragment>
            <div style={{ background: 'white', paddingLeft: '20px' }}>
              <div className='row' style={{ width: '288px' }}>
                <div className='col-1'>
                  <div
                    style={{
                      height: '64px',
                      width: '4px',
                      background: '#008A50',
                    }}
                  ></div>
                </div>

                <div className='col-11' style={{ padding: '0px' }}>
                  <div style={{ height: '10px' }}></div>
                  <Dropdown
                    overlay={() => {
                      return <BotsTypeMenu />;
                    }}
                    trigger='click'
                  >
                    <div className='row' style={{ cursor: 'pointer' }}>
                      <div
                        className='col-11'
                        style={{
                          font: 'Roboto',
                          fontWeight: '400px',
                          fontSize: '24px',
                          color: '#006EED',
                        }}
                      >
                        Select Bots Type
                      </div>

                      <div
                        className='col-1'
                        style={{ color: '#323232', fontSize: '16px' }}
                      >
                        <FaAngleDown />
                      </div>
                    </div>
                  </Dropdown>
                  <div style={{ height: '8px' }}></div>
                  <div
                    style={{
                      font: 'Roboto',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      color: 'rgba(50, 50, 50, 0.4)',
                    }}
                  >
                    BOT TYPE
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
          <div style={{ height: '210px', background: 'white' }}></div>

          <ImageDisplay />
        </div>
      </div>
    </div>
  );
};

export default Bots;
