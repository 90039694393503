import React, { Fragment, useEffect, useState } from 'react';
import {
  calculateDPodStat,
  NO_SHIPMENT,
} from '../../helpers/digitalpod/digitalPodHelper';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { CutGuideWoCardStat } from '../cutguide/common';
import { StatusComponent } from '../../components/common/status-component';

const getDPodShipments = (dPodResult, pendingSyncItems) => {
  if (!dPodResult || !dPodResult.result || dPodResult.result.length === 0) {
    return [];
  }
  const shipments = dPodResult.shipments || {};
  const orders = dPodResult.orders;
  const result = dPodResult?.result || [];
  const shipmentMap = {};
  result.forEach(item => {
    if (!shipmentMap[`${item.shipment_id}`]) {
      shipmentMap[`${item.shipment_id}`] = {
        orders: orders,
        shipments: { ...shipments },
        result: [],
      };
    }
    shipmentMap[`${item.shipment_id}`].result.push(cloneDeep(item));
  });

  return Object.keys(shipmentMap)
    .map(shipmentId => {
      const shipmentResult = shipmentMap[`${shipmentId}`];
      const shipment = { ...(dPodResult.shipments[`${shipmentId}`] || {}) };
      shipmentResult.shipment = shipment || {
        code: 'UNKNOWN',
      };
      return calculateDPodStat(
        shipmentResult,
        {
          type: { value: 'SHIPMENT' },
          value: { key: shipmentId, value: shipment.code || shipmentId },
        },
        pendingSyncItems,
      );
    })
    .sort((a, b) => {
      if (a.shipment.code === b.shipment.code) {
        return 0;
      }
      if (a.shipment.code === NO_SHIPMENT) {
        return 1;
      } else if (b.shipment.code === NO_SHIPMENT) {
        return -1;
      }
      return b.shipment.code.localeCompare(a.shipment.code);
    });
};

const DPodShipmentProgress = ({ shipment }) => {
  const [inProgress, setInProgress] = useState(0);
  useEffect(() => {
    const _total = shipment.stat.box_total + shipment.stat.item_total;
    const _inProgress =
      shipment.stat.box_processed + shipment.stat.item_processed;
    setInProgress(_total === 0 ? 0 : (_inProgress * 100) / _total);
  }, [shipment, setInProgress]);

  const pending = Math.max(0, 100 - inProgress);
  return (
    <div className='d-flex w-100 h-100'>
      <div
        className='h-100 success-background-color'
        style={{ width: inProgress + '%' }}
      ></div>
      <div
        className='h-100 pending-background-color'
        style={{ width: pending + '%' }}
      ></div>
    </div>
  );
};

export const DPodShipmentCard = ({ shipment, onCardClick, onRefresh }) => {
  const [shipmentStatus, setShipmentStatus] = useState('PENDING');
  useEffect(() => {
    let s = 'PENDING';
    if (shipment.stat.total_processed > 0) {
      if (
        shipment.stat.total_processed > 0 &&
        shipment.stat.total_processed < shipment.result.length
      ) {
        s = 'IN_PROGRESS';
      } else if (shipment.stat.total_processed >= shipment.result.length) {
        s = 'COMPLETED';
      }
    }
    setShipmentStatus(s);
  }, [shipment, setShipmentStatus]);
  return (
    <Fragment>
      <div
        className={`position-relative track-trace-card-wrp overflow-hidden`}
        style={{ animationDuration: '1s', overflow: 'hidden' }}
        onClick={onCardClick}
      >
        <div className={'track-trace-part-name'}>{shipment.shipment.code}</div>
        <div className={'d-flex '}>
          <div className={'track-trace-scanned-on'}>
            {shipment.shipment.created_on
              ? moment(shipment.shipment.created_on).format('DD MMM hh:mm a')
              : '-'}
          </div>
        </div>
        <div className='rubik-font pt-3 pb-1 d-flex'>
          <CutGuideWoCardStat
            statCount={shipment.stat.box_processed}
            totalCount={shipment.stat.box_total}
            label={'BOXES'}
          />
          <CutGuideWoCardStat
            statCount={shipment.stat.item_direct_processed}
            totalCount={shipment.stat.item_direct_shipped}
            label={'ITEMS'}
          />
          <CutGuideWoCardStat
            statCount={shipment.stat.item_processed}
            totalCount={shipment.stat.item_total}
            label={'ITEM TOTAL'}
          />
        </div>
        <div className='pb-2'>
          <div className='d-flex justify-content-end'>
            <StatusComponent
              statusMap={{
                IN_PROGRESS: 'YELLOW',
                PENDING: 'BLUE_DOT',
                COMPLETED: 'GREEN',
              }}
              statusText={shipmentStatus}
              statusKey={shipmentStatus}
            />
          </div>
        </div>
        <div className='pipeline-progress'>
          <DPodShipmentProgress shipment={shipment} />{' '}
        </div>
      </div>
    </Fragment>
  );
};
export const ShipmentListContainer = ({
  dPodResult,
  pendingItems,
  history,
  currentStation,
  onSelect,
  modes,
}) => {
  const [shipments, setShipments] = useState(
    getDPodShipments(dPodResult, pendingItems),
  );
  useEffect(() => {
    setShipments(getDPodShipments(dPodResult, pendingItems));
  }, [dPodResult, pendingItems, setShipments]);
  return (
    <Fragment>
      <div className='row py-2'>
        {shipments.map(shipment => {
          return (
            <div
              className='col-12 col-md-12 col-lg-6 col-xl-6'
              key={shipment.shipment.uuid}
              style={{ minWidth: '280px' }}
            >
              <DPodShipmentCard
                shipment={shipment}
                onCardClick={() => {
                  onSelect(shipment);
                }}
                onRefresh={() => {}}
              />
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default ShipmentListContainer;
