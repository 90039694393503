import { DEFAULT } from './const';
import Update from 'immutability-helper';
import { createReducer } from '@reduxjs/toolkit';
import { setLabelPrintRecords, setPageFilterParams } from './actions';

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setLabelPrintRecords, (state, action) => {
    state.label_print_records = action.payload;
  });
  builder.addCase(setPageFilterParams, (state, { payload }) => {
    return Update(state, {
      filterParams: { $merge: payload },
    });
  });
});
export default reducer;
