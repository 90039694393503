import service from './index';
import { getState } from '../store';

export const createNewBomAttachment = fileName => {
  const {
    user: { org, user_id },
  } = getState();
  let payload = {
    active: true,
    deleted: true,
    approved: true,
    user_id: user_id,
    org_id: org.uuid,
    req_payload: {
      file_name: fileName || 'Order Src.csv',
      org_id: org.uuid,
      folder: 'orders/bom',
      content_type: 'application/octet-stream',
      sign_request: true,
    },
  };
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-attachment-service/v1/naadi/attachment/${org.uuid}/attachments/create`,
    data: payload,
  });
};

export const createOrderUploadAttachment = (fileName, orderName) => {
  const {
    user: { org, user_id },
  } = getState();
  let payload = {
    active: true,
    deleted: true,
    approved: true,
    user_id: user_id,
    org_id: org.uuid,
    req_payload: {
      file_name: fileName || orderName + '.zip',
      org_id: org.uuid,
      folder: 'orders/bulk/orderupload',
      content_type: 'application/zip',
      sign_request: true,
    },
  };
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-attachment-service/v1/naadi/attachment/${org.uuid}/attachments/create`,
    data: payload,
  });
};

export const createBulkImportAttachment = fileName => {
  const {
    user: { org, user_id },
  } = getState();
  let payload = {
    active: true,
    deleted: true,
    approved: true,
    user_id: user_id,
    org_id: org.uuid,
    req_payload: {
      file_name: fileName,
      org_id: org.uuid,
      folder: 'orders/import/orderupload',
      content_type: 'application/zip',
      sign_request: true,
    },
  };
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-attachment-service/v1/naadi/attachment/${org.uuid}/attachments/create`,
    data: payload,
  });
};

export const expandOrderSrcService = order_src_id => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrc/expand/upload`,
    data: {
      org_id: org.uuid,
      user_id: user_id,
      req_payload: {
        uuid: order_src_id,
      },
    },
  });
};
export const addOrderSrcService = (
  name,
  order_id,
  order_src_id,
  order_src_type,
  attachment_id,
  group_by_cols,
  column_headers,
) => {
  const {
    user: { org, user_id },
  } = getState();
  const payload = {
    active: true,
    deleted: true,
    approved: true,
    user_id: user_id,
    org_id: org.uuid,
    req_payload: {
      uuid: order_src_id,
      org_id: org.uuid,
      name: name,
      order_id: order_id,
      order_src_type: order_src_type,
      released: false,
      item_count: 0,
      attachment_id: attachment_id,
      packing_group_fields: group_by_cols,
      column_headers: column_headers,
    },
  };
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrc/create?trigger_job=false`,
    data: payload,
  });
};

export const getOrdersBomDetails = (order_id, fetch_status) => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ordersrc/filter`,
    data: {
      user_id,
      page: 0,
      size: 100,
      org_id: org.uuid,
      req_payload: {
        org_id: org.uuid,
        uuid: [],
        deleted: [false],
        order_id: [order_id],
        fetch_status,
      },
    },
  });
};

export const getBomDetailsBomId = bomId => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ordersrc/filter`,
    data: {
      user_id,
      page: 0,
      size: 100,
      org_id: org.uuid,
      req_payload: {
        org_id: org.uuid,
        uuid: [bomId],
        deleted: [false],
      },
    },
  });
};

export const getOrderAttachments = order_id => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/attachment/filter`,
    data: {
      user_id,
      page: 0,
      size: 100,
      org_id: org.uuid,
      req_payload: {
        org_id: org.uuid,
        deleted: [false],
        order_id: [order_id],
      },
    },
  });
};

export const getBomDetails = (bom_id, order_id, fetch_status) => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ordersrc/deserialize/${bom_id}`,
    data: {
      user_id,
      page: 0,
      size: 100,
      org_id: org.uuid,
      req_payload: {
        org_id: org.uuid,
        order_id: [order_id],
        uuid: [bom_id],
        deleted: [false],
      },
    },
  });
};
