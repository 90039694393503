import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
import { cloneDeep } from 'lodash';
export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('TRACKTRACE_STATION_STATE', type, verb);

export const TYPES = {
  CURRENT_STATION: 'CURRENT_STATION',
  BRANCH_STATIONS: 'BRANCH_STATIONS',
  BRANCH_CURRENT_STATION: 'BRANCH_CURRENT_STATION',
  STATION_CTX: 'STATION_CTX',
  REJECT_MODE: 'REJECT_MODE',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  UPDATE_CACHE: 'UPDATE_CACHE',
  ORDER_ITEM_SYNC: 'ORDER_ITEM_SYNC',
};

export const resetStationState = createAction(getActionName('RESET', 'RESET'));
export const setCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'SET'),
);

export const resetCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'RESET'),
);

export const setBranchStations = createAction(
  getActionName(TYPES.BRANCH_STATIONS, 'SET'),
);

export const setBranchCurrentStation = createAction(
  getActionName(TYPES.BRANCH_CURRENT_STATION, 'SET'),
);

export const setStationCtxType = createAction(
  getActionName(TYPES.STATION_CTX, 'TYPESET'),
);
export const setStationCtxValue = createAction(
  getActionName(TYPES.STATION_CTX, 'VALSET'),
);

export const toggleRejectMode = createAction(
  getActionName(TYPES.REJECT_MODE, 'TOGGLE'),
);
export const setRejectMode = createAction(
  getActionName(TYPES.REJECT_MODE, 'SET'),
);

export const addImageLinks = createAction(
  getActionName(TYPES.IMAGE_LINKS, 'MULTI_MERGE'),
);
export const addImageLink = createAction(
  getActionName(TYPES.IMAGE_LINKS, 'MERGE'),
);
export const updateStationLocation = createAction(
  getActionName(TYPES.UPDATE_LOCATION, 'MERGE'),
);
export const updateStationCache = createAction(
  getActionName(TYPES.UPDATE_CACHE, 'SET'),
);
export const setOrderItemSyncCtxPersist = createAction(
  getActionName(TYPES.ORDER_ITEM_SYNC, 'CTX_SET'),
);

export const DEFAULT = {
  current_station: null,
  branch_stations: {},
  rejectmode: false,
  ctx: {
    type: { label: 'Order', value: 'ORDER' },
    value: {},
  },
  image_links: {},
  station_location: {},
  station_cache: [],
  order_item_sync_ctx: null,
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetStationState, state => {
    return Update(state, {
      current_station: { $set: null },
      branch_stations: { $set: {} },
      rejectmode: { $set: false },
      ctx: {
        $set: {
          type: { label: 'Order', value: 'ORDER' },
          value: {},
        },
      },
      image_links: { $set: {} },
      station_location: { $set: {} },
      station_cache: { $set: [] },
      order_item_sync_ctx: { $set: null },
    });
  });
  builder.addCase(resetCurrentStation, state => {
    const branch_stations = cloneDeep(state.branch_stations);
    const current_station = state.current_station;
    if (current_station && branch_stations[`${current_station.branch_id}`]) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    return Update(state, {
      current_station: { $set: null },
      branch_stations: { $set: branch_stations },
    });
  });
  builder.addCase(setCurrentStation, (state, { payload }) => {
    const branch_stations = cloneDeep(state.branch_stations);
    let ctx = cloneDeep(state.ctx) || {};
    const current_station = state.current_station;
    if (
      !payload &&
      current_station &&
      branch_stations[`${current_station.branch_id}`]
    ) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    if (
      current_station &&
      ctx &&
      ctx.value &&
      ctx.value.branch_id !== current_station.branch_id
    ) {
      ctx = {
        type: { label: 'Order', value: 'ORDER' },
        value: {},
      };
    }
    if (payload && payload.branch_id) {
      branch_stations[`${payload.branch_id}`] = payload;
    }

    return Update(state, {
      current_station: { $set: payload },
      branch_stations: { $set: branch_stations },
      ctx: { $set: ctx },
    });
  });

  builder.addCase(setBranchCurrentStation, state => {
    const branch_stations = state.branch_stations;
    const user = state.user;
    let current_station = null;
    if (
      user &&
      user.branch &&
      user.branch.branch_id &&
      branch_stations[user.branch.branch_id]
    ) {
      current_station = branch_stations[user.branch.branch_id];
    }
    return Update(state, {
      current_station: { $set: current_station },
    });
  });
  builder.addCase(setStationCtxType, (state, { payload }) => {
    const _payload =
      payload && payload.label && payload.value
        ? payload
        : { label: 'Order', value: 'ORDER' };
    const _ctx = cloneDeep(state.ctx);
    if (
      !_payload ||
      !_payload.value ||
      (_ctx.type && _ctx.type.value !== _payload.value)
    ) {
      _ctx.value = {};
    }
    _ctx.type = _payload;
    return Update(state, {
      ctx: {
        $set: _ctx,
      },
    });
  });
  builder.addCase(setStationCtxValue, (state, { payload }) => {
    const _ctx = cloneDeep(state.ctx);
    _ctx.value = payload;
    return Update(state, {
      ctx: {
        $set: _ctx,
      },
    });
  });
  builder.addCase(toggleRejectMode, state => {
    const rejectmode = state.rejectmode === true;
    return Update(state, {
      rejectmode: {
        $set: !rejectmode,
      },
    });
  });
  builder.addCase(setRejectMode, (state, { payload }) => {
    return Update(state, {
      rejectmode: {
        $set: payload === true,
      },
    });
  });
  builder.addCase(addImageLink, (state, { payload }) => {
    const image_links = cloneDeep(state.image_links || {});
    for (let key in image_links) {
      if (!image_links[`${key}`]) continue;
      const imageObj = image_links[`${key}`];
      const diff = new Date().getTime() - imageObj.time;
      if (diff > 1000 * 60 * 60 * 24 * 3) {
        delete image_links[`${key}`];
      }
    }
    image_links[`${payload.attachment_id}`] = {
      attachment_id: payload.attachment_id,
      link: payload.link,
      time: new Date().getTime(),
    };
    return Update(state, {
      image_links: { $set: image_links },
    });
  });
  builder.addCase(addImageLinks, (state, { payload }) => {
    const image_links = cloneDeep(state.image_links || {});
    for (let key in image_links) {
      if (!image_links[`${key}`]) continue;
      const imageObj = image_links[`${key}`];
      const diff = new Date().getTime() - imageObj.time;
      if (diff > 1000 * 60 * 60 * 24 * 3) {
        delete image_links[`${key}`];
      }
    }
    for (let i = 0; i < payload.length; i++) {
      const image = payload[`${i}`];
      image_links[`${image.attachment_id}`] = {
        attachment_id: image.attachment_id,
        link: image.link,
        time: new Date().getTime(),
      };
    }

    return Update(state, {
      image_links: { $set: image_links },
    });
  });
  builder.addCase(updateStationLocation, (state, { payload }) => {
    return Update(state, {
      station_location: { $merge: payload },
    });
  });
  builder.addCase(updateStationCache, (state, { payload }) => {
    const sC = cloneDeep(state.station_cache || []);
    let match = null;
    for (let i = 0; i < sC.length; i++) {
      const cache = sC[`${i}`];
      if (cache.key === payload.key) {
        match = cache;
      }
    }
    if (match === null) {
      match = { ...payload };
      sC.push(match);
    }
    match.order_items = payload.order_items || [];
    match.order_items_list = payload.order_items_list || [];
    if (sC.length > 10) {
      sC.pop();
    }
    return Update(state, {
      station_cache: { $set: sC },
    });
  });
  builder.addCase(setOrderItemSyncCtxPersist, (state, { payload }) => {
    return Update(state, {
      order_item_sync_ctx: { $set: payload },
    });
  });
});

export default reducer;
