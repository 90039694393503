import { DEFAULT } from './const';
import { createReducer } from '@reduxjs/toolkit';
import { popUuid, setScanId, addUuid } from './actions';
const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setScanId, (state, action) => {
    state.scanId = action.payload;
  });
  builder.addCase(popUuid, (state, action) => {
    const index = state.uuidList.indexOf(action.payload);
    if (index >= 0) {
      state.uuidList.splice(index, 1);
    }
  });
  builder.addCase(addUuid, (state, action) => {
    state.uuidList.push(...action.payload);
  });
});

export default reducer;
