import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

export function TableLink(props) {
  const { text, url, is_bold } = props;
  return (
    <Link to={url} className={is_bold ? 'bold-link' : 'link'}>
      {text}
    </Link>
  );
}

TableLink.propTypes = {
  text: PropTypes.any,
  url: PropTypes.string,
  is_bold: PropTypes.bool,
};

export function TableDateCell(props) {
  const { value } = props;
  return <div>{format(new Date(value), 'dd-MM-yyyy')}</div>;
}

TableDateCell.propTypes = {
  value: PropTypes.string,
};
