import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DropDown from 'react-overlays/Dropdown';
import { useDropdownToggle, useDropdownMenu } from 'react-overlays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faSyncAlt,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import useDebounce from '../../../hooks/useDebounce';
import { ExpandLess, ExpandMore } from '../../../assets/icons/nav-icons';
import { cloneDeep, isEqual } from 'lodash';
import { toastResponseError } from '../../../helpers/common/helper';
const GroupSelect = ({
  props,
  label,
  getGroupOptions,
  groupLabelField,
  groupValueField,
  labelField,
  valueField,
  onGroupSelect,
  groupSelected,
  onSelect,
  selected,
  search,
  show,
  onToggle,
  drop,
  className,
  ...rest
}) => {
  // eslint-disable-next-line
  const [options, setOptions] = useState([]);

  const [groupOptions, setGroupOptions] = useState([]);
  // eslint-disable-next-line
  const [busy, setBusy] = useState(false);
  const loadGroupOptions = useCallback(() => {
    setBusy(true);
    getGroupOptions()
      .then(o => setGroupOptions(o))
      .finally(() => setBusy(false));
  }, [getGroupOptions]);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    loadGroupOptions();
  }, []);

  /* eslint-enable react-hooks/exhaustive-deps */
  return (
    <div className={className}>
      <DropDown show={show} onToggle={onToggle} drop={drop}>
        <div className='position-relative'>
          <Toggle busy={busy}>
            {selected && selected[labelField.toString()]
              ? selected[labelField.toString()]
              : label}
          </Toggle>
          <GroupOptions
            groupOptions={groupOptions}
            onGroupSelect={onGroupSelect}
            onSelect={onSelect}
            groupSelected={groupSelected}
            groupLabelField={groupLabelField}
            groupValueField={groupValueField}
            labelField={labelField}
            valueField={valueField}
            busy={busy}
            selected={selected}
          />
        </div>
      </DropDown>
    </div>
  );
};

GroupSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  getGroupOptions: PropTypes.func.isRequired,
  groupLabelField: PropTypes.string.isRequired,
  groupValueField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  groupSelected: PropTypes.object,
  selected: PropTypes.object,
  search: PropTypes.bool,
  className: PropTypes.string,
  show: PropTypes.bool,
  onToggle: PropTypes.func,
  drop: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

const Toggle = ({ id, busy, children }) => {
  const [props, { toggle }] = useDropdownToggle();
  return (
    <div id={id} {...props} onClick={toggle}>
      <div className='d-flex'>
        <div className='select-toggle'>{children}</div>
        <FontAwesomeIcon
          icon={busy ? faSyncAlt : faChevronDown}
          className='ms-2 d-print-none'
          spin={busy}
        />
      </div>
    </div>
  );
};

Toggle.propTypes = {
  id: PropTypes.string,
  busy: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

const GroupOptions = ({
  groupOptions,
  groupLabelField,
  groupValueField,
  onGroupSelect,
  onSelect,
  //onSearch,
  labelField,
  valueField,
  selected,
  groupSelected,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [menuProps, { show, toggle }] = useDropdownMenu({
    flip: 'true',
    offset: [0, 0],
  });
  const input = useRef(null);
  const [search, setSearch] = useState('');
  const [selectedGroup, setSelectedGroup] = useState({});
  const [groupValueOptions, setGroupValueOptions] = useState({});
  const [busy] = useState(false);
  const term = useDebounce(search);
  const [visibleReports, setVisibleReports] = useState('');
  useEffect(() => {
    if (show && input.current) {
      input.current.focus();
    }
  }, [show]);

  const handleGroupSelect = useCallback(
    async group => {
      const _selectedGroup = cloneDeep(selectedGroup);
      if (group) {
        const groupVal = group[`${groupValueField}`];
        if (selectedGroup[`${groupVal}`]) {
          _selectedGroup[`${groupVal}`] = !selectedGroup[`${groupVal}`];
        } else {
          _selectedGroup[`${groupVal}`] = true;
        }
      }

      const _groups = [];
      const _groupValueOptions = cloneDeep(groupValueOptions);

      for (let key in _selectedGroup) {
        if (_selectedGroup[`${key}`] === true) {
          _groups.push(`${key}`);
          _groupValueOptions[`${key}`] = [];
        }
      }

      if (_groups.length > 0) {
        const groupResult = await onGroupSelect(_groups, term);
        groupResult.forEach(val => {
          const _groupVal = val[`${groupValueField}`];
          if (_groupValueOptions[`${_groupVal}`]) {
            _groupValueOptions[`${_groupVal}`].push(val);
          }
        });

        if (!isEqual(_groupValueOptions, groupValueOptions)) {
          const filteredGroupValueOptions = Object.fromEntries(
            _groups.map(group => [
              group,
              _groupValueOptions[`${group}`].filter(
                item => item.visible === true,
              ),
            ]),
          );
          setGroupValueOptions(filteredGroupValueOptions);
        }
      }
      setSelectedGroup(_selectedGroup);
    },
    [groupValueField, onGroupSelect, selectedGroup, groupValueOptions, term],
  );

  useEffect(() => {
    const report = async () => {
      try {
        const _groups = groupOptions.map(o => o.report_type);
        const groupResult = await onGroupSelect(_groups, term);

        const visible = groupResult.filter(e => e.visible === true);

        const visibleReports = groupOptions.filter(o =>
          visible.some(v => v.report_type === o.report_type),
        );
        setVisibleReports(visibleReports);
      } catch (error) {
        toastResponseError('Error fetching group result:', error);
      }
    };
    report();
  }, [groupOptions, onGroupSelect, term]);
  /* eslint-enable react-hooks/exhaustive-deps */
  /*useEffect(() => {
    if (!show) return;
    if (!selectedGroup || !selectedGroup.report_type) {
      handleGroupSelect(groupSelected);
    }
  }, [show, selectedGroup]);*/

  return (
    <div {...menuProps}>
      <ul className='list-group list-group-group shadow bg-white border'>
        {onGroupSelect && (
          <li className='list-group-item list-group-item-input'>
            <input
              placeholder='Search ..'
              className='form-control form-control-sm w-auto m-2 rounded-pill'
              ref={input}
              onChange={e => setSearch(e.target.value)}
            />
          </li>
        )}
        {busy ? (
          <li className='list-group-item list-group-item-action cursor-pointer border-right-0 border-left-0 muted'>
            <FontAwesomeIcon icon={faSyncAlt} spin className='me-2' /> Loading
            ..
          </li>
        ) : visibleReports.length > 0 ? (
          visibleReports.map((o, i) => {
            return (
              <li
                key={`select-group-menu-${i}`}
                className={`list-group-select-option list-group-item list-group-item-action cursor-pointer border-right-0 border-left-0`}
                onClick={() => handleGroupSelect(o)}
              >
                <div>
                  <div>{o[groupLabelField.toString()]}</div>
                  <div className='group-select-dropdown'>
                    <div>
                      {selectedGroup[o[`${groupValueField}`]] &&
                        selectedGroup[o[`${groupValueField}`]] === true && (
                          <ExpandLess />
                        )}
                      {(!selectedGroup[o[`${groupValueField}`]] ||
                        selectedGroup[o[`${groupValueField}`]] === false) && (
                        <ExpandMore />
                      )}
                    </div>
                  </div>
                </div>
                <div onClick={e => e.stopPropagation()}>
                  {selectedGroup[o[`${groupValueField}`]] &&
                    selectedGroup[o[`${groupValueField}`]] === true && (
                      <Options
                        toggle={toggle}
                        options={groupValueOptions[o[`${groupValueField}`]]}
                        onSelect={onSelect}
                        labelField={labelField}
                        valueField={valueField}
                        selected={selected}
                      />
                    )}
                </div>
              </li>
            );
          })
        ) : (
          <li className='list-group-item list-group-item-action cursor-pointer border-right-0 border-left-0 muted'>
            <FontAwesomeIcon icon={faExclamationTriangle} className='me-2' />
            No Data ..
          </li>
        )}
      </ul>
    </div>
  );
};

GroupOptions.propTypes = {
  //getOptions: PropTypes.func.isRequired,
  groupOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  //onSearch: PropTypes.func,
  groupLabelField: PropTypes.string.isRequired,
  groupValueField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object,
  groupSelected: PropTypes.object,
  busy: PropTypes.bool,
};

const Options = ({
  options,
  labelField,
  valueField,
  onSelect,
  selected,
  toggle,
  busy,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const onSelected = useCallback(
    o => {
      onSelect(o);
      toggle();
    },
    [onSelect, toggle],
  );
  return (
    <div>
      <ul className='list-group list-group-group-item'>
        {busy ? (
          <div></div>
        ) : options && options.length > 0 ? (
          options.map((o, i) => {
            let isActive = false;
            if (
              selected &&
              selected[valueField.toString()] === o[valueField.toString()]
            ) {
              isActive = true;
            }
            return (
              <li
                key={`select-menu-${i}`}
                className={`list-group-item list-group-item-action cursor-pointer border-0${
                  isActive ? ' selected' : ''
                }`}
                onClick={() => onSelected(o)}
              >
                {o[labelField.toString()]}
              </li>
            );
          })
        ) : (
          <li className='list-group-item list-group-item-action cursor-pointer border-right-0 border-left-0 muted'>
            <FontAwesomeIcon icon={faExclamationTriangle} className='me-2' />
            No Data ..
          </li>
        )}
      </ul>
    </div>
  );
};

Options.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  labelField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.object,
  //onSearch: PropTypes.func,
  busy: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

export default GroupSelect;
