import { DEFAULT } from './const';
import { createReducer } from '@reduxjs/toolkit';
import { toggleSideMenu, closeSideMenu, openSideMenu } from './actions';

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(toggleSideMenu, (state, action) => {
    state.sideMenuToggled = !state.sideMenuToggled;
  });
  builder.addCase(closeSideMenu, (state, action) => {
    state.sideMenuToggled = false;
  });
  builder.addCase(openSideMenu, (state, action) => {
    state.sideMenuToggled = true;
  });
});

export default reducer;
