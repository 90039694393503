/* eslint-disable security/detect-object-injection */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import '../../scss/components/_dropdown.scss';
import { useHistory, useLocation } from 'react-router-dom';
import SearchIcon from '../../assets/icons/searchIcon.svg';
import { ToastContainer } from 'react-toastify';
import ScreenLoader from '../../components/common/screen-loader';
import useMobile from '../../hooks/useMobile';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';
import { cloneDeep } from 'lodash';
import {
  createWorkstation,
  fetchWorkstations,
  deleteWorkstation,
} from '../../services/workstation';
import {
  createWorkstationLink,
  fetchWorkstationLink,
  deleteOperationLink,
} from '../../services/operation';
import { fetchStations, getLinkOperation } from '../../services/station';
import { toastError, toastMessage } from '../../helpers/packing/packingHelper';
import WorkStationHeader from './workstationheader';
import Select from '../../components/operations/select-upload';
import BottomDrawerStations from './bottom-drawer-stations';
import { DeleteIcon } from '../../assets/icons';
import BottomDrawerContent from './bottom-drawer-menu';
import { ItemMenu } from '../workflowrules/item-menu';
import { FaAngleDown } from 'react-icons/fa';
import BottomDrawerItems from '../downloadformat/bottom-drawer-items';
import BlackClose from '../../assets/icons/black-close.svg';
import { getItemList } from '../../services/items';
import { getState } from '../../store';
import { ResponseCodes } from '@naadi/framework';
import {
  closeConfirmScreen,
  displayConfirmScreen,
} from '../../store/app/screenoverlay/actions';
import NaadiConfirmScreen from '../../components/common/naadi-confirm';
import ScreenModal from '../../components/common/screen-modal';
const Class_RECT = 'rectangle-input';
const Class_RECT_Highlight = 'rectangle-input highlight';
const Class_RECT_Error = 'rectangle-input error';

const getItemFilterCb = term => {
  return new Promise((resolve, reject) => {
    const itemListReq = {
      active: [true],
      deleted: [false],
      latest: [true],
      parent_item_code: ['GOODS'],
    };

    if (term) itemListReq.term = term.toUpperCase();
    getItemList(itemListReq, 0, 10).then(val => {
      if (val.status === 200) {
        resolve(val.payload);
      } else {
        reject([]);
      }
    }, reject);
  });
};

const ItemsFilter = ({ labelText, itemsList, setItemsList, itemsDrawer }) => {
  const getOptions = term => {
    if (!term) {
      return Promise.resolve(itemsDrawer);
    }
    return getItemFilterCb(term);
  };
  const handleSelect = i => {
    if (itemsList.indexOf(i.code) === -1) {
      setItemsList([...itemsList, i.code]);
    }
  };
  return (
    <Select
      label={labelText}
      getOptions={getOptions}
      labelField='code'
      valueField='uuid'
      onSelect={handleSelect}
      search
    />
  );
};

const ItemData = (
  searchText,
  setSearchText,
  itemsDrawer,
  setItemOpen,
  itemSelected,
) => {
  return (
    <div>
      <div style={{ fontSize: '20px', textAlign: 'center' }}>SELECT ITEM</div>
      <div style={{ height: '30px' }}></div>
      <div>
        <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
        <input
          type='text'
          id='search_field'
          className='searchModal'
          style={{
            border: '0px !important',
            color: 'rgba(50, 50, 50, 0.4)',
            fontStyle: 'italic',
          }}
          value={searchText}
          onChange={e => {
            setSearchText(e.target.value);
          }}
          placeholder='Type here to search Item'
        />
      </div>
      {itemsDrawer &&
        itemsDrawer.length > 0 &&
        itemsDrawer.map(row => (
          <div key={row.uuid}>
            <div
              key={row.uuid}
              style={{
                fontSize: '14px',
                height: '20px',
                paddingTop: '12px',
                cursor: 'pointer',
                color: '#006EED',
              }}
              onClick={() => {
                itemSelected(row.name);
                setItemOpen(false);
              }}
            >
              {row.name}
            </div>
            <div
              className='operation-checklist-line'
              style={{
                borderColor: '#C4C4C4',
              }}
            ></div>
          </div>
        ))}
    </div>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const NewWorkstation = () => {
  const location = useLocation();
  const [nameFocus, setNameFocus] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [codeFocus, setCodeFocus] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [stationFocus, setStationFocus] = useState(false);
  const [stationError, setStationError] = useState(false);
  const [workstationList, setWorkstationList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, isTab] = useMobile();
  const [workstationUuid, setWorkstationUuid] = useState('');
  const [searchText, setSearchText] = useState('');
  const [titleText, setTitleText] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [operationDrawer, setOperationDrawer] = useState([]);
  const [operationOpen, setOperationOpen] = useState(false);
  const [operationList, setOperationList] = useState([]);
  const [stationList, setStationList] = useState([]);
  const [headerName, setHeaderName] = useState('');
  const [itemsList, setItemsList] = useState([]);
  const [itemsDrawer, setItemsDrawer] = useState([]);
  const [itemOpen, setItemOpen] = useState(false);
  const [codeList, setCodeList] = useState([]);
  useEffect(() => {
    const search = location.search;
    const urlSearchParams = new URLSearchParams(search);
    const _params = Object.fromEntries(urlSearchParams.entries());
    setWorkstationUuid(_params.uuid);
    // setParams(_params);
  }, [location, setWorkstationUuid]);

  const [form, setForm] = useState({
    name: '',
    code: '',
    station: '',
    enforce_access: false,
    enable_item_print: false,
    auto_operation: false,
  });
  const fetchStation = useCallback(
    async oid => {
      const req = {
        page: 0,
        size: 100,
        req_payload: {
          deleted: [false],
          active: [true],
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_STATION'));
        const fetchSt = await fetchStations(req);
        if (fetchSt && fetchSt.status !== 200) {
          return;
        }
        setStationList(fetchSt.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_STATION'));
      }
    },
    [dispatch, setStationList],
  );
  useEffect(() => {
    fetchStation();
  }, [fetchStation]);
  const onCodeUpdate = useCallback(
    value => {
      setForm(prevForm => ({
        ...prevForm,
        code: value,
      }));
    },
    [setForm],
  );
  useEffect(() => {
    setCodeList(
      stationList.map(station => ({
        key: station.station_code,
        label: station.name,
        onClick: () => onCodeUpdate(station.station_code),
      })),
    );
  }, [stationList, setCodeList, onCodeUpdate]);

  const onChange = useCallback(e => {
    const target = e.target;
    setForm(o => ({ ...o, [target.getAttribute('name')]: target.value }));
  }, []);

  const fetchOperations = useCallback(
    async tid => {
      const operationBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (searchText) operationBody.req_payload.term = searchText.toUpperCase();
      try {
        dispatch(actions.busy.add('FETCH_OPERATION'));
        const getOperation = await getLinkOperation(operationBody);

        if (getOperation && getOperation.status !== 200) {
          return;
        }

        setOperationDrawer(getOperation.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_OPERATION'));
      }
    },
    [dispatch, searchText, setOperationDrawer],
  );
  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  const fetchWorkstationOperationLink = useCallback(
    async tid => {
      if (!workstationUuid) return;
      const operationBody = {
        page: 0,
        size: 10,
        active: true,
        deleted: false,
        sort: [{ column: 'id', asc: true }],
        req_payload: {
          station_instance_id: [workstationUuid],
        },
      };

      try {
        dispatch(actions.busy.add('FETCH_LINKWORKSTATION'));
        const getOperation = await fetchWorkstationLink(operationBody);

        if (getOperation && getOperation.status !== 200) {
          return;
        }

        setOperationList(getOperation.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_LINKWORKSTATION'));
      }
    },
    [dispatch, workstationUuid, setOperationList],
  );
  useEffect(() => {
    fetchWorkstationOperationLink();
  }, [fetchWorkstationOperationLink]);

  const delOperationLink = useCallback(
    async oid => {
      try {
        dispatch(actions.busy.add('DELETE_OPERATIONLINK'));
        const deleteDF = await deleteOperationLink(oid);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Delete Operation Link');
        }
      } finally {
        dispatch(actions.busy.remove('DELETE_OPERATIONLINK'));
      }
    },
    [dispatch],
  );

  const itemSelected = useCallback(
    async item => {
      setItemsList(list => {
        if (list.indexOf(item.code) === -1) {
          return [...list, item.code];
        }
        return list;
      });
    },
    [setItemsList],
  );

  const operationData = () => {
    return (
      <div>
        <div style={{ fontSize: '20px', textAlign: 'center' }}>
          LINK AN OPERATION
        </div>
        <div style={{ height: '30px' }}></div>
        <div>
          <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
          <input
            type='text'
            id='search_field'
            className='searchModal'
            style={{
              border: '0px !important',
              color: 'rgba(50, 50, 50, 0.4)',
              fontStyle: 'italic',
            }}
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder='Type here to search'
          />
        </div>
        {operationDrawer &&
          operationDrawer.length > 0 &&
          operationDrawer.map(row => (
            <div key={row.uuid}>
              <div
                key={row.uuid}
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  const list = cloneDeep(operationList);
                  const objWS = {
                    deleted: false,
                    operation: {
                      name: row.name,
                      uuid: row.uuid,
                    },
                  };
                  list.push(objWS);
                  setOperationList(list);
                  setOperationOpen(false);
                }}
              >
                {row.name}
              </div>
              <div
                className='format-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
      </div>
    );
  };

  const fetchWorkstation = useCallback(
    async tid => {
      const req = {
        approved: true,
        active: true,
        deleted: false,
        page: 0,
        size: 2,
        req_payload: {
          deleted: [false],
          active: [true],
        },
      };
      if (workstationUuid) {
        req.req_payload.uuid = [workstationUuid];
      }
      try {
        dispatch(actions.busy.add('FETCH_OPERATION'));
        const fetchOp = await fetchWorkstations(req);
        if (fetchOp && fetchOp.status !== 200) {
          return;
        }

        if (fetchOp && fetchOp.payload.length > 0) {
          setWorkstationList(fetchOp.payload);
          setItemsList(fetchOp.payload[0].supported_item_type || []);
          setForm({
            name: fetchOp.payload[0].instance_name,
            code: fetchOp.payload[0].station.station_code,
            station: fetchOp.payload[0].station_group,
            enforce_access: fetchOp.payload[0].enforce_access,
            enableprint: fetchOp.payload[0].enable_item_print,
            auto_operation: fetchOp.payload[0].auto_operation,
          });
          setHeaderName(fetchOp.payload[0].instance_name);
        }
      } finally {
        dispatch(actions.busy.remove('FETCH_OPERATION'));
      }
    },
    [dispatch, workstationUuid, setWorkstationList, setForm],
  );
  useEffect(() => {
    if (workstationUuid) {
      fetchWorkstation();
    }
  }, [fetchWorkstation, workstationUuid]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const saveChanges = useCallback(async () => {
    if (form.name === '') {
      toastError('Please Enter Workstation Name');
      setNameError(true);
      return;
    }
    if (form.station === '') {
      toastError('Please Enter Station Group');
      setStationError(true);
      return;
    }

    try {
      dispatch(actions.busy.add('CREATE_WORKSTATION'));

      const data = {
        active: true,
        approved: true,
        deleted: false,
        req_payload: {
          instance_name: form.name,
          station_code: form.code,
          station_group: form.station,
          enforce_access: form.enforce_access,
          supported_item_type: itemsList,
          enable_item_print: form.enableprint,
          auto_operation: form.auto_operation,
        },
      };
      if (workstationList && workstationList.length > 0) {
        data.req_payload.uuid = workstationList[0].uuid;
      }
      const createOpRespFields = await createWorkstation(data);
      if (createOpRespFields.status !== 200) {
        toastError(
          createOpRespFields.err && createOpRespFields.err.err
            ? createOpRespFields.err.err
            : 'Unable to create the Workstation',
        );
        return;
      }
      for (let i = 0; i < operationList.length; i++) {
        if (operationList[`${i}`].deleted === true) {
          delOperationLink(operationList[`${i}`].uuid);
        } else {
          const linkdata = {
            active: true,
            approved: true,
            deleted: false,
            req_payload: {
              station_id: createOpRespFields.payload.uuid,
              operation_id: operationList[i].operation.uuid,
            },
          };
          const createLink = await createWorkstationLink(linkdata);
          if (createLink.status !== 200) {
            toastError(
              createLink.err && createLink.err.err
                ? createLink.err.err
                : 'Unable to create the Station Link',
            );
            return;
          }
        }
      }
      toastMessage({
        type: 'success',
        message: 'Workstation Saved',
      });
      //history.goBack();
    } finally {
      dispatch(actions.busy.remove('CREATE_WORKSTATION'));
    }
  }, [
    dispatch,
    form,
    workstationList,
    operationList,
    delOperationLink,
    itemsList,
  ]);

  const deleteWorkstations = useCallback(
    id => {
      const _confirmDeleteWS = async () => {
        const { busy } = getState();
        try {
          if (busy.includes('DELETE_WORKSTATION')) {
            return;
          }

          dispatch(actions.busy.add('DELETE_WORKSTATION'));
          for (let i = 0; i < operationList.length; i++) {
            delOperationLink(operationList[`${i}`].uuid);
          }
          const result = await deleteWorkstation(id);
          if (result.status !== ResponseCodes.SUCCESS) {
            const err =
              result.err && result.err.err
                ? result.err.err
                : 'Unable to Delete the Workstation';
            dispatch(closeConfirmScreen());
            dispatch(
              displayConfirmScreen({
                content: confirmScreen('error', err),
                boundingClass: null,
                boundingStyle: null,
              }),
            );
            return;
          }
          dispatch(closeConfirmScreen());
          fetchWorkstation();
          toastMessage({
            type: 'success',
            message: 'Workstation deleted',
          });
          history.goBack();
          fetchWorkstation();
        } finally {
          dispatch(actions.busy.remove('DELETE_WORKSTATION'));
        }
      };

      const cancelDeleteWS = () => {
        dispatch(closeConfirmScreen());
      };
      const confirmScreen = (state, error) => {
        const secondaryButton = (
          <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
        );
        return (
          <NaadiConfirmScreen
            message={
              <div>Do you really want to delete {form.name} Workstation?</div>
            }
            state={state}
            primaryButton={<div>DELETE</div>}
            primaryAction={_confirmDeleteWS}
            secondaryButton={secondaryButton}
            secondaryAction={cancelDeleteWS}
            error={error}
          />
        );
      };
      dispatch(
        displayConfirmScreen({
          content: confirmScreen('initial', null),
          boundingClass: null,
          boundingStyle: null,
        }),
      );
    },
    [
      dispatch,
      operationList,
      delOperationLink,
      fetchWorkstation,
      history,
      form,
    ],
  );
  const markInactive = useCallback(
    async oid => {
      const req = {
        active: false,
        approved: true,
        deleted: false,
        req_payload: {
          uuid: oid,
        },
      };
      try {
        dispatch(actions.busy.add('MARK_INACTIVE'));
        const deleteDF = await createWorkstation(req);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Mark as Inactive');
        }
        toastMessage({
          type: 'success',
          message: 'Workstation Marked as Inactive',
        });
        history.goBack();
        fetchWorkstation();
      } finally {
        dispatch(actions.busy.remove('MARK_INACTIVE'));
      }
    },
    [dispatch, fetchWorkstation, history],
  );
  const fetchItems = useCallback(
    async tid => {
      try {
        dispatch(actions.busy.add('FETCH_ITEM'));
        const fetchedItems = await getItemFilterCb(searchText?.toUpperCase());
        setItemsDrawer(fetchedItems);
      } catch (err) {
      } finally {
        dispatch(actions.busy.remove('FETCH_ITEM'));
      }
    },
    [dispatch, searchText],
  );
  const itemFetchRef = useRef(false);
  useEffect(() => {
    const fn = async () => {
      try {
        if (itemFetchRef.current === true) {
          return;
        }
        itemFetchRef.current = true;
        await fetchItems();
      } finally {
        itemFetchRef.current = false;
      }
    };
    fn();
  }, [fetchItems]);
  //const getOptions = useCallback(operationsFilter, []);
  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: 'fit-content', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ScreenModal />
      <ToastContainer />

      <BottomDrawerContent
        isMobile={isMobile}
        isTab={isTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        title={titleText}
        delWorkstation={deleteWorkstations}
        markInactive={markInactive}
        id={workstationUuid}
      />
      <BottomDrawerStations
        isMobile={isMobile}
        isTab={isTab}
        itemOpen={operationOpen}
        setItemOpen={setOperationOpen}
        itemData={operationData()}
      />

      <div className='container-fluid position-relative px-0'>
        <WorkStationHeader
          name={headerName}
          isMobile={isMobile}
          isTab={isTab}
          setWorkstationId={setWorkstationUuid}
          titleText={titleText}
          setTitleText={setTitleText}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          workstationUuid={workstationUuid}
          delWorkstation={deleteWorkstations}
          markInactive={markInactive}
        />
        <div style={{ height: '22px' }}></div>
        <Form style={{ padding: '0px' }}>
          <div
            className={
              nameError
                ? Class_RECT_Error
                : nameFocus
                ? Class_RECT_Highlight
                : Class_RECT
            }
            style={{
              margin: '0px',
              padding: '0px',
              marginBottom: '20px',
              marginLeft: '20px',
              paddingLeft: '10px',
            }}
          >
            <div>
              <label className='lbl-input lbl-padding'>Name</label>

              <Form.Control
                id='name'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='name'
                placeholder='Enter name of workstation'
                value={form.name}
                onFocus={() => {
                  setNameFocus(true);
                  setNameError(false);
                }}
                onBlur={() => {
                  setNameFocus(false);
                }}
                onChange={onChange}
              />
            </div>
          </div>
          <div
            className={
              codeError
                ? Class_RECT_Error
                : codeFocus
                ? Class_RECT_Highlight
                : Class_RECT
            }
            style={{
              margin: '0px',
              padding: '0px',
              marginBottom: '20px',
              marginLeft: '20px',
              paddingLeft: '10px',
            }}
          >
            <div>
              <label className='lbl-input lbl-padding'>Code</label>
              <div>
                <ItemMenu
                  actionLabel={
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Form.Control
                        id='code'
                        className='txt-input primary'
                        type='text'
                        autoComplete='off'
                        name='code'
                        placeholder='Enter Code'
                        value={form.code}
                        onFocus={() => {
                          setCodeFocus(true);
                          setCodeError(false);
                        }}
                        onBlur={() => {
                          setCodeFocus(false);
                        }}
                        onChange={onChange}
                        style={{ pointerEvents: 'none' }}
                      />
                      <div style={{ width: '10px' }}></div>
                      <FaAngleDown />
                    </div>
                  }
                  actions={codeList}
                />
              </div>
            </div>
          </div>
          <div
            className={
              stationError
                ? Class_RECT_Error
                : stationFocus
                ? Class_RECT_Highlight
                : Class_RECT
            }
            style={{
              margin: '0px',
              padding: '0px',
              marginBottom: '20px',
              marginLeft: '20px',
              paddingLeft: '10px',
            }}
          >
            <div>
              <label className='lbl-input lbl-padding'>Station Group</label>
              <Form.Control
                id='station'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='station'
                placeholder='Enter Station Group'
                value={form.station}
                onFocus={() => {
                  setStationFocus(true);
                  setStationError(false);
                }}
                onBlur={() => {
                  setStationFocus(false);
                }}
                onChange={onChange}
                style={{ pointerEvents: workstationUuid ? 'none' : '' }}
              />
            </div>
          </div>
        </Form>

        <div
          style={{
            maxWidth: '320px',
            marginLeft: '20px',
            marginTop: '30px',
          }}
        >
          <div className='row'>
            <div className='col-9'>Link Operation</div>
            <div className='col-3' style={{ color: '#006EED' }}>
              {!(isMobile || isTab) && (
                <Select
                  label={'Add+'}
                  getOptions={() => Promise.resolve(operationDrawer)}
                  labelField='name'
                  valueField='uuid'
                  onSelect={i => {
                    const list = cloneDeep(operationList);
                    const objWS = {
                      deleted: false,
                      operation: {
                        name: i.name,
                        uuid: i.uuid,
                      },
                    };
                    list.push(objWS);
                    setOperationList(list);
                  }}
                  search
                />
              )}
              {(isMobile || isTab) && (
                <div onClick={() => setOperationOpen(true)}>{'Add+'}</div>
              )}
            </div>
          </div>
          <div style={{ height: '20px' }}></div>
          {!operationList ||
            (operationList.length === 0 && (
              <div
                style={{
                  height: '30px',
                  display: 'flex',
                  borderBottom: '1px',
                  borderBottomColor: '#DEDEDE',
                  borderBottomStyle: 'outset',
                  fontSize: '12px',
                }}
              >
                <div
                  style={{
                    color: '#006EED',
                    cursor: 'pointer',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderColor: '#C4C4C4',
                    alignItem: 'center',
                  }}
                >
                  {!(isMobile || isTab) && (
                    <Select
                      label={'Add an existing operation'}
                      getOptions={() => Promise.resolve(operationDrawer)}
                      labelField='name'
                      valueField='uuid'
                      onSelect={i => {
                        const list = cloneDeep(operationList);
                        const objWS = {
                          deleted: false,
                          operation: {
                            name: i.name,
                            uuid: i.uuid,
                          },
                        };
                        list.push(objWS);
                        setOperationList(list);
                      }}
                      search
                    />
                  )}
                  {(isMobile || isTab) && (
                    <div onClick={() => setOperationOpen(true)}>
                      Add an existing operation
                    </div>
                  )}
                </div>
              </div>
            ))}
          {operationList &&
            operationList.length > 0 &&
            operationList.map((row, index) => {
              return (
                row.deleted === false && (
                  <div
                    key={row?.uuid || index}
                    style={{
                      paddingBottom: '16px',
                      display: 'flex',
                      alignContent: 'space-between',
                    }}
                  >
                    <div style={{ marginRight: '12px', width: '300px' }}>
                      {row.operation.name}
                    </div>
                    <div>
                      <DeleteIcon
                        onClick={() => {
                          setOperationList(list => {
                            return list.map(item => {
                              if (item.operation.uuid === row.operation.uuid) {
                                return { ...item, deleted: true };
                              }
                              return item;
                            });
                          });
                        }}
                      />
                    </div>
                  </div>
                )
              );
            })}
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#C4C4C4',
            }}
          ></div>
        </div>
        <div style={{ height: '20px' }}></div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
            marginLeft: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '20px' }}>
              Enforce Access
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='enforce_access'
              name='enforce_access'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.enforce_access = !newVal.enforce_access;
                  return newVal;
                });
              }}
              value={true}
              checked={form.enforce_access === true}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
            marginLeft: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '20px' }}>
              Enable Item Print
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='enableprint'
              name='enableprint'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.enableprint = !newVal.enableprint;
                  return newVal;
                });
              }}
              value={true}
              checked={form.enableprint === true}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
            marginLeft: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '20px' }}>
              Auto Operation
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='auto_operation'
              name='auto_operation'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.auto_operation = !newVal.auto_operation;
                  return newVal;
                });
              }}
              value={true}
              checked={form.auto_operation === true}
            />
          </div>
        </div>
        <BottomDrawerItems
          isMobile={isMobile}
          isTab={isTab}
          itemOpen={itemOpen}
          setItemOpen={setItemOpen}
          itemData={ItemData(
            searchText,
            setSearchText,
            itemsDrawer,
            setItemOpen,
            itemSelected,
          )}
        />
        <div
          style={{
            maxWidth: '320px',
            marginLeft: '20px',
            marginTop: '30px',
          }}
        >
          <div className='row'>
            <div className='col-9'>Select Items</div>
            <div className='col-3' style={{ color: '#006EED' }}>
              {!(isMobile || isTab) && (
                <ItemsFilter
                  labelText='Add+'
                  itemsList={itemsList}
                  setItemsList={setItemsList}
                  itemsDrawer={itemsDrawer}
                />
              )}
              {(isMobile || isTab) && (
                <div onClick={() => setItemOpen(true)}>Add+</div>
              )}
            </div>
          </div>
          <div style={{ height: '20px' }}></div>
          {!itemsList ||
            (itemsList.length === 0 && (
              <div
                style={{
                  height: '30px',
                  display: 'flex',
                  borderBottom: '1px',
                  borderBottomColor: '#DEDEDE',
                  borderBottomStyle: 'outset',
                  fontSize: '12px',
                }}
              >
                <div style={{ color: '#006EED', cursor: 'pointer' }}>
                  <ItemsFilter
                    labelText='Select items'
                    itemsList={itemsList}
                    setItemsList={setItemsList}
                    itemsDrawer={itemsDrawer}
                  />
                </div>
                <div>&nbsp; </div>
              </div>
            ))}
          <div>
            <div className='d-flex'>
              {itemsList &&
                itemsList.length > 0 &&
                itemsList.map((row, index) => {
                  return (
                    <div
                      key={row || index}
                      className='filter-btns'
                      style={{
                        border: '1px solid',
                        color: '#027AFF !important',
                        display: 'flex',
                      }}
                    >
                      <div style={{ marginRight: '12px' }}>{row}</div>
                      <div>
                        <img
                          src={BlackClose}
                          alt=''
                          onClick={() => {
                            setItemsList(list => {
                              const newList = cloneDeep(list);
                              newList.splice(index, 1);
                              return newList;
                            });
                          }}
                        ></img>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className='d-flex'>
          <div
            className='floating-div'
            style={{ paddingRight: workstationUuid ? '90px' : '' }}
          >
            <button
              className='btn btn-outline-primary btn-md btn-save-changes'
              style={{ marginRight: '12px', background: 'white' }}
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
            {workstationUuid && (
              <button
                className='btn btn-outline-primary btn-md btn-save-changes'
                style={{
                  marginRight: '12px',
                  background: 'white',
                  borderColor: 'red',
                  color: 'red',
                }}
                onClick={() => deleteWorkstations(workstationUuid)}
              >
                Delete
              </button>
            )}
            <button
              className='btn btn-primary btn-save-changes'
              onClick={() => {
                saveChanges();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewWorkstation;
