import { createAction, createReducer } from '@reduxjs/toolkit';
import Update from 'immutability-helper';

export const DEFAULT = {
  user_dashboards: {},
  current_dashboard: {},
};
export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}
const getActionName = (type, verb) =>
  actionTypeName('USER_DASHBOARD_STATE', type, verb);
export const TYPES = {
  USER_DASHBOARDS: 'USER_DASHBOARDS',
  CURRENT_DASHBOARD: 'CURRENT_DASHBOARD',
};

export const setCurrentDashboard = createAction(
  getActionName(TYPES.CURRENT_DASHBOARD, 'SET'),
);
export const setOrgUserDashboard = createAction(
  getActionName(TYPES.USER_DASHBOARDS, 'MERGE'),
);
export const unsetOrgUserDashboard = createAction(
  getActionName(TYPES.USER_DASHBOARDS, 'UNSET'),
);
export const setOrgUserDashboards = createAction(
  getActionName(TYPES.USER_DASHBOARDS, 'SET'),
);

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setCurrentDashboard, (state, { payload }) => {
    return Update(state, {
      current_dashboard: { $set: payload },
    });
  });
  builder.addCase(setOrgUserDashboard, (state, { payload }) => {
    return Update(state, {
      user_dashboards: { $merge: payload },
    });
  });
  builder.addCase(unsetOrgUserDashboard, (state, { payload }) => {
    return Update(state, {
      user_dashboards: { $unset: payload },
    });
  });
  builder.addCase(setOrgUserDashboards, (state, { payload }) => {
    return Update(state, {
      user_dashboards: { $set: payload },
    });
  });
});

export default reducer;
