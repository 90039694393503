import { getState } from '../store';
import service from './index';

export const uploadFileService = (fileData, attachment_id) => {
  const {
    user: { org },
  } = getState();
  const form = new FormData();
  form.append('file', fileData);
  form.append('attachment_id', attachment_id);
  form.append('org_id', org.uuid);

  return service({
    method: 'POST',
    url: `/naadi-app/api/fileupload/signed`,
    data: form,
  });
};

export const uploadBase64File = (attachmentId, base64) => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-attachment-service/v1/naadi/attachment/${org.uuid}/attachments/upload/${attachmentId}`,
    data: {
      req_payload: base64,
    },
  });
};

export const createNewAttachmentService = (fileName, folder, contentType) => {
  const {
    user: { org, user_id },
  } = getState();
  const payload = {
    active: true,
    deleted: true,
    approved: true,
    user_id: user_id,
    org_id: org.uuid,
    req_payload: {
      file_name: fileName,
      org_id: org.uuid,
      folder: folder,
      content_type: contentType,
      sign_request: false,
    },
  };
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-attachment-service/v1/naadi/attachment/${org.uuid}/attachments/create`,
    data: payload,
  });
};

export const getAttachmentSignedUrlService = (attachment_id, validity) => {
  const {
    user: { org, user_id },
  } = getState();
  const params = {
    user_id: user_id,
    upload: false,
    validity: validity && validity > 0 ? validity : 120,
  };
  return service({
    method: 'GET',
    url: `/naadi-app/api/naadi-attachment-service/v1/naadi/attachment/${org.uuid}/attachments/signed/${attachment_id}`,
    params: params,
  });
};
