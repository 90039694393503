import {
  getCustomerOrderOperationStat,
  getExtCodeOrderOperationStat,
  getOrderOperationStat,
  getOrderStatItems,
  getParentOrderOperationStat,
  getSecondaryCustomerOrderOperationStat,
} from '../../services/order';
import { getScannedRecordFromOrderItem } from './orderItemHelper';

export const getOrderPipelineStat = async (
  pipelineGroup,
  operations,
  breadcrumb,
  sortDirection,
  filterTerm,
  itemTypes,
) => {
  sortDirection = !sortDirection ? 'desc' : sortDirection;
  const size = operations.length <= 1 ? 100 : 100 * operations.length;
  switch (pipelineGroup) {
    case 'ORDER':
      return await getOrderOperationStat(
        operations,
        breadcrumb,
        0,
        size,
        sortDirection,
        filterTerm,
        itemTypes,
      );
    case 'PARENT_ORDER':
      return await getParentOrderOperationStat(
        operations,
        breadcrumb,
        0,
        size,
        sortDirection,
        filterTerm,
        itemTypes,
      );
    case 'CUSTOMER':
      return await getCustomerOrderOperationStat(
        operations,
        0,
        size,
        sortDirection,
        filterTerm,
        itemTypes,
      );
    case 'ORDER_EXTERNAL_REFERENCE':
      return await getExtCodeOrderOperationStat(
        operations,
        breadcrumb,
        0,
        size,
        sortDirection,
        filterTerm,
        itemTypes,
      );
    case 'SECONDARY_CUSTOMER':
      return await getSecondaryCustomerOrderOperationStat(
        operations,
        breadcrumb,
        0,
        size,
        sortDirection,
        filterTerm,
        itemTypes,
      );
    default:
  }
  return { status: 200, payload: [] };
};

export const getOrderItemPipelineStat = async (
  pipelineGroup,
  operations,
  breadcrumb,
  itemTypes,
) => {
  if (
    !breadcrumb ||
    breadcrumb.pipeline_group !== 'ITEMS' ||
    pipelineGroup !== 'ITEMS'
  ) {
    return {
      status: 200,
      payload: [],
    };
  }
  const resp = await getOrderStatItems(operations, breadcrumb, itemTypes);
  if (resp.status !== 200) {
    return new Promise((resolve, reject) => {
      reject(
        resp.err && resp.err.err
          ? resp.err.err
          : 'Unable to fetch the Order items',
      );
    });
  }
  const metadata = resp.meta_data || {};
  const ordersMap = metadata.orders || {};
  const orderItemStatusMap = metadata.order_item_status || {};
  const payload = resp.payload.map(item => {
    const order = ordersMap[item.order_id];
    const orderItemStatus = orderItemStatusMap[item.uuid];
    if (order && order.uuid) {
      item.order = order;
    }
    return getScannedRecordFromOrderItem(
      item,
      orderItemStatus.scan_status,
      orderItemStatus.processed_on,
    );
  });
  return {
    status: 200,
    payload: payload,
  };
};
