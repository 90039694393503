import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import BOMTable, { EditableCell } from '../bom_views/tableComponent';
import Chip from '../../../components/common/chips';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import ColumnSelectionComponent from './columnSelector';
import {
  unsetGroupByCols,
  editBOM,
  resetBomIDinEdit,
  hideCSVEditTableModal,
  getSourcesDetails,
  setEditActiveColumn,
  resetEditActiveColumn,
  resetGroupByCols,
} from '../../../store/order/bom/bomDataDuck';
import { setCSVData } from '../../../store/order/ordersList/actions';
import { JSONToCSVConvertor } from '../bom_views/utils';
import {
  EditIcon,
  CopyIcon,
  DeleteIcon,
  ErrorIcon,
} from '../../../assets/icons';
import { retriggerOrderSrcEventService } from '../../../services/order';
import { cloneDeep } from 'lodash';

// eslint-disable-next-line sonarjs/cognitive-complexity
const EditBOMSlectionComponent = ({ order_id, show_modal, closeModal }) => {
  /*eslint-disable-line*/
  const dispatch = useDispatch();
  /*const show_modal = useSelector(
    state => state.bom_data.show_csv_table_edit_menu,
  );*/
  const { sideMenuToggled } = useSelector(state => state.appNav);
  const bom_id = useSelector(state => state.bom_data.bom_id_edit_menu);
  const bomDataMap = useSelector(state => state.bom_data.bom_data);
  const [bom_data, setBomData] = useState(bomDataMap[`${bom_id}`] || {});
  const group_by_cols = useSelector(state => state.bom_data.group_by_cols);
  const editable_rows = useSelector(state => state.bom_data.editable_rows);
  const [bom_table_data, setBomTableData] = useState(bom_data.table_data);
  const column_headers = bom_data.column_headers;

  const bom_data_item_errors = bom_data.item_errors || [];
  const order_errors = bom_data.order_errors || [];
  const can_retrigger =
    bom_data_item_errors.filter(val => val.length > 0).length > 0 ||
    order_errors.length > 0;
  const order_details =
    useSelector(state => state.ordersList.orders_details)[`${order_id}`] || {};
  useEffect(() => {
    const _bomData = bomDataMap[`${bom_id}`] || {};
    setBomData(_bomData);
    setBomTableData(_bomData.table_data);
  }, [bomDataMap, bom_id]);
  const handleClose = () => {
    dispatch(resetGroupByCols([]));
    dispatch(hideCSVEditTableModal(dispatch));
    dispatch(
      setCSVData({
        list: [],
        columns: [],
        column_headers: [],
      }),
    );
    dispatch(resetBomIDinEdit(dispatch));
    setDeletedRows([]);
    if (closeModal) {
      closeModal();
    }
  };
  const [headerChangeCounter, setHeaderCounter] = useState(0);
  const [data, setData] = React.useState([]);
  const [columnHeaders, setColumnHeaders] = React.useState([]);
  const [deletedRows, setDeletedRows] = useState([]);
  const [errorOnTop, setErrorOnTop] = useState(false);
  const Columns = React.useMemo(() => {
    if (columnHeaders && columnHeaders.length > 0) {
      let temp_cols = columnHeaders.map((c, idx) => ({
        Header: c,
        accessor: c,
        Cell: cellProps => {
          // eslint-disable-line
          const { row } = cellProps;
          const has_field_error =
            row.values.__ROW_FIELD_ERRORS__ &&
            row.values.__ROW_FIELD_ERRORS__[`${c}`];
          const has_item_errors =
            row.values.__ROW_ERRORS__ &&
            row.values.__ROW_ERRORS__.length &&
            !idx;
          const serializeErrors =
            row.values.__ROW_FIELD_ERRORS__['SERIALIZE'] || '';
          const releaseErrors =
            row.values.__ROW_FIELD_ERRORS__['RELEASE'] || '';

          return (
            <div className=''>
              <div className='d-flex align-items-center'>
                <div
                  className={`fs-12 ${
                    has_item_errors || has_field_error ? 'font-weight-bold' : ''
                  }`}
                >
                  <EditableCell {...cellProps} />
                </div>
                {(has_item_errors || has_field_error) && (
                  <div className='ms-4'>
                    <ErrorIcon />
                  </div>
                )}
              </div>
              {has_item_errors && (
                <div className='my-1 text-error'>
                  {row.values.__ROW_ERRORS__}
                </div>
              )}
              {has_field_error && (
                <div className='my-1 text-error'>
                  {row.values.__ROW_FIELD_ERRORS__[`${c}`]}
                </div>
              )}
              {serializeErrors && serializeErrors !== '' && c === '#' && (
                <div>{serializeErrors}</div>
              )}
              {releaseErrors && releaseErrors !== '' && c === '#' && (
                <div>{releaseErrors}</div>
              )}
            </div>
          );
        },
      }));
      temp_cols.push({
        Header: 'Options',
        accessor: 'created_on',
        disableFilters: false,
        sticky: 'right',
        width: 120,
        maxWidth: 300,
        minWidth: 120,
        Cell: cellProps => {
          // eslint-disable-line
          const { row, row_index } = cellProps;
          const { values } = row; // eslint-disable-line
          return (
            <div className='delivery-date-col d-flex'>
              <div
                className=' clickable'
                onClick={() => cellProps.onRowEdit(row_index)}
              >
                <EditIcon />
              </div>
              <div
                className='mx-3 clickable'
                onClick={() => cellProps.onRowCopy(values)}
              >
                <CopyIcon />
              </div>
              <div
                className=' clickable'
                onClick={() => cellProps.onRowDelete(row_index)}
              >
                <DeleteIcon />
              </div>
            </div>
          );
        },
      });
      return temp_cols;
    }
    return [];
  }, [columnHeaders]);

  useEffect(() => {
    if (show_modal) {
      dispatch(
        getSourcesDetails({
          bom_id,
          order_id: order_id,
        }),
      );
    } else {
      dispatch(resetEditActiveColumn(dispatch));
    }
  }, [dispatch, show_modal, bom_id, order_id]);
  /* eslint-disable */
  useEffect(() => {
    if (bom_table_data) {
      setData(bom_table_data);
    }
    if (column_headers) {
      setColumnHeaders(column_headers);
      setHeaderCounter(headerChangeCounter + 1);
    }
  }, [bom_data]);
  /* eslint-enable */
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[`${rowIndex}`],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const addNewRow = () => {
    let empty_key_set = {};
    Columns.map(({ accessor }) => {
      empty_key_set[`${accessor}`] = '';
      return null;
    });
    setData([...data, empty_key_set]);
  };

  const onRowDelete = row_index => {
    setDeletedRows(oldArray => [...oldArray, data[`${row_index}`]]);
    setData(data.filter((item, item_idx) => item_idx !== row_index));
  };

  const onRowEdit = row_index => {
    dispatch(
      setEditActiveColumn({
        [row_index]: !editable_rows[`${row_index}`],
      }),
    );
  };
  const onRowCopy = row_val => {
    setData([...data, row_val]);
  };

  const ITEM_EXTERNAL_REF_FIELD = 'ITEM-EXTERNAL-REFERENCE';
  const onAddBomClick = async () => {
    const _data = cloneDeep(data);
    const rowsToDelete = deletedRows;
    const extRefList = [];
    const ITEM_DELETED_FIELD = 'ITEM-DELETED';
    for (const rowIndex in _data) {
      if (!_data[`${rowIndex}`]) continue;
      const row = _data[`${rowIndex}`];
      if (row[`${ITEM_EXTERNAL_REF_FIELD}`]) {
        extRefList.push(row[`${ITEM_EXTERNAL_REF_FIELD}`]);
      }
      delete row['__ROW_ERRORS__'];
      delete row['__ROW_FIELD_ERRORS__'];
      if (
        rowsToDelete &&
        rowsToDelete.length > 0 &&
        row[`${ITEM_DELETED_FIELD}`] === undefined
      ) {
        row[`${ITEM_DELETED_FIELD}`] = false;
      }
    }
    for (const rowIndex in rowsToDelete) {
      if (!rowsToDelete[`${rowIndex}`]) continue;
      const row = cloneDeep(rowsToDelete[`${rowIndex}`]);
      const extRef = row[`${ITEM_EXTERNAL_REF_FIELD}`];
      if (!extRef) continue;
      if (extRefList.indexOf(extRef) >= 0) continue;
      row[`${ITEM_DELETED_FIELD}`] = true;
      delete row['__ROW_ERRORS__'];
      delete row['__ROW_FIELD_ERRORS__'];
      _data.push(row);
    }
    const csv_data = JSONToCSVConvertor(_data, 'temp_csv', true);
    for (const row_index in editable_rows) {
      if (editable_rows[`${row_index}`] === true) {
        onRowEdit(row_index);
      }
    }
    dispatch(
      editBOM(order_details, csv_data, null, bom_id, null, group_by_cols),
    );
  };
  const handleErrorOnTop = () => {
    setErrorOnTop(!errorOnTop);
    const _data = cloneDeep(data);
    _data.sort((a, b) => {
      const aExtRef = a[`${ITEM_EXTERNAL_REF_FIELD}`] || '';
      const bExtRef = b[`${ITEM_EXTERNAL_REF_FIELD}`] || '';
      if (errorOnTop) {
        const aRowErrors = a['__ROW_ERRORS__'] || 0;
        const aFieldErrors = Object.keys(
          a['__ROW_FIELD_ERRORS__'] || {},
        ).length;
        const bRowErrors = b['__ROW_ERRORS__'] || 0;
        const bFieldErrors = Object.keys(
          b['__ROW_FIELD_ERRORS__'] || {},
        ).length;
        const aHasErrors = aRowErrors > 0 || aFieldErrors > 0;
        const bHasErrors = bRowErrors > 0 || bFieldErrors > 0;
        if (aHasErrors === bHasErrors) {
          return aExtRef.localeCompare(bExtRef);
        } else if (aHasErrors) {
          return -1;
        } else if (bHasErrors) {
          return 1;
        }
        return 0;
      }
      return aExtRef.localeCompare(bExtRef);
    });
    for (const rowIndex in _data) {
      const indexVal = parseInt(rowIndex) + 1;
      _data[`${rowIndex}`]['#'] = indexVal + '';
    }
    setData(_data);
  };
  const defaultColumn = {
    width: Columns.length > 6 ? 200 : 275,
    maxWidth: 400,
    minWidth: Columns.length > 6 ? 200 : 275,
  };
  const hiddenColumns = ['__ROW_ERRORS__', '__ROW_FIELD_ERRORS__', 'QTY'];
  const groupByFilterHideColumns = [
    ...hiddenColumns,
    'Options',
    '#',
    'ITEM-DELETED',
    'ITEM-QTY',
    'ITEM-NAME',
    'ITEM-CODE',
    'ITEM-SERIALIZATION-CODE',
    ITEM_EXTERNAL_REF_FIELD,
  ];
  const retriggerUploadJob = async () => {
    await retriggerOrderSrcEventService(bom_id);
  };
  const renderOrderErrors = () => {
    const has_order_errors = order_errors.length;
    return (
      <div>
        {has_order_errors > 0 && (
          <div>
            <div className='ms-4'>
              <ErrorIcon />
            </div>
            {order_errors.map((error, errindex) => (
              <div
                className='my-1 text-error'
                key={`order-err-row-${errindex}`}
              >
                {error}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  const renderNoDataFragmentWithErrors = () => {
    return (
      <div>
        <Alert variant='warning'>No data to show</Alert>
        {renderOrderErrors()}
      </div>
    );
  };
  const sideBarToggledCss =
    sideMenuToggled === true ? 'is-side-bar-toggled' : '';
  return (
    <Modal
      dialogClassName={`modal-90w naadi-modal-sidebar-padding ${sideBarToggledCss}`}
      aria-labelledby='contained-modal-title-vcenter'
      show={show_modal}
      onHide={handleClose}
      centered
    >
      <Modal.Header className='border-0 p-4'>
        <div className='fs-24'>Preview</div>
        <div>
          <div className='text-label'>Group by</div>
          <ColumnSelectionComponent
            filters={Columns}
            hideFilters={groupByFilterHideColumns}
          />
        </div>
      </Modal.Header>
      <Container fluid>
        <Row>
          <Col className='text-right'>
            <div className='filter-labels-wrapper'>
              {group_by_cols.map(col_name => (
                <Chip
                  key={col_name}
                  onClick={() => dispatch(unsetGroupByCols(col_name))}
                >
                  <span className='title-xs font-weight-bold'>{col_name}</span>
                </Chip>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal.Body className='pb-0 pt-0'>
        {columnHeaders &&
        Columns.length > 0 &&
        columnHeaders.length > 0 &&
        data &&
        data.length > 0 ? (
          <div>
            <BOMTable
              columns={Columns}
              data={data}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              addNewRow={addNewRow}
              onRowDelete={onRowDelete}
              onRowEdit={onRowEdit}
              onRowCopy={onRowCopy}
              defaultColumn={defaultColumn}
              editable_rows={editable_rows}
              hiddenColumns={hiddenColumns}
            />
            {renderOrderErrors()}
          </div>
        ) : (
          renderNoDataFragmentWithErrors()
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='row w-100'>
          <div className='col order-show-error-on-top-wrp'>
            <input
              type='checkbox'
              className='main-box-checkbox cursor-pointer'
              onChange={handleErrorOnTop}
              value={true}
              checked={errorOnTop}
            />
            <div className='ms-2'>Show Errors On Top</div>
            <div className='ms-2'>
              <ErrorIcon />
            </div>
          </div>
          <div className='col-md-6 display-flex modal-footer border-0'>
            <Button variant='outline-primary' onClick={handleClose}>
              Close
            </Button>
            <div>
              {can_retrigger === true && (
                <Button variant='outline-primary' onClick={retriggerUploadJob}>
                  Retrigger
                </Button>
              )}
            </div>
            {1 === 2 && (
              <Button variant='primary' onClick={() => onAddBomClick()}>
                Update BOM
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EditBOMSlectionComponent.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  order_id: PropTypes.string,
  bom_id: PropTypes.string,
};

export default EditBOMSlectionComponent;
