export const TYPES = {
  ADD_ITEM: 'ADD_ITEM',
  EDIT_ITEM: 'EDIT_ITEM',
  DELETE_ITEM: 'DELETE_ITEM',
  OPEN_BOX: 'OPEN_BOX',
  CLOSE_BOX: 'CLOSE_BOX',
  ADD_SCAN_RECORD: 'ADD_SCAN_RECORD',
  BOX_HISTORY: 'BOX_HISTORY',
  TOGGLE_EXTRA: 'TOGGLE_EXTRA',
};

export const DEFAULT = {
  items: [],
  visible: true,
  openedBoxId: null,
  openedBoxCode: null,
  detailsView: false,
  isEditable: false,
  boxHistory: [],
  extra: false,
};
