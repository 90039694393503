import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
import { cloneDeep } from 'lodash';
import moment from 'moment';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('TRACKTRACE_STATE', type, verb);
export const TRACK_TRACE_BASE_PATH = '/app/tracktrace';
export const TRACK_TRACE_HOME = `${TRACK_TRACE_BASE_PATH}/home`;
export const TYPES = {
  SCANNED_ITEMS: 'SCANNED_ITEMS',
  SELECTED_RECORD: 'SELECTED_RECORD',
  IMAGE_LINKS: 'IMAGE_LINKS',
  VIEW_MODE: 'VIEW_MODE',
  ORDER_ITEM_DETAIL: 'ORDER_ITEM_DETAIL',
  ITEM_FIELD_CONFIG: 'ITEM_FIELD_CONFIG',
  REJECT_REASONS: 'REJECT_REASONS',
  GROUP_BY: 'GROUP_BY',
  ORDER_ITEM_SYNC: 'ORDER_ITEM_SYNC',
  OFFLINE_SCAN: 'OFFLINE_SCAN',
};
export const resetTrackTrace = createAction(
  getActionName('TRACKTRACE', 'RESET'),
);
export const resetTrackTraceStation = createAction(
  getActionName('TRACKTRACE', 'RESET_STATION'),
);
export const setScannedItemsInStore = createAction(
  getActionName(TYPES.SCANNED_ITEMS, 'SET'),
);
export const setScannedItemSyncTime = createAction(
  getActionName(TYPES.SCANNED_ITEMS, 'SET_TIME'),
);

export const filterScannedItemsDeprecated = createAction(
  getActionName(TYPES.SCANNED_ITEMS, 'FILTER'),
);
export const setSelectedRecord = createAction(
  getActionName(TYPES.SELECTED_RECORD, 'SET'),
);
export const setListViewMode = createAction(
  getActionName(TYPES.VIEW_MODE, 'LIST'),
);
export const setImageViewMode = createAction(
  getActionName(TYPES.VIEW_MODE, 'IMAGE'),
);
export const setOrderItemDetail = createAction(
  getActionName(TYPES.ORDER_ITEM_DETAIL, 'SET'),
);
export const setItemDetailHistory = createAction(
  getActionName(TYPES.ORDER_ITEM_DETAIL, 'HISTORY'),
);
export const setItemFieldConfig = createAction(
  getActionName(TYPES.ITEM_FIELD_CONFIG, 'MERGE'),
);
export const setRejectReasons = createAction(
  getActionName(TYPES.REJECT_REASONS, 'SET'),
);
export const setGroupByFilter = createAction(
  getActionName(TYPES.GROUP_BY, 'SET'),
);
export const setOrderItemSyncTime = createAction(
  getActionName(TYPES.ORDER_ITEM_SYNC, 'SET_TIME'),
);
export const setOrderItemStatusSyncTime = createAction(
  getActionName(TYPES.ORDER_ITEM_SYNC, 'SET_STATUS_TIME'),
);
export const setOrderItemSyncCtx = createAction(
  getActionName(TYPES.ORDER_ITEM_SYNC, 'SET_CTX'),
);
export const setOrderItems = createAction(
  getActionName(TYPES.ORDER_ITEM_SYNC, 'SET_ITEMS'),
);
export const setOrderItemsList = createAction(
  getActionName(TYPES.ORDER_ITEM_SYNC, 'SET_ITEMS_LIST'),
);
export const addOrderItems = createAction(
  getActionName(TYPES.ORDER_ITEM_SYNC, 'ADD_ITEMS'),
);
export const setOrderItemSyncLock = createAction(
  getActionName(TYPES.ORDER_ITEM_SYNC, 'SET_LOCK'),
);
export const setOrderItemStatusSyncLock = createAction(
  getActionName(TYPES.ORDER_ITEM_SYNC, 'SET_STATUS_LOCK'),
);

export const addOfflineScan = createAction(
  getActionName(TYPES.OFFLINE_SCAN, 'ADD'),
);

export const removeOfflineScan = createAction(
  getActionName(TYPES.OFFLINE_SCAN, 'REMOVE'),
);

export const resetOfflineScan = createAction(
  getActionName(TYPES.OFFLINE_SCAN, 'RESET'),
);

export const DEFAULT = {
  scanned_items: [],
  scanned_items_sync_time: 0,
  selected_record: null,
  list_view_mode: true,
  image_view_mode: 'SMALL',
  order_item_detail: null,
  item_detail_history: false,
  item_field_config: {},
  reject_reasons: [],
  group_by: null,
  order_item_sync_time: 0,
  order_item_status_sync_time: 0,
  order_item_sync_ctx: null,
  order_items: [],
  order_item_sync_lock: false,
  order_item_status_sync_lock: false,
  busy: [],
  offline_scans: [],
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetTrackTrace, state => {
    return Update(state, {
      scanned_items: { $set: [] },
      selected_record: { $set: null },
      order_item_detail: { $set: null },
      item_field_config: { $set: {} },
      reject_reasons: { $set: [] },
      group_by: { $set: null },
      scanned_items_sync_time: { $set: 0 },
      order_item_sync_time: { $set: 0 },
      order_item_status_sync_time: { $set: 0 },
      order_items_list: { $set: null },
      order_items: { $set: [] },
      order_item_sync_ctx: { $set: null },
    });
  });
  builder.addCase(resetTrackTraceStation, state => {
    return Update(state, {
      scanned_items: { $set: [] },
      selected_record: { $set: null },
      order_item_detail: { $set: null },
      item_field_config: { $set: {} },
      reject_reasons: { $set: [] },
      scanned_items_sync_time: { $set: 0 },
      order_item_status_sync_time: { $set: 0 },
      order_item_sync_time: { $set: 0 },
      order_items: { $set: [] },
      order_items_list: { $set: null },
    });
  });
  builder.addCase(setScannedItemsInStore, (state, { payload }) => {
    return Update(state, {
      scanned_items: { $set: payload },
    });
  });
  builder.addCase(setScannedItemSyncTime, (state, { payload }) => {
    return Update(state, {
      scanned_items_sync_time: { $set: payload },
    });
  });

  /*eslint-enable sonarjs/cognitive-complexity */
  builder.addCase(filterScannedItemsDeprecated, (state, { payload }) => {
    let scanned_items = cloneDeep(state.scanned_items) || [];
    if (payload && moment(payload).isValid()) {
      scanned_items = scanned_items.filter(val =>
        moment(val.updated_on).isAfter(payload),
      );
    }
    return Update(state, {
      scanned_items: { $set: scanned_items },
    });
  });
  builder.addCase(setSelectedRecord, (state, { payload }) => {
    return Update(state, {
      selected_record: { $set: payload },
    });
  });
  builder.addCase(setListViewMode, (state, { payload }) => {
    return Update(state, {
      list_view_mode: { $set: payload === true },
    });
  });
  builder.addCase(setImageViewMode, (state, { payload }) => {
    return Update(state, {
      image_view_mode: { $set: payload },
    });
  });
  builder.addCase(setOrderItemDetail, (state, { payload }) => {
    return Update(state, {
      order_item_detail: { $set: payload },
    });
  });
  builder.addCase(setItemDetailHistory, (state, { payload }) => {
    return Update(state, {
      item_detail_history: { $set: payload === true },
    });
  });
  builder.addCase(setItemFieldConfig, (state, { payload }) => {
    return Update(state, {
      item_field_config: { $merge: payload },
    });
  });
  builder.addCase(setRejectReasons, (state, { payload }) => {
    return Update(state, {
      reject_reasons: { $set: payload },
    });
  });
  builder.addCase(setGroupByFilter, (state, { payload }) => {
    return Update(state, {
      group_by: { $set: payload },
    });
  });
  builder.addCase(setOrderItemSyncTime, (state, { payload }) => {
    return Update(state, {
      order_item_sync_time: { $set: payload },
    });
  });
  builder.addCase(setOrderItemStatusSyncTime, (state, { payload }) => {
    return Update(state, {
      order_item_status_sync_time: { $set: payload },
    });
  });
  builder.addCase(setOrderItemSyncCtx, (state, { payload }) => {
    return Update(state, {
      order_item_sync_ctx: { $set: payload },
      order_items: { $set: [] },
      order_items_list: { $set: null },
      order_item_sync_time: { $set: 0 },
      order_item_status_sync_time: { $set: 0 },
    });
  });
  builder.addCase(setOrderItems, (state, { payload }) => {
    return Update(state, {
      order_items: { $set: payload },
    });
  });
  builder.addCase(setOrderItemsList, (state, { payload }) => {
    return Update(state, {
      order_items_list: { $set: payload },
    });
  });
  builder.addCase(addOrderItems, (state, { payload }) => {
    //const start = new Date().getTime();
    const orderItems = cloneDeep(state.order_items || []);
    const _payload = payload ? [...payload] : [];
    for (let i = 0; i < orderItems.length; i++) {
      const orderItem = orderItems[`${i}`];
      for (let j = 0; j < _payload.length; j++) {
        const _orderItem = _payload[`${j}`];
        if (_orderItem.uuid === orderItem.uuid) {
          orderItems[`${i}`] = _orderItem;
          _payload.splice(j--, 1);
        }
      }
    }
    for (let i = 0; i < _payload.length; i++) {
      orderItems.push(_payload[`${i}`]);
    }
    //const successItems = orderItems.filter(val => val.status === 'SUCCESS');
    //console.log(successItems.length);
    return Update(state, {
      order_items: { $set: orderItems },
    });
  });
  builder.addCase(setOrderItemSyncLock, (state, { payload }) => {
    return Update(state, {
      order_item_sync_lock: { $set: payload === true },
    });
  });
  builder.addCase(setOrderItemStatusSyncLock, (state, { payload }) => {
    return Update(state, {
      order_item_sync_status_lock: { $set: payload === true },
    });
  });

  builder.addCase(addOfflineScan, (state, { payload }) => {
    const offlineScans = cloneDeep(state.offline_scans || []).filter(
      val => val.scan_code !== payload.scan_code,
    );
    offlineScans.push(payload);
    return Update(state, {
      offline_scans: { $set: offlineScans },
    });
  });
  builder.addCase(removeOfflineScan, (state, { payload }) => {
    const offlineScans = cloneDeep(state.offline_scans || []).filter(
      val => val.scan_code !== payload,
    );
    return Update(state, {
      offline_scans: { $set: offlineScans },
    });
  });
  builder.addCase(resetOfflineScan, (state, { payload }) => {
    return Update(state, {
      offline_scans: { $set: [] },
    });
  });
});

export default reducer;
