import { useState, useEffect, useRef } from 'react';

const getDomain = () => {
  const url = window.location.href;
  if (url.match(/^https?:\/\/dpod.*.naadi.io/)) {
    return 'dpod';
  }
  return '';
};

export const useDomain = () => {
  const [domain, setDomain] = useState(getDomain());
  const lastDomain = useRef(domain);
  useEffect(() => {
    const _domain = getDomain();
    if (_domain !== lastDomain.current) {
      setDomain(_domain);
      lastDomain.current = _domain;
    }
  }, []);

  return [domain];
};

export default useDomain;
