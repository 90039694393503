//import { applyMiddleware } from 'redux';
//import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import { createStateSyncMiddleware } from 'redux-state-sync';
import { PERSIST } from 'redux-persist/es/constants';

// import storage from 'redux-persist/lib/storage';

import reducers from './reducers';
import { configureStore } from '@reduxjs/toolkit';

const persistConfig = {
  key: 'root',
  storage: storage('myDB'),
  whitelist: [
    'user',
    'token',
    'selection',
    'scan',
    'itemslist',
    'itemsList',
    'boxAndItems',
    'orderArchive',
    'appNav',
    'connectedPrinters',
    'orderstate',
    'reportfilter',
    'userdashboard',
    'stationstate',
    'cgstationstate',
    'tracktrace',
    'cutsmart',
    'cutguide',
    'digitalpod',
    'dpodstationstate',
    'dpodexternstationstate',
    'pipeline',
    'groups',
    'users',
    'shipment',
    'shipmentstate',
  ],
};
const reduxStateSyncConfig = {
  blacklist: [PERSIST],
  prepareState: state => state.toJS(),
};

const persistedReducer = persistReducer(persistConfig, reducers);

const middlewares = [thunk, createStateSyncMiddleware(reduxStateSyncConfig)];
//const middlewares = [thunk];

//const composed = composeWithDevTools(applyMiddleware(...middlewares));

//const store1 = createStore(persistedReducer, composed);
const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
});
const persistor = persistStore(store);

const outerStore = { store, persistor };

export default outerStore;
export const getState = () => store.getState();
