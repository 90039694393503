import React, { Fragment, useCallback, useState, useEffect } from 'react';
import '../../scss/components/_dropdown.scss';
import IconMenu from '../../assets/icons/icon-menu.svg';
import { toggleSideMenu } from '../../store/app/nav/actions';
import TemplateImage from '../../assets/img/template-img.svg';
import ScreenLoader from '../../components/common/screen-loader';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';
import { ToastContainer } from 'react-toastify';
import { getItemCode } from '../../services/uploadformat';
import Select from '../../components/packing/common/select';
import { useHistory } from 'react-router-dom';
import BottomDrawerItems from './bottom-drawer-items';
import useMobile from '../../hooks/useMobile';
import SearchIcon from '../../assets/icons/searchIcon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
const TXT_COL = 'rgba(50, 50, 50, 0.4)';

const ItemsFilter = ({ isMobile, isTab, itemOpen, setItemOpen, itemData }) => {
  const itemFilter = term => {
    return new Promise((resolve, reject) => {
      const itemBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (term) itemBody.req_payload.term = term.toUpperCase();
      getItemCode(itemBody).then(val => {
        if (val.status === 200) {
          resolve(val.payload);
        } else {
          reject([]);
        }
      }, reject);
    });
  };
  const getOptions = useCallback(itemFilter, []);

  const history = useHistory();

  return (
    <Fragment>
      <div className='d-flex' style={{ width: '350px', margin: '-10px' }}>
        <div style={{ paddingLeft: '0px' }}>
          <div
            style={{ height: '64px', width: '4px', background: '#008A50' }}
          ></div>
        </div>

        <div style={{ padding: '0px' }}>
          <div style={{ height: '8px' }}></div>

          <div
            className='d-flex'
            style={{ cursor: 'pointer', paddingTop: '0px' }}
          >
            <div
              style={{
                font: 'Roboto',
                fontWeight: '400px',
                fontSize: '24px',
                color: '#006EED',
                paddingLeft: '8px',
              }}
            >
              Select Item
            </div>

            <div
              style={{
                color: '#323232',
                fontSize: '16px',
                marginLeft: '10px',
              }}
            >
              {!(isMobile || isTab) && (
                <Select
                  //label={`Select an Order`}
                  getOptions={getOptions}
                  labelField='code'
                  valueField='uuid'
                  onSelect={i => {
                    history.push(
                      `/app/uploadformat/additemfeatures?item=${i.code}`,
                    );
                  }}
                  //selected={pipelineMode ? null : selection}
                  //className='page-size-selector dropdown-table-filter'
                  search
                />
              )}
              {(isMobile || isTab) && (
                <div onClick={() => setItemOpen(true)}>
                  <FontAwesomeIcon icon={faChevronDown} className='ms-2' />
                </div>
              )}
              <BottomDrawerItems
                isMobile={isMobile}
                isTab={isTab}
                itemOpen={itemOpen}
                setItemOpen={() => setItemOpen}
                itemData={itemData()}
              />
            </div>
          </div>

          <div
            style={{
              font: 'Roboto',
              fontWeight: 'bold',
              fontSize: '14px',
              color: TXT_COL,
              paddingLeft: '10px',
              paddingTop: '8px',
            }}
          >
            ITEMS
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const UserInputListHeader = () => {
  const dispatch = useDispatch();
  const handleMenuToggle = () => {
    dispatch(toggleSideMenu());
  };
  return (
    <Fragment>
      <div
        className='flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 '
        style={{ zIndex: 10, width: '100%' }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          type='button'
        >
          <img
            src={IconMenu}
            alt='Menu'
            onClick={() => handleMenuToggle()}
          ></img>
        </button>
        <div className='ms-2 page-header'>
          <div className='page-title d-flex align-items-center'>
            <div className='ps-2'>Upload Format</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ImageDisplay = () => {
  return (
    <Fragment>
      <div style={{ textAlign: 'center' }}>
        <img src={TemplateImage} alt=''></img>
      </div>
      <div style={{ height: '24px' }}></div>
      <div
        style={{
          font: 'Roboto',
          fontSize: '16px',
          color: TXT_COL,
          background: 'white',
          textAlign: 'center',
        }}
      >
        Select an Item to begin
      </div>
    </Fragment>
  );
};

const UploadFormat = () => {
  const [isMobile, isTab] = useMobile();
  const [itemOpen, setItemOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [itemsDrawer, setItemsDrawer] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const itemSelected = useCallback(
    async item => {
      history.push(`/app/uploadformat/additemfeatures?item=${item}`);
    },
    [history],
  );
  const fetchItems = useCallback(
    async tid => {
      const itemBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (searchText) itemBody.req_payload.term = searchText.toUpperCase();
      try {
        dispatch(actions.busy.add('FETCH_ITEM'));
        const getItems = await getItemCode(itemBody);

        if (getItems && getItems.status !== 200) {
          return;
        }

        setItemsDrawer(getItems.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_ITEM'));
      }
    },
    [dispatch, searchText],
  );
  useEffect(() => {
    fetchItems();
  }, [fetchItems]);
  const itemData = () => {
    return (
      <div>
        <div style={{ fontSize: '20px', textAlign: 'center' }}>SELECT ITEM</div>
        <div style={{ height: '30px' }}></div>
        <div>
          <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
          <input
            type='text'
            id='search_field'
            className='searchModal'
            style={{
              border: '0px !important',
              color: TXT_COL,
              fontStyle: 'italic',
            }}
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder='Type here to search Item'
          />
        </div>
        {itemsDrawer &&
          itemsDrawer.length > 0 &&
          itemsDrawer.map(row => (
            <div key={row.uuid}>
              <div
                key={row.uuid}
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  itemSelected(row.code);
                  setItemOpen(false);
                }}
              >
                {row.name}
              </div>
              <div
                className='operation-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
      </div>
    );
  };
  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ToastContainer />
      <div>
        <div className='container-fluid position-relative px-0'>
          <UserInputListHeader />
          <div style={{ background: 'white', marginLeft: '50px' }}>
            <ItemsFilter
              isMobile={isMobile}
              isTab={isTab}
              itemOpen={itemOpen}
              setItemOpen={setItemOpen}
              itemData={itemData}
            />
            <div style={{ height: '210px', background: 'white' }}></div>

            <ImageDisplay />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFormat;
