import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock } from '@fortawesome/free-solid-svg-icons';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useMobile from '../../../hooks/useMobile';
import moment from 'moment';
import { IconInfoCircledWhite } from '../../../views/cutsmart/iconSetting';
import { toastError } from '../../../helpers/packing/packingHelper';
import { getOrderProgress } from '../../../services/order-operation-stat';
import { actions } from '@naadi/framework';
import { getWorkOrders } from '../../../services/cutsmart';
import { useDispatch } from 'react-redux';
import { getOrders } from '../../../services/order';
import _ from 'lodash';
const TimelineItem = ({
  status,
  title,
  subtitle,
  created_on,
  first_operation_time,
  last_operation_time,
  firstOrder,
}) => {
  const timeWithOutYear = 'DD MMM hh:mma';
  const timeWithYear = 'DD MMM YYYY hh:mma';
  let Icon;
  let statusClass;
  const isCurrentYear = date => moment(date).isSame(new Date(), 'year');
  const showYear =
    !isCurrentYear(first_operation_time) || !isCurrentYear(last_operation_time);

  let tooltipTitle = '';
  let tooltipMessage = '';

  switch (status) {
    case 'Done':
      Icon = faCheckCircle;
      statusClass = 'done';
      tooltipTitle = 'SUCCESS';
      tooltipMessage = `${title} is done successfully`;
      break;
    case 'inProgress':
      Icon = faClock;
      statusClass = 'in-progress';
      tooltipTitle = 'IN PROGRESS';
      tooltipMessage = `${title} is currently in progress`;
      break;
    default:
      Icon = faClock;
      statusClass = 'pending';
      tooltipTitle = 'PENDING';
      tooltipMessage = `${title} is pending and yet to start`;
  }
  return (
    <div className='timeline-item roboto-font'>
      <div className='timeline-icon'>
        <OverlayTrigger
          delay={{ hide: 450, show: 300 }}
          overlay={props => (
            <Tooltip {...props}>
              <span className='cs-tooltips'>
                <IconInfoCircledWhite className='cs-tooltipColor' />{' '}
                {tooltipTitle}
                <article className='textS'>{tooltipMessage}</article>
              </span>
            </Tooltip>
          )}
          placement='bottom'
        >
          <button
            disabled
            className='cs-dis-btn'
            style={{ backgroundColor: 'white', border: 'none' }}
          >
            <FontAwesomeIcon
              icon={Icon}
              className={`icon status-icon ${statusClass}`}
            />
          </button>
        </OverlayTrigger>
      </div>

      <div className='timeline-content'>
        <div className='d-flex'>
          <div className='item-title pe-3'>{title}</div>
          {last_operation_time && first_operation_time && (
            <div className='item-subtitle text-dark'>
              {moment
                .duration(
                  moment(last_operation_time).diff(first_operation_time),
                )
                .days() > 0 &&
                `${moment
                  .duration(
                    moment(last_operation_time).diff(first_operation_time),
                  )
                  .days()}d `}
              {moment
                .duration(
                  moment(last_operation_time).diff(first_operation_time),
                )
                .hours() > 0 &&
                `${moment
                  .duration(
                    moment(last_operation_time).diff(first_operation_time),
                  )
                  .hours()}h`}
            </div>
          )}
        </div>

        {subtitle && <div className='item-subtitle rubik-font'>{subtitle}</div>}
        {created_on ? (
          <div className='item-date'>
            {moment(created_on).format(
              showYear ? timeWithYear : timeWithOutYear,
            )}{' '}
            {firstOrder === true && '(First order)'}
          </div>
        ) : (
          <div className='item-date rubik-font'>
            {first_operation_time && last_operation_time && (
              <>
                {moment(first_operation_time).format(
                  showYear ? timeWithYear : timeWithOutYear,
                )}
                {' - '}
                {status === 'inProgress'
                  ? ` (last scanned at ${moment(last_operation_time).format(
                      showYear ? timeWithYear : timeWithOutYear,
                    )})`
                  : moment(last_operation_time).format(
                      showYear ? timeWithYear : timeWithOutYear,
                    )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const TimeLineModal = ({ timelineModal, setTimelineModal, operations }) => {
  const dispatch = useDispatch();
  const [isMobile] = useMobile();
  const orderDetail = timelineModal?.timeLineData;
  const [orderStatus, setOrderStatus] = useState([]);
  const [opOrder, setOpOrder] = useState({});
  const [wo, setWo] = useState([]);
  const [order, setOrder] = useState([]);
  const fetchOpOrder = useCallback(async () => {
    dispatch(actions.busy.add('FETCH_OP_ORDER'));

    if (orderDetail.group !== 'ORDER') {
      let entity = '';
      if (orderDetail.group === 'PARENT_ORDER') {
        entity = orderDetail.order.uuid;
      } else if (orderDetail.group === 'CUSTOMER') {
        entity = orderDetail.order.entity_id;
      } else if (orderDetail.group === 'ORDER_EXTERNAL_REFERENCE') {
        entity = orderDetail.order.ext_code;
      } else if (orderDetail.group === 'SECONDARY_CUSTOMER') {
        entity = orderDetail.order.secondary_customer;
      }
      const ordersRes = await getOrderProgress(
        orderDetail.group === 'PARENT_ORDER' ? 'ORDER' : 'PARENT_ORDER',
        orderDetail.group2,
        orderDetail.group3,
        new Date(0),
        entity,
        orderDetail.group,
        orderDetail.item_type,
        300,
      );
      if (ordersRes.status !== 200) {
        toastError('ORDER_FETCH_ERROR');
        setOpOrder({});
      } else {
        setOpOrder(ordersRes.payload[0]);
      }
    } else {
      setOpOrder(orderDetail);
    }
    dispatch(actions.busy.remove('FETCH_OP_ORDER'));
  }, [dispatch, orderDetail]);
  const fetchOrder = useCallback(async () => {
    if (!opOrder?.order) {
      return setOrder([]);
    }
    dispatch(actions.busy.add('FETCH_ORDER'));

    const filterObj = {
      org_id: opOrder?.order?.org_id,
      branch_id: [opOrder?.order?.branch_id],
      term: opOrder?.order?.ref_code,
    };
    const order = await getOrders(filterObj);
    setOrder(order.payload);
    dispatch(actions.busy.remove('FETCH_ORDER'));
  }, [dispatch, opOrder?.order]);
  const fetchWorkOrders = useCallback(async () => {
    if (!opOrder?.order?.uuid) {
      return setWo([]);
    }
    try {
      dispatch(actions.busy.add('FETCH_WORK_ORDER_DETAIL'));
      const woResp = await getWorkOrders({
        page: 0,
        req_payload: {
          fetch_ctx: true,
          fetch_counts: true,
          order_id: [opOrder?.order?.uuid],
          wo_name_term: '',
        },
      });
      if (woResp.status === 200) {
        const _wo = woResp.payload;
        setWo(_wo);
      }
    } finally {
      dispatch(actions.busy.remove('FETCH_WORK_ORDER_DETAIL'));
    }
  }, [dispatch, opOrder?.order?.uuid]);

  const ordStatus = useCallback(() => {
    const orderDetails = {
      status: orderDetail?.order ? 'Done' : '',
      title: `Order Created`,
      created_on: order?.[0]?.created_on || 'N/A',
      firstOrder: orderDetail?.order?.uuid !== opOrder?.order?.uuid,
    };
    const woDetails = wo?.map(v => ({
      status: v?.created_on ? 'Done' : '',
      title: `Wo: ${v?.wo_name} Created`,
      created_on: v?.created_on || 'N/A',
    }));
    setOrderStatus(prevOrderDetail => [
      orderDetails,
      ...woDetails,
      ...prevOrderDetail,
    ]);
  }, [opOrder?.order?.uuid, order, orderDetail?.order, wo]);

  const opStatus = useCallback(() => {
    const updatedOpStatus = operations
      .filter(operation => operation !== 'Shipment_Date')
      .map(operation => {
        const stat = orderDetail?.operation_stats?.[`${operation}`];
        const totalPipeline = stat?.total_pipeline || 0;
        const totalPending = stat?.total_pending_qty || 0;

        if (totalPipeline === 0 && totalPending === 0) {
          return null;
        }

        let status = '';
        if (totalPipeline > 0 && totalPending === totalPipeline) {
          status = 'Pending';
        } else if (totalPipeline > 0 && totalPending === 0) {
          status = 'Done';
        } else if (totalPending < totalPipeline) {
          status = 'inProgress';
        }
        const operationName = _.startCase(_.toLower(operation));

        return {
          status,
          title: operationName,
          first_operation_time: stat?.first_operation_time || '',
          last_operation_time: stat?.last_operation_time || '',
          subtitle: status === 'Pending' ? 'To be picked' : '',
        };
      })
      .filter(item => item !== null);

    setOrderStatus(updatedOpStatus);
    ordStatus();
  }, [operations, ordStatus, orderDetail?.operation_stats]);
  useEffect(() => {
    fetchOpOrder();
  }, [fetchOpOrder]);
  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);
  useEffect(() => {
    fetchWorkOrders();
  }, [fetchWorkOrders]);
  useEffect(() => {
    if (opOrder) {
      opStatus();
    }
  }, [opOrder, opStatus]);
  return (
    <Fragment>
      <Modal
        centered={!isMobile}
        size={'md'}
        show={timelineModal.show}
        onHide={() => {
          setTimelineModal(val => ({ ...val, show: false }));
        }}
        dialogClassName='track-trace-dialog rubik-font'
      >
        <Modal.Header
          className='px-32'
          style={{ borderBottom: '0px' }}
          closeButton
        >
          <div className='modal-title rubik-font'>Timeline</div>
        </Modal.Header>
        <Modal.Body className='py-0 px-32 pb-2'>
          <div className='rubik-font'>
            {timelineModal.timeLineData?.order?.ref_code ||
              timelineModal.timeLineData?.reference}

            {orderDetail.group === 'PARENT_ORDER' &&
              orderDetail.order.child_orders.length > 1 &&
              ` (Including ${
                orderDetail.order.child_orders.length - 1
              } Child Orders)`}
            {orderDetail.group === 'SECONDARY_CUSTOMER' &&
              ` (${orderDetail.order.child_orders.length} Orders)`}
          </div>

          <div className='timeline-container rubik-font'>
            <div className='timeline-items'>
              {orderStatus?.map((o, i) => {
                return (
                  <Fragment key={i}>
                    <TimelineItem
                      status={o.status}
                      title={o.title}
                      created_on={o.created_on}
                      subtitle={o.subtitle}
                      first_operation_time={o.first_operation_time}
                      last_operation_time={o.last_operation_time}
                      firstOrder={o.firstOrder}
                    />
                  </Fragment>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default TimeLineModal;
