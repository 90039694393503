//import React from 'react';
import useMobile from '../../hooks/useMobile';

export const OnlyReact = ({ children }) => {
  const [isMobile] = useMobile();
  return isMobile ? children : null;
};
export const AboveMobile = ({ children }) => {
  const [isMobile] = useMobile();
  return !isMobile ? children : null;
};
export const AboveTab = ({ children }) => {
  //eslint-disable-next-line
  const [isMobile, isTab] = useMobile();
  return !isTab ? children : null;
};

export default OnlyReact;
