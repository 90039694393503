import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { cloneDeep } from 'lodash';
import { toastError } from '../../../helpers/packing/packingHelper';

const PrintAllPartHeader = ({ onSelectAll, selectAll, onPartSearch }) => {
  return (
    <Fragment>
      <div
        className='rubik-font d-flex bg-white pt-2 pb-2 px-3 '
        style={{
          fontWeight: 'bold',
          color: '#323232',
          fontSize: '12px',
          borderBottom: '1px solid #979797',
        }}
      >
        <div
          className='bg-white px-1  d-flex'
          style={{
            width: '60px',
          }}
        >
          <div>
            <input
              type='checkbox'
              className='main-box-checkbox cursor-pointer'
              value={true}
              disabled={false}
              onChange={() => {
                onSelectAll(!selectAll);
              }}
              checked={selectAll}
            />
          </div>
        </div>
        <div
          style={{ width: '60px' }}
          className='d-flex px-1 align-items-center'
        >
          #
        </div>
        <div
          style={{ width: '300px' }}
          className='d-flex px-1 align-items-center'
        >
          PART NUMBER
        </div>
        <div
          style={{ width: '160px' }}
          className='d-flex px-1 align-items-center'
        >
          LENGTH
        </div>
        <div style={{ width: '160px' }} className='d-flex px-1  '>
          WIDTH
        </div>
      </div>
      <div
        className='rubik-font d-flex bg-white pt-2 pb-2 px-3 '
        style={{
          fontWeight: 'bold',
          color: '#323232',
          fontSize: '12px',
        }}
      >
        <div
          className='bg-white px-1  d-flex'
          style={{
            width: '60px',
          }}
        ></div>
        <div
          style={{ width: '60px' }}
          className='d-flex px-1 align-items-center '
        ></div>
        <div
          style={{ width: '300px' }}
          className='d-flex px-1 align-items-center '
        >
          <input
            type='search'
            style={{ maxHeight: '32px' }}
            className='table-search-input py-0'
            placeholder={'Part Name'}
            onClick={e => e.stopPropagation()}
            onChange={e => {
              onPartSearch(e.target.value ? e.target.value : '');
            }}
          />
        </div>
        <div
          style={{ width: '160px' }}
          className='d-flex px-1 align-items-center '
        ></div>
        <div
          style={{ width: '160px' }}
          className='d-flex px-1 align-items-center '
        ></div>
      </div>
    </Fragment>
  );
};

const PrintAllPartRow = ({ part, index, onPartSelectCb }) => {
  return (
    <Fragment>
      <div
        className='d-flex bg-white pt-2 pb-2 px-3 '
        style={{
          color: '#323232',
          fontSize: '12px',
        }}
      >
        <div
          className='px-1  d-flex'
          style={{
            width: '60px',
          }}
        >
          <div>
            <input
              type='checkbox'
              className='main-box-checkbox cursor-pointer'
              value={true}
              disabled={false}
              onChange={() => {
                onPartSelectCb(!part.selected);
              }}
              checked={part.selected}
            />
          </div>
        </div>
        <div
          style={{ width: '60px' }}
          className='d-flex px-1 align-items-center'
        >
          {index + 1}
        </div>
        <div
          style={{ width: '300px' }}
          className='d-flex px-1 align-items-center'
        >
          {part.type === 'OFFCUT' ? 'OFFCUT' : part.part_name}
        </div>
        <div
          style={{ width: '160px' }}
          className='d-flex px-1 align-items-center'
        >
          {Math.round(part.length * 10) / 10}
        </div>
        <div style={{ width: '160px' }} className='d-flex px-1  '>
          {Math.round(part.width * 10) / 10}
        </div>
      </div>
    </Fragment>
  );
};

export const getPartsFromPattern = pattern => {
  const boardParts = [];
  let maxParts = 0;
  pattern.pattern_boards.forEach(board => {
    const parts = board.parts.map(part => {
      const _part = cloneDeep(part);
      _part.type = 'PART';
      _part.board_id = board.uuid;
      _part.selected = false;
      return _part;
    });
    const remnants = board.remnants.map(part => {
      const _part = cloneDeep(part);
      _part.type = 'REMNANT';
      _part.board_id = board.uuid;
      _part.selected = false;
      return _part;
    });
    const partsAdnOffcuts = [...parts, ...remnants];
    boardParts.push(partsAdnOffcuts);
    maxParts = Math.max(maxParts, partsAdnOffcuts.length);
  });
  const allParts = [];
  for (let i = 0; i < maxParts; i++) {
    boardParts.forEach(parts => {
      if (parts[`${i}`]) {
        allParts.push(parts[`${i}`]);
      }
    });
  }
  return allParts;
};

const PatternPrintAllModal = ({ pattern, onCloseCb, onPartPrint }) => {
  const [parts, setParts] = useState([]);
  useEffect(() => {
    setParts(getPartsFromPattern(pattern));
  }, [pattern, setParts]);
  return (
    <Fragment>
      <Modal
        centered={true}
        size={'lg'}
        show={true}
        onHide={onCloseCb}
        dialogClassName='track-trace-dialog'
      >
        <Modal.Header
          className='px-32'
          style={{ borderBottom: '0px' }}
          closeButton
        >
          <h3>Print Part Labels</h3>
        </Modal.Header>
        <Modal.Body className='px-16 py-16'>
          <div>
            <PrintAllPartHeader
              selectAll={
                parts.filter(val => val.selected).length === parts.length
              }
              onSelectAll={selected => {
                setParts(parts => {
                  const updatedParts = [...parts];
                  return updatedParts.map(val => {
                    val.selected = selected === true;
                    return val;
                  });
                });
              }}
            />
            <div className='overflow-scroll' style={{ maxHeight: '500px' }}>
              {parts.map((part, index) => {
                return (
                  <PrintAllPartRow
                    key={part.uuid}
                    part={part}
                    index={index}
                    onPartSelectCb={() => {
                      setParts(parts => {
                        const updatedParts = [...parts];
                        return updatedParts.map(val => {
                          if (val.uuid === part.uuid) {
                            const _val = cloneDeep(val);
                            _val.selected = !_val.selected;
                            return _val;
                          }
                          return val;
                        });
                      });
                    }}
                  />
                );
              })}
            </div>
            <div className='d-flex justify-content-end py-3 '>
              <div
                className='btn btn-outline-primary me-3 '
                onClick={onCloseCb}
              >
                CANCEL
              </div>
              <div
                className='btn btn-primary'
                onClick={() => {
                  const selectedParts = parts.filter(val => val.selected);
                  if (selectedParts.length === 0) {
                    toastError('Select atleast one part to print');
                    return;
                  }
                  onPartPrint(selectedParts);
                }}
              >
                PRINT
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export default PatternPrintAllModal;
