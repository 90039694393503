import service from './index';
import { getState } from '../store';

export const getLinkWorkstation = req => {
  const {
    user: { org, branch },
  } = getState();
  req.req_payload.branch_id = [branch.branch_id];
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationinstance/filter`,
    data: req,
  });
};

export const fetchOperations = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operations/filter`,
    data: req,
  });
};

export const createOperation = req => {
  const {
    user: { org },
  } = getState();
  req.req_payload.org_id = org.uuid;
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operations/update`,
    data: req,
  });
};
export const createWorkstationLink = req => {
  const {
    user: { org, branch },
  } = getState();
  req.req_payload.branch_id = branch.branch_id;
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationoperation/update`,
    data: req,
  });
};

export const fetchWorkstationLink = req => {
  const {
    user: { org, branch },
  } = getState();
  req.req_payload.org_id = org.uuid;
  req.req_payload.branch_id = [branch.branch_id];
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationoperation/filter`,
    data: req,
  });
};
export const deleteOperation = tid => {
  const {
    user: { org },
  } = getState();

  return service({
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operations/id/${tid}`,
    method: 'DELETE',
  });
};

export const deleteOperationLink = tid => {
  const {
    user: { org },
  } = getState();

  return service({
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationoperation/id/${tid}`,
    method: 'DELETE',
  });
};
