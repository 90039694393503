"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reducer = _interopRequireDefault(require("./busy/reducer"));

var _reducer2 = _interopRequireDefault(require("./lang/reducer"));

var _reducer3 = _interopRequireDefault(require("./user/reducer"));

var _reducer4 = _interopRequireDefault(require("./token/reducer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var reducers = {
  busy: _reducer.default,
  lang: _reducer2.default,
  user: _reducer3.default,
  token: _reducer4.default
};
var _default = reducers;
exports.default = _default;