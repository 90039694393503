import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from './icons';

const Chip = ({ children, onClick }) => {
  return (
    <div className='chip' onClick={onClick}>
      <div className='d-flex justify-content-between align-items-center h-100'>
        {children}
        <div className='chip-cross-btn d-flex justify-content-center align-items-center'>
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

Chip.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export default Chip;
