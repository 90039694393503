import React from 'react';

import PropTypes from 'prop-types';

const IconPrint = ({ fill, onClick }) => {
  return (
    <svg
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 0H16V4H4V0ZM3 5H17C18.66 5 20 6.34 20 8V14H16V18H4V14H0V8C0 6.34 1.34 5 3 5ZM6 16H14V11H6V16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9Z'
        fill={fill}
      />
    </svg>
  );
};

IconPrint.propTypes = {
  fill: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default IconPrint;
