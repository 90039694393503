import service from './index';
import { getState } from '../store';

export const getLinkStation = req => {
  const {
    user: { org },
  } = getState();

  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stations/filter`,
    data: req,
  });
};

export const fetchWorkstations = req => {
  const {
    user: { org, branch },
  } = getState();
  req.req_payload.branch_id = [branch.branch_id];
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationinstance/filter`,
    data: req,
  });
};

export const createWorkstation = req => {
  const {
    user: { org, branch, user_id },
  } = getState();
  req.user_id = user_id;
  req.req_payload.branch_id = branch.branch_id;
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationinstance/update`,
    data: req,
  });
};
export const createOperationStationLink = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationoperation/update`,
    data: req,
  });
};

export const deleteWorkstation = tid => {
  const {
    user: { org },
  } = getState();

  return service({
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationinstance/id/${tid}`,
    method: 'DELETE',
  });
};
