import React, { useCallback, Fragment, useEffect, useState } from 'react';
import { SortAscending } from '../../assets/icons/table-icon';
import MappingImg from '../../assets/img/mapping.svg';
import Select from '../../components/uploadformat/select-upload';
import BlackClose from '../../assets/icons/black-close.svg';
import { cloneDeep } from 'lodash';
import BottomDrawerItems from './bottom-drawer-items';
import SearchIcon from '../../assets/icons/searchIcon.svg';
const SPBETWN = 'space-between';
const featureData = (searchText, setSearchText, item, setFeatureOpen) => {
  return (
    <div>
      <div style={{ fontSize: '20px', textAlign: 'center' }}>
        SELECT ITEM FEATURE
      </div>
      <div style={{ height: '30px' }}></div>
      <div>
        <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
        <input
          type='text'
          id='search_field'
          className='searchModal'
          style={{
            border: '0px !important',
            color: 'rgba(50, 50, 50, 0.4)',
            fontStyle: 'italic',
          }}
          value={searchText}
          onChange={e => {
            setSearchText(e.target.value);
          }}
          placeholder='Type here to search Feature'
        />
      </div>
      {Object.values(item.definition) &&
        Object.values(item.definition).length > 0 &&
        Object.values(item.definition).map((row, index) => (
          <div key={row.name}>
            <div
              key={row.name}
              style={{
                fontSize: '14px',
                height: '20px',
                paddingTop: '12px',
                cursor: 'pointer',
                color: '#006EED',
              }}
              onClick={() => {
                setFeatureOpen(false);
              }}
            >
              {row.name}
            </div>
            <div
              className='operation-checklist-line'
              style={{
                borderColor: '#C4C4C4',
              }}
            ></div>
          </div>
        ))}
    </div>
  );
};
const ItemFeaturesFilter = ({
  isMobile,
  isTab,
  setItemOpen,
  onFeatureSelect,
  item,
}) => {
  const getOptions = useCallback(
    term => {
      return new Promise((resolve, reject) => {
        if (Object.values(item.definition)) {
          resolve(
            Object.values(item.definition).filter(val =>
              val.feature_code.includes(term.toUpperCase()),
            ),
          );
        } else {
          reject([]);
        }
      });
    },
    [item],
  );
  return (
    <Fragment>
      {item && item.uuid && !(isMobile || isTab) && (
        <Select
          label={`Add +`}
          getOptions={getOptions}
          labelField='name'
          valueField='feature_code'
          onSelect={i => {
            onFeatureSelect(i.feature_code);
          }}
          //selected={pipelineMode ? null : selection}
          //className='page-size-selector dropdown-table-filter'
          search
        />
      )}
      {(isMobile || isTab) && (
        <div onClick={() => setItemOpen(true)}>Add +</div>
      )}
    </Fragment>
  );
};
const Mapping = ({
  isMobile,
  isTab,
  headerList,
  setHeaderList,
  selMapping,
  selectedItem,
  selectedFeature,
  setSelectedFeature,
  mappedFields,
}) => {
  const MAP_VAL = 'HEADER';
  const [featureOpen, setFeatureOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    setHeaderList(Object.keys(mappedFields));
    setSelectedFeature(Object.values(mappedFields));
  }, [mappedFields, setHeaderList, setSelectedFeature]);

  const delMapping = useCallback(
    (index, feature) => {
      setSelectedFeature(sFeatures => {
        return sFeatures.map((val, i) => {
          if (i === index) {
            const _val = cloneDeep(val);
            const valIndex = _val.indexOf(feature);
            if (valIndex >= 0) {
              _val.splice(valIndex, 1);
            }
            return _val;
          }
          return val;
        });
      });
    },
    [setSelectedFeature],
  );
  return (
    <div
      className='bg-white'
      style={{
        alignContent: 'left',
        marginLeft: isMobile ? '30px' : '40px',
        marginRight: '30px',
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: SPBETWN,
            maxWidth: '888px',
          }}
        >
          <div>Mapping</div>
        </div>
        <div style={{ height: '20px' }}></div>
        {!(isMobile || isTab) && (
          <div
            style={{
              maxWidth: '888px',
              display: 'flex',
              paddingBottom: '8px',
              fontWeight: 'bold',
              fontSize: '10px',
            }}
          >
            <div
              style={{
                borderBottom: '2px',
                borderColor: '#027AFF',
                borderStyle: 'solid',
                borderTop: '0px',
                borderLeft: '0px',
                borderRight: '0px',
                height: '25px',
              }}
            >
              <div
                style={{
                  width: '220px',
                  height: '14px',
                  justifyContent: SPBETWN,
                  display: 'flex',
                }}
              >
                <div>HEADERS</div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20px' }}>
                    <SortAscending />
                  </div>
                  <div
                    style={{
                      height: '24px',
                      width: '10px',
                      border: '0px',
                      borderRight: '1px',
                      borderStyle: 'dashed',
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              style={{
                borderBottom: '2px',
                borderColor: '#C0C0C0',
                borderStyle: 'solid',
                borderTop: '0px',
                borderLeft: '0px',
                borderRight: '0px',
                height: '25px',
                width: '668px',
              }}
            >
              <div
                style={{
                  width: '110px',
                  display: 'flex',
                  marginLeft: '15px',
                }}
              >
                <div>ITEM FEATURE</div>
              </div>
            </div>
          </div>
        )}
      </div>

      {headerList &&
        headerList.length > 0 &&
        // eslint-disable-next-line sonarjs/cognitive-complexity
        headerList.map((row, i) => {
          const headerMappedVals = selectedFeature[`${i}`] || [];
          return (
            <div key={i}>
              <div
                style={{
                  maxWidth: '888px',
                  paddingBottom: '8px',
                  paddingTop: '8px',
                  height: '43px',
                  display: 'flex',
                }}
              >
                <div style={{ width: '220px' }}>
                  {selMapping === MAP_VAL ? row : `Column ${i + 1}`}
                </div>

                <div
                  style={{
                    color: '#006EED',
                    cursor: 'pointer',
                    marginLeft: '15px',
                  }}
                >
                  <ItemFeaturesFilter
                    isMobile={isMobile}
                    isTab={isTab}
                    setItemOpen={setFeatureOpen}
                    itemData={featureData(
                      searchText,
                      setSearchText,
                      selectedItem,
                      setFeatureOpen,
                    )}
                    onFeatureSelect={featureCode => {
                      let selected = false;
                      setSelectedFeature(selFeature => {
                        return selFeature.map((val, index) => {
                          for (let i = 0; i < selFeature.length; i++) {
                            // eslint-disable-next-line security/detect-object-injection
                            if (selFeature[i].indexOf(featureCode) >= 0)
                              selected = true;
                          }

                          if (
                            index === i &&
                            val.indexOf(featureCode) === -1 &&
                            !selected
                          ) {
                            return [...val, featureCode];
                          }
                          return val;
                        });
                      });
                    }}
                    item={selectedItem}
                  />
                  <BottomDrawerItems
                    isMobile={isMobile}
                    isTab={isTab}
                    itemOpen={featureOpen}
                    setItemOpen={() => setFeatureOpen}
                    itemData={featureData(
                      searchText,
                      setSearchText,
                      selectedItem,
                      setFeatureOpen,
                    )}
                  />
                </div>
                <div className='d-flex' style={{ marginLeft: '12px' }}>
                  {!(isMobile || isTab) &&
                    headerMappedVals &&
                    headerMappedVals.map((featureCode, index) => {
                      return (
                        <div
                          key={row.uuid || index}
                          className='filter-btns'
                          style={{
                            border: '1px solid',
                            color: '#027AFF !important',
                            display: 'flex',
                          }}
                        >
                          <div style={{ marginRight: '12px' }}>
                            {featureCode}
                          </div>
                          <div>
                            <img
                              src={BlackClose}
                              alt=''
                              onClick={() => delMapping(i, featureCode)}
                            ></img>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div style={{ display: 'flex', paddingBottom: '8px' }}>
                {(isMobile || isTab) &&
                  headerMappedVals &&
                  headerMappedVals.map((featureCode, index) => {
                    return (
                      <div
                        key={row.uuid || index}
                        className='filter-btns'
                        style={{
                          border: '1px solid',
                          color: '#027AFF !important',
                          display: 'flex',
                          width: 'fit-content',
                        }}
                      >
                        <div style={{ marginRight: '12px' }}>{featureCode}</div>
                        <div>
                          <img
                            src={BlackClose}
                            alt=''
                            onClick={() => delMapping(i, featureCode)}
                          ></img>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div
                style={{
                  backgroundColor: '#DEDEDE',
                  width: '888px',
                  height: '1px',
                }}
              ></div>
            </div>
          );
        })}
      {(!headerList || headerList.length === 0) && (
        <div>
          <div style={{ height: '120px' }}></div>
          <div style={{ paddingLeft: '205px' }}>
            <img src={MappingImg} alt=''></img>
          </div>
          <div style={{ height: '27px' }}></div>
          <div className='d-flex' style={{ paddingLeft: '105px' }}>
            <div style={{ color: '#323232' }}>Nothing to show.</div>
            <div
              style={{
                color: '#006EED',
                paddingLeft: '5px',
                paddingRight: '5px',
              }}
            >
              Add a sample file
            </div>
            <div style={{ color: '#323232' }}>to map</div>
          </div>
          <div style={{ height: '120px' }}></div>
        </div>
      )}
    </div>
  );
};

export default Mapping;
