import React, { Fragment } from 'react';

import '../../scss/components/_dropdown.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import DropDownSmall from '../../assets/icons/dropDownSmallBlue.svg';
import { MoreDropdown } from '../../assets/icons/common-icons';
const NewBoxMenu = ({
  id,
  box,
  isMobile,
  isTab,
  setMenuOpen,
  setBotid,
  downloadBot,
  deleteBot,
  titleText,
  setTitleText,
}) => {
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer`}
        ref={ref}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );

  const BottomDrawerMenu = id => {
    setBotid(id);
    setTitleText(titleText);
    setMenuOpen(true);
  };

  return (
    <Fragment>
      {!isMobile && !isTab && (
        <div style={{ marginTop: '-6px' }}>
          <Dropdown className='page-size-selector dropdown-table-filter'>
            <Dropdown.Toggle as={CustomToggle}>
              {box && <MoreDropdown />}
              {!box && (
                <div
                  className='d-flex'
                  style={{ color: '#006EED', cursor: 'pointer' }}
                >
                  Actions
                  <div style={{ marginLeft: '16px' }}>
                    <img src={DropDownSmall} alt=''></img>
                  </div>
                </div>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => downloadBot(id)}
                key='0'
                eventKey='0'
              >
                DOWNLOAD BOT
              </Dropdown.Item>

              <Dropdown.Item onClick={() => deleteBot(id)} key='3' eventKey='3'>
                DELETE
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      {(isMobile || isTab) && (
        <MoreDropdown onClick={() => BottomDrawerMenu(id)} />
      )}
    </Fragment>
  );
};
export default NewBoxMenu;
