import React, { Fragment, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { NaadiModalCloseIcon } from '../../assets/icons/common-icons';
import Form from 'react-bootstrap/Form';

const QueryModal = ({
  showModal,
  closeCallback,
  isMobile,
  isTab,
  hookValue,
  setHookValue,
  form,
  bottype,
}) => {
  useEffect(() => {
    setHookValue(form.customhook);
  }, [form, setHookValue]);
  const saveQuery = () => {
    if (bottype === 'DESIGN_TOOL') form.query = hookValue;
    else form.port = hookValue;
    closeCallback();
  };
  return (
    <Fragment>
      <Modal
        centered={true}
        size={'xl'}
        show={showModal}
        onHide={closeCallback}
        dialogClassName='template-dialog'
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <Modal.Body style={{ padding: '0px' }}>
            {' '}
            <div
              style={{ margin: 'auto', padding: '24px', position: 'relative' }}
            >
              <div className='row'>
                <div className='col-11' style={{ fontSize: '24px' }}>
                  {bottype === 'DESIGN_TOOL' ? 'Edit Query' : 'Edit Port'}
                </div>
                <div className='col-1' onClick={closeCallback}>
                  <NaadiModalCloseIcon />
                </div>
              </div>
              <div style={{ height: '24px' }}></div>
              <div></div>
              <div style={{ height: '30px' }}></div>
              <div>
                <div
                  style={{
                    height: '352px',
                    cap: 'Round',
                    borderRadius: '10px',
                    borderStyle: 'solid',
                    borderColor: '#979797',
                  }}
                >
                  <div>
                    <div
                      style={{
                        font: 'Roboto',
                        fontSize: '16px',
                        color: 'rgba(50, 50, 50, 0.4)',
                        background: 'white',
                      }}
                    >
                      <Form.Control
                        as='textarea'
                        rows={15}
                        name='hook'
                        value={hookValue}
                        onChange={e => setHookValue(e.target.value)}
                        placeholder='Enter Custom Hook'
                        autoComplete='off'
                        style={{ padding: '16px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ height: '20px' }}></div>
              <div style={{ textAlign: 'right', display: 'flex' }}>
                <button
                  className='btn btn-outline-primary btn-md btn-save-changes'
                  style={{
                    marginRight: '12px',
                    background: 'white',
                    textAlign: 'center',
                  }}
                  onClick={closeCallback}
                >
                  Close
                </button>
                <button
                  className='btn btn-primary btn-md btn-save-changes'
                  style={{
                    marginRight: '12px',
                    textAlign: 'center',
                  }}
                  onClick={saveQuery}
                >
                  Save
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    </Fragment>
  );
};

export default QueryModal;
