import service from './index';
import { getState } from '../store';

export const getCurrentUserOrgAccess = (org, org_user) => {
  const { user } = getState();
  const { org_users } = user || {};
  const _org_user = (org_users ? org_users[`${org.uuid}`] : org_user) || {};
  return service({
    method: 'GET',
    url: `/api/naadi-auth-service/v1/naadi/auth/${org.uuid}/orguseraccess/access/${_org_user.org_user_id}`,
    data: {},
  });
};
