import React, { Fragment } from 'react';
import {
  TrackTraceViewModeDetail,
  TrackTraceViewModeImage,
  TrackTraceViewModeLargeImage,
  TrackTraceViewModeList,
  TrackTraceViewModeSmallImage,
} from '../../assets/icons/icon-tracktrace';
import useMobile from '../../hooks/useMobile';
import { useDispatch, useSelector } from 'react-redux';
import {
  setGroupByFilter,
  setImageViewMode,
  setListViewMode,
} from '../../store/tracktrace/tracktrace';
import GroupFilterDropdown from './group/group-filter';
import { SearchBar } from '../common/search';
import { PrinterSelector } from '../../views/order/labelprint/PrinterSelector';

export const TrackTraceWoViewModes = ({
  onSearch,
  handlePrintAll,
  printers,
}) => {
  return (
    <Fragment>
      <div className={'row pt-4'}>
        <div className={'col-12 col-md-6 col-lg-7 col-xl-8'}>
          <div className={'d-flex align-items-center justify-content-start'}>
            <SearchBar
              placeholder='Select Material'
              onSearch={onSearch}
              on={'key'}
            />
            <div className='d-flex w-100 justify-content-end'>
              <PrinterSelector
                printers={printers}
                onPrintCb={printer => {
                  handlePrintAll(printer);
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <div>Print All</div>
              </PrinterSelector>
            </div>
          </div>
        </div>
        <div
          className={
            'col-12 col-md-6 col-lg-5 col-xl-4 d-flex align-items-end justify-context-start'
          }
        >
          <div className='bold'>Pressing Currently</div>
        </div>
      </div>
    </Fragment>
  );
};

export const TrackTraceViewModes = ({ pipelineMode, orderItems }) => {
  const [isMobile, isTab] = useMobile();
  const dispatch = useDispatch();
  const { image_view_mode, list_view_mode, order_items } = useSelector(
    state => state.tracktrace,
  );
  const { ctx } = useSelector(state => state.stationstate);
  return (
    <Fragment>
      <div className={'row pt-4'}>
        <div className={'col-6'}>
          {((ctx.value && ctx.value.key) || pipelineMode === true) && (
            <div className='d-flex justify-content-start align-items-center primary-text-color'>
              {!isTab && !isMobile && pipelineMode === false && (
                <div className=' pe-3'>Recent Scans:</div>
              )}
              <div className=''>
                <div
                  className='text-label ps-1'
                  onClick={e => dispatch(setGroupByFilter(null))}
                >
                  GROUP BY
                </div>
                <div
                  className='position-relative '
                  style={{ maxWidth: '300px' }}
                >
                  <GroupFilterDropdown
                    order_items={
                      pipelineMode === true ? orderItems : order_items
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={'col-6'}>
          <div className={'d-flex align-items-center justify-content-end'}>
            <div className={'secondary-text track-trace-view-mode-text pe-4'}>
              VIEW MODES:
            </div>
            <div className='pe-2'>
              <div
                onClick={e => {
                  dispatch(setListViewMode(true));
                }}
                className={`track-trace-view-mode-icon-wrp ${
                  list_view_mode ? 'selected' : ''
                }`}
              >
                <TrackTraceViewModeList />
              </div>
            </div>
            <div className='pe-2'>
              <div
                onClick={e => {
                  dispatch(setListViewMode(false));
                }}
                className={`track-trace-view-mode-icon-wrp ${
                  !list_view_mode ? 'selected' : ''
                }`}
              >
                <TrackTraceViewModeDetail />
              </div>
            </div>
            {isMobile === false && (
              <div className='pe-2'>
                <div
                  onClick={e => {
                    dispatch(
                      setImageViewMode(
                        image_view_mode === 'SMALL' ? 'NONE' : 'SMALL',
                      ),
                    );
                  }}
                  className={`track-trace-view-mode-icon-wrp ${
                    image_view_mode === 'SMALL' ? 'selected' : ''
                  }`}
                >
                  <TrackTraceViewModeSmallImage />
                </div>
              </div>
            )}
            {isMobile === false && (
              <div className='pe-2'>
                <div
                  onClick={e =>
                    dispatch(
                      setImageViewMode(
                        image_view_mode === 'LARGE' ? 'NONE' : 'LARGE',
                      ),
                    )
                  }
                  className={`track-trace-view-mode-icon-wrp ${
                    image_view_mode === 'LARGE' ? 'selected' : ''
                  }`}
                >
                  <TrackTraceViewModeLargeImage />
                </div>
              </div>
            )}
            {isMobile === true && (
              <div className='pe-2'>
                <div
                  onClick={e =>
                    dispatch(
                      setImageViewMode(
                        image_view_mode === 'NONE' ? 'SMALL' : 'NONE',
                      ),
                    )
                  }
                  className={`track-trace-view-mode-icon-wrp ${
                    image_view_mode === 'SMALL' || image_view_mode === 'LARGE'
                      ? 'selected'
                      : ''
                  }`}
                >
                  <TrackTraceViewModeImage />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

TrackTraceViewModes.propTypes = {};

export default TrackTraceViewModes;
