/* eslint-disable sonarjs/cognitive-complexity */
import {
  generateReport,
  getReportColumns,
  getReportConfigByCode,
  getReportConfigs,
  getReportDefaultUserConfig,
  getReportFilters,
  getReports,
} from '../../services/report';
import { ResponseCodes } from '@naadi/framework';
import { toastResponseError } from '../common/helper';
import { cloneDeep, isEqual } from 'lodash';
import {
  setCurrentReport,
  setCurrentReportConfig,
  setReportFilter,
} from '../../store/report/reportfilters';
import { subMonths } from 'date-fns';
import { toastError } from '../packing/packingHelper';
import {
  resetReportNote,
  setReportConfigs,
  setReportResult,
} from '../../store/report/reportresult';
import qs from 'qs';
import { getState } from '../../store';

export const formatReportFilter = params => {
  return {
    ...params,
    fields: params.fields || {},
    from_date: params.from_date || subMonths(new Date(), 1),
    to_date: params.to_date || new Date(),
    page: params.page || 0,
    size: params.size || 50,
  };
};

export const applyDefaultFilter = () => async (dispatch, getState) => {
  const currentReport = getState().reportfilter.current_report;
  if (!currentReport || !currentReport.uuid) return;
  const reportFilter =
    getState().reportfilter.report_filters[currentReport.uuid];
  if (!reportFilter) return;
  const filters = currentReport.filters;
  if (!filters || filters.length === 0) return;
  const _reportFilter = cloneDeep(reportFilter);
  if (!_reportFilter.fields) {
    _reportFilter.fields = {};
  }
  filters.forEach(filter => {
    if (!filter.default_value) return;
    const fieldVal = _reportFilter.fields[filter.field_name] || [];
    if (fieldVal.length > 0) return;
    switch (filter.column_type) {
      case 'NUMBER':
        if (!isNaN(fieldVal)) {
          fieldVal.push({ k: filter.default_value });
        }
        break;
      case 'STRING':
        fieldVal.push({ k: filter.default_value });
        break;
      case '':
      default:
        break;
    }
    if (fieldVal.length === 0) return;
    _reportFilter.fields[filter.field_name] = fieldVal;
  });
  const payload = {
    [currentReport.uuid]: _reportFilter,
  };
  dispatch(setReportFilter(payload));
};

export const getUnselectedColumns = report => {
  const selectedColumns = report.selected_columns || [];
  return report.columns
    .filter(val => val.active === true)
    .map(val => val.alias_name)
    .filter(val => selectedColumns.indexOf(val) === -1);
};
export const selectReportById = async (
  report_id,
  filter,
  filters,
  dispatch,
) => {
  const reqFilter = {
    page: 0,
    size: 100,
    req_payload: {
      uuid: [report_id],
    },
  };
  const selectReportResult = await getReports(reqFilter);
  if (selectReportResult.status !== ResponseCodes.SUCCESS) {
    toastResponseError(selectReportResult, 'Unable to fetch the report detail');
    return;
  }
  const reports = selectReportResult.payload;
  if (reports.length === 0) {
    toastError('Report Not Found');
    return;
  }

  await selectReport(
    reports[0],
    filters ? filters.preset : null,
    false,
    true,
    dispatch,
  );
  if (filter) {
    dispatch(
      setReportFilter({
        [report_id]: filter,
      }),
    );
  }
  dispatch(fetchCurrentReportResult());
};

export const selectReport = async (
  report,
  config_code,
  fetchResult,
  showLoader,
  dispatch,
) => {
  try {
    if (showLoader === true) {
      // dispatch(actions.busy.add('SELECT_REPORT_LOADER'));
    }
    const reportColumnsResponse = await getReportColumns(report.uuid);
    if (reportColumnsResponse.status !== ResponseCodes.SUCCESS) {
      toastResponseError(
        reportColumnsResponse,
        'Unable to fetch the Report Columns',
      );
      return;
    }
    const _report = cloneDeep(report);
    _report.columns = reportColumnsResponse.payload;
    const reportConfig = {
      code: 'DEFAULT',
      name: 'Default',
      report_id: report.uuid,
    };
    if (config_code) {
      reportConfig.code = config_code;
    }
    const defaultUserConfigResp = await getReportDefaultUserConfig(report.uuid);
    if (
      defaultUserConfigResp.status !== ResponseCodes.SUCCESS &&
      defaultUserConfigResp.status !== ResponseCodes.RECORD_NOT_FOUND_EXCEPTION
    ) {
      toastError('Unable to fetch the Report Config');
      return;
    } else if (defaultUserConfigResp.status === ResponseCodes.SUCCESS) {
      reportConfig.code = defaultUserConfigResp.payload;
    }
    const reportConfigsResp = await getReportConfigs(report.uuid);
    if (reportConfigsResp.status !== ResponseCodes.SUCCESS) {
      toastError('Unable to to fetch Report Configs');
      return;
    }
    const reportConfigs = reportConfigsResp.payload;
    const reportUserConfigResp = await getReportConfigByCode(
      report.uuid,
      config_code ? config_code : reportConfig.code,
    );
    if (
      reportUserConfigResp.status !== ResponseCodes.SUCCESS &&
      defaultUserConfigResp.status !== ResponseCodes.RECORD_NOT_FOUND_EXCEPTION
    ) {
      toastError('Unable to fetch the Report Config');
      return;
    }
    if (reportUserConfigResp.status === ResponseCodes.SUCCESS) {
      reportConfig.name = reportUserConfigResp.payload.name;
      reportConfig.code = reportUserConfigResp.payload.code;
      reportConfig.columns = reportUserConfigResp.payload.columns;
      reportConfig.freeze_columns = reportUserConfigResp.payload.freeze_columns;
    }

    const activeColumns = _report.columns
      .filter(val => val.active === true)
      .map(val => val.alias_name);
    if (!reportConfig.columns) {
      reportConfig.columns = _report.columns
        .filter(val => val.default_selected)
        .map(val => val.alias_name);
    }
    if (!reportConfig.freeze_columns) {
      reportConfig.freeze_columns = 1;
    }
    reportConfig.columns = reportConfig.columns.filter(
      val => activeColumns.indexOf(val) >= 0,
    );
    _report.selected_columns = reportConfig.columns;
    _report.freeze_columns = reportConfig.freeze_columns;

    const reportFilterResponse = await getReportFilters(report.uuid);
    if (reportFilterResponse.status !== ResponseCodes.SUCCESS) {
      toastResponseError(
        reportFilterResponse,
        'Unable to fetch the Report Filters',
      );
      return;
    }
    _report.filters = reportFilterResponse.payload;
    dispatch(setReportConfigs(reportConfigs));
    dispatch(
      setCurrentReportConfig({
        code: reportConfig.code,
        name: reportConfig.name,
      }),
    );
    dispatch(setCurrentReport(_report));
    dispatch(resetReportNote());
    dispatch(applyDefaultFilter());
    //
    const reportResult = getState().reportresult;
    if (
      reportResult &&
      reportResult.report_result &&
      reportResult.report_result.report_id !== _report.uuid
    ) {
      dispatch(setReportResult({ report_id: null, result: [] }));
    }
    if (fetchResult) {
      dispatch(fetchCurrentReportResult());
    }
  } finally {
    if (showLoader === true) {
      // dispatch(actions.busy.remove('SELECT_REPORT_LOADER'));
    }
  }
};
export const getCurrentReportFilter = (currentReport, reportFilter) => {
  if (!reportFilter) {
    return {
      missingMandatoryFields: [],
      filterValues: null,
    };
  }
  const fields = {};
  currentReport.filters.forEach(val => {
    fields[val.field_name] = {
      field_name: val.field_name,
      field_display_name: val.field_display_name,
      column_type: val.column_type,
      mandatory: val.mandatory,
    };
  });
  const filterValues = {};
  Object.keys(reportFilter.fields || {}).forEach(field => {
    if (!fields[`${field}`]) return;
    const values = reportFilter.fields[`${field}`];
    if (values.length === 0) return;
    switch (fields[`${field}`].column_type) {
      case 'NUMBER':
        filterValues[`${field}`] = values[0].v ? values[0].v : values[0].k;
        return;
      case 'STRING':
        filterValues[`${field}`] = values[0].v ? values[0].v : values[0].k;
        break;
      case 'ORDER_EXTERNAL_CODE':
      case 'ORDER_PARENT_CODE':
      case 'ORG_ENTITY':
        filterValues[`${field}`] = values.map(val => val.k);
        break;
      case 'ORDER_CODE':
      case 'SHIPMENT_ID':
        filterValues[`${field}`] = values.map(val => val.k);
        filterValues[`${field}_code`] = values.map(val => val.v);
        break;
      default:
        filterValues[`${field}`] = values.map(val => val.k);
    }
  });
  if (
    currentReport.from_date_field &&
    !filterValues[currentReport.from_date_field]
  ) {
    filterValues[currentReport.from_date_field] = reportFilter.from_date;
  }
  if (
    currentReport.to_date_field &&
    !filterValues[currentReport.to_date_field]
  ) {
    filterValues[currentReport.to_date_field] = reportFilter.to_date;
  }
  const missingMandatoryFields = currentReport.filters.filter(val => {
    let returnVal = false;
    if (val.mandatory === false) {
      returnVal = false;
    } else if (filterValues[val.field_name] === undefined) {
      returnVal = true;
    }
    return returnVal;
  });
  return {
    missingMandatoryFields: missingMandatoryFields,
    filterValues: filterValues,
  };
};
export const fetchCurrentReportResult =
  (groupFilter, setIsTableBusy) => async (dispatch, getState) => {
    setIsTableBusy(true);
    const currentReport = getState().reportfilter.current_report;
    if (!currentReport || !currentReport.uuid) return;
    const reportFilter =
      getState().reportfilter.report_filters[currentReport.uuid] || {};
    const { missingMandatoryFields, filterValues } = getCurrentReportFilter(
      currentReport,
      reportFilter,
    );
    if (missingMandatoryFields.length > 0) {
      dispatch(
        setReportResult({
          report_id: currentReport.uuid,
          result: [],
          page: 0,
        }),
      );
      toastError('Mandatory Filters Not Applied');
      return;
    }
    const filter = {
      page: reportFilter.page || 0,
      size: reportFilter.size || 50,
      req_payload: {
        report_id: currentReport.uuid,
        filter_values: filterValues,
        display_columns: currentReport.selected_columns || [],
        group_filter: groupFilter,
      },
    };
    try {
      const reportResult = await generateReport(filter);
      if (reportResult.status !== ResponseCodes.SUCCESS) {
        toastResponseError(reportResult, 'Unable to Generate the Report');
        return;
      }
      const resultPayload = reportResult.payload;
      dispatch(
        setReportResult({
          report_id: currentReport.uuid,
          result: resultPayload.script_response.result,
          page: filter.page,
        }),
      );
      setIsTableBusy(false);
    } catch (err) {
      toastResponseError(err, 'Unable to Generate the Report');
    }
  };

export const updateReportFilter = payload => (dispatch, getState) => {
  dispatch(setReportFilter(payload));
  //dispatch(fetchCurrentReportResult());
};
const setNumericValue = (obj, field, def) => {
  if (!obj) {
    return;
  }
  if (obj[`${field}`] === undefined) {
    obj[`${field}`] = def;
    return;
  }
  if (isNaN(obj[`${field}`])) {
    obj[`${field}`] = def;
  } else if (parseInt(obj[`${field}`]) !== obj[`${field}`]) {
    obj[`${field}`] = parseInt(obj[`${field}`]);
  }
};
export const updateReportQs = (history, location) => (dispatch, getState) => {
  const currentReport = getState().reportfilter.current_report || {};
  if (!currentReport || !currentReport.uuid) {
    return;
  }
  if (!history) return;
  const reportFilters = getState().reportfilter.report_filters || {};
  const reportFilter = cloneDeep(reportFilters[currentReport.uuid] || {});
  if (!currentReport.from_date_field || currentReport.from_date_field === '') {
    delete reportFilter.from_date;
  }
  if (!currentReport.to_date_field || currentReport.to_date_field === '') {
    delete reportFilter.to_date;
  }
  const { current_report_config } = getState().reportfilter;
  const params = {
    report_id: currentReport.uuid,
    org_id: currentReport.org_id,
    filter: reportFilter,
    cols: currentReport.selected_columns || [],
    preset:
      current_report_config && current_report_config.code
        ? current_report_config.code
        : 'DEFAULT',
  };
  const _params = location.search ? qs.parse(location.search.substring(1)) : {};
  if (_params.src_filter) {
    //params.src_filter = _params.src_filter;
  }
  if (!_params.cols) {
    _params.cols = [];
  }
  _params.cols = Object.values(_params.cols);
  if (!_params.filter) {
    _params.filter = { page: 0, size: 50, fields: {} };
  }
  if (!_params.filter.fields) {
    _params.filter.fields = {};
  }
  setNumericValue(_params.filter, 'size', 50);
  setNumericValue(_params.filter, 'page', 0);
  setNumericValue(params.filter, 'size', 50);
  setNumericValue(params.filter, 'page', 0);
  if (_params.preset) {
    //params.preset = _params.preset;
  }
  const stringified_params = qs.stringify(params);
  if (!isEqual(_params, params)) {
    if (location.search === '') {
      history.replace(`/app/report?${stringified_params}`);
    } else {
      history.push(`/app/report?${stringified_params}`);
    }
  }
};

export const reportHasDateFilter = report => {
  let returnVal = false;
  if (!report) {
    returnVal = false;
  } else if (report.from_date_field && report.from_date_field.length > 0) {
    returnVal = true;
  } else if (report.to_date_field && report.to_date_field.length > 0) {
    returnVal = true;
  }
  return returnVal;
};
