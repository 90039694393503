import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
import { cloneDeep } from 'lodash';

export const setReportFilterByReportId =
  (report_id, filter) => (dispatch, getState) => {
    const reportFilter = {
      [report_id]: filter,
    };
    dispatch(setReportFilter(reportFilter));
  };

export const archiveOrgReportFilters = org_uuid => (dispatch, getState) => {
  const filter = getState().reportfilter.report_filters;
  const currentReport = getState().reportfilter.current_report;
  const prevOrgFilter =
    getState().reportfilter.previous_org_report[`${org_uuid}`] || {};
  const newOrgFilter = prevOrgFilter.filter || {};
  const newCurrentReport = prevOrgFilter.report || {};
  if (currentReport.org_id) {
    const archive = {
      [currentReport.org_id]: {
        filter: cloneDeep(filter),
        report: cloneDeep(currentReport),
      },
    };
    dispatch(setPreviousOrgReport(archive));
  }
  dispatch(resetReport());
  dispatch(setCurrentReport(cloneDeep(newCurrentReport)));
  dispatch(setReportFilter(cloneDeep(newOrgFilter)));
};

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}
const getActionName = (type, verb) =>
  actionTypeName('REPORT_FILTER_STATE', type, verb);

export const TYPES = {
  REPORT_FILTER: 'REPORT_FILTER',
  CURRENT_REPORT: 'CURRENT_REPORT',
  PREVIOUS_ORG_REPORT: 'PREVIOUS_ORG_REPORT',
  REPORT_CONFIG: 'REPORT_CONFIG',
};

export const resetReport = createAction(
  getActionName(TYPES.REPORT_FILTER, 'UNSET'),
);

export const setReportFilter = createAction(
  getActionName(TYPES.REPORT_FILTER, 'SET'),
);

export const setCurrentReport = createAction(
  getActionName(TYPES.CURRENT_REPORT, 'SET'),
);

export const setPreviousOrgReport = createAction(
  getActionName(TYPES.PREVIOUS_ORG_REPORT, 'SET'),
);
export const setCurrentReportConfig = createAction(
  getActionName(TYPES.REPORT_CONFIG, 'SETCURRENT'),
);

export const DEFAULT = {
  report_filters: {},
  current_report: {},
  previous_org_report: {},
  report_config: {},
  current_report_config: {
    code: 'DEFAULT',
    name: 'Default',
  },
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetReport, state => {
    return Update(state, {
      current_report: { $set: {} },
      report_filters: { $set: {} },
    });
  });

  builder.addCase(setReportFilter, (state, { payload }) => {
    return Update(state, {
      report_filters: { $merge: payload },
    });
  });
  builder.addCase(setCurrentReport, (state, { payload }) => {
    return Update(state, {
      current_report: { $merge: payload },
    });
  });
  builder.addCase(setPreviousOrgReport, (state, { payload }) => {
    return Update(state, {
      previous_org_report: { $merge: payload },
    });
  });
  builder.addCase(setCurrentReportConfig, (state, { payload }) => {
    return Update(state, {
      current_report_config: { $set: payload },
    });
  });
});

export default reducer;
