import React from 'react';
//import BottomDrawer from '../../../components/template/bottomdrawer';
import BottomDrawer from '../../../components/groups/bottomdrawer';

const BottomDrawerContent = ({
  isMobile,
  isTab,
  menuOpen,
  setMenuOpen,
  title,
  menuList,
  menuClick,
  setMenuClick,
}) => {
  return (
    <BottomDrawer
      entity={''}
      drawerTitle=''
      handleClose={() => {
        setMenuOpen(false);
        setMenuClick('main');
      }}
      popUp={menuOpen && (isMobile || isTab)}
      drawerBody={
        <div
          style={{
            margin: 'auto',
            padding: '30px',
            position: 'relative',
          }}
        >
          <div style={{ fontSize: '20px', textAlign: 'center' }}>{title}</div>
          <div style={{ height: '20px' }}></div>
          <div>
            {menuClick === 'main' &&
              menuList.map(mL => {
                return (
                  <div
                    key={mL.key ? mL.key : mL.title}
                    style={{
                      color: '#006EED',
                      textAlign: 'center',
                      paddingTop: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      mL.title === 'BOM ACTIONS'
                        ? setMenuClick('bom')
                        : setMenuClick('operation');
                    }}
                  >
                    <div
                      style={{
                        height: '30px',
                        borderBottom: '1px',
                        borderBottomStyle: 'solid',
                        borderColor: 'gray',
                      }}
                    >
                      {mL.title}
                    </div>
                  </div>
                );
              })}
            {menuClick === 'bom' &&
              menuList[0].children.map(mL => {
                return (
                  <div
                    key={mL.key ? mL.key : mL.title}
                    style={{
                      color: '#006EED',
                      textAlign: 'center',
                      paddingTop: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      mL.onClick();
                      setMenuOpen(false);
                      setMenuClick('main');
                    }}
                  >
                    <div
                      style={{
                        height: '30px',
                        borderBottom: '1px',
                        borderBottomStyle: 'solid',
                        borderColor: 'gray',
                      }}
                    >
                      {mL.title}
                    </div>
                  </div>
                );
              })}
            {menuClick === 'operation' &&
              menuList[1].children.map(mL => {
                return (
                  <div
                    key={mL.key ? mL.key : mL.title}
                    style={{
                      color: '#006EED',
                      textAlign: 'center',
                      paddingTop: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      mL.onClick();
                      setMenuOpen(false);
                      setMenuClick('main');
                    }}
                  >
                    <div
                      style={{
                        height: '30px',
                        borderBottom: '1px',
                        borderBottomStyle: 'solid',
                        borderColor: 'gray',
                      }}
                    >
                      {mL.title}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      }
    />
  );
};

export default BottomDrawerContent;
