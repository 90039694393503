import { DEFAULT } from './const';
import { createReducer } from '@reduxjs/toolkit';
import {
  addItem,
  setItems,
  setBoxes,
  fetchBoxes,
  fetchBoxesRestore,
  fetchBoxItems,
  fetchBoxItemsRestore,
  addBoxItemAsync,
  syncBoxItem,
  addBoxAsync,
  deleteBox,
  removeBoxItem,
  removeBoxItemHistory,
  resetItemsList,
} from './actions';

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetItemsList, state => {
    state.fetchBoxItems = [];
    state.items = [];
    state.boxesItems = [];
    state.fetchBoxes = [];
  });
  builder.addCase(addItem, (state, action) => {
    if (!action.payload) return;
    const items = state.items;
    const item = action.payload;
    const index = items.findIndex(val => val.uuid === item.uuid);
    if (index >= 0) {
      if (item.released === false) {
        items.splice(index, 1);
      } else {
        items[parseInt(index)] = item;
      }
    } else if (item.deleted === false && item.released === true) {
      items.push(item);
    }
  });
  builder.addCase(setItems, (state, action) => {
    const items = state.items;
    items.splice(0, items.length);
    const filteredItems = action.payload.filter(
      val => !val.deleted && val.released,
    );
    items.push(...filteredItems);
  });
  builder.addCase(setBoxes, (state, action) => {
    const boxesItems = state.boxesItems;
    if (boxesItems.length > 0) {
      boxesItems.splice(0, boxesItems.length);
    }
    boxesItems.push(...action.payload);
  });
  builder.addCase(fetchBoxes, (state, action) => {
    const fetchBoxes = state.fetchBoxes;
    action.payload.forEach(val => {
      if (val.synced === false) return;
      val.synced = true;
    });
    fetchBoxes.splice(0, fetchBoxes.length);
    fetchBoxes.push(...action.payload);
  });
  builder.addCase(fetchBoxesRestore, (state, action) => {
    const fetchBoxes = state.fetchBoxes;
    fetchBoxes.splice(0, fetchBoxes.length);
    fetchBoxes.push(...action.payload);
  });
  builder.addCase(fetchBoxItems, (state, action) => {
    const fetchBoxItems = state.fetchBoxItems;
    fetchBoxItems.splice(0, fetchBoxItems.length);
    action.payload.forEach(val => {
      val.synced = true;
      val.history = [];
    });
    fetchBoxItems.push(...action.payload);
  });
  builder.addCase(fetchBoxItemsRestore, (state, action) => {
    const fetchBoxItems = state.fetchBoxItems;
    fetchBoxItems.splice(0, fetchBoxItems.length);
    fetchBoxItems.push(...action.payload);
  });
  builder.addCase(syncBoxItem, (state, action) => {
    const boxItem = action.payload;
    if (boxItem.history === undefined || boxItem.history === null) {
      boxItem.history = [];
    }
    const fetchBoxItems = state.fetchBoxItems;
    const index = fetchBoxItems.findIndex(val => val.uuid === boxItem.uuid);
    if (index === -1) {
      fetchBoxItems.push(boxItem);
      return;
    }
    fetchBoxItems[parseInt(index)] = boxItem;
  });
  builder.addCase(removeBoxItem, (state, action) => {
    const fetchBoxItems = state.fetchBoxItems;
    const index = fetchBoxItems.findIndex(val => val.uuid === action.payload);
    fetchBoxItems.splice(index, 1);
  });
  builder.addCase(removeBoxItemHistory, (state, action) => {
    const payload = action.payload;
    const item = state.fetchBoxItems.find(val => val.uuid === payload.uuid);
    if (!item) return;
    const index = item.history.findIndex(
      val => val.scan_id === payload.scan_id,
    );
    if (index >= 0) {
      item.history.splice(index, 1);
    }
  });
  builder.addCase(addBoxItemAsync, (state, action) => {
    const payload = action.payload;
    const boxItem = payload.boxItem;
    const scanCode = payload.scan_code;
    const fetchBoxItems = state.fetchBoxItems;
    const index = fetchBoxItems.findIndex(val => val.uuid === boxItem.uuid);
    boxItem.scanned_on = new Date();
    boxItem.scan_code = scanCode;
    boxItem.updated_by = payload.user_id;
    boxItem.scan_station = payload.scan_station;
    boxItem.manual_entry = payload.manual_entry;
    boxItem.scan_id = payload.scan_id;
    boxItem.pod = payload.pod;
    boxItem.pod_name = payload.pod_name;
    boxItem.history = [];
    boxItem.synced = payload.synced === true;
    if (index === -1) {
      fetchBoxItems.push(boxItem);
    } else {
      const _boxItem = fetchBoxItems[parseInt(index)];
      _boxItem.history = _boxItem.history === undefined ? [] : _boxItem.history;
      boxItem.history.push(..._boxItem.history);
      if (!boxItem.synced || boxItem.synced !== true) {
        boxItem.history.push({
          deleted: _boxItem.deleted,
          scanned_on: _boxItem.scanned_on,
          qty: _boxItem.qty,
          scan_station: _boxItem.scan_station,
          scan_id: _boxItem.scan_id,
          scan_code: _boxItem.scan_code,
          pod: _boxItem.pod,
          pod_name: _boxItem.pod_name,
          manual_entry: _boxItem.manual_entry,
          user_id: _boxItem.updated_by,
        });
      }
      fetchBoxItems[parseInt(index)] = boxItem;
    }
  });
  builder.addCase(addBoxAsync, (state, action) => {
    const box = action.payload;
    const fetchBoxes = state.fetchBoxes;
    const index = fetchBoxes.findIndex(val => val.uuid === box.uuid);
    const boxContext = [];
    fetchBoxes.forEach(val => {
      if (boxContext.indexOf(val.packing_context_ref) === -1) {
        boxContext.push(val.packing_context_ref);
      }
    });
    if (
      boxContext.length > 0 &&
      boxContext.indexOf(box.packing_context_ref) === -1
    ) {
      if (box.deleted === true) {
        box.synced = true;
      } else {
        return;
      }
    }
    if (!box.updated_on || box.synced !== true) {
      box.updated_on = new Date();
    }
    if (box.synced === undefined) {
      box.synced = false;
    }
    if (index === -1) {
      fetchBoxes.push(box);
    } else {
      const _box = fetchBoxes[parseInt(index)];
      for (let key in _box) {
        //eslint-disable-next-line
        if (box[`${key}`] === undefined && _box[`${key}`] !== undefined) {
          //eslint-disable-next-line
          box[`${key}`] = _box[`${key}`];
        }
      }
      fetchBoxes[parseInt(`${index}`)] = box;
    }
  });
  builder.addCase(deleteBox, (state, action) => {
    if (!action.payload) return;
    const box = state.fetchBoxes.find(
      val => val.deleted === false && val.uuid === action.payload,
    );
    if (box) {
      box.deleted = true;
      box.synced = false;
    }
  });
});
export default reducer;
