import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from './external/header';
import ScreenLoader from '../../components/common/screen-loader';
import PropTypes from 'prop-types';

import {
  getOverlayStyle,
  toastResponseError,
} from '../../helpers/common/helper';

import ScreenOverlay from '../../components/common/screen-overlay';
import { useHistory } from 'react-router-dom';
import { useLocation, useSearchParams } from 'react-router-dom-v5-compat';
import Footer from '../cutguide/cutguide/footer';
import QrcodeScanModal from '../../components/tracktrace/qrcode-scan-modal';
import SubHeader from './external/sub-header';
import { toggleRejectMode } from '../../store/tracktrace/stationstate';

import {
  appendDPodExternalPendingSync,
  removeDPodExternalPendingSync,
  setDPodExternalStationCtxType,
  setDPodExternalStationCtxValue,
  setDPodExternalRefreshCache,
  updateDPodExternalStationCache,
} from '../../store/digitalpod/dpodexternstationstate';

import { getState } from '../../store';
import { actions } from '@naadi/framework';
import { getDigitalPodDataExternal } from '../../services/shipment';
import {
  toastError,
  toastSuccess,
  toastWarning,
} from '../../helpers/packing/packingHelper';
import { cloneDeep } from 'lodash';
import {
  calculateDPodStat,
  getCtxPendingItems,
  getItemFromScanCode,
  MODES,
  scanOrderItems,
} from '../../helpers/digitalpod/digitalPodHelper';
import { ShipmentListContainer } from './digital-pod-shipments';
import DigitalPodOrderListContainer from './digital-pod-orders';
import DigitalPodItemListContainer from './digital-pod-shipment-items';
import { getRejectionReasons } from '../../helpers/tracktrace/orderItemHelper';
import RejectReasonModal from '../../components/tracktrace/reject-reason';
import {
  DPodPendingItemsCard,
  DPodPendingListContainer,
} from './digital-pod-pending';

const defaultFeatures = [
  'MATERIAL',
  'CUT_LENGTH',
  'CUT_WIDTH',
  'INVENTORY_TYPE',
  'REJECT_REASON_TEMPLATE',
  'REJECTION_REASONS',
];
const mappedCtxType = {
  SHIPMENT_COLLABORATOR: 'SHIPMENT',
};
const ORDER = 'ORDER';
const ORDER_ITEM = 'ORDER_ITEM';
const FAILED_TO_UPDATE = 'Failed to Update';

/* eslint-enable sonarjs/cognitive-complexity */
const DEFAULT_RESULT = {
  shipments: {},
  orders: {},
  result: [],
  stat: {
    box_shipped: 0,
    box_shipped_processed: 0,
    box_processed: 0,
    box_total: 0,
    item_shipped: 0,
    item_shipped_processed: 0,
    item_processed: 0,
    item_total: 0,
  },
};
const current_station = {
  uuid: 'digitalpod',
  org_id: '',
  branch_id: '',
  instance_name: 'digitalpod',
  station_group: 'digitalpod',
  station_code: 'digitalpod',
  station_name: 'Digital POD',
  operations: ['DIGITALPOD'],
  parent_operations: [],
  allow_scan_uploads: false,
  allow_box_scan: true,
  allow_offline_scan: true,
  auto_operation: false,
  supported_item_type: [],
  enable_item_print: false,
};
const DigitalPodExternalHome = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  //eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const { screens } = useSelector(state => state.screenoverlay);
  const [baseStyles, setBaseStyles] = useState(getOverlayStyle());

  const { rejectmode } = useSelector(state => state.stationstate);

  const [dPodResult, setDPodResult] = useState(cloneDeep(DEFAULT_RESULT));
  const [dPodResultWithPending, setDPodResultWithPending] = useState(
    cloneDeep(dPodResult),
  );
  const [showScanner, setShowScanner] = useState(false);
  const [mode, setMode] = useState(MODES.SHIPMENT_LISTING);
  const [modes, setModes] = useState([]);
  const [shipment, setShipment] = useState({});
  const [lastScanItem, setLastScanItem] = useState({});
  const { ctx, pending_sync } = useSelector(
    state => state.dpodexternstationstate,
  );

  const [scanState, setScanState] = useState('');
  const [pendingSyncItems, setPendingSyncItems] = useState(
    getCtxPendingItems(ctx, pending_sync),
  );
  useEffect(() => {
    setPendingSyncItems(getCtxPendingItems(ctx, pending_sync));
  }, [pending_sync, ctx, setPendingSyncItems]);
  const [showRejectModal, setShowRejectModal] = useState({
    show: false,
    orderItems: [],
    manualEntry: false,
    lastScan: {},
    rejectReasons: [],
  });
  const onRejectModeToggle = useCallback(() => {
    dispatch(toggleRejectMode());
  }, [dispatch]);
  useEffect(() => {
    setBaseStyles(getOverlayStyle());
  }, [screens]);

  useEffect(() => {
    if (!modes || modes.length === 0) {
      setMode(MODES.SHIPMENT_LISTING);
      return;
    } else if (modes.length === 1) {
      setMode(
        modes[0]?.mode === MODES.PENDING_SYNC_LISTING
          ? MODES.PENDING_SYNC_LISTING
          : MODES.SHIPMENT_LISTING,
      );
    }
    const modeVal = modes[`${modes.length - 1}`];
    setMode(modeVal.mode);
  }, [modes, setMode]);

  const [scrollTop, setScrollTop] = useState(0);
  const recalculatePendingItemStat = useCallback(
    async (ctx, calcResult, pendingSync) => {
      if (!ctx || !ctx.type || !ctx.value || !ctx.value.key) {
        return null;
      }
      const ctxPendingSync = getCtxPendingItems(ctx, pendingSync || []);

      const _calcResult = calculateDPodStat(
        cloneDeep(calcResult),
        ctx,
        ctxPendingSync,
      );
      setDPodResultWithPending(_calcResult);
    },
    [setDPodResultWithPending],
  );
  useEffect(() => {
    recalculatePendingItemStat(ctx, dPodResult, pending_sync);
  }, [ctx, pending_sync, dPodResult, recalculatePendingItemStat]);
  const populatingData = useRef(false);
  const populateDigitalPodData = useCallback(
    async (ctx, skipCache, isBackground) => {
      if (!ctx || !ctx.type || !ctx.value || !ctx.value.key) {
        return null;
      }
      const ctxType = ctx.type.value;
      const ctxId = ctx.value.key;
      const orgId = ctx.value.org_id;
      try {
        populatingData.current = true;
        if (!isBackground) {
          dispatch(actions.busy.add('GET_DIGITAL_POD_DATA'));
        }

        if (
          !current_station ||
          !current_station.operations ||
          current_station.operations.length === 0
        ) {
          return null;
        }
        let _ctxType = mappedCtxType[`${ctxType}`] || ctxType;
        const dPodResp = await getDigitalPodDataExternal(
          orgId,
          _ctxType,
          ctxId,
          defaultFeatures,
          current_station.operations,
          skipCache,
        );
        if (dPodResp.status !== 200) {
          if (!isBackground) {
            toastResponseError(dPodResp, 'Unable to Fetch the Context Data');
          }
          return null;
        }
        if (skipCache) {
          dispatch(setDPodExternalRefreshCache(false));
        }
        const result = {
          shipments: dPodResp?.meta_data?.shipments || {},
          orders: dPodResp?.meta_data?.orders || {},
          result: dPodResp.payload,
        };

        const calcResult = calculateDPodStat(result, ctx);
        dispatch(updateDPodExternalStationCache(calcResult));
        setDPodResult(calcResult);
        return calcResult;
      } catch (err) {
        const stationCache = getState().dpodexternstationstate.station_cache;
        if (
          stationCache.ctx_type === ctxType &&
          stationCache.ctx_id === ctxId &&
          ctxType &&
          ctxId
        ) {
          setDPodResult(cloneDeep(stationCache));
        }
      } finally {
        populatingData.current = false;
        if (!isBackground) {
          dispatch(actions.busy.remove('GET_DIGITAL_POD_DATA'));
        }
      }
      return null;
    },
    [dispatch, setDPodResult],
  );

  useEffect(() => {
    if (populatingData.current === true) {
      return;
    }
    populateDigitalPodData(
      ctx,
      getState().dpodexternstationstate.refresh_cache === true,
    );
  }, [ctx, populateDigitalPodData]);
  const ctxTypeSelectCb = useCallback(
    (type, ctx) => {
      if (!type) return;
      dispatch(setDPodExternalStationCtxType(type));
      dispatch(updateDPodExternalStationCache(null));
      setSearchParams(val => {
        const ctx = getState().dpodexternstationstate?.ctx;
        const ctxType = ctx.type?.value || 'SHIPMENT_COLLABORATOR';
        const ctxValue = ctx.value?.key || '';
        val.set('ctx_type', ctxType);
        val.set('ctx_val', ctxValue);
        return val;
      });
    },
    [dispatch, setSearchParams],
  );

  const onCtxValueSelect = useCallback(
    async (ctxValue, type) => {
      const _ctx = cloneDeep(getState().dpodexternstationstate.ctx);
      if (type) {
        ctxTypeSelectCb(type, _ctx, dispatch);
      }
      _ctx.value = ctxValue;
      dispatch(setDPodExternalStationCtxValue(ctxValue));
      await populateDigitalPodData(
        _ctx,
        getState().dpodexternstationstate.refresh_cache,
      );

      const ctx = getState().dpodexternstationstate?.ctx;
      setSearchParams(val => {
        const ctxType = ctx.type?.value || 'SHIPMENT_COLLABORATOR';
        const ctxValue = ctx.value?.key || '';
        val.set('ctx_type', ctxType);
        val.set('ctx_val', ctxValue);
        return val;
      });
    },
    [dispatch, ctxTypeSelectCb, populateDigitalPodData, setSearchParams],
  );
  const onCtxTypeSelect = useCallback(
    type => {
      ctxTypeSelectCb(type, ctx, dispatch);
    },
    [dispatch, ctx, ctxTypeSelectCb],
  );
  const createOrderModeCb = useCallback(
    order => {
      return {
        mode: MODES.SHIPMENT_ITEM_LISTING,
        shipment_id: order.shipment.uuid,
        order_id: order.order.uuid,
        shipment: order.shipment,
        order: order.order,
        label: order.order.ref_code,
        onSelect: () => {
          setModes(val => {
            if (val.length > 3) {
              const newModes = [...val];
              newModes.splice(3);
              return newModes;
            }
            return val;
          });
        },
      };
    },
    [setModes],
  );
  const clearShipmentSelection = useCallback(() => {
    setSearchParams(val => {
      const ctx = getState().dpodexternstationstate?.ctx;
      const ctxType = ctx.type?.value || 'ORDER';
      const ctxValue = ctx.value?.key || '';
      val.set('ctx_type', ctxType);
      val.set('ctx_val', ctxValue);
      val.delete('shipment_id');
      val.delete('order_id');
      return val;
    });
  }, [setSearchParams]);

  const createModeCb = useCallback(
    shipment => {
      const shipmentModeCb = {
        mode: MODES.SHIPMENT_LISTING,
        shipment_id: shipment.shipment.uuid,
        label: 'Home',
        onSelect: () => {
          clearShipmentSelection();
          setModes([]);
        },
      };
      const orderCbMode = {
        mode: MODES.SHIPMENT_ORDER_LISTING,
        shipment_id: shipment.shipment.uuid,
        label: shipment.shipment.code,
        onSelect: () => {
          setModes(val => {
            if (val.length > 2) {
              const newModes = [...val];
              newModes.splice(2);
              return newModes;
            }
            return val;
          });
        },
      };
      return [shipmentModeCb, orderCbMode];
    },
    [setModes, clearShipmentSelection],
  );
  const createPendingModeCb = useCallback(() => {
    const syncListHome = {
      mode: MODES.PENDING_SYNC_LISTING,
      label: 'Home',
      onSelect: () => {
        clearShipmentSelection();
        setModes([]);
      },
    };

    return [syncListHome];
  }, [setModes, clearShipmentSelection]);
  const createModesFromId = useCallback(
    (shipmentId, orderId) => {
      const shipment = dPodResult.shipments[`${shipmentId}`];
      if (!shipment) {
        return [];
      }
      const _modes = createModeCb({ shipment: shipment });
      if (orderId) {
        const order = dPodResult.orders[`${orderId}`];
        if (!order) {
          return _modes;
        }
        const orderMode = createOrderModeCb({
          shipment: shipment,
          order: order,
        });
        _modes.push(orderMode);
      }
      return _modes;
    },
    [dPodResult, createOrderModeCb, createModeCb],
  );
  useEffect(() => {
    const shipmentId = searchParams.get('shipment_id');
    const orderId = searchParams.get('order_id');
    if (!shipmentId && !orderId) {
      return;
    }
    if (shipmentId !== '' && modes.length === 0) {
      const _modes = createModesFromId(shipmentId, orderId);
      if (_modes.length > 0) {
        setModes(_modes);
      }
    }
  }, [searchParams, createModeCb, createModesFromId, setModes, modes]);
  const isCtxSelected = useCallback(() => {
    const ctx = getState().dpodexternstationstate.ctx;
    let returnVal = false;
    if (!ctx.type || !ctx.type.value || !ctx.value || !ctx.value.key) {
      returnVal = false;
    } else if (ctx.value.key.length > 0) {
      returnVal = true;
    }
    return returnVal;
  }, []);
  const onRejectModalClose = useCallback(() => {
    setShowRejectModal({
      show: false,
      scanCode: '',
      orderItems: [],
      manualEntry: false,
      rejectReasons: [],
      lastScan: {},
    });
  }, [setShowRejectModal]);
  const onScan = useCallback(
    async (orderItems, manualEntry, rejection, images) => {
      try {
        const resp = await scanOrderItems(
          orderItems,
          manualEntry,
          rejection,
          images,
          dispatch,
          current_station,
          {
            uuid: ctx?.value?.collaborator_id,
            org_id: ctx?.value?.org_id,
          },
        );
        if (!resp.success) {
          toastError(
            resp.error || resp.payload.err[0]?.err || FAILED_TO_UPDATE,
          );
        } else if (resp.success) {
          if (!getState().dpodexternstationstate.refresh_cache) {
            dispatch(setDPodExternalRefreshCache(true));
          }

          if (resp.previous_scan_status === 'SUCCESS') {
            toastWarning(resp.message);
          } else {
            toastSuccess(resp.message);
          }
        }
        return resp;
      } catch (err) {
        return { error: err.message ? err.message : FAILED_TO_UPDATE };
      }

      //syncOrderItems(dispatch);
      //return result;
    },
    [dispatch, ctx],
  );
  const updateScanResult = useCallback(
    scanResult => {
      if (
        !scanResult?.payload?.success ||
        scanResult?.payload?.success?.length === 0
      ) {
        return;
      }
      const operationStatusList = scanResult?.payload?.success || [];
      const orderItemMap = {};
      operationStatusList.forEach(s => {
        if (!orderItemMap[`${s.order_item.uuid}`]) {
          orderItemMap[`${s.order_item.uuid}`] = [];
        }
        orderItemMap[`${s.order_item.uuid}`].push({
          deleted: s.deleted,
          operation_code: s.operation_code,
          status: s.status,
          comment: s.comment,
          qty: s.qty,
          reject_qty: s.reject_qty,
          skip_operation: s.skip_operation,
        });
      });
      const updateOrderItem = orderItem => {
        const ois = orderItemMap[`${orderItem.uuid}`] || [];
        const oisMap = {};
        ois.forEach(v => {
          oisMap[`${v.operation_code}`] = ois;
        });
        const _ois = orderItem._operation_status.filter(val => {
          return oisMap[val.operation_code] ? false : true;
        });
        orderItem._operation_status = [..._ois, ...ois];
      };
      const updateBox = box => {
        box?.box_items.forEach(bi => {
          if (bi.box_item_type === 'ORDER') {
            updateOrderItem(bi.order_box_item);
          } else if (bi.box_item_type === 'BOX') {
            updateBox(bi.box_box_item);
          }
        });
      };
      const stationCache = getState().dpodexternstationstate?.station_cache;
      const _dPodResult = cloneDeep(stationCache);
      _dPodResult.result.forEach(item => {
        if (item.type === 'BOX') {
          updateBox(item.box);
        } else if (item.type === ORDER_ITEM) {
          updateOrderItem(item.order_item);
        }
      });
      const calcResult = calculateDPodStat(_dPodResult, ctx);
      dispatch(updateDPodExternalStationCache(calcResult));
      setDPodResult(_dPodResult);
    },
    [dispatch, setDPodResult, ctx],
  );
  /* eslint-disable sonarjs/cognitive-complexity */
  const onScanWrapper = useCallback(
    async (scanCode, scanItem, manualEntry, silent, reject) => {
      setScanState(null);
      setLastScanItem(scanItem);
      const scanRef =
        (scanItem.type === 'BOX'
          ? scanItem.box?.uuid
          : scanItem.type === 'ORDER_ITEM'
          ? scanItem.order_item.uuid
          : '') +
        '::' +
        ctx?.type?.value +
        '::' +
        ctx?.value?.key;
      const scanRecord = {
        scan_ref: scanRef,
        scan_code: scanCode,
        scanItem: scanItem,
        manualEntry: manualEntry,
        reject: reject,
        scan_date: new Date(),
        err: { err: '' },
        ctx: ctx,
      };
      try {
        if (rejectmode || reject) {
          let rejectReasons = [];
          let lastScan = {};
          let _orderItems = [];
          if (scanItem.type === ORDER_ITEM) {
            _orderItems = [scanItem.order_item];
          } else if (scanItem.type === 'BOX') {
            _orderItems = scanItem.box.box_items
              .filter(val => val.box_item_type === 'ORDER')
              .map(val => val.order_box_item);
          }
          if (_orderItems.length === 0) {
            toastError('There are no Order items in the Box to Accept');
            return;
          }
          const rejectReasonsResp = await getRejectionReasons(
            undefined,
            undefined,
            current_station,
            _orderItems[0],
          );
          if (rejectReasonsResp.error) {
            scanRecord.err =
              rejectReasonsResp.error?.err || 'Failed Select Reject Reason';
            toastError(rejectReasonsResp.error);
            setScanState('error');
            dispatch(appendDPodExternalPendingSync(scanRecord));
            return;
          }
          rejectReasons = rejectReasonsResp.reasons || [];
          lastScan = rejectReasonsResp.lastScan;

          setShowRejectModal({
            show: true,
            orderItems: _orderItems,
            scanCode: scanCode + '',
            manualEntry: manualEntry,
            rejectReasons: rejectReasons,
            lastScan: lastScan || {},
            scanRecord: scanRecord,
          });
          return null;
        } else {
          const orderItems = [];
          if (scanItem.type === ORDER_ITEM) {
            orderItems.push(scanItem.order_item);
          } else if (scanItem.type === 'BOX') {
            scanItem.box.box_items.forEach(val => {
              if (val.box_item_type === ORDER) {
                orderItems.push(val.order_box_item);
              }
            });
          }
          if (orderItems.length === 0) {
            return null;
          }
          const scanResult = await onScan(
            orderItems,
            manualEntry,
            {
              rejected: rejectmode,
            },
            [],
          );
          if (!scanResult.success) {
            scanRecord.err =
              scanResult.error ||
              scanResult.payload.err[0]?.err ||
              FAILED_TO_UPDATE;
            dispatch(appendDPodExternalPendingSync(scanRecord));
          } else {
            dispatch(removeDPodExternalPendingSync(scanRecord));
          }
          updateScanResult(scanResult);
        }
      } catch (err) {
        scanRecord.err = err.message ? err.message : 'Failed to process';
        dispatch(appendDPodExternalPendingSync(scanRecord));
        toastError('Failed to process');
      }

      return null;
    },
    [
      dispatch,
      setScanState,
      rejectmode,
      updateScanResult,
      onScan,
      setLastScanItem,
      ctx,
    ],
  );
  const populateCtxIfNotFound = useCallback(
    async (scanCode, manualEntry, silent, reject) => {},
    [],
  );

  /* eslint-enable sonarjs/cognitive-complexity */
  const onScanCodeWrapper = useCallback(
    async (scanCode, manualEntry, silent, reject) => {
      if (scanCode) {
        scanCode = scanCode.trim();
      }

      let _dPodResult = dPodResult;
      if (!isCtxSelected()) {
        const result = await populateCtxIfNotFound(scanCode);
        if (!result) {
          return;
        }
        _dPodResult = dPodResult;
      }
      if (scanCode === '') {
        return;
      }
      const scanItem = getItemFromScanCode(_dPodResult, scanCode);
      if (!scanItem) {
        toastWarning(`The Scanned Item ${scanCode} Could not be found`);
        return;
      }
      return await onScanWrapper(
        scanCode,
        cloneDeep(scanItem),
        manualEntry,
        silent,
        reject,
      );
    },
    [dPodResult, isCtxSelected, onScanWrapper, populateCtxIfNotFound],
  );

  const onRejectOfRetry = useCallback(
    async scanRecord => {
      if (!scanRecord || !scanRecord.scan_ref) {
        return;
      }
      const rejectreason = scanRecord.rejectreason;
      const rejectcomment = scanRecord.rejectcomment;
      const images = scanRecord.images;
      const scanItem = scanRecord.scanItem;
      let _orderItems = [];
      if (scanItem.type === ORDER_ITEM) {
        _orderItems = [scanItem.order_item];
      } else if (scanItem.type === 'BOX') {
        _orderItems = scanItem.box.box_items
          .filter(val => val.box_item_type === ORDER)
          .map(val => val.order_box_item);
      }
      if (_orderItems.length === 0) {
        toastError('There are no Order items in the Box to Accept');
        return;
      }
      const scanResult = await onScan(
        _orderItems,
        scanRecord.manualEntry,
        {
          rejected: true,
          reasons: [],
          rejection_list: rejectreason,
          comment: rejectcomment,
          rejectReasons: [],
        },
        images,
      );
      updateScanResult(scanResult);

      if (!scanResult.success) {
        scanRecord.err =
          scanResult.error ||
          scanResult.payload.err[0]?.err ||
          FAILED_TO_UPDATE;
        dispatch(appendDPodExternalPendingSync(scanRecord));
      } else {
        dispatch(removeDPodExternalPendingSync(scanRecord));
      }
    },
    [dispatch, onScan, updateScanResult],
  );

  const onRejectModalConfirm = useCallback(
    async (rejectreason, rejectcomment, images) => {
      setScanState(null);
      const scanResult = await onScan(
        showRejectModal.orderItems,
        showRejectModal.manualEntry,
        {
          rejected: true,
          reasons: [],
          rejection_list: rejectreason,
          comment: rejectcomment,
          rejectReasons: [],
        },
        images,
      );
      updateScanResult(scanResult);

      if (scanResult.success) {
        onRejectModalClose();
        setScanState('warn');
      } else {
        setScanState('error');
      }
      const scanRecord = showRejectModal.scanRecord;

      if (scanRecord && scanRecord.scan_ref) {
        if (!scanResult.success) {
          scanRecord.err =
            scanResult.error ||
            scanResult.payload.err[0]?.err ||
            FAILED_TO_UPDATE;
          scanRecord.rejectreason = rejectreason;
          scanRecord.rejectcomment = rejectcomment;
          scanRecord.images = images;
          dispatch(appendDPodExternalPendingSync(scanRecord));
          onRejectModalClose();
        } else {
          dispatch(removeDPodExternalPendingSync(scanRecord));
        }
      }
    },
    [
      dispatch,
      onScan,
      showRejectModal,
      onRejectModalClose,
      setScanState,
      updateScanResult,
    ],
  );
  return (
    <Fragment>
      <div
        onScroll={event => {
          setScrollTop(event.currentTarget.scrollTop);
        }}
        className='height-100-percent width-100-percent'
        style={{
          height: '100%',
          overflowX: 'hidden',
          backgroundColor: '#EFEFEF',
        }}
      >
        <div style={{ display: 'block', overflow: 'hidden' }}>
          <ScreenLoader />
        </div>
        <div
          style={baseStyles.overlayStyle}
          className='scroll-content container-fluid'
        >
          <ScreenOverlay module={'digitalpod'} />
        </div>
        <div style={baseStyles.screenStyle}>
          <div
            className='container-fluid position-relative px-0 '
            style={{
              display: 'block',
              minHeight: '100%',
              backgroundColor: '#EFEFEF',
              paddingBottom: '32px',
            }}
          >
            <Header
              location={location}
              history={history}
              mode={mode}
              headerLabel={
                shipment?.shipment?.code ? shipment?.shipment?.code : null
              }
              scrollTop={scrollTop}
              scanState={scanState}
            />
            <SubHeader
              isClosed={false}
              rejectmode={rejectmode}
              onRejectModeToggle={onRejectModeToggle}
              scanState={''}
              onShowOfflineScans={() => {}}
              currentStation={current_station}
              ctx={ctx}
              location={location}
              history={history}
              onCtxTypeSelect={onCtxTypeSelect}
              onCtxValueSelect={onCtxValueSelect}
              dPodResult={dPodResultWithPending}
              refreshResult={() => {
                populateDigitalPodData(ctx, true);
              }}
              mode={mode}
              shipment={shipment}
            />

            <div className='px-4 '>
              {mode === MODES.SHIPMENT_LISTING && (
                <div className='py-4'>
                  <ShipmentListContainer
                    dPodResult={dPodResultWithPending}
                    pendingItems={pendingSyncItems || []}
                    currentStation={current_station}
                    history={history}
                    onSelect={shipment => {
                      setSearchParams(val => {
                        const ctx = getState().dpodexternstationstate?.ctx;
                        const ctxType = ctx.type?.value || ORDER;
                        const ctxValue = ctx.value?.key || '';
                        val.set('ctx_type', ctxType);
                        val.set('ctx_val', ctxValue);
                        val.set('shipment_id', shipment.shipment.uuid);
                        val.delete('order_id', null);
                        return val;
                      });
                      const newModes = createModeCb(shipment);
                      setModes(newModes);
                      setShipment(shipment);
                    }}
                    modes={modes}
                  />
                  {pendingSyncItems.length > 0 && (
                    <div className='row py-2'>
                      <div
                        className='col-12 col-md-12 col-lg-6 col-xl-6'
                        style={{ minWidth: '280px' }}
                      >
                        <DPodPendingItemsCard
                          onCardClick={() => {
                            const newModes = createPendingModeCb();
                            setModes(newModes);
                          }}
                          pendingItems={pendingSyncItems}
                          ctx={ctx}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {mode === MODES.PENDING_SYNC_LISTING && (
                <div className='py-4'>
                  <DPodPendingListContainer
                    dPodResult={dPodResultWithPending}
                    modes={modes}
                    ctx={ctx}
                    pendingItems={pendingSyncItems}
                    onRetry={pendingItem => {
                      if (pendingItem.reject) {
                        onRejectOfRetry(pendingItem);
                      } else {
                        onScanWrapper(
                          pendingItem.scan_code,
                          pendingItem.scanItem,
                          pendingItem.manualEntry,
                          false,
                          pendingItem.reject,
                        );
                      }
                    }}
                  />
                </div>
              )}
              {modes.length > 1 && mode === MODES.SHIPMENT_ORDER_LISTING && (
                <DigitalPodOrderListContainer
                  dPodResult={dPodResultWithPending}
                  pendingItems={pendingSyncItems}
                  modes={modes}
                  shipmentId={modes[1].shipment_id}
                  onSelect={result => {
                    setSearchParams(val => {
                      const ctx = getState().dpodexternstationstate?.ctx;
                      const ctxType = ctx.type?.value || ORDER;
                      const ctxValue = ctx.value?.key || '';
                      val.set('ctx_type', ctxType);
                      val.set('ctx_val', ctxValue);
                      val.set('shipment_id', result.shipment.uuid);
                      val.set('order_id', result.order.uuid);
                      return val;
                    });
                    const newModes = [
                      ...createModeCb(result),
                      createOrderModeCb(result),
                    ];
                    setModes(newModes);
                  }}
                />
              )}
              {modes.length > 2 && mode === MODES.SHIPMENT_ITEM_LISTING && (
                <DigitalPodItemListContainer
                  dPodResult={dPodResultWithPending}
                  pendingItems={pendingSyncItems}
                  modes={modes}
                  setModes={setModes}
                  lastScanItem={lastScanItem}
                  onScanWrapper={(scanItem, reject) => {
                    if (modes[1]?.shipment_id === 'NO_SHIPMENT') {
                      toastError('The Item Does not belong to the Shipment');
                      return;
                    }
                    onScanWrapper(
                      scanItem.scanCode,
                      scanItem,
                      true,
                      false,
                      reject,
                    );
                  }}
                  currentStation={current_station}
                />
              )}
            </div>
          </div>
        </div>

        <Footer
          lable={'Box/Items'}
          placeholder={'Scan The Box/Items Here'}
          disabled={false}
          onSubmit={scanCode => {
            onScanCodeWrapper(scanCode, false, false, false);
            //onBoardScanWrapper(scanCode, null, null, history);
          }}
          onShowScanner={() => {
            setShowScanner(true);
          }}
        />
        {showRejectModal.show && (
          <RejectReasonModal
            showModal={showRejectModal.show}
            onCloseCb={onRejectModalClose}
            onConfirmCb={onRejectModalConfirm}
            rejectReasons={showRejectModal.rejectReasons}
            scanCode={showRejectModal.scanCode || ''}
            lastScan={showRejectModal.lastScan || {}}
          />
        )}
        {showScanner === true && (
          <QrcodeScanModal
            onCloseCb={() => {
              setShowScanner(false);
            }}
            onSubmit={scanCode => {
              onScanCodeWrapper(scanCode, false, false, false);
              //onBoardScanWrapper(scanCode, null, null, history);
            }}
          ></QrcodeScanModal>
        )}
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default DigitalPodExternalHome;

DigitalPodExternalHome.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};
