import React, { useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import '../../scss/components/_dropdown.scss';
import { useHistory, useLocation } from 'react-router-dom';
import SearchIcon from '../../assets/icons/searchIcon.svg';
import { ToastContainer } from 'react-toastify';
import ScreenLoader from '../../components/common/screen-loader';
import useMobile from '../../hooks/useMobile';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';
import {
  createOperation,
  fetchOperations,
  getLinkWorkstation,
  deleteOperation,
  createWorkstationLink,
  fetchWorkstationLink,
  deleteOperationLink,
} from '../../services/operation';
import { toastError, toastMessage } from '../../helpers/packing/packingHelper';
import OperationHeader from './operationheader';
import Select from '../../components/operations/select-upload';
import BottomDrawerWorkstations from './bottom-drawer-workstations';
import { DeleteIcon } from '../../assets/icons';
import BottomDrawerContent from './bottom-drawer-menu';
import { cloneDeep } from 'lodash';
const Class_RECT = 'rectangle-input';
const Class_RECT_Highlight = 'rectangle-input highlight';
const Class_RECT_Error = 'rectangle-input error';

// eslint-disable-next-line sonarjs/cognitive-complexity
const NewOperation = () => {
  const location = useLocation();
  const [nameFocus, setNameFocus] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [codeFocus, setCodeFocus] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, isTab] = useMobile();
  const [operationUuid, setOperationUuid] = useState('');
  const [searchText, setSearchText] = useState('');
  const [workstationDrawer, setWorkstationDrawer] = useState();
  const [workstationList, setWorkstationList] = useState([]);
  const [workstationOpen, setWorkstationOpen] = useState(false);
  const [titleText, setTitleText] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [headerName, setHeaderName] = useState('');
  useEffect(() => {
    const search = location.search;
    const urlSearchParams = new URLSearchParams(search);
    const _params = Object.fromEntries(urlSearchParams.entries());
    setOperationUuid(_params.uuid);
    // setParams(_params);
  }, [location, setOperationUuid]);

  const [form, setForm] = useState({
    name: '',
    code: '',
  });

  const onChange = useCallback(e => {
    const target = e.target;
    setForm(o => ({ ...o, [target.getAttribute('name')]: target.value }));
  }, []);

  const fetchWorkstations = useCallback(
    async tid => {
      const workstationBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (searchText)
        workstationBody.req_payload.term = searchText.toUpperCase();
      try {
        dispatch(actions.busy.add('FETCH_STATION'));
        const getWorkstation = await getLinkWorkstation(workstationBody);

        if (getWorkstation && getWorkstation.status !== 200) {
          return;
        }

        setWorkstationDrawer(getWorkstation.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_STATION'));
      }
    },
    [dispatch, searchText, setWorkstationDrawer],
  );
  useEffect(() => {
    fetchWorkstations();
  }, [fetchWorkstations]);

  const FetchOperation = useCallback(
    async tid => {
      const req = {
        approved: true,
        active: true,
        deleted: false,
        page: 0,
        size: 2,
        sort: [{ column: 'operation_code', asc: true }],
        req_payload: {
          deleted: [false],
          active: [true],
        },
      };
      if (operationUuid) {
        req.req_payload.uuid = [operationUuid];
      }
      try {
        dispatch(actions.busy.add('FETCH_OPERATION'));
        const fetchOp = await fetchOperations(req);
        if (fetchOp && fetchOp.status !== 200) {
          return;
        }

        if (fetchOp && fetchOp.payload.length > 0) {
          setForm({
            name: fetchOp.payload[0].name,
            code: fetchOp.payload[0].operation_code,
          });
          setHeaderName(fetchOp.payload[0].name);
        }
      } finally {
        dispatch(actions.busy.remove('FETCH_OPERATION'));
      }
    },
    [dispatch, operationUuid],
  );
  useEffect(() => {
    if (operationUuid) {
      FetchOperation();
    }
  }, [FetchOperation, operationUuid]);

  const fetchWorkstationOperationLink = useCallback(
    async tid => {
      if (!operationUuid || operationUuid === null) return;
      const workstationBody = {
        page: 0,
        size: 10,
        active: true,
        delted: false,
        sort: [{ column: 'id', asc: true }],
        req_payload: {
          operation_id: [operationUuid],
        },
      };

      try {
        dispatch(actions.busy.add('FETCH_LINKWORKSTATION'));
        const getWorkstation = await fetchWorkstationLink(workstationBody);

        if (getWorkstation && getWorkstation.status !== 200) {
          return;
        }

        setWorkstationList(getWorkstation.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_LINKWORKSTATION'));
      }
    },
    [dispatch, operationUuid, setWorkstationList],
  );
  useEffect(() => {
    fetchWorkstationOperationLink();
  }, [fetchWorkstationOperationLink]);

  const delOperationLink = useCallback(
    async oid => {
      try {
        dispatch(actions.busy.add('DELETE_OPERATIONLINK'));
        const deleteDF = await deleteOperationLink(oid);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Delete Operation Link');
        }
      } finally {
        dispatch(actions.busy.remove('DELETE_OPERATIONLINK'));
      }
    },
    [dispatch],
  );
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const saveChanges = useCallback(async () => {
    if (form.name === '') {
      toastError('Please Enter Operation Name');
      return;
    }
    try {
      dispatch(actions.busy.add('CREATE_OPERATION'));

      const data = {
        active: true,
        approved: true,
        deleted: false,
        req_payload: {
          name: form.name,
          operation_code: form.code,
        },
      };
      if (operationUuid && operationUuid != null) {
        data.req_payload.uuid = operationUuid;
      }
      const createOpRespFields = await createOperation(data);
      if (createOpRespFields.status !== 200) {
        toastError(
          createOpRespFields.err && createOpRespFields.err.err
            ? createOpRespFields.err.err
            : 'Unable to create the Operation',
        );
        return;
      }
      for (let i = 0; i < workstationList.length; i++) {
        if (workstationList[`${i}`].deleted === true) {
          delOperationLink(workstationList[`${i}`].uuid);
        } else {
          const linkdata = {
            active: true,
            approved: true,
            deleted: false,
            req_payload: {
              station_id: workstationList[`${i}`].station_instance.uuid,
              operation_id: createOpRespFields.payload.uuid,
            },
          };
          const createLink = await createWorkstationLink(linkdata);
          if (createLink.status !== 200) {
            toastError(
              createLink.err && createLink.err.err
                ? createLink.err.err
                : 'Unable to create the Operation Workstation Link',
            );
            return;
          }
        }
      }

      toastMessage({
        type: 'success',
        message:
          operationUuid && operationUuid != null
            ? 'Updated Successfully'
            : 'Created Successfully',
      });
      //history.goBack();
    } finally {
      dispatch(actions.busy.remove('CREATE_OPERATION'));
    }
  }, [dispatch, form, workstationList, delOperationLink, operationUuid]);

  const workstationData = () => {
    return (
      <div>
        <div style={{ fontSize: '20px', textAlign: 'center' }}>
          LINK A STATION
        </div>
        <div style={{ height: '30px' }}></div>
        <div>
          <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
          <input
            type='text'
            id='search_field'
            className='searchModal'
            style={{
              border: '0px !important',
              color: 'rgba(50, 50, 50, 0.4)',
              fontStyle: 'italic',
            }}
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder='Type here to search'
          />
        </div>
        {workstationDrawer &&
          workstationDrawer.length > 0 &&
          workstationDrawer.map(row => (
            <div key={row.uuid}>
              <div
                key={row.uuid}
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  const list = cloneDeep(workstationList);
                  const objWS = {
                    deleted: false,
                    station_instance: {
                      instance_name: row.instance_name,
                      uuid: row.uuid,
                    },
                  };
                  list.push(objWS);
                  setWorkstationList(list);
                  setWorkstationOpen(false);
                }}
              >
                {row.instance_name}
              </div>
              <div
                className='operation-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
      </div>
    );
  };
  const delOperation = useCallback(
    async oid => {
      try {
        dispatch(actions.busy.add('DELETE_OPERATION'));
        for (let i = 0; i < workstationList.length; i++) {
          delOperationLink(workstationList[`${i}`].station_instance.uuid);
        }
        const deleteDF = await deleteOperation(oid);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Delete Operation');
        }
        toastMessage({
          type: 'success',
          message: 'Operation Removed Successfully',
        });
        FetchOperation();
        history.goBack();
      } finally {
        dispatch(actions.busy.remove('DELETE_OPERATION'));
      }
    },
    [dispatch, FetchOperation, history, delOperationLink, workstationList],
  );
  const markInactive = useCallback(
    async oid => {
      const req = {
        active: false,
        approved: true,
        deleted: false,
        req_payload: {
          uuid: oid,
        },
      };

      try {
        dispatch(actions.busy.add('MARK_INACTIVE'));
        const deleteDF = await createOperation(req);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Mark as Inactive');
        }
        toastMessage({
          type: 'success',
          message: 'Operation Marked as Inactive',
        });
        FetchOperation();
        history.goBack();
      } finally {
        dispatch(actions.busy.remove('MARK_INACTIVE'));
      }
    },
    [dispatch, FetchOperation, history],
  );
  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ToastContainer />

      <BottomDrawerContent
        isMobile={isMobile}
        isTab={isTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        title={titleText}
        delOperation={delOperation}
        markInactive={markInactive}
        id={operationUuid}
      />
      <div>
        <div className='container-fluid position-relative px-0'>
          <OperationHeader
            name={headerName}
            isMobile={isMobile}
            isTab={isTab}
            setOperationId={setOperationUuid}
            titleText={titleText}
            setTitleText={setTitleText}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            operationUuid={operationUuid}
            delOperation={delOperation}
            markInactive={markInactive}
          />
          <div style={{ height: '22px' }}></div>
          <Form style={{ padding: '0px' }}>
            <div
              className={
                nameError
                  ? Class_RECT_Error
                  : nameFocus
                  ? Class_RECT_Highlight
                  : Class_RECT
              }
              style={{
                margin: '0px',
                padding: '0px',
                marginBottom: '20px',
                marginLeft: '20px',
                paddingLeft: '10px',
              }}
            >
              <div>
                <label className='lbl-input lbl-padding'>Name</label>

                <Form.Control
                  id='name'
                  className='txt-input primary'
                  type='text'
                  autoComplete='off'
                  name='name'
                  placeholder='Enter name of operation'
                  value={form.name}
                  onFocus={() => {
                    setNameFocus(true);
                    setNameError(false);
                  }}
                  onBlur={() => {
                    setNameFocus(false);
                  }}
                  onChange={onChange}
                />
              </div>
            </div>
            <div
              className={
                codeError
                  ? Class_RECT_Error
                  : codeFocus
                  ? Class_RECT_Highlight
                  : Class_RECT
              }
              style={{
                margin: '0px',
                padding: '0px',
                marginBottom: '20px',
                marginLeft: '20px',
                paddingLeft: '10px',
              }}
            >
              <div>
                <label className='lbl-input lbl-padding'>Code</label>

                <Form.Control
                  id='code'
                  className='txt-input primary'
                  type='text'
                  autoComplete='off'
                  name='code'
                  placeholder='Enter Code'
                  value={form.code}
                  onFocus={() => {
                    setCodeFocus(true);
                    setCodeError(false);
                  }}
                  onBlur={() => {
                    setCodeFocus(false);
                  }}
                  onChange={onChange}
                  style={{ pointerEvents: operationUuid ? 'none' : '' }}
                />
              </div>
            </div>
          </Form>
          <BottomDrawerWorkstations
            isMobile={isMobile}
            isTab={isTab}
            itemOpen={workstationOpen}
            setItemOpen={setWorkstationOpen}
            itemData={workstationData()}
          />
          <div
            style={{
              maxWidth: '320px',
              marginLeft: '20px',
              marginTop: '30px',
            }}
          >
            <div className='row'>
              <div className='col-9'>Link Workstation</div>
              <div className='col-3' style={{ color: '#006EED' }}>
                {!(isMobile || isTab) && (
                  <Select
                    label={'Add+'}
                    getOptions={() => Promise.resolve(workstationDrawer)}
                    labelField='instance_name'
                    valueField='uuid'
                    onSelect={i => {
                      const list = cloneDeep(workstationList);
                      const objWS = {
                        deleted: false,
                        station_instance: {
                          instance_name: i.instance_name,
                          uuid: i.uuid,
                        },
                      };

                      list.push(objWS);
                      setWorkstationList(list);
                    }}
                    search
                  />
                )}
                {(isMobile || isTab) && (
                  <div onClick={() => setWorkstationOpen(true)}>{'Add+'}</div>
                )}
              </div>
            </div>
            <div style={{ height: '20px' }}></div>
            {!workstationList ||
              (workstationList.length === 0 && (
                <div
                  style={{
                    height: '30px',
                    display: 'flex',
                    borderBottom: '1px',
                    borderBottomColor: '#DEDEDE',
                    borderBottomStyle: 'outset',
                    fontSize: '12px',
                  }}
                >
                  <div
                    style={{
                      color: '#006EED',
                      cursor: 'pointer',
                      alignItems: 'center',
                      borderBottom: '1px solid',
                      borderColor: '#C4C4C4',
                      alignItem: 'center',
                    }}
                  >
                    {!(isMobile || isTab) && (
                      <Select
                        label={'Add a new or existing workstation'}
                        getOptions={() => Promise.resolve(workstationDrawer)}
                        labelField='instance_name'
                        valueField='uuid'
                        onSelect={i => {
                          const list = cloneDeep(workstationList);
                          const objWS = {
                            deleted: false,
                            station_instance: {
                              instance_name: i.instance_name,
                              uuid: i.uuid,
                            },
                          };

                          list.push(objWS);
                          setWorkstationList(list);
                        }}
                        search
                      />
                    )}
                    {(isMobile || isTab) && (
                      <div onClick={() => setWorkstationOpen(true)}>
                        Add a new or existing workstation
                      </div>
                    )}
                  </div>
                </div>
              ))}
            {workstationList &&
              workstationList.length > 0 &&
              workstationList.map((row, index) => {
                return (
                  row.deleted === false && (
                    <div
                      key={row.uuid || index}
                      style={{
                        paddingBottom: '16px',
                        display: 'flex',
                        alignContent: 'space-between',
                      }}
                    >
                      <div style={{ marginRight: '12px', width: '300px' }}>
                        {row.station_instance.instance_name}
                      </div>
                      <div>
                        <DeleteIcon
                          onClick={() => {
                            setWorkstationList(list => {
                              return list.map(item => {
                                if (
                                  item.station_instance.uuid ===
                                  row.station_instance.uuid
                                ) {
                                  return { ...item, deleted: true };
                                }
                                return item;
                              });
                            });
                          }}
                        />
                      </div>
                    </div>
                  )
                );
              })}
            <div
              style={{ borderBottom: '1px solid', borderColor: '#C4C4C4' }}
            ></div>
          </div>

          <div className='d-flex'>
            <div className='floating-div'>
              <button
                className='btn btn-outline-primary btn-md btn-save-changes'
                style={{ marginRight: '12px', background: 'white' }}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary btn-save-changes'
                onClick={() => {
                  saveChanges();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOperation;
