import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
import { cloneDeep } from 'lodash';
const WORK_ORDER_NAME = 'Work Order';
const WORK_ORDER_KEY = 'WO';
export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('CUT_GUIDE_STATION_STATE', type, verb);

export const TYPES = {
  CURRENT_STATION: 'CURRENT_STATION',
  BRANCH_STATIONS: 'BRANCH_STATIONS',
  BRANCH_CURRENT_STATION: 'BRANCH_CURRENT_STATION',
  STATION_CTX: 'STATION_CTX',
  UPDATE_CACHE: 'UPDATE_CACHE',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  SHOW_KEYBOARD: 'SHOW_KEYBOARD',
  MULTI_ACCESS: 'MULTI_ACCESS',
  PATTERN_LARGE: 'PATTERN_LARGE',
};

export const resetCgStationState = createAction(
  getActionName('RESET', 'RESET'),
);
export const setCgCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'SET'),
);

export const resetCgCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'RESET'),
);

export const setCgBranchStations = createAction(
  getActionName(TYPES.BRANCH_STATIONS, 'SET'),
);

export const setCgBranchCurrentStation = createAction(
  getActionName(TYPES.BRANCH_CURRENT_STATION, 'SET'),
);

export const setCgStationCtxType = createAction(
  getActionName(TYPES.STATION_CTX, 'TYPESET'),
);
export const setCgStationCtxValue = createAction(
  getActionName(TYPES.STATION_CTX, 'VALSET'),
);

export const updateCgStationCache = createAction(
  getActionName(TYPES.UPDATE_CACHE, 'SET'),
);

export const updateCgStationLocation = createAction(
  getActionName(TYPES.UPDATE_LOCATION, 'MERGE'),
);

export const updateShowKeyBoard = createAction(
  getActionName(TYPES.SHOW_KEYBOARD, 'MERGE'),
);

export const updateCgMultiAccess = createAction(
  getActionName(TYPES.MULTI_ACCESS, 'SET'),
);

export const updateCgPatternLarge = createAction(
  getActionName(TYPES.PATTERN_LARGE, 'SET'),
);

export const DEFAULT = {
  current_station: null,
  branch_stations: {},
  ctx: {
    type: { label: 'Work Order', value: 'WO' },
    value: {},
  },
  station_location: {},
  station_cache: [],
  order_item_sync_ctx: null,
  show_keyboard: false,
  multi_access: {
    active: true,
    user: '',
  },
  pattern_large: false,
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetCgStationState, state => {
    return Update(state, {
      current_station: { $set: null },
      branch_stations: { $set: {} },
      ctx: {
        $set: {
          type: { label: WORK_ORDER_NAME, value: WORK_ORDER_KEY },
          value: {},
        },
      },
      station_location: { $set: {} },
      station_cache: { $set: [] },
    });
  });
  builder.addCase(resetCgCurrentStation, state => {
    const branch_stations = cloneDeep(state.branch_stations);
    const current_station = state.current_station;
    if (current_station && branch_stations[`${current_station.branch_id}`]) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    return Update(state, {
      current_station: { $set: null },
      branch_stations: { $set: branch_stations },
    });
  });
  builder.addCase(setCgCurrentStation, (state, { payload }) => {
    const branch_stations = cloneDeep(state.branch_stations);
    let ctx = cloneDeep(state.ctx) || {};
    const current_station = state.current_station;
    if (
      !payload &&
      current_station &&
      branch_stations[`${current_station.branch_id}`]
    ) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    if (
      current_station &&
      ctx &&
      ctx.value &&
      ctx.value.branch_id !== current_station.branch_id
    ) {
      ctx = {
        type: { label: WORK_ORDER_NAME, value: WORK_ORDER_KEY },
        value: {},
      };
    }
    if (payload && payload.branch_id) {
      branch_stations[`${payload.branch_id}`] = payload;
    }

    return Update(state, {
      current_station: { $set: payload },
      branch_stations: { $set: branch_stations },
      ctx: { $set: ctx },
    });
  });

  builder.addCase(setCgBranchCurrentStation, state => {
    const branch_stations = state.branch_stations;
    const user = state.user;
    let current_station = null;
    if (
      user &&
      user.branch &&
      user.branch.branch_id &&
      branch_stations[user.branch.branch_id]
    ) {
      current_station = branch_stations[user.branch.branch_id];
    }
    return Update(state, {
      current_station: { $set: current_station },
    });
  });
  builder.addCase(setCgStationCtxType, (state, { payload }) => {
    const _payload =
      payload && payload.label && payload.value
        ? payload
        : { label: WORK_ORDER_NAME, value: WORK_ORDER_KEY };
    const _ctx = cloneDeep(state.ctx);
    if (
      !_payload ||
      !_payload.value ||
      (_ctx.type && _ctx.type.value !== _payload.value)
    ) {
      _ctx.value = {};
    }
    _ctx.type = _payload;
    return Update(state, {
      ctx: {
        $set: _ctx,
      },
    });
  });
  builder.addCase(setCgStationCtxValue, (state, { payload }) => {
    const _ctx = cloneDeep(state.ctx);
    _ctx.value = payload;
    return Update(state, {
      ctx: {
        $set: _ctx,
      },
    });
  });

  builder.addCase(updateCgStationLocation, (state, { payload }) => {
    return Update(state, {
      station_location: { $merge: payload },
    });
  });
  builder.addCase(updateCgStationCache, (state, { payload }) => {
    const sC = cloneDeep(state.station_cache || []);
    let match = null;
    for (let i = 0; i < sC.length; i++) {
      const cache = sC[`${i}`];
      if (cache.key === payload.key) {
        match = cache;
      }
    }
    if (match === null) {
      match = { ...payload };
      sC.push(match);
    }
    match.order_items = payload.order_items || [];
    match.order_items_list = payload.order_items_list || [];
    if (sC.length > 10) {
      sC.pop();
    }
    return Update(state, {
      station_cache: { $set: sC },
    });
  });
  builder.addCase(updateShowKeyBoard, (state, { payload }) => {
    return Update(state, {
      show_keyboard: { $set: payload === true },
    });
  });
  builder.addCase(updateCgMultiAccess, (state, { payload }) => {
    return Update(state, {
      multi_access: { $set: payload || {} },
    });
  });
  builder.addCase(updateCgPatternLarge, (state, { payload }) => {
    return Update(state, {
      pattern_large: { $set: payload === true },
    });
  });
});

export default reducer;
