import service from './index';
import { getState } from '../store';

export const getBots = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrcclient/filter`,
    data: req,
  });
};

export const getCNCBots = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/client/filter`,
    data: req,
  });
};

export const getPrinterBots = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/printer/filter`,
    data: req,
  });
};

export const delBot = tid => {
  const {
    user: { org },
  } = getState();

  return service({
    url: `/naadi-app/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrcclient/id/${tid}`,
    method: 'DELETE',
  });
};
export const delCNCBot = tid => {
  const {
    user: { org },
  } = getState();

  return service({
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/client/id/${tid}`,
    method: 'DELETE',
  });
};

export const delPrinterBot = tid => {
  const {
    user: { org },
  } = getState();

  return service({
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/printer/id/${tid}`,
    method: 'DELETE',
  });
};

export const createDesignBot = data => {
  const {
    user: { org },
  } = getState();

  return service({
    url: `/naadi-app/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrcclient/create`,
    method: 'POST',
    data,
  });
};

export const createCNCBot = data => {
  const {
    user: { org },
  } = getState();

  return service({
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/client/update`,
    method: 'POST',
    data,
  });
};

export const createPrinterBot = data => {
  const {
    user: { org, branch },
  } = getState();
  data.req_payload.branch_id = branch.branch_id;
  return service({
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/printer/create`,
    method: 'POST',
    data,
  });
};
