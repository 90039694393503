import React, { useState, useRef, Fragment, useCallback } from 'react';
import useParentWidth from '../../hooks/useParentWidth';
import { useHistory } from 'react-router-dom';
import { StorageIcon } from './icon';
import { PaginationNext, PaginationPrev } from '../../assets/icons/nav-icons';

const HeaderODelete = ({ selectedOrders }) => {
  const history = useHistory();
  const container = useRef();

  useParentWidth(container);

  const goBack = useCallback(() => {
    history.push('/app/orders/list');
  }, [history]);

  return (
    <Fragment>
      <div className='d-flex mt-3'>
        <div
          style={{ paddingLeft: '20px' }}
          className={'cursor-pointer'}
          onClick={goBack}
        >
          <svg
            width='32'
            height='28'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M15 19L16.41 17.59L11.83 13L10.8294 12.0357L11.83 11L16.42 6.41L15 5L8 12L15 19Z'
              fill='black'
            />
          </svg>
        </div>
        <div className='page-title d-flex align-items-center'>
          Delete Orders ({selectedOrders.length} selected)
        </div>
      </div>
    </Fragment>
  );
};
export const StorageBar = ({ usedStorages, totalStorage = 5 }) => {
  const usedStorage = usedStorages / 1024;
  const usedPercentage = (usedStorage / totalStorage) * 100;
  const remaining = `${(totalStorage - usedStorage).toFixed(2)} GB`;
  const isExceeded = usedStorage > totalStorage;
  const progressBarColor = isExceeded ? 'bg-danger' : 'bg-primary';
  const iconColor = isExceeded ? ' #C00000' : '#027AFF';

  return (
    <div
      className='mt-2 position-relative'
      style={{
        width: '353px',
        left: '39px',
        height: '56px',
        paddingLeft: '10px',
        paddingRight: '10px',
        border: '1px solid #F5F5F5',
        borderRadius: '8px',
        backgroundColor: '#F5F5F5',
      }}
    >
      <div
        className='d-flex align-items-center justify-content-between rounded'
        style={{ paddingTop: '2px' }}
      >
        <div className='d-flex align-items-center'>
          <StorageIcon iconColor={iconColor} />
          <span
            className='position-absolute'
            style={{ color: 'black', left: '60px', fontSize: '14px' }}
          >
            Storage
          </span>
        </div>
        <span
          style={{
            fontSize: '14px',
            color: isExceeded ? '#C00000' : '#027AFF',
          }}
        >
          {isExceeded ? `0 GB (${remaining})` : `${remaining}`} left
        </span>
      </div>
      <div
        className='progress position-relative'
        style={{
          height: '8px',
          borderRadius: '5px',
          maxWidth: '280px',
          left: '50px',
        }}
      >
        <div
          className={`progress-bar ${progressBarColor}`}
          role='progressbar'
          style={{ width: `${usedPercentage}%` }}
          aria-valuenow={usedPercentage}
          aria-valuemin='0'
          aria-valuemax='100'
        ></div>
      </div>
    </div>
  );
};

export const PaginationComponent = ({ totalCount, pageNo, onPageChange }) => {
  const pageSize = 50;
  const pageStart = pageNo * pageSize + 1;
  const pageEnd = Math.min((pageNo + 1) * pageSize, totalCount) || 1;
  const isPrevDisabled = pageNo === 0;
  const isNextDisabled = pageEnd >= totalCount;
  const onPrevPage = () => {
    if (!isPrevDisabled) {
      const newPage = pageNo - 1;
      onPageChange(newPage);
    }
  };
  const onNextPage = () => {
    if (!isNextDisabled) {
      const newPage = pageNo + 1;
      onPageChange(newPage);
    }
  };
  return (
    <div className='d-flex justify-content-end mt-3'>
      <span style={{ fontSize: '12px' }}>Showing</span>
      <span
        className='text-primary ps-1'
        style={{ fontSize: '12px' }}
      >{`${pageStart} to ${pageEnd}`}</span>
      <span style={{ padding: '0 10px' }} onClick={onPrevPage}>
        <PaginationPrev disabled={isPrevDisabled} />
      </span>
      <span style={{ padding: '0 16px' }} onClick={onNextPage}>
        <PaginationNext disabled={isNextDisabled} />
      </span>
    </div>
  );
};
export const TrashBanner = ({ show, orderCount, onViewTrash }) => {
  const [order] = useState(orderCount);
  if (!show) return null;
  return (
    <div
      className='d-flex align-items-center justify-content-between'
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#2C2F33',
        color: '#FFFFFF', // white text
        padding: '2px 10px',
        borderRadius: '20px',
        zIndex: 1050,
        boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
        minWidth: '150px',
      }}
    >
      <span style={{ fontSize: '12px' }}>
        {order.length} orders Moved to Trash
      </span>
      <div
        style={{
          color: '#027AFF', // blue color for link
          textDecoration: 'underline',
          marginLeft: '10px',
          fontWeight: 'bold',
          fontSize: '12px',
          cursor: 'pointer',
        }}
      >
        View Trash
      </div>
    </div>
  );
};

export default HeaderODelete;
