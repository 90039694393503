import { DEFAULT, TYPES } from './const';
import statCalc from '../../helpers/shipment/stat';

// eslint-disable-next-line sonarjs/cognitive-complexity
const reducer = (state = DEFAULT, action) => {
  switch (action.type) {
    // on selecting shipment.
    case TYPES.SELECT_SHIPMENT: {
      const { shipment } = action;
      return { ...state, shipment, shipments: [] };
    }
    // updating shipments global stats for landing pages.
    case TYPES.SET_STAT: {
      const { stat } = action;
      return { ...state, stat };
    }
    // updating shipments for landing page.
    case TYPES.SET_SHIPMENTS: {
      const { shipments } = action;
      return { ...state, shipments };
    }
    // updating shipments filter for landing pages.
    case TYPES.SET_SHIPMENT_FILTERS: {
      const { shipments_filters } = action;
      return { ...state, shipments_filters };
    }
    // updating shipments status filter for landing pages.
    case TYPES.ADD_STATUS_FILTER: {
      const { status } = action;
      const shipments_filters = JSON.parse(
        JSON.stringify(state.shipments_filters || {}),
      );
      const shipment_status = shipments_filters.SHIPMENT_STATUS || [];
      if (!shipment_status.find(o => o.value === status.value)) {
        shipments_filters.SHIPMENT_STATUS = [...shipment_status, status];
      }
      return { ...state, shipments_filters };
    }
    // updating orders on selection of shipment.
    case TYPES.SET_ORDERS: {
      const { orders } = action;
      return { ...state, orders };
    }
    // resetting selected shipment.
    case TYPES.RESET_SHIPMENT: {
      return {
        ...state,
        shipment: null,
        orders: null,
        orders_filters: {},
        item_detail: null,
      };
    }
    // on updating order's box status.
    case TYPES.ON_CHANGE_BOX_STATUS: {
      const { order, box, completed, shipped_qty, busy } = action;
      let new_state = JSON.parse(JSON.stringify(state));
      const o = new_state.orders
        ? new_state.orders.find(r => r.uuid === order.uuid)
        : null;
      if (o) {
        let b = o.boxes.find(r => r.uuid === box.uuid);
        if (b) {
          b.checked = completed;
          b.shipped_qty = shipped_qty;
          b.busy = busy;
          const [shipment, orders] = statCalc(
            new_state.shipment,
            new_state.orders,
          );
          new_state.shipment = shipment;
          new_state.orders = orders;
        }
      }
      return new_state;
    }
    // on updated order's box status.
    case TYPES.ON_UPDATED_BOX_STATUS: {
      const { order, box, completed, shipped_qty, box_item } = action;
      const new_state = JSON.parse(JSON.stringify(state));
      const o = (new_state.orders || []).find(r => r.uuid === order.uuid);
      if (o && o.boxes) {
        let b = o.boxes.find(r => r.uuid === box.uuid);
        if (b) {
          b.checked = completed;
          b.shipped_qty = shipped_qty;
          b.busy = false;
          if (completed && box_item) {
            // adding box item in the list, required for delinking.
            new_state.shipment.box.box_item_list.push({
              uuid: box_item.uuid,
              box_item_id: box_item.box_item_id,
            });
          } else if (!completed) {
            // removing delinked box item.
            new_state.shipment.box.box_item_list =
              new_state.shipment.box.box_item_list.filter(
                r => r.box_item_id !== box.uuid,
              );
          }
          const [shipment, orders] = statCalc(
            new_state.shipment,
            new_state.orders,
          );
          new_state.shipment = shipment;
          new_state.orders = orders;
        }
      }
      return new_state;
    }
    // on completing all box & item.
    case TYPES.ON_RESET_BOX_STATUS: {
      const { order } = action;
      const new_state = JSON.parse(JSON.stringify(state));
      const o = new_state.orders.find(r => r.uuid === order.uuid);
      if (o) {
        for (let i in o.boxes) {
          const box = o.boxes[`${i}`];
          if (box.completed || box.checked) {
            box.checked = false;
            box.completed = false;
            box.busy = true;
          }
        }
      }
      return new_state;
    }
    // on changing order filter.
    case TYPES.SET_ORDERS_FILTER: {
      const { label, value, isAdd } = action;
      const orders_filters = JSON.parse(JSON.stringify(state.orders_filters));
      if (isAdd) {
        orders_filters[`${label}`] = [value];
      } else {
        if (orders_filters[`${label}`]) {
          delete orders_filters[`${label}`];
        }
      }
      return { ...state, orders_filters };
    }
    // on changing box quantity.
    case TYPES.SET_BOX_QUANTITY: {
      const { order, box, shipped_qty, busy } = action;
      const new_state = JSON.parse(JSON.stringify(state));
      const o = new_state.orders.find(r => r.uuid === order.uuid);
      if (o) {
        const b = o.boxes.find(r => r.uuid === box.uuid);
        if (b) {
          b.shipped_qty = shipped_qty;
          b.busy = busy;
          const [shipment, orders] = statCalc(
            new_state.shipment,
            new_state.orders,
          );
          new_state.shipment = shipment;
          new_state.orders = orders;
        }
      }
      return new_state;
    }
    case TYPES.SET_ITEM_DETAIL: {
      const { item_detail } = action;
      return { ...state, item_detail };
    }
    case TYPES.SET_SHIPMENT_SUMMARY_TEMPLATE: {
      const { shipment_summary_template } = action;
      return { ...state, shipment_summary_template };
    }
    case TYPES.SET_NEW_MODE: {
      return { ...state, new_shipment_mode: action === true };
    }
    default: {
      return { ...state };
    }
  }
};

export default reducer;
