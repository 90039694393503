import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useMobile from '../../hooks/useMobile';
import Exclamation from '../../assets/icons/delete-exclamation.svg';
import { NaadiModalCloseIcon } from '../../assets/icons/common-icons';
export const ActionConfirmModal = ({ closeCb, confirmCb }) => {
  const [isMobile] = useMobile();
  return (
    <Modal
      centered={!isMobile}
      size={'md'}
      show={true}
      onHide={() => {
        closeCb();
      }}
      dialogClassName='track-trace-dialog'
    >
      <div
        style={{ textAlign: 'right', paddingRight: '20px', paddingTop: '20px' }}
        onClick={() => closeCb()}
      >
        <NaadiModalCloseIcon />
      </div>
      <Modal.Header
        className='px-32'
        style={{ borderBottom: '0px', alignSelf: 'center' }}
      >
        <Modal.Title style={{ alignSelf: 'center', width: '100%' }}>
          <div style={{ textAlign: 'webkit-center' }}>
            <div style={{ paddingTop: '20px', textAlign: 'center' }}>
              <img src={Exclamation} alt='' />
            </div>
            <div style={{ height: '20px', alignSelf: 'center' }}></div>
            Delete Workflow Rule?
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ alignSelf: 'center' }}>
        <div>Do you really want to delete the Workflow Rule?</div>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: '0px' }}>
        <div className='d-flex w-100'>
          <div className='flex-1 text-right pe-2 ps-6'>
            <Button
              variant='outline-primary'
              size='lg '
              className='w-100'
              onClick={e => {
                closeCb();
              }}
            >
              Cancel
            </Button>
          </div>
          <div className='flex-1 text-left w-100 ps-2 pe-6'>
            <Button
              type='submit'
              className='ms-2 w-100'
              size='lg'
              onClick={e => {
                confirmCb();
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
