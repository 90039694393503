import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { FaAngleDown } from 'react-icons/fa';
import BottomDrawer from '../../components/template/bottomdrawer';
import CustomHookModal from './editquery-modal';
import GreyClose from '../../assets/icons/grey-close.svg';
import Select from '../../components/bot/select-upload';
import { getLinkWorkstation, fetchOperations } from '../../services/operation';
import { fetchStations } from '../../services/station';
import { cloneDeep } from 'lodash';
import SearchIcon from '../../assets/icons/searchIcon.svg';
import BottomDrawerStations from './bottom-drawer-stations';
const StationGroupsFilter = ({
  labelText,
  selectStationGroup,
  setSelectStationGroup,
}) => {
  const stationGroupFilter = useCallback(term => {
    return new Promise((resolve, reject) => {
      const stationGroupBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (term) stationGroupBody.req_payload.term = term.toUpperCase();
      getLinkWorkstation(stationGroupBody).then(val => {
        if (val.status === 200) {
          resolve(val.payload);
        } else {
          reject([]);
        }
      }, reject);
    });
  }, []);

  return (
    <div style={{ width: '320px' }}>
      <label className='lbl-input lbl-padding' style={{ marginLeft: '16px' }}>
        STATION GROUP
      </label>
      <Select
        selVal={
          selectStationGroup
            ? selectStationGroup.station_group
            : 'Select Station Group'
        }
        label={labelText}
        getOptions={stationGroupFilter}
        labelField='station_group'
        valueField='uuid'
        onSelect={i => {
          setSelectStationGroup(i);
        }}
        search
      />
    </div>
  );
};
const StationsFilter = ({ labelText, selectStation, setSelectStation }) => {
  const stationFilter = useCallback(term => {
    return new Promise((resolve, reject) => {
      const stationBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (term) stationBody.req_payload.term = term.toUpperCase();
      fetchStations(stationBody).then(val => {
        if (val.status === 200) {
          resolve(val.payload);
        } else {
          reject([]);
        }
      }, reject);
    });
  }, []);

  return (
    <div style={{ width: '320px' }}>
      <label className='lbl-input lbl-padding' style={{ marginLeft: '16px' }}>
        STATION
      </label>
      <Select
        selVal={
          selectStation ? selectStation.station_code : 'Select Station Value'
        }
        label={labelText}
        getOptions={stationFilter}
        labelField='name'
        valueField='uuid'
        onSelect={i => {
          setSelectStation(i);
        }}
        search
      />
    </div>
  );
};
const WorkstationsFilter = ({
  labelText,
  selectWorkstation,
  setSelectWorkstation,
}) => {
  const workstationFilter = useCallback(term => {
    return new Promise((resolve, reject) => {
      const workstationBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (term) workstationBody.req_payload.term = term.toUpperCase();
      getLinkWorkstation(workstationBody).then(val => {
        if (val.status === 200) {
          resolve(val.payload);
        } else {
          reject([]);
        }
      }, reject);
    });
  }, []);
  return (
    <div style={{ width: '320px' }}>
      <label className='lbl-input lbl-padding' style={{ marginLeft: '16px' }}>
        POD
      </label>
      <Select
        selVal={
          selectWorkstation ? selectWorkstation.instance_name : 'Select POD'
        }
        label={labelText}
        getOptions={workstationFilter}
        labelField='instance_name'
        valueField='uuid'
        onSelect={i => {
          setSelectWorkstation(i);
        }}
        search
      />
    </div>
  );
};
const OperationsFilter = ({
  labelText,
  selectOperation,
  setSelectOperation,
}) => {
  const oprationFilter = useCallback(term => {
    return new Promise((resolve, reject) => {
      const operationBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (term) operationBody.req_payload.term = term.toUpperCase();
      fetchOperations(operationBody).then(val => {
        if (val.status === 200) {
          resolve(val.payload);
        } else {
          reject([]);
        }
      }, reject);
    });
  }, []);

  return (
    <div style={{ width: '320px' }}>
      <label className='lbl-input lbl-padding' style={{ marginLeft: '16px' }}>
        Operation
      </label>
      <Select
        selVal={
          selectOperation ? selectOperation.operation_code : 'Select Operation'
        }
        label={labelText}
        getOptions={oprationFilter}
        labelField='name'
        valueField='uuid'
        onSelect={i => {
          setSelectOperation(i);
        }}
        search
      />
    </div>
  );
};

const BotInputBeamsaw = ({
  bottype,
  selectStationGroup,
  setSelectStationGroup,
  selectStation,
  setSelectStation,
  selectWorkstation,
  setSelectWorkstation,
  selectOperation,
  setSelectOperation,
  form,
  setForm,
  onChange,
  errors,
  isMobile,
  isTab,
  stationgroupDrawer,
  stationDrawer,
  workstationDrawer,
  operationDrawer,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const Class_RECT = 'rectangle-input';
  const [showHookModal, setShowHookModal] = useState(false);
  const [hookValue, setHookValue] = useState('');
  const [stationgroupOpen, setStationgroupOpen] = useState(false);
  const [workstationOpen, setWorkstationOpen] = useState(false);
  const [stationOpen, setStationOpen] = useState(false);
  const [operationOpen, setOperationOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const closeCallback = setShowHookModal => {
    setShowHookModal(false);
  };

  const stationgroupData = () => {
    return (
      <div>
        <div style={{ fontSize: '20px', textAlign: 'center' }}>
          LINK A STATION GROUP
        </div>
        <div style={{ height: '30px' }}></div>
        <div>
          <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
          <input
            type='text'
            id='search_field'
            className='searchModal'
            style={{
              border: '0px',
              color: 'rgba(50, 50, 50, 0.5)',
              fontStyle: 'italic',
            }}
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder='Type here to search'
          />
        </div>
        {stationgroupDrawer &&
          stationgroupDrawer.length > 0 &&
          stationgroupDrawer.map(row => (
            <div key={row.uuid}>
              <div
                key={row.uuid}
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  setSelectStationGroup(row);
                  setStationgroupOpen(false);
                }}
              >
                {row.station_group}
              </div>
              <div
                className='operation-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
      </div>
    );
  };
  const stationData = () => {
    return (
      <div>
        <div style={{ fontSize: '20px', textAlign: 'center' }}>
          LINK A STATION
        </div>
        <div style={{ height: '30px' }}></div>
        <div>
          <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
          <input
            type='text'
            id='search_field'
            className='searchModal'
            style={{
              border: '0px',
              color: 'rgba(50, 50, 50, 0.5)',
              fontStyle: 'italic',
            }}
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder='Type here to search'
          />
        </div>
        {stationDrawer &&
          stationDrawer.length > 0 &&
          stationDrawer.map(row => (
            <div key={row.uuid}>
              <div
                key={row.uuid}
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  setSelectStation(row);
                  setStationOpen(false);
                }}
              >
                {row.station_code}
              </div>
              <div
                className='operation-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
      </div>
    );
  };
  const workstationData = () => {
    return (
      <div>
        <div style={{ fontSize: '20px', textAlign: 'center' }}>LINK A POD</div>
        <div style={{ height: '30px' }}></div>
        <div>
          <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
          <input
            type='text'
            id='search_field'
            className='searchModal'
            style={{
              border: '0px !important',
              color: 'rgba(50, 50, 50, 0.4)',
              fontStyle: 'italic',
            }}
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder='Type here to search'
          />
        </div>
        {workstationDrawer &&
          workstationDrawer.length > 0 &&
          workstationDrawer.map(row => (
            <div key={row.uuid}>
              <div
                key={row.uuid}
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  setSelectWorkstation(row);
                  setWorkstationOpen(false);
                }}
              >
                {row.instance_name}
              </div>
              <div
                className='operation-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
      </div>
    );
  };
  const operationData = () => {
    return (
      <div>
        <div style={{ fontSize: '20px', textAlign: 'center' }}>
          LINK AN OPERATION
        </div>
        <div style={{ height: '30px' }}></div>
        <div>
          <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
          <input
            type='text'
            id='search_field'
            className='searchModal'
            style={{
              border: '0px !important',
              color: 'rgba(50, 50, 50, 0.4)',
              fontStyle: 'italic',
            }}
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder='Type here to search'
          />
        </div>
        {operationDrawer &&
          operationDrawer.length > 0 &&
          operationDrawer.map(row => (
            <div key={row.uuid}>
              <div
                key={row.uuid}
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  setSelectOperation(row);
                  setOperationOpen(false);
                }}
              >
                {row.operation_code}
              </div>
              <div
                className='operation-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
      </div>
    );
  };
  return (
    <div
      className='bg-white'
      style={{
        alignContent: 'left',
        marginLeft: '40px',
      }}
    >
      {isMobile && showHookModal ? (
        <BottomDrawer
          entity={''}
          drawerTitle=''
          handleClose={() => closeCallback(setShowHookModal)}
          popUp={showHookModal && isMobile}
          drawerBody={
            <CustomHookModal
              showModal={showHookModal}
              closeCallback={() => closeCallback(setShowHookModal)}
              isMobile={isMobile}
              isTab={isTab}
              hookValue={hookValue}
              setHookValue={setHookValue}
              form={form}
              bottype={bottype}
            />
          }
        />
      ) : (
        showHookModal && (
          <CustomHookModal
            showModal={showHookModal}
            closeCallback={() => closeCallback(setShowHookModal)}
            isMobile={isMobile}
            isTab={isTab}
            hookValue={hookValue}
            setHookValue={setHookValue}
            form={form}
            bottype={bottype}
          />
        )
      )}
      <BottomDrawerStations
        isMobile={isMobile}
        isTab={isTab}
        itemOpen={stationgroupOpen}
        setItemOpen={setStationgroupOpen}
        itemData={stationgroupData()}
      />
      <BottomDrawerStations
        isMobile={isMobile}
        isTab={isTab}
        itemOpen={stationOpen}
        setItemOpen={setStationOpen}
        itemData={stationData()}
      />
      <BottomDrawerStations
        isMobile={isMobile}
        isTab={isTab}
        itemOpen={workstationOpen}
        setItemOpen={setWorkstationOpen}
        itemData={workstationData()}
      />
      <BottomDrawerStations
        isMobile={isMobile}
        isTab={isTab}
        itemOpen={operationOpen}
        setItemOpen={setOperationOpen}
        itemData={operationData()}
      />
      <div style={{ height: '27px' }}></div>
      <Form style={{ padding: '0px' }}>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='row m-0 p-0'>
            <div className='col-md-10 col-sm-10 col-10 m-0 p-0'>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                BOT TYPE
              </label>

              <Form.Control
                id='bottype'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='bottype'
                placeholder='Enter Value Here'
                value={bottype}
                disabled
                isInvalid={!!errors.bottype}
                onChange={onChange}
                style={{ marginLeft: '16px' }}
              />
            </div>
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='row m-0 p-0'>
            <div className='col-md-10 col-sm-10 col-10 m-0 p-0'>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                PRINTER NAME
              </label>

              <Form.Control
                id='printername'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='printername'
                placeholder='Enter Value Here'
                value={form.printername}
                isInvalid={!!errors.printername}
                onChange={onChange}
                style={{ marginLeft: '16px' }}
              />
              {errors.printername && (
                <Form.Control.Feedback type='invalid'>
                  <div style={{ height: '8px' }}></div>
                  {errors.printername}
                </Form.Control.Feedback>
              )}
            </div>
            <div
              className='col-md-2 col-sm-2 col-2 password-image'
              style={{ paddingTop: '15px', textAlignLast: 'right' }}
            >
              <img src={GreyClose} alt=''></img>
            </div>
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '30px' }}>
              Enable Trace
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='enabletrace'
              name='enabletrace'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.enabletrace = !newVal.enabletrace;
                  return newVal;
                });
              }}
              value={true}
              checked={form.enabletrace === true}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '30px' }}>
              Enable Curl
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='enablecurl'
              name='enablecurl'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.enablecurl = !newVal.enablecurl;
                  return newVal;
                });
              }}
              value={true}
              checked={form.enablecurl === true}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
          onClick={() => setShowHookModal(true)}
        >
          <label
            className='lbl-input lbl-padding'
            style={{ marginLeft: '16px' }}
          >
            PORT
          </label>

          <Form.Control
            id='port'
            className='txt-input primary'
            type='text'
            autoComplete='off'
            name='port'
            placeholder='Enter Value Here'
            value={form.port}
            isInvalid={!!errors.port}
            onChange={onChange}
            style={{ marginLeft: '16px' }}
          />
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Out folder
            </label>

            <Form.Control
              id='out_folder'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='out_folder'
              placeholder='Enter Value Here'
              value={form.out_folder}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Queue folder
            </label>

            <Form.Control
              id='queue_folder'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='queue_folder'
              placeholder='Enter Value Here'
              value={form.queue_folder}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '30px' }}>
              Sync Label
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='sync_label'
              name='sync_label'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.sync_label = !newVal.sync_label;
                  return newVal;
                });
              }}
              value={true}
              checked={form.sync_label === true}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '30px' }}>
              Offline Mode
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='offline_mode'
              name='offline_mode'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.offline_mode = !newVal.offline_mode;
                  return newVal;
                });
              }}
              value={true}
              checked={form.offline_mode === true}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Label Retension Days
            </label>

            <Form.Control
              id='label_retension'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='label_retension'
              placeholder='Enter Value Here'
              value={form.label_retension}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              DPI
            </label>

            <Form.Control
              id='dpi'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='dpi'
              placeholder='Enter Value Here'
              value={form.dpi}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Label Crop Left
            </label>

            <Form.Control
              id='label_crop_left'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='label_crop_left'
              placeholder='Enter Value Here'
              value={form.label_crop_left}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Label Crop Right
            </label>

            <Form.Control
              id='label_crop_right'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='label_crop_right'
              placeholder='Enter Value Here'
              value={form.label_crop_right}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Label Crop Top
            </label>

            <Form.Control
              id='label_crop_top'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='label_crop_top'
              placeholder='Enter Value Here'
              value={form.label_crop_top}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Label Crop Bottom
            </label>

            <Form.Control
              id='label_crop_bottom'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='label_crop_bottom'
              placeholder='Enter Value Here'
              value={form.label_crop_bottom}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              QR code Crop X0
            </label>

            <Form.Control
              id='qr_code_crop_x0'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='qr_code_crop_x0'
              placeholder='Enter Value Here'
              value={form.qr_code_crop_x0}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              QR code Crop Y0
            </label>

            <Form.Control
              id='qr_code_crop_y0'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='qr_code_crop_y0'
              placeholder='Enter Value Here'
              value={form.qr_code_crop_y0}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              QR code Crop X1
            </label>

            <Form.Control
              id='qr_code_crop_x1'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='qr_code_crop_x1'
              placeholder='Enter Value Here'
              value={form.qr_code_crop_x1}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              QR code Crop Y1
            </label>

            <Form.Control
              id='qr_code_crop_y1'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='qr_code_crop_y1'
              placeholder='Enter Value Here'
              value={form.qr_code_crop_y1}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          {!(isMobile || isTab) && (
            <StationGroupsFilter
              labelText={'Add+'}
              selectStationGroup={selectStationGroup}
              setSelectStationGroup={setSelectStationGroup}
            />
          )}
          {(isMobile || isTab) && (
            <div>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                STATION Group
              </label>
              <div onClick={() => setStationgroupOpen(true)}>
                {' '}
                <div
                  className='row'
                  style={{ width: '320px', paddingLeft: '15px' }}
                >
                  <div className='col-10'>
                    {selectStationGroup
                      ? selectStationGroup.station_group
                      : 'Select Station Group'}
                  </div>
                  <div className='col-2 m-0 p-0' style={{ textAlign: 'right' }}>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          {!(isMobile || isTab) && (
            <StationsFilter
              labelText={'Add+'}
              selectStation={selectStation}
              setSelectStation={setSelectStation}
            />
          )}
          {(isMobile || isTab) && (
            <div>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                STATION
              </label>
              <div onClick={() => setStationOpen(true)}>
                {' '}
                <div
                  className='row'
                  style={{ width: '320px', paddingLeft: '15px' }}
                >
                  <div className='col-10'>
                    {selectStation
                      ? selectStation.station_code
                      : 'Select Station'}
                  </div>
                  <div className='col-2 m-0 p-0' style={{ textAlign: 'right' }}>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          {!(isMobile || isTab) && (
            <WorkstationsFilter
              labelText={'Add+'}
              selectWorkstation={selectWorkstation}
              setSelectWorkstation={setSelectWorkstation}
            />
          )}
          {(isMobile || isTab) && (
            <div>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                POD
              </label>
              <div onClick={() => setWorkstationOpen(true)}>
                {' '}
                <div
                  className='row'
                  style={{ width: '320px', paddingLeft: '15px' }}
                >
                  <div className='col-10'>
                    {selectWorkstation
                      ? selectWorkstation.instance_name
                      : 'Select POD'}
                  </div>
                  <div className='col-2 m-0 p-0' style={{ textAlign: 'right' }}>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          {!(isMobile || isTab) && (
            <OperationsFilter
              labelText={'Add+'}
              selectOperation={selectOperation}
              setSelectOperation={setSelectOperation}
            />
          )}
          {(isMobile || isTab) && (
            <div>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                OPERATION
              </label>
              <div onClick={() => setOperationOpen(true)}>
                {' '}
                <div
                  className='row'
                  style={{ width: '320px', paddingLeft: '15px' }}
                >
                  <div className='col-10'>
                    {selectOperation
                      ? selectOperation.operation_code
                      : 'Select Operation'}
                  </div>
                  <div className='col-2 m-0 p-0' style={{ textAlign: 'right' }}>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default BotInputBeamsaw;
