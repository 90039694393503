import { getState } from '../store';
import service from './index';

export const getOrderProgress = (
  group,
  group2,
  group3,
  from,
  term,
  termType,
  itemType,
  size,
) => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const fromMillis = from ? from.getTime() : new Date(0).getTime();
  termType = termType ? termType : '';
  size = size || 200;
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstat/progress/${group}?branch_id=${branchId}&from=${fromMillis}&term=${term}&term_type=${termType}&size=${size}&item_type=${itemType}&group2=${group2}&group3=${group3}`,
  });
};

export const pinOrderStat = (group, operationCodes, statKey, pin) => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const operationCode =
    operationCodes && operationCodes.length > 0 ? operationCodes.join(',') : '';
  return service({
    method: 'PUT',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstat/pin/${branchId}/${group}/${statKey}?pin=${pin}&operation_codes=${operationCode}`,
  });
};
