import React, { Fragment, useState, useEffect, useCallback } from 'react';
import '../../scss/components/_dropdown.scss';
import IconMenu from '../../assets/icons/icon-menu.svg';
import ScreenLoader from '../../components/common/screen-loader';
import IconAddBox from '../../components/packing/common/icon-add-box';
import { toggleSideMenu } from '../../store/app/nav/actions';
import { FaAngleDown } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { actions } from '@naadi/framework';
import { toastError, toastMessage } from '../../helpers/packing/packingHelper';
import { ToastContainer } from 'react-toastify';
import NewBoxMenu from './newbox-menu';
import moment from 'moment';
import yaml from 'json-to-pretty-yaml';
import {
  getBots,
  delBot,
  getCNCBots,
  delCNCBot,
  getPrinterBots,
  delPrinterBot,
} from '../../services/bot';
import useMobile from '../../hooks/useMobile';
import BottomDrawerContent from './bottom-drawer-menu';
import { Menu, Dropdown } from 'antd';
const FETCH_ERROR = 'Unable to fetch data!';
const NAADI_BASE_WS_URL = 'wss://api.prod.naadi.io/naadi-app';
const NAADI_BASE_URL = 'https://api.prod.naadi.io/naadi-app';
const KEYCLOAK_OPENID_URL =
  'https://keycloak.naadi.io/realms/naadi/.well-known/openid-configuration';

const BotsTypeMenu = setBottype => {
  const history = useHistory();
  return (
    <Menu
      className='menuItems'
      style={{
        padding: 0,
        background: '#ffffff',
        border: '1px solid #c0c0c0',
        boxSizing: 'borderBox',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
        borderRadius: '8px',
      }}
    >
      <Menu.Item
        key='0'
        onClick={() => history.push(`/app/bot/box?type=DESIGN_TOOL`)}
      >
        DESIGN_TOOL
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='1'
        onClick={() => history.push(`/app/bot/box?type=SCAN_CLIENT`)}
      >
        PRINTER
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='2'
        onClick={() => history.push(`/app/bot/box?type=CNC_CLIENT`)}
      >
        CNC
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='3'
        onClick={() => history.push(`/app/bot/box?type=PRINT_INTERCEPT_CLIENT`)}
      >
        BEAM SAW
      </Menu.Item>
    </Menu>
  );
};

const BotsHeader = () => {
  const dispatch = useDispatch();
  const handleMenuToggle = () => {
    dispatch(toggleSideMenu());
  };
  return (
    <Fragment>
      <div
        className='flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 '
        style={{ zIndex: 10, width: '100%' }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          type='button'
        >
          <img
            src={IconMenu}
            alt='Menu'
            onClick={() => handleMenuToggle()}
          ></img>
        </button>
        <div className='ms-2 page-header'>
          <div className='page-title d-flex align-items-center'>
            <div className='ps-2'>Naadi Bots</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const BoxDisplay = ({
  botUuid,
  botName,
  isMobile,
  isTab,
  botid,
  setShowPreviewModal,
  setShowItemPreviewModal,
  setBotid,
  bottype,
  menuOpen,
  setMenuOpen,
  deleteBot,
  downloadBot,
  setTitleText,
  lastConnected,
}) => {
  const history = useHistory();
  return (
    <div
      className='col-12 col-sm-6 col-md-6  col-lg-4 col-xl-4'
      style={{
        paddingLeft: '16px',
        paddingBottom: '16px',
        marginTop: '0px',
      }}
    >
      <div
        className='border rounded shadow-sm px-4 py-3 bg-white'
        style={{ height: '92px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            className='cursor-pointer'
            style={{ paddingTop: '8px' }}
            onClick={() =>
              history.push(`/app/bot/newbot?id=` + botUuid + '?type=' + bottype)
            }
          >
            {botName}
            {lastConnected && (
              <div>
                <div style={{ height: '10px' }}></div>
                Connected {lastConnected} ago
              </div>
            )}
          </div>
          <div className='d-flex'>
            <NewBoxMenu
              id={botUuid}
              botid={botid}
              setShowPreviewModal={setShowPreviewModal}
              setShowItemPreviewModal={setShowItemPreviewModal}
              setBotid={setBotid}
              bottype={bottype}
              box={true}
              isMobile={isMobile}
              isTab={isTab}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              deleteBot={deleteBot}
              downloadBot={downloadBot}
              titleText={bottype}
              setTitleText={setTitleText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getDesignBotInfo = fetchBot => {
  return {
    NAADI_BASE_WS_URL: NAADI_BASE_WS_URL,
    NAADI_BASE_URL: NAADI_BASE_URL,
    KEYCLOAK_OPENID_URL: KEYCLOAK_OPENID_URL,
    org_id: fetchBot.payload[0].org_id,
    branch_id: fetchBot.payload[0].branch_id,
    ENABLE_TRACE: fetchBot.payload[0].client_config?.enable_trace === true,
    design_tool_config: {
      client_id: fetchBot.payload[0].uuid,
      client_type: fetchBot.payload[0].client_type,
      DATABASE: fetchBot.payload[0].client_config.database,
      USERNAME: fetchBot.payload[0].client_config.username,
      PASSWORD: fetchBot.payload[0].client_config.password,
      PORT: fetchBot.payload[0].client_config.port,
      HOST: fetchBot.payload[0].client_config.host,
      image_path: fetchBot.payload[0].client_config.image_path,
      program_path: fetchBot.payload[0].client_config.program_path,
    },
  };
};

const getBeamSawBotInfo = fetchBot => {
  return {
    NAADI_BASE_WS_URL: NAADI_BASE_WS_URL,
    NAADI_BASE_URL: NAADI_BASE_URL,
    KEYCLOAK_OPENID_URL: KEYCLOAK_OPENID_URL,
    org_id: fetchBot.payload[0].org_id,
    branch_id: fetchBot.payload[0].branch_id,
    beamsaw_client_config: {
      client_id: fetchBot.payload[0].uuid,
      printer_name: fetchBot.payload[0].client_config.printername,
      port: fetchBot.payload[0].client_config.port,
      out_folder: fetchBot.payload[0].client_config.out_folder,
      sync_label: fetchBot.payload[0].client_config.sync_label,
      offline_mode: fetchBot.payload[0].client_config.offline_mode,
      label_retension: fetchBot.payload[0].client_config.label_retension,
      dpi: fetchBot.payload[0].client_config.dpi,
      label_crop_left: fetchBot.payload[0].client_config.label_crop_left,
      label_crop_right: fetchBot.payload[0].client_config.label_crop_right,
      label_crop_top: fetchBot.payload[0].client_config.label_crop_top,
      label_crop_bottom: fetchBot.payload[0].client_config.label_crop_bottom,
      qr_code_crop_x0: fetchBot.payload[0].client_config.qr_code_crop_x0,
      qr_code_crop_y0: fetchBot.payload[0].client_config.qr_code_crop_y0,
      qr_code_crop_x1: fetchBot.payload[0].client_config.qr_code_crop_x1,
      qr_code_crop_y1: fetchBot.payload[0].client_config.qr_code_crop_y1,
    },
    station_config: fetchBot.payload[0].client_config.station_config,
  };
};

const getPrinterBotInfo = fetchBot => {
  return {
    NAADI_BASE_WS_URL: NAADI_BASE_WS_URL,
    NAADI_BASE_URL: NAADI_BASE_URL,
    KEYCLOAK_OPENID_URL: KEYCLOAK_OPENID_URL,
    org_id: fetchBot.payload[0].org_id,
    branch_id: fetchBot.payload[0].branch_id,
    printer_config: {
      printer_id: fetchBot.payload[0]?.uuid,
      printer_name: fetchBot.payload[0]?.name,
      pref_format: fetchBot.payload[0]?.pref_format,
      print_exe_path: fetchBot.payload[0]?.client_config?.print_exe_path,
      printer_argument: fetchBot.payload[0]?.client_config?.printer_argument,
      printer_type: fetchBot.payload[0]?.client_config?.printer_type,
    },
  };
};

const getCncBotInfo = fetchBot => {
  return {
    NAADI_BASE_WS_URL: NAADI_BASE_WS_URL,
    NAADI_BASE_URL: NAADI_BASE_URL,
    KEYCLOAK_OPENID_URL: KEYCLOAK_OPENID_URL,
    org_id: fetchBot.payload[0].org_id,
    branch_id: fetchBot.payload[0].branch_id,
    cnc_client_config: {
      client_id: fetchBot.payload[0].uuid,
      machine_type: fetchBot.payload[0].client_config?.machine_type,
      machine_name: fetchBot.payload[0].client_config?.machine_name,
      serial_port_in: fetchBot.payload[0].client_config?.serial_port_in,
      serial_port_out: fetchBot.payload[0].client_config?.serial_port_out,
      baudrate: fetchBot.payload[0].client_config?.boudrate,
      read_timeout_ms: fetchBot.payload[0].client_config?.read_timeout_ms,
      queue_folder: fetchBot.payload[0].client_config?.queue_folder,
      program_suffix_length:
        fetchBot.payload[0].client_config?.program_suffix_length,
      feature_operation_mapping:
        fetchBot.payload[0].client_config?.feature_operation_mapping,
      barcodr_split_token:
        fetchBot.payload[0].client_config?.barcodr_split_token,
      program_path: fetchBot.payload[0].client_config?.program_path,
      program_target_path:
        fetchBot.payload[0].client_config?.program_target_path,
      program_file_ext: fetchBot.payload[0].client_config?.program_file_ext,
    },
    station_config: {
      pod: fetchBot.payload[0]?.client_pair_id,
    },
  };
};

const Box = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, isTab] = useMobile();
  const [bottype, setBottype] = useState('');
  const [botList, setBotList] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [botid, setBotid] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [titleText, setTitleText] = useState('');
  useEffect(() => {
    const search = location.search;
    const urlSearchParams = new URLSearchParams(search);
    const _params = Object.fromEntries(urlSearchParams.entries());
    setBottype(_params.type);
  }, [location, bottype]);

  function exportBotInfo(botInfo, bottype) {
    const data = yaml.stringify(botInfo);
    const blob = new Blob([data], { type: 'yaml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    if (bottype === 'DESIGN_TOOL') {
      link.download = 'Design Bot Config.yaml';
    } else if (bottype === 'CNC_CLIENT') {
      link.download = 'CNC Bot Config.yaml';
    } else if (bottype === 'PRINT_INTERCEPT_CLIENT') {
      link.download = 'BeamSaw Bot Config.yaml';
    } else if (bottype === 'SCAN_CLIENT') {
      link.download = 'Printer Bot Config.yaml';
    }
    link.href = url;
    link.click();
  }
  const fetchBots = useCallback(async () => {
    if (!bottype || bottype === '') {
      return;
    }
    try {
      dispatch(actions.busy.add('FETCH_BOT'));
      const req = {
        req_payload: {
          deleted: [false],
        },
      };
      let allbots;
      if (bottype === 'DESIGN_TOOL') {
        req.req_payload.client_type = [bottype];
        allbots = await getBots(req);
      } else if (
        bottype === 'CNC_CLIENT' ||
        bottype === 'PRINT_INTERCEPT_CLIENT'
      ) {
        req.req_payload.client_type = [bottype];
        allbots = await getCNCBots(req);
      } else if (bottype === 'SCAN_CLIENT') {
        allbots = await getPrinterBots(req);
      }
      if (allbots.status !== 200) {
        return toastError(FETCH_ERROR);
      }
      setBotList(allbots.payload);
    } finally {
      dispatch(actions.busy.remove('FETCH_BOT'));
    }
  }, [dispatch, bottype]);
  useEffect(() => {
    fetchBots();
  }, [fetchBots]);
  const getHumanizedDuration = printer => {
    if (!printer.connected_on) {
      return null;
    }
    const lastConnectedTime = moment().diff(
      moment(printer.connected_on),
      'minutes',
    );
    return moment.duration(lastConnectedTime, 'minutes').humanize();
  };
  const downloadBot = useCallback(
    async tid => {
      const req = {
        page: 0,
        size: 100,
        active: true,
        approved: true,
        req_payload: {
          deleted: [false],
          uuid: [tid],
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_BOT'));
        let fetchBot;
        if (bottype === 'DESIGN_TOOL') {
          fetchBot = await getBots(req);
        } else if (
          bottype === 'CNC_CLIENT' ||
          bottype === 'PRINT_INTERCEPT_CLIENT'
        ) {
          fetchBot = await getCNCBots(req);
        } else if (bottype === 'SCAN_CLIENT') {
          fetchBot = await getPrinterBots(req);
        }
        if (fetchBot && fetchBot.status !== 200) {
          return;
        }
        if (fetchBot && fetchBot.payload.length > 0) {
          if (bottype === 'DESIGN_TOOL') {
            exportBotInfo(getDesignBotInfo(fetchBot), bottype);
          } else if (bottype === 'CNC_CLIENT') {
            exportBotInfo(getCncBotInfo(fetchBot), bottype);
          } else if (bottype === 'PRINT_INTERCEPT_CLIENT') {
            exportBotInfo(getBeamSawBotInfo(fetchBot), bottype);
          } else if (bottype === 'SCAN_CLIENT') {
            exportBotInfo(getPrinterBotInfo(fetchBot), bottype);
          }
        }
      } finally {
        dispatch(actions.busy.remove('FETCH_BOT'));
      }
    },
    [bottype, dispatch],
  );

  const deleteBot = useCallback(
    async tid => {
      try {
        dispatch(actions.busy.add('DELETE_BOT'));
        let deleteBot;
        if (bottype === 'DESIGN_TOOL') {
          deleteBot = await delBot(tid);
        } else if (
          bottype === 'CNC_CLIENT' ||
          bottype === 'PRINT_INTERCEPT_CLIENT'
        ) {
          deleteBot = await delCNCBot(tid);
        } else if (bottype === 'SCAN_CLIENT') {
          deleteBot = await delPrinterBot(tid);
        }
        if (deleteBot && deleteBot.status !== 200) {
          return toastError('Unable to Delete Bot');
        }
        toastMessage({
          type: 'success',
          message: 'Bot Removed Successfully',
        });
        fetchBots();
      } finally {
        dispatch(actions.busy.remove('DELETE_BOT'));
      }
    },
    [dispatch, fetchBots, bottype],
  );

  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ToastContainer />

      <BottomDrawerContent
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        isMobile={isMobile}
        isTab={isTab}
        title={titleText}
        downloadBot={downloadBot}
        deleteBot={deleteBot}
        id={botid}
      />
      <div>
        <div className='container-fluid position-relative px-0'>
          <BotsHeader />
          <div style={{ background: 'white' }}>
            <Fragment>
              <div style={{ background: 'white', paddingLeft: '20px' }}>
                <div className='row' style={{ width: '288px' }}>
                  <div className='col-1'>
                    <div
                      style={{
                        height: '64px',
                        width: '4px',
                        background: '#008A50',
                      }}
                    ></div>
                  </div>

                  <div className='col-11' style={{ padding: '0px' }}>
                    <div style={{ height: '10px' }}></div>
                    <Dropdown
                      overlay={() => {
                        return <BotsTypeMenu />;
                      }}
                      trigger='click'
                    >
                      <div className='row' style={{ cursor: 'pointer' }}>
                        <div
                          className='col-11'
                          style={{
                            font: 'Roboto',
                            fontWeight: '400px',
                            fontSize: '24px',
                            color: '#006EED',
                          }}
                        >
                          {bottype
                            ? bottype === 'SCAN_CLIENT'
                              ? 'PRINTER'
                              : bottype
                            : 'Select Bots Type'}
                        </div>

                        <div
                          className='col-1'
                          style={{ color: '#323232', fontSize: '16px' }}
                        >
                          <FaAngleDown />
                        </div>
                      </div>
                    </Dropdown>
                    <div style={{ height: '8px' }}></div>
                    <div
                      style={{
                        font: 'Roboto',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: 'rgba(50, 50, 50, 0.4)',
                      }}
                    >
                      BOT TYPE
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
            <div
              style={{
                paddingTop: '48px',
                paddingLeft: '16px',
                paddingRight: '16px',
              }}
            >
              <div className='w-100'>
                <div className='row'>
                  {botList &&
                    botList.map(b => {
                      return (
                        <BoxDisplay
                          key={b.uuid}
                          botUuid={b.uuid}
                          botName={
                            bottype === 'SCAN_CLIENT' ? b.name : b.client_name
                          }
                          isMobile={isMobile}
                          isTab={isTab}
                          botList={botList}
                          showPreViewModal={showPreviewModal}
                          setShowPreviewModal={setShowPreviewModal}
                          setBotid={setBotid}
                          bottype={bottype}
                          menuOpen={menuOpen}
                          setMenuOpen={setMenuOpen}
                          downloadBot={downloadBot}
                          deleteBot={deleteBot}
                          setTitleText={setTitleText}
                          lastConnected={getHumanizedDuration(b)}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className='floating-btn'>
        <IconAddBox
          onClick={() => history.push(`/app/bot/newbot?type=` + bottype)}
        />
      </button>
    </div>
  );
};

export default Box;
