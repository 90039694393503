/* eslint-disable sonarjs/cognitive-complexity */

import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import BOMTable, { EditableCell } from '../bom_views/tableComponent';
import Chip from '../../../components/common/chips';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ColumnSelectionComponent from '../bom_views/columnSelector';
import {
  unsetGroupByCols,
  hideCSVTableModal,
  createBOM,
  resetEditActiveColumn,
  setEditActiveColumn,
} from '../../../store/order/bom/bomDataDuck';
import { setCSVData } from '../../../store/order/ordersList/actions';
import { JSONToCSVConvertor } from '../bom_views/utils';
import { EditIcon, CopyIcon, DeleteIcon } from '../../../assets/icons';
import { cloneDeep } from 'lodash';

const AddNewBOMSlectionComponent = ({ order_id, showModal, closeModal }) => {
  const dispatch = useDispatch();
  const group_by_cols = useSelector(state => state.bom_data.group_by_cols);
  const csv_data = useSelector(state => state.ordersList.csv_list || []);
  const column_headers = useSelector(
    state => state.ordersList.column_headers || [],
  );
  const csv_headers_data = useSelector(
    state => state.ordersList.csv_columns || [],
  );
  const file_name = useSelector(state => state.ordersList.file_name) || 'Bom';
  const _order_details = useSelector(state => state.ordersList.orders_details);
  const [order_details, setOrderDetails] = useState();
  const selectedOrderSrcId =
    useSelector(state => state.ordersList.selected_order_src_id) || null;
  const selectedOrderSrcType =
    useSelector(state => state.ordersList.selected_order_src_type) || null;
  const editable_rows = useSelector(state => state.bom_data.editable_rows);
  const { sideMenuToggled } = useSelector(state => state.appNav);
  const [data, setData] = React.useState(csv_data);
  useEffect(() => {
    const d = (_order_details || {})[`${order_id}`] || { uuid: order_id };
    setOrderDetails(d);
  }, [_order_details, order_id]);
  const handleClose = () => {
    dispatch(hideCSVTableModal(dispatch));
    closeModal();
    dispatch(
      setCSVData({
        list: [],
        columns: [],
        column_headers: [],
      }),
    );
    dispatch(resetEditActiveColumn(dispatch));
  };
  useEffect(() => {
    if (csv_data.length) {
      setData(csv_data);
    }
  }, [csv_data]);

  const ITEM_EXTERNAL_REF_FIELD = 'ITEM-EXTERNAL-REFERENCE';
  const hiddenColumns = ['__ROW_ERRORS__', '__ROW_FIELD_ERRORS__'];
  const Columns = React.useMemo(() => {
    if (csv_headers_data) {
      let temp_cols = cloneDeep(csv_headers_data);
      temp_cols.push({
        Header: 'Options',
        accessor: 'created_on',
        disableFilters: false,
        sticky: 'right',
        width: 120,
        maxWidth: 300,
        minWidth: 120,
        Cell: cellProps => {
          // eslint-disable-line
          const { row, row_index } = cellProps;
          const { values } = row; // eslint-disable-line
          return (
            <div className='delivery-date-col d-flex'>
              <div
                className=' clickable'
                onClick={() => cellProps.onRowEdit(row_index)}
              >
                <EditIcon />
              </div>
              <div
                className='mx-3 clickable'
                onClick={() => cellProps.onRowCopy(values)}
              >
                <CopyIcon />
              </div>
              <div
                className=' clickable'
                onClick={() => cellProps.onRowDelete(row_index)}
              >
                <DeleteIcon />
              </div>
            </div>
          );
        },
      });
      return temp_cols;
    }
    return [];
  }, [csv_headers_data]);

  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[`${rowIndex}`],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const addNewRow = () => {
    const empty_key_set = {};
    Columns.map(({ accessor }) => {
      empty_key_set[`${accessor}`] = '';
      return empty_key_set[`${accessor}`];
    });
    empty_key_set['#'] = data.length + '';
    empty_key_set['__ROW_ERRORS__'] = [];
    empty_key_set['__ROW_FIELD_ERRORS__'] = {};
    populateExternalRef(empty_key_set);
    setData([...data, empty_key_set]);
  };

  const onRowDelete = row_index => {
    setData(data.filter((item, item_idx) => item_idx !== row_index));
  };

  const onRowEdit = row_index => {
    dispatch(
      setEditActiveColumn({
        [`${row_index}`]: !editable_rows[`${row_index}`],
      }),
    );
  };
  const populateExternalRef = row_val => {
    const extRefList = [];
    data.forEach(val => {
      const extRef = val[`${ITEM_EXTERNAL_REF_FIELD}`];
      if (extRef) {
        extRefList.push(val[`${ITEM_EXTERNAL_REF_FIELD}`] + '');
      }
    });

    for (let i = 1; i <= data.length + 1; i++) {
      const extRef = i + '';
      if (extRefList.indexOf(extRef) === -1) {
        row_val[`${ITEM_EXTERNAL_REF_FIELD}`] = extRef;
      }
    }
  };
  const onRowCopy = row_val => {
    row_val['#'] = data.length + '';
    row_val['__ROW_ERRORS__'] = [];
    row_val['__ROW_FIELD_ERRORS__'] = {};
    populateExternalRef(row_val);
    setData([...data, row_val]);
  };
  const defaultColumn = {
    width: csv_headers_data.length > 6 ? 200 : 275,
    maxWidth: 400,
    minWidth: csv_headers_data.length > 6 ? 200 : 275,
    Cell: EditableCell,
  };

  const onAddBomClick = useCallback(async () => {
    const csv_data = JSONToCSVConvertor(data, 'temp_csv', true, column_headers);
    dispatch(
      createBOM(
        order_details,
        csv_data,
        file_name,
        selectedOrderSrcId,
        selectedOrderSrcType,
        group_by_cols,
        column_headers,
        closeModal,
      ),
    );
  }, [
    dispatch,
    data,
    column_headers,
    order_details,
    file_name,
    selectedOrderSrcId,
    selectedOrderSrcType,
    group_by_cols,
    closeModal,
  ]);
  const sideBarToggledCss =
    sideMenuToggled === true ? 'is-side-bar-toggled' : '';

  return (
    <Modal
      dialogClassName={`modal-90w naadi-modal-sidebar-padding ${sideBarToggledCss}`}
      aria-labelledby='contained-modal-title-vcenter'
      show={showModal}
      onHide={handleClose}
      centered
    >
      <Modal.Header className='border-0 p-4'>
        <div className='fs-24'>Preview</div>
        <div>
          <div className='text-label'>Group by</div>
          <ColumnSelectionComponent filters={Columns} />
        </div>
      </Modal.Header>
      <Container fluid>
        <Row>
          <Col className='text-right'>
            <div className='filter-labels-wrapper'>
              {group_by_cols.map((col_name, index) => (
                <Chip
                  key={col_name}
                  onClick={() => dispatch(unsetGroupByCols(col_name))}
                >
                  <span className='title-xs font-weight-bold'>{col_name}</span>
                </Chip>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal.Body>
        <BOMTable
          columns={Columns}
          data={data}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          addNewRow={addNewRow}
          defaultColumn={defaultColumn}
          onRowDelete={onRowDelete}
          onRowEdit={onRowEdit}
          onRowCopy={onRowCopy}
          hiddenColumns={hiddenColumns}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-primary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='primary' onClick={() => onAddBomClick()}>
          Add to BOM
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddNewBOMSlectionComponent.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  order_id: PropTypes.string,
};
export const CSVTableMenu = AddNewBOMSlectionComponent;
export default AddNewBOMSlectionComponent;
