"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _const = require("./const");

var reducer = function reducer() {
  var token = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _const.DEFAULT;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  if (action.type === _const.TYPES.SET) {
    return action.token;
  }

  return token;
};

var _default = reducer;
exports.default = _default;