import { DEFAULT } from './const';
import { createReducer } from '@reduxjs/toolkit';
import { resetOrderArchive, updateOrderArchive } from './actions';

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetOrderArchive, state => {
    state.orders = {};
  });
  builder.addCase(updateOrderArchive, (state, action) => {
    const order = action.payload;
    const value = {
      boxesItems: order.boxesItems,
      items: order.items,
      fetchBoxes: order.fetchBoxes,
      fetchBoxItems: order.fetchBoxItems,
      category: order.category,
      selection: order.selection,
      orgId: order.orgId,
      archiveDate: order.archiveDate,
    };
    const orderId = order.orderId;
    if (!state.orders) {
      state.orders = {};
    }
    const now = new Date().getTime();
    const hasExpired = orderVal => {
      let returnVal = false;
      if (!orderVal) {
        returnVal = false;
      } else if (!orderVal.archiveDate) {
        returnVal = true;
      } else {
        const diff = now - orderVal.archiveDate;
        if (!diff || diff > 2 * 24 * 60 * 60 * 1000) {
          returnVal = true;
        }
      }

      return returnVal;
    };
    const ordersList = Object.keys(state.orders).map(key => {
      return {
        order: key,
        archiveDate: state.orders[`${key}`].archiveDate,
      };
    });
    ordersList.sort((a, b) => {
      return b.archiveDate - a.archiveDate;
    });
    for (let i = 3; i < ordersList.length; i++) {
      const _order = ordersList[`${i}`];
      delete state.orders[`${_order.order}`];
    }
    Object.keys(state.orders).forEach(key => {
      if (hasExpired(state.orders[`${key}`])) {
        delete state.orders[`${key}`];
      }
    });
    state.orders[orderId + ''] = value;
  });
});

export default reducer;
