import React, { useCallback, Fragment, useState, useEffect } from 'react';
import BlackClose from '../../assets/icons/black-close.svg';
import DraggableImg from '../../assets/icons/draggable.svg';
import { DeleteIcon } from '../../assets/icons';
import Select from '../../components/uploadformat/select-upload';
import { cloneDeep } from 'lodash';
import Form from 'react-bootstrap/Form';
import BottomDrawerItems from './bottom-drawer-items';
import SearchIcon from '../../assets/icons/searchIcon.svg';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../report/customize-report';
const FeatureData = (
  indexVal,
  setSearchText,
  itemFeatures,
  featureSelected,
  setFeatureOpen,
) => {
  const [inputText, setInputText] = useState('');
  useEffect(() => {
    setSearchText(inputText);
  }, [setSearchText, inputText]);
  return (
    <div>
      <div style={{ fontSize: '20px', textAlign: 'center' }}>
        SELECT ITEM FEATURE
      </div>
      <div style={{ height: '30px' }}></div>
      <div>
        <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
        <input
          type='text'
          id='search_field'
          className='searchModal'
          style={{
            border: '0px !important',
            color: 'rgba(50, 50, 50, 0.4)',
            fontStyle: 'italic',
          }}
          value={inputText}
          onChange={e => {
            setInputText(e.target.value);
          }}
          placeholder='Type here to search Feature'
        />
      </div>

      {itemFeatures &&
        itemFeatures.length > 0 &&
        itemFeatures
          .filter(iF => iF.name.toUpperCase().includes(inputText.toUpperCase()))
          .map((row, index) => (
            <div key={row.uuid}>
              <div
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  featureSelected(row, indexVal);
                  setFeatureOpen(false);
                }}
              >
                {row.name}
              </div>
              <div
                className='operation-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
    </div>
  );
};
const ItemFeaturesFilter = ({
  itemFeatures,
  addFeatureClick,
  setAddFeatureClick,
  indexVal,
  isMobile,
  isTab,
  setFeatureOpen,
}) => {
  const getOptions = useCallback(
    term => {
      return new Promise((resolve, reject) => {
        if (itemFeatures) {
          resolve(
            itemFeatures.filter(val =>
              val.feature_code.includes(term.toUpperCase()),
            ),
          );
        } else {
          reject([]);
        }
      });
    },
    [itemFeatures],
  );
  return (
    <Fragment>
      {itemFeatures && !(isMobile || isTab) && (
        <Select
          label={`Add feature`}
          getOptions={getOptions}
          labelField='name'
          valueField='feature_code'
          onSelect={i => {
            setAddFeatureClick(
              addFeatureClick.map((val, index) => {
                if (
                  index === indexVal &&
                  !addFeatureClick.find(
                    aFC => aFC.objVal.sel_feature === i.feature_code,
                  )
                ) {
                  const updatedVal = cloneDeep(val);
                  updatedVal.objVal.clicked = true;
                  updatedVal.objVal.sel_feature = i.feature_code;
                  updatedVal.objVal.sel_feature_val = i.name;
                  return updatedVal;
                }
                return { ...val };
              }),
            );
          }}
          //selected={pipelineMode ? null : selection}
          //className='page-size-selector dropdown-table-filter'
          search
        />
      )}
      {(isMobile || isTab) && (
        <div onClick={() => setFeatureOpen(true)}>Add feature</div>
      )}
    </Fragment>
  );
};
const ConfigureColumn = ({
  isMobile,
  isTab,
  itemFeatures,
  conform,
  onConFormChange,
  addFeatureClick,
  setAddFeatureClick,
}) => {
  const [featureOpen, setFeatureOpen] = useState(false);
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState('');
  const [placeholderProps, setPlaceholderProps] = useState({});
  const featureSelected = useCallback(
    async (feature, indexVal) => {
      setAddFeatureClick(
        addFeatureClick.map((val, index) => {
          if (
            index === indexVal &&
            !addFeatureClick.find(
              aFC => aFC.objVal.sel_feature === feature.feature_code,
            )
          ) {
            const updatedVal = cloneDeep(val);
            updatedVal.objVal.clicked = true;
            updatedVal.objVal.sel_feature = feature.feature_code;
            updatedVal.objVal.sel_feature_val = feature.name;
            return updatedVal;
          }
          return { ...val };
        }),
      );
    },
    [setAddFeatureClick, addFeatureClick],
  );

  const closeBtnClicked = useCallback(
    indexVal => {
      setAddFeatureClick(
        addFeatureClick.map((val, index) => {
          if (index === indexVal) {
            const updatedVal = cloneDeep(val);
            updatedVal.objVal.clicked = false;
            updatedVal.objVal.sel_feature = '';
            updatedVal.objVal.sel_feature_val = '';
            return updatedVal;
          }
          return val;
        }),
      );
    },
    [addFeatureClick, setAddFeatureClick],
  );
  const addColumns = useCallback(
    e => {
      const aFClick = cloneDeep(addFeatureClick);
      const addFeatureObj = {
        objVal: {
          clicked: false,
          sel_feature: '',
          sel_feature_val: '',
        },
      };
      aFClick.push(addFeatureObj);
      setAddFeatureClick(aFClick);
    },
    [addFeatureClick, setAddFeatureClick],
  );
  /*const onDragUpdate = useCallback(
    update => {
      if (!update.destination) {
        return;
      }
      if (update.destination.droppableId !== 'uploadcolumns') {
        setPlaceholderProps({});
        return;
      }
      const draggableId = update.draggableId;
      const destinationIndex = update.destination.index;

      const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`;
      const draggedDOM = document.querySelector(domQuery);

      if (!draggedDOM) {
        return;
      }
      const { clientHeight, clientWidth } = draggedDOM;

      const clientY =
        parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
        [...draggedDOM.parentNode.children]
          .slice(0, destinationIndex)
          .reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
          }, 0);

      setPlaceholderProps({
        clientHeight,
        clientWidth,
        clientY,
        clientX: parseFloat(
          window.getComputedStyle(draggedDOM.parentNode).paddingLeft
        ),
      });
    },
    [setPlaceholderProps]
  );*/
  const onDragEnd = useCallback(
    async result => {
      setPlaceholderProps({});
      const { source, destination } = result;
      // dropped outside the list
      if (!destination) {
        return;
      }
      if (destination.droppableId !== 'uploadcolumns') {
        return;
      }
      if (destination.index === source.index) return;
      setAddFeatureClick(addFeatureClick => {
        const _addFeatureClick = cloneDeep(addFeatureClick);
        const src = _addFeatureClick[source.index];
        const dst = _addFeatureClick[destination.index];
        if (!src || !dst) {
          return addFeatureClick;
        }

        _addFeatureClick.splice(source.index, 1);
        _addFeatureClick.splice(destination.index, 0, src);
        return _addFeatureClick;
      });
    },
    [setPlaceholderProps, setAddFeatureClick],
  );
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgba(151,151,151,0.2)' : '',
    marginRight: isDraggingOver ? '32px' : '0px',
  });
  const AddRow = useCallback(
    ({ indexVal, element, inputVal, inputValFeature }) => {
      return (
        <div
          className='row'
          style={{
            paddingTop: '24px',
            marginLeft: '0px',
            maxWidth: isMobile ? '320px' : '60%',
          }}
        >
          <div className='col-1'>{indexVal + 1}</div>
          <div className='col-1'>
            <img src={DraggableImg} alt=''></img>
          </div>

          {element.objVal.clicked === false && (
            <div
              className='col-9'
              style={{ textAlign: 'center', color: '#006EED' }}
            >
              <ItemFeaturesFilter
                itemFeatures={itemFeatures}
                addFeatureClick={addFeatureClick}
                setAddFeatureClick={setAddFeatureClick}
                indexVal={indexVal}
                isMobile={isMobile}
                isTab={isTab}
                setFeatureOpen={setFeatureOpen}
              />
            </div>
          )}
          <BottomDrawerItems
            isMobile={isMobile}
            isTab={isTab}
            itemOpen={featureOpen}
            setItemOpen={setFeatureOpen}
            itemData={FeatureData(
              indexVal,
              setSearchText,
              itemFeatures,
              featureSelected,
              setFeatureOpen,
            )}
          />
          {element.objVal.clicked === true && (
            <div className='col-9'>
              <div
                className='row'
                style={{
                  margin: '0px',
                  padding: '0px',
                }}
              >
                <div className='col-10'>
                  <Form.Control
                    id={'itemname' + indexVal}
                    className='txt-input primary'
                    type='text'
                    autoComplete='off'
                    name={'itemname' + indexVal}
                    placeholder='Item Name'
                    value={`${inputVal}`}
                    onChange={onConFormChange}
                  />
                  <div style={{ fontSize: '10px' }}>
                    <Form.Control
                      id={'itemfeature' + indexVal}
                      className='txt-input primary'
                      type='text'
                      autoComplete='off'
                      name={'itemfeature' + indexVal}
                      placeholder='Item Feature'
                      value={`${inputValFeature}`}
                      onChange={onConFormChange}
                    />
                  </div>
                </div>
                <div className='col-2'>
                  <img
                    src={BlackClose}
                    onClick={() => closeBtnClicked(indexVal)}
                    alt=''
                  ></img>
                </div>
              </div>
            </div>
          )}
          <div
            className='col-1'
            onClick={() => {
              setAddFeatureClick(afc => {
                const newList = [...afc];
                newList.splice(indexVal, 1);
                return newList;
              });
            }}
          >
            <DeleteIcon />
          </div>
        </div>
      );
    },
    [
      addFeatureClick,
      closeBtnClicked,
      itemFeatures,
      onConFormChange,
      setAddFeatureClick,
      setFeatureOpen,
      isMobile,
      isTab,
      featureOpen,
      featureSelected,
    ],
  );
  return (
    <div
      className='bg-white'
      style={{
        alignContent: 'left',
        marginLeft: '20px',
        marginTop: '36px',
      }}
    >
      <div>
        <div>Configure Columns</div>
        <div style={{ height: '16px' }}></div>
      </div>

      <Form style={{ padding: '0px' }}>
        <Fragment>
          <DragDropContext onDragEnd={onDragEnd} onDragUpdate={() => {}}>
            <StrictModeDroppable droppableId='uploadcolumns'>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {addFeatureClick &&
                    addFeatureClick.length > 0 &&
                    addFeatureClick.map((element, index) => {
                      return (
                        <Draggable
                          key={`upload-column-${index}`}
                          draggableId={index + ''}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={`available-column-${index}`}
                            >
                              <AddRow
                                key={index || element}
                                indexVal={index}
                                element={element}
                                inputVal={
                                  conform[`itemname${index}`] === ''
                                    ? ''
                                    : conform[`itemname${index}`] ||
                                      element.objVal.sel_feature_val ||
                                      ''
                                }
                                inputValFeature={
                                  conform[`itemfeature${index}`] === ''
                                    ? ''
                                    : conform[`itemfeature${index}`] ||
                                      element.objVal.sel_feature ||
                                      ''
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                  <div
                    style={{
                      position: 'absolute',
                      top: (placeholderProps.clientY || 0) + 51,
                      left: (placeholderProps.clientX || 0) + 15,
                      height: (placeholderProps.clientHeight || 0) + 1,
                      width: placeholderProps.clientWidth || 0,
                      background: '#eee',
                    }}
                  />
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </Fragment>
        <div
          style={{
            textAlign: 'center',
            color: '#006EED',
            maxWidth: '320px',
            paddingTop: '16px',
            cursor: 'pointer',
            paddingBottom: '110px',
          }}
          onClick={() => addColumns()}
        >
          Add Columns +
        </div>
      </Form>
    </div>
  );
};

export default ConfigureColumn;
