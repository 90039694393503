import React from 'react';
import BottomDrawer from '../../components/template/bottomdrawer';

const BottomDrawerItems = ({
  isMobile,
  isTab,
  itemOpen,
  setItemOpen,
  itemData,
}) => {
  return (
    <BottomDrawer
      entity={''}
      drawerTitle=''
      handleClose={() => setItemOpen(false)}
      popUp={itemOpen && (isMobile || isTab)}
      drawerBody={
        <div
          style={{
            margin: 'auto',
            padding: '30px',
            position: 'relative',
          }}
        >
          {itemData}
        </div>
      }
    />
  );
};

export default BottomDrawerItems;
