import PropTypes from 'prop-types';
import React, { memo } from 'react';

export const TrackTraceItemImageIcon = memo(({ fill, disabled, invert }) => {
  let _fill = fill ? fill : '#323232';
  if (disabled && !fill) {
    _fill = '#DEDEDE';
  } else if (invert) {
    _fill = '#ffffff';
  }

  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='16'
        height='13'
        viewBox='0 0 16 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M14.125 0.625H4.5C3.54297 0.625 2.75 1.41797 2.75 2.375V8.5C2.75 9.45703 3.54297 10.25 4.5 10.25H14.125C15.082 10.25 15.875 9.45703 15.875 8.5V2.375C15.875 1.41797 15.082 0.625 14.125 0.625ZM14.5625 8.5C14.5625 8.74609 14.3711 8.9375 14.125 8.9375H13.6875L10.707 4.53516C10.625 4.42578 10.4883 4.34375 10.3242 4.34375C10.1875 4.34375 10.0508 4.42578 9.96875 4.53516L8.24609 7.10547L7.64453 6.28516C7.5625 6.14844 7.42578 6.09375 7.28906 6.09375C7.15234 6.09375 7.01562 6.14844 6.93359 6.28516L4.9375 8.9375H4.5C4.25391 8.9375 4.0625 8.74609 4.0625 8.5V2.375C4.0625 2.12891 4.25391 1.9375 4.5 1.9375H14.125C14.3711 1.9375 14.5625 2.12891 14.5625 2.375V8.5ZM1.4375 10.6875V2.375C0.726562 2.375 0.125 2.97656 0.125 3.6875V11.125C0.125 12.082 0.917969 12.875 1.875 12.875H11.9375C12.6484 12.875 13.25 12.2734 13.25 11.5625H2.3125C1.82031 11.5625 1.4375 11.1797 1.4375 10.6875ZM6.25 2.8125C5.75781 2.8125 5.375 3.19531 5.375 3.6875C5.375 4.17969 5.75781 4.5625 6.25 4.5625C6.74219 4.5625 7.125 4.17969 7.125 3.6875C7.125 3.19531 6.74219 2.8125 6.25 2.8125Z'
          fill={_fill}
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
});
TrackTraceItemImageIcon.propTypes = {
  fill: PropTypes.string,
};

export const TrackTraceScanIcon = memo(({ fill, invert }) => {
  let _fill = fill ? fill : '#323232';
  if (invert) {
    _fill = '#ffffff';
  }
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='17'
        height='13'
        viewBox='0 0 17 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16.4375 11.125H12.9375C12.6914 11.125 12.5 11.3164 12.5 11.5625V12.4375C12.5 12.6836 12.6914 12.875 12.9375 12.875H16.4375C16.6836 12.875 16.875 12.6836 16.875 12.4375V11.5625C16.875 11.3164 16.6836 11.125 16.4375 11.125ZM16.4375 7.625H12.9375C12.6914 7.625 12.5 7.81641 12.5 8.0625C12.5 8.30859 12.6914 8.5 12.9375 8.5H16.4375C16.6836 8.5 16.875 8.30859 16.875 8.0625C16.875 7.81641 16.6836 7.625 16.4375 7.625ZM16.4375 9.375H12.9375C12.6914 9.375 12.5 9.56641 12.5 9.8125C12.5 10.0586 12.6914 10.25 12.9375 10.25H16.4375C16.6836 10.25 16.875 10.0586 16.875 9.8125C16.875 9.56641 16.6836 9.375 16.4375 9.375ZM16.4375 5H12.9375C12.6914 5 12.5 5.19141 12.5 5.4375V6.3125C12.5 6.55859 12.6914 6.75 12.9375 6.75H16.4375C16.6836 6.75 16.875 6.55859 16.875 6.3125V5.4375C16.875 5.19141 16.6836 5 16.4375 5ZM16.4375 0.625H12.9375C12.6914 0.625 12.5 0.816406 12.5 1.0625V1.9375C12.5 2.18359 12.6914 2.375 12.9375 2.375H16.4375C16.6836 2.375 16.875 2.18359 16.875 1.9375V1.0625C16.875 0.816406 16.6836 0.625 16.4375 0.625ZM16.4375 3.25H12.9375C12.6914 3.25 12.5 3.44141 12.5 3.6875C12.5 3.93359 12.6914 4.125 12.9375 4.125H16.4375C16.6836 4.125 16.875 3.93359 16.875 3.6875C16.875 3.44141 16.6836 3.25 16.4375 3.25ZM9.875 0.625H4.1875C2.49219 0.625 1.125 1.99219 1.125 3.6875C1.125 5.05469 2.05469 6.23047 3.28516 6.61328L1.28906 10.0312C0.933594 10.6602 1.15234 11.4531 1.78125 11.8359L3.28516 12.7109C3.50391 12.8203 3.72266 12.875 3.94141 12.875C4.40625 12.875 4.84375 12.6289 5.08984 12.2188L8.23438 6.75H9.875C10.3672 6.75 10.75 6.36719 10.75 5.875V1.5C10.75 1.00781 10.3672 0.625 9.875 0.625ZM3.94141 11.5625L2.4375 10.6875L4.73438 6.75H6.73047L3.94141 11.5625ZM9.4375 5.4375H4.1875C3.23047 5.4375 2.4375 4.64453 2.4375 3.6875C2.4375 2.73047 3.23047 1.9375 4.1875 1.9375H9.4375V5.4375Z'
          fill={_fill}
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
});
TrackTraceScanIcon.propTypes = {
  fill: PropTypes.string,
};

export const TrackTraceManualScanIcon = memo(({ fill, invert }) => {
  let _fill = fill ? fill : '#323232';
  if (invert) {
    _fill = '#ffffff';
  }
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='12'
        height='15'
        viewBox='0 0 12 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.9375 3.375C3.64844 3.375 4.25 2.77344 4.25 2.0625C4.25 1.37891 3.64844 0.75 2.9375 0.75C2.19922 0.75 1.625 1.35156 1.625 2.0625C1.625 2.74609 2.19922 3.375 2.9375 3.375ZM10.8125 3.375H6.4375C6.19141 3.375 6 3.56641 6 3.78516V7.3125H5.07031L4.19531 5.20703C3.94922 4.63281 3.40234 4.25 2.77344 4.25H2.28125C1.43359 4.25 0.75 4.93359 0.75 5.75391V8.54297C0.75 8.76172 0.832031 9.33594 1.32422 9.71875L3.40234 11.3594C3.56641 11.4961 3.67578 11.6602 3.73047 11.8516L4.25 14.1758C4.33203 14.5039 4.66016 14.75 5.04297 14.6406C5.39844 14.5859 5.61719 14.2031 5.53516 13.8477L4.93359 11.332C4.87891 11.0039 4.6875 10.7031 4.41406 10.4844L3.375 9.74609V6.62891L3.97656 8.10547C4.11328 8.40625 4.41406 8.625 4.76953 8.625H10.8125C11.0312 8.625 11.25 8.43359 11.25 8.21484V3.8125C11.25 3.59375 11.0312 3.375 10.8125 3.375ZM9.9375 7.3125H7.3125V4.6875H9.9375V7.3125ZM0.75 14.0938C0.75 14.4492 1.02344 14.7227 1.40625 14.7227C1.76172 14.7227 2.0625 14.4766 2.0625 14.0938V11.4688C1.73438 11.1953 0.75 10.4297 0.75 10.4023V14.0938Z'
          fill={_fill}
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
});
TrackTraceManualScanIcon.propTypes = {
  fill: PropTypes.string,
};

export const TrackTraceHistoryIcon = memo(({ fill, error, invert }) => {
  let _fill = fill ? fill : '#323232';
  if (error === true && !fill) {
    _fill = '#C00000';
  } else if (invert) {
    _fill = '#fff';
  }
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='14'
        height='13'
        viewBox='0 0 14 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7 0.625C4.8125 0.625 2.81641 1.82812 1.75 3.66016V1.28125C1.75 0.925781 1.44922 0.625 1.09375 0.625C0.710938 0.625 0.4375 0.925781 0.4375 1.28125V5.21875C0.4375 5.60156 0.710938 5.875 1.09375 5.875H4.59375C4.94922 5.875 5.25 5.60156 5.25 5.21875C5.25 4.86328 4.94922 4.5625 4.59375 4.5625H2.70703C3.52734 3.00391 5.16797 1.9375 7 1.9375C9.65234 1.9375 11.8125 4.09766 11.8125 6.75C11.8125 9.40234 9.65234 11.5625 7 11.5625C5.85156 11.5625 4.75781 11.1523 3.91016 10.4414C3.63672 10.1953 3.22656 10.25 2.98047 10.5234C2.76172 10.7969 2.78906 11.207 3.0625 11.4531C4.15625 12.3828 5.55078 12.875 7 12.875C10.3633 12.875 13.125 10.1406 13.125 6.75C13.125 3.38672 10.3633 0.625 7 0.625ZM7 3.25C6.61719 3.25 6.34375 3.55078 6.34375 3.90625V7.1875C6.34375 7.40625 6.42578 7.625 6.61719 7.73438L8.14844 8.82812C8.25781 8.91016 8.39453 8.9375 8.50391 8.9375C8.69531 8.9375 8.88672 8.85547 9.02344 8.66406C9.24219 8.39062 9.16016 7.98047 8.88672 7.76172L7.65625 6.85938V3.90625C7.65625 3.55078 7.35547 3.25 7 3.25Z'
          fill={_fill}
        />
      </svg>
    </div>
  );
});
TrackTraceHistoryIcon.propTypes = {
  fill: PropTypes.string,
};
export const TrackTracePrintIcon = memo(({ fill, error, invert }) => {
  let _fill = fill ? fill : '#323232';
  if (error === true && !fill) {
    _fill = '#C00000';
  } else if (invert) {
    _fill = '#fff';
  }
  _fill = '#006EED';

  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='16'
        height='14'
        viewBox='0 0 16 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.2 0H12.8V3.11111H3.2V0ZM2.4 3.88889H13.6C14.928 3.88889 16 4.93111 16 6.22222V10.8889H12.8V14H3.2V10.8889H0V6.22222C0 4.93111 1.072 3.88889 2.4 3.88889ZM4.8 12.4444H11.2V8.55556H4.8V12.4444ZM13.6 7C13.16 7 12.8 6.65 12.8 6.22222C12.8 5.79444 13.16 5.44444 13.6 5.44444C14.04 5.44444 14.4 5.79444 14.4 6.22222C14.4 6.65 14.04 7 13.6 7Z'
          fill={_fill}
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
});
TrackTracePrintIcon.propTypes = {
  fill: PropTypes.string,
};

export const TrackTracePrintIconHeader = memo(({ fill, error, invert }) => {
  let _fill = fill ? fill : '#323232';
  if (error === true && !fill) {
    _fill = '#C00000';
  } else if (invert) {
    _fill = '#fff';
  }
  _fill = '#006EED';
  return (
    <div
      className='cursor-pointer standard-icon-wrp'
      style={{ paddingBottom: '5px', marginLeft: '20px' }}
    >
      <svg
        width='20'
        height='18'
        viewBox='0 0 20 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4 0H16V3.9995H4V0ZM3 4.99984H17C18.66 4.99984 20 6.33967 20 7.99947V13.9987H16V17.9982H4V13.9987H0V7.99947C0 6.33967 1.34 4.99984 3 4.99984ZM6 15.9957H14V10.9963H6V15.9957ZM17 9.00118C16.45 9.00118 16 8.55124 16 8.00131C16 7.45137 16.45 7.00143 17 7.00143C17.55 7.00143 18 7.45137 18 8.00131C18 8.55124 17.55 9.00118 17 9.00118Z'
          fill={_fill}
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
});
TrackTracePrintIconHeader.propTypes = {
  fill: PropTypes.string,
};
export const TrackTraceItemDetailIcon = memo(({ fill, invert, onClick }) => {
  let _fill = fill ? fill : '#006EED';
  if (invert) {
    _fill = '#ffffff';
  }
  return (
    <div className='cursor-pointer standard-icon-wrp' onClick={onClick}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8 0.5C3.84375 0.5 0.5 3.84375 0.5 8C0.5 12.1562 3.84375 15.5 8 15.5C12.1562 15.5 15.5 12.1562 15.5 8C15.5 3.84375 12.1562 0.5 8 0.5ZM8 14C4.6875 14 2 11.3125 2 8C2 4.6875 4.6875 2 8 2C11.3125 2 14 4.6875 14 8C14 11.3125 11.3125 14 8 14ZM9.25 10.5H8.75V7.75C8.75 7.34375 8.40625 7 8 7H7C6.59375 7 6.25 7.34375 6.25 7.75C6.25 8.15625 6.59375 8.5 7 8.5H7.25V10.5H6.75C6.34375 10.5 6 10.8438 6 11.25C6 11.6562 6.34375 12 6.75 12H9.25C9.65625 12 10 11.6562 10 11.25C10 10.8438 9.65625 10.5 9.25 10.5ZM8 6C8.5625 6 9 5.5625 9 5C9 4.4375 8.5625 4 8 4C7.4375 4 7 4.4375 7 5C7 5.5625 7.4375 6 8 6Z'
          fill={_fill}
        />
      </svg>
    </div>
  );
});
TrackTraceItemDetailIcon.propTypes = {
  fill: PropTypes.string,
  invert: PropTypes.bool,
};
export const TrackTraceCtxSelectIcon = memo(({ fill, invert, onClick }) => {
  let _fill = fill ? fill : '#006EED';
  if (invert) {
    _fill = '#ffffff';
  }
  return (
    <div className='cursor-pointer standard-icon-wrp' onClick={onClick}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.25 0H11.0312C10.5938 0 10.25 0.25 10.0625 0.625C9.90625 1 10 1.4375 10.3125 1.75L11.5625 3L5.53125 9.03125C5.15625 9.4375 5.15625 10.0625 5.53125 10.4688C5.75 10.6562 6 10.75 6.25 10.75C6.5 10.75 6.75 10.6562 6.96875 10.4688L13 4.4375L14.25 5.6875C14.4375 5.90625 14.7188 6 14.9688 6C15.125 6 15.25 5.96875 15.375 5.9375C15.75 5.75 16 5.40625 16 4.96875V0.75C16 0.34375 15.6562 0 15.25 0ZM12.25 10C11.8438 10 11.5 10.3438 11.5 10.75V14.25C11.5 14.375 11.375 14.5 11.25 14.5H1.75C1.625 14.5 1.5 14.375 1.5 14.25V4.75C1.5 4.625 1.625 4.5 1.75 4.5H5.25C5.65625 4.5 6 4.15625 6 3.75C6 3.34375 5.65625 3 5.25 3H1.75C0.78125 3 0 3.78125 0 4.75V14.25C0 15.2188 0.78125 16 1.75 16H11.25C12.2188 16 13 15.2188 13 14.25V10.75C13 10.3438 12.6562 10 12.25 10Z'
          fill={_fill}
        />
      </svg>
    </div>
  );
});
TrackTraceCtxSelectIcon.propTypes = {
  fill: PropTypes.string,
  invert: PropTypes.bool,
};

export const TrackTraceViewModeList = memo(({ fill }) => {
  let _fill = fill ? fill : '#323232';
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='14'
        height='12'
        viewBox='0 0 14 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.1875 4.4375H0.4375C0.191406 4.4375 0 4.62891 0 4.875V6.625C0 6.87109 0.191406 7.0625 0.4375 7.0625H2.1875C2.43359 7.0625 2.625 6.87109 2.625 6.625V4.875C2.625 4.62891 2.43359 4.4375 2.1875 4.4375ZM2.1875 8.8125H0.4375C0.191406 8.8125 0 9.00391 0 9.25V11C0 11.2461 0.191406 11.4375 0.4375 11.4375H2.1875C2.43359 11.4375 2.625 11.2461 2.625 11V9.25C2.625 9.00391 2.43359 8.8125 2.1875 8.8125ZM2.1875 0.0625H0.4375C0.191406 0.0625 0 0.253906 0 0.5V2.25C0 2.49609 0.191406 2.6875 0.4375 2.6875H2.1875C2.43359 2.6875 2.625 2.49609 2.625 2.25V0.5C2.625 0.253906 2.43359 0.0625 2.1875 0.0625ZM13.3438 5.09375H5.03125C4.67578 5.09375 4.375 5.39453 4.375 5.75C4.375 6.10547 4.67578 6.40625 5.03125 6.40625H13.3438C13.6992 6.40625 14 6.10547 14 5.75C14 5.39453 13.6992 5.09375 13.3438 5.09375ZM13.3438 0.71875H5.03125C4.67578 0.71875 4.375 1.01953 4.375 1.375C4.375 1.73047 4.67578 2.03125 5.03125 2.03125H13.3438C13.6992 2.03125 14 1.73047 14 1.375C14 1.01953 13.6992 0.71875 13.3438 0.71875ZM13.3438 9.46875H5.03125C4.67578 9.46875 4.375 9.76953 4.375 10.125C4.375 10.4805 4.67578 10.7812 5.03125 10.7812H13.3438C13.6992 10.7812 14 10.4805 14 10.125C14 9.76953 13.6992 9.46875 13.3438 9.46875Z'
          fill={_fill}
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
});
TrackTraceViewModeList.propTypes = {
  fill: PropTypes.string,
};
export const TrackTraceViewModeDetail = memo(({ fill }) => {
  let _fill = fill ? fill : '#323232';
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='14'
        height='13'
        viewBox='0 0 14 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.25 0.625H1.75C0.792969 0.625 0 1.41797 0 2.375V11.125C0 12.082 0.792969 12.875 1.75 12.875H12.25C13.207 12.875 14 12.082 14 11.125V2.375C14 1.41797 13.207 0.625 12.25 0.625ZM12.6875 2.375V4.34375H5.03125V1.9375H12.25C12.4961 1.9375 12.6875 2.12891 12.6875 2.375ZM5.03125 5.65625H12.6875V7.84375H5.03125V5.65625ZM3.71875 7.84375H1.3125V5.65625H3.71875V7.84375ZM1.75 1.9375H3.71875V4.34375H1.3125V2.375C1.3125 2.12891 1.50391 1.9375 1.75 1.9375ZM1.3125 11.125V9.15625H3.71875V11.5625H1.75C1.50391 11.5625 1.3125 11.3711 1.3125 11.125ZM12.25 11.5625H5.03125V9.15625H12.6875V11.125C12.6875 11.3711 12.4961 11.5625 12.25 11.5625Z'
          fill={_fill}
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
});
TrackTraceViewModeDetail.propTypes = {
  fill: PropTypes.string,
};

export const TrackTraceViewModeSmallImage = memo(({ fill }) => {
  let _fill = fill ? fill : '#323232';
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='14'
        height='11'
        viewBox='0 0 14 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.2812 4.875H13.3438C13.6992 4.875 14 4.57422 14 4.21875C14 3.86328 13.6992 3.5625 13.3438 3.5625H10.9375V1.15625C10.9375 0.800781 10.6367 0.5 10.2812 0.5C9.92578 0.5 9.625 0.800781 9.625 1.15625V4.21875C9.625 4.57422 9.92578 4.875 10.2812 4.875ZM3.71875 6.625H0.65625C0.300781 6.625 0 6.92578 0 7.28125C0 7.63672 0.300781 7.9375 0.65625 7.9375H3.0625V10.3438C3.0625 10.6992 3.36328 11 3.71875 11C4.07422 11 4.375 10.6992 4.375 10.3438V7.28125C4.375 6.92578 4.07422 6.625 3.71875 6.625ZM13.3438 6.625H10.2812C9.92578 6.625 9.625 6.92578 9.625 7.28125V10.3438C9.625 10.6992 9.92578 11 10.2812 11C10.6367 11 10.9375 10.6992 10.9375 10.3438V7.9375H13.3438C13.6992 7.9375 14 7.63672 14 7.28125C14 6.92578 13.6992 6.625 13.3438 6.625ZM3.71875 0.5C3.36328 0.5 3.0625 0.800781 3.0625 1.15625V3.5625H0.65625C0.300781 3.5625 0 3.86328 0 4.21875C0 4.57422 0.300781 4.875 0.65625 4.875H3.71875C4.07422 4.875 4.375 4.57422 4.375 4.21875V1.15625C4.375 0.800781 4.07422 0.5 3.71875 0.5Z'
          fill={_fill}
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
});
TrackTraceViewModeSmallImage.propTypes = {
  fill: PropTypes.string,
};

export const TrackTraceViewModeLargeImage = memo(({ fill }) => {
  let _fill = fill ? fill : '#323232';
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='14'
        height='11'
        viewBox='0 0 14 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.71875 9.6875H1.3125V7.28125C1.3125 6.92578 1.01172 6.625 0.65625 6.625C0.300781 6.625 0 6.92578 0 7.28125V10.3438C0 10.6992 0.300781 11 0.65625 11H3.71875C4.07422 11 4.375 10.6992 4.375 10.3438C4.375 9.98828 4.07422 9.6875 3.71875 9.6875ZM3.71875 0.5H0.65625C0.300781 0.5 0 0.800781 0 1.15625V4.21875C0 4.57422 0.300781 4.875 0.65625 4.875C1.01172 4.875 1.3125 4.57422 1.3125 4.21875V1.8125H3.71875C4.07422 1.8125 4.375 1.51172 4.375 1.15625C4.375 0.800781 4.07422 0.5 3.71875 0.5ZM13.3438 0.5H10.2812C9.92578 0.5 9.625 0.800781 9.625 1.15625C9.625 1.51172 9.92578 1.8125 10.2812 1.8125H12.6875V4.21875C12.6875 4.57422 12.9883 4.875 13.3438 4.875C13.6992 4.875 14 4.57422 14 4.21875V1.15625C14 0.800781 13.6992 0.5 13.3438 0.5ZM13.3438 6.625C12.9883 6.625 12.6875 6.92578 12.6875 7.28125V9.6875H10.2812C9.92578 9.6875 9.625 9.98828 9.625 10.3438C9.625 10.6992 9.92578 11 10.2812 11H13.3438C13.6992 11 14 10.6992 14 10.3438V7.28125C14 6.92578 13.6992 6.625 13.3438 6.625Z'
          fill={_fill}
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
});
TrackTraceViewModeLargeImage.propTypes = {
  fill: PropTypes.string,
};

export const TrackTraceViewModeImage = memo(({ fill }) => {
  let _fill = fill ? fill : '#323232';
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='14'
        height='13'
        viewBox='0 0 14 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.9375 2.8125C3.44531 2.8125 3.0625 3.19531 3.0625 3.6875C3.0625 4.17969 3.44531 4.5625 3.9375 4.5625C4.42969 4.5625 4.8125 4.17969 4.8125 3.6875C4.8125 3.19531 4.42969 2.8125 3.9375 2.8125ZM13.125 2.375C13.125 1.41797 12.332 0.625 11.375 0.625H2.625C1.66797 0.625 0.875 1.41797 0.875 2.375V11.125C0.875 12.082 1.66797 12.875 2.625 12.875H11.375C12.332 12.875 13.125 12.082 13.125 11.125V2.375ZM11.8125 11.125C11.8125 11.3711 11.6211 11.5625 11.375 11.5625H2.625C2.37891 11.5625 2.1875 11.3711 2.1875 11.125V9.8125H11.8125V11.125ZM11.8125 8.5H11.0195L8.39453 4.53516C8.3125 4.42578 8.17578 4.34375 8.01172 4.34375C7.875 4.34375 7.73828 4.42578 7.65625 4.53516L5.93359 7.10547L5.33203 6.28516C5.25 6.14844 5.11328 6.09375 4.97656 6.09375C4.83984 6.09375 4.70312 6.14844 4.62109 6.28516L3.00781 8.5H2.1875V2.375C2.1875 2.12891 2.37891 1.9375 2.625 1.9375H11.375C11.6211 1.9375 11.8125 2.12891 11.8125 2.375V8.5Z'
          fill={_fill}
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
});
TrackTraceViewModeImage.propTypes = {
  fill: PropTypes.string,
};
export const TrackTraceItemFieldSelectedIcon = memo(
  ({ selected, onClick, small }) => {
    return (
      <div
        className={`cursor-pointer ${
          small === undefined || small === false ? 'standard-icon-wrp' : ''
        }`}
        onClick={onClick}
      >
        {selected === true && (
          <svg
            width='14'
            height='13'
            viewBox='0 0 14 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.65625 0.921875L9.20312 4.03906L12.625 4.53125C13.2344 4.625 13.4922 5.375 13.0469 5.82031L10.5625 8.21094L11.1484 11.6094C11.2422 12.2188 10.6094 12.6875 10.0469 12.4062L7 10.8125L3.92969 12.4297C3.36719 12.7109 2.73438 12.2422 2.82812 11.6328L3.41406 8.23438L0.929688 5.82031C0.484375 5.375 0.742188 4.625 1.35156 4.53125L4.77344 4.03906L6.32031 0.921875C6.60156 0.359375 7.39844 0.382812 7.65625 0.921875Z'
              fill='#027AFF'
            />
          </svg>
        )}
        {selected !== true && (
          <svg
            width='14'
            height='13'
            viewBox='0 0 14 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12.625 4.53125L9.20312 4.03906L7.65625 0.921875C7.51562 0.640625 7.25781 0.5 6.97656 0.5C6.71875 0.5 6.46094 0.640625 6.32031 0.921875L4.77344 4.03906L1.35156 4.53125C0.742188 4.625 0.484375 5.375 0.929688 5.82031L3.41406 8.21094L2.82812 11.6328C2.75781 12.125 3.13281 12.5 3.57812 12.5C3.69531 12.5 3.8125 12.4766 3.92969 12.4297L7 10.8125L10.0469 12.4297C10.1641 12.4766 10.2812 12.5234 10.3984 12.5234C10.8438 12.5234 11.2188 12.125 11.1484 11.6328L10.5625 8.23438L13.0469 5.84375C13.4922 5.375 13.2344 4.625 12.625 4.53125ZM9.39062 8.02344L9.90625 11.0703L7.16406 9.64062C7.04688 9.57031 6.92969 9.57031 6.8125 9.64062L4.07031 11.0703L4.58594 8.02344C4.60938 7.90625 4.5625 7.78906 4.49219 7.69531L2.26562 5.53906L5.33594 5.09375C5.45312 5.07031 5.57031 5 5.61719 4.88281L7 2.09375L8.35938 4.88281C8.40625 5 8.52344 5.07031 8.64062 5.09375L11.7109 5.53906L9.48438 7.69531C9.41406 7.78906 9.36719 7.90625 9.39062 8.02344Z'
              fill='#323232'
              fillOpacity='0.4'
            />
          </svg>
        )}
      </div>
    );
  },
);
TrackTraceItemFieldSelectedIcon.propTypes = {
  fill: PropTypes.string,
};
export const TrackTraceSuccessIcon = memo(({ fill, selected, onClick }) => {
  const _fill = fill ? fill : '#006EED';
  return (
    <div
      className={`${onClick ? 'cursor-pointer' : ''} standard-icon-wrp`}
      onClick={onClick}
    >
      <svg
        width='14'
        height='9'
        viewBox='0 0 14 9'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.7891 2.03906C13.0703 1.73438 13.0703 1.26562 12.7891 0.960938L12.0391 0.210938C11.875 0.0703125 11.6875 0 11.5 0C11.3125 0 11.125 0.0703125 10.9609 0.210938L5.5 5.69531L3.03906 3.21094C2.875 3.07031 2.6875 3 2.5 3C2.3125 3 2.125 3.07031 1.96094 3.21094L1.21094 3.96094C0.929688 4.26562 0.929688 4.73438 1.21094 5.03906L4.96094 8.78906C5.125 8.92969 5.3125 9 5.5 9C5.6875 9 5.875 8.92969 6.03906 8.78906L12.7891 2.03906Z'
          fill={_fill}
        />
      </svg>
    </div>
  );
});
TrackTraceSuccessIcon.propTypes = {
  fill: PropTypes.string,
  selected: PropTypes.bool,
};
export const TrackTraceRejectIcon = memo(({ fill, selected }) => {
  const _fill = fill ? fill : '#C00000';
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.7812 9.71875L7.0625 6L10.7812 2.28125C11.0625 2 11.0625 1.5 10.7812 1.21875C10.5 0.9375 10 0.9375 9.71875 1.21875L6 4.9375L2.28125 1.21875C2 0.9375 1.5 0.9375 1.21875 1.21875C0.9375 1.5 0.9375 2 1.21875 2.28125L4.9375 6L1.21875 9.71875C0.9375 10 0.9375 10.5 1.21875 10.7812C1.5 11.0625 2 11.0625 2.28125 10.7812L6 7.0625L9.71875 10.7812C10 11.0625 10.5 11.0625 10.7812 10.7812C11.0625 10.5 11.0625 10 10.7812 9.71875Z'
          fill={_fill}
        />
      </svg>
    </div>
  );
});
TrackTraceRejectIcon.propTypes = {
  fill: PropTypes.string,
  selected: PropTypes.bool,
};

export const TrackTraceDrillDoneIcon = memo(({ fill, selected }) => {
  const _fill = fill ? fill : '#00C06F';
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='13'
        height='13'
        viewBox='0 0 13 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.5 0.875C3.38281 0.875 0.875 3.38281 0.875 6.5C0.875 9.61719 3.38281 12.125 6.5 12.125C9.61719 12.125 12.125 9.61719 12.125 6.5C12.125 3.38281 9.61719 0.875 6.5 0.875ZM9.21875 5.46875L6.21875 8.46875C6.07812 8.58594 5.91406 8.65625 5.75 8.65625C5.58594 8.65625 5.42188 8.58594 5.28125 8.46875L3.78125 6.96875C3.52344 6.71094 3.52344 6.28906 3.78125 6.03125C4.03906 5.77344 4.46094 5.77344 4.71875 6.03125L5.75 7.0625L8.28125 4.53125C8.53906 4.27344 8.96094 4.27344 9.21875 4.53125C9.47656 4.78906 9.47656 5.21094 9.21875 5.46875Z'
          fill={_fill}
        />
      </svg>
    </div>
  );
});
TrackTraceDrillDoneIcon.propTypes = {
  fill: PropTypes.string,
  selected: PropTypes.bool,
};
export const PipelineModeTextIcon = () => {
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='12'
        height='13'
        viewBox='0 0 12 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0 3.875C0 4.29688 0.328125 4.625 0.75 4.625H9.44531L7.99219 6.07812C7.82812 6.21875 7.82812 6.45312 7.99219 6.61719L8.50781 7.13281C8.67188 7.29688 8.90625 7.29688 9.04688 7.13281L11.7891 4.41406C11.9297 4.25 12 4.0625 12 3.875C12 3.6875 11.9297 3.5 11.7891 3.33594L9.04688 0.617188C8.90625 0.453125 8.67188 0.453125 8.50781 0.617188L7.99219 1.13281C7.82812 1.29688 7.82812 1.53125 7.99219 1.67188L9.44531 3.125H0.75C0.328125 3.125 0 3.45312 0 3.875ZM0.210938 8.58594C0.0703125 8.75 0 8.9375 0 9.125C0 9.3125 0.0703125 9.5 0.210938 9.66406L2.95312 12.3828C3.09375 12.5469 3.32812 12.5469 3.49219 12.3828L4.00781 11.8672C4.17188 11.7031 4.17188 11.4688 4.00781 11.3281L2.55469 9.875H11.25C11.6719 9.875 12 9.54688 12 9.125C12 8.70312 11.6719 8.375 11.25 8.375H2.55469L4.00781 6.92188C4.17188 6.78125 4.17188 6.54688 4.00781 6.38281L3.49219 5.86719C3.32812 5.70312 3.09375 5.70312 2.95312 5.86719L0.210938 8.58594Z'
          fill='#006EED'
        />
      </svg>
    </div>
  );
};
PipelineModeTextIcon.propTypes = {
  fill: PropTypes.string,
};
export const PipelineModeIcon = () => {
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='17'
        height='15'
        viewBox='0 0 17 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16.5 12H6.5C6.21875 12 6 12.2188 6 12.5V13.5C6 13.7812 6.21875 14 6.5 14H16.5C16.7812 14 17 13.7812 17 13.5V12.5C17 12.2188 16.7812 12 16.5 12ZM2.5 11.5C1.6875 11.5 1 12.1875 1 13C1 13.8125 1.6875 14.5 2.5 14.5C3.3125 14.5 4 13.8125 4 13C4 12.1875 3.34375 11.5 2.5 11.5ZM4.6875 1.25L2.96875 3.15625L2.28125 2.46875C2 2.1875 1.5 2.1875 1.21875 2.46875C0.9375 2.75 0.9375 3.25 1.21875 3.53125L2.46875 4.78125C2.625 4.90625 2.8125 5 3 5H3.03125C3.21875 5 3.40625 4.90625 3.5625 4.75L5.8125 2.25C6.09375 1.9375 6.0625 1.46875 5.75 1.1875C5.4375 0.90625 4.96875 0.9375 4.6875 1.25ZM4.6875 6.25L2.96875 8.15625L2.28125 7.46875C2 7.1875 1.5 7.1875 1.21875 7.46875C0.9375 7.75 0.9375 8.25 1.21875 8.53125L2.46875 9.78125C2.625 9.90625 2.8125 10 3 10H3.03125C3.21875 10 3.40625 9.90625 3.5625 9.75L5.8125 7.25C6.09375 6.9375 6.0625 6.46875 5.75 6.1875C5.4375 5.90625 4.96875 5.9375 4.6875 6.25ZM16.5 2H8.5C8.21875 2 8 2.21875 8 2.5V3.5C8 3.78125 8.21875 4 8.5 4H16.5C16.7812 4 17 3.78125 17 3.5V2.5C17 2.21875 16.7812 2 16.5 2ZM16.5 7H8.5C8.21875 7 8 7.21875 8 7.5V8.5C8 8.78125 8.21875 9 8.5 9H16.5C16.7812 9 17 8.78125 17 8.5V7.5C17 7.21875 16.7812 7 16.5 7Z'
          fill='#081320'
        />
      </svg>
    </div>
  );
};
export const PipelineScanModeIcon = () => {
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='19'
        height='15'
        viewBox='0 0 19 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18.75 12H14.25C14.125 12 14 12.125 14 12.25V13.75C14 13.875 14.125 14 14.25 14H18.75C18.875 14 19 13.875 19 13.75V12.25C19 12.125 18.875 12 18.75 12ZM18.75 8H14.25C14.125 8 14 8.125 14 8.25V8.75C14 8.875 14.125 9 14.25 9H18.75C18.875 9 19 8.875 19 8.75V8.25C19 8.125 18.875 8 18.75 8ZM18.75 10H14.25C14.125 10 14 10.125 14 10.25V10.75C14 10.875 14.125 11 14.25 11H18.75C18.875 11 19 10.875 19 10.75V10.25C19 10.125 18.875 10 18.75 10ZM18.75 5H14.25C14.125 5 14 5.125 14 5.25V6.75C14 6.875 14.125 7 14.25 7H18.75C18.875 7 19 6.875 19 6.75V5.25C19 5.125 18.875 5 18.75 5ZM18.75 0H14.25C14.125 0 14 0.125 14 0.25V1.75C14 1.875 14.125 2 14.25 2H18.75C18.875 2 19 1.875 19 1.75V0.25C19 0.125 18.875 0 18.75 0ZM18.75 3H14.25C14.125 3 14 3.125 14 3.25V3.75C14 3.875 14.125 4 14.25 4H18.75C18.875 4 19 3.875 19 3.75V3.25C19 3.125 18.875 3 18.75 3ZM11 0H4.5C2.5625 0 1 1.5625 1 3.5C1 5.0625 2.03125 6.375 3.46875 6.8125L1.1875 10.75C0.78125 11.4688 1.03125 12.375 1.75 12.8125L3.46875 13.8125C4.1875 14.2188 5.125 13.9688 5.53125 13.25L9.125 7H11C11.5625 7 12 6.5625 12 6V1C12 0.4375 11.5625 0 11 0Z'
          fill='#081320'
        />
      </svg>
    </div>
  );
};
export const TrackTraceImageAddIcon = () => {
  return (
    <div className='cursor-pointer standard-icon-wrp'>
      <svg
        width='10'
        height='10'
        viewBox='0 0 10 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.05734 3.88H9.83734V6.02H6.05734V9.78H3.91734V6.02H0.137344V3.88H3.91734V0.0799997H6.05734V3.88Z'
          fill='#006EED'
        />
      </svg>
    </div>
  );
};
export const TrackTraceImageRemoveIcon = ({ onClick }) => {
  return (
    <div className='cursor-pointer standard-icon-wrp' onClick={onClick}>
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_d_1431_11486)'>
          <path
            d='M14 6.5C9.84375 6.5 6.5 9.84375 6.5 14C6.5 18.1562 9.84375 21.5 14 21.5C18.1562 21.5 21.5 18.1562 21.5 14C21.5 9.84375 18.1562 6.5 14 6.5ZM17 14.75H11C10.5938 14.75 10.25 14.4062 10.25 14C10.25 13.5938 10.5938 13.25 11 13.25H17C17.4062 13.25 17.75 13.5938 17.75 14C17.75 14.4062 17.4062 14.75 17 14.75Z'
            fill='white'
          />
          <path
            d='M14 5.5C9.29147 5.5 5.5 9.29147 5.5 14H7.5C7.5 10.396 10.396 7.5 14 7.5V5.5ZM5.5 14C5.5 18.7085 9.29147 22.5 14 22.5V20.5C10.396 20.5 7.5 17.604 7.5 14H5.5ZM14 22.5C18.7085 22.5 22.5 18.7085 22.5 14H20.5C20.5 17.604 17.604 20.5 14 20.5V22.5ZM22.5 14C22.5 9.29147 18.7085 5.5 14 5.5V7.5C17.604 7.5 20.5 10.396 20.5 14H22.5ZM17 13.75H11V15.75H17V13.75ZM11 13.75C11.0831 13.75 11.1443 13.7848 11.1798 13.8202C11.2152 13.8557 11.25 13.9169 11.25 14H9.25C9.25 14.9585 10.0415 15.75 11 15.75V13.75ZM11.25 14C11.25 14.0831 11.2152 14.1443 11.1798 14.1798C11.1443 14.2152 11.0831 14.25 11 14.25V12.25C10.0415 12.25 9.25 13.0415 9.25 14H11.25ZM11 14.25H17V12.25H11V14.25ZM17 14.25C16.9169 14.25 16.8557 14.2152 16.8202 14.1798C16.7848 14.1443 16.75 14.0831 16.75 14H18.75C18.75 13.0415 17.9585 12.25 17 12.25V14.25ZM16.75 14C16.75 13.9169 16.7848 13.8557 16.8202 13.8202C16.8557 13.7848 16.9169 13.75 17 13.75V15.75C17.9585 15.75 18.75 14.9585 18.75 14H16.75Z'
            fill='#006EED'
          />
        </g>
        <defs>
          <filter
            id='filter0_d_1431_11486'
            x='0.5'
            y='0.5'
            width='31'
            height='31'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dx='2' dy='2' />
            <feGaussianBlur stdDeviation='4' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_1431_11486'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_1431_11486'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export const OrderItemSplitIcon = ({ onClick }) => {
  return (
    <div className='cursor-pointer standard-icon-wrp px-0' onClick={onClick}>
      <svg
        width='35'
        height='35'
        viewBox='0 0 35 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M22.0938 11.0938C22.4688 10.9375 22.9062 11.0312 23.1875 11.3125L25.1875 13.3125C25.375 13.5 25.5 13.75 25.5 14C25.5 14.2812 25.375 14.5312 25.1875 14.7188L23.1875 16.7188C22.9062 17 22.4688 17.0938 22.0938 16.9375C21.7188 16.7812 21.5 16.4062 21.5 16V15H20.3125C20.0625 15 19.7812 15.125 19.5938 15.3125L16.9062 18L19.5938 20.7188C19.7812 20.9062 20.0625 21 20.3125 21H21.5V20C21.5 19.625 21.7188 19.25 22.0938 19.0938C22.4688 18.9375 22.9062 19.0312 23.1875 19.3125L25.1875 21.3125C25.375 21.5 25.5 21.75 25.5 22C25.5 22.2812 25.375 22.5312 25.1875 22.7188L23.1875 24.7188C22.9062 25 22.4688 25.0938 22.0938 24.9375C21.7188 24.7812 21.5 24.4062 21.5 24V23H20.3125C19.5312 23 18.75 22.6875 18.1875 22.125L15.0625 19H10.5C9.9375 19 9.5 18.5625 9.5 18C9.5 17.4688 9.9375 17 10.5 17H15.0625L18.1875 13.9062C18.75 13.3438 19.5312 13 20.3125 13H21.5V12C21.5 11.625 21.7188 11.25 22.0938 11.0938Z'
          fill='#006EED'
        />
      </svg>
    </div>
  );
};

export const KeyBoardIcon = ({ onClick, fill }) => {
  const _fill = fill ? fill : '#006eed';
  return (
    <div className='cursor-pointer standard-icon-wrp px-0' onClick={onClick}>
      <svg
        width='62'
        height='26'
        viewBox='0 0 62 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.73457 1.26851C4.73457 1.1207 4.61842 1 4.47647 1H1.29243C1.15048 1 1.03433 1.1207 1.03433 1.26851V3.09425C1.03433 3.24206 1.15048 3.36276 1.29243 3.36276H4.47647C4.61842 3.36276 4.73457 3.24206 4.73457 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M9.06273 1.26851C9.06273 1.1207 8.94659 1 8.80463 1H5.62046C5.47851 1 5.36237 1.1207 5.36237 1.26851V3.09425C5.36237 3.24206 5.47851 3.36276 5.62046 3.36276H8.80463C8.94659 3.36276 9.06273 3.24206 9.06273 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M13.3908 1.26851C13.3908 1.1207 13.2747 1 13.1327 1H9.94856C9.8066 1 9.69046 1.1207 9.69046 1.26851V3.09425C9.69046 3.24206 9.8066 3.36276 9.94856 3.36276H13.1327C13.2747 3.36276 13.3908 3.24206 13.3908 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M17.7189 1.26851C17.7189 1.1207 17.6027 1 17.4608 1H14.2767C14.1348 1 14.0186 1.1207 14.0186 1.26851V3.09425C14.0186 3.24206 14.1348 3.36276 14.2767 3.36276H17.4608C17.6027 3.36276 17.7189 3.24206 17.7189 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M22.0469 1.26851C22.0469 1.1207 21.9308 1 21.7888 1H18.6048C18.4629 1 18.3467 1.1207 18.3467 1.26851V3.09425C18.3467 3.24206 18.4629 3.36276 18.6048 3.36276H21.7888C21.9308 3.36276 22.0469 3.24206 22.0469 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M26.3751 1.26851C26.3751 1.1207 26.259 1 26.117 1H22.933C22.791 1 22.6749 1.1207 22.6749 1.26851V3.09425C22.6749 3.24206 22.791 3.36276 22.933 3.36276H26.117C26.259 3.36276 26.3751 3.24206 26.3751 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M30.7033 1.26851C30.7033 1.1207 30.5872 1 30.4452 1H27.261C27.1191 1 27.0029 1.1207 27.0029 1.26851V3.09425C27.0029 3.24206 27.1191 3.36276 27.261 3.36276H30.4452C30.5872 3.36276 30.7033 3.24206 30.7033 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M35.0313 1.26851C35.0313 1.1207 34.9151 1 34.7732 1H31.5892C31.4473 1 31.3311 1.1207 31.3311 1.26851V3.09425C31.3311 3.24206 31.4473 3.36276 31.5892 3.36276H34.7732C34.9151 3.36276 35.0313 3.24206 35.0313 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M43.6875 1.26851C43.6875 1.1207 43.5713 1 43.4294 1H40.2455C40.1035 1 39.9874 1.1207 39.9874 1.26851V3.09425C39.9874 3.24206 40.1035 3.36276 40.2455 3.36276H43.4294C43.5713 3.36276 43.6875 3.24206 43.6875 3.09425V1.26851Z'
          stroke='black'
          strokeMiterlimit='10'
        />
        <path
          d='M48.0157 1.26851C48.0157 1.1207 47.8995 1 47.7576 1H44.5737C44.4317 1 44.3156 1.1207 44.3156 1.26851V3.09425C44.3156 3.24206 44.4317 3.36276 44.5737 3.36276H47.7576C47.8995 3.36276 48.0157 3.24206 48.0157 3.09425V1.26851Z'
          stroke='black'
          strokeMiterlimit='10'
        />
        <path
          d='M52.3438 1.26851C52.3438 1.1207 52.2276 1 52.0857 1H48.9017C48.7598 1 48.6436 1.1207 48.6436 1.26851V3.09425C48.6436 3.24206 48.7598 3.36276 48.9017 3.36276H52.0857C52.2276 3.36276 52.3438 3.24206 52.3438 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M56.6719 1.26851C56.6719 1.1207 56.5558 1 56.4138 1H53.2299C53.0879 1 52.9718 1.1207 52.9718 1.26851V3.09425C52.9718 3.24206 53.0879 3.36276 53.2299 3.36276H56.4138C56.5558 3.36276 56.6719 3.24206 56.6719 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M61 3.09425V1.26851C61 1.1207 60.8839 1 60.7419 1H57.558C57.416 1 57.2999 1.1207 57.2999 1.26851V3.09425C57.2999 3.24206 57.416 3.36276 57.558 3.36276H60.7419C60.8837 3.36276 61 3.24193 61 3.09425Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M39.3593 1.26851C39.3593 1.1207 39.2432 1 39.1012 1H35.9173C35.7754 1 35.6592 1.1207 35.6592 1.26851V3.09425C35.6592 3.24206 35.7754 3.36276 35.9173 3.36276H39.1012C39.2432 3.36276 39.3593 3.24206 39.3593 3.09425V1.26851Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M1.03433 4.213V7.30087C1.03433 7.44855 1.15048 7.56938 1.29243 7.56938H4.26057C4.40252 7.56938 4.51867 7.44855 4.51867 7.30087V4.213C4.51867 4.06532 4.40252 3.94449 4.26057 3.94449H1.29243C1.15048 3.94449 1.03433 4.06532 1.03433 4.213Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M5.21722 4.213V7.30087C5.21722 7.44855 5.33337 7.56938 5.47532 7.56938H8.44346C8.58542 7.56938 8.70156 7.44855 8.70156 7.30087V4.213C8.70156 4.06532 8.58542 3.94449 8.44346 3.94449H5.47532C5.33337 3.94449 5.21722 4.06532 5.21722 4.213Z'
          stroke='black'
          strokeMiterlimit='10'
        />
        <path
          d='M9.65831 3.94449C9.51635 3.94449 9.40021 4.06532 9.40021 4.213V7.30087C9.40021 7.44855 9.51635 7.56938 9.65831 7.56938H12.6264C12.7684 7.56938 12.8845 7.44855 12.8845 7.30087V4.213C12.8845 4.06532 12.7684 3.94449 12.6264 3.94449H9.65831Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M13.8412 3.94449C13.6992 3.94449 13.5831 4.06532 13.5831 4.213V7.30087C13.5831 7.44855 13.6992 7.56938 13.8412 7.56938H16.8093C16.9513 7.56938 17.0674 7.44855 17.0674 7.30087V4.213C17.0674 4.06532 16.9513 3.94449 16.8093 3.94449H13.8412Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M18.0241 3.94449C17.8821 3.94449 17.766 4.06532 17.766 4.213V7.30087C17.766 7.44855 17.8821 7.56938 18.0241 7.56938H20.9922C21.1342 7.56938 21.2503 7.44855 21.2503 7.30087V4.213C21.2503 4.06532 21.1342 3.94449 20.9922 3.94449H18.0241Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M22.2071 3.94449C22.0651 3.94449 21.949 4.06532 21.949 4.213V7.30087C21.949 7.44855 22.0651 7.56938 22.2071 7.56938H25.1752C25.3172 7.56938 25.4333 7.44855 25.4333 7.30087V4.213C25.4333 4.06532 25.3172 3.94449 25.1752 3.94449H22.2071Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M26.39 3.94449C26.248 3.94449 26.1319 4.06532 26.1319 4.213V7.30087C26.1319 7.44855 26.248 7.56938 26.39 7.56938H29.3581C29.5001 7.56938 29.6162 7.44855 29.6162 7.30087V4.213C29.6162 4.06532 29.5001 3.94449 29.3581 3.94449H26.39Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M30.573 3.94449C30.431 3.94449 30.3149 4.06532 30.3149 4.213V7.30087C30.3149 7.44855 30.431 7.56938 30.573 7.56938H33.5411C33.6831 7.56938 33.7992 7.44855 33.7992 7.30087V4.213C33.7992 4.06532 33.6831 3.94449 33.5411 3.94449H30.573Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M34.7559 3.94449C34.6139 3.94449 34.4978 4.06532 34.4978 4.213V7.30087C34.4978 7.44855 34.6139 7.56938 34.7559 7.56938H37.724C37.866 7.56938 37.9821 7.44855 37.9821 7.30087V4.213C37.9821 4.06532 37.866 3.94449 37.724 3.94449H34.7559Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M38.939 3.94449C38.7971 3.94449 38.6809 4.06532 38.6809 4.213V7.30087C38.6809 7.44855 38.7971 7.56938 38.939 7.56938H41.9071C42.0491 7.56938 42.1652 7.44855 42.1652 7.30087V4.213C42.1652 4.06532 42.0491 3.94449 41.9071 3.94449H38.939Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M43.1219 3.94449C42.9799 3.94449 42.8638 4.06532 42.8638 4.213V7.30087C42.8638 7.44855 42.9799 7.56938 43.1219 7.56938H46.09C46.232 7.56938 46.3481 7.44855 46.3481 7.30087V4.213C46.3481 4.06532 46.232 3.94449 46.09 3.94449H43.1219Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M47.3049 3.94449C47.1629 3.94449 47.0468 4.06532 47.0468 4.213V7.30087C47.0468 7.44855 47.1629 7.56938 47.3049 7.56938H50.273C50.4149 7.56938 50.5311 7.44855 50.5311 7.30087V4.213C50.5311 4.06532 50.4149 3.94449 50.273 3.94449H47.3049Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M51.4877 3.94449C51.3458 3.94449 51.2296 4.06532 51.2296 4.213V7.30087C51.2296 7.44855 51.3458 7.56938 51.4877 7.56938H54.4559C54.5978 7.56938 54.714 7.44855 54.714 7.30087V4.213C54.714 4.06532 54.5978 3.94449 54.4559 3.94449H51.4877Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M61 7.30087V4.213C61 4.06532 60.8838 3.94449 60.7419 3.94449H55.6138C55.4719 3.94449 55.3557 4.06532 55.3557 4.213V7.30087C55.3557 7.44855 55.4719 7.56938 55.6138 7.56938H60.7419C60.8837 7.56938 61 7.44841 61 7.30087Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M7.57869 8.1511C7.43673 8.1511 7.32059 8.27193 7.32059 8.41961V11.5075C7.32059 11.6552 7.43673 11.776 7.57869 11.776H10.5468C10.6888 11.776 10.8049 11.6552 10.8049 11.5075V8.41961C10.8049 8.27193 10.6888 8.1511 10.5468 8.1511H7.57869Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M11.7659 8.1511C11.624 8.1511 11.5078 8.27193 11.5078 8.41961V11.5075C11.5078 11.6552 11.624 11.776 11.7659 11.776H14.7341C14.876 11.776 14.9922 11.6552 14.9922 11.5075V8.41961C14.9922 8.27193 14.876 8.1511 14.7341 8.1511H11.7659Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M15.9532 8.1511C15.8112 8.1511 15.6951 8.27193 15.6951 8.41961V11.5075C15.6951 11.6552 15.8112 11.776 15.9532 11.776H18.9213C19.0633 11.776 19.1794 11.6552 19.1794 11.5075V8.41961C19.1794 8.27193 19.0633 8.1511 18.9213 8.1511H15.9532Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M19.8825 11.5075C19.8825 11.6552 19.9987 11.776 20.1406 11.776H23.1088C23.2507 11.776 23.3669 11.6552 23.3669 11.5075V8.41961C23.3669 8.27193 23.2507 8.1511 23.1088 8.1511H20.1406C19.9987 8.1511 19.8825 8.27193 19.8825 8.41961V11.5075Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M27.5543 8.41961C27.5543 8.27193 27.4381 8.1511 27.2962 8.1511H24.328C24.1861 8.1511 24.0699 8.27193 24.0699 8.41961V11.5075C24.0699 11.6552 24.1861 11.776 24.328 11.776H27.2962C27.4381 11.776 27.5543 11.6552 27.5543 11.5075V8.41961Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M31.7415 8.41961C31.7415 8.27193 31.6254 8.1511 31.4834 8.1511H28.5153C28.3733 8.1511 28.2572 8.27193 28.2572 8.41961V11.5075C28.2572 11.6552 28.3733 11.776 28.5153 11.776H31.4834C31.6254 11.776 31.7415 11.6552 31.7415 11.5075V8.41961Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M35.9288 8.41961C35.9288 8.27193 35.8126 8.1511 35.6707 8.1511H32.7026C32.5606 8.1511 32.4445 8.27193 32.4445 8.41961V11.5075C32.4445 11.6552 32.5606 11.776 32.7026 11.776H35.6707C35.8126 11.776 35.9288 11.6552 35.9288 11.5075V8.41961Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M39.8581 11.776C40.0001 11.776 40.1162 11.6552 40.1162 11.5075V8.41961C40.1162 8.27193 40.0001 8.1511 39.8581 8.1511H36.89C36.748 8.1511 36.6319 8.27193 36.6319 8.41961V11.5075C36.6319 11.6552 36.748 11.776 36.89 11.776H39.8581Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M40.8191 11.5075C40.8191 11.6552 40.9352 11.776 41.0772 11.776H44.0453C44.1873 11.776 44.3034 11.6552 44.3034 11.5075V8.41961C44.3034 8.27193 44.1873 8.1511 44.0453 8.1511H41.0772C40.9352 8.1511 40.8191 8.27193 40.8191 8.41961V11.5075Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M48.2327 11.776C48.3747 11.776 48.4908 11.6552 48.4908 11.5075V8.41961C48.4908 8.27193 48.3747 8.1511 48.2327 8.1511H45.2646C45.1226 8.1511 45.0065 8.27193 45.0065 8.41961V11.5075C45.0065 11.6552 45.1226 11.776 45.2646 11.776H48.2327Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M52.42 11.776C52.562 11.776 52.6781 11.6552 52.6781 11.5075V8.41961C52.6781 8.27193 52.562 8.1511 52.42 8.1511H49.4519C49.3099 8.1511 49.1938 8.27193 49.1938 8.41961V11.5075C49.1938 11.6552 49.3099 11.776 49.4519 11.776H52.42Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M56.6074 11.776C56.7494 11.776 56.8655 11.6552 56.8655 11.5075V8.41961C56.8655 8.27193 56.7494 8.1511 56.6074 8.1511H53.6393C53.4973 8.1511 53.3812 8.27193 53.3812 8.41961V11.5075C53.3812 11.6552 53.4973 11.776 53.6393 11.776H56.6074Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M1.29243 8.1511C1.15048 8.1511 1.03433 8.27193 1.03433 8.41961V11.5075C1.03433 11.6552 1.15048 11.776 1.29243 11.776H6.42047C6.56242 11.776 6.67857 11.6552 6.67857 11.5075V8.41961C6.67857 8.27193 6.56242 8.1511 6.42047 8.1511H1.29243Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M8.38925 15.7141C8.38925 15.8618 8.5054 15.9826 8.64735 15.9826H11.6155C11.7574 15.9826 11.8736 15.8618 11.8736 15.7141V12.6262C11.8736 12.4785 11.7574 12.3577 11.6155 12.3577H8.64735C8.5054 12.3577 8.38925 12.4785 8.38925 12.6262V15.7141Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M12.5676 15.7141C12.5676 15.8618 12.6837 15.9826 12.8257 15.9826H15.7938C15.9358 15.9826 16.0519 15.8618 16.0519 15.7141V12.6262C16.0519 12.4785 15.9358 12.3577 15.7938 12.3577H12.8257C12.6837 12.3577 12.5676 12.4785 12.5676 12.6262V15.7141Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M19.9722 15.9826C20.1142 15.9826 20.2303 15.8618 20.2303 15.7141V12.6262C20.2303 12.4785 20.1142 12.3577 19.9722 12.3577H17.0041C16.8621 12.3577 16.746 12.4785 16.746 12.6262V15.7141C16.746 15.8618 16.8621 15.9826 17.0041 15.9826H19.9722Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M20.9242 15.7141C20.9242 15.8618 21.0403 15.9826 21.1823 15.9826H24.1504C24.2924 15.9826 24.4085 15.8618 24.4085 15.7141V12.6262C24.4085 12.4785 24.2924 12.3577 24.1504 12.3577H21.1823C21.0403 12.3577 20.9242 12.4785 20.9242 12.6262V15.7141Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M28.3288 15.9826C28.4708 15.9826 28.5869 15.8618 28.5869 15.7141V12.6262C28.5869 12.4785 28.4708 12.3577 28.3288 12.3577H25.3607C25.2187 12.3577 25.1026 12.4785 25.1026 12.6262V15.7141C25.1026 15.8618 25.2187 15.9826 25.3607 15.9826H28.3288Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M32.5071 15.9826C32.6491 15.9826 32.7652 15.8618 32.7652 15.7141V12.6262C32.7652 12.4785 32.6491 12.3577 32.5071 12.3577H29.5391C29.3972 12.3577 29.281 12.4785 29.281 12.6262V15.7141C29.281 15.8618 29.3972 15.9826 29.5391 15.9826H32.5071Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M36.6855 15.9826C36.8275 15.9826 36.9436 15.8618 36.9436 15.7141V12.6262C36.9436 12.4785 36.8275 12.3577 36.6855 12.3577H33.7174C33.5754 12.3577 33.4593 12.4785 33.4593 12.6262V15.7141C33.4593 15.8618 33.5754 15.9826 33.7174 15.9826H36.6855Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M40.8639 15.9826C41.0059 15.9826 41.122 15.8618 41.122 15.7141V12.6262C41.122 12.4785 41.0059 12.3577 40.8639 12.3577H37.8958C37.7538 12.3577 37.6377 12.4785 37.6377 12.6262V15.7141C37.6377 15.8618 37.7538 15.9826 37.8958 15.9826H40.8639Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M41.8159 15.7141C41.8159 15.8618 41.9321 15.9826 42.074 15.9826H45.0422C45.1841 15.9826 45.3003 15.8618 45.3003 15.7141V12.6262C45.3003 12.4785 45.1841 12.3577 45.0422 12.3577H42.074C41.9321 12.3577 41.8159 12.4785 41.8159 12.6262V15.7141Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M45.9942 15.7141C45.9942 15.8618 46.1104 15.9826 46.2523 15.9826H49.2205C49.3624 15.9826 49.4786 15.8618 49.4786 15.7141V12.6262C49.4786 12.4785 49.3624 12.3577 49.2205 12.3577H46.2523C46.1104 12.3577 45.9942 12.4785 45.9942 12.6262V15.7141Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M50.1726 15.7141C50.1726 15.8618 50.2888 15.9826 50.4307 15.9826H53.3988C53.5408 15.9826 53.6569 15.8618 53.6569 15.7141V12.6262C53.6569 12.4785 53.5408 12.3577 53.3988 12.3577H50.4307C50.2888 12.3577 50.1726 12.4785 50.1726 12.6262V15.7141Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M54.3509 15.7141C54.3509 15.8618 54.467 15.9826 54.609 15.9826H57.5771C57.7191 15.9826 57.8352 15.8618 57.8352 15.7141V12.6262C57.8352 12.4785 57.7191 12.3577 57.5771 12.3577H54.609C54.467 12.3577 54.3509 12.4785 54.3509 12.6262V15.7141Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M1.03433 12.6262V15.7141C1.03433 15.8618 1.15048 15.9826 1.29243 15.9826H7.40692C7.54888 15.9826 7.66502 15.8618 7.66502 15.7141V12.6262C7.66502 12.4785 7.54888 12.3577 7.40692 12.3577H1.29243C1.15048 12.3577 1.03433 12.4785 1.03433 12.6262Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M18.7975 24.7315C18.7975 24.8792 18.9136 25 19.0556 25H38.7525C38.8945 25 39.0106 24.8792 39.0106 24.7315V21.0395C39.0106 20.8918 38.8945 20.771 38.7525 20.771H19.0556C18.9136 20.771 18.7975 20.8918 18.7975 21.0395V24.7315Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M13.5845 24.7315C13.5845 24.8792 13.7006 25 13.8426 25H17.8673C18.0092 25 18.1254 24.8792 18.1254 24.7315V21.0395C18.1254 20.8918 18.0092 20.771 17.8673 20.771H13.8426C13.7006 20.771 13.5845 20.8918 13.5845 21.0395V24.7315Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M39.7032 24.7315C39.7032 24.8792 39.8194 25 39.9613 25H43.9859C44.1278 25 44.244 24.8792 44.244 24.7315V21.0395C44.244 20.8918 44.1278 20.771 43.9859 20.771H39.9613C39.8194 20.771 39.7032 20.8918 39.7032 21.0395V24.7315Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M44.8474 24.7315C44.8474 24.8792 44.9635 25 45.1054 25H48.1421C48.2841 25 48.4002 24.8792 48.4002 24.7315V21.0395C48.4002 20.8918 48.2841 20.771 48.1421 20.771H45.1054C44.9635 20.771 44.8474 20.8918 44.8474 21.0395V24.7315Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M1 21.0395V24.7315C1 24.8792 1.11614 25 1.2581 25H4.29476C4.43672 25 4.55286 24.8792 4.55286 24.7315V21.0395C4.55286 20.8918 4.43672 20.771 4.29476 20.771H1.2581C1.11614 20.771 1 20.8918 1 21.0395Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M5.18286 24.7315C5.18286 24.8792 5.29901 25 5.44096 25H8.47762C8.61958 25 8.73572 24.8792 8.73572 24.7315V21.0395C8.73572 20.8918 8.61958 20.771 8.47762 20.771H5.44096C5.29901 20.771 5.18286 20.8918 5.18286 21.0395V24.7315Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M9.3317 24.7315C9.3317 24.8792 9.44784 25 9.58979 25H12.6265C12.7684 25 12.8846 24.8792 12.8846 24.7315V21.0395C12.8846 20.8918 12.7684 20.771 12.6265 20.771H9.58979C9.44784 20.771 9.3317 20.8918 9.3317 21.0395V24.7315Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M9.51298 20.1892C9.65493 20.1892 9.77108 20.0684 9.77108 19.9207V16.8328C9.77108 16.6852 9.65493 16.5643 9.51298 16.5643H6.54484C6.40289 16.5643 6.28674 16.6852 6.28674 16.8328V19.9207C6.28674 20.0684 6.40289 20.1892 6.54484 20.1892H9.51298Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M13.69 20.1892C13.832 20.1892 13.9481 20.0684 13.9481 19.9207V16.8328C13.9481 16.6852 13.832 16.5643 13.69 16.5643H10.7219C10.58 16.5643 10.4638 16.6852 10.4638 16.8328V19.9207C10.4638 20.0684 10.58 20.1892 10.7219 20.1892H13.69Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M18.1252 19.9207V16.8328C18.1252 16.6852 18.0091 16.5643 17.8671 16.5643H14.899C14.757 16.5643 14.6409 16.6852 14.6409 16.8328V19.9207C14.6409 20.0684 14.757 20.1892 14.899 20.1892H17.8671C18.0091 20.1892 18.1252 20.0683 18.1252 19.9207Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M19.0762 16.5643C18.9342 16.5643 18.8181 16.6852 18.8181 16.8328V19.9207C18.8181 20.0684 18.9342 20.1892 19.0762 20.1892H22.0443C22.1863 20.1892 22.3024 20.0684 22.3024 19.9207V16.8328C22.3024 16.6852 22.1863 16.5643 22.0443 16.5643H19.0762Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M22.9951 19.9207C22.9951 20.0684 23.1112 20.1892 23.2532 20.1892H26.2213C26.3632 20.1892 26.4794 20.0684 26.4794 19.9207V16.8328C26.4794 16.6852 26.3632 16.5643 26.2213 16.5643H23.2532C23.1112 16.5643 22.9951 16.6852 22.9951 16.8328V19.9207Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M27.1721 19.9207C27.1721 20.0684 27.2883 20.1892 27.4302 20.1892H30.3984C30.5403 20.1892 30.6565 20.0684 30.6565 19.9207V16.8328C30.6565 16.6852 30.5403 16.5643 30.3984 16.5643H27.4302C27.2883 16.5643 27.1721 16.6852 27.1721 16.8328V19.9207Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M31.3492 19.9207C31.3492 20.0684 31.4653 20.1892 31.6073 20.1892H34.5754C34.7174 20.1892 34.8335 20.0684 34.8335 19.9207V16.8328C34.8335 16.6852 34.7174 16.5643 34.5754 16.5643H31.6073C31.4653 16.5643 31.3492 16.6852 31.3492 16.8328V19.9207Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M39.0106 19.9207V16.8328C39.0106 16.6852 38.8945 16.5643 38.7525 16.5643H35.7844C35.6424 16.5643 35.5263 16.6852 35.5263 16.8328V19.9207C35.5263 20.0684 35.6424 20.1892 35.7844 20.1892H38.7525C38.8945 20.1892 39.0106 20.0683 39.0106 19.9207Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M39.9613 16.5643C39.8194 16.5643 39.7032 16.6852 39.7032 16.8328V19.9207C39.7032 20.0684 39.8194 20.1892 39.9613 20.1892H42.9295C43.0714 20.1892 43.1876 20.0684 43.1876 19.9207V16.8328C43.1876 16.6852 43.0714 16.5643 42.9295 16.5643H39.9613Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M47.1067 20.1892C47.2486 20.1892 47.3648 20.0684 47.3648 19.9207V16.8328C47.3648 16.6852 47.2486 16.5643 47.1067 16.5643H44.1385C43.9966 16.5643 43.8804 16.6852 43.8804 16.8328V19.9207C43.8804 20.0684 43.9966 20.1892 44.1385 20.1892H47.1067Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M51.2837 20.1892C51.4257 20.1892 51.5418 20.0684 51.5418 19.9207V16.8328C51.5418 16.6852 51.4257 16.5643 51.2837 16.5643H48.3156C48.1736 16.5643 48.0575 16.6852 48.0575 16.8328V19.9207C48.0575 20.0684 48.1736 20.1892 48.3156 20.1892H51.2837Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M5.34226 20.1892C5.48422 20.1892 5.60036 20.0684 5.60036 19.9207V16.8328C5.60036 16.6852 5.48422 16.5643 5.34226 16.5643H1.29243C1.15048 16.5643 1.03433 16.6852 1.03433 16.8328V19.9207C1.03433 20.0684 1.15048 20.1892 1.29243 20.1892H5.34226Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M60.7419 20.1892C60.8838 20.1892 61 20.0684 61 19.9207V16.8328C61 16.6852 60.8838 16.5643 60.7419 16.5643H52.455C52.313 16.5643 52.1969 16.6852 52.1969 16.8328V19.9207C52.1969 20.0684 52.313 20.1892 52.455 20.1892H60.7419Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M53.2557 24.7315C53.2557 24.8792 53.3719 25 53.5138 25H56.5505C56.6925 25 56.8086 24.8792 56.8086 24.7315V21.0395C56.8086 20.8918 56.6925 20.771 56.5505 20.771H53.5138C53.3719 20.771 53.2557 20.8918 53.2557 21.0395V24.7315Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M49.0645 24.7315C49.0645 24.8792 49.1806 25 49.3226 25H52.3592C52.5012 25 52.6173 24.8792 52.6173 24.7315V23.154C52.6173 23.0063 52.5012 22.8855 52.3592 22.8855H49.3226C49.1806 22.8855 49.0645 23.0063 49.0645 23.154V24.7315Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M57.4471 24.7315C57.4471 24.8792 57.5633 25 57.7052 25H60.7419C60.8839 25 61 24.8792 61 24.7315V23.154C61 23.0063 60.8839 22.8855 60.7419 22.8855H57.7052C57.5633 22.8855 57.4471 23.0063 57.4471 23.154V24.7315Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
        <path
          d='M61 15.7141V12.0445C61 11.8968 61 11.6552 61 11.5075V8.41961C61 8.27193 60.8838 8.1511 60.7419 8.1511H57.7738C57.6318 8.1511 57.5157 8.27193 57.5157 8.41961V11.5075C57.5157 11.6552 57.6318 11.776 57.7738 11.776H58.1879C58.3298 11.776 58.446 11.8968 58.446 12.0445V15.7141C58.446 15.8618 58.5621 15.9826 58.7041 15.9826H60.7419C60.8837 15.9826 61 15.8616 61 15.7141Z'
          stroke={_fill}
          strokeMiterlimit='10'
        />
      </svg>
    </div>
  );
};
