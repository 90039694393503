import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { CutGuideWoCardStat } from '../cutguide/common';
import { StatusComponent } from '../../components/common/status-component';
import { DigitalPodBreadCrumb } from './common';
import {
  calculateDPodStat,
  getBoxFromResult,
  getBoxTypeResult,
  getOrderItemFromResult,
  MODES,
  preparePendingItemMap,
} from '../../helpers/digitalpod/digitalPodHelper';
import { cloneDeep } from 'lodash';
import IconPackingItems from '../../assets/icons/icon-packing-items.svg';
import IconBoxPacked from '../../assets/icons/icon-boxpacked.svg';
import moment from 'moment/moment';
import {
  hasBottomDrill,
  hasTopDrill,
} from '../../helpers/tracktrace/orderItemHelper';
import { TrackTraceCard } from '../../components/tracktrace/container';
import {
  TrackTraceRejectIcon,
  TrackTraceSuccessIcon,
} from '../../assets/icons/icon-tracktrace';
import { OrderItemDetailBase } from '../../components/tracktrace/order-item-detail';
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

const getDPodShipmentItems = (
  dPodResult,
  shipmentId,
  orderId,
  pendingItems,
) => {
  if (
    !dPodResult ||
    !dPodResult.result ||
    dPodResult.result.length === 0 ||
    !shipmentId
  ) {
    return [];
  }
  const shipments = dPodResult.shipments || {};
  const orders = dPodResult.orders;
  const result = dPodResult?.result || [];
  const filteredResult = result.filter(
    item => item.shipment_id === shipmentId && item.order_id === orderId,
  );
  const itemResult = {
    orders: { ...orders },
    shipments: { ...shipments },
    result: cloneDeep(filteredResult),
  };
  const calculatedResult = calculateDPodStat(
    itemResult,
    {
      type: { value: 'SHIPMENT' },
      value: { key: shipmentId, value: shipmentId },
    },
    pendingItems,
  );
  calculatedResult.result
    .filter(a => a.skip_operation === false)
    .sort((a, b) => {
      if (a.type !== b.type) {
        return a.type.localeCompare(b.type);
      }
      if (a.type === 'BOX') {
        return (a.box.box_index = b.box.box_index);
      } else if (a.type === 'ORDER_ITEM') {
        return a.order_item.code.localeCompare(b.order_item.code);
      }
      return 0;
    });
  return calculatedResult;
};

const mapOperationStatus = (val, _operations) => {
  const operations = _operations
    .filter(
      operationVal =>
        operationVal.operation_code !== 'TOP_DRILL' || hasTopDrill(val),
    )
    .filter(
      operationVal =>
        operationVal.operation_code !== 'BOTTOM_DRILL' || hasBottomDrill(val),
    )
    .filter(operationVal => operationVal.skip_operation !== true);
  if (operations.length === 0) {
    val.status = 'NO_OPERATION';
    return val;
  }
  val.operations = operations;
  const operationStatus = {};
  const statusMap = {
    PENDING: 0,
    SUCCESS: 0,
    REJECTED: 0,
    NO_OPERATION: 0,
    processed_on: new Date(0),
  };
  let total = 0;
  operations.map(val => {
    statusMap[`${val.status}`]++;
    total++;
    if (moment(val.processed_on).isAfter(moment(statusMap.processed_on))) {
      statusMap.processed_on = val.processed_on;
    }
    operationStatus[`${val.operation_code}`] = val;
    return val;
  });
  val.operation_status = operationStatus;
  val.processed_on = statusMap.processed_on;
  if (statusMap.REJECTED > 0) {
    val.status = 'REJECTED';
  } else if (statusMap.SUCCESS === total) {
    val.status = 'SUCCESS';
  } else {
    val.status = 'PENDING';
  }
  return val;
};

export const DPodOrderItemCard = ({
  orderItem,
  onCardClick,
  onRefresh,
  onScanWrapper,
  lastScanItem,
  currentStation,
  pendingSyncStatus,
}) => {
  const [scanRecord, setScanRecord] = useState({});
  useEffect(() => {
    const _orderItem = cloneDeep(orderItem);
    _orderItem.operation_status = {};
    _orderItem.operations = [];
    _orderItem.box_items = [];
    _orderItem.status = 'PENDING';
    _orderItem.processed_on = new Date(0);
    if (!_orderItem.operation_checklist) {
      _orderItem.operation_checklist = [];
    }
    _orderItem.operation_pending = _orderItem.operation_checklist.filter(
      c => c.mandatory === true,
    ).length;
    const updatedOrderItem = mapOperationStatus(
      _orderItem,
      _orderItem._operation_status || [],
    );
    if (pendingSyncStatus === 'OI_PENDING_S') {
      updatedOrderItem.status = 'SUCCESS';
    } else if (pendingSyncStatus === 'OI_PENDING_R') {
      updatedOrderItem.status = 'REJECTED';
    } else if (pendingSyncStatus === 'BOX_PENDING_S') {
      updatedOrderItem.status = 'SUCCESS';
    } else if (pendingSyncStatus === 'BOX_PENDING_R') {
      updatedOrderItem.status = 'REJECTED';
    }
    const scanRecord = {
      uuid: 'dummy:' + updatedOrderItem.uuid,
      order_item: updatedOrderItem,
      status: updatedOrderItem.status,
    };
    setScanRecord(scanRecord);
  }, [orderItem, setScanRecord, pendingSyncStatus]);
  const getLastScannedBackGround = useCallback(() => {
    if (
      lastScanItem.type === 'ORDER_ITEM' &&
      lastScanItem.order_item &&
      lastScanItem.order_item?.uuid === orderItem?.uuid
    ) {
      return 'selected';
    }
    return '';
  }, [orderItem, lastScanItem]);
  return (
    <Fragment>
      {currentStation && scanRecord && scanRecord.uuid && (
        <TrackTraceCard
          scanRecord={scanRecord}
          showOrderItemDetailCB={() => {
            onCardClick();
          }}
          scannedItems={[]}
          appendScannedItemsCb={() => {
            //Add code to handle updating the status
            //this needs the order item to be updated back the same record
          }}
          onScanWrapper={(scanCode, manualEntry, silent, reject) => {
            onScanWrapper(
              {
                type: 'ORDER_ITEM',
                scanCode: scanRecord.order_item.code,
                order_item: scanRecord.order_item,
              },
              reject,
            );
          }}
          syncCb={() => {}}
          showDetailExternal={true}
          externallySelectedClass={getLastScannedBackGround()}
          currentStation={currentStation}
        />
      )}
    </Fragment>
  );
};
export const DPodItemBoxCard = ({
  dPodResult,
  item,
  onCardClick,
  onRefresh,
  onScanWrapper,
  lastScanItem,
  currentStation,
}) => {
  const [itemStatus, setItemStatus] = useState('PENDING');
  const [itemName, setItemName] = useState('');
  const getSuccessBackground = useCallback(() => {
    if (item.qty === 0) return '';
    if (item.processed_qty >= item.qty) {
      return 'selected success';
    }
    return '';
  }, [item]);
  const getRejectBackground = useCallback(() => {
    if (item.qty === 0) return '';
    if (item.reject_qty > 0) {
      return 'selected error';
    }
    return '';
  }, [item]);
  const getLastScannedBackGround = useCallback(() => {
    if (
      lastScanItem.type === 'BOX' &&
      lastScanItem.box &&
      lastScanItem.box.uuid === item.box.uuid
    ) {
      return 'selected';
    }
    return '';
  }, [item, lastScanItem]);
  useEffect(() => {
    if (!item) {
      return;
    }
    let s = 'PENDING';
    if (item.processed) {
      s = 'COMPLETED';
    } else if (item.processed_qty > 0 && item.processed_qty < item.qty) {
      s = 'IN_PROGRESS';
    }
    if (item.reject_qty > 0) {
      s = 'REJECTED';
    }
    setItemStatus(s);
  }, [item, setItemStatus]);
  useEffect(() => {
    if (!item) {
      return;
    }
    if (item.type === 'BOX') {
      setItemName('Box - ' + item.box.box_index);
    } else if (item.type === 'ORDER_ITEM') {
      setItemName(item.order_item.code);
    }
  }, [item]);
  return (
    <Fragment>
      {!item && <Fragment></Fragment>}
      {item && (
        <div
          className={`position-relative track-trace-card-wrp overflow-hidden ${getLastScannedBackGround()}`}
          style={{ animationDuration: '1s', overflow: 'hidden' }}
        >
          <div
            className='track-trace-card-status-icons'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            style={{
              margin: '-12px',
              padding: '12px',
              paddingLeft: '48px',
              paddingBottom: '48px',
              marginBottom: '-48px',
              marginLeft: '-48px',
              zIndex: '1',
            }}
          >
            <div
              className={`status-icon cursor-pointer me-4 ${getSuccessBackground()}`}
              onClick={() => {
                onScanWrapper(
                  {
                    type: 'BOX',
                    scanCode: item.box.box_index,
                    box: item.box,
                  },
                  false,
                );
              }}
            >
              <TrackTraceSuccessIcon />
            </div>
            <div
              className={`status-icon ${getRejectBackground()}`}
              onClick={() => {
                onScanWrapper(
                  {
                    type: 'BOX',
                    scanCode: item.box.box_index,
                    box: item.box,
                  },
                  true,
                );
              }}
            >
              <TrackTraceRejectIcon />
            </div>
          </div>
          <div
            onClick={e => {
              e.preventDefault();
              //dispatch(setOrderItemDetail(scanRecord.order_item));
              onCardClick();
            }}
          >
            <div className={'track-trace-part-name d-flex'}>
              {item.type === 'BOX' && (
                <div className='pe-1'>
                  <img src={IconBoxPacked} alt={'box'} />
                </div>
              )}
              {item.type === 'ORDER_ITEM' && (
                <div className='pe-1'>
                  <img src={IconPackingItems} alt={'item'} />
                </div>
              )}
              {itemName}
            </div>

            <div className='d-flex'>
              <div className='rubik-font pt-3 pb-1 d-flex'>
                <CutGuideWoCardStat
                  statCount={item.processed_qty}
                  totalCount={item.qty}
                  label={'ITEMS'}
                />
              </div>
              <div className='rubik-font pt-3 pb-1 d-flex'>
                <CutGuideWoCardStat
                  statCount={item.reject_qty}
                  totalCount={item.qty}
                  label={'REJECTED ITEMS'}
                  countClassName={`${item.reject_qty > 0 ? 'error' : ''}`}
                />
              </div>
            </div>
            <div className='pb-2'>
              <div className='d-flex justify-content-end'>
                <StatusComponent
                  statusMap={{
                    IN_PROGRESS: 'YELLOW',
                    PENDING: 'BLUE_DOT',
                    COMPLETED: 'GREEN',
                    REJECTED: 'RED',
                  }}
                  statusText={itemStatus}
                  statusKey={itemStatus}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export const DigitalPodItemListContainer = ({
  dPodResult,
  pendingItems,
  modes,
  setModes,
  onScanWrapper,
  lastScanItem,
  currentStation,
}) => {
  const [items, setItems] = useState(
    getDPodShipmentItems(
      dPodResult,
      modes[2].shipment_id,
      modes[2].order_id,
      pendingItems,
    ),
  );
  const [orderItemPendingMap, setOrderItemPendingMap] = useState({});
  const [boxPendingMap, setBoxPendingMap] = useState({});
  const [orderItem, setOrderItem] = useState(null);
  const [box, setBox] = useState(null);
  useEffect(() => {
    const _items = getDPodShipmentItems(
      dPodResult,
      modes[2].shipment_id,
      modes[2].order_id,
      pendingItems,
    );
    setItems(_items);
  }, [dPodResult, setItems, pendingItems, modes]);
  useEffect(() => {
    const { orderItemSyncMap, boxSyncMap } =
      preparePendingItemMap(pendingItems);
    setOrderItemPendingMap(orderItemSyncMap);
    setBoxPendingMap(boxSyncMap);
  }, [pendingItems, setOrderItemPendingMap, setBoxPendingMap]);
  useEffect(() => {
    if (modes.length <= 3) {
      setBox(null);
      setOrderItem(null);
      return;
    }
    const lastMode = modes[`${modes.length - 1}`];
    if (lastMode.box) {
      setBox(getBoxFromResult(dPodResult, lastMode.box));
      setOrderItem(null);
    } else if (lastMode.order_item) {
      setOrderItem(getOrderItemFromResult(dPodResult, lastMode.order_item));
      setBox(null);
    } else {
      setBox(null);
      setOrderItem(null);
    }
  }, [modes, setBox, setOrderItem, dPodResult]);
  const onBreadCrumbSelect = useCallback(
    index => {
      setModes(val => {
        if (val.length > 3) {
          const newModes = [...val];
          newModes.splice(index + 1);
          return newModes;
        }
        return val;
      });
    },
    [setModes],
  );

  const leadingActions = item => (
    <LeadingActions>
      <SwipeAction
        onClick={() => {
          onScanWrapper(item, false);
        }}
        style={{ backgroundColor: '#008a50' }}
      >
        <div
          className='d-flex align-items-center '
          style={{
            backgroundColor: 'rgba(2, 122, 255, 0.1)',
            height: '100%',
            padding: '8px 0px',
            userSelect: 'none',
            width: '72px',
          }}
        >
          <div
            className='d-flex justify-content-center'
            style={{ minWidth: '64px' }}
          >
            <TrackTraceSuccessIcon />
          </div>
        </div>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = item => (
    <TrailingActions>
      <SwipeAction
        onClick={() => {
          onScanWrapper(item, true);
        }}
        style={{ backgroundColor: '#008a50' }}
      >
        <div
          className='d-flex align-items-center '
          style={{
            backgroundColor: 'rgba(192, 0, 0, 0.1)',
            height: '100%',
            padding: '8px 0px',
            userSelect: 'none',
            width: '72px',
          }}
        >
          <div
            className='d-flex justify-content-center'
            style={{ minWidth: '64px' }}
          >
            <TrackTraceRejectIcon />
          </div>
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <Fragment>
      <div>
        <DigitalPodBreadCrumb modes={modes} />
      </div>
      <div className='row py-2'>
        {modes.length === 3 && (
          <div className='basic-swipeable-list__container track-trace-swipeable-wrp'>
            <SwipeableList fullSwipe={true} threshold={0.6} type={ListType.IOS}>
              {items.result
                .filter(val => val.type === 'BOX' || val.type === 'ORDER_ITEM')
                .map((item, index) => {
                  const actionItem = {};
                  if (item.type === 'BOX') {
                    actionItem.type = 'BOX';
                    actionItem.box = item.box;
                    actionItem.scanState = item.box.code;
                  } else if (item.type === 'ORDER_ITEM') {
                    actionItem.type = 'ORDER_ITEM';
                    actionItem.order_item = item.order_item;
                    actionItem.scanState = item.order_item.code;
                    const pSyncOrderItem =
                      orderItemPendingMap[`${item.order_item?.uuid}`];
                    actionItem.pendingSyncStatus = !pSyncOrderItem
                      ? ''
                      : pSyncOrderItem.reject
                      ? 'OI_PENDING_R'
                      : 'OI_PENDING_S';
                  }
                  return (
                    <SwipeableListItem
                      key={index}
                      leadingActions={leadingActions(actionItem)}
                      trailingActions={trailingActions(actionItem)}
                      onSwipeEnd={() => {}}
                      onClick={() => {}}
                    >
                      <div
                        className='col-12 col-md-12 col-lg-6 col-xl-6'
                        style={{ minWidth: '280px' }}
                      >
                        {item.type === 'BOX' && (
                          <DPodItemBoxCard
                            item={getBoxTypeResult(dPodResult, item)}
                            lastScanItem={lastScanItem}
                            onCardClick={() => {
                              setModes(m => {
                                const newModes = [...m];
                                newModes.push({
                                  mode: MODES.SHIPMENT_ITEM_LISTING,
                                  box: item.box,
                                  item: null,
                                  label: `Box - ${item.box.box_index}`,
                                  onSelect: () =>
                                    onBreadCrumbSelect(modes.length),
                                });
                                return newModes;
                              });
                            }}
                            onScanWrapper={onScanWrapper}
                            onRefresh={() => {}}
                            currentStation={currentStation}
                          />
                        )}

                        {item.type === 'ORDER_ITEM' && (
                          <DPodOrderItemCard
                            orderItem={item.order_item}
                            lastScanItem={lastScanItem}
                            pendingSyncStatus={actionItem.pendingSyncStatus}
                            onCardClick={() => {
                              setModes(m => {
                                const newModes = [...m];
                                newModes.push({
                                  mode: MODES.SHIPMENT_ITEM_LISTING,
                                  box: null,
                                  order_item: item.order_item,
                                  label: `${item.order_item.code}`,
                                  onSelect: () =>
                                    onBreadCrumbSelect(modes.length),
                                });
                                return newModes;
                              });
                            }}
                            onScanWrapper={onScanWrapper}
                            onRefresh={() => {}}
                            currentStation={currentStation}
                          />
                        )}
                      </div>
                    </SwipeableListItem>
                  );
                })}
            </SwipeableList>
          </div>
        )}

        {modes.length > 3 && box && (
          <div className='basic-swipeable-list__container track-trace-swipeable-wrp'>
            <SwipeableList fullSwipe={true} threshold={0.5} type={ListType.IOS}>
              {box.box_items
                .filter(val => val.box_item_type === 'ORDER')
                .map(boxItem => {
                  const pSyncBox = boxPendingMap[`${box.uuid}`];
                  const pSyncOrderItem =
                    orderItemPendingMap[`${boxItem.order_box_item?.uuid}`];
                  const syncPendingStatusBox = !pSyncBox
                    ? ''
                    : pSyncBox.reject
                    ? 'BOX_PENDING_R'
                    : 'BOX_PENDING_S';
                  const syncPendingStatusOrderItem = !pSyncOrderItem
                    ? ''
                    : pSyncOrderItem.reject
                    ? 'OI_PENDING_R'
                    : 'OI_PENDING_S';

                  const pendingSyncStatus =
                    syncPendingStatusOrderItem === ''
                      ? syncPendingStatusBox
                      : syncPendingStatusOrderItem;
                  return (
                    <SwipeableListItem
                      key={boxItem.uuid}
                      leadingActions={leadingActions({
                        type: 'ORDER_ITEM',
                        order_item: boxItem.order_box_item,
                        scanCode: boxItem.order_box_item.code,
                      })}
                      trailingActions={trailingActions({
                        type: 'ORDER_ITEM',
                        order_item: boxItem.order_box_item,
                        scanCode: boxItem.order_box_item.code,
                      })}
                      onSwipeEnd={() => {}}
                      onClick={() => {}}
                    >
                      <Fragment>
                        <div
                          className='col-12 col-md-12 col-lg-6 col-xl-6'
                          style={{ minWidth: '280px' }}
                        >
                          {boxItem.box_item_type === 'ORDER' && (
                            <DPodOrderItemCard
                              orderItem={boxItem.order_box_item}
                              lastScanItem={lastScanItem}
                              pendingSyncStatus={pendingSyncStatus}
                              onCardClick={() => {
                                setModes(m => {
                                  const newModes = [...m];
                                  newModes.push({
                                    mode: MODES.SHIPMENT_ITEM_LISTING,
                                    box: null,
                                    order_item: boxItem.order_box_item,
                                    label: `${boxItem.order_box_item.code}`,
                                    showItemDetail: true,
                                    onSelect: () =>
                                      onBreadCrumbSelect(modes.length),
                                  });
                                  return newModes;
                                });
                              }}
                              onScanWrapper={onScanWrapper}
                              onRefresh={() => {}}
                              currentStation={currentStation}
                            />
                          )}
                        </div>
                      </Fragment>
                    </SwipeableListItem>
                  );
                })}
            </SwipeableList>
          </div>
        )}
        {modes.length > 3 && modes[modes.length - 1]?.showItemDetail && (
          <Fragment>
            <OrderItemDetailBase
              order_item_detail={modes.length > 3 ? orderItem : null}
              closeCb={() => {
                setModes(m => {
                  const lastIndex = m.length;
                  const newModes = [...m];
                  newModes.splice(lastIndex - 1);
                  return newModes;
                });
              }}
              inline={true}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default DigitalPodItemListContainer;
