import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import backBtn from '../../assets/icons/backBtn.svg';
import NewBoxMenu from './newbox-menu';
const WorkFlowHeader = ({
  headertext,
  ruleUuid,
  isMobile,
  isTab,
  setMenuOpen,
  delRule,
  setTitleText,
  setRuleName,
  setRuleId,
  setShowConfirm,
}) => {
  const history = useHistory();
  return (
    <Fragment>
      <div
        className='flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 '
        style={{ zIndex: 10, width: '100%' }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          type='button'
        >
          <img alt='' src={backBtn} onClick={() => history.goBack()} />
        </button>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div className='ms-2 page-header'>
            <div className='page-title d-flex align-items-center'>
              <div className='ps-2'>
                {ruleUuid ? 'Workflow Rule' : 'Create New Workflow Rule'}
              </div>
            </div>
          </div>

          <NewBoxMenu
            id={ruleUuid}
            box={false}
            isMobile={isMobile}
            isTab={isTab}
            setMenuOpen={setMenuOpen}
            delRule={delRule}
            titleText={headertext}
            setTitleText={setTitleText}
            setRuleName={setRuleName}
            setRuleId={setRuleId}
            setShowConfirm={setShowConfirm}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default WorkFlowHeader;
