import { DEFAULT } from './const';
import { createReducer } from '@reduxjs/toolkit';
import {
  addScreen,
  closeScreen,
  clearScreen,
  displayConfirmScreen,
  closeConfirmScreen,
} from './actions';
const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(addScreen, (state, action) => {
    action.payload.id = state.screens.length;
    const existingScreen = state.screens.filter(
      val =>
        val.key &&
        val.key === action.payload.key &&
        action.payload.unique === true,
    );
    if (existingScreen.length === 0) {
      state.screens.push(action.payload);
    }
  });
  builder.addCase(closeScreen, (state, action) => {
    state.screens.pop();
  });
  builder.addCase(clearScreen, (state, action) => {
    state.screens = [];
  });
  builder.addCase(displayConfirmScreen, (state, action) => {
    state.confirmScreen = {
      show: true,
      content: action.payload.content,
      boundingClass: action.payload.boundingClass,
      boundingStyle: action.payload.boundingStyle,
    };
  });
  builder.addCase(closeConfirmScreen, (state, action) => {
    state.confirmScreen = {
      show: false,
      content: null,
      boundingClass: null,
      boundingStyle: null,
    };
  });
});

export default reducer;
