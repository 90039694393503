/* eslint-disable sonarjs/cognitive-complexity */

import { actions, ResponseCodes } from '@naadi/framework';
import { TYPES } from './const';
import { isBoxItem } from '../../../helpers/packing/itemsHelper';
import moment from 'moment';
import { getItems, fetchReqUuid, getOrders } from '../../../services/order';
import {
  getOrderBoxes,
  getOrderBoxItems,
  getBoxesWithFilter,
} from '../../../services/packing';
import { addUuid, setScanId } from '../scan/actions';
import {
  setBoxes,
  setItems,
  fetchBoxes,
  fetchBoxItems,
  fetchBoxesRestore,
  fetchBoxItemsRestore,
} from '../itemslist/actions';
import { createAction } from '@reduxjs/toolkit';
import { closeBox } from '../boxAndItems/actions';
import {
  createPredefinedBoxNoBoxes,
  toastError,
} from '../../../helpers/packing/packingHelper';
import {
  syncPackingBox,
  syncPackingBoxItems,
} from '../../../helpers/packing/packingOfflineSync';
import { cloneDeep } from 'lodash';
import { archiveCurrentOrder } from '../orderarchive/actions';
import { closeCurrentBox } from '../../../helpers/packing/currentBoxHelper';

export const resetSelection = createAction('RESET_ALL');
export const setCategory = createAction(TYPES.SET_CATEGORY);
export const setSelection = createAction(TYPES.SET_SELECTION);
export const toggleNotification = createAction(TYPES.TOGGLE_NOTIFICATION);
export const setItemLeftDetail = createAction('ITEM_LEFT_DETAIL');
export const setShowCurrentBoxDetail = createAction('SHOW_CURRENT_BOX_DETAIL');
export const setItemSyncDate = createAction('SET_ITEM_SYNC_DATE');
export const setPackingOperationSyncDate = createAction(
  'SET_PACKING_OPERATION_SYNC_DATE',
);
export const setBoxSyncDate = createAction('SET_BOX_SYNC_DATE');
export const setBoxItemSyncDate = createAction('SET_BOX_ITEM_SYNC_DATE');

export const CHANGE_SELECTION = 'CHANGE_SELECTION';

const fetchMissingBoxes = async (orderId, boxes, boxItems) => {
  const boxMap = {};
  boxes.forEach(val => {
    boxMap[val.uuid] = val;
  });
  const missingBoxes = [];
  boxItems.forEach(val => {
    if (!boxMap[val.box.uuid]) {
      missingBoxes.push(val.box.uuid);
    }
  });
  if (missingBoxes.length === 0) {
    return boxes;
  }
  const filter = {
    req_payload: {
      packing_context: ['ORDER'],
      packing_context_ref: [orderId],
      uuid: missingBoxes,
    },
  };
  const result = await getBoxesWithFilter(filter);
  if (result.status !== ResponseCodes.SUCCESS) {
    console.log('Unable to Fetch the Deleted Boxes');
    return boxes;
  }
  result.payload.forEach(val => {
    if (val.deleted === true) {
      val.deleted = false;
      val.synced = false;
    }
  });
  boxes.push(...result.payload);
  return boxes;
};

export const changeSelection =
  (selection, history, resolver) => async (dispatch, getState) => {
    const { busy, scan, itemslist, user, orderArchive } = getState();
    const oldSelection = getState().selection;
    const resolve = (sync, force) => {
      if (resolver) {
        resolver({
          sync: sync,
          force: force,
        });
      }
    };
    if (busy.includes(CHANGE_SELECTION)) {
      resolve(false, false);
      return;
    }
    await syncPackingBox(dispatch);
    await syncPackingBoxItems(dispatch);
    dispatch(actions.busy.add(CHANGE_SELECTION));
    if (scan.uuidList.length < 1000) {
      await fetchReqUuid(Math.min(200, 500 - scan.uuidList.length)).then(
        async result => {
          //console.log(result);
          await dispatch(addUuid(result.payload));
        },
      );
    }
    const syncState = {
      force: false,
      sync: false,
    };
    try {
      if (
        oldSelection.selection &&
        oldSelection.selection.uuid === selection.uuid
      ) {
        return;
      }
      if (oldSelection.selection) {
        const archivePayload = {
          orderId: oldSelection.selection.uuid,
          selection: oldSelection.selection,
          orgId: oldSelection.selection.org_id,
          branchId: oldSelection.selection.branch_id,
          category: oldSelection.category,
          boxesItems: itemslist.boxesItems,
          items: itemslist.items,
          fetchBoxes: itemslist.fetchBoxes,
          fetchBoxItems: itemslist.fetchBoxItems,
          itemSyncDate: oldSelection.itemSyncDate,
          boxSyncDate: oldSelection.boxSyncDate,
          boxItemSyncDate: oldSelection.boxItemSyncDate,
          packingOperationSyncDate: oldSelection.packingOperationSyncDate,
          archiveDate: new Date().getTime(),
        };
        dispatch(archiveCurrentOrder(archivePayload));
      }
      if (orderArchive.orders && orderArchive.orders[selection.uuid]) {
        const archivePayload = orderArchive.orders[selection.uuid];
        if (user.org && user.org.uuid === archivePayload.orgId) {
          dispatch(setSelection(cloneDeep(archivePayload.selection)));
          dispatch(setScanId(null));
          dispatch(closeBox());
          const boxesItems = cloneDeep(archivePayload.boxesItems);
          const items = cloneDeep(archivePayload.items);
          const fetchBoxesRecords = cloneDeep(archivePayload.fetchBoxes);
          const fetchBoxItemsRecords = cloneDeep(archivePayload.fetchBoxItems);
          dispatch(setBoxes(boxesItems));
          dispatch(setItems(items));
          dispatch(fetchBoxesRestore(fetchBoxesRecords));
          dispatch(fetchBoxItemsRestore(fetchBoxItemsRecords));
          dispatch(setItemSyncDate(null));
          dispatch(setBoxSyncDate(archivePayload.boxSyncDate));
          dispatch(setBoxItemSyncDate(archivePayload.boxItemSyncDate));
          dispatch(
            setPackingOperationSyncDate(
              archivePayload.packingOperationSyncDate,
            ),
          );
          syncState.sync = true;
          syncState.force = true;
          //await syncOfflineOrderUpdates(dispatch, true);
          dispatch(
            createPredefinedBoxNoBoxes(
              items,
              fetchBoxesRecords,
              selection,
              oldSelection.category,
            ),
          );
          return;
        }
      }
      dispatch(closeCurrentBox());
      const orderResp = await getOrders(
        {
          deleted: [false],
          uuid: [selection.uuid],
        },
        {
          page: 0,
          size: 1,
        },
      );
      if (orderResp.status !== 200 || orderResp.payload.length === 0) {
        if (history) {
          history.push(`/app/packing`);
        }
        if (orderResp.status !== 200) {
          toastError('Unable to Fetch the Order Detail');
        } else if (orderResp.payload.length === 0) {
          toastError('Order Not Found');
        }
        //resolve(false,false);
        return;
      }
      const orderDetail = orderResp.payload[0];
      const [resItems] = await Promise.all([
        getItems(selection.uuid, ['packing'], ['packing'], 100),
      ]);
      const resBoxes = await getOrderBoxes(selection.uuid);
      const resBoxItems = await getOrderBoxItems(selection.uuid);
      let errMsg = null;
      if (resItems.status !== ResponseCodes.SUCCESS) {
        errMsg = 'Unable to Fetch the Order Items';
      } else if (resBoxes.status !== ResponseCodes.SUCCESS) {
        errMsg = 'Unable to Fetch the Order Boxes';
      } else if (resBoxItems.status !== ResponseCodes.SUCCESS) {
        errMsg = 'Unable to Fetch the Order Packed Boxes';
      }
      if (errMsg != null) {
        toastError(errMsg);
        syncState.sync = true;
        //await syncOfflineOrderUpdates(dispatch, false);
        return;
      }
      dispatch(
        setSelection({
          code: orderDetail.code,
          ref_code: orderDetail.ref_code,
          uuid: selection.uuid,
          org_id: orderDetail.org_id,
          branch_id: orderDetail.branch_id,
        }),
      );
      dispatch(setScanId(null));
      dispatch(closeBox());
      /*const itemSyncDate = resItems.payload
        .map(val => moment(val.updated_on))
        .reduce((a, b) => (a.isAfter(b) ? a : b), moment(new Date(0)))
        .toDate();*/
      const boxSyncDate = resBoxItems.payload
        .map(val => moment(val.updated_on))
        .reduce((a, b) => (a.isAfter(b) ? a : b), moment(new Date(0)))
        .toDate();
      const boxItemSyncDate = resItems.payload
        .map(val => moment(val.updated_on))
        .reduce((a, b) => (a.isAfter(b) ? a : b), moment(new Date(0)))
        .toDate();
      const boxes = await fetchMissingBoxes(
        selection.uuid,
        resBoxes.payload,
        resBoxItems.payload,
      );
      dispatch(setItemSyncDate(null));
      dispatch(setBoxSyncDate(boxSyncDate));
      dispatch(setBoxItemSyncDate(boxItemSyncDate));
      dispatch(fetchBoxes(boxes));
      dispatch(fetchBoxItems(resBoxItems.payload));

      const filterBoxes = resItems.payload.filter(isBoxItem);
      const filterItems = resItems.payload.filter(item => !isBoxItem(item));
      dispatch(setBoxes(filterBoxes));
      dispatch(setItems(filterItems));
      dispatch(setPackingOperationSyncDate(new Date(0)));
      dispatch(
        createPredefinedBoxNoBoxes(
          itemslist.items,
          boxes,
          oldSelection.category,
        ),
      );
      syncState.sync = true;
      //await syncOfflineOrderUpdates(dispatch, false);
    } catch (err) {
      console.log(err);
      toastError('Unable to Fetch the Order Detail');
    } finally {
      dispatch(actions.busy.remove(CHANGE_SELECTION));
      resolve(syncState.sync, syncState.force);
    }
  };
