import { createAction } from '@reduxjs/toolkit';
import {
  setBoxItemSyncDate,
  setBoxSyncDate,
  setItemSyncDate,
  setPackingOperationSyncDate,
  setSelection,
} from '../selection/actions';
import {
  fetchBoxes,
  fetchBoxItems,
  setItems,
  setBoxes,
} from '../itemslist/actions';

export const updateOrderArchive = createAction('UPDATE_ORDER_ARCHIVE');
export const resetOrderArchive = createAction('RESET_ORDER_ARCHIVE');

export const clearOldArchives = () => (dispatch, getState) => {};

export const archiveCurrentOrder = () => (dispatch, getState) => {
  const { itemslist } = getState();
  const oldSelection = getState().selection;
  if (
    !oldSelection ||
    !oldSelection.selection ||
    !oldSelection.selection.uuid
  ) {
    return;
  }
  const archivePayload = {
    orderId: oldSelection.selection.uuid,
    selection: oldSelection.selection,
    orgId: oldSelection.selection.org_id,
    category: oldSelection.category,
    boxesItems: itemslist.boxesItems,
    items: itemslist.items,
    fetchBoxes: itemslist.fetchBoxes,
    fetchBoxItems: itemslist.fetchBoxItems,
    itemSyncDate: oldSelection.itemSyncDate,
    boxSyncDate: oldSelection.boxSyncDate,
    boxItemSyncDate: oldSelection.boxItemSyncDate,
    archiveDate: new Date().getTime(),
  };
  dispatch(updateOrderArchive(archivePayload));
  dispatch(setSelection(null));
  dispatch(setItems([]));
  dispatch(setBoxes([]));
  dispatch(fetchBoxes([]));
  dispatch(fetchBoxItems([]));
  dispatch(setItemSyncDate(new Date(0)));
  dispatch(setBoxSyncDate(new Date(0)));
  dispatch(setBoxItemSyncDate(new Date(0)));
  dispatch(setPackingOperationSyncDate(new Date(0)));
};
