import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ScreenLoader from '../../components/common/screen-loader';
import PropTypes from 'prop-types';
import { actions } from '@naadi/framework';

import {
  getOverlayStyle,
  toastResponseError,
} from '../../helpers/common/helper';

import ScreenOverlay from '../../components/common/screen-overlay';
import { cloneDeep } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import {
  getWoOptimizationResults,
  getWorkOrders,
} from '../../services/cutsmart';
import urlParamSanitize from '../../helpers/url-param-sanitizer';
import { toastError } from '../../helpers/packing/packingHelper';
import CutGuideMaterial from './womaterial/wo-material-body';
import CutGuidePatterns from './wopatterns/wo-pattern-body';
import CutGuidePattern from './cutguide/wo-pattern-cutguide-body';
import {
  onBoardScanWrapper,
  updatePartScanToResult,
} from '../../helpers/cutguide/cutGuideHelper';
import Footer from './cutguide/footer';
import QrcodeScanModal from '../../components/tracktrace/qrcode-scan-modal';
import { updateCgMultiAccess } from '../../store/cutguide/cgstationstate';
import { CgWebSocket, getLoginSessionId } from './common';
import { getState } from '../../store';
import { getCutSmartPatternTemplate } from '../../helpers/cutsmart/cutsmart';

// eslint-disable-next-line sonarjs/cognitive-complexity
const CutGuideWo = ({ mode }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { screens } = useSelector(state => state.screenoverlay);
  const [baseStyles, setBaseStyles] = useState(getOverlayStyle());
  const { cut_guide_items_sync_time } = useSelector(state => state.cutguide);
  const { WoId, MatId, PatternId } = useParams();
  const woId = useMemo(() => urlParamSanitize(WoId), [WoId]);
  const matId = useMemo(() => urlParamSanitize(MatId), [MatId]);
  const patternId = useMemo(() => urlParamSanitize(PatternId), [PatternId]);
  const [woResult, setWoResult] = useState({});
  const [showScanner, setShowScanner] = useState(false);
  const { current_station } = useSelector(state => state.cgstationstate);
  const [wo, setWo] = useState({});
  const [displayTemplate, setDisplayTemplate] = useState({
    fields: [],
    features: [],
  });

  const { org, login_session_id } = useSelector(state => state?.user || {});
  const getBroadCastMessage = useCallback(() => {
    const userName = getState().user?.username;
    return JSON.stringify({
      namespace: 'ORG_BROAD_CAST',
      org_id: org.uuid,
      message: {
        type: 'CUT_GUIDE_LOCK',
        login_session_id: getLoginSessionId(login_session_id),
        station_instance_id: current_station.uuid,
        user_name: userName || '',
      },
    });
  }, [org, login_session_id, current_station]);

  useEffect(() => {
    const fn = async () => {
      const t = await getCutSmartPatternTemplate(dispatch);
      setDisplayTemplate(t);
    };
    fn();
  }, [dispatch, setDisplayTemplate]);

  const onWsMessageCb = useCallback(
    lastJsonMessage => {
      if (
        lastJsonMessage?.payload?.namespace === 'ORG_BROAD_CAST' &&
        lastJsonMessage?.payload?.org_id === org?.uuid &&
        lastJsonMessage?.payload?.message?.type === 'CUT_GUIDE_LOCK' &&
        lastJsonMessage?.payload?.message?.login_session_id &&
        lastJsonMessage?.payload?.message?.login_session_id !== '' &&
        lastJsonMessage?.payload?.message?.station_instance_id &&
        lastJsonMessage?.payload?.message?.station_instance_id !== '' &&
        lastJsonMessage?.payload?.message?.login_session_id !==
          getLoginSessionId(login_session_id) &&
        lastJsonMessage?.payload?.message?.login_session_id !==
          getLoginSessionId() &&
        current_station?.uuid ===
          lastJsonMessage?.payload?.message?.station_instance_id &&
        lastJsonMessage?.payload?.message?.user_name &&
        lastJsonMessage?.payload?.message?.user_name !== ''
      ) {
        const userName = lastJsonMessage?.payload?.message?.user_name;
        dispatch(updateCgMultiAccess({ active: false, user: userName }));
        //history.replace('/app/cutguide/home');
      }
    },
    [dispatch, org, login_session_id, current_station],
  );

  useEffect(() => {
    setBaseStyles(getOverlayStyle());
  }, [screens]);
  const scannedItemsRef = useRef({
    syncTime: cut_guide_items_sync_time,
  });
  useEffect(() => {
    scannedItemsRef.current.syncTime = cloneDeep(cut_guide_items_sync_time);
  }, [cut_guide_items_sync_time]);
  const populateWoDetail = useCallback(
    async woId => {
      if (fetchingDetail.current) {
        return;
      }
      try {
        setWoResult({});
        fetchingDetail.current = true;
        dispatch(actions.busy.add('FETCH_WO_DETAIL'));
        const woDetailResp = await getWorkOrders({
          page: 0,
          size: 1,
          req_payload: {
            uuid: [woId],
            deleted: [false],
            wo_status: ['RELEASED'],
          },
        });
        if (woDetailResp.status !== 200) {
          toastResponseError(
            woDetailResp,
            'Unable to fetch the Work Order Detail',
          );
          return;
        } else if (woDetailResp.payload.length === 0) {
          toastResponseError(
            woDetailResp,
            'The work order to be fetched not found',
          );
          return;
        }
        const wo = woDetailResp.payload[0];
        setWo(wo);

        const resp = await getWoOptimizationResults(woId, true);
        if (resp.status !== 200) {
          toastResponseError(
            woDetailResp,
            'Unable to fetch the optimization results',
          );
          return;
        } else if (resp.payload.length === 0) {
          toastError('Could not Find an optimization result.');
          return;
        }
        const woResult = resp.payload[0];
        setWoResult(woResult);
      } finally {
        fetchingDetail.current = false;
        dispatch(actions.busy.remove('FETCH_WO_DETAIL'));
      }
    },
    [dispatch, setWo, setWoResult],
  );
  const fetchingDetail = useRef(false);
  useEffect(() => {
    populateWoDetail(woId);
  }, [woId, populateWoDetail]);

  const onPartUpdate = useCallback(
    (parts, isRejected) => {
      setWoResult(result => {
        return updatePartScanToResult(
          result,
          matId,
          patternId,
          parts,
          isRejected,
        );
      });
    },
    [matId, patternId],
  );
  return (
    <Fragment>
      <div
        className='height-100-percent width-100-percent'
        style={{
          height: 'calc(100vh)',
          overflowX: 'hidden',
          backgroundColor: '#EFEFEF',
        }}
      >
        <div style={{ display: 'block', overflow: 'hidden' }}>
          <ScreenLoader />
        </div>
        <div
          style={baseStyles.overlayStyle}
          className='scroll-content container-fluid'
        >
          <ScreenOverlay module={'cutguide'} />
        </div>
        <div style={baseStyles.screenStyle}>
          <div
            className='container-fluid position-relative px-0 '
            style={{
              display: 'block',
              height: '100vh',
              overflow: 'hidden',
              backgroundColor: '#EFEFEF',
              paddingBottom: '60px',
            }}
          >
            {mode === 'material' && (
              <CutGuideMaterial woResult={woResult} wo={wo} />
            )}
            {mode === 'patterns' && (
              <CutGuidePatterns
                woResult={woResult}
                wo={wo}
                matId={matId}
                displayTemplate={displayTemplate}
              />
            )}
            {mode === 'cutguide' && (
              <CutGuidePattern
                current_station={current_station}
                woResult={woResult}
                wo={wo}
                matId={matId}
                patternId={patternId}
                onPartStatusUpdate={onPartUpdate}
                displayTemplate={displayTemplate}
              />
            )}
          </div>
        </div>
        {mode !== 'cutguide' && (
          <Footer
            lable={'Boards'}
            placeholder={'Scan The Board Here'}
            disabled={false}
            onSubmit={scanCode => {
              onBoardScanWrapper(scanCode, wo, woResult, history);
            }}
            onShowScanner={() => {
              setShowScanner(true);
            }}
          />
        )}
        {showScanner === true && (
          <QrcodeScanModal
            onCloseCb={() => {
              setShowScanner(false);
            }}
            onSubmit={scanCode => {
              onBoardScanWrapper(scanCode, wo, woResult, history);
            }}
          ></QrcodeScanModal>
        )}
        <ToastContainer />
        <CgWebSocket
          heartBeatMessageCb={() => {}}
          onConnectMessageCb={getBroadCastMessage}
          onMessageCb={onWsMessageCb}
        />
      </div>
    </Fragment>
  );
};

export default CutGuideWo;

CutGuideWo.propTypes = {
  mode: PropTypes.string,
};
