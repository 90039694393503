import React, { Fragment } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const ActionIcon = ({ onClick, fill = '#006EED' }) => {
  return (
    <div onClick={onClick}>
      <svg
        width='4'
        height='16'
        viewBox='0 0 4 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4 2C4 0.90625 3.09375 0 2 0C0.90625 0 0 0.90625 0 2C0 3.09375 0.90625 4 2 4C3.09375 4 4 3.09375 4 2ZM4 8C4 6.90625 3.09375 6 2 6C0.90625 6 0 6.90625 0 8C0 9.09375 0.90625 10 2 10C3.09375 10 4 9.09375 4 8ZM4 14C4 12.9062 3.09375 12 2 12C0.90625 12 0 12.9062 0 14C0 15.0938 0.90625 16 2 16C3.09375 16 4 15.0938 4 14Z'
          fill={fill}
        />
      </svg>
    </div>
  );
};

export const PageAction = ({
  actionLabel,
  actions,
  actionLinkColor,
  labelClass,
}) => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className='clickable'
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
  return (
    <Fragment>
      <Dropdown className='page-size-selector' flip={'false'} drop={'down'}>
        <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
          <div
            className='d-flex justify-content-center cursor-pointer dropdown-content'
            style={{ padding: '6px' }}
          >
            <div
              className={`me-2 primary-btn-color ${
                labelClass ? labelClass : ''
              }`}
            >
              {actionLabel}
            </div>
            <div className='me-2'>
              <ActionIcon fill={actionLinkColor} />
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {actions &&
            actions.map(action => {
              return (
                <Fragment key={action.key}>
                  <Dropdown.Item
                    className='cursor-pointer'
                    eventKey={action.key}
                    onClick={() => {
                      if (action.onClick) {
                        action.onClick();
                      }
                    }}
                  >
                    {action.label}
                  </Dropdown.Item>
                </Fragment>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default PageAction;
