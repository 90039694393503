import React, { Fragment, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, uniqWith, isEqual } from 'lodash';
import {
  deleteReportUserConfig,
  updateReportConfig,
} from '../../services/report';
import {
  setCurrentReport,
  setCurrentReportConfig,
} from '../../store/report/reportfilters';
import { toastError } from '../../helpers/packing/packingHelper';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { NumberDecrease, NumberIncrease } from '../../assets/icons/nav-icons';
import { PlusIcon } from '../../assets/icons';
import Button from 'react-bootstrap/Button';
import { closeScreen } from '../../store/app/screenoverlay/actions';
import useMobile from '../../hooks/useMobile';
import Modal from 'react-bootstrap/Modal';
import { ResponseCodes } from '@naadi/framework';

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

export const PresetSaveAsModal = ({ showModal, onCloseCb, preset }) => {
  const dispatch = useDispatch();
  const [isMobile] = useMobile();
  const [name, setName] = useState('');
  useEffect(() => {
    if (!preset) {
      setName('');
    } else {
      setName(preset.config.name + ' - Copy');
    }
  }, [preset]);
  const createNewReportPreset = useCallback(async () => {
    if (!preset || !preset.config) {
      toastError('Unable to Update');
      return;
    }
    if (!name || name.length === 0) {
      return;
    }
    const reqPayload = {
      report_id: preset.report_id,
      name: name,
      columns: preset.columns,
      freeze_columns: preset.freeze_columns,
    };
    const newPresetResp = await updateReportConfig(reqPayload);
    if (newPresetResp.status !== ResponseCodes.SUCCESS) {
      toastError('Unable to Save');
      return;
    }
    dispatch(
      setCurrentReportConfig({
        code: newPresetResp.payload.code,
        name: newPresetResp.payload.name,
        report_id: newPresetResp.payload.report.uuid,
      }),
    );
    onCloseCb();
  }, [dispatch, name, preset, onCloseCb]);
  return (
    <Fragment>
      <Modal
        centered={!isMobile}
        size={'lg'}
        show={showModal}
        onHide={onCloseCb}
        dialogClassName='track-trace-dialog'
      >
        <Modal.Header closeButton>
          <Modal.Title>Save Report Preset As</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '1rem' }}>
          <div className='input-container'>
            <label className='mb-0 formLabel'>Report Preset Name</label>
            <input
              id='reportPresetName'
              name='preset'
              type='text'
              className='input-fields'
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex w-100'>
            <div className='flex-1 text-right pe-2 ps-6'>
              <Button
                variant='outline-primary'
                size='lg '
                className='w-100'
                onClick={e => onCloseCb()}
              >
                Cancel
              </Button>
            </div>
            <div className='flex-1 text-left w-100 ps-2 pe-6'>
              <Button
                type='submit'
                className='ms-2 w-100'
                size='lg'
                onClick={createNewReportPreset}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

PresetSaveAsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseCb: PropTypes.func.isRequired,
};

const DeleteSelectedColumnIcon = () => {
  //prettier-ignore
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="6.5" fill="white" stroke="#979797"/>
      <path d="M10 4.60429L9.39571 4L7 6.39571L4.60429 4L4 4.60429L6.39571 7L4 9.39571L4.60429 10L7 7.60429L9.39571 10L10 9.39571L7.60429 7L10 4.60429Z" fill="#027AFF"/>
    </svg>
  );
};

const CustomizeReport = ({ closeCb, userReportConfig }) => {
  const dispatch = useDispatch();
  const [availableColumns, setAvailableColumns] = useState([]);
  const [columns, setColumns] = useState({});
  const [placeholderProps, setPlaceholderProps] = useState({});
  const currentReport = useSelector(
    state => state.reportfilter.current_report || {},
  );
  const [reportColumns, setReportColumns] = useState([]);
  const [selected_columns, setSelectedColumns] = useState([]);
  const { current_report_config } = useSelector(state => state.reportfilter);
  const [freezeColumns, setFreezeColumns] = useState(
    currentReport.freeze_columns || 1,
  );
  const [baseColumns, setBaseColumns] = useState([]);
  const [saveAsPreset, setSaveAsPreset] = useState(null);
  useEffect(() => {
    setReportColumns(currentReport.columns || []);
    setSelectedColumns(currentReport.selected_columns || []);
    setFreezeColumns(currentReport.freeze_columns || 1);
  }, [currentReport, setFreezeColumns, setSelectedColumns, setReportColumns]);
  useEffect(() => {
    if (userReportConfig && userReportConfig.base_columns) {
      setBaseColumns(userReportConfig.base_columns);
    } else {
      setBaseColumns([]);
    }
  }, [userReportConfig, setBaseColumns]);
  useEffect(() => {
    const _columns = {};
    reportColumns.forEach(column => {
      _columns[column.column_name] = column;
    });
    setColumns(_columns);
  }, [reportColumns]);
  useEffect(() => {
    const columns = currentReport.columns
      .filter(val => val.active === true)
      .map(val => val.alias_name)
      .filter(val => selected_columns.indexOf(val) === -1);
    setAvailableColumns(columns);
  }, [selected_columns, currentReport]);
  const updateSelectedColumn = useCallback(
    async (selectedColumns, columnsToFreeze) => {
      const _currentReport = cloneDeep(currentReport);
      _currentReport.selected_columns = uniqWith(selectedColumns, isEqual);
      _currentReport.freeze_columns = columnsToFreeze;
      dispatch(setCurrentReport(_currentReport));
    },
    [dispatch, currentReport],
  );
  const updateFreezeColumns = useCallback(
    async incr => {
      const columnsToFreeze = freezeColumns + incr;
      if (columnsToFreeze < 1) {
        return;
      }
      if (columnsToFreeze > 4) {
        return;
      }
      setFreezeColumns(columnsToFreeze);
      //await updateSelectedColumn(selected_columns, columnsToFreeze);
    },
    [setFreezeColumns, freezeColumns],
  );
  const deleteSelected = async column => {
    if (selected_columns.length <= 1) {
      toastError('At least One Colum has to be selected');
      return;
    }
    const selectedColumns = cloneDeep(selected_columns);
    const columnIndex = selectedColumns.indexOf(column);
    if (columnIndex >= 0) {
      selectedColumns.splice(columnIndex, 1);
    }
    setSelectedColumns(selectedColumns);
    /*
    await updateSelectedColumn(
      uniqWith(selectedColumns, isEqual),
      freezeColumns
    );*/
  };
  const addSelected = async column => {
    if (selected_columns.indexOf(column) >= 0) return;
    const selectedColumns = cloneDeep(selected_columns);
    selectedColumns.push(column);
    setSelectedColumns(selectedColumns);
  };
  const onDragEnd = async result => {
    setPlaceholderProps({});
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (destination.droppableId !== 'selectedcolumns') {
      return;
    }
    const selectedColumns = cloneDeep(selected_columns);
    const dropColumn =
      source.droppableId === 'availablecolumns'
        ? availableColumns[source.index]
        : selected_columns[source.index];
    if (!dropColumn) return;
    selectedColumns.splice(destination.index, 0, dropColumn);
    if (source.droppableId === 'selectedcolumns') {
      if (source.index > destination.index) {
        selectedColumns.splice(source.index + 1, 1);
      } else {
        selectedColumns.splice(source.index, 1);
      }
    }
    setSelectedColumns(selectedColumns);
    //await updateSelectedColumn(selectedColumns, freezeColumns);
  };
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgba(151,151,151,0.2)' : '',
    marginRight: isDraggingOver ? '32px' : '0px',
  });
  const [searchColumns, setSearchColumns] = useState('');
  const onDragUpdate = useCallback(update => {
    if (!update.destination) {
      return;
    }
    if (update.destination.droppableId !== 'selectedcolumns') {
      setPlaceholderProps({});
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
      ),
    });
  }, []);
  const onConfigSaveAsCB = useCallback(async () => {
    await updateSelectedColumn(selected_columns, freezeColumns);
    dispatch(closeScreen());
    if (closeCb) {
      closeCb();
    }
  }, [
    closeCb,
    dispatch,
    selected_columns,
    freezeColumns,
    updateSelectedColumn,
  ]);
  const onConfigSave = useCallback(async () => {
    if (!currentReport || !currentReport.uuid) {
      return;
    }
    const currentSelectedColumn = current_report_config || {};
    const reqPayload = {
      columns: selected_columns,
      name: currentSelectedColumn.name ? currentSelectedColumn.name : 'Default',
      code: currentSelectedColumn.code ? currentSelectedColumn.code : 'DEFAULT',
      report_id: currentReport.uuid,
      freeze_columns: freezeColumns,
    };
    const reportConfigUpdateResp = await updateReportConfig(reqPayload);
    if (reportConfigUpdateResp.status !== 200) {
      toastError(
        reportConfigUpdateResp.err && reportConfigUpdateResp.err.err
          ? reportConfigUpdateResp.err.err
          : 'Unable to Update the Report Config',
      );
      return;
    }
    await updateSelectedColumn(selected_columns, freezeColumns);
    dispatch(closeScreen());
    if (closeCb) {
      closeCb();
    }
  }, [
    dispatch,
    closeCb,
    currentReport,
    selected_columns,
    freezeColumns,
    current_report_config,
    updateSelectedColumn,
  ]);
  const onConfigDelete = useCallback(async () => {
    const currentSelectedColumn = current_report_config || {};
    if (!currentReport || !currentReport.uuid || !currentSelectedColumn.code) {
      if (closeCb) {
        closeCb();
      }
      dispatch(closeScreen());
      return;
    }
    const reportConfigDeleteResp = await deleteReportUserConfig(
      currentReport.uuid,
      currentSelectedColumn.code,
    );
    if (reportConfigDeleteResp.status !== 200) {
      toastError('Unable to Delete the Config At this Moment. Please retry..');
      return;
    }
    dispatch(setCurrentReportConfig({}));
    dispatch(closeScreen());
    if (closeCb) {
      closeCb();
    }
  }, [dispatch, closeCb, currentReport, current_report_config]);

  const renderAvailableColumns = () => {
    return (
      <StrictModeDroppable droppableId='availablecolumns'>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {availableColumns
              .filter(column => {
                const txt = columns[column + '']
                  ? columns[column + ''].column_desc
                  : '';
                return txt.toLowerCase().includes(searchColumns.toLowerCase())
                  ? true
                  : false;
              })
              .map((column, index) => {
                return (
                  <Draggable
                    key={`available-column-${column}`}
                    draggableId={column}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={`available-column-${column}`}
                      >
                        <div
                          className='naadi-report-customize-selected me-0 ps-2 '
                          key={`available-column2-${column}`}
                        >
                          <div>
                            {columns[column + '']
                              ? columns[column + ''].column_desc
                              : ''}
                          </div>
                          <div
                            onClick={() => addSelected(column)}
                            className='naadi-report-customize-add'
                          >
                            <PlusIcon fill={'#027AFF'} />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    );
  };
  const renderSelectedColumns = () => {
    return (
      <StrictModeDroppable droppableId='selectedcolumns'>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {selected_columns.map((column, index) => {
              return (
                <Draggable
                  key={`selected-column-${column}`}
                  draggableId={column}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div
                        className='naadi-report-customize-selected ps-2'
                        key={`selected-column-${column}`}
                      >
                        <div>
                          {columns[column + '']
                            ? columns[column + ''].column_desc
                            : ''}
                        </div>
                        <div
                          onClick={() => deleteSelected(column)}
                          className='naadi-report-customize-delete'
                        >
                          {baseColumns.indexOf(column) === -1 && (
                            <DeleteSelectedColumnIcon />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <div
              style={{
                position: 'absolute',
                top: (placeholderProps.clientY || 0) + 51,
                left: (placeholderProps.clientX || 0) + 15,
                height: (placeholderProps.clientHeight || 0) + 1,
                width: placeholderProps.clientWidth || 0,
                background: '#eee',
              }}
            />
          </div>
        )}
      </StrictModeDroppable>
    );
  };
  const renderFreezeColumn = () => {
    return (
      <div className='naadi-report-customize-freeze-wrp'>
        <div>Freeze Columns</div>
        <div className='freeze-step-wrp'>
          <div
            className='cursor-pointer'
            onClick={() => updateFreezeColumns(-1)}
          >
            <NumberDecrease />
          </div>
          <div className='ms-4 text-primary editable-text me-4'>
            {freezeColumns}
          </div>
          <div
            className='cursor-pointer'
            onClick={() => updateFreezeColumns(1)}
          >
            <NumberIncrease />
          </div>
        </div>
      </div>
    );
  };
  return (
    <Fragment>
      <div className='row'>
        <div className='col-12 col-md-6'>
          {/* <div className='naadi-report-customize-header'>
            Tap column headers to add to the selected pane
          </div> */}
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='naadi-report-customize-header-main'>
              AVAILABLE COLUMNS
            </div>
            <div className='naadi-report-customize-input-wrp'>
              <div>
                <input
                  placeholder='Search ..'
                  className='form-control form-control-sm w-auto m-2 rounded-pill'
                  //ref={input}
                  onChange={e => setSearchColumns(e.target.value)}
                />
              </div>
              {renderAvailableColumns()}
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='naadi-report-customize-header-main'>
              SELECTED COLUMNS
            </div>
            {renderSelectedColumns()}
            {renderFreezeColumn()}
          </div>
        </div>
      </DragDropContext>
      <div className='row'>
        <div className='d-flex justify-content-end pe-4'>
          <Button
            onClick={e => {
              dispatch(closeScreen());
            }}
            variant='outline-primary'
            type='submit'
            className='ms-2 '
            size='lg'
          >
            Cancel
          </Button>
          <Button
            onClick={onConfigDelete}
            variant='outline-primary'
            type='submit'
            className='ms-2 '
            size='lg'
          >
            Delete
          </Button>
          <Button
            onClick={e => {
              setSaveAsPreset({
                report_id: currentReport.uuid,
                columns: selected_columns,
                freeze_columns: freezeColumns,
                config: current_report_config,
              });
            }}
            variant='outline-primary'
            type='submit'
            className='ms-2 outline-primary'
            size='lg'
          >
            Save As
          </Button>
          <Button
            onClick={onConfigSave}
            variant='outline-primary'
            type='submit'
            className='ms-2 outline-primary'
            size='lg'
          >
            Save
          </Button>
        </div>
      </div>
      <div>
        <PresetSaveAsModal
          onCloseCb={e => {
            setSaveAsPreset(null);
            onConfigSaveAsCB();
          }}
          showModal={saveAsPreset !== null}
          preset={saveAsPreset}
        />
      </div>
    </Fragment>
  );
};

CustomizeReport.propTypes = {
  match: PropTypes.object,
  closeCb: PropTypes.func.isRequired,
};
export default CustomizeReport;
