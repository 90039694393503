import sortBy from '../common/sort-by';

export const calcOrder = order => {
  let total = 0;
  let completed = 0;

  let total_box = 0;
  let completed_box = 0;

  let total_item = 0;
  let completed_item = 0;

  for (const box of order.boxes || []) {
    total++;
    if (box.type === 'BOX') {
      total_box++;
    } else if (box.type === 'ITEM') {
      total_item++;
    }

    if (box.checked && (box.shipped_qty === box.qty || box.serialized)) {
      completed++;
      if (box.type === 'BOX') {
        completed_box++;
      } else if (box.type === 'ITEM') {
        completed_item++;
      }
    }
  }

  order.total = total;
  order.completed = completed;

  order.total_box = total_box;
  order.completed_box = completed_box;

  order.total_item = total_item;
  order.completed_item = completed_item;

  return [
    total,
    completed,
    total_box,
    completed_box,
    total_item,
    completed_item,
  ];
};

export const calc = (shipment, orders) => {
  orders = orders.sort(sortBy('code'));
  let total = 0;
  let completed = 0;
  let total_box = 0;
  let completed_box = 0;
  let total_item = 0;
  let completed_item = 0;
  for (const order of orders) {
    const [t, c, tb, cb, ti, ci] = calcOrder(order);
    total += t;
    completed += c;
    total_box += tb;
    completed_box += cb;
    total_item += ti;
    completed_item += ci;
  }
  shipment.total = total;
  shipment.completed = completed;
  shipment.total_box = total_box;
  shipment.completed_box = completed_box;
  shipment.total_item = total_item;
  shipment.completed_item = completed_item;
  return [shipment, orders];
};

export default calc;
