"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.remove = exports.default = exports.add = void 0;

var _const = require("./const");

var add = function add(key) {
  return {
    type: _const.TYPES.ADD,
    key: key
  };
};

exports.add = add;

var remove = function remove(key) {
  return {
    type: _const.TYPES.REMOVE,
    key: key
  };
};

exports.remove = remove;
var actions = {
  add: add,
  remove: remove
};
var _default = actions;
exports.default = _default;