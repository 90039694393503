import React from 'react';
import PropTypes from 'prop-types';
const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 10, 11);
const toMonth = new Date(currentYear, 0);

export default function YearMonthForm({ date, localeUtils, onChange }) {
  //eslint-disable-line

  const months = localeUtils.getMonths();
  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className='DayPicker-Caption'>
      <div className='text-primary pb-2'>
        {months[date.getMonth()]} {date.getFullYear()}
      </div>
      <div
        className='d-flex py-4 year-month-selec
      tor title-sm'
      >
        <div className='pe-1'>
          <select name='month' onChange={handleChange} value={date.getMonth()}>
            {months.map((month, i) => {
              return (
                <option key={month} value={i}>
                  {month}
                </option>
              );
            })}
          </select>
        </div>
        <div className='ps-1'>
          <select
            name='year'
            onChange={handleChange}
            value={date.getFullYear()}
          >
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    </form>
  );
}

YearMonthForm.propTypes = {
  date: PropTypes.any,
  localeUtils: PropTypes.any,
  onChange: PropTypes.func,
};
