import { getAttachmentSignedUrlService } from '../../services/upload';
import { toastMessage } from '../packing/packingHelper';
import { ResponseCodes } from '@naadi/framework';
import FileSaver from 'file-saver';
import moment from 'moment';

export const sortChildOrders = order => {
  if (!order || !order.child_orders) return;
  order.child_orders.sort((a, b) => {
    return a.id - b.id;
  });
  order.child_orders.forEach(sortChildOrders);
};

export const downloadOrderAttachment = async attachment_id => {
  const orderSrcAttachmentResp = await getAttachmentSignedUrlService(
    attachment_id,
  );
  if (orderSrcAttachmentResp.status !== ResponseCodes.SUCCESS) {
    toastMessage({
      type: 'error',
      message: 'Unable to Fetch the Attachment From Server',
      autoClose: 5000,
    });
    return;
  }
  const attachment = orderSrcAttachmentResp.payload;
  //console.log(attachment.signed_url);
  FileSaver.saveAs(attachment.signed_url, attachment.file_name);
  //console.log(attachmentSignedResponse);
};

export const getPeriodDesc = summary => {
  switch (summary.period_type) {
    case 'HOUR':
      return moment(summary.period).utc().format('hh a');
    case 'DAY':
    case 'WEEK':
      return moment(summary.period).format('DD/MMM/YYYY');
    case 'MONTH':
      return moment(summary.period).format('MMM/YYYY');
    case 'YEAR':
      return moment(summary.period).format('YYYY');
    default:
      return summary.period;
  }
};

const chartColors = [
  '#1E90FF',
  '#2A9D8F',
  '#87CEEB',
  '#F94144',
  '#3DDC97',
  '#3D7CF6',
  '#FF4D4D',
  '#6FA2B9',
  '#55A630',
  '#FF0000',
  '#6B8EB1',
  '#FF6B6B',
  '#43AA8B',
  '#D92E2E',
  '#3399FF',
  '#34D399',
  '#D72638',
  '#A7C7E7',
  '#E63946',
  '#5085A5',
  '#23CE6B',
  '#CCE8FF',
  '#FF4500',
  '#00B4D8',
  '#6CC070',
  '#3B8AC4',
  '#E94E77',
  '#0077B6',
  '#FF5A5F',
  '#5499C7',
  '#2ECC71',
  '#264653',
  '#52A9FF',
  '#F87272',
  '#003F88',
  '#56C596',
  '#669BBC',
  '#FF3E4D',
  '#28A745',
  '#81C784',
  '#7FDBFF',
  '#FC5A50',
  '#5DADE2',
  '#00B894',
  '#A8CFFF',
  '#356CA5',
  '#72B2C9',
  '#6CC4FF',
  '#C0E8F9',
];
export const getBarGraphSeries = bar_groups => {
  const series = [];
  if (bar_groups) {
    bar_groups.forEach((group, index) => {
      if (group === 'actual') {
        series.push({
          type: 'bars',
          color: chartColors[`${index}`],
        });
      } else if (group === 'planned') {
        series.push({
          type: 'area',
          color: chartColors[`${index}`],
          lineWidth: 0.5,
          opacity: 0.8,
        });
      }
    });
  }
  return series;
};

export const getPeriod = (fromDate, toDate) => {
  let period = 'HOUR';
  const diff = moment(toDate).diff(moment(fromDate), 'days');
  if (diff > 365) {
    period = 'YEAR';
  } else if (diff > 31) {
    period = 'MONTH';
  } else if (diff > 1) {
    period = 'DAY';
  } else if (diff >= 1) {
    period = 'HOUR';
  }
  return period;
};

export const prepareProductionSummaryChartData = (summary, planned) => {
  summary = summary || [];
  planned = planned || [];
  const periodList = [];
  const periodMap = {};
  const podList = [];
  let periodType = 'DAY';
  const DEFAULT_POD = '-';
  const total = {
    summary: {},
    planned: {},
    summary_total: 0,
    planned_total: 0,
  };
  const barGroups = [];
  summary.forEach(val => {
    periodType = val.period_type;

    if (periodList.indexOf(val.period) === -1) {
      periodList.push(val.period);
    }
    const podName =
      val.pod_name && val.pod_name.length > 0 ? val.pod_name : DEFAULT_POD;
    if (!total.summary[`${podName}`]) {
      total.summary[`${podName}`] = 0;
    }
    total.summary_total += val.qty;
    total.summary[`${podName}`] += val.qty;
    if (podList.indexOf(podName) === -1) {
      podList.push(podName);
    }
    if (!periodMap[`${val.period}`]) {
      periodMap[`${val.period}`] = {
        summary: [],
        planned: [],
      };
    }
    periodMap[`${val.period}`].summary.push(val);
  });

  planned.forEach(val => {
    if (periodList.indexOf(val.period) === -1) {
      periodList.push(val.period);
    }
    const podName =
      val.pod_name && val.pod_name.length > 0 ? val.pod_name : DEFAULT_POD;

    if (!total.planned[`${podName}`]) {
      total.planned[`${podName}`] = 0;
    }
    total.planned_total += val.capacity;
    total.planned[`${podName}`] += val.capacity;
    if (podList.indexOf(podName) === -1) {
      podList.push(podName);
    }

    if (!periodMap[`${val.period}`]) {
      periodMap[`${val.period}`] = {
        summary: [],
        planned: [],
      };
    }
    periodMap[`${val.period}`].planned.push(val);
  });
  periodList.sort((a, b) => {
    return moment(a).diff(moment(b));
  });
  const actualHeader = [...podList];
  const plannedHeader = [...podList];
  const podIndex = {};
  podList.forEach((val, index) => {
    podIndex[`${val}`] = index + 1;
  });
  actualHeader.forEach(() => {
    barGroups.push('actual');
  });
  plannedHeader.forEach(() => {
    barGroups.push('planned');
  });
  const periodVals = [
    [
      'Period',
      ...actualHeader.map(val => {
        const podTotal = total.summary[`${val}`] || 0;
        return val + '(A) - ' + podTotal;
      }),
      ...plannedHeader.map(val => {
        const podTotal = total.planned[`${val}`] || 0;
        return val + '(P) - ' + podTotal;
      }),
    ],
  ];
  periodList.forEach(period => {
    const periodVal = [];
    periodVal.length = actualHeader.length + plannedHeader.length + 1;
    periodVal.fill(null);
    periodVal[0] = getPeriodDesc({ period, period_type: periodType });
    const actualPodVal = [...periodVal];
    const plannedPodVal = [...periodVal];
    actualHeader.forEach((val, index) => {
      actualPodVal[index + 1] = 0;
      plannedPodVal[index + 1 + actualHeader.length] = 0;
    });
    const periodData = periodMap[`${period}`];
    periodData.summary.forEach(val => {
      const podName =
        val.pod_name && val.pod_name.length > 0 ? val.pod_name : DEFAULT_POD;
      const podIdx = podIndex[`${podName}`];
      if (podIdx) {
        actualPodVal[`${podIdx}`] += val.qty;
      }
    });
    periodData.planned.forEach(val => {
      const podName =
        val.pod_name && val.pod_name.length > 0 ? val.pod_name : DEFAULT_POD;
      const podIdx = podIndex[`${podName}`];
      if (podIdx) {
        plannedPodVal[podIdx + actualHeader.length] += val.capacity;
      }
    });
    plannedPodVal.forEach((val, index) => {
      if (val !== null) {
        actualPodVal[`${index}`] = val;
      }
    });
    periodVals.push(actualPodVal);
  });
  return {
    graph_summary: periodVals,
    bar_groups: barGroups,
    planned_total: total.planned_total,
    summary_total: total.summary_total,
  };
};
