import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import { DigitalPodBreadCrumb } from './common';
import { removeDPodPendingSync } from '../../store/digitalpod/dpodstationstate';
import { useDispatch } from 'react-redux';

export const DPodPendingItemCard = ({ pendingItem, onRetry }) => {
  const dispatch = useDispatch();
  const [itemName, setItemName] = useState(pendingItem.scan_code);
  useEffect(() => {
    if (pendingItem.scanItem) {
      if (pendingItem.scanItem.type === 'BOX') {
        setItemName('BOX - ' + pendingItem.scanItem.box.box_index);
      } else if (pendingItem.scanItem.type === 'ORDER_ITEM') {
        setItemName(pendingItem.scanItem.order_item.code);
      }
    } else {
      setItemName('');
    }
  }, [setItemName, pendingItem]);
  return (
    <Fragment>
      <div
        className={`position-relative track-trace-card-wrp overflow-hidden`}
        style={{ animationDuration: '1s', overflow: 'hidden' }}
      >
        <div className={'track-trace-part-name'}>{itemName}</div>
        <div className={'d-flex '}>
          <div className={'track-trace-scanned-on'}>
            Scanned On :{' '}
            {pendingItem.scan_date
              ? moment(pendingItem.scan_date).format('DD MMM hh:mm a')
              : '-'}
          </div>
        </div>
        <div className='d-flex error'>{pendingItem.err}</div>
        <div className='d-flex justify-content-end'>
          <div className='pe-4'>
            <div
              className='btn btn-link '
              onClick={() => {
                dispatch(removeDPodPendingSync(pendingItem));
              }}
            >
              Delete
            </div>
          </div>

          <div className='btn btn-link ' onClick={onRetry}>
            Retry
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export const DPodPendingItemsCard = ({
  pendingItems,
  onCardClick,
  ctx,
  onRefresh,
}) => {
  return (
    <Fragment>
      <div
        className={`position-relative track-trace-card-wrp overflow-hidden`}
        style={{ animationDuration: '1s', overflow: 'hidden' }}
        onClick={onCardClick}
      >
        <div className={'track-trace-part-name'}>Sync Failures</div>
        <div className={'d-flex '}>
          <div className={'track-trace-scanned-on'}></div>
        </div>
        <div className='rubik-font pt-3 pb-1 d-flex'>
          <div className={'rubik-font pe-2'}>
            <div className='d-flex align-items-start pe-2'>
              <div style={{ fontSize: '24px', lineHeight: '16px' }}>
                {pendingItems.length}
              </div>
            </div>
            <div style={{ fontSize: '12px' }}>Errors</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export const DPodPendingListContainer = ({
  dPodResult,
  ctx,
  pendingItems,
  modes,
  onRetry,
}) => {
  const loaded = useRef(false);
  const processAll = useCallback(() => {
    if (!onRetry || !pendingItems) {
      return;
    }
    pendingItems.forEach(val => {
      onRetry(val);
    });
  }, [onRetry, pendingItems]);
  useEffect(() => {
    if (loaded.current) {
      return;
    }
    loaded.current = true;
    processAll();
  }, [processAll]);
  return (
    <Fragment>
      <div className='position-relative'>
        <DigitalPodBreadCrumb modes={modes} />
        <div style={{ position: 'absolute', right: '32px', top: '0px' }}>
          <div className='btn btn-link' onClick={processAll}>
            Retry All
          </div>
        </div>
      </div>
      <div className='row py-2'>
        {pendingItems.map(pendingSync => {
          return (
            <div
              className='col-12 col-md-12 col-lg-6 col-xl-6'
              key={pendingSync.scan_ref}
              style={{ minWidth: '280px' }}
            >
              <DPodPendingItemCard
                pendingItem={pendingSync}
                onRetry={() => {
                  onRetry(pendingSync);
                }}
              />
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default DPodPendingListContainer;
