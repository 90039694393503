/* eslint-disable  sonarjs/cognitive-complexity*/
export const JSONToCSVConvertor = (
  JSONData,
  ReportTitle,
  ShowLabel,
  headerColumns,
) => {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  const arrData =
    typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

  let CSV = '';
  const headerRow = arrData[0];
  const headerkeys = [];
  //This condition will generate the Label/Header
  if (ShowLabel && !headerColumns) {
    let row = '';

    //This loop will extract the label from 1st index of on array
    for (const index in headerRow) {
      //Now convert each value to string and comma-seprated
      if (index === undefined || index === '') continue;
      row += index + ',';
      headerkeys.push(index + '');
    }

    row = row.slice(0, row.length - 1);
    //append Label row with line break
    CSV += row + '\r\n';
  } else if (headerColumns) {
    for (const index in headerRow) {
      if (index === '0' || index === 0) continue;
      headerkeys.push(index + '');
    }
    CSV += headerColumns.filter((val, index) => index > 0).join(',') + '\r\n';
  }

  //1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = '';

    //2nd loop will extract each column and convert it in string comma-seprated
    for (const index in arrData[`${i}`]) {
      if (headerkeys.indexOf(index + '') === -1) continue;
      row += '"' + arrData[`${i}`][`${index}`] + '",';
    }

    row = row.slice(0, row.length - 1);
    //add a line break after each row
    CSV += row + '\r\n';
  }

  if (CSV === '') {
    alert('Invalid data');
    return;
  }

  //Generate a file name
  let fileName = 'MyReport_';
  //this will remove the blank-spaces from the title and replace it with an underscore
  // eslint-disable-next-line
  fileName += ReportTitle.replace(/ /g, '_');

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension
  return 'data:text/csv;charset=utf-8,' + escape(CSV);
  // //this trick will generate a temp <a /> tag
  // var link = document.createElement('a');
  // link.href = uri;

  // //set the visibility hidden so it will not effect on your web-layout
  // link.style = 'visibility:hidden';
  // link.download = fileName + '.csv';
};

/* eslint-enable  sonarjs/cognitive-complexity*/
