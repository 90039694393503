import React, { useEffect, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { prefixImageUrl } from '../../helpers/org/orgHelper';
import { isEqual } from 'lodash';

export const OrgLogo = () => {
  const user = useSelector(state => state.user);
  const [org, setOrg] = useState(user && user.org ? user.org : {});
  const imagePrefix = prefixImageUrl(72);
  useEffect(() => {
    const _org = user && user.org ? user.org : {};
    if (!isEqual(org, _org)) {
      setOrg(user && user.org ? user.org : {});
    }
  }, [user, org, setOrg]);
  return (
    <Fragment>
      {org && org.image_url ? (
        <div style={{ maxWidth: '148px', height: '48px', textAlign: 'right' }}>
          <img
            src={imagePrefix + org.image_url}
            alt='org'
            className='orgImages'
            width='100%'
            height='100%'
            style={{ objectFit: 'cover' }}
          />
        </div>
      ) : (
        <div className='noProfilePic'>
          {org && org.name ? org.name.substr(0, 1).toUpperCase() : 'N'}
        </div>
      )}
    </Fragment>
  );
};
