"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.set = exports.reset = exports.default = void 0;

var _const = require("./const");

var set = function set(user) {
  return {
    user: user,
    type: _const.TYPES.SET
  };
};

exports.set = set;

var reset = function reset() {
  return {
    user: {},
    type: _const.TYPES.RESET
  };
};

exports.reset = reset;
var actions = {
  set: set,
  reset: reset
};
var _default = actions;
exports.default = _default;