export const TYPES = {
  SET_ITEMS: 'SET_ITEMS',
  SET_BOXES: 'SET_BOXES',
  FETCH_BOXES: 'FETCH_BOXES',
  FETCH_BOX_ITEMS: 'FETCH_BOX_ITEMS',
  ADD_BOX_ITEM: 'FETCH_BOX_ITEM',
  ADD_BOX: 'ADD_BOX',
};

export const DEFAULT = {
  boxesItems: [],
  items: [],
  fetchBoxes: [],
  fetchBoxItems: [],
};
