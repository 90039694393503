import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import Header from '../wolist/header';
import WoMatSubHeader from './wo-material-sub-header';
import WoMaterialFilter from './wo-material-filter';
import WoMaterialListContainer from './wo-material-list';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
const WORK_ORDER = 'Work Order';
const WO = 'WO';
const CutGuideMaterial = ({ wo, woResult }) => {
  const history = useHistory();
  const location = useLocation();
  const [term, setTerm] = useState('');
  const [ctx, setCtx] = useState({
    type: {
      label: WORK_ORDER,
      value: WO,
    },
    value: null,
  });
  const { current_station } = useSelector(state => state.cgstationstate);
  useEffect(() => {
    if (!wo.id) {
      setCtx({
        type: {
          label: WORK_ORDER,
          value: WO,
        },
        value: null,
      });
    } else {
      setCtx({
        type: {
          label: WORK_ORDER,
          value: WO,
        },
        value: {
          label: wo.wo_name,
          value: wo.uuid,
        },
      });
    }
  }, [wo]);
  return (
    <Fragment>
      <Header
        location={location}
        history={history}
        onBack={() => {
          history.push('/app/cutguide/home');
        }}
        label={'Material'}
      />
      <WoMatSubHeader
        currentStation={current_station}
        onCtxTypeSelect={() => {
          setCtx({
            type: {
              label: WORK_ORDER,
              value: WO,
            },
            value: null,
          });
        }}
        onCtxValueSelect={wo => {
          if (wo && wo.org_id && wo.key) {
            history.push('/app/cutguide/materials/' + wo.org_id + '/' + wo.key);
          }
        }}
        ctx={ctx}
        woResult={woResult}
      />
      <div
        className='px-4'
        style={{
          minHeight: '300px',
          maxHeight: 'calc(100vh - 250px)',
          overflow: 'scroll',
        }}
      >
        <WoMaterialFilter onFilterUpdate={setTerm} />
        <WoMaterialListContainer
          woMaterials={woResult.wo_optimization_materials || []}
          term={term}
          onCardClick={woMat => {
            history.push(
              '/app/cutguide/materials/' +
                wo.org_id +
                '/' +
                wo.uuid +
                '/pattern/' +
                woMat.uuid,
            );
          }}
        />
      </div>
    </Fragment>
  );
};

export default CutGuideMaterial;

CutGuideMaterial.propTypes = {
  mode: PropTypes.string,
};
