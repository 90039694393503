import React, { Fragment } from 'react';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import { DropdownSubmenu, NavDropdownMenu } from 'react-bootstrap-submenu';
import { FaAngleDown } from 'react-icons/fa';
import '../../../scss/childordmgmt/_navdropdown.scss';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className='clickable'
    style={{ color: '#006EED', padding: '5px', lineHeight: 'normal' }}
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));
export const ActionWithSubMenu = ({ menuList, title }) => {
  return (
    <Fragment>
      <NavDropdownMenu
        title={
          <Dropdown.Toggle as={CustomToggle}>
            {title}
            <FaAngleDown style={{ paddingLeft: '5px' }} />
          </Dropdown.Toggle>
        }
      >
        {menuList.map(menu => {
          return (
            <DropdownSubmenu
              href={menu.href ? menu.href : '#'}
              title={menu.title}
              key={menu.title}
            >
              {menu.children &&
                menu.children.map(childMenu => {
                  return (
                    <NavDropdown.Item
                      href={childMenu.href ? childMenu.href : '#'}
                      onClick={childMenu.onClick}
                      key={childMenu.title}
                    >
                      {childMenu.title}
                    </NavDropdown.Item>
                  );
                })}
            </DropdownSubmenu>
          );
        })}
      </NavDropdownMenu>
    </Fragment>
  );
};

export default ActionWithSubMenu;
