/* eslint-disable sonarjs/cognitive-complexity */
import React, { Fragment, memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GroupItemModal, { getGroupCompletion } from './group/group-items-modal';
import { setRejectMode } from '../../store/tracktrace/stationstate';
import RejectReasonModal from './reject-reason';
import {
  getRejectionReasons,
  hasCncOperation,
  mergeRejectReasons,
  trackTraceMultiScan,
} from '../../helpers/tracktrace/orderItemHelper';
import { toastError, toastSuccess } from '../../helpers/packing/packingHelper';
import { uniqBy } from 'lodash';
import { actions } from '@naadi/framework';

const TrackTraceGroupCard = memo(({ group, showGroupCb, scannedItems }) => {
  const { selected_record } = useSelector(state => state.tracktrace);
  const [completion, setCompletion] = useState(0);
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setCompletion(getGroupCompletion(group));
  }, [group, setCompletion]);
  useEffect(() => {
    if (!group || !group.items) {
      setSelected(false);
      return;
    }
    const selectedRecord = scannedItems.find(
      val => val.uuid === selected_record,
    );
    if (
      selectedRecord &&
      selectedRecord.order_item &&
      group.items[`${selectedRecord.order_item.uuid}`]
    ) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [group, selected_record, setSelected, scannedItems]);

  return (
    <Fragment>
      <div
        className={`track-trace-card-wrp track-trace-group-card-wrp cursor-pointer ${
          selected ? 'selected' : ''
        }`}
        onClick={e => (showGroupCb ? showGroupCb(group) : e.preventDefault())}
      >
        <div>
          <div
            className='font-weight-bold'
            style={{
              minHeight: '20px',
              maxWidth: '85%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {group.group}
          </div>
        </div>
        <div className='track-trace-group-card-status-count'>
          {group.success}/{group.success + group.pending + group.rejected}
        </div>
        <div className='track-trace-group-card-status-completion'>
          <div className='percentage' style={{ width: completion + '%' }}></div>
        </div>
      </div>
    </Fragment>
  );
});
TrackTraceGroupCard.propTypes = {
  group: PropTypes.object.isRequired,
};
export const TrackTraceGroupCards = ({
  scanned_items,
  scannedItems,
  showOrderItemDetailCB,
  appendScannedItemsCb,
  onScanWrapper,
  syncCb,
}) => {
  const dispatch = useDispatch();
  const { image_view_mode, group_by } = useSelector(state => state.tracktrace);
  const [scannedOrderItems, setScannedOrderItems] = useState([]);
  const { current_station } = useSelector(state => state.stationstate);
  const [modalGroup, setModalGroup] = useState(null);
  const [orderItemStatus, setOrderItemStatus] = useState({});
  const [showRejectModal, setShowRejectModal] = useState({
    show: false,
    reasons: null,
    rejectReasons: [],
  });
  const [isMultiCheck, setIsMultiCheck] = useState(false);
  const getCardWrpClass = useCallback(image_view_mode => {
    switch (image_view_mode) {
      case 'SMALL':
        return 'col-md-12 col-lg-6 col-xl-4';
      case 'LARGE':
        return 'col-md-12 col-lg-6';
      default:
        return 'col-12 col-md-4 col-lg-4';
    }
  }, []);
  const [cardWrpClass, setCardWrpClass] = useState(
    getCardWrpClass(image_view_mode),
  );
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    setCardWrpClass(getCardWrpClass(image_view_mode));
  }, [image_view_mode, getCardWrpClass]);
  useEffect(() => {
    const statusMap = {};
    scanned_items.forEach(val => {
      statusMap[`${val.order_item.uuid}`] = val.status;
    });
    setOrderItemStatus(statusMap);
    const _orderItemsList = scanned_items
      .map(val => val.order_item)
      .filter(val => val)
      .filter((a, i, self) => self.findIndex(s => s.uuid === a.uuid) === i);
    setScannedOrderItems(_orderItemsList);
  }, [scanned_items, setScannedOrderItems, setOrderItemStatus]);
  useEffect(() => {
    if (!group_by || group_by === '') return;
    const groupMap = {};
    scannedOrderItems
      .filter(val => hasCncOperation(val, current_station))
      .map(val => {
        const key = {
          key: 'DEFAULT',
          label: 'Default',
          additionalKeys: [],
        };
        switch (group_by.key) {
          case '__ITEM_CODE':
            key.key = val.code;
            key.lable = val.code;
            break;
          case '__DEFINITION_CODE':
            key.key = val.item_definition.code;
            key.lable = key.key;
            break;
          case '__DEFINITION_NAME':
            key.key = val.item_definition.name;
            key.lable = key.key;
            break;
          case '__LOCATION':
            key.key =
              val.order_item_status && val.order_item_status.location
                ? val.order_item_status.location
                : 'DEFAULT';
            key.lable = key.key;
            break;
          case '__BOX':
            const boxItems =
              val.box_items && val.box_items.length > 0
                ? [...val.box_items]
                    .sort((a, b) => a.box.box_index - b.box.box_index)
                    .map(boxItem => {
                      return {
                        box_id: boxItem.box.uuid,
                        code: boxItem.box.code,
                        box_index: boxItem.box.box_index,
                      };
                    })
                : [];

            const boxes = uniqBy(boxItems, 'box_id');
            key.key =
              boxes.length > 0
                ? val.order.ref_code + '-' + boxes[0].box_index
                : 'DEFAULT';
            key.lable = key.key;
            key.boxCodes = boxes.map(box => box.code);
            key.additionalKeys = boxes.map(
              box => val.order.ref_code + '-' + box.box_index,
            );
            break;
          default:
            if (val.item_definition.definition[`${group_by.key}`]) {
              key.key =
                val.item_definition.definition[`${group_by.key}`].val + '';
              key.label =
                val.item_definition.definition[`${group_by.key}`].name + '';
            }
        }
        if (!groupMap[`${key.key}`]) {
          groupMap[`${key.key}`] = {
            success: 0,
            rejected: 0,
            pending: 0,
            no_operation: 0,
            group: key.key,
            items: {},
            group_by: group_by.key,
            boxCodes: key.boxCodes,
          };
        }
        if (key.additionalKeys && key.additionalKeys.length > 0) {
          key.additionalKeys.forEach(additionalKey => {
            if (!groupMap[`${additionalKey}`]) {
              groupMap[`${additionalKey}`] = {
                success: 0,
                rejected: 0,
                pending: 0,
                no_operation: 0,
                group: additionalKey,
                items: {},
                group_by: group_by.key,
                boxCodes: [],
              };
            }
          });
        }
        const status = orderItemStatus[`${val.uuid}`]
          ? orderItemStatus[`${val.uuid}`]
          : 'PENDING';
        switch (status) {
          case 'SUCCESS':
            groupMap[`${key.key}`].success++;
            break;
          case 'REJECTED':
            groupMap[`${key.key}`].rejected++;
            break;
          case 'NO_OPERATION':
            groupMap[`${key.key}`].no_operation++;
            break;
          case 'PENDING':
          default:
            groupMap[`${key.key}`].pending++;
        }
        if (key.additionalKeys && key.additionalKeys.length > 0) {
          key.additionalKeys.forEach(additionalKey => {
            groupMap[`${additionalKey}`].items[`${val.uuid}`] = {
              uuid: val.uuid,
              updated_on: val.updated_on,
              scanned_on: val.scanned_on,
              status: status,
              code: val.code,
              scan_alternates: val.scan_alternates,
              group_by: group_by.key,
              boxCodes: [],
            };
          });
        } else {
          groupMap[`${key.key}`].items[`${val.uuid}`] = {
            uuid: val.uuid,
            updated_on: val.updated_on,
            scanned_on: val.scanned_on,
            status: status,
            code: val.code,
            scan_alternates: val.scan_alternates,
          };
        }
        return val;
      });
    const groupList = Object.keys(groupMap).map(key => {
      return groupMap[`${key}`];
    });
    groupList.sort((a, b) => {
      return a.group.localeCompare(b.group);
    });
    setGroups(groupList);
  }, [
    current_station,
    scannedOrderItems,
    orderItemStatus,
    group_by,
    setGroups,
  ]);
  const onGroupItemModalClose = useCallback(() => {
    setModalGroup(null);
  }, [setModalGroup]);
  const showGroupCb = useCallback(
    group => {
      if (group && group.group) {
        dispatch(setRejectMode(false));
      }
      setShowRejectModal({
        show: false,
        reasons: null,
        comment: '',
      });
      setModalGroup(group ? group.group : null);
    },
    [dispatch, setModalGroup],
  );
  const showRejectModalCb = useCallback(
    async (scanCode, manualEntry, scanCodes) => {
      try {
        dispatch(actions.busy.add('REJECT_REASON_LIST'));

        const _scanCodes = scanCodes || [];
        if (scanCode && scanCode.length > 0) {
          _scanCodes.push(scanCode);
        }
        const rReasons = [];
        for (let i = 0; i < _scanCodes.length; i++) {
          const scanCode = _scanCodes[`${i}`].code;
          const rejectReasonsResp = await getRejectionReasons(scanCode);
          if (rejectReasonsResp.error) {
            toastError(rejectReasonsResp.error);
            return;
          }
          rReasons.push(rejectReasonsResp.reasons);
        }
        const rejectReasons = mergeRejectReasons(rReasons);
        setShowRejectModal({
          show: true,
          reasons: null,
          comment: '',
          scanCode: scanCode,
          scanCodes: scanCodes || [],
          manualEntry: manualEntry,
          rejectReasons: rejectReasons,
        });
      } finally {
        dispatch(actions.busy.remove('REJECT_REASON_LIST'));
      }
    },
    [dispatch],
  );
  const rejectModalConfirmCb = useCallback(
    async (showRejectModal, reasons, comment, images) => {
      try {
        if (showRejectModal) {
          const scanCodes = showRejectModal.scanCode
            ? [showRejectModal.scanCode]
            : showRejectModal.scanCodes;
          dispatch(actions.busy.add('MULTI-REJECTION'));
          const resp = await trackTraceMultiScan(
            scanCodes,
            true,
            {
              rejected: true,
              reasons: reasons,
              comment: comment,
              images: images,
            },
            images,
            true,
            dispatch,
            scannedItems,
            appendScannedItemsCb,
          );

          if (resp.challenge === true) {
            return;
          }
          if (resp.error) {
            toastError(
              typeof resp.error === 'object'
                ? resp.error.message || (
                    <div>
                      {resp.error.map((v, index) => (
                        <p key={index}>{`${v.code} - ${v.err}`}</p>
                      ))}
                    </div>
                  )
                : resp.error,
            );
          } else if (resp.success) {
            toastSuccess(resp.message);
          }
          await syncCb();
        }
        setShowRejectModal({
          show: false,
          reasons: reasons,
          comment: comment,
          rejectReasons: [],
        });
        setIsMultiCheck(false);
      } finally {
        dispatch(actions.busy.remove('MULTI-REJECTION'));
      }
    },
    [dispatch, setShowRejectModal, scannedItems, appendScannedItemsCb, syncCb],
  );
  return (
    <Fragment>
      <div className='col-12'>
        <div className='row'>
          {groups &&
            groups.map(val => {
              return (
                <div className={cardWrpClass} key={val.group}>
                  <TrackTraceGroupCard
                    group={val}
                    showGroupCb={showGroupCb}
                    scannedItems={scannedItems}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className='col-12'>
        <GroupItemModal
          onCloseCb={onGroupItemModalClose}
          showModal={modalGroup !== null && showRejectModal.show === false}
          showRejectModalCb={showRejectModalCb}
          group={groups.find(val => val.group === modalGroup) || {}}
          rejectReasons={showRejectModal.reasons}
          rejectComment={showRejectModal.comment}
          scannedItems={scannedItems}
          appendScannedItemsCb={appendScannedItemsCb}
          syncCb={syncCb}
          isMultiCheck={isMultiCheck}
          setIsMultiCheck={setIsMultiCheck}
        />
        {showRejectModal.show && (
          <RejectReasonModal
            showModal={showRejectModal.show === true}
            onCloseCb={e =>
              setShowRejectModal({
                show: false,
                reasons: null,
                comment: showRejectModal.comment,
                rejectReasons: [],
              })
            }
            onConfirmCb={(reasons, comment, images) =>
              rejectModalConfirmCb(showRejectModal, reasons, comment, images)
            }
            rejectReasons={showRejectModal.rejectReasons || []}
            scanCode={showRejectModal.scanCode}
          />
        )}
      </div>
    </Fragment>
  );
};
TrackTraceGroupCards.propTypes = {
  scanned_items: PropTypes.array.isRequired,
  scannedItems: PropTypes.array.isRequired,
  appendScannedItemsCb: PropTypes.func.isRequired,
};
