import service from './index';
import { getState } from '../store';

export const getAttachmentClaim = (
  attachmentType,
  serviceName,
  serviceRef,
  attachmentId,
) => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'GET',
    url: `/naadi-app/api/naadi-attachment-service/v1/naadi/attachment/${org.uuid}/attachments/claim/${attachmentType}/${serviceName}/${serviceRef}/${attachmentId}?sign_url=true`,
    data: {},
  });
};
