import { PROFILE_DESC, PROFILE_NAME } from '../actions';

const defaultState = { name: '', description: '' };

export const profileReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PROFILE_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case PROFILE_DESC:
      return {
        ...state,
        description: action.payload,
      };
    default:
      return null;
  }
};
