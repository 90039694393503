import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('CUT_GUIDE_STATE', type, verb);

export const TYPES = {
  CUT_GUIDE: 'CUT_GUIDE',
};
export const DEFAULT = {
  cut_guide_items_sync_time: 0,
  wo_filters: {},
  printer: null,
};

export const resetCutGuide = createAction(
  getActionName(TYPES.CUT_GUIDE, 'RESET'),
);
export const resetCutGuideStation = createAction(
  getActionName(TYPES.CUT_GUIDE, 'RESET_STATION'),
);

export const setCutGuideSyncTime = createAction(
  getActionName(TYPES.CUT_GUIDE, 'SET_SYNC_TIME'),
);

export const setCgWoFilters = createAction(
  getActionName(TYPES.CUT_GUIDE, 'SET_WO_FILTER'),
);

export const setCgPrinter = createAction(
  getActionName(TYPES.CUT_GUIDE, 'SET_PRINTER'),
);

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetCutGuide, state => {
    return Update(state, {
      cut_guide_items_sync_time: { $set: 0 },
      group_by: { $set: null },
    });
  });
  builder.addCase(resetCutGuideStation, state => {
    return Update(state, {
      cut_guide_items_sync_time: { $set: 0 },
    });
  });
  builder.addCase(setCutGuideSyncTime, (state, { payload }) => {
    return Update(state, {
      cut_guide_items_sync_time: { $set: payload },
    });
  });

  builder.addCase(setCgWoFilters, (state, { payload }) => {
    return Update(state, {
      wo_filters: { $set: payload },
    });
  });
  builder.addCase(setCgPrinter, (state, { payload }) => {
    return Update(state, {
      printer: { $set: payload },
    });
  });
});

export default reducer;
