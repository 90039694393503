export const TYPES = {
  ENTER_SCAN_ID: 'ENTER_SCAN_ID',
  FETCH_UUID: 'FETCH_UUID',
  POP_UUID: 'POP_UUID',
};

export const DEFAULT = {
  scanId: null,
  uuidList: [],
};
