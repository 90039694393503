export const TYPES = {
  ADD_SCREEN: 'ADD_SCREEN',
  CLOSE_SCREEN: 'CLOSE_SCREEN',
  CLEAR_SCREEN: 'CLEAR_SCREEN',
};

export const DEFAULT = {
  screens: [],
  confirmScreen: {
    show: false,
    content: null,
    boundingClass: null,
    boundingStyle: null,
  },
};
