import React, {
  useCallback,
  useRef,
  Fragment,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

export const NewLineScanInput = ({
  onSubmitCb,
  placeholder,
  disabled,
  resetTrigger,
  submitTrigger,
  focusTrigger,
}) => {
  const ref = useRef();
  const [inputVal, setInputVal] = useState('');
  const submitVal = useRef({
    inputVal: '',
    isManual: true,
    modificationCount: 0,
    firstModifiedTime: 0,
    isPasted: false,
  });
  const reset = useCallback(() => {
    setInputVal('');
    submitVal.current = {
      inputVal: '',
      isManual: true,
      modificationCount: 0,
      firstModifiedTime: 0,
      isPasted: false,
    };
  }, []);
  useEffect(() => {
    if (resetTrigger > 0) {
      reset();
    }
  }, [resetTrigger, reset]);
  useEffect(() => {
    if (submitTrigger) {
      setInputVal(input => {
        if (onSubmitCb && input && input.length > 0) {
          onSubmitCb((input ? input : '').trim(), true);
        }
        reset();
        return '';
      });
    }
  }, [submitTrigger, onSubmitCb, reset]);
  useEffect(() => {
    ref.current.focus();
  }, [focusTrigger]);
  const onPaste = useCallback(() => {
    submitVal.current.isPasted = true;
  }, []);
  return (
    <Fragment>
      <input
        ref={ref}
        placeholder={placeholder}
        className='form-control'
        value={inputVal}
        onKeyDown={e => {
          if (
            e.keyCode === 13 ||
            e.code === 'Enter' ||
            e.code === 'NumpadEnter'
          ) {
            const diff =
              new Date().getTime() - submitVal.current.firstModifiedTime;
            if (diff < 2500 && inputVal.length > 5) {
              submitVal.current.isManual = false;
            }
            if (onSubmitCb) {
              onSubmitCb(
                inputVal ? inputVal.trim() : '',
                submitVal.current.isManual || submitVal.current.isPasted,
              );
            }
            reset();
          }
        }}
        onChange={e => {
          const val = e.target.value ? e.target.value : '';

          if (val.length > 0 && submitVal.current.firstModifiedTime === 0) {
            submitVal.current.firstModifiedTime = new Date().getTime();
          } else if (val.length === 0) {
            submitVal.current.firstModifiedTime = 0;
          }
          setInputVal(val);
          submitVal.current.inputVal = val;
        }}
        onPaste={onPaste}
        disabled={disabled}
      />
    </Fragment>
  );
};

const ScanInput = ({
  onSubmitCb,
  placeholder,
  disabled,
  submitTrigger,
  onKeyDown,
  onChange,
}) => {
  const [inputVal, setInputVal] = useState('');
  const submitVal = useRef({
    inputVal: '',
    isManual: true,
    modificationCount: 0,
    firstModifiedTime: 0,
    isPasted: false,
    lastSubmitTrigger: 0,
  });

  const setModificationStatus = useCallback(() => {
    let isManual = false;
    const timeToModify =
      submitVal.current.firstModifiedTime === 0
        ? 0
        : new Date().getTime() - submitVal.current.firstModifiedTime;
    if (
      submitVal.current.isPasted ||
      (submitVal.current.modificationCount > 5 && timeToModify > 1500)
    ) {
      isManual = true;
    }
    submitVal.current.isManual = isManual;
  }, []);
  const onPaste = useCallback(() => {
    submitVal.current.isPasted = true;
  }, []);

  useEffect(() => {
    if (
      submitVal.current.lastSubmitTrigger >= submitTrigger ||
      !submitTrigger
    ) {
      return;
    }
    submitVal.current.lastSubmitTrigger = submitTrigger;
    setModificationStatus();
    if (onSubmitCb) {
      onSubmitCb(submitVal.current.inputVal, submitVal.current.isManual);
    }
    submitVal.current.inputVal = '';
    setInputVal('');
    submitVal.current.isManual = true;
    submitVal.current.modificationCount = 0;
    submitVal.current.firstModifiedTime = 0;
    submitVal.current.isPasted = false;
  }, [submitTrigger, setModificationStatus, setInputVal, onSubmitCb]);

  const onInputChange = useCallback(
    e => {
      if (onChange) {
        onChange(e);
      }
      if (e.target.value === '') {
        submitVal.current.modificationCount = 0;
        submitVal.current.isPasted = false;
        submitVal.current.firstModifiedTime = 0;
        return;
      }
      if (e.target.value === submitVal.current.inputVal) {
        return;
      }
      submitVal.current.inputVal = e.target.value;
      setInputVal(e.target.value);
      const inputValLength = submitVal.current.inputVal
        ? submitVal.current.inputVal.length
        : 0;
      if (e.target.value.length === 0) {
        submitVal.current.modificationCount = 0;
        submitVal.current.firstModifiedTime = 0;
      } else {
        submitVal.current.firstModifiedTime =
          submitVal.current.firstModifiedTime > 0
            ? submitVal.current.firstModifiedTime
            : new Date().getTime();
      }
      if (e.target.value.length > inputValLength) {
        submitVal.current.modificationCount += 1;
      }
    },
    [onChange],
  );
  return (
    <Fragment>
      <input
        placeholder={placeholder}
        className='form-control'
        value={inputVal}
        onKeyDown={e => {
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
        onChange={onInputChange}
        onPaste={onPaste}
        disabled={disabled}
      />
    </Fragment>
  );
};

ScanInput.propTypes = {
  onSubmitCb: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  submitTrigger: PropTypes.number.isRequired,
};

export default ScanInput;
