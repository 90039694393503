import React, { useRef, useEffect } from 'react';
import { toggleSideMenu } from '../../store/app/nav/actions';
import { useDispatch } from 'react-redux';
import OnlyMobile from './only-mobile';
import useParentWidth from '../../hooks/useParentWidth';
import Menu from '../../assets/icons/icon-menu.svg';
import backBtn from '../../assets/icons/backBtn.svg';
import { useHistory } from 'react-router-dom';

const Header = ({ type, name, icon }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const container = useRef();

  //const [scrollY, setScrollY] = useState(0);

  const handleMenuToggle = () => {
    dispatch(toggleSideMenu());
  };

  useParentWidth(container);

  useEffect(() => {
    const onScroll = () => {
      //setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className='container-fluid px-0' style={{ minHeight: '76px' }}>
      <div
        ref={container}
        className={`flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 `}
        style={{ zIndex: 10, position: 'fixed' }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          onClick={type === 'form' ? goBack : handleMenuToggle}
          type='button'
        >
          <img src={type === 'form' ? backBtn : Menu} alt='Menu' />
        </button>
        <div className='ms-2 page-header'>
          <div className='page-title d-flex align-items-center'>
            <OnlyMobile>
              <span className='ps-2'>{icon}</span>
            </OnlyMobile>
            <div className='ps-2'>{name}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
