/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */

import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
import { actions } from '@naadi/framework';
import {
  createNewBomAttachment,
  addOrderSrcService,
  getOrdersBomDetails,
  getOrderAttachments,
  getBomDetails,
  expandOrderSrcService,
} from '../../../services/bom';
import { ResponseCodes } from '@naadi/framework';
import {
  setCSVData,
  setOrderSrcId,
  setOrderSrcType,
  updateOrders,
} from '../ordersList/actions';
import { uploadFileService } from '../../../services/upload';
import {
  releaseHoldToProductionService,
  serializeOrderSrc,
  updateOrderService,
} from '../../../services/order';
import {
  toastError,
  toastMessage,
} from '../../../helpers/packing/packingHelper';
import { getState } from '../../index';
import { cloneDeep, uniq } from 'lodash';
import {
  getResponseErrorMessage,
  toastResponseError,
} from '../../../helpers/common/helper';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}
const getActionName = (type, verb) => actionTypeName('ADD_BOM', type, verb);

const UNABLE_TO_CLOSE_ORDER = 'Unable to Close the Order';
const UNABLE_TO_CANCEL_ORDER = 'Unable to Cancel the Order';
const UNABLE_TO_INPROD_ORDER = 'Unable to Release the Hold on the order';
export const TYPES = {
  RESET_GROUP_BY_COLS: 'RESET_GROUP_BY_COLS',
  SET_GROUP_BY_COLS: 'SET_GROUP_BY_COLS',
  UNSET_GROUP_BY_COLS: 'UNSET_GROUP_BY_COLS',
  SAVING_NEW_BOM: 'SAVING_NEW_BOM',
  FETCHING_BOM_FOR_ORDERS: 'FETCHING_BOM_FOR_ORDERS',
  FETCHING_ORDER_ATTACHMENTS: 'FETCHING_ORDER_ATTACHMENTS',
  RELEASE_ORDER: 'RELEASE_ORDER',
  CANCEL_ORDER: 'CANCEL_ORDER',
  CLOSE_ORDER: 'CLOSE_ORDER',
  REOPEN_ORDER: 'REOPEN_ORDER',
  RESET_COLUMN_HEADERS: 'RESET_COLUMN_HEADERS',
  SET_COLUMN_HEADERS: 'SET_COLUMN_HEADERS',
  UNSET_COLUMN_HEADERS: 'UNSET_COLUMN_HEADERS',
};

export const setGroupByCols = createAction(
  getActionName('GROUP_BY_COL', 'SET'),
);
export const resetGroupByCols = createAction(
  getActionName('GROUP_BY_COL', 'RESET'),
);
export const unsetGroupByCols = createAction(
  getActionName('GROUP_BY_COL', 'UN_SET'),
);
export const showCSVTableModal = createAction(
  getActionName('SHOW_MODAL_CSV', 'SET'),
);

export const hideCSVTableModal = createAction(
  getActionName('SHOW_MODAL_CSV', 'UN_SET'),
);

export const showCSVEditTableModal = createAction(
  getActionName('SHOW_MODAL_CSV_EDIT', 'SET'),
);

export const hideCSVEditTableModal = createAction(
  getActionName('SHOW_MODAL_CSV_EDIT', 'UN_SET'),
);

export const setBOMForOrder = createAction(
  getActionName('SET_BOM_FOR_ORDER', 'SET'),
);

export const setAttachmentsForOrder = createAction(
  getActionName('SET_ATTACHMENT_FOR_ORDER', 'SET'),
);

export const setBomIDinEdit = createAction(
  getActionName('BOM_ID_IN_EDIT_MODE', 'SET'),
);

export const resetBomIDinEdit = createAction(
  getActionName('BOM_ID_IN_EDIT_MODE', 'UNSET'),
);

export const setEditActiveColumn = createAction(
  getActionName('EDIT_TABLE_ROW', 'SET'),
);

export const resetEditActiveColumn = createAction(
  getActionName('EDIT_TABLE_ROW', 'RESET'),
);

export const setBomData = createAction(getActionName('BOM_DETAILS', 'SET'));

export const createBOM =
  (
    order,
    payload,
    order_src_name,
    order_src_id,
    order_src_type,
    group_by_cols,
    column_headers,
    onCloseCb,
  ) =>
  async (dispatch, getState) => {
    const { busy } = getState();
    if (busy.includes(TYPES.SAVING_NEW_BOM)) {
      return;
    }
    dispatch(actions.busy.add(TYPES.SAVING_NEW_BOM));
    try {
      await _createBOM(
        order,
        payload,
        order_src_name,
        order_src_id,
        order_src_type,
        group_by_cols,
        column_headers,
        dispatch,
        getState,
      );
      dispatch(setOrderSrcType({ type: null }));
      dispatch(setOrderSrcId({ order_src_id: null }));
      if (onCloseCb) {
        onCloseCb();
      }
      dispatch(hideCSVTableModal());
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(actions.busy.remove(TYPES.SAVING_NEW_BOM));
    }
  };

export const editBOM =
  (
    order,
    payload,
    order_src_name,
    order_src_id,
    order_src_type,
    group_by_cols,
  ) =>
  async (dispatch, getState) => {
    const { busy } = getState();
    if (busy.includes(TYPES.SAVING_NEW_BOM)) {
      return;
    }
    dispatch(actions.busy.add(TYPES.SAVING_NEW_BOM));
    try {
      await _createBOM(
        order,
        payload,
        order_src_name,
        order_src_id,
        order_src_type,
        group_by_cols,
        null,
        dispatch,
        getState,
      );
      dispatch(setOrderSrcType({ type: null }));
      dispatch(setOrderSrcId({ order_src_id: null }));
      dispatch(hideCSVEditTableModal(dispatch));
      dispatch(
        setCSVData({
          list: [],
          columns: [],
          column_headers: [],
        }),
      );
      dispatch(resetBomIDinEdit(dispatch));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(actions.busy.remove(TYPES.SAVING_NEW_BOM));
    }
  };

export const _createBOM = async (
  order,
  payload,
  order_src_name,
  order_src_id,
  order_src_type,
  group_by_cols,
  column_headers,
  dispatch,
  getState,
) => {
  try {
    const fetchResult = await fetch(payload);
    const blobData = await fetchResult.blob();
    const bomAttachmentResp = await createNewBomAttachment(order_src_name);
    if (bomAttachmentResp.status !== ResponseCodes.SUCCESS) {
      toastError(
        bomAttachmentResp.err && bomAttachmentResp.err.err
          ? bomAttachmentResp.err.err
          : 'Unable to upload the attachment',
      );
      console.log('Error');
      return;
    }
    const attachmentVO = bomAttachmentResp.payload;
    const uploadResult = await uploadFileService(blobData, attachmentVO.uuid);
    if (uploadResult.status !== ResponseCodes.SUCCESS) {
      console.log('1Handle Upload Failure Message here.');
      toastError(
        uploadResult.err && uploadResult.err.err
          ? uploadResult.err.err
          : 'Unable to upload the attachment.',
      );
      return;
    } else if (uploadResult.payload !== true) {
      console.log('2Handle Upload Failure Message..');
      toastError('Upload of BOM failed');
      return;
    }

    const addOrderSrcResult = await addOrderSrcService(
      order_src_name,
      order.uuid,
      order_src_id,
      order_src_type,
      attachmentVO.uuid,
      group_by_cols,
      column_headers,
    );
    if (addOrderSrcResult.status !== ResponseCodes.SUCCESS) {
      console.log('3Handle Upload Failure Message');
      const err =
        addOrderSrcResult.err && addOrderSrcResult.err.err
          ? addOrderSrcResult.err.err
          : 'Unable to Update the Order Source';
      toastError(err);
      return;
    }
    const expandResult = await expandOrderSrcService(
      addOrderSrcResult.payload.uuid,
    );
    if (expandResult.status !== ResponseCodes.SUCCESS) {
      toastError('Order Uploaded, Validation Failed');
      dispatch(getSourcesForOrder(dispatch, order.uuid, true));
      return;
    }
    const msg = order.uuid
      ? 'Order Source Update Scheduled'
      : 'Order Source Created and Scheduled';
    toastMessage({
      type: 'success',
      message: msg,
    });
    dispatch(getSourcesForOrder(dispatch, order.uuid, true));
  } catch (err) {
    console.log(err);
  }
};

export const getSourcesForOrderSync = async (
  selection,
  id,
  showLoader,
  dispatch,
  getState,
  resolver,
  background,
) => {
  const resolve = status => {
    if (resolver) {
      resolver(status);
    }
  };
  let status = {
    type: 'success',
    message: '',
    err: '',
  };

  if (getState) {
    const { busy } = getState();
    if (
      !background &&
      busy.includes(TYPES.FETCHING_BOM_FOR_ORDERS) &&
      showLoader
    ) {
      status.type = 'error';
      status.err = 'Fetch Error';
      resolve(status);
      return {};
    }
  }

  try {
    if (showLoader === true && !background) {
      dispatch(actions.busy.add(TYPES.FETCHING_BOM_FOR_ORDERS));
    }
    const bomDetailResp = await getOrdersBomDetails(id, true);
    if (bomDetailResp.status === 200) {
      const sources_for_id = {
        [id]:
          bomDetailResp.payload && bomDetailResp.payload.length
            ? bomDetailResp.payload
            : [],
      };
      if (!background) dispatch(setBOMForOrder(sources_for_id));
      return {
        type: 'sucess',
        message: 'Bom Fetched',
      };
    } else {
      return {
        type: 'error',
        message:
          bomDetailResp.err && bomDetailResp.err.err
            ? bomDetailResp.err.err
            : 'Unable to fetch',
      };
    }
  } finally {
    if (showLoader === true && !background) {
      dispatch(actions.busy.remove(TYPES.FETCHING_BOM_FOR_ORDERS));
    }
  }
};

export const getSourcesForOrder =
  (selection, id, showLoader) => async (dispatch, getState) => {
    return await getSourcesForOrderSync(
      selection,
      id,
      showLoader,
      dispatch,
      getState,
    );
  };

export const getAttachmentsForOrder =
  (selection, id) => (dispatch, getState) => {
    const { busy } = getState();
    if (busy.includes(TYPES.FETCHING_ORDER_ATTACHMENTS)) {
      return;
    }
    dispatch(actions.busy.add(TYPES.FETCHING_ORDER_ATTACHMENTS));
    Promise.all([getOrderAttachments(id, true)])
      .then(([attachments]) => {
        const attachments_for_id = {
          [id]:
            attachments.payload && attachments.payload.length
              ? attachments.payload
              : [],
        };
        dispatch(setAttachmentsForOrder(attachments_for_id));
      })
      .finally(() => {
        dispatch(actions.busy.remove(TYPES.FETCHING_ORDER_ATTACHMENTS));
      });
  };
export const getOrderSrcColumnHeaders = (
  order_src_id,
  order_id,
  table_data,
) => {
  const { bom_data } = getState();
  if (!bom_data.sources[`${order_id}`]) return [];
  const order_src = bom_data.sources[`${order_id}`].filter(
    val => val.uuid === order_src_id,
  );
  if (!order_src) return [];
  const columnHeaders = uniq(
    order_src.length > 0 && order_src[0].column_headers
      ? cloneDeep(order_src[0].column_headers)
      : [],
  );

  table_data.forEach(row => {
    Object.keys(row).forEach(col => {
      if (columnHeaders.indexOf(col) === -1) {
        columnHeaders.push(col);
      }
    });
  });
  return columnHeaders;
};
export const getSourcesDetails =
  ({ bom_id, order_id }) =>
  (dispatch, getState) => {
    const { busy } = getState();
    if (busy.includes(TYPES.FETCHING_BOM_FOR_ORDERS)) {
      return;
    }
    dispatch(actions.busy.add(TYPES.FETCHING_BOM_FOR_ORDERS));
    Promise.all([getBomDetails(bom_id, order_id, true)])
      .then(([{ payload }]) => {
        const table_data =
          payload && payload.rows && payload.rows.length
            ? payload.rows.map(({ fields }) => fields)
            : [];
        table_data.forEach((val, index) => {
          val['#'] = index + 1;
          val['__ROW_ERRORS__'] = payload.rows[`${index}`].item_errors;
          val['__ROW_FIELD_ERRORS__'] =
            payload.rows[`${index}`].item_field_errors;
        });
        const item_errors =
          payload && payload.rows && payload.rows.length
            ? payload.rows.map(({ item_errors }) => item_errors)
            : [];
        const item_field_errors =
          payload && payload.rows && payload.rows.length
            ? payload.rows.map(({ item_field_errors }) => item_field_errors)
            : [];
        const order_errors =
          payload && payload.order_errors ? payload.order_errors : [];
        const column_headers = getOrderSrcColumnHeaders(
          bom_id,
          order_id,
          table_data,
        ).filter(val => val && val !== null && val.length > 0);
        const sources_for_id = {
          [bom_id]: {
            table_data,
            item_errors,
            item_field_errors,
            order_errors,
            column_headers,
          },
        };
        dispatch(setBomData(sources_for_id));
      })
      .finally(() => {
        dispatch(actions.busy.remove(TYPES.FETCHING_BOM_FOR_ORDERS));
      });
  };

export const closeOrder =
  (order_id, resolver, background) => async (dispatch, getState) => {
    const { busy } = getState();
    const resolve = status => {
      if (resolver) {
        resolver(status);
      }
    };
    let status = {
      type: 'success',
      message: '',
      err: '',
    };
    if (!background && busy.includes(TYPES.CLOSE_ORDER)) {
      status.type = 'error';
      status.err = 'Close is in progress. Do not retrigger';
      resolve(status);
      return;
    }
    try {
      if (!background) {
        dispatch(actions.busy.add(TYPES.CLOSE_ORDER));
      }

      const orderUpdateResp = await updateOrderService(order_id, {
        status: 'CLOSED',
      });
      if (orderUpdateResp.status !== 200) {
        if (!background) {
          toastResponseError(orderUpdateResp, UNABLE_TO_CLOSE_ORDER);
        }
        status.type = 'error';
        status.err = getResponseErrorMessage(
          orderUpdateResp,
          UNABLE_TO_CLOSE_ORDER,
        );
        return;
      }
      status.message = 'Order Closed';
      if (!background) {
        toastMessage('Order Closed');
      }
    } finally {
      if (!background) {
        dispatch(actions.busy.remove(TYPES.CLOSE_ORDER));
      }
      resolve(status);
    }
  };

export const cancelOrder =
  (order_id, resolver, background) => async (dispatch, getState) => {
    const { busy } = getState();
    const resolve = status => {
      if (resolver) {
        resolver(status);
      }
    };
    let status = {
      type: 'success',
      message: '',
      err: '',
    };

    if (!background && busy.includes(TYPES.CANCEL_ORDER)) {
      status.type = 'error';
      status.err = 'Cancellation is in progress. Do not retrigger';
      resolve(status);
      return;
    }
    try {
      if (!background) {
        dispatch(actions.busy.add(TYPES.CANCEL_ORDER));
      }

      const orderUpdateResp = await updateOrderService(order_id, {
        status: 'CANCELLED',
      });
      if (orderUpdateResp.status !== 200) {
        if (!background) {
          toastResponseError(orderUpdateResp, UNABLE_TO_CANCEL_ORDER);
        }
        status.type = 'error';
        status.err = getResponseErrorMessage(
          orderUpdateResp,
          UNABLE_TO_CANCEL_ORDER,
        );
        return;
      }
      status.message = 'Order Cancelled';
      if (!background) {
        toastMessage('Order Cancelled');
      }
      const ordersDetailsMap = getState().ordersList.orders_details;
      if (!ordersDetailsMap || ordersDetailsMap[`${order_id}`]) return;
      const order = cloneDeep(ordersDetailsMap[`${order_id}`]);
      order.status = orderUpdateResp.payload.status;
      order.rev = orderUpdateResp.payload.rev;
      order.updated_on = orderUpdateResp.payload.updated_on;
      dispatch(
        updateOrders({
          [order_id]: order,
        }),
      );
    } finally {
      if (!background) {
        dispatch(actions.busy.remove(TYPES.CANCEL_ORDER));
      }

      resolve(status);
    }
  };

export const releaseHoldToProduction =
  (order_id, resolver, background) => async (dispatch, getState) => {
    const resolve = status => {
      if (resolver) {
        resolver(status);
      }
    };
    let status = {
      type: 'success',
      message: '',
      err: '',
    };
    const { busy } = getState();
    if (!background && busy.includes('UPDATE_ORDER_STATE')) {
      resolve(status);
      return;
    }
    try {
      if (!background) {
        dispatch(actions.busy.remove('UPDATE_ORDER_STATE'));
      }
      const orderUpdateResp = await releaseHoldToProductionService(order_id);

      if (orderUpdateResp.status !== 200) {
        if (!background) {
          toastResponseError(orderUpdateResp, UNABLE_TO_INPROD_ORDER);
        }
        status.type = 'error';
        status.err = getResponseErrorMessage(
          orderUpdateResp,
          UNABLE_TO_INPROD_ORDER,
        );
        return;
      }
      status.message = 'Orders State Updated';
      if (!background) {
        toastMessage('Order State Updated');
      }
    } finally {
      if (!background) {
        dispatch(actions.busy.remove('UPDATE_ORDER_STATE'));
      }
      resolve(status);
    }
  };
export const updateOrderState =
  (order_id, orderState, resolver, background) =>
  async (dispatch, getState) => {
    const { busy } = getState();

    const resolve = status => {
      if (resolver) {
        resolver(status);
      }
    };
    let status = {
      type: 'success',
      message: '',
      err: '',
    };
    if (!background && busy.includes('UPDATE_ORDER_STATE')) {
      resolve(status);
      return;
    }

    try {
      if (!background) {
        dispatch(actions.busy.add('UPDATE_ORDER_STATE'));
      }
      const orderUpdateResp = await updateOrderService(order_id, {
        status: orderState,
      });

      if (orderUpdateResp.status !== 200) {
        if (!background) {
          toastResponseError(orderUpdateResp, UNABLE_TO_INPROD_ORDER);
        }
        status.type = 'error';
        status.err = getResponseErrorMessage(
          orderUpdateResp,
          UNABLE_TO_INPROD_ORDER,
        );
        return;
      }
      status.message = 'Orders State Updated';
      if (!background) {
        toastMessage('Order State Updated');
      }
    } finally {
      if (!background) {
        dispatch(actions.busy.remove('UPDATE_ORDER_STATE'));
      }
      resolve(status);
    }
  };
export const reopenOrder = order_id => async (dispatch, getState) => {
  const { busy } = getState();
  if (busy.includes(TYPES.REOPEN_ORDER)) {
    return;
  }
  try {
    dispatch(actions.busy.add(TYPES.REOPEN_ORDER));
    const orderUpdateResp = await updateOrderService(order_id, {
      status: 'CREATED',
    });
    if (orderUpdateResp.status !== 200) {
      toastResponseError(orderUpdateResp, 'Unable to Close the Order');
      return;
    }
    toastMessage('Reopen Closed');
  } finally {
    dispatch(actions.busy.remove(TYPES.REOPEN_ORDER));
  }
};

export const releaseOrderToProductionAsync = async (
  order_id,
  releaseCb,
  hideLoader,
  dispatch,
  busy,
  bom_data,
) => {
  if (!hideLoader && busy.includes(TYPES.RELEASE_ORDER)) {
    return null;
  }
  if (!bom_data || !bom_data.sources) return;
  const sources_for_order = bom_data.sources[`${order_id}`] || [];
  if ((!hideLoader || hideLoader === undefined) && busy) {
    dispatch(actions.busy.add(TYPES.RELEASE_ORDER));
  }
  try {
    let serializationCnt = 0;
    //let releaseCnt = 0;
    const errors = [];
    for (let i = 0; i < sources_for_order.length; i++) {
      const order_src = sources_for_order[`${i}`];
      if (order_src.released === true) {
        serializationCnt++;
        //releaseCnt++;
        continue;
      }
      const serializeResp = await serializeOrderSrc(order_src.uuid, true);
      if (serializeResp.status !== ResponseCodes.SUCCESS) {
        if (serializeResp.err && serializeResp.err.err) {
          errors.push(serializeResp.err.err);
        }
        continue;
      }
      serializationCnt++;
      //releaseCnt++;
    }
    if (serializationCnt === 0 && errors.length > 0) {
      return {
        err: errors[0] + '',
      };
      //toastError(errors[0] + '');
    } else if (
      serializationCnt < sources_for_order.length ||
      errors.length > 0
    ) {
      if (!hideLoader) {
        toastError('Unable to Release All the Order Sources');
      }
      return {
        type: 'error',
        err: 'Unable to Release All the Order Sources',
      };
    } else {
      if (!hideLoader) {
        toastMessage({
          type: 'success',
          message: 'Order Release to Production Initiated.',
        });
      }

      if (releaseCb) {
        releaseCb();
      }

      return {
        type: 'success',
        message: 'Order Release to Production Initiated.',
      };
    }
  } catch (err) {
    console.log(err);
    if (!hideLoader) {
      toastError('Unable to Release the Order');
    }

    return {
      type: 'error',
      err: 'Unable to Release the Order.',
    };
  } finally {
    if ((!hideLoader || hideLoader === undefined) && busy) {
      dispatch(actions.busy.remove(TYPES.RELEASE_ORDER));
    }
  }
};
export const releaseOrderToProduction =
  (order_id, releaseCb, hideLoader) => async (dispatch, getState) => {
    const { busy, bom_data } = getState();
    const resp = await releaseOrderToProductionAsync(
      order_id,
      releaseCb,
      hideLoader,
      dispatch,
      busy,
      bom_data,
    );
    if (resp.err) {
      toastError(resp.err);
    } else if (resp.message) {
      toastMessage({
        type: 'success',
        message: resp.message,
      });
    }
  };

export const DEFAULT = {
  group_by_cols: [],
  show_csv_table_menu: false,
  show_label_print_modal: false,
  label_print_records: [],
  label_print_group_fields: [],
  label_print_group_fields_selected: [],
  sources: {},
  attachments: {},
  bom_data: {},
  editable_rows: {},
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetGroupByCols, (state, { payload }) => {
    return Update(state, {
      group_by_cols: { $set: payload },
    });
  });
  builder.addCase(setGroupByCols, (state, { payload }) => {
    return Update(state, {
      group_by_cols: { $push: [payload] },
    });
  });
  builder.addCase(unsetGroupByCols, (state, { payload }) => {
    const index = state.group_by_cols.indexOf(payload);
    return Update(state, {
      group_by_cols: { $splice: [[index, 1]] },
    });
  });

  builder.addCase(hideCSVTableModal, state => {
    return Update(state, {
      show_csv_table_menu: { $set: false },
    });
  });
  builder.addCase(showCSVTableModal, state => {
    return Update(state, {
      show_csv_table_menu: { $set: true },
    });
  });
  builder.addCase(setBOMForOrder, (state, { payload }) => {
    return Update(state, {
      sources: { $merge: payload },
    });
  });
  builder.addCase(setAttachmentsForOrder, (state, { payload }) => {
    return Update(state, {
      attachments: { $merge: payload },
    });
  });
  builder.addCase(hideCSVEditTableModal, state => {
    return Update(state, {
      show_csv_table_edit_menu: { $set: false },
    });
  });
  builder.addCase(showCSVEditTableModal, state => {
    return Update(state, {
      show_csv_table_edit_menu: { $set: true },
    });
  });
  builder.addCase(setBomIDinEdit, (state, { payload }) => {
    return Update(state, {
      bom_id_edit_menu: { $set: payload },
    });
  });
  builder.addCase(resetBomIDinEdit, state => {
    return Update(state, {
      bom_id_edit_menu: { $set: null },
    });
  });
  builder.addCase(setBomData, (state, { payload }) => {
    return Update(state, {
      bom_data: { $merge: payload },
    });
  });
  builder.addCase(setEditActiveColumn, (state, { payload }) => {
    return Update(state, {
      editable_rows: { $merge: payload },
    });
  });
  builder.addCase(resetEditActiveColumn, (state, { payload }) => {
    return Update(state, {
      editable_rows: { $set: {} },
    });
  });
});

export default reducer;
