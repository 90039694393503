import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  getHumanizedDuration,
  isPrinterConnected,
} from '../../../helpers/printers/printerHelper';
import Dropdown from 'react-bootstrap/Dropdown';
import IconPrint from '../../../components/packing/common/icon-print';
import { fetchConnectedPrinters } from '../../../services/org';
import { actions } from '@naadi/framework';

export const getPrinters = async (dispatch, background = false) => {
  try {
    if (!background) {
      dispatch(actions.busy.add('FETCH_CONNECTED_PRINTERS'));
    }

    const resp = await fetchConnectedPrinters({ deleted: [false] });
    if (resp.status !== 200) {
      return [];
    }
    resp.payload.unshift({
      printer_id: 'OFFLINE_PDF_PRINTER',
      name: 'PDF Download',
    });
    return resp.payload;
  } finally {
    if (!background) {
      dispatch(actions.busy.remove('FETCH_CONNECTED_PRINTERS'));
    }
  }
};

export const PrinterSelector = ({
  printers,
  onPrintCb,
  disabled,
  triggerDropDown,
  children,
  onClick,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const lastTrigger = useRef(triggerDropDown);
  const onDropdownToggle = useCallback(
    status => {
      //console.log('Inside Printer Selector');
      if (disabled && status) {
        return;
      }
      setDropdownOpen(status);
    },
    [disabled, setDropdownOpen],
  );
  useEffect(() => {
    if (
      !triggerDropDown ||
      (lastTrigger.current && lastTrigger.current >= triggerDropDown)
    ) {
      return;
    }
    lastTrigger.current = triggerDropDown;
    setDropdownOpen(true);
  }, [triggerDropDown, setDropdownOpen]);

  /* eslint-disable sonarjs/no-identical-functions */
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display ${
          isDropdownOpen ? 'active' : ''
        }`}
        style={{ zIndex: 1000 }}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );

  const renderPrinterCard = useCallback(
    printer => {
      const lastConnected = getHumanizedDuration(printer);
      const lastConnectedMessage =
        printer.printer_id === 'OFFLINE_PDF_PRINTER'
          ? 'Pdf Download'
          : lastConnected
          ? 'Connected ' + lastConnected + ' ago'
          : 'Printer Not Connected';
      const connected =
        printer.printer_id === 'OFFLINE_PDF_PRINTER' ||
        isPrinterConnected(printer);
      const printerStatusTextClass = connected
        ? 'printer-status-text success'
        : 'printer-status-text failure';
      const printerStatusClass = connected
        ? 'printer-status printer-connected'
        : 'printer-status';
      return (
        <div
          className={`printer-select-wrp `}
          onClick={e => {
            //console.log('::' + printer.name + ' Clicked');
            if (onPrintCb) onPrintCb(printer);
            setDropdownOpen(false);
            if (onClick) {
              onClick(e);
            }
          }}
        >
          <div className='dropdown-printer-name'>{printer.name}</div>
          <div className='printer-last-connected'>
            <span>{lastConnectedMessage}</span>
            <span> . </span>
            <span className={printerStatusTextClass}>
              {connected ? 'ONLINE' : 'OFFLINE'} -{' '}
              {printer.selected === true ? 'SELECTED' : ''}
            </span>
          </div>
          <div className={printerStatusClass}>
            <div></div>
          </div>
        </div>
      );
    },
    [onClick, onPrintCb],
  );

  /* eslint-enable sonarjs/no-identical-functions */

  return (
    <div className='d-flex position-relative order-label-print-filter-selector'>
      <div className='d-flex'>
        <Dropdown
          className='page-size-selector'
          onToggle={onDropdownToggle}
          //drop={'down'}
        >
          <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
            <div
              className={`btn-label text-primary print-text d-flex align-items-center`}
            >
              {children ? children : <></>}

              <div
                className='print-icon d-flex align-items-center h-100'
                style={{ top: '0px' }}
              >
                <IconPrint
                  onClick={e => {
                    console.log('Icon Click');
                  }}
                  fill={disabled === true ? '#CCCCCC' : '#007BFF'}
                />
              </div>
            </div>
          </Dropdown.Toggle>
          {isDropdownOpen && (
            <Dropdown.Menu
              className='overflow-auto  dropdown'
              //align={{ sm: 'right', md: 'start' }}
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    options: [0, 10],
                  },
                ],
              }}
              flip={true}
              show={isDropdownOpen}
            >
              {(disabled === undefined || disabled === false) &&
                printers.map(printer => (
                  <Dropdown.Item
                    onClick={() => {
                      //debugger;
                      //console.log('Printer On Print CB');
                    }}
                    disabled={false}
                    key={printer.printer_id}
                    eventKey={printer.name}
                    className={`printer-select-box  ${
                      printer.selected === true ? 'selected' : ''
                    }`}
                  >
                    {renderPrinterCard(printer)}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </div>
    </div>
  );
};
