import { createAction, createReducer } from '@reduxjs/toolkit';
import Update from 'immutability-helper';

export const DEFAULT = {
  layouts: {},
  breakpoint: 'lg',
  freeze: false,
};
export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}
const getActionName = (type, verb) =>
  actionTypeName('DASHBOARD_STATE', type, verb);
export const TYPES = {
  LAYOUT: 'LAYOUT',
  BREAKPOINT: 'BREAKPOINT',
  FREEZE: 'FREEZE',
};

export const resetDashboardLayout = createAction(
  getActionName(TYPES.LAYOUT, 'RESET'),
);
export const updateDashboardLayout = createAction(
  getActionName(TYPES.LAYOUT, 'MERGE'),
);
export const setDashboardBreakpoint = createAction(
  getActionName(TYPES.BREAKPOINT, 'SET'),
);

export const setFreezeLayout = createAction(getActionName(TYPES.FREEZE, 'SET'));

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetDashboardLayout, (state, { payload }) => {
    return Update(state, {
      layouts: { $set: {} },
    });
  });
  builder.addCase(updateDashboardLayout, (state, { payload }) => {
    return Update(state, {
      layouts: { $merge: payload },
    });
  });
  builder.addCase(setDashboardBreakpoint, (state, { payload }) => {
    return Update(state, {
      breakpoint: { $set: payload },
    });
  });
  builder.addCase(setFreezeLayout, (state, { payload }) => {
    return Update(state, {
      freeze: { $set: payload === true },
    });
  });
});

export default reducer;
