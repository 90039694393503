import React, { Fragment, memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  addOfflineScan,
  removeOfflineScan,
  setSelectedRecord,
} from '../../store/tracktrace/tracktrace';
import {
  TrackTraceDrillDoneIcon,
  TrackTraceHistoryIcon,
  TrackTraceItemDetailIcon,
  TrackTraceItemImageIcon,
  TrackTraceManualScanIcon,
  TrackTraceRejectIcon,
  TrackTraceScanIcon,
  TrackTraceSuccessIcon,
} from '../../assets/icons/icon-tracktrace';

import {
  getOrderItemDrillsStatus,
  getOrderItemSelectedFieldValues,
  getScannedRecordImageLinks,
  getScanCodeFromScanRecord,
  trackTraceScan,
  getTrackTraceContainerClass,
  getTrackTraceCardsWrpClass,
  hasCncOperation,
  getScanStateClass,
} from '../../helpers/tracktrace/orderItemHelper';
import { isEqual, cloneDeep } from 'lodash';
import { Carousel } from 'react-bootstrap';
import useMobile from '../../hooks/useMobile';
import {
  toastError,
  toastSuccess,
  toastMessage,
} from '../../helpers/packing/packingHelper';
import { TrackTraceGroupCards } from './group-container';
import ImageListModal from './image-list-modal';
import { isSerializedItem } from '../../helpers/packing/itemsHelper';
import { TrackTracePrintIcon } from '../../assets/icons/icon-tracktrace';
import { actions } from '@naadi/framework';
import { printOrderItemLabels } from '../../services/order';
import { mergePdfFiles, toastResponseError } from '../../helpers/common/helper';
import FileSaver from 'file-saver';
// eslint-disable-next-line sonarjs/cognitive-complexity
export const handlePrinting = async (printers, dispatch, records) => {
  const filterPrinter = printers.filter(val => val.selected === true);
  const selectedPrinter =
    filterPrinter.length > 0
      ? filterPrinter
      : [
          {
            printer_id: 'OFFLINE_PDF_PRINTER',
            selected: true,
          },
        ];
  if (records.length === 0) {
    toastMessage('There are No Labels to Print');
    return {
      error: 'There are No Labels to Print',
      success: false,
    };
  }
  const orderItems = records
    .filter(val => val.selected === true)
    .map(val => val.uuid);

  if (!selectedPrinter || selectedPrinter.length === 0) {
    toastError('Please Select Printer');
    return {
      error: 'Please Select Printer',
      success: false,
    };
  }
  const reqData = {
    order_item_id: orderItems,
    printer_id: selectedPrinter[0].printer_id,
  };
  if (selectedPrinter[0].printer_id === 'OFFLINE_PDF_PRINTER') {
    reqData.file_name = 'Part_Label.pdf';
    reqData.batch_size = orderItems.length + 1;
  }
  try {
    dispatch(actions.busy.add('PRINT_ITEM_LABELS'));
    const printLabelResp = await printOrderItemLabels(reqData);
    if (printLabelResp.status !== 200) {
      toastResponseError(printLabelResp, 'Unable To Print Label');
      return {
        error: 'Unable to Print Label',
        success: false,
      };
    }
    const images = printLabelResp.payload.images;
    if (
      selectedPrinter[0].printer_id === 'OFFLINE_PDF_PRINTER' &&
      images.length > 0
    ) {
      let imageUrl = images[0];
      if (images.length > 1) {
        imageUrl = await mergePdfFiles(images);
      }
      FileSaver.saveAs(imageUrl, 'Part_Labels.pdf');
    }
    toastMessage(images.length + ' labels printed');
    return {
      error: images.length + ' labels printed',
      success: true,
    };
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(actions.busy.remove('PRINT_ITEM_LABELS'));
  }
};
export const OfflineScanRecordCardBase = memo(({ offlineScan }) => {
  return (
    <Fragment>
      <div className={'track-trace-part-name'}>{offlineScan.scan_code}</div>
      <div className={'d-flex '}>
        <div className={'track-trace-scanned-on'}>
          {moment(offlineScan.scanned_on).format('DD MMM hh:mm a')}
        </div>
        <div className={`track-trace-scan-status error`}>
          <span>.</span>
          <span className={`warning`}>OFFLINE</span>
        </div>
        <div className='track-trace-card-status-icons'>
          <div
            className={`status-icon cursor-pointer ${
              !offlineScan.is_reject ? 'selected success' : ''
            }`}
          >
            <TrackTraceSuccessIcon />
          </div>
          <div
            className={`status-icon ${
              offlineScan.is_reject ? 'selected error' : ''
            }`}
            onClick={() => {}}
          >
            <TrackTraceRejectIcon />
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export const ScanRecordImageCarousel = memo(({ images, name }) => {
  const [showImageListModal, setShowImageListModal] = useState({
    show: false,
    images: [],
  });
  const getImage = useCallback(image => {
    return image;
  }, []);
  return (
    <Fragment>
      <Carousel
        className='cursor-pointer'
        controls={false}
        onClick={() => {
          const displayImages = images.map(val => {
            return {
              data: getImage(val),
            };
          });
          setShowImageListModal({
            show: true,
            images: displayImages,
          });
        }}
      >
        {images.map((image, index) => {
          return (
            <Carousel.Item key={index}>
              <img
                className={'d-block w-100'}
                alt={name}
                src={getImage(image)}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
      {showImageListModal.show && (
        <ImageListModal
          images={showImageListModal.images}
          onCloseCb={() => {
            setShowImageListModal({ images: [], show: false });
          }}
        />
      )}
    </Fragment>
  );
});
ScanRecordImageCarousel.propTypes = {
  images: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};
export const ScanRecordImageLarge = memo(
  ({
    scanRecord,
    showOrderItemDetailCB,
    setScanStateCb,
    scannedItems,
    appendScannedItemsCb,
    onScanWrapper,
    syncCb,
    currentStation,
  }) => {
    const { list_view_mode } = useSelector(state => state.tracktrace);
    const [images, setImages] = useState(scanRecord.images || []);
    useEffect(() => {
      setImages(scanRecord.images || []);
    }, [scanRecord, setImages]);

    return (
      <Fragment>
        <div className='pt-2'>
          <div className={'pt-2'}>
            <div style={{ minHeight: '200px' }}>
              <ScanRecordImageCarousel
                images={images}
                name={scanRecord.code || ''}
              />
            </div>
            <div className='track-trace-view-image-large-content-top track-trace-view-image-large-content-top-bg'></div>
            <div className='track-trace-view-image-large-content-top'>
              <ScanRecordCardBase
                scanRecord={scanRecord}
                showOrderItemDetailCB={showOrderItemDetailCB}
                setScanStateCb={setScanStateCb}
                invert={true}
                scannedItems={scannedItems}
                appendScannedItemsCb={appendScannedItemsCb}
                onScanWrapper={onScanWrapper}
                syncCb={syncCb}
                currentStation={currentStation}
              />
              <ScanRecordCncOperations
                scanRecord={scanRecord}
                invert={true}
                setScanStateCb={setScanStateCb}
                scannedItems={scannedItems}
                appendScannedItemsCb={appendScannedItemsCb}
                current_station={currentStation}
              />
            </div>
            {list_view_mode === false &&
              scanRecord &&
              scanRecord.order_item && (
                <div className='pt-2'>
                  <OrderItemFieldDetail order_item={scanRecord.order_item} />
                </div>
              )}
          </div>
        </div>
      </Fragment>
    );
  },
);
ScanRecordImageLarge.propTypes = {
  scanRecord: PropTypes.object,
  showOrderItemDetailCB: PropTypes.func.isRequired,
  scannedItems: PropTypes.array.isRequired,
  appendScannedItemsCb: PropTypes.func.isRequired,
};
export const OrderItemFieldDetail = memo(({ order_item, boxIndex }) => {
  const [fields, setFields] = useState(
    getOrderItemSelectedFieldValues(order_item, boxIndex),
  );
  const { item_field_config } = useSelector(state => state.tracktrace);

  useEffect(() => {
    setFields(getOrderItemSelectedFieldValues(order_item, boxIndex));
  }, [order_item, item_field_config, boxIndex]);
  return (
    <Fragment>
      <div className='track-trace-item-field-detail'>
        {fields.map(val => {
          const displayVal = '' + val.val;
          return (
            <div key={val.key} className='d-flex'>
              <div>{val.label ? val.label : val.key}</div>
              <div className='pe-2'>: </div>
              <div>{displayVal}</div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
});
export const ScanRecordImageSmall = memo(
  ({
    scanRecord,
    showOrderItemDetailCB,
    setScanStateCb,
    scannedItems,
    appendScannedItemsCb,
    onScanWrapper,
    currentStation,
    syncCb,
  }) => {
    const { list_view_mode } = useSelector(state => state.tracktrace);

    const [images, setImages] = useState(scanRecord.images || []);
    useEffect(() => {
      setImages(scanRecord.images || []);
    }, [scanRecord, setImages]);
    //eslint-disable-next-line
    const [isMobile, isTab] = useMobile();
    return (
      <Fragment>
        <div className='pt-2 position-relative'>
          {isTab === false && (
            <ScanRecordCardBase
              scanRecord={scanRecord}
              showOrderItemDetailCB={showOrderItemDetailCB}
              setScanStateCb={setScanStateCb}
              scannedItems={scannedItems}
              appendScannedItemsCb={appendScannedItemsCb}
              onScanWrapper={onScanWrapper}
              syncCb={syncCb}
              currentStation={currentStation}
            />
          )}
          <ScanRecordCncOperations
            scanRecord={scanRecord}
            setScanStateCb={setScanStateCb}
            scannedItems={scannedItems}
            appendScannedItemsCb={appendScannedItemsCb}
            currentStation={currentStation}
          />
          <div className={'pt-4'}>
            <ScanRecordImageCarousel
              images={images}
              name={scanRecord.code || ''}
            />
          </div>
        </div>
        {list_view_mode !== true && scanRecord && scanRecord.order_item && (
          <div className='pt-2'>
            <OrderItemFieldDetail order_item={scanRecord.order_item} />
          </div>
        )}
      </Fragment>
    );
  },
);
ScanRecordImageSmall.propTypes = {
  scanRecord: PropTypes.object,
  showOrderItemDetailCB: PropTypes.func.isRequired,
  scannedItems: PropTypes.array.isRequired,
  appendScannedItemsCb: PropTypes.func.isRequired,
};
/* eslint-disable sonarjs/cognitive-complexity */
export const ScanRecordCardBase = memo(
  ({
    scanRecord,
    invert,
    showOrderItemDetailCB,
    setScanStateCb,
    scannedItems,
    appendScannedItemsCb,
    onScanWrapper,
    currentStation,
    syncCb,
  }) => {
    const { current_station } = useSelector(state => state.stationstate);
    const { printers } = useSelector(state => state.connectedPrinters);
    const dispatch = useDispatch();

    const getSuccessBackground = useCallback(() => {
      if (scanRecord && scanRecord.status === 'SUCCESS') {
        return 'selected success';
      }
      return '';
    }, [scanRecord]);

    const getRejectBackground = useCallback(() => {
      if (scanRecord && scanRecord.status === 'REJECTED') {
        return 'selected error';
      }
      return '';
    }, [scanRecord]);

    const hasImage = useCallback(() => {
      return scanRecord && scanRecord.images && scanRecord.images.length > 0;
    }, [scanRecord]);

    const [scanCode, setScanCode] = useState(
      getScanCodeFromScanRecord(scanRecord),
    );

    useEffect(() => {
      setScanCode(getScanCodeFromScanRecord(scanRecord));
    }, [scanRecord]);

    const [itemName, setItemName] = useState(scanCode);

    useEffect(() => {
      if (
        scanRecord &&
        scanRecord.order_item &&
        !isSerializedItem(scanRecord.order_item)
      ) {
        setItemName(scanRecord.order_item.item_name);
      } else {
        setItemName(scanCode);
      }
    }, [scanCode, scanRecord]);

    const [scannedOn, setScannedOn] = useState(null);

    useEffect(() => {
      if (!scanRecord) {
        setScannedOn(null);
        return;
      }

      let _scannedOn = scanRecord.scanned_on;

      if (scanRecord.uuid.startsWith('dummy')) {
        let updatedOn = moment(scanRecord.scanned_on);

        for (let operation in scanRecord.operation_status) {
          const operationStatus = scanRecord.operation_status[`${operation}`];

          if (operationStatus && operationStatus.processed_on) {
            let processedOn = moment(operationStatus.processed_on);

            if (processedOn.isAfter(updatedOn)) {
              updatedOn = processedOn;
              _scannedOn = operationStatus.processed_on;
            }
          }
        }
      }

      setScannedOn(_scannedOn);
    }, [scanRecord]);

    return (
      <Fragment>
        <div className='track-trace-part-name'>{itemName}</div>
        <div className='d-flex'>
          <div className='track-trace-scanned-on'>
            {moment(scannedOn).format('DD MMM hh:mm a')}
          </div>
          <div
            className={`track-trace-scan-status ${
              scanRecord.status !== 'SUCCESS' ? 'error' : ''
            }`}
          >
            <span>.</span>
            <span
              className={
                scanRecord.status === 'PENDING' &&
                scanRecord.order_item.operation_checklist.filter(
                  val => !val.skip_operation,
                ).length === 0
                  ? 'success'
                  : scanRecord.status === 'PENDING'
                  ? 'warning'
                  : ''
              }
            >
              {scanRecord.status}
            </span>
          </div>
          <div className='track-trace-card-status-icons'>
            <div
              className={`status-icon cursor-pointer ${getSuccessBackground()}`}
              onClick={() =>
                onScanWrapper &&
                onScanWrapper(scanRecord.order_item.code, true, false, false)
              }
            >
              <TrackTraceSuccessIcon />
            </div>
            <div
              className={`status-icon ${getRejectBackground()}`}
              onClick={() =>
                onScanWrapper &&
                onScanWrapper(scanRecord.order_item.code, true, false, true)
              }
            >
              <TrackTraceRejectIcon />
            </div>
          </div>
        </div>
        <div className='pt-2'>
          <div className='d-flex'>
            <div className='pe-3'>
              <TrackTraceItemImageIcon disabled={!hasImage()} invert={invert} />
            </div>
            {scanRecord.manual_entry ? (
              <div className='pe-3'>
                <TrackTraceManualScanIcon invert={invert} />
              </div>
            ) : (
              <div className='pe-3'>
                <TrackTraceScanIcon invert={invert} />
              </div>
            )}
            <div className='pe-3'>
              {current_station && current_station.enable_item_print ? (
                <div
                  onClick={() =>
                    handlePrinting(printers, dispatch, [
                      { uuid: scanRecord.order_item.uuid, selected: true },
                    ])
                  }
                >
                  <TrackTracePrintIcon
                    fill='#006EED'
                    error={scanRecord.scan_status !== 'SUCCESS'}
                    invert={invert}
                  />
                </div>
              ) : (
                <TrackTraceHistoryIcon
                  error={scanRecord.scan_status !== 'SUCCESS'}
                  invert={invert}
                />
              )}
            </div>
            <div className='pe-4'>
              <div
                className={`track-trace-checklist-pending-wrp ${
                  scanRecord.order_item &&
                  scanRecord.order_item.operation_pending > 0
                    ? 'error'
                    : scanRecord.order_item &&
                      scanRecord.order_item.operation_checklist &&
                      scanRecord.order_item.operation_checklist.length > 0
                    ? 'warning'
                    : ''
                }`}
              >
                {scanRecord.order_item &&
                scanRecord.order_item.operation_pending
                  ? scanRecord.order_item.operation_pending
                  : 0}
              </div>
            </div>
            <div className='pe-3'>
              <TrackTraceItemDetailIcon
                onClick={e => showOrderItemDetailCB(scanRecord.order_item)}
                invert={invert}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  },
);

/* eslint-enable sonarjs/cognitive-complexity */
ScanRecordCardBase.propTypes = {
  scanRecord: PropTypes.object,
  showOrderItemDetailCB: PropTypes.func.isRequired,
  scannedItems: PropTypes.array.isRequired,
  appendScannedItemsCb: PropTypes.func.isRequired,
};
/* eslint-disable sonarjs/cognitive-complexity */
const ScanRecordCncOperations = memo(
  ({
    scanRecord,
    invert,
    setScanStateCb,
    scannedItems,
    appendScannedItemsCb,
    current_station,
  }) => {
    const dispatch = useDispatch();
    const { rejectmode } = useSelector(state => state.stationstate);
    const [drillStatus, setDrillStatus] = useState(
      getOrderItemDrillsStatus(scanRecord),
    );
    useEffect(() => {
      setDrillStatus(getOrderItemDrillsStatus(scanRecord));
    }, [scanRecord]);
    const scanItem = useCallback(
      async drill => {
        if (rejectmode === true) {
          toastError('Scan to Reject');
          return;
        }
        const drillField = drill.toLowerCase();
        if (
          !drillStatus[`${drillField}`] ||
          drillStatus[`${drillField}`].status === 'SUCCESS'
        ) {
          return;
        }
        const scanCode = drillStatus[`${drillField}`].scan_code;
        if (!scanCode || scanCode === '') {
          return;
        }
        if (setScanStateCb) {
          setScanStateCb(null);
        }
        const resp = await trackTraceScan(
          scanCode,
          scanRecord.manual_entry,
          { rejected: false },
          [],
          true,
          dispatch,
          scannedItems,
          appendScannedItemsCb,
        );
        if (resp.challenge === true) {
          return;
        }
        let scanState = '';
        if (resp.error) {
          toastError(resp.error);
          scanState = 'error';
        } else if (resp.success) {
          toastSuccess(resp.message);
          scanState = 'success';
        }
        if (setScanStateCb) {
          setScanStateCb(scanState);
        }
        //await syncOrderItems(dispatch);
        //syncOrderItemStatus(dispatch);
      },
      [
        dispatch,
        scanRecord,
        rejectmode,
        drillStatus,
        setScanStateCb,
        scannedItems,
        appendScannedItemsCb,
      ],
    );
    if (!current_station || current_station.station_code !== 'DRILLING') {
      return <Fragment></Fragment>;
    }
    if (
      current_station.operations.indexOf('TOP_DRILL') === -1 &&
      current_station.operations.indexOf('BOTTOM_DRILL') === -1
    ) {
      return <Fragment></Fragment>;
    }
    if (!drillStatus.top_drill.exists && !drillStatus.bottom_drill.status) {
      return <Fragment></Fragment>;
    }

    return (
      <Fragment>
        <div className='d-flex pt-2'>
          <div className='flex-1'>
            {drillStatus.top_drill.exists === true && (
              <div
                onClick={e => scanItem('TOP_DRILL')}
                className={`track-trace-drill-button ${
                  drillStatus.top_drill.status === 'SUCCESS'
                    ? 'completed'
                    : 'pending'
                } ${invert ? 'inverted' : ''}`}
              >
                <div className='status-icon'>
                  <TrackTraceDrillDoneIcon />
                </div>
                <div>Top Drill</div>
              </div>
            )}
          </div>
          <div className='flex-1'>
            {drillStatus.bottom_drill.exists === true && (
              <div
                onClick={e => scanItem('BOTTOM_DRILL')}
                className={`track-trace-drill-button ${
                  drillStatus.bottom_drill.status === 'SUCCESS'
                    ? 'completed'
                    : 'pending'
                } ${invert ? 'inverted' : ''}`}
              >
                <div className='status-icon'>
                  <TrackTraceDrillDoneIcon />
                </div>
                <div>Bottom Drill</div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  },
);
/* eslint-enable sonarjs/cognitive-complexity */
ScanRecordCncOperations.propTypes = {
  scanRecord: PropTypes.object,
  scannedItems: PropTypes.array.isRequired,
  appendScannedItemsCb: PropTypes.func.isRequired,
};

export const TrackTraceOfflineCard = memo(
  ({ offlineScan, dispatch, onRetry }) => {
    return (
      <Fragment>
        <div
          className={`position-relative track-trace-card-wrp warning-background-color`}
        >
          <OfflineScanRecordCardBase offlineScan={offlineScan} />
          <div className='track-trace-item-field-detail pt-2'>
            <div className='d-flex'>
              <div>BRANCH</div>
              <div className='pe-2'>: </div>
              <div>{offlineScan.branch_name}</div>
            </div>
          </div>
          <div className='track-trace-item-field-detail '>
            <div className='d-flex'>
              <div>ORDER</div>
              <div className='pe-2'>: </div>
              <div>{offlineScan.order_ref_code}</div>
            </div>
          </div>
          {offlineScan.match_type === 'BOX' && offlineScan.box_index && (
            <div className='track-trace-item-field-detail '>
              <div className='d-flex'>
                <div>BOX</div>
                <div className='pe-2'>: </div>
                <div>{offlineScan.box_index}</div>
              </div>
            </div>
          )}
          {offlineScan.ctx_matched === false && (
            <div className='track-trace-item-field-detail '>
              <div className='d-flex'>
                <div
                  className={`${
                    offlineScan.allow_ctx_mismatch ? 'success' : 'error'
                  }`}
                >
                  This Does not belong to the Selected Context
                </div>
                <div
                  className='btn btn-link '
                  style={{ padding: '0px !important' }}
                  onClick={() => {
                    const _offlineScan = cloneDeep(offlineScan);
                    _offlineScan.allow_ctx_mismatch =
                      !offlineScan.allow_ctx_mismatch;
                    dispatch(addOfflineScan(_offlineScan));
                  }}
                >
                  {offlineScan.allow_ctx_mismatch ? 'Block' : 'Allow'}
                </div>
              </div>
            </div>
          )}
          <div className='track-trace-item-field-detail '>
            <div className='d-flex error'>
              {offlineScan.err_msg ? offlineScan.err_msg : ''}
            </div>
          </div>
          <div className='d-flex'>
            <div className='flex-1'>
              <div
                className='btn btn-link'
                onClick={() => {
                  dispatch(removeOfflineScan(offlineScan.scan_code));
                }}
              >
                Remove
              </div>
            </div>
            {offlineScan.retry_allowed === undefined ||
              (offlineScan.retry_allowed === true && (
                <div className='flex-1'>
                  <div
                    className='btn btn-link'
                    onClick={() => onRetry(offlineScan)}
                  >
                    Retry
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Fragment>
    );
  },
);

export const TrackTraceOfflineCards = ({ dispatch, offlineScanRetry }) => {
  const { image_view_mode } = useSelector(state => state.tracktrace);
  const [cardWrpClass, setCardWrpClass] = useState('');

  useEffect(() => {
    setCardWrpClass(getTrackTraceCardsWrpClass(image_view_mode));
  }, [setCardWrpClass, image_view_mode]);
  const { org } = useSelector(state => state.user);
  const { offline_scans } = useSelector(state => state.tracktrace);
  const [filteredScans, setFilteredScans] = useState([]);
  useEffect(() => {
    setFilteredScans(offline_scans.filter(val => val.org_id === org.uuid));
  }, [offline_scans, org]);
  return (
    <Fragment>
      {filteredScans.map(scan => {
        return (
          <div
            className={cardWrpClass}
            key={scan.scan_code}
            style={{ minWidth: '280px' }}
          >
            <TrackTraceOfflineCard
              offlineScan={scan}
              dispatch={dispatch}
              onRetry={offlineScanRetry}
            />{' '}
          </div>
        );
      })}
    </Fragment>
  );
};

/* eslint-disable sonarjs/cognitive-complexity */
export const TrackTraceCard = memo(
  ({
    scanRecord,
    showOrderItemDetailCB,
    setScanStateCb,
    scannedItems,
    appendScannedItemsCb,
    onScanWrapper,
    showDetailExternal,
    externallySelectedClass,
    currentStation,
    syncCb,
  }) => {
    const dispatch = useDispatch();
    const { selected_record, image_view_mode, list_view_mode } = useSelector(
      state => state.tracktrace,
    );
    const [selected, setSelected] = useState(
      selected_record && selected_record === scanRecord.uuid,
    );
    //eslint-disable-next-line
    const [isMobile, isTab] = useMobile();
    const [showDetail, setShowDetail] = useState(false);
    const [selectedStateClass, setSelectedStateClass] = useState('');
    useEffect(() => {
      let scanState = '';
      if (selected) {
        switch (scanRecord.status) {
          case 'SUCCESS':
            scanState = 'success';
            break;
          case 'REJECTED':
            scanState = 'error';
            break;
          case 'NO_OPERATION':
            scanState = 'warn';
            break;
          default:
            scanState = '';
        }
      }
      setSelectedStateClass(getScanStateClass(scanState));
    }, [selected, selected_record, scanRecord]);
    useEffect(() => {
      setSelected(selected_record && selected_record === scanRecord.uuid);
    }, [scanRecord, selected_record, setSelected]);
    useEffect(() => {
      const fn = async () => {
        const imageLinks = await getScannedRecordImageLinks(
          scanRecord,
          dispatch,
        );
        if (
          !scanRecord.images ||
          scanRecord.images.length !== imageLinks.length ||
          !isEqual(scanRecord.images, imageLinks)
        ) {
          const _scanRecord = cloneDeep(scanRecord);
          _scanRecord.images = imageLinks;
          appendScannedItemsCb([_scanRecord]);
          //dispatch(appendScannedItems([_scanRecord]));
        }
      };
      if (selected_record && scanRecord.uuid === selected_record) {
        fn();
      }
    }, [dispatch, scanRecord, selected_record, appendScannedItemsCb]);
    useEffect(() => {
      if (image_view_mode === 'SMALL' || image_view_mode === 'LARGE') {
        setShowDetail(false);
      } else if (list_view_mode === true) {
        setShowDetail(false);
      } else {
        setShowDetail(true);
      }
    }, [setShowDetail, image_view_mode, list_view_mode]);
    const onCardClick = useCallback(() => {
      if (scanRecord) {
        dispatch(setSelectedRecord(scanRecord.uuid));
      }
    }, [dispatch, scanRecord]);
    return (
      <Fragment>
        <div
          className={`position-relative track-trace-card-wrp  ${
            externallySelectedClass !== undefined
              ? externallySelectedClass
              : selected
              ? 'selected'
              : ''
          } ${selectedStateClass}`}
          style={{ animationDuration: '1s' }}
          onClick={onCardClick}
        >
          <ScanRecordCardBase
            scanRecord={scanRecord}
            showOrderItemDetailCB={showOrderItemDetailCB}
            setScanStateCb={setScanStateCb}
            scannedItems={scannedItems}
            appendScannedItemsCb={appendScannedItemsCb}
            onScanWrapper={onScanWrapper}
            syncCb={syncCb}
          />
          {isTab &&
            (image_view_mode === 'SMALL' || image_view_mode === 'LARGE') && (
              <ScanRecordImageSmall
                scanRecord={scanRecord}
                showOrderItemDetailCB={showOrderItemDetailCB}
                scannedItems={scannedItems}
                appendScannedItemsCb={appendScannedItemsCb}
              />
            )}
          {(showDetail === true || showDetailExternal) && (
            <div className='pt-3'>
              <OrderItemFieldDetail order_item={scanRecord.order_item} />
            </div>
          )}
        </div>
      </Fragment>
    );
  },
);
/* eslint-enable sonarjs/cognitive-complexity */
TrackTraceCard.propTypes = {
  scanRecord: PropTypes.object.isRequired,
  showOrderItemDetailCB: PropTypes.func.isRequired,
  scannedItems: PropTypes.array.isRequired,
  appendScannedItemsCb: PropTypes.func,
};

export const TrackTraceCards = ({
  scanned_items,
  showOrderItemDetailCB,
  setScanStateCb,
  scannedItems,
  appendScannedItemsCb,
  onScanWrapper,
  syncCb,
}) => {
  const { image_view_mode } = useSelector(state => state.tracktrace);
  const [cardWrpClass, setCardWrpClass] = useState('');

  useEffect(() => {
    setCardWrpClass(getTrackTraceCardsWrpClass(image_view_mode));
  }, [setCardWrpClass, image_view_mode]);

  return (
    <Fragment>
      {scanned_items &&
        scanned_items.map(val => {
          return (
            <div
              className={cardWrpClass}
              key={val.uuid}
              style={{ minWidth: '280px' }}
            >
              <TrackTraceCard
                scanRecord={val}
                showOrderItemDetailCB={showOrderItemDetailCB}
                setScanStateCb={setScanStateCb}
                scannedItems={scannedItems}
                appendScannedItemsCb={appendScannedItemsCb}
                onScanWrapper={onScanWrapper}
                syncCb={syncCb}
              />
            </div>
          );
        })}
    </Fragment>
  );
};
TrackTraceCards.propTypes = {
  scanned_items: PropTypes.array.isRequired,
  showOrderItemDetailCB: PropTypes.func.isRequired,
  scannedItems: PropTypes.array.isRequired,
  appendScannedItemsCb: PropTypes.func,
};
const TrackTraceContainer = ({
  scannedItems,
  showOrderItemDetailCB,
  setScanStateCb,
  appendScannedItemsCb,
  onScanWrapper,
  syncCb,
  pipelineMode,
  offlineScanRetry,
}) => {
  const dispatch = useDispatch();
  //const history = useHistory();
  //const location = useLocation();
  //const busy = useSelector(state => state.busy);
  const { current_station } = useSelector(state => state.stationstate);
  const [filteredScannedItems, setFilteredScannedItems] = useState([]);
  const [filteredScannedItemsTop, setFilteredScannedItemsTop] = useState([]);
  const { image_view_mode, group_by } = useSelector(state => state.tracktrace);
  const { selected_record } = useSelector(state => state.tracktrace);
  const [selectedRecord, setSelectedRecord] = useState(
    scannedItems.find(val => val.uuid === selected_record),
  );

  const [containerClass, setContainerClass] = useState(
    getTrackTraceContainerClass(image_view_mode),
  );

  useEffect(() => {
    setContainerClass(getTrackTraceContainerClass(image_view_mode));
  }, [setContainerClass, image_view_mode]);

  useEffect(() => {
    const _selectedRecord = scannedItems.find(
      val => val.uuid === selected_record,
    );
    setSelectedRecord(_selectedRecord);
  }, [scannedItems, setSelectedRecord, selected_record]);
  useEffect(() => {
    const _filteredScannedItems = scannedItems.filter(
      val =>
        (hasCncOperation(val.order_item, current_station) || pipelineMode) &&
        (val.ctx_item !== true ||
          val.status === 'SUCCESS' ||
          pipelineMode === true ||
          group_by !== null),
    );
    setFilteredScannedItems(_filteredScannedItems);
  }, [
    scannedItems,
    group_by,
    current_station,
    setFilteredScannedItems,
    pipelineMode,
  ]);
  useEffect(() => {
    if (pipelineMode) {
      setFilteredScannedItemsTop(filteredScannedItems);
      return;
    }
    if (filteredScannedItems.length > 5) {
      const sliced = filteredScannedItems.slice(0, 5);
      setFilteredScannedItemsTop(sliced);
    } else {
      setFilteredScannedItemsTop(filteredScannedItems);
    }
  }, [pipelineMode, filteredScannedItems, setFilteredScannedItemsTop]);
  //eslint-disable-next-line
  const [isMobile, isTab] = useMobile();

  return (
    <Fragment>
      <div className={'row'}>
        <div className={`col-12 col-sm-12 ${containerClass}`}>
          <div className={'row'}>
            {current_station && current_station.allow_offline_scan && (
              <TrackTraceOfflineCards
                dispatch={dispatch}
                offlineScanRetry={offlineScanRetry}
              />
            )}
            {group_by === null && (
              <TrackTraceCards
                scanned_items={filteredScannedItemsTop}
                showOrderItemDetailCB={showOrderItemDetailCB}
                setScanStateCb={setScanStateCb}
                scannedItems={scannedItems}
                appendScannedItemsCb={appendScannedItemsCb}
                onScanWrapper={onScanWrapper}
                syncCb={syncCb}
              />
            )}
            {group_by !== null && (
              <TrackTraceGroupCards
                scanned_items={filteredScannedItems}
                scannedItems={scannedItems}
                showOrderItemDetailCB={showOrderItemDetailCB}
                setScanStateCb={setScanStateCb}
                appendScannedItemsCb={appendScannedItemsCb}
                syncCb={syncCb}
              />
            )}
          </div>
        </div>
        {!isTab &&
          selectedRecord &&
          image_view_mode &&
          image_view_mode === 'SMALL' && (
            <div className={'col-4 d-hidden d-md-inline col-md-6 col-lg-4'}>
              <ScanRecordImageSmall
                scanRecord={selectedRecord}
                showOrderItemDetailCB={showOrderItemDetailCB}
                setScanStateCb={setScanStateCb}
                scannedItems={scannedItems}
                appendScannedItemsCb={appendScannedItemsCb}
                onScanWrapper={onScanWrapper}
                currentStation={current_station}
                syncCb={syncCb}
              />
            </div>
          )}
        {!isTab &&
          selectedRecord &&
          image_view_mode &&
          image_view_mode === 'LARGE' && (
            <div className={'col-md-6 col-lg-6 d-lg-inline position-relative'}>
              <ScanRecordImageLarge
                scanRecord={selectedRecord}
                showOrderItemDetailCB={showOrderItemDetailCB}
                setScanStateCb={setScanStateCb}
                scannedItems={scannedItems}
                appendScannedItemsCb={appendScannedItemsCb}
                onScanWrapper={onScanWrapper}
                syncCb={syncCb}
              />
            </div>
          )}
      </div>
    </Fragment>
  );
};
TrackTraceContainer.propTypes = {
  showOrderItemDetailCB: PropTypes.func.isRequired,
};

export default TrackTraceContainer;
