/* eslint-disable sonarjs/cognitive-complexity */
import { closeBox, openBox } from '../../store/packing/boxAndItems/actions';
import { addUuid, popUuid } from '../../store/packing/scan/actions';
import {
  addBoxItem,
  addBox,
  addBoxAsync,
  addBoxSync,
  addItem,
} from '../../store/packing/itemslist/actions';
import {
  getAssemblyParentBox,
  getAssemblyParentUUID,
  isBoxItem,
  isSerializedItem,
} from './itemsHelper';
import { toast } from 'react-toastify';
import { ToastMessage } from '@naadi/framework';
import React from 'react';
import { cloneDeep } from 'lodash';
import {
  createScanRecord,
  delinkItemFromBox,
  fetchReqUuid,
  getItemsWithFilter,
  linkItemToBox,
} from '../../services/order';
import { getState } from '../../store';
import {
  syncOfflineOrderBoxesUpdates,
  syncOfflineOrderBoxItemUpdates,
  syncPackingBox,
  syncPackingBoxById,
  syncPackingBoxItems,
} from './packingOfflineSync';
import {
  getBoxesWithFilter,
  getBoxItemsWithFilter,
  getPrintedBoxResult,
  reopenDeletedBox,
} from '../../services/packing';
import { ResponseCodes } from '@naadi/framework';

export const SCAN_VALUE_TYPE = {
  HARDWARE: 'HARDWARE',
  ORDER_ITEM: 'ORDER_ITEM',
  ORDER_ITEM_BOX: 'ORDER_ITEM_BOX',
  BOX: 'BOX',
  NOT_FOUND: 'NOT_FOUND',
};

export const toastWarning = message => {
  toastMessage({
    type: 'warn',
    message: message,
    autoClose: 5000,
  });
};
export const toastError = message => {
  toastMessage({
    type: 'error',
    message: message,
    autoClose: 5000,
  });
};
export const toastSuccess = message => {
  toastMessage({
    type: 'success',
    message: message,
    autoClose: 5000,
  });
};

export const toastMessage = val => {
  if (val.message === undefined || val.type === undefined) {
    return;
  }
  const options = {
    position: 'bottom-center',
    autoClose: val.autoClose ? val.autoClose : 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  toast.error(
    <ToastMessage message={val.message} type={val.type}></ToastMessage>,
    options,
  );
};
export const refreshUuid = async dispatch => {
  const { uuidList } = getState().scan;
  if (uuidList.length < 1000) {
    const uuidReqResult = await fetchReqUuid(200);
    if (uuidReqResult.status === 200) {
      dispatch(addUuid(uuidReqResult.payload));
    }
  }
};
export const getUuid = dispatch => {
  const { uuidList } = getState().scan;
  const newUUID = uuidList[0];

  dispatch(popUuid(newUUID));
  if (uuidList.length < 1000) {
    fetchReqUuid(200).then(result => {
      if (result.status === 200) {
        dispatch(addUuid(result.payload));
      }
    });
  }
  return newUUID;
};
export const addScanCodeWhenNotFound = async (scanValue, options, dispatch) => {
  if (options.category && options.category.value && options.selection) {
    const filter = {
      req_payload: {
        deleted: [false],
        released: [true],
        scan_code: scanValue,
      },
    };
    if (options.category.value === 'ORDER' && options.selection.uuid) {
      filter.req_payload.order_id = [options.selection.uuid];
    }
    const itemListResp = await getItemsWithFilter(filter, 0, 10);
    if (itemListResp.status !== 200 || itemListResp.payload.length === 0) {
      return false;
    }
    const _selection = getState().selection;

    /* eslint-disable sonarjs/no-collapsible-if */
    if (
      _selection &&
      _selection.category &&
      _selection.category.value === options.category.value
    ) {
      if (
        options.category.value === 'ORDER' &&
        _selection.selection.uuid === options.selection.uuid
      ) {
        dispatch(addItem(itemListResp.payload[0]));
        return true;
      }
    }
    /* eslint-enable sonarjs/no-collapsible-if */
  }
  return false;
};
export const findScanCode = (scanValue, options) => {
  const itemsList = options.itemsList;
  const notFound = {
    TYPE: SCAN_VALUE_TYPE.NOT_FOUND,
    MATCH: null,
  };
  if (!scanValue) return notFound;
  if (scanValue.length <= 0) return notFound;
  const matchBox = itemsList.fetchBoxes.find(
    box => box.code !== null && box.code === scanValue.toUpperCase(),
  );
  if (matchBox !== null && matchBox !== undefined) {
    return {
      TYPE: SCAN_VALUE_TYPE.BOX,
      MATCH: matchBox,
    };
  }
  const matchItemBox = itemsList.boxesItems.find(
    item => item.code.toLowerCase() === scanValue.toLowerCase(),
  );
  if (matchItemBox != null) {
    return {
      TYPE: SCAN_VALUE_TYPE.ORDER_ITEM_BOX,
      MATCH: matchItemBox,
    };
  }

  const matchItem = itemsList.items.find(
    item => item.code.toLowerCase() === scanValue.toLowerCase(),
  );
  if (matchItem != null) {
    return {
      TYPE: SCAN_VALUE_TYPE.ORDER_ITEM,
      MATCH: matchItem,
    };
  }
  const scanAlternateMatch = itemsList.items.find(
    item =>
      item.scan_alternates &&
      item.scan_alternates.length > 0 &&
      item.scan_alternates.filter(
        val => val && val.toLowerCase() === scanValue.toLowerCase(),
      ).length > 0,
  );
  if (scanAlternateMatch != null) {
    return {
      TYPE: SCAN_VALUE_TYPE.ORDER_ITEM,
      MATCH: scanAlternateMatch,
    };
  }
  const filteredItemMatch = itemsList.items.filter(
    item =>
      !isSerializedItem(item) &&
      item?.item_definition?.parents?.indexOf('HARDWARE') >= 0 &&
      (item?.item_definition?.code === scanValue ||
        item?.item_definition?.code === 'HARDWARE::' + scanValue ||
        item?.item_definition?.short_code === scanValue),
  );
  if (filteredItemMatch.length > 0) {
    return {
      TYPE: SCAN_VALUE_TYPE.HARDWARE,
      MATCHES: filteredItemMatch,
    };
  }
  return notFound;
};

const createItemBoxWhenNotFound = async (item, dispatch) => {
  const { itemslist, selection } = getState();
  const itemsList = itemslist;
  const packingCategory = selection.category;
  if (!isBoxItem(item)) return null;
  const boxItem = getState().itemslist.fetchBoxes.find(
    val => val.code === item.code,
  );
  if (!boxItem) {
    const newUUID = getUuid(dispatch);
    const boxIndex = getBoxIndex(itemsList.fetchBoxes);
    const box = {
      uuid: newUUID,
      code: item.code,
      org_id: selection.selection.org_id,
      packing_context: packingCategory.value,
      packing_context_ref: selection.selection.uuid,
      package_type: 'BOX',
      print_status: 'PENDING',
      box_index: boxIndex,
      strict_inventory: false,
      editable: true,
      approved: true,
      deleted: false,
      active: true,
      synced: false,
      rev: 0,
      isNew: true,
    };
    return await addBoxSync(box, dispatch);
    //dispatch(addBox(box));
  } else if (boxItem.deleted === true) {
    const _box = cloneDeep(boxItem);
    _box.deleted = false;
    _box.synced = false;
    return await addBoxSync(_box, dispatch);
    //dispatch(addBox(_box));
  } else {
    return boxItem;
  }
};

const openBoxForBoxItem = async (item, options) => {
  const { itemslist } = getState();
  const itemsList = itemslist;
  const dispatch = options.dispatch;
  const boxItem = getAssemblyParentBox(item, itemsList);
  if (boxItem === null) {
    return null;
  }
  let box = await createItemBoxWhenNotFound(boxItem, dispatch);
  if (!box) {
    return {
      type: 'error',
      message: 'Unable to find a matching box',
    };
  }
  const canPack = canItemPackInBox(item, box, 1);
  if (canPack.type !== 'success') {
    const alternateBoxItems = itemsList.boxesItems.filter(
      val => val.item_id === boxItem.item_id && val.uuid !== boxItem.uuid,
    );
    box = null;
    alternateBoxItems.forEach(val => {
      if (box !== null) {
        return;
      }
      const _box = createItemBoxWhenNotFound(val, dispatch);
      if (!_box) {
        throw Error('Unable to Create a Box');
      }
      const canPack = canItemPackInBox(item, _box, 1);
      if (canPack.type === 'success') {
        box = _box;
      }
    });
  }
  return box;
};

export const isItemBox = (box, preparedData) => {
  let returnVal = true;
  if (preparedData && preparedData.itemBoxMap) {
    if (preparedData.itemBoxMap[`${box.code}`]) {
      returnVal = true;
    } else {
      returnVal = false;
    }
  } else {
    const { itemslist } = getState();
    const boxesItems = itemslist.boxesItems;
    const itemBox = boxesItems.find(val => val.code === box.code);
    if (!itemBox || itemBox === null || itemBox === undefined) {
      returnVal = false;
    }
  }
  return returnVal;
};
export const isEmptyBox = box => {
  const { itemslist } = getState();
  const fetchBoxItems = itemslist.fetchBoxItems;
  const itemBox = fetchBoxItems.find(val => val.box.uuid === box.uuid);
  return itemBox ? false : true;
};
const isOrderItemPresentInBox = (item, box) => {
  const { itemslist } = getState();
  const itemsList = itemslist;
  const boxItems = itemsList.fetchBoxItems.filter(val => {
    let returnVal = false;
    if (val.deleted) {
      returnVal = false;
    } else {
      returnVal =
        val.box.uuid === box.uuid &&
        val.box_item_id === item.uuid &&
        val.box_item_type === 'ORDER';
    }
    return returnVal;
  });

  return boxItems !== undefined && boxItems.length > 0 ? true : false;
};
const ITEM_ADDED_TO_BOX_MESSAGE = 'Item Added to the Box';
const canItemPackInItemBox = (item, box, qty) => {
  const { itemslist } = getState();
  const itemsList = itemslist;
  const boxItems = itemsList.fetchBoxItems.filter(val => {
    if (val.deleted) return false;
    return val.box.uuid === box.uuid;
  });

  let packedItemQty = 0;
  for (let i = 0; i < boxItems.length; i++) {
    const _box = boxItems[parseInt(i)];
    if (_box.deleted === true) continue;
    const orderItem = itemsList.items.find(
      val => val.uuid === _box.box_item_id,
    );
    if (orderItem.item_id === item.item_id) {
      packedItemQty += _box.qty;
    }
  }
  const boxItem = itemsList.boxesItems.find(val => box.code === val.code);
  if (boxItem === undefined || boxItem === null) {
    return new Error('Unable to Find an Open Box');
  }
  const filteredItems = itemsList.items.filter(val => {
    const assemblyParentId = getAssemblyParentUUID(val);
    return (
      val.deleted === false &&
      assemblyParentId &&
      assemblyParentId === boxItem.uuid &&
      val.item_id === item.item_id
    );
  });
  const totalItemQty = filteredItems
    .map(val => val.qty)
    .reduce((prev, next) => {
      return prev + next;
    }, 0);
  const newPackedQty = packedItemQty + qty;

  if (newPackedQty < totalItemQty) {
    return {
      type: 'success',
      packedQty: packedItemQty,
      totalItemQty: totalItemQty,
      message: newPackedQty + ' of ' + totalItemQty + ' items packed',
    };
  } else if (newPackedQty === totalItemQty) {
    return {
      type: 'success',
      packedQty: packedItemQty,
      totalItemQty: totalItemQty,
      message: ITEM_ADDED_TO_BOX_MESSAGE,
    };
  }
  let message = 'Item qty Exceeds the Total Qty allowed';
  if (totalItemQty > 1) {
    message += ' by ' + (newPackedQty - totalItemQty);
  }
  if (isSerializedItem(item)) {
    message = 'Item already exists in the Box';
  }
  return {
    type: 'error',
    message: message,
    packedQty: packedItemQty,
    totalItemQty: totalItemQty,
  };
};

const canItemPackInPackingGroup = (item, box, qty, preparedData) => {
  if (!isSerializedItem(item)) {
    //debugger;
  }

  if (!preparedData) {
    preparedData = {};
  }
  const currentBoxPackedItems = preparedData.currentBoxPackedItems
    ? preparedData.currentBoxPackedItems
    : [];
  //const packedItems = [];
  const packedItemBoxMap = preparedData.packedItemBoxMap
    ? preparedData.packedItemBoxMap
    : {};
  if (!preparedData.currentBoxPackedItems) {
    getState().itemslist.fetchBoxItems.forEach(val => {
      if (val.deleted === true) return;
      if (val.box.uuid === box.uuid) {
        currentBoxPackedItems.push(val);
      }
      //packedItems.push(val);
      if (!packedItemBoxMap[val.box_item_id + '']) {
        packedItemBoxMap[val.box_item_id + ''] = {
          packedQty: 0,
          totalItemQty: 0,
          boxItems: [],
        };
      }
      packedItemBoxMap[val.box_item_id + ''].packedQty += val.qty;
      packedItemBoxMap[val.box_item_id + ''].boxItems.push(val);
    });
    getState().itemslist.items.forEach(val => {
      if (packedItemBoxMap[val.uuid + '']) {
        packedItemBoxMap[val.uuid + ''].totalItemQty += val.qty;
      }
    });
  }

  const boxNo = getOrderItemBoxNo(item);
  if (boxNo > 0 && box.box_index !== boxNo) {
    return {
      type: 'error',
      packedQty: 0,
      totalItemQty: 1,
      message: 'This Item Should be Packed in Box ' + boxNo,
    };
  }
  if (currentBoxPackedItems.length === 0 && !packedItemBoxMap[item.uuid + '']) {
    return {
      type: 'success',
      packedQty: 0,
      totalItemQty: 1,
      message: ITEM_ADDED_TO_BOX_MESSAGE,
    };
  }
  if (currentBoxPackedItems.length === 0 && packedItemBoxMap[item.uuid + '']) {
    const packedQty = packedItemBoxMap[item.uuid + ''].packedQty;
    const totalItemQty = packedItemBoxMap[item.uuid + ''].totalItemQty;
    if (totalItemQty - packedQty - qty >= 0) {
      return {
        type: 'success',
        packedQty: packedQty,
        totalItemQty: totalItemQty,
        message: ITEM_ADDED_TO_BOX_MESSAGE,
      };
    }
  }
  if (packedItemBoxMap[item.uuid + '']) {
    const packedQty = packedItemBoxMap[item.uuid + ''].packedQty;
    const totalItemQty = packedItemBoxMap[item.uuid + ''].totalItemQty;
    const itemPackedInCurrentBox = currentBoxPackedItems
      .filter(val => val.box_item_id === item.uuid)
      .map(val => val.qty)
      .reduce((total, num) => {
        return total + num;
      }, 0);

    if (totalItemQty - packedQty + itemPackedInCurrentBox <= 0) {
      const boxItem = packedItemBoxMap[item.uuid + ''].boxItems[0];
      const boxNumber = boxItem ? boxItem.box.box_index : '';
      return {
        type: 'error',
        packedQty: packedQty,
        totalItemQty: totalItemQty,
        message: 'Item Already Packed in Box ' + boxNumber,
      };
    }
  }
  const packedItemIds = currentBoxPackedItems.map(val => val.box_item_id);
  const currentBoxItems = preparedData.currentBoxItems
    ? preparedData.currentBoxItems
    : getState().itemslist.items.filter(val => {
        let returnVal = false;
        if (val.deleted === true || isBoxItem(val)) {
          returnVal = false;
        } else if (packedItemIds.indexOf(val.uuid) >= 0) {
          returnVal = true;
        }
        return returnVal;
      });
  let packingGroup = null;
  let packingGroupFound = false;
  let noPackingGroupItems = 0;
  let packedItemQty = 0;
  let totalItemQty = 0;
  const itemPackingGroup = !item.packing_group ? '' : item.packing_group;
  const itemsInPackingGroup = [];
  currentBoxItems.forEach(val => {
    const _itemPackingGroup = !val.packing_group ? '' : val.packing_group;
    if (_itemPackingGroup === '') {
      noPackingGroupItems++;
      return;
    }
    if (!packingGroup) {
      packingGroup = _itemPackingGroup;
    }
    if (_itemPackingGroup === itemPackingGroup) {
      if (item.uuid === val.uuid) {
        totalItemQty += val.qty;
        itemsInPackingGroup.push(val);
      }
      packingGroupFound = true;
      return;
    }
    if (item.allowed_packing_groups.indexOf(_itemPackingGroup) >= 0) {
      if (item.uuid === val.uuid) {
        totalItemQty += val.qty;
        itemsInPackingGroup.push(val);
      }
      packingGroupFound = true;
    }
  });
  itemsInPackingGroup.forEach(val => {
    const packedItem = packedItemBoxMap[val.uuid + ''];
    if (packedItem) {
      packedItemQty += packedItem.packedQty;
    }
  });
  if (currentBoxPackedItems.length === 0) {
    const packedItem = packedItemBoxMap[item.uuid + ''];
    if (packedItem) {
      packedItemQty += packedItem.packedQty;
    }
    packingGroupFound = true;
  }
  if (packingGroupFound) {
    return {
      type: 'success',
      packedQty: packedItemQty,
      totalItemQty: totalItemQty,
      message: ITEM_ADDED_TO_BOX_MESSAGE,
      packedItemBoxMap: packedItemBoxMap,
    };
  }
  if (noPackingGroupItems > 0 && noPackingGroupItems === currentBoxItems) {
    return {
      type: 'success',
      packedQty: currentBoxItems.length,
      totalItemQty: currentBoxItems.length + 1,
      message: ITEM_ADDED_TO_BOX_MESSAGE,
    };
  }

  const errMessage = packingGroup
    ? 'Item Does not belong to Packing Group ' + packingGroup
    : 'Item Does not Belong to the Box';
  return {
    type: 'error',
    message: errMessage,
    packedQty: packedItemQty,
    totalItemQty: packedItemQty,
  };
};

export const canItemPackInBox = (item, box, qty, preparedData) => {
  if (isItemBox(box, preparedData)) {
    return canItemPackInItemBox(item, box, qty);
  }
  return canItemPackInPackingGroup(item, box, qty, preparedData);
};
export const getPackedQtyExcludingBox = (item, box) => {
  const { itemslist } = getState();
  const itemsList = itemslist;

  return itemsList.fetchBoxItems
    .filter(
      val =>
        val.deleted === false &&
        val.box_item_id === item.uuid &&
        box.uuid !== val.box.uuid,
    )
    .map(val => val.qty)
    .reduce((prev, current) => prev + current, 0);
};

const getBoxIndex = boxes => {
  const indexes = [];
  boxes.forEach(box => {
    if (box.deleted === false) {
      indexes.push(box.box_index);
    }
  });
  indexes.sort();
  let index = 1;
  for (let i = 0; i < indexes.length; i++) {
    if (indexes.indexOf(index) === -1) {
      return index;
    }
    index++;
  }
  while (indexes.indexOf(index) >= 0) {
    index++;
  }
  return index;
};
const getNewBoxIndex = () => {
  const { itemslist } = getState();
  return getBoxIndex(itemslist.fetchBoxes);
};
export const openEmptyBox = async (code, orgId, packingType, dispatch) => {
  const { user, selection, boxAndItems, itemslist } = getState();
  if (!user || !user.org) return null;
  if (!selection.selection) return null;
  if (boxAndItems.openedBoxId) return null;

  const newUUID = getUuid(dispatch);
  if (!newUUID) {
    console.log('UUID List exhausted..');
    return null;
  }

  const newBox = {
    uuid: newUUID,
    code: code ? code : newUUID,
    org_id: orgId ? orgId : user.org.org_id,
    packing_context: selection.category.value,
    packing_context_ref: selection.selection.uuid,
    package_type: packingType,
    print_status: 'PENDING',
    box_index: getBoxIndex(itemslist.fetchBoxes),
    strict_inventory: false,
    editable: true,
    approved: true,
    deleted: false,
    active: true,
    isNew: true,
  };
  const box = await addBoxSync(newBox, dispatch);
  //dispatch(addBox(newBox, true));
  dispatch(
    openBox({
      uuid: box.uuid,
      code: box.code,
      isEditable: box.editable,
    }),
  );
  return box;
};
const openBoxIfNotOpen = async (scanMatch, options) => {
  const { itemslist, scan, selection } = getState();
  const itemsList = itemslist;
  const uuidList = scan.uuidList;
  const dispatch = options.dispatch;
  switch (scanMatch.TYPE) {
    case SCAN_VALUE_TYPE.BOX:
      const boxMatch = scanMatch.MATCH;
      dispatch(
        openBox({
          uuid: boxMatch.uuid,
          code: boxMatch.code,
          isEditable: boxMatch.editable,
        }),
      );
      return boxMatch;
    case SCAN_VALUE_TYPE.ORDER_ITEM_BOX:
      const orderItemBoxMatch = scanMatch.MATCH;
      const box = itemsList.fetchBoxes.find(
        item => item.code === orderItemBoxMatch.code,
      );
      if (uuidList.length === 0) {
        return null;
      }

      if (box === undefined) {
        return await openEmptyBox(
          orderItemBoxMatch.code,
          orderItemBoxMatch.org_id,
          'BOX',
          dispatch,
        );
      }
      return null;
    case SCAN_VALUE_TYPE.ORDER_ITEM:
      const boxForItem = await openBoxForBoxItem(scanMatch.MATCH, {
        dispatch: dispatch,
      });
      if (boxForItem !== null) {
        dispatch(
          openBox({
            uuid: boxForItem.uuid,
            code: boxForItem.code,
            isEditable: boxForItem.editable,
          }),
        );
        return boxForItem;
      }
      const findMatchingBox = val => {
        let returnVal = false;
        try {
          const canPack = canItemPackInBox(scanMatch.MATCH, val, 1);
          if (canPack.type === 'error') {
            returnVal = false;
          } else {
            returnVal = true;
          }
          return returnVal;
        } catch (err) {
          console.log(err);
        }
        return returnVal;
      };
      const boxNo = getOrderItemBoxNo(scanMatch.MATCH);
      let matchBox = getState()
        .itemslist.fetchBoxes.filter(
          val =>
            val.editable === true &&
            val.deleted === false &&
            !isItemBox(val) &&
            (isEmptyBox(val) || (val.box_index === boxNo && boxNo > 0)),
        )
        .find(findMatchingBox);
      if (!matchBox) {
        matchBox = getState()
          .itemslist.fetchBoxes.filter(
            val =>
              val.deleted === true &&
              !isItemBox(val) &&
              (val.print_status === 'PENDING' ||
                val.print_status === 'REPACKING'),
          )
          .find(findMatchingBox);
        if (matchBox) {
          matchBox = cloneDeep(matchBox);
          matchBox.deleted = false;
          matchBox.editable = true;
          matchBox.synced = false;
          if (boxNo > 0) {
            matchBox.box_index = boxNo;
          }
          const updatedBox = await addBoxSync(matchBox, dispatch);
          matchBox.updated_on = updatedBox.updated_on;
          matchBox.rev = updatedBox.rev;
          matchBox.synced = true;
          matchBox.editable = updatedBox.editable;
          matchBox.box_index = updatedBox.box_index;
          if (matchBox.print_status === 'PENDING') {
            matchBox.box_index =
              boxNo > 0 ? boxNo : getBoxIndex(itemsList.fetchBoxes);
          }
          dispatch(addBox(matchBox, false));
        }
      }
      if (!matchBox) {
        const findLinkedBox = itemsList.fetchBoxItems.find(
          val =>
            val.deleted === false && val.box_item_id === scanMatch.MATCH.uuid,
        );
        if (findLinkedBox) {
          matchBox = itemsList.fetchBoxes.find(
            val => val.uuid === findLinkedBox.box.uuid,
          );
        }
      }
      if (matchBox) {
        dispatch(
          openBox({
            uuid: matchBox.uuid,
            code: matchBox.code,
            isEditable: matchBox.editable,
          }),
        );
        return matchBox;
      }

      const newUUID = getUuid(dispatch);
      if (!newUUID) {
        // console.log('UUID List exhausted');
        return null;
      }
      const _newBox = {
        uuid: newUUID,
        code: newUUID,
        org_id: selection.selection.org_id,
        packing_context: selection.category.value,
        packing_context_ref: selection.selection.uuid,
        package_type: 'BOX',
        print_status: 'PENDING',
        box_index:
          boxNo && boxNo > 0 ? boxNo : getBoxIndex(itemsList.fetchBoxes),
        strict_inventory: false,
        editable: true,
        approved: true,
        deleted: false,
        active: true,
        isNew: true,
        synced: false,
      };
      //dispatch(addBox(newBox));
      const newBox = await addBoxSync(_newBox, dispatch);
      dispatch(
        openBox({
          uuid: newBox.uuid,
          code: newBox.code,
          isEditable: newBox.editable,
        }),
      );
      return newBox;
    default:
      return null;
  }
};

export const checkOrOpenBox = async (scanMatch, qty, options) => {
  const { itemslist, boxAndItems } = getState();
  const itemsList = itemslist;
  const dispatch = options.dispatch;
  if (scanMatch.TYPE === SCAN_VALUE_TYPE.NOT_FOUND) {
    return null;
  }
  let openedBoxId = boxAndItems.openedBoxId;
  if (openedBoxId !== null) {
    const box = itemsList.fetchBoxes.find(val => val.uuid === openedBoxId);
    if (!box || box.deleted) {
      dispatch(closeBox());
      openedBoxId = null;
    }
  }
  if (openedBoxId === null) {
    return await openBoxIfNotOpen(scanMatch, {
      dispatch: dispatch,
    });
  } else {
    switch (scanMatch.TYPE) {
      case SCAN_VALUE_TYPE.BOX:
        if (scanMatch.MATCH.uuid === boxAndItems.openedBoxId) {
          return scanMatch.MATCH;
        }
        break;
      case SCAN_VALUE_TYPE.ORDER_ITEM_BOX:
        if (scanMatch.MATCH.code === boxAndItems.openedBoxCode) {
          const matchBox = itemsList.fetchBoxes.find(
            val => val.code === scanMatch.MATCH,
          );
          if (matchBox === undefined) {
            return null;
          }
          return matchBox;
        }
        break;
      case SCAN_VALUE_TYPE.ORDER_ITEM:
        const box = itemsList.fetchBoxes.find(
          val => val.uuid === boxAndItems.openedBoxId,
        );
        if (box === undefined || box === null) {
          throw new Error('Open A Box Before Packing');
        }
        return box;
      default:
    }
  }
  return null;
};

export const addItemToBox = async (scanMatch, qty, dispatch) => {
  switch (scanMatch.TYPE) {
    case SCAN_VALUE_TYPE.ORDER_ITEM:
      try {
        const options = {
          dispatch: dispatch,
          box: scanMatch.BOX,
          scanCode: scanMatch.scanCode,
          manualEntry: scanMatch.manualEntry,
        };
        const addItemResp = await addOrderItemToBox(
          scanMatch.MATCH,
          qty,
          options,
        );
        toastMessage(addItemResp);
        return addItemResp.type;
      } catch (err) {
        console.log(err);
        toastError(err.toString());
        return 'error';
      }
    case SCAN_VALUE_TYPE.ORDER_ITEM_BOX:
    default:
      return 'warn';
  }
};
export const addOrderItemToBox = async (item, qty, options) => {
  const { user, itemslist, boxAndItems, selection } = getState();
  const itemsList = itemslist;
  const dispatch = options.dispatch;
  const packingCategory = selection.category;
  const scanCode = options.scan_code;
  const openedBoxId = boxAndItems.openedBoxId;
  const manualEntry = options.manualEntry === true;
  if (packingCategory.value !== 'ORDER') {
    throw new Error('Packing Context Should be Order');
  } else if (selection.selection === null) {
    throw new Error('Order Not Selected');
  } else if (openedBoxId === null) {
    throw new Error('Box Should be Opened before adding an Item');
  }
  let box = itemsList.fetchBoxes.find(val => val.uuid === openedBoxId);
  if (box === undefined) {
    throw new Error('Box Should be Opened before adding an Item');
  }
  if (isSerializedItem(item) && isOrderItemPresentInBox(item, box)) {
    return {
      type: 'warn',
      message: 'Item Already Present in the this Box',
    };
  }
  const canPack = canItemPackInBox(item, box, qty);
  if (!canPack) {
    return {
      type: 'error',
      message: 'Item Already Present in the Box',
    };
  } else if (canPack.type === 'error') {
    return canPack;
  }
  let boxItem = itemsList.fetchBoxItems.find(
    val =>
      val.box_item_id === item.uuid &&
      val.deleted === false &&
      val.box.uuid === openedBoxId,
  );

  if (boxItem === undefined) {
    const uuid = getUuid(dispatch);
    boxItem = {
      uuid: uuid,
      box_id: openedBoxId,
      box_item_type: packingCategory.value,
      box_item_id: item.uuid,
      qty: qty,
      acknowledged: true,
      org_id: user.org.uuid,
      active: true,
      deleted: false,
      approved: true,
      box: box,
    };
  } else {
    boxItem = cloneDeep(boxItem);
    boxItem.deleted = false;
    boxItem.qty = qty;
  }
  let scanUUID = getUuid(dispatch);
  const currentStation = getState().selection?.current_station || {};
  boxItem.pod = currentStation.uuid || 'packing';
  boxItem.pod_name = currentStation.instance_name || 'packing';
  boxItem.scan_station = 'packing';
  boxItem.scan_id = scanUUID;
  boxItem.manual_entry = manualEntry;
  boxItem.scan_code = scanCode;
  const boxResp = await linkBoxToItem(boxItem, box, dispatch);
  if (!boxResp || boxResp === null || boxResp.uuid === undefined) {
    console.log('box response is null or uuid does not match');
    console.log(boxResp);
    return {
      type: 'error',
      message:
        boxResp.err && boxResp.err.err
          ? boxResp.err.err
          : 'Unable to Sync the Box Item with Backend',
    };
  }
  if (boxResp && boxResp.uuid) {
    boxItem.uuid = boxResp.uuid;
  }
  if (boxResp && boxResp.scan_record_id) {
    scanUUID = boxResp.scan_record_id;
  }
  if (boxResp && boxResp.deleted) {
    boxItem.deleted = boxResp.deleted;
  }
  dispatch(
    addBoxItem({
      scan_station: 'packing',
      scan_id: scanUUID,
      pod: currentStation.uuid || 'packing',
      pod_name: currentStation.instance_name || 'packing',
      manual_entry: manualEntry,
      boxItem: boxItem,
      scan_code: scanCode,
      user_id: user.user_id,
      skipPosting: true,
      synced: true,
    }),
  );

  if (boxResp.already_linked === true) {
    return {
      type: 'warn',
      message: 'Item was already present in the Box',
    };
  }
  return {
    type: 'success',
    message: 'Item added to Box',
  };
};
const fetchBoxAsync = async box => {
  const filter = {
    req_payload: {
      org_id: box.org_id,
      uuid: [box.uuid],
    },
  };
  return await getBoxesWithFilter(filter);
};
const fetchBoxItem = async boxItem => {
  const filter = {
    req_payload: {
      org_id: boxItem.org_id,
      box_id: [boxItem.box.uuid],
      box_item_id: [boxItem.box_item_id],
      deleted: [false],
    },
  };
  return await getBoxItemsWithFilter(filter);
};
export const postScanRecord = async (
  history,
  boxItem,
  box,
  packingContext,
  packingContextRef,
) => {
  if (
    history.scan_id === undefined ||
    history.scan_id === null ||
    !history.scan_id
  ) {
    return {
      status: 200,
      payload: {},
    };
  }
  if (history.scan_code === undefined) {
    history.scan_code = boxItem.code;
  }
  const reqData = {
    user_id: history.user_id,
    org_id: boxItem.org_id,
    req_payload: {
      scan_station: history.scan_station,
      scan_id: history.scan_id,
      scan_code: history.scan_code,
      pod: history.pod,
      pod_name: history.pod_name,
      manual_entry: history.manual_entry,
      scan_status: history.scan_status,
      scan_item_id: boxItem.box_item_id,
      scan_context_type: packingContext,
      scan_context_1: packingContextRef,
      scan_context_2: box.uuid,
      scan_context_3: boxItem.uuid,
      scan_context_4: 'BOX_ITEM',
      acknowledged: history.acknowledged,
      scanned_on: history.scanned_on,
      ref_date: new Date(),
    },
  };
  const scanRecordResp = await createScanRecord(reqData);
  if (scanRecordResp.status !== 200) {
    console.log(scanRecordResp);
    throw new Error('Scan Record Request Failed');
  }
  return scanRecordResp;
};
export const getBoxItemByUuid = async boxItem => {
  const filter = {
    req_payload: {
      org_id: boxItem.org_id,
      uuid: [boxItem.uuid],
    },
  };
  const boxItemResp = await getBoxItemsWithFilter(filter);
  if (boxItemResp.status === ResponseCodes.SUCCESS) {
    return boxItemResp.payload;
  }
  return null;
};
export const reopenDeletedBoxIfNeeded = async (box, dispatch) => {
  //Assuming tha the Box would not be deleted from multiple places
  if (box.deleted === false && box.id > 0) {
    return;
  }
  const boxResp = await fetchBoxAsync(box);
  if (boxResp.status === ResponseCodes.SUCCESS && boxResp.payload.length > 0) {
    const _box = boxResp.payload[0];
    if (_box.deleted === true) {
      _box.index = getNewBoxIndex();
      const reopenBoxResp = await reopenDeletedBox(_box);
      if (reopenBoxResp.status !== 200) {
        console.log(reopenBoxResp);
        throw new Error('Unable to Open The Box..Try Again..');
      }
      _box.synced = true;
      await addBoxSync(_box, dispatch);
    }
  }
};
export const linkBoxToItem = async (boxItem, box, dispatch) => {
  let scanCode = boxItem.scan_code;
  if (boxItem.scan_id === undefined) {
    boxItem.scan_id = getUuid(dispatch);
  }
  if (boxItem.scan_code === undefined || boxItem.scan_code === null) {
    scanCode = '';
  }
  const reqData = {
    approved: true,
    active: true,
    deleted: false,
    user_id: boxItem.updated_by,
    org_id: boxItem.org_id,
    scan_station: boxItem.scan_station || 'packing',
    pod: boxItem.pod || 'packing',
    pod_name: boxItem.pod_name || 'packing',
    scan_id: boxItem.scan_id,
    scan_code: scanCode,
    manual_entry: boxItem.manual_entry,
    req_payload: {
      uuid: boxItem.uuid,
      org_id: boxItem.org_id,
      acknowledged: true,
    },
  };
  const linkReqPayload = {
    uuid: boxItem.uuid,
    box_id: box.uuid,
    box_item_type: boxItem.box_item_type,
    box_item_id: boxItem.box_item_id,
    qty: boxItem.qty,
    acknowledged: boxItem.acknowledged,
    scan_record: {},
  };
  if (boxItem.deleted === false) {
    await reopenDeletedBoxIfNeeded(box, dispatch);
    reqData.req_payload = linkReqPayload;
    let linkResp = await linkItemToBox(reqData);
    if (linkResp.status === ResponseCodes.RECORD_NOT_FOUND_EXCEPTION) {
      await reopenDeletedBoxIfNeeded(box, dispatch);
      linkResp = await linkItemToBox(reqData);
    }
    if (linkResp.status === 200) {
      return linkResp.payload;
    } else if (linkResp.status === ResponseCodes.RECORD_EXISTS_EXCEPTION) {
      const boxItemResp = await fetchBoxItem(boxItem);
      if (
        boxItemResp.status === ResponseCodes.SUCCESS &&
        boxItemResp.payload.length > 0
      ) {
        const _boxItem = boxItemResp.payload.find(val => val.deleted === false);
        if (_boxItem !== undefined) {
          return _boxItem;
        }
        reqData.scan_id = getUuid(dispatch);
        const linkResp2 = await linkItemToBox(reqData);
        if (linkResp2.status === ResponseCodes.SUCCESS) {
          return linkResp2.payload;
        } else {
          console.log(linkResp2);
        }
      }
      console.log(linkResp);
      const errMsg =
        linkResp.err && linkResp.err.err
          ? linkResp.err.err
          : 'Unable to Link the Item To the Box';
      throw new Error(errMsg);
    } else if (linkResp.status === ResponseCodes.RECORD_NOT_FOUND_EXCEPTION) {
      const boxResp = await fetchBoxAsync(box);
      if (
        boxResp &&
        (boxResp.status === ResponseCodes.RECORD_NOT_FOUND_EXCEPTION ||
          (boxResp.status === ResponseCodes.SUCCESS &&
            boxResp.payload.length === 0))
      ) {
        const _box = cloneDeep(box);
        _box.synced = false;
        dispatch(addBox(_box));
      }
    } else {
      console.log(linkResp);
      throw new Error(
        linkResp.err && linkResp.err.err
          ? linkResp.err.err
          : 'Unhandled Error on Linking a Box',
      );
    }
  } else {
    const delinkResp = await delinkItemFromBox(reqData);
    if (delinkResp.status === ResponseCodes.SUCCESS) {
      return delinkResp.payload;
    } else if (delinkResp.status === ResponseCodes.RECORD_NOT_FOUND_EXCEPTION) {
      const filter = {
        req_payload: {
          org_id: boxItem.org_id,
          uuid: [boxItem.uuid],
          deleted: [false],
        },
      };
      const delinkBoxItemResp = await getBoxItemsWithFilter(filter);
      if (delinkBoxItemResp.status === ResponseCodes.SUCCESS) {
        if (delinkBoxItemResp.payload.length === 0) {
          return {
            NOT_FOUND: true,
            uuid: boxItem.uuid,
            deleted: true,
          };
        } else {
          console.log('Should have got deleted');
          console.log(boxItem);
          console.log(delinkBoxItemResp);
          //throw new Error('Unhandled Error on DeLinking a Box');
        }
      }
      if (box.synced === true && box.deleted === false) {
        filter.req_payload.uuid = [boxItem.box.uuid];
        const delinkBoxResp = await getBoxesWithFilter(filter);
        if (delinkBoxResp.status === ResponseCodes.SUCCESS) {
          if (delinkBoxResp.payload.length > 0) {
            const _box = delinkBoxResp.payload[0];
            if (_box.deleted === true) {
              const __box = cloneDeep(_box);
              __box.synced = false;
              dispatch(addBox(__box));
            }
          } else {
            console.log(box);
          }
        }
      }
    } else {
      //console.log(delinkResp);
      const errMsg =
        delinkResp && delinkResp.err && delinkResp.err.err
          ? delinkResp.err.err
          : 'Unable to Delink the Item from the Box';
      throw new Error(errMsg);
    }
    return null;
  }
};

export const validatePackingSync = async (box, dispatch) => {
  if (!box || box.deleted === true) {
    return {
      type: 'error',
      message: 'Box is Deleted. Cannot Print',
    };
  }
  const synStatus = await syncPackingBoxById(dispatch, box);
  if (synStatus.errors > 0) {
    return {
      type: 'error',
      message:
        synStatus.errorlist.length > 0
          ? synStatus.errorlist[0]
          : 'Box data is not synced properly. Please refresh',
    };
  }
  await syncOfflineOrderBoxesUpdates(dispatch);
  await syncOfflineOrderBoxItemUpdates(dispatch);
  const boxSyncStatus = await syncPackingBox(dispatch);
  const boxItemSyncStatus = await syncPackingBoxItems(dispatch);

  const { itemslist } = getState();
  const _box = itemslist.fetchBoxes.find(val => val.uuid === box.uuid);
  if (_box.synced === false) {
    return {
      type: 'error',
      message: 'Box is Not Synced, Retry after a few seconds',
    };
  }
  const unsyncedItems = itemslist.fetchBoxItems.filter(
    val =>
      val.box.uuid === box.uuid &&
      (val.synced === false || val.history.length > 0),
  );
  if (unsyncedItems.length > 0) {
    return {
      type: 'error',
      message: 'Box Items are yet to be Synced, Retry after a few seconds',
    };
  }
  if (boxItemSyncStatus.errors > 0) {
    console.log(boxItemSyncStatus);
    return {
      type: 'error',
      message: 'All Box Items are Yet to be Synced, Retry after a few seconds',
    };
  }
  if (boxSyncStatus.errors > 0) {
    //console.log(boxSyncStatus);
    return {
      type: 'error',
      message: 'Box is Yet to be Synced, Retry after a few seconds',
    };
  }
  const totalBoxItems = itemslist.fetchBoxItems.filter(
    val => val.box.uuid === box.uuid && val.deleted === false,
  );
  if (totalBoxItems.length === 0) {
    return {
      type: 'error',
      message: 'There are no Items in the Box',
    };
  }
  const similarBoxes = itemslist.fetchBoxes.filter(
    val =>
      val.uuid !== box.uuid &&
      val.deleted === false &&
      val.editable === false &&
      val.box_index === box.box_index,
  );
  if (similarBoxes.length > 0) {
    const boxesWithItems = [];
    getState().itemslist.fetchBoxItems.forEach(val => {
      if (val.deleted === true) return;
      if (boxesWithItems.indexOf(val.box.uuid) >= 0) return;
      boxesWithItems.push(val.box.uuid);
    });
    const boxes = getState().itemslist.fetchBoxes.filter(
      val =>
        val.deleted === false &&
        (val.editable === false ||
          boxesWithItems.indexOf(val.uuid) >= 0 ||
          isItemBox(val)),
    );
    const newIndex = getBoxIndex(boxes);
    const updateBox = cloneDeep(box);
    updateBox.box_index = newIndex;
    updateBox.synced = false;
    dispatch(addBoxAsync(updateBox));
    return {
      type: 'success',
      message: 'Synced',
    };
  }
  return {
    type: 'success',
    message: 'Synced',
  };
};

export const printBox = async (box, dispatch) => {
  const { connectedPrinters } = getState();
  const printer = connectedPrinters.printers.find(val => val.selected === true);
  const printerId = printer ? printer.printer_id : null;
  const printResult = await getPrintedBoxResult(box, printerId);
  if (!printResult) {
    return;
  }
  if (printResult.status !== ResponseCodes.SUCCESS) {
    console.log(printResult);
    if (printResult.err && printResult.err.err) {
      toastError(printResult.err.err);
    } else {
      toastError('Unable to Print the Box Label');
    }
    return;
  }
  const printedBox = printResult.payload;
  let existingBox = getState().itemslist.fetchBoxes.find(
    val => val.uuid === box.uuid,
  );
  if (!existingBox) {
    printedBox.synced = true;
    printedBox.history = [];
    existingBox = printedBox;
  } else {
    existingBox = cloneDeep(existingBox);
  }
  existingBox.rev = printedBox.rev;
  existingBox.deleted = printedBox.deleted;
  existingBox.editable = printedBox.editable;
  existingBox.index = printedBox.index;
  existingBox.synced = true;
  dispatch(addBoxAsync(existingBox));
  const printPayload = printResult.payload;
  if (printedBox.print_status === 'PRINTED') {
    if (printerId === null) {
      toastError('Printer Was not Selected');
    } else if (
      printerId !== null &&
      printPayload &&
      (!printPayload.labels || printPayload.labels.length === 0)
    ) {
      toastError('Label Generation Failed');
    }
  }
  return printResult.payload;
  //console.log('Ready to be printed');
};
export const getOrderItemBoxNo = orderItem => {
  if (!orderItem) {
    return 0;
  }
  if (
    orderItem.item_definition &&
    orderItem.item_definition.definition &&
    orderItem.item_definition.definition.BOX_NO &&
    orderItem.item_definition.definition.BOX_NO.value &&
    orderItem.item_definition.definition.BOX_NO.value.val > 0 &&
    !isNaN(orderItem.item_definition.definition.BOX_NO.value.val)
  ) {
    return orderItem.item_definition.definition.BOX_NO.value.val;
  }
  return 0;
};
export const getOrderItemBoxNos = items => {
  if (!items) {
    return [];
  }
  const boxIndexes = items
    .map(val => {
      if (!val) {
        return null;
      }
      return getOrderItemBoxNo(val);
    })
    .filter(val => val != null && val > 0);
  return boxIndexes.filter((val, pos) => boxIndexes.indexOf(val) === pos);
};
export const createPredefinedBoxNoBoxes =
  (items, boxes, selection, category) => async (dispatch, getState) => {
    if (!category || category.value !== 'ORDER') {
      return;
    }
    const {
      user: { org },
    } = getState();
    const boxNos = getOrderItemBoxNos(items);
    if (boxNos.length === 0) {
      return;
    }
    const boxIndexes = boxes
      .filter(val => val.deleted === false)
      .map(val => val.box_index);
    for (let i = 1; i <= boxNos.length; i++) {
      const boxIndex = boxNos[`${i - 1}`];
      if (boxIndexes.indexOf(boxIndex) >= 0) {
        continue;
      }
      const newUUID = getUuid(dispatch);
      if (!newUUID) {
        console.log('UUID List exhausted');
        return;
      }
      const newBox = {
        uuid: newUUID,
        code: newUUID,
        org_id: org && org.org_id ? org.org_id : '',
        packing_context: category.value,
        packing_context_ref: selection.uuid,
        package_type: 'BOX',
        print_status: 'PENDING',
        box_index: boxIndex,
        strict_inventory: false,
        editable: true,
        approved: true,
        deleted: false,
        active: true,
        isNew: true,
      };
      try {
        await addBoxSync(newBox, dispatch);
      } catch (err) {
        console.log(err);
      }
    }
  };

export const createCurrentSelectionPredefinedBoxNoBoxes =
  () => (dispatch, getState) => {
    const { itemslist, selection } = getState();
    const items = itemslist.items;
    const boxes = itemslist.fetchBoxes;
    dispatch(
      createPredefinedBoxNoBoxes(
        items,
        boxes,
        selection.selection,
        selection.category,
      ),
    );
  };
