import axios from 'axios';
import { actions } from '@naadi/framework';
import { BASE_URL } from '@naadi/framework';
import outerStore, { getState } from '../store';
import { cloneDeep } from 'lodash';
import { WSS_URL } from '@naadi/framework';

export const getWebSocketUrl = ({ token, orgId, login_session_id }) => {
  let wsUrl = `${WSS_URL}/naadi-web-service2/api/websocket/${orgId}?`;
  if (login_session_id) {
    wsUrl += `&login_session_id=${login_session_id}`;
  }
  if (!token || token == null) {
    return wsUrl;
  }

  return `${wsUrl}&access_token=${token ? token : ''}`;
};
const service = ({
  prepended = true,
  headers = {},
  method = 'GET',
  token = null,
  ...rest
}) => {
  let params = {};
  if (prepended) {
    rest.url = BASE_URL + rest.url;
  }
  if (method.toUpperCase() === 'GET' && rest.data) {
    params = { ...params, ...rest.data };
  }
  let { user } = getState();
  token = getState().token;
  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }
  rest.url += objectToParams(params);
  return axios({ ...rest, method, headers })
    .then(response => response.data)
    .catch(err => {
      if (
        (err &&
          err.response &&
          err.response.status === 401 &&
          user &&
          user.authenticated === true) ||
        (err && err.name === 'Error' && err.message === 'Network Error')
      ) {
        const _user = cloneDeep(user);
        _user.authenticated = false;
        outerStore.store.dispatch(actions.user.set(_user));
      }
      return Promise.reject(err);
    });
};

export const objectToParams = o => {
  let s = '';
  for (const k in o) {
    const v = o[k.toString()];
    s += s ? '&' : '?';
    s += k + '=' + encodeURIComponent(v);
  }
  return s;
};

export default service;
