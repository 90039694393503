import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('GROUPS_STATE', type, verb);

export const TYPES = {
  GROUPS_FILTER: 'GROUPS_FILTER',
  GROUPS_ACTIVE_FILTER: 'GROUPS_ACTIVE_FILTER',
  GROUPS_INACTIVE_FILTER: 'GROUPS_INACTIVE_FILTER',
  GROUPS_SHOWMORE_FILTER: 'GROUPS_SHOWMORE_FILTER',
  GROUPS_CREATED_MESSAGE: 'GROUPS_CREATED_MESSAGE',
};

export const setGroupsFilter = createAction(
  getActionName(TYPES.GROUPS_FILTER, 'SET'),
);

export const setGroupsActiveFilter = createAction(
  getActionName(TYPES.GROUPS_ACTIVE_FILTER, 'SET'),
);
export const setGroupsInactiveFilter = createAction(
  getActionName(TYPES.GROUPS_INACTIVE_FILTER, 'SET'),
);
export const setGroupsShowmoreFilter = createAction(
  getActionName(TYPES.GROUPS_SHOWMORE_FILTER, 'SET'),
);
export const setGroupsCreatedmessage = createAction(
  getActionName(TYPES.GROUPS_CREATED_MESSAGE, 'SET'),
);

export const DEFAULT = {
  active: true,
  search_term: '',
  inactive: false,
  showmore: false,
  createdmessage: '',
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setGroupsFilter, (state, { payload }) => {
    return Update(state, {
      search_term: { $set: payload },
    });
  });
  builder.addCase(setGroupsActiveFilter, (state, { payload }) => {
    return Update(state, {
      active: { $set: payload },
    });
  });
  builder.addCase(setGroupsInactiveFilter, (state, { payload }) => {
    return Update(state, {
      inactive: { $set: payload },
    });
  });
  builder.addCase(setGroupsShowmoreFilter, (state, { payload }) => {
    return Update(state, {
      showmore: { $set: payload },
    });
  });
  builder.addCase(setGroupsCreatedmessage, (state, { payload }) => {
    return Update(state, {
      createdmessage: { $set: payload },
    });
  });
});

export default reducer;
