import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrgUserConfig,
  OrgUserConfigTypes,
  updateOrgUserConfig,
} from '../../services/org';
import { toastResponseError } from '../../helpers/common/helper';
import { addScreen } from '../../store/app/screenoverlay/actions';
import { actions, ResponseCodes } from '@naadi/framework';
import { getState } from '../../store';
import {
  setItemDetailHistory,
  setItemFieldConfig,
} from '../../store/tracktrace/tracktrace';
import { TrackTraceItemFieldSelectedIcon } from '../../assets/icons/icon-tracktrace';
import { toastError } from '../../helpers/packing/packingHelper';
import {
  getItemFieldConfig,
  getItemFieldConfigKey,
} from '../../helpers/tracktrace/orderItemHelper';
import { getOrderItemById, getScanRecords } from '../../services/order';
import { AboveMobile } from '../common/only-mobile';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { getAttachmentSignedUrlService } from '../../services/upload';
import ImageListModal from './image-list-modal';
import { TrackTracePrintIconHeader } from '../../assets/icons/icon-tracktrace';
import { handlePrinting } from '../../components/tracktrace/container';
export const OrderItemScanHistory = ({ order_item }) => {
  const dispatch = useDispatch();
  const [history, setHistory] = useState({
    page: 0,
    history: [],
    total_pages: 0,
  });
  const [showImageListModal, setShowImageListModal] = useState({
    show: false,
    images: [],
  });
  const loadScanRecords = useCallback(
    async (order_item, page) => {
      try {
        dispatch(actions.busy.add('LOAD_ORDER_ITEM_SCAN_HISTORY'));
        const scanRecordFilter = {
          order_item_id: [order_item.uuid],
        };
        const scanRecordResp = await getScanRecords({
          page: page,
          size: 200,
          req_payload: scanRecordFilter,
          sort: [
            {
              column: 'updated_on',
              asc: false,
            },
          ],
        });
        if (scanRecordResp.status !== 200) {
          return {
            err: 'Unable to Fetch the History',
          };
        }
        return {
          history: scanRecordResp.payload,
          page: page,
          total_pages: scanRecordResp.total_pages,
        };
      } finally {
        dispatch(actions.busy.remove('LOAD_ORDER_ITEM_SCAN_HISTORY'));
      }
    },
    [dispatch],
  );
  const fetchRef = useRef('');
  useEffect(() => {
    if (!order_item || !order_item.uuid) {
      return;
    }
    const fn = async () => {
      if (fetchRef.current !== '' && fetchRef.current === order_item.uuid) {
        return;
      }
      try {
        fetchRef.current = order_item.uuid;
        const historyResp = await loadScanRecords(order_item, 0);
        if (historyResp.err) {
          toastError(historyResp.err);
          return;
        }
        setHistory(historyResp);
      } finally {
        fetchRef.current = '';
      }
    };
    fn();
  }, [order_item, loadScanRecords, setHistory]);
  const fetchScanImages = useCallback(async scanRecord => {
    if (!scanRecord.images || scanRecord.images.length === 0) {
      return;
    }
    const images = [];
    for (let i = 0; i < scanRecord.images.length; i++) {
      const attachmentId = scanRecord.images[`${i}`];
      const signedResp = await getAttachmentSignedUrlService(attachmentId, 900);
      if (signedResp.status !== ResponseCodes.SUCCESS) {
        toastError('Unable to Fetch the Images');
        return;
      }
      const attachment = signedResp.payload;
      images.push({
        data: attachment.signed_url,
        name: attachment.name,
      });
    }
    setShowImageListModal({
      images: images,
      show: true,
    });
  }, []);
  return (
    <Fragment>
      <div>
        <div className='track-trace-item-detail-body'>
          <AboveMobile>
            <div className='track-trace-item-detail-content-header d-flex'>
              <div className='flex-1'>STATION</div>
              <div className='flex-1'>USER</div>
              <div className='flex-1'>TIME</div>
              <div className='flex-1'>STATUS</div>
              <div className='flex-1'></div>
            </div>
            {history.history.map(row => {
              return (
                <div
                  key={row.id}
                  className='track-trace-item-detail-content-row d-flex'
                >
                  <div className='flex-1'>
                    <div>{row.pod_name || row.pod}</div>
                    <div>{row.location}</div>
                  </div>
                  <div className='flex-1'>{row.user_name}</div>
                  <div className='flex-1'>
                    {moment(row.updated_on).format('DD-MMM-YYYY HH:mm a')}
                  </div>
                  <div className='flex-1'>
                    {row.scan_status}-
                    {row.reject_reason ? row.reject_reason.join(',') : ''}
                  </div>
                  <div className='flex-1' style={{ padding: '0px' }}>
                    {row.images && row.images.length > 0 && (
                      <div
                        className='track-trace-highlighted-link'
                        onClick={() => {
                          fetchScanImages(row);
                        }}
                      >
                        Images ({row.images.length})
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {showImageListModal.show && (
              <ImageListModal
                images={showImageListModal.images}
                onCloseCb={() => {
                  setShowImageListModal({ images: [], show: false });
                }}
              />
            )}
          </AboveMobile>
        </div>
      </div>
    </Fragment>
  );
};
export const OrderItemDetailFields = memo(({ order_item }) => {
  const dispatch = useDispatch();
  const [orderItem, setOrderItem] = useState(order_item);
  const { item_field_config } = useSelector(state => state.tracktrace);
  const [field_config, setFieldConfig] = useState(
    getItemFieldConfig(item_field_config, orderItem),
  );
  useEffect(() => {
    setFieldConfig(getItemFieldConfig(item_field_config, orderItem));
  }, [orderItem, item_field_config, dispatch]);
  useEffect(() => {
    const fn = async () => {
      const orderItemResp = await getOrderItemById(order_item.uuid);
      if (orderItemResp.status !== 200) {
        toastResponseError(
          orderItemResp,
          'Unable to Fetch the Complete Order Item Detail',
        );
        return;
      } else if (orderItemResp.payload.length === 0) {
        return;
      }
      setOrderItem(orderItemResp.payload[0]);
    };
    fn();
  }, [order_item, setOrderItem]);
  const getFieldsList = useCallback(
    order_item => {
      if (!order_item || !order_item.code) {
        return [];
      }
      const rows = [
        {
          key: 'ORDER_CODE',
          label: 'Order',
          value: order_item?.order?.ref_code,
          do_not_select: true,
          selected: false,
        },
        {
          key: '__ITEM_CODE',
          label: 'Item Code',
          value: order_item?.code,
          selected: field_config.display_fields['__ITEM_CODE'] === true,
        },
        {
          key: '__DEFINITION_CODE',
          label: 'Definition Code',
          value: order_item?.item_definition?.code,
          selected: field_config.display_fields['__DEFINITION_CODE'] === true,
        },
        {
          key: '__DEFINITION_NAME',
          label: 'Definition Name',
          value: order_item?.item_definition?.name,
          selected: field_config.display_fields['__DEFINITION_NAME'] === true,
        },
      ];
      const _rows = Object.keys(order_item?.item_definition?.definition).map(
        key => {
          const label = order_item?.item_definition?.definition[`${key}`]?.name;
          const value =
            order_item?.item_definition?.definition[`${key}`]?.value?.val ||
            order_item?.item_definition?.definition[`${key}`]?.val;
          const valType =
            order_item?.item_definition?.definition[`${key}`]?.value_type;
          return {
            key: key,
            label: label,
            value: value,
            type: valType,
            selected: field_config.display_fields[`${key}`] === true,
          };
        },
      );
      _rows.sort((a, b) => {
        return a.key.localeCompare(b.key);
      });
      return [...rows, ..._rows];
    },
    [field_config],
  );
  const [fieldsList, setFieldsList] = useState(getFieldsList(orderItem));
  useEffect(() => {
    setFieldsList(getFieldsList(orderItem));
  }, [getFieldsList, orderItem]);
  const onFieldSelect = useCallback(
    async row => {
      if (row.do_not_select === true) {
        return;
      }
      const configKeyResp = await getOrgUserConfig(
        OrgUserConfigTypes.ITEM_DISPLAY_FIELDS,
        orderItem.item_id,
      );
      if (
        configKeyResp.status !== 200 &&
        configKeyResp.status !== ResponseCodes.RECORD_NOT_FOUND_EXCEPTION
      ) {
        toastError('Unable to Fetch the Item Field Config');
        return;
      }
      const fieldConfig =
        configKeyResp.status === 200
          ? configKeyResp.payload.config_val
          : {
              fields: [],
              display_fields: {},
            };
      if (fieldConfig.display_fields[row.key]) {
        delete fieldConfig.display_fields[row.key];
      } else {
        fieldConfig.display_fields[row.key] = true;
      }
      const updateResp = await updateOrgUserConfig(
        OrgUserConfigTypes.ITEM_DISPLAY_FIELDS,
        orderItem.item_id,
        fieldConfig,
      );
      if (updateResp.status !== 200) {
        toastError('Unable to Update the Config');
        return;
      }
      const key = getItemFieldConfigKey(orderItem);
      dispatch(
        setItemFieldConfig({
          [key]: updateResp.payload.config_val,
        }),
      );
    },
    [dispatch, orderItem],
  );
  const renderFieldRow = useCallback(
    row => {
      if (!row) {
        return <></>;
      }
      let rowVal = row.value;
      if (typeof rowVal === 'object') {
        rowVal = JSON.stringify(rowVal);
      }
      return (
        <div className='track-trace-item-detail-content-row row' key={row.key}>
          <div
            className='track-trace-item-detail-selector'
            onClick={e => onFieldSelect(row)}
          >
            {row.do_not_select !== true && (
              <TrackTraceItemFieldSelectedIcon selected={row.selected} />
            )}
          </div>
          <div className='col-4'>{row.label}</div>
          <div className='col'>{rowVal}</div>
        </div>
      );
    },
    [onFieldSelect],
  );
  return (
    <Fragment>
      <div className='track-trace-item-detail-body'>
        <div className='track-trace-item-detail-content-header row'>
          <div className='track-trace-item-detail-selector'></div>
          <div className='col-4'>ITEM NAME</div>
          <div className='col'>ITEM DETAIL</div>
        </div>
        {fieldsList &&
          fieldsList.map(row => {
            return renderFieldRow(row);
          })}
      </div>
    </Fragment>
  );
});
OrderItemDetailFields.propTypes = {
  order_item: PropTypes.object.isRequired,
};

export const OrderItemDetail = memo(({ order_item }) => {
  const dispatch = useDispatch();
  const { item_detail_history } = useSelector(state => state.tracktrace);
  const { current_station } = useSelector(state => state.stationstate);
  const { printers } = useSelector(state => state.connectedPrinters);
  return (
    <Fragment>
      <div className='track-trace-item-detail-header d-flex'>
        {order_item.code}{' '}
        {current_station && current_station.enable_item_print === true && (
          <div
            onClick={() => {
              handlePrinting(printers, dispatch, [
                { uuid: order_item.uuid, selected: true },
              ]);
            }}
          >
            <TrackTracePrintIconHeader fill='#006EED' />
          </div>
        )}
      </div>
      <div className='d-flex track-trace-item-detail-tab'>
        <div
          className={`flex-1 ${item_detail_history !== true ? 'selected' : ''}`}
          onClick={e => dispatch(setItemDetailHistory(false))}
        >
          <div className='pb-2 cursor-pointer'>DETAILS</div>
        </div>
        <div
          className={`flex-1 cursor-pointer ${
            item_detail_history === true ? 'selected' : ''
          }`}
          onClick={e => dispatch(setItemDetailHistory(true))}
        >
          <div className='pb-2'>SCAN HISTORY</div>
        </div>
      </div>
      {item_detail_history !== true && order_item && (
        <OrderItemDetailFields order_item={order_item} />
      )}
      {item_detail_history === true && order_item && (
        <OrderItemScanHistory order_item={order_item} />
      )}
    </Fragment>
  );
});
OrderItemDetail.propTypes = {
  order_item: PropTypes.object.isRequired,
};
export const OrderItemDetailBase = memo(
  ({ order_item_detail, closeCb, inline }) => {
    const dispatch = useDispatch();
    const [orderItemDetail, setOrderItemDetail] = useState({});

    const showItemDetailOverlay = useCallback(
      orderItem => {
        const overlay = {
          header: 'Item Detail',
          src: 'ORDER_ITEM_DETAIL_BASE',
          screen: <OrderItemDetail order_item={orderItem}></OrderItemDetail>,
          closeCb: () => {
            //dispatch(setOrderItemDetail(null));
            closeCb();
          },
        };
        dispatch(addScreen(overlay));
      },
      [dispatch, closeCb],
    );
    /* eslint-disable sonarjs/cognitive-complexity*/
    useEffect(() => {
      const fn = async orderItemDetail => {
        if (!orderItemDetail || !orderItemDetail.uuid) {
          return;
        }
        if (!orderItemDetail.item_definition || !orderItemDetail.item_id) {
          return;
        }
        if (getState().busy.includes('FETCH_ORDER_ITEM_DETAIL')) {
          return;
        }
        const screens =
          getState().screenoverlay && getState().screenoverlay.screens
            ? getState().screenoverlay.screens
            : [];
        const detailScreen = screens.filter(
          val => val.src === 'ORDER_ITEM_DETAIL_BASE',
        );
        if (detailScreen.length > 0) {
          return;
        }
        try {
          dispatch(actions.busy.add('FETCH_ORDER_ITEM_DETAIL'));
          const configKeyResp = await getOrgUserConfig(
            OrgUserConfigTypes.ITEM_DISPLAY_FIELDS,
            orderItemDetail.item_id,
          );
          const fieldConfig =
            configKeyResp.status === 200
              ? configKeyResp.payload.config_val
              : {
                  fields: [],
                  display_fields: {},
                };
          if (
            configKeyResp.status !== 200 &&
            configKeyResp.status !== ResponseCodes.RECORD_NOT_FOUND_EXCEPTION
          ) {
            toastResponseError(
              configKeyResp,
              'Unable to Fetch the Item Config',
            );
            dispatch(setOrderItemDetail(null));
            return;
          }
          const key = orderItemDetail.org_id + '::' + orderItemDetail.item_id;
          dispatch(
            setItemFieldConfig({
              [key]: fieldConfig,
            }),
          );
          if (orderItemDetail) {
            if (inline === true) {
              setOrderItemDetail(orderItemDetail);
            } else {
              showItemDetailOverlay(orderItemDetail);
            }

            //setOrderItemDetail(orderItemDetail);
          }
        } finally {
          dispatch(actions.busy.remove('FETCH_ORDER_ITEM_DETAIL'));
        }

        //dispatch(setOrderItemDetail(null));
      };
      if (!isEqual(orderItemDetail, order_item_detail)) {
        //setOrderItemDetail(order_item_detail);
        fn(order_item_detail);
      }
    }, [
      dispatch,
      order_item_detail,
      orderItemDetail,
      showItemDetailOverlay,
      inline,
    ]);
    /* eslint-enable */
    return (
      <Fragment>
        {inline === true && (
          <OrderItemDetail order_item={orderItemDetail}></OrderItemDetail>
        )}
      </Fragment>
    );
  },
);

OrderItemDetailBase.propTypes = {
  order_item_detail: PropTypes.object,
  closeCb: PropTypes.func.isRequired,
};
