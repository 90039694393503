import React, { useRef, useState, useEffect } from 'react';
import { toggleSideMenu } from '../../store/app/nav/actions';
import { OrdersIcon } from '../../assets/icons/orders-icon';
import { useDispatch } from 'react-redux';
import OnlyMobile from '../../components/common/only-mobile';
import useParentWidth from '../../hooks/useParentWidth';
import Menu from '../../assets/icons/icon-menu.svg';
import { OrgLogo } from '../../components/org/org-logo';
import useMobile from '../../hooks/useMobile';
import PropTypes from 'prop-types';
import qs from 'qs';
import { CustomizeIcon, UploadIcon } from '../../assets/icons/common-icons';
import { useCallback } from 'react';
const Header = ({ history, title }) => {
  const [isMobile] = useMobile();
  const dispatch = useDispatch();

  const container = useRef();

  const [scrollY, setScrollY] = useState(0);
  //const [showBulkUpload, setShowBulkUpload] = useState(false);

  const handleMenuToggle = () => {
    dispatch(toggleSideMenu());
  };

  useParentWidth(container);

  useEffect(() => {
    const onScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const addSettings = useCallback(() => {
    history.push('/app/orders/progress/customize-stations');
  }, [history]);
  return (
    <div
      className='container-fluid'
      style={{ minHeight: '76px', padding: '0px' }}
    >
      <div
        ref={container}
        className={`width-100-percent header navbar-expand-lg d-flex bg-white p-3 py-4 ${
          scrollY > 150 ? ' sub-header position-fixed' : 'position-absolute'
        }`}
        style={{ zIndex: 10 }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          onClick={handleMenuToggle}
          type='button'
        >
          <img src={Menu} alt='Menu' />
        </button>
        <div className='page-header d-flex align-items-center'>
          <div className='page-title d-flex align-items-center'>
            <OnlyMobile>
              <span className='ps-2'>
                <OrdersIcon />
              </span>
            </OnlyMobile>
            <div
              className='ps-2 align-self-center'
              style={{ whiteSpace: 'nowrap' }}
            >
              {title ? title : 'Orders'}
            </div>
          </div>
        </div>
        <div className='order-header-right w-100 d-flex justify-content-end align-items-center'>
          <div
            className='order-header-upload-wrp d-flex cursor-pointer'
            onClick={() => {
              history.push('/app/orders/bulkupload');
              //setShowBulkUpload(true);
            }}
          >
            <div className='text-primary pe-3'>Import Orders</div>
            <div
              className='pe-3'
              onClick={e => {
                e.stopPropagation();
                const qparams = qs.stringify({ upload: true });
                history.push(`/app/orders/bulkupload?${qparams}`);
              }}
            >
              <UploadIcon />
            </div>
          </div>
          <div
            className='order-header-upload-wrp d-flex cursor-pointer'
            onClick={addSettings}
          >
            <div className='text-primary pt-1 pe-3'>Settings</div>
            <div className='pe-3'>
              <CustomizeIcon fill={'#006EED'} />
            </div>
          </div>
          <div className='order-header-logo-wrp'>
            <div className='d-flex'>{!isMobile && <OrgLogo />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
Header.propTypes = {
  history: PropTypes.object.isRequired,
};
export default Header;
