import service from './index';
import { getState } from '../store';

export const getItemCode = req => {
  const {
    user: { org },
  } = getState();

  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/items/filter`,
    data: req,
  });
};

export const getItemByTerm = (term, has_children) => {
  const {
    user: { org, user_id, branch },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/items/filter`,
    data: {
      user_id,
      page: 0,
      size: 5,
      org_id: org.uuid,
      req_payload: {
        term,
        has_children,
        branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
        deleted: [false],
      },
      sort: [
        {
          column: 'id',
          asc: false,
        },
      ],
    },
  });
};

export const createUploadFormat = req => {
  const {
    user: { org, user_id },
  } = getState();
  req.org_id = org.uuid;
  req.user_id = user_id;
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/items/features/${req.req_payload.org_item_id}/update`,
    data: req,
  });
};
