/* eslint-disable security/detect-object-injection */
import React, { useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import '../../scss/components/_dropdown.scss';
import { useHistory, useLocation } from 'react-router-dom';
import SearchIcon from '../../assets/icons/searchIcon.svg';
import { ToastContainer } from 'react-toastify';
import ScreenLoader from '../../components/common/screen-loader';
import useMobile from '../../hooks/useMobile';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';
import {
  createDownloadFormat,
  createDownloadFormatField,
  fetchDownloadFormatField,
  deleteDownloadFormatField,
} from '../../services/downloadformat';
import { toastError, toastMessage } from '../../helpers/packing/packingHelper';
import DownloadFormatHeader from './downloadheader';
import ConfigureColumn from './configurecolumn';
import { getItemCode } from '../../services/uploadformat';
import { fetchDownloadFormat } from '../../services/downloadformat';
import Select from '../../components/uploadformat/select-upload';
import BlackClose from '../../assets/icons/black-close.svg';
import { cloneDeep } from 'lodash';
import BottomDrawerItems from './bottom-drawer-items';
const Class_RECT = 'rectangle-input';
const Class_RECT_Highlight = 'rectangle-input highlight';
const Class_RECT_Error = 'rectangle-input error';

const ItemsFilter = ({ labelText, itemsList, setItemsList }) => {
  const itemFilter = useCallback(term => {
    return new Promise((resolve, reject) => {
      const itemBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (term) itemBody.req_payload.term = term.toUpperCase();
      getItemCode(itemBody).then(val => {
        if (val.status === 200) {
          resolve(val.payload);
        } else {
          reject([]);
        }
      }, reject);
    });
  }, []);

  return (
    <Select
      label={labelText}
      getOptions={itemFilter}
      labelField='code'
      valueField='uuid'
      onSelect={i => {
        const list = cloneDeep(itemsList);
        list.push(i.code);
        setItemsList(list);
      }}
      //selected={pipelineMode ? null : selection}
      //className='page-size-selector dropdown-table-filter'
      search
    />
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const DownloadAdd = () => {
  const location = useLocation();
  const [formatFocus, setFormatFocus] = useState(false);
  const [formatError, setFormatError] = useState(false);
  const [serialisationFocus, setSerialisationFocus] = useState(false);
  const [serialisationError, setSerialisationError] = useState(false);
  const [headerFocus, setHeaderFocus] = useState(false);
  const [headerError, setHeaderError] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [itemOpen, setItemOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, isTab] = useMobile();
  const [formatName, setFormatName] = useState('');
  const [items, setItems] = useState([]);
  const [itemFeatures, setItemFeatures] = useState([]);
  const [addFeatureClick, setAddFeatureClick] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [fetchFormatVal, setFetchFormatVal] = useState([]);
  const [fetchFieldVal, setFetchFieldVal] = useState([]);
  const [itemsDrawer, setItemsDrawer] = useState([]);
  useEffect(() => {
    const search = location.search;
    const urlSearchParams = new URLSearchParams(search);
    const _params = Object.fromEntries(urlSearchParams.entries());
    setFormatName(_params.name);
    // setParams(_params);
  }, [location, setFormatName]);

  const [form, setForm] = useState({
    format: '',
    split_serialisation: true,
    exclude_header: false,
  });

  const onChange = useCallback(e => {
    const target = e.target;
    setForm(o => ({ ...o, [target.getAttribute('name')]: target.value }));
  }, []);
  const [conform, setConForm] = useState({
    itemname: '',
  });
  const onConFormChange = useCallback(e => {
    const target = e.target;
    setConForm(o => ({ ...o, [target.getAttribute('name')]: target.value }));
  }, []);
  const fetchItems = useCallback(
    async tid => {
      const itemBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (searchText) itemBody.req_payload.term = searchText.toUpperCase();
      try {
        dispatch(actions.busy.add('FETCH_ITEM'));
        const getItems = await getItemCode(itemBody);

        if (getItems && getItems.status !== 200) {
          return;
        }

        setItemsDrawer(getItems.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_ITEM'));
      }
    },
    [dispatch, searchText],
  );
  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const fetchFormat = useCallback(
    async tid => {
      const req = {
        page: 0,
        size: 100,
        req_payload: {
          deleted: [false],
          name: [formatName],
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_FORMAT'));
        const fetchDF = await fetchDownloadFormat(req);
        if (fetchDF && fetchDF.status !== 200) {
          return;
        }

        if (fetchDF && fetchDF.payload.length > 0) {
          setFetchFormatVal(fetchDF.payload[0]);
          setItemsList(cloneDeep(fetchDF.payload[0].items));
          setForm({
            format: fetchDF.payload[0].name,
            split_serialisation: fetchDF.payload[0].split_serialized,
            exclude_header: fetchDF.payload[0].exclude_header,
          });
          const reqField = {
            page: 0,
            size: 100,
            approved: true,
            deleted: false,
            active: false,
            req_payload: {
              format_id: [fetchDF.payload[0].uuid],
              deleted: [false],
            },
            sort: [{ column: 'index', asc: true }],
          };

          const fetchField = await fetchDownloadFormatField(reqField);
          if (fetchField && fetchField.status !== 200) {
            return;
          }

          if (fetchField && fetchField.payload.length > 0) {
            setFetchFieldVal(fetchField.payload);
            for (let i = 0; i < fetchField.payload.length; i++) {
              const addFeatureObj = {
                objVal: {
                  clicked: true,
                  sel_feature: fetchField.payload[i].field,
                  sel_feature_val: fetchField.payload[i].mapped_name,
                },
              };
              setAddFeatureClick(list => {
                const newafClick = cloneDeep(list);
                newafClick.push(addFeatureObj);
                return newafClick;
              });
            }
          }
        }
      } finally {
        dispatch(actions.busy.remove('FETCH_FORMAT'));
      }
    },
    [dispatch, formatName],
  );
  useEffect(() => {
    fetchFormat();
  }, [fetchFormat]);

  const populateSelectedItem = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async code => {
      if (itemsList.length === 0) {
        setItems([]);
        return;
      }
      const itemBody = {
        req_payload: {
          active: [true],
          deleted: [false],
          code: itemsList,
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_ITEMLIST'));
        const iList = await getItemCode(itemBody);
        if (iList.status !== 200) {
          toastError('Unable to Fetch Items');
          return null;
        }

        if (iList.payload && iList.payload.length > 0) {
          //setSelectedItem(iList.payload[0]);

          setItems(iList.payload);
        }
      } finally {
        dispatch(actions.busy.remove('FETCH_ITEMLIST'));
      }
    },
    [dispatch, setItems, itemsList],
  );
  useEffect(() => {
    setItemsList(itemsList);
    populateSelectedItem();
  }, [populateSelectedItem, setItemsList, itemsList]);

  useEffect(() => {
    const featurMap = {};
    items.forEach(item => {
      Object.values(item.definition).forEach(def => {
        featurMap[`${def.feature_code}`] = def;
      });
    });
    setItemFeatures(Object.values(featurMap));
  }, [items, setItemFeatures]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const saveChanges = useCallback(async () => {
    if (form.format === '') {
      toastError('Please Enter Format');
      return;
    }
    try {
      dispatch(actions.busy.add('CREATE_DOWNLOADFORMAT'));

      const data = {
        page: 0,
        size: 100,
        active: true,
        approved: true,
        deleted: false,
        req_payload: {
          name: form.format,
          items: itemsList,
          split_serialized: form.split_serialisation,
          exclude_header: form.exclude_header,
        },
      };
      if (fetchFormatVal && fetchFormatVal.uuid) {
        data.req_payload.uuid = fetchFormatVal.uuid;
      }
      const createDFRespFields = await createDownloadFormat(data);
      if (createDFRespFields.status !== 200) {
        toastError(
          createDFRespFields.err && createDFRespFields.err.err
            ? createDFRespFields.err.err
            : 'Unable to create the Download Formats',
        );
        return;
      }
      for (let i = 0; i < addFeatureClick.length; i++) {
        const dataFields = {
          page: 0,
          size: 1,
          active: true,
          approved: true,
          deleted: false,
          req_payload: {
            format_id: createDFRespFields.payload.uuid,
            field:
              conform[`itemfeature${i}`] ||
              addFeatureClick[i].objVal.sel_feature,

            index: i,
            mapped_name:
              conform[`itemname${i}`] ||
              addFeatureClick[i].objVal.sel_feature_val,
            default_val: '',
          },
        };
        const createRespFields = await createDownloadFormatField(dataFields);
        if (createRespFields.status !== 200) {
          toastError(
            createRespFields.err && createRespFields.err.err
              ? createRespFields.err.err
              : 'Unable to create the Download Format Field',
          );
          return;
        }
      }
      for (let i = 0; i < fetchFieldVal.length; i++) {
        if (!addFeatureClick.find(afc => afc.uuid === fetchFieldVal[i].uuid)) {
          const delRespFields = await deleteDownloadFormatField(
            fetchFieldVal[i].uuid,
          );
          if (delRespFields.status !== 200) {
            toastError(
              delRespFields.err && delRespFields.err.err
                ? delRespFields.err.err
                : 'Unable to delete the Download Format Field',
            );
            return;
          }
        }
      }
      toastMessage({
        type: 'success',
        message: 'Created Successfully',
      });
      history.goBack();
    } finally {
      dispatch(actions.busy.remove('CREATE_DOWNLOADFORMAT'));
    }
  }, [
    dispatch,
    itemsList,
    form,
    conform,
    addFeatureClick,
    history,
    fetchFormatVal,
    fetchFieldVal,
  ]);
  const itemSelected = useCallback(
    async item => {
      setItemsList(list => {
        const newList = cloneDeep(list);
        newList.push(item);
        return newList;
      });
    },
    [setItemsList],
  );
  const itemData = () => {
    return (
      <div>
        <div style={{ fontSize: '20px', textAlign: 'center' }}>SELECT ITEM</div>
        <div style={{ height: '30px' }}></div>
        <div>
          <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
          <input
            type='text'
            id='search_field'
            className='searchModal'
            style={{
              border: '0px !important',
              color: 'rgba(50, 50, 50, 0.4)',
              fontStyle: 'italic',
            }}
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder='Type here to search Item'
          />
        </div>
        {itemsDrawer &&
          itemsDrawer.length > 0 &&
          itemsDrawer.map(row => (
            <div key={row.uuid}>
              <div
                key={row.uuid}
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  itemSelected(row.name);
                  setItemOpen(false);
                }}
              >
                {row.name}
              </div>
              <div
                className='operation-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
      </div>
    );
  };
  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ToastContainer />

      <div>
        <div className='container-fluid position-relative px-0'>
          <DownloadFormatHeader
            isMobile={isMobile}
            isTab={isTab}
            setFormatName={setFormatName}
          />
          <div style={{ height: '22px' }}></div>
          <Form style={{ padding: '0px' }}>
            <div
              className={
                formatError
                  ? Class_RECT_Error
                  : formatFocus
                  ? Class_RECT_Highlight
                  : Class_RECT
              }
              style={{
                margin: '0px',
                padding: '0px',
                marginBottom: '20px',
                marginLeft: '20px',
                paddingLeft: '10px',
              }}
            >
              <div>
                <label className='lbl-input lbl-padding'>FORMAT</label>

                <Form.Control
                  id='format'
                  className='txt-input primary'
                  type='text'
                  autoComplete='off'
                  name='format'
                  placeholder='Enter a format'
                  value={form.format}
                  onFocus={() => {
                    setFormatFocus(true);
                    setFormatError(false);
                  }}
                  onBlur={() => {
                    setFormatFocus(false);
                  }}
                  onChange={onChange}
                />
              </div>
            </div>
            <div
              className={!(isMobile || isTab) && 'row'}
              style={{ maxWidth: '700px' }}
            >
              <div className='col-6'>
                <div
                  className={
                    serialisationError
                      ? 'row ' + Class_RECT_Error
                      : serialisationFocus
                      ? 'row ' + Class_RECT_Highlight
                      : 'row ' + Class_RECT
                  }
                  style={{
                    margin: '0px',
                    paddingTop: '15px',
                    marginBottom: '20px',
                    marginLeft: '20px',
                  }}
                >
                  <div className='col-10'>Split Serialisation</div>
                  <Form.Check
                    className='col-2'
                    type='switch'
                    id='split_serialisation'
                    name='split_serialisation'
                    onFocus={() => {
                      setSerialisationFocus(true);
                      setSerialisationError(false);
                    }}
                    onBlur={() => {
                      setSerialisationFocus(false);
                    }}
                    onChange={() => {
                      setForm(val => {
                        const newVal = cloneDeep(val);
                        newVal.split_serialisation =
                          !newVal.split_serialisation;
                        return newVal;
                      });
                    }}
                    value={true}
                    checked={form.split_serialisation === true}
                  />
                </div>
              </div>
              <div className='col-6'>
                <div
                  className={
                    headerError
                      ? 'row ' + Class_RECT_Error
                      : headerFocus
                      ? 'row ' + Class_RECT_Highlight
                      : 'row ' + Class_RECT
                  }
                  style={{
                    margin: '0px',
                    paddingTop: '15px',
                    marginBottom: '20px',
                    marginLeft: '20px',
                  }}
                >
                  <div className='col-10'>Exclude Header</div>
                  <Form.Check
                    className='col-2'
                    type='switch'
                    id='exclude_header'
                    name='exclude_header'
                    value={true}
                    checked={form.exclude_header === true}
                    onFocus={() => {
                      setHeaderFocus(true);
                      setHeaderError(false);
                    }}
                    onBlur={() => {
                      setHeaderFocus(false);
                    }}
                    onChange={e => {
                      setForm(val => {
                        const newVal = cloneDeep(val);
                        newVal.exclude_header = !newVal.exclude_header;
                        return newVal;
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
          <BottomDrawerItems
            isMobile={isMobile}
            isTab={isTab}
            itemOpen={itemOpen}
            setItemOpen={setItemOpen}
            itemData={itemData()}
          />
          <div
            style={{
              maxWidth: '320px',
              marginLeft: '20px',
              marginTop: '30px',
            }}
          >
            <div className='row'>
              <div className='col-9'>Select Items</div>
              <div className='col-3' style={{ color: '#006EED' }}>
                {!(isMobile || isTab) && (
                  <ItemsFilter
                    labelText='Add+'
                    itemsList={itemsList}
                    setItemsList={setItemsList}
                  />
                )}
                {(isMobile || isTab) && (
                  <div onClick={() => setItemOpen(true)}>Add+</div>
                )}
              </div>
            </div>
            <div style={{ height: '20px' }}></div>
            {!itemsList ||
              (itemsList.length === 0 && (
                <div
                  style={{
                    height: '30px',
                    display: 'flex',
                    borderBottom: '1px',
                    borderBottomColor: '#DEDEDE',
                    borderBottomStyle: 'outset',
                    fontSize: '12px',
                  }}
                >
                  <div style={{ color: '#006EED', cursor: 'pointer' }}>
                    <ItemsFilter
                      labelText='Select items'
                      itemsList={itemsList}
                      setItemsList={setItemsList}
                    />
                  </div>
                  <div>&nbsp; to which the template applies</div>
                </div>
              ))}
            <div>
              <div className='d-flex'>
                {itemsList &&
                  itemsList.length > 0 &&
                  itemsList.map((row, index) => {
                    return (
                      <div
                        key={row || index}
                        className='filter-btns'
                        style={{
                          border: '1px solid',
                          color: '#027AFF !important',
                          display: 'flex',
                        }}
                      >
                        <div style={{ marginRight: '12px' }}>{row}</div>
                        <div>
                          <img
                            src={BlackClose}
                            alt=''
                            onClick={() => {
                              setItemsList(list => {
                                const newList = cloneDeep(list);
                                newList.splice(index, 1);
                                return newList;
                              });
                            }}
                          ></img>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <ConfigureColumn
            itemFeatures={itemFeatures}
            conform={conform}
            onConFormChange={onConFormChange}
            addFeatureClick={addFeatureClick}
            setAddFeatureClick={setAddFeatureClick}
            isMobile={isMobile}
            isTab={isTab}
          />
          <div className='d-flex'>
            <div className='floating-div'>
              <button
                className='btn btn-outline-primary btn-md btn-save-changes'
                style={{ marginRight: '12px', background: 'white' }}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary btn-save-changes'
                onClick={() => {
                  saveChanges();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAdd;
