import React, { Fragment } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
export const ItemMenu = ({ actionLabel, actions }) => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className='clickable'
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
  return (
    <Fragment>
      <Dropdown className='page-size-selector position-relative'>
        <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
          <div
            className='d-flex justify-content-center cursor-pointer dropdown-content'
            style={{ paddingLeft: '6px' }}
          >
            <div className='me-2 primary-btn-color'>{actionLabel}</div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {actions &&
            actions.map(action => {
              return (
                <Fragment key={action.key}>
                  <Dropdown.Item
                    className='cursor-pointer'
                    eventKey={action.key}
                    onClick={() => {
                      if (action.onClick) {
                        action.onClick(action.key);
                      }
                    }}
                  >
                    {action.label}
                  </Dropdown.Item>
                </Fragment>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
