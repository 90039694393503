import React, { Fragment, useEffect, useState } from 'react';
import { StatusComponent } from '../../../components/common/status-component';
import PatternCanvas from '../../cutsmart/optimization/wo-optimization-pattern';
import { getNormalizedPattern } from '../../../helpers/cutguide/cutGuideHelper';

export const CutGuideWoPatternCard = ({
  optimization,
  pattern,
  woMat,
  index,
  onCardClick,
  displayTemplate,
}) => {
  const [normalizedPattern, setNormalizedPattern] = useState({});
  const [patternStatus, setPatternStatus] = useState('PENDING');
  const [patternName, setPatternName] = useState('');
  useEffect(() => {
    setNormalizedPattern(getNormalizedPattern(pattern));
  }, [pattern, setNormalizedPattern]);
  useEffect(() => {
    if (pattern.processed_qty >= pattern.total_qty) {
      setPatternStatus('COMPLETED');
    } else if (pattern.processed_qty > 0 && pattern.total_qty > 0) {
      setPatternStatus('IN_PROGRESS');
    } else {
      setPatternStatus('PENDING');
    }
  }, [pattern, setPatternStatus]);
  useEffect(() => {
    const matIndex = woMat ? woMat.material_index : '';
    const patternIndex = pattern ? pattern.pattern_index : '';
    setPatternName(matIndex + '' + patternIndex);
  }, [woMat, pattern, setPatternName]);
  return (
    <Fragment>
      <div
        className={`position-relative track-trace-card-wrp overflow-hidden`}
        style={{
          animationDuration: '1s',
          overflow: 'hidden',
          minHeight: '400px',
        }}
      >
        <div className='d-flex position-relative'>
          <div className='w-100'>
            <div className='d-flex'>
              <div className={'track-trace-part-name'}>#{patternName}</div>
              <div className='w-100 justify-content-end'>
                <div className='d-flex justify-content-end'>
                  <StatusComponent
                    statusMap={{
                      IN_PROGRESS: 'YELLOW',
                      PENDING: 'BLUE_DOT',
                      COMPLETED: 'GREEN',
                    }}
                    statusText={patternStatus}
                    statusKey={patternStatus}
                  />
                </div>
              </div>
            </div>
            <div className='rubik-font' style={{ fontSize: '12px' }}>
              <div className='text-secondary d-flex  '>
                <div style={{ width: '120px' }}>No Of Parts</div>
                <div>{normalizedPattern.part_count}</div>
              </div>
              <div className='text-secondary d-flex '>
                <div style={{ width: '120px' }}>Board Size</div>
                <div>
                  {normalizedPattern.length} x {normalizedPattern.width}
                </div>
              </div>
              <div className='text-secondary d-flex '>
                <div style={{ width: '120px' }}>Board Qty</div>
                <div>{normalizedPattern.board_count}</div>
              </div>
              <div className='text-secondary d-flex '>
                <div style={{ width: '120px' }}>Grain</div>
                <div>{normalizedPattern.grain > 0 ? 'Yes' : 'No'}</div>
              </div>
              <div className='text-secondary d-flex '>
                <div style={{ width: '120px' }}>Pressed Location</div>
                <div>{normalizedPattern.pressed_location}</div>
              </div>
              <div className='text-secondary d-flex py-1'>
                <div style={{ width: '120px' }}>Remarks</div>
                <div>{normalizedPattern.comment}</div>
              </div>
            </div>
            <div
              className='cursor-pointer'
              onClick={() => {
                onCardClick(normalizedPattern);
              }}
            >
              <PatternCanvas
                pattern={normalizedPattern}
                props={{}}
                optimizationType={optimization.optimization_type}
                displayTemplate={displayTemplate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export const WoPatternListContainer = ({
  wo,
  woMat,
  optimization,
  woPatterns,
  onCardClick,
  displayTemplate,
}) => {
  return (
    <Fragment>
      <div className='row py-2'>
        {woPatterns.map((pattern, index) => {
          return (
            <div
              className='col-12 col-md-6 '
              key={pattern.uuid}
              style={{ minWidth: '280px' }}
            >
              <CutGuideWoPatternCard
                onCardClick={onCardClick}
                optimization={optimization}
                pattern={pattern}
                index={index}
                woMat={woMat}
                displayTemplate={displayTemplate}
              />
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default WoPatternListContainer;
