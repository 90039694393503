const SUCCESS = 200;
const GENERIC_EXCEPTION = 10000;
const RECORD_MODIFIED_EXCEPTION = 10001;
const RECORD_NOT_FOUND_EXCEPTION = 10002;
const RECORD_EXISTS_EXCEPTION = 10003;
const FIELD_VALIDATION_EXCEPTION = 10004;
const VO_VALIDATION_EXCEPTION = 10005;
const FIELD_NULL_VALIDATION_EXCEPTION = 10006;
const CUSTOM_FIELD_VALIDATION_EXCEPTION = 10007;
const FIELD_NOT_FOUND_VALIDATION_EXCEPTION = 10008;
const INVALID_REQUEST_EXCEPTION = 10009;
const CONSTRAINT_EXCEPTION = 10010;

const ResponseCodes = {
  SUCCESS: SUCCESS,
  NOT_FOUND: 401,
  SYSTEM_ERROR: 500,
  GENERIC_EXCEPTION: GENERIC_EXCEPTION,
  RECORD_MODIFIED_EXCEPTION: RECORD_MODIFIED_EXCEPTION,
  RECORD_NOT_FOUND_EXCEPTION: RECORD_NOT_FOUND_EXCEPTION,
  RECORD_EXISTS_EXCEPTION: RECORD_EXISTS_EXCEPTION,
  FIELD_VALIDATION_EXCEPTION: FIELD_VALIDATION_EXCEPTION,
  VO_VALIDATION_EXCEPTION: VO_VALIDATION_EXCEPTION,
  FIELD_NULL_VALIDATION_EXCEPTION: FIELD_NULL_VALIDATION_EXCEPTION,
  CUSTOM_FIELD_VALIDATION_EXCEPTION: CUSTOM_FIELD_VALIDATION_EXCEPTION,
  FIELD_NOT_FOUND_VALIDATION_EXCEPTION: FIELD_NOT_FOUND_VALIDATION_EXCEPTION,
  INVALID_REQUEST_EXCEPTION: INVALID_REQUEST_EXCEPTION,
  CONSTRAINT_EXCEPTION: CONSTRAINT_EXCEPTION,
  FIELD_CIRCULAR_VALIDATION_EXCEPTION: 10011,
};

export default ResponseCodes;

