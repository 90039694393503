import React, {
  Fragment,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import useParentWidth from '../../../hooks/useParentWidth';
import { NewLineScanInput } from '../../../components/common/scan-input';
import ScanIcon from '../../../assets/icons/scan-icon.svg';

import PropTypes from 'prop-types';
import { LocationSelectText } from '../../../components/tracktrace/location-select';
import { AboveTab } from '../../../components/common/only-mobile';
import { KeyBoardIcon } from '../../../assets/icons/icon-tracktrace';
import { useDispatch, useSelector } from 'react-redux';
import { updateShowKeyBoard } from '../../../store/cutguide/cgstationstate';

const Footer = memo(
  ({
    label = 'Scan',
    placeholder = '',
    onSubmit,
    disabled = false,
    onShowScanner,
  }) => {
    const container = useRef();
    const dispatch = useDispatch();
    const [submitTrigger] = useState(0);
    const { show_keyboard } = useSelector(state => state.cgstationstate);
    const separator = useMemo(() => {
      return (
        <div
          style={{
            height: '90%',
            width: '1px',
            margin: '8px',
            backgroundColor: '#000',
          }}
        ></div>
      );
    }, []);
    useParentWidth(container, [], w => w + 15);
    const footerStyle = useCallback(() => {
      return {
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 2,
        height: '64px',
        borderBottom: '4px solid #007bff',
      };
    }, []);

    return (
      <div
        ref={container}
        className='footer width-100-percent'
        style={footerStyle()}
      >
        <div
          onSubmit={e => {
            e.preventDefault();
          }}
          className='d-flex justify-content-between align-items-center search'
        >
          <div className='w-100'>
            {show_keyboard && (
              <Fragment>
                <div className='label-s'>{label}</div>
                <NewLineScanInput
                  onSubmitCb={onSubmit}
                  placeholder={placeholder}
                  disabled={disabled}
                  submitTrigger={submitTrigger}
                />
              </Fragment>
            )}
          </div>
          <div className='d-flex align-items-center px-1'>
            <KeyBoardIcon
              fill={show_keyboard ? null : '#cecece'}
              onClick={() => {
                dispatch(updateShowKeyBoard(!show_keyboard));
              }}
            />
          </div>
          <div>{show_keyboard === true}</div>
          {separator}
          <div className='d-flex align-items-center px-1'>
            <img
              onClick={onShowScanner}
              src={ScanIcon}
              alt='Scan here to Scan QrCode'
              className='cursor-pointer pe-1'
            />
          </div>
          <AboveTab>
            {separator}
            <div>
              <div className='btn-label text-primary btn-primary-tapped text-button-padded cursor-pointer'>
                <LocationSelectText />
              </div>
            </div>
          </AboveTab>
        </div>
      </div>
    );
  },
);

Footer.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onAdd: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Footer;
