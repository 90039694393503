import { TYPES } from './const';
import { createAction } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
export const setLabelPrintRecords = createAction(TYPES.SET_LABEL_PRINT_RECORDS);
export const setPageParams = createAction(TYPES.SET_ITEMS_PAGE_PARAMAS);
export const setPageFilterParams = createAction(
  TYPES.SET_ITEMS_PAGE_FILTER_PARAMS,
);
export const formatFiltersObj = data => {
  return {
    entity_id: data.entity_id,
    code: data.code && isArray(data.code) ? data.code : [],
    item_code_term: isArray(data.item_code_term) ? data.item_code_term : [],
    short_code_term:
      data.short_code_term && isArray(data.short_code_term)
        ? data.short_code_term
        : [],
    item_name_term: data.item_name_term,
    parent_item_code_term: isArray(data.parent_item_code_term)
      ? data.parent_item_code_term
      : [],
    status_term: isArray(data.status_term) ? data.status_term : [],
    notuuid: data.notuuid && isArray(data.notuuid) ? data.notuuid : [],
  };
};
