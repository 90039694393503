import { updateWorkOrderCtx } from '../../services/cutsmart';
import { getResponseErrorMessage } from '../common/helper';
import { getState } from '../../store';
import { getOrgConfig } from '../../services/org';
import { setCutSmartPatternTemplate } from '../../store/cutsmart/cutsmartstateless';

export const applyWoCtxFilter = ({ ctxList, filter }) => {
  if (!ctxList) {
    return [];
  }
  /* eslint-disable sonarjs/no-duplicated-branches */
  return ctxList.filter(val => {
    let returnVal = true;
    if (
      filter.order &&
      filter.order.length > 0 &&
      val.order &&
      val.order.ref_code.toLowerCase().indexOf(filter.order.toLowerCase()) ===
        -1
    ) {
      returnVal = false;
    } else if (
      filter.parent_order &&
      filter.parent_order.length > 0 &&
      !val.parent_order
    ) {
      returnVal = false;
    } else if (
      filter.parent_order &&
      filter.parent_order.length > 0 &&
      val.parent_order &&
      val.order.parent_order.ref_code
        .toLowerCase()
        .indexOf(filter.parent_order.toLowerCase()) === -1
    ) {
      returnVal = false;
    }
    return returnVal;
  });
  /* eslint-enable sonarjs/no-duplicated-branches */
};

export const refreshOrderItems = async wo => {
  if (wo.wo_status !== 'DRAFT' && wo.wo_status !== 'OPTIMIZING') {
    return {
      status: 'warn',
      msg: 'Refresh is supported only in Draft Status',
    };
  }
  for (let i = 0; i < wo.ctx_list.length; i++) {
    const woCtx = wo.ctx_list[`${i}`];
    if (!woCtx.deleted) {
      const udpateResp = await updateWorkOrderCtx({
        uuid: woCtx.uuid,
        wo_ctx_type: woCtx.wo_ctx_type,
        wo_ctx_ref: woCtx.wo_ctx_ref,
        active: true,
      });
      if (udpateResp.status !== 200) {
        const errMessage = getResponseErrorMessage(
          udpateResp,
          'Unable to refresh',
        );
        return {
          status: 'error',
          msg: errMessage,
        };
      }
    }
  }
  return {
    status: 'success',
    msg: 'Order Items Refreshed',
  };
};

export const getCutSmartPatternTemplate = async dispatch => {
  const { user, cutsmartstateless } = getState();
  const { cut_smart_pattern_template } = cutsmartstateless;
  if (!user || !user.org || !user.org.uuid) {
    return {
      fields: [],
      features: [],
    };
  }
  const { org } = user;
  if (
    cut_smart_pattern_template != null &&
    cut_smart_pattern_template.org_id === org.uuid
  ) {
    return cut_smart_pattern_template;
  }
  const orgConfigResp = await getOrgConfig('CUTSMART_ITEM_FEATURES_TEMPLATE');
  if (
    orgConfigResp.status !== 200 ||
    !orgConfigResp.payload ||
    !orgConfigResp.payload.value ||
    !orgConfigResp.payload.value.val
  ) {
    return {
      fields: [],
      features: [],
    };
  }
  const config = orgConfigResp.payload.value.val;
  config.fields = config.fields || [];
  config.features = config.features || [];
  config.org_id = org.uuid;
  dispatch(setCutSmartPatternTemplate(config));
  return config;
};
