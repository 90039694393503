import React from 'react';
import PropTypes from 'prop-types';
import { ModalMessageStatusMobileIcon } from '../../assets/icons/common-icons';

const NaadiConfirmScreen = ({
  message,
  state,
  error,
  primaryButton,
  primaryAction,
  secondaryButton,
  secondaryAction,
  successCb,
  onClose,
}) => {
  const _primaryAction = (input1, input2) => {
    if (state === 'success') return;
    primaryAction(input1, input2);
  };
  const errorClass = state === 'success' ? '' : 'naadi-error';
  return (
    <div>
      <div className='naadi-modal-confirm-status-icon'>
        <div className='confirm-icons-message w-100 d-block'>
          <div className='small-icon pe-0 text-center'>
            <ModalMessageStatusMobileIcon state={state} />
          </div>
          <div className='large-icon pe-0'>
            <ModalMessageStatusMobileIcon state={state} />
          </div>
          <div className='naadi-confirm-message-text'>
            {error && <div className={errorClass}>{error}</div>}
          </div>
        </div>
      </div>
      <div className='naadi-modal-confirm-header'>
        {state !== 'success' && <div>{message}</div>}
      </div>
      <div className='d-flex'>
        {secondaryButton && (
          <div className='naadi-modal-confirm-button'>
            <div
              className='naadi-modal-confirm-button-content'
              onClick={secondaryAction}
            >
              {secondaryButton}
            </div>
          </div>
        )}
        {state !== 'success' && (
          <div className='naadi-modal-confirm-button'>
            <div
              className={'naadi-modal-confirm-button-content btn-primary'}
              onClick={_primaryAction}
            >
              {primaryButton}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

NaadiConfirmScreen.displayName = 'Confirm Action';
NaadiConfirmScreen.propTypes = {
  message: PropTypes.element.isRequired,
  error: PropTypes.element,
  state: PropTypes.string.isRequired,
  primaryButton: PropTypes.element.isRequired,
  primaryAction: PropTypes.func.isRequired,
  secondaryButton: PropTypes.element,
  secondaryAction: PropTypes.func,
  successCb: PropTypes.func,
  onClose: PropTypes.func,
};
export default NaadiConfirmScreen;
