"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.set = exports.default = exports.change = void 0;

var _const = require("./const");

var _lang = require("../../service/lang");

var set = function set(lang) {
  return {
    type: _const.TYPES.SET,
    lang: lang
  };
};

exports.set = set;

var change = function change(locale) {
  return function (dispatch) {
    return (0, _lang.get)(locale).then(function (messages) {
      return dispatch(set({
        locale: locale,
        messages: messages
      }));
    });
  };
};

exports.change = change;
var actions = {
  set: set,
  change: change
};
var _default = actions;
exports.default = _default;