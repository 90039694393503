import React from 'react';

import PropTypes from 'prop-types';
export const IconPackingSummary = ({ onClick }) => {
  return (
    <div className='cursor-pointer' onClick={onClick}>
      <svg
        width='55'
        height='55'
        viewBox='0 0 55 55'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_d_4645_12880)'>
          <path
            d='M27.5 49C40.4787 49 51 38.4787 51 25.5C51 12.5213 40.4787 2 27.5 2C14.5213 2 4 12.5213 4 25.5C4 38.4787 14.5213 49 27.5 49Z'
            fill='#027AFF'
          />
          <path
            d='M36 19.7137L30.6875 23.4482L25.29 19.6506L19 23.4482V20.9866L25.375 17.1365L30.6875 20.8709L36 17.1365V19.7137ZM36 27.656H19V26.3469H36V27.656ZM36 30.5547H19V31.8637H36V30.5547Z'
            fill='white'
          />
        </g>
        <defs>
          <filter
            id='filter0_d_4645_12880'
            x='0'
            y='0'
            width='55'
            height='55'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='2' />
            <feGaussianBlur stdDeviation='2' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4645_12880'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_4645_12880'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

IconPackingSummary.propTypes = {
  onClick: PropTypes.func,
};

const IconAddBox = ({ onClick }) => {
  return (
    <div className='cursor-pointer' onClick={onClick}>
      <svg
        style={{ padding: '0px', background: 'none' }}
        width='55'
        height='55'
        viewBox='0 0 55 55'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_d)'>
          <circle cx='27.5' cy='25.5' r='23.5' fill='#027AFF' />
        </g>
        <path
          d='M39.446 27.2819L39.446 24.7181L29.2819 24.7181V14.554H26.7181V24.7181L16.554 24.7181L16.554 27.2819L26.7181 27.2819V37.4461H29.2819V27.2819L39.446 27.2819Z'
          fill='white'
        />
        <defs>
          <filter
            id='filter0_d'
            x='0'
            y='0'
            width='55'
            height='55'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            />
            <feOffset dy='2' />
            <feGaussianBlur stdDeviation='2' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

IconAddBox.propTypes = {
  onClick: PropTypes.func,
};

export default IconAddBox;
