export const TYPES = {
  SET_CATEGORY: 'SELECTION_SET_CATEGORY',
  SET_SELECTION: 'SELECTION_SET_SELECTION',
  TOGGLE_NOTIFICATION: 'TOGGLE_NOTIFICATION',
};

export const DEFAULT = {
  category: { label: 'Order', value: 'ORDER' },
  selection: null,
  notification: false,
  itemLeftDetail: false,
  showCurrentBoxDetail: false,
  itemSyncDate: null,
  packingOperationSyncDate: null,
  boxSyncDate: null,
  boxItemSyncDate: null,
  current_station: {
    uuid: 'packing',
    station_code: 'packing',
    operations: ['packing'],
  },
  branch_stations: {},
};
