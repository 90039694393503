import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Provider } from 'react-redux';
import Keycloak from 'keycloak-js';
import { PersistGate } from 'redux-persist/integration/react';

import { keycloak } from '@naadi/framework';
import outerStore from './store';
import App from './app';
import './scss/styles.scss';
//import { datadogRum } from '@datadog/browser-rum';
import 'react-html5-camera-photo/build/css/index.css';
import 'react-bootstrap-submenu/dist/index.css';

const rootElem = document.getElementById('root');
const root = createRoot(rootElem);
if (process.env.NODE_ENV === 'production') {
}

const AppRoot = () => {
  const [authClient, setAuthClient] = useState(new Keycloak(keycloak));
  useEffect(() => {
    setAuthClient(_authClient => {
      _authClient.onTokenExpired = () => {
        _authClient.logout();
      };
      return _authClient;
    });
  }, [setAuthClient]);
  return (
    <ReactKeycloakProvider authClient={authClient}>
      <Provider store={outerStore.store}>
        <PersistGate loading={null} persistor={outerStore.persistor}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ReactKeycloakProvider>
  );
};
// console.log(outerStore);
root.render(<AppRoot />);
//ReactDOM.render(<AppRoot />, rootElem);
