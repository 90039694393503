"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var SUCCESS = 200;
var GENERIC_EXCEPTION = 10000;
var RECORD_MODIFIED_EXCEPTION = 10001;
var RECORD_NOT_FOUND_EXCEPTION = 10002;
var RECORD_EXISTS_EXCEPTION = 10003;
var FIELD_VALIDATION_EXCEPTION = 10004;
var VO_VALIDATION_EXCEPTION = 10005;
var FIELD_NULL_VALIDATION_EXCEPTION = 10006;
var CUSTOM_FIELD_VALIDATION_EXCEPTION = 10007;
var FIELD_NOT_FOUND_VALIDATION_EXCEPTION = 10008;
var INVALID_REQUEST_EXCEPTION = 10009;
var CONSTRAINT_EXCEPTION = 10010;
var ResponseCodes = {
  SUCCESS: SUCCESS,
  NOT_FOUND: 401,
  SYSTEM_ERROR: 500,
  GENERIC_EXCEPTION: GENERIC_EXCEPTION,
  RECORD_MODIFIED_EXCEPTION: RECORD_MODIFIED_EXCEPTION,
  RECORD_NOT_FOUND_EXCEPTION: RECORD_NOT_FOUND_EXCEPTION,
  RECORD_EXISTS_EXCEPTION: RECORD_EXISTS_EXCEPTION,
  FIELD_VALIDATION_EXCEPTION: FIELD_VALIDATION_EXCEPTION,
  VO_VALIDATION_EXCEPTION: VO_VALIDATION_EXCEPTION,
  FIELD_NULL_VALIDATION_EXCEPTION: FIELD_NULL_VALIDATION_EXCEPTION,
  CUSTOM_FIELD_VALIDATION_EXCEPTION: CUSTOM_FIELD_VALIDATION_EXCEPTION,
  FIELD_NOT_FOUND_VALIDATION_EXCEPTION: FIELD_NOT_FOUND_VALIDATION_EXCEPTION,
  INVALID_REQUEST_EXCEPTION: INVALID_REQUEST_EXCEPTION,
  CONSTRAINT_EXCEPTION: CONSTRAINT_EXCEPTION,
  FIELD_CIRCULAR_VALIDATION_EXCEPTION: 10011
};
var _default = ResponseCodes;
exports.default = _default;