import { TYPES } from './const';
import { actions, ResponseCodes } from '@naadi/framework';
import { createAction } from '@reduxjs/toolkit';
import {
  getOrders,
  getEntities,
  saveNewOrder,
  getOrdersDetails,
  saveNewEntity,
  getOrdersDownloadFormats,
} from '../../../services/order';
import { isArray } from 'lodash';
import {
  toastError,
  toastMessage,
} from '../../../helpers/packing/packingHelper';
import { sortChildOrders } from '../../../helpers/order/orderHelper';
import { cloneDeep } from 'lodash';
import { actionTypeName } from '../bom/bomDataDuck';

const getActionName = (type, verb) => actionTypeName('ORDER_LIST', type, verb);

export const setOrders = createAction(TYPES.SET_ORDERS);
export const updateOrders = createAction(TYPES.UPDATE_ORDERS);
export const setPageParams = createAction(TYPES.SET_ORDERS_PAGE_PARAMAS);
export const setPageFilterParams = createAction(
  TYPES.SET_ORDERS_PAGE_FILTER_PARAMS,
);
export const setEntities = createAction(TYPES.SET_ORDERS_CREATE_PAGE_ENTITIES);
export const showBomMenu = createAction(TYPES.SHOW_BOM_MODEL);
export const hideBomMenu = createAction(TYPES.HIDE_BOM_MODEL);
export const setParentOrders = createAction(TYPES.SET_PARENT_ORDERS);
export const setOrderDownloadFormats = createAction(
  TYPES.SET_ORDER_DOWNLOAD_FORMATS,
);

export const showEntityCreateModal = createAction(
  TYPES.SHOW_ENTITY_CREATE_MODAL,
);
export const storeNewOrderEntityCreated = createAction(
  TYPES.STORE_NEW_ENTITY_CREATED,
);

export const hideEntityCreateModal = createAction(
  TYPES.HIDE_ENTITY_CREATE_MODAL,
);

export const showLabelPrintModal = createAction(
  getActionName('SHOW_LABEL_PRINT_MODAL', 'SET'),
);
export const setLabelPrintRecords = createAction(
  getActionName('SET_LABEL_PRINT_RECORDS', 'SET'),
);
export const setLabelPrintGroupFields = createAction(
  getActionName('SET_LABEL_PRINT_GROUP_FIELDS', 'SET'),
);
export const setLabelPrintGroupFieldsSelected = createAction(
  getActionName('SET_LABEL_PRINT_GROUP_FIELDS_SELECTED', 'SET'),
);

export const setCSVData = createAction(TYPES.CSV_DATA_SET);
export const setOrderSrcType = createAction('ORDER_SRC_TYPE_SET');
export const setOrderSrcId = createAction('ORDER_SRC_ID_SET');
export const showPrinterPartLabelUploader = createAction(
  'SHOW_PRINTER_PART_LABEL_UPLOADER',
);

export const FETCHING_ORDERS = 'FETCHING_ORDERS';
export const FETCHING_CUSTOMERS = 'FETCHING_CUSTOMERS';
export const SAVING_NEW_ORDER = 'SAVING_NEW_ORDER';
export const SAVING_NEW_ENTITY = 'SAVING_NEW_ENTITY';

export const fetchOrders =
  (selection, filters, sortBy) => (dispatch, getState) => {
    const { busy, ordersList } = getState();
    if (busy.includes(FETCHING_ORDERS)) {
      return;
    }
    const page_params = cloneDeep(ordersList.pageParams);

    dispatch(actions.busy.add(FETCHING_ORDERS));
    if (filters.to_date) {
      const shiftDate =
        new Date(filters.to_date).getTime() + 1000 * 60 * 60 * 4;
      filters.to_date = new Date(shiftDate);
    }
    if (filters.page) {
      page_params.page_no = filters.page;
    }
    if (filters.size) {
      page_params.page_size = filters.size;
    }
    const filterObj = formatFiltersObj(filters);
    if (
      (filterObj.term && filterObj.term.length > 0) ||
      (filterObj.parent_order_id_term &&
        filterObj.parent_order_id_term.length > 0)
    ) {
      delete filterObj.from_date;
      delete filterObj.to_date;
    }
    filterObj.fetch_count = true;

    Promise.all([getOrders(filterObj, page_params, sortBy)])
      .then(([orders]) => {
        dispatch(
          setOrders(
            orders.payload && orders.payload.length ? orders.payload : [],
          ),
        );
        dispatch(
          setPageParams({
            total_count: orders.total,
            total_pages: orders.total_pages,
            page_no: orders.page,
          }),
        );
      })
      .finally(() => {
        dispatch(actions.busy.remove(FETCHING_ORDERS));
      });
  };

export const formatFiltersObj = data => {
  return {
    from_date: data.from_date,
    to_date: data.to_date,
    ref_code: data.ref_code && isArray(data.ref_code) ? data.ref_code : [],
    entity_id: data.entity_id,
    code: data.code && isArray(data.code) ? data.code : [],
    term: data.term,
    code_term: data.code_term && isArray(data.code_term) ? data.code_term : [],
    ref_code_term: isArray(data.ref_code_term) ? data.ref_code_term : [],
    ext_code_term: isArray(data.ext_code_term) ? data.ext_code_term : [],
    parent_order_id_term: data.parent_order_id_term || [],
    secondary_customer_term: data.secondary_customer_term || [],
    status: isArray(data.status) ? data.status : [],
    notuuid: data.notuuid && isArray(data.notuuid) ? data.notuuid : [],
    page: parseInt(data.page ? data.page : 0),
    size: parseInt(data.size ? data.size : 50),
  };
};

// export const fetchCustomers = (disptach, filters) => (dispatch, getState) => {
//   const { busy } = getState();
//   if (busy.includes(FETCHING_CUSTOMERS)) {
//     return;
//   }
//   dispatch(actions.busy.add(FETCHING_CUSTOMERS));
//   Promise.all([getEntities({ ...filters })])
//     .then(([entities]) => {
//       dispatch(
//         setEntities(
//           entities.payload && entities.payload.length ? entities.payload : []
//         )
//       );
//     })
//     .finally(() => {
//       dispatch(actions.busy.remove(FETCHING_CUSTOMERS));
//     });
// };

export const fetchCustomers =
  (disptach, filters) => async (dispatch, getState) => {
    const { busy } = getState();
    if (busy.includes(FETCHING_CUSTOMERS)) {
      return;
    }
    try {
      dispatch(actions.busy.add(FETCHING_CUSTOMERS));
      const entities = await getEntities({ ...filters });
      dispatch(
        setEntities(
          entities.payload && entities.payload.length ? entities.payload : [],
        ),
      );
      dispatch(actions.busy.remove(FETCHING_CUSTOMERS));
    } catch (error) {
      dispatch(actions.busy.remove(FETCHING_CUSTOMERS));
    }
  };

export const fetchParentOrders =
  (disptach, filters) => (dispatch, getState) => {
    const { busy } = getState();
    if (busy.includes(TYPES.FETCHING_PARENT_ORDERS)) {
      return;
    }
    dispatch(actions.busy.add(TYPES.FETCHING_PARENT_ORDERS));
    Promise.all([
      getOrders(
        { ...filters },
        {
          page_size: 1000,
          page_no: 0,
        },
      ),
    ])
      .then(([orders]) => {
        dispatch(
          setParentOrders(
            orders.payload && orders.payload.length ? orders.payload : [],
          ),
        );
      })
      .finally(() => {
        dispatch(actions.busy.remove(TYPES.FETCHING_PARENT_ORDERS));
      });
  };

export const createOrder =
  (selection, payload, history) => (dispatch, getState) => {
    const { busy } = getState();
    const {
      user: { branch },
    } = getState();
    if (busy.includes(SAVING_NEW_ORDER)) {
      return;
    }
    dispatch(actions.busy.add(SAVING_NEW_ORDER));
    payload.branch_id = branch ? branch.branch_id : null;
    Promise.all([saveNewOrder(payload)])
      .then(([new_order]) => {
        if (
          new_order.status === ResponseCodes.FIELD_CIRCULAR_VALIDATION_EXCEPTION
        ) {
          const err =
            'Parent Order Circular Reference through ' +
            new_order.err.list.join(' -> ');
          toastError(err);
          return;
        } else if (new_order.status !== ResponseCodes.SUCCESS) {
          const err =
            new_order.err && new_order.err.err
              ? new_order.err.err
              : 'Order Update Failed';
          toastError(err);
          return;
        }
        toastMessage({
          type: 'success',
          message: 'Order Updated',
        });
        if (payload.uuid) {
          dispatch(fetchOrderDetails(dispatch, payload.uuid));
        } else {
          history.push(`/app/orders/details/${new_order.payload.uuid}`);
        }
      })
      .finally(() => {
        dispatch(actions.busy.remove(SAVING_NEW_ORDER));
      });
  };

export const fetchOrderDetails =
  (selection, id, resolve) => async (dispatch, getState) => {
    const { busy } = getState();
    const resolver = success => {
      if (resolve) {
        resolve(success);
      }
    };
    if (!id || id === '') {
      dispatch(setOrderDownloadFormats([]));
      resolver(false);
      return;
    }
    if (busy.includes(FETCHING_ORDERS)) {
      resolver(false);
      return;
    }
    try {
      dispatch(actions.busy.add(FETCHING_ORDERS));
      const orderDetailResp = await getOrdersDetails(id);
      const order =
        orderDetailResp.payload && orderDetailResp.payload.length
          ? orderDetailResp.payload[0]
          : {};
      sortChildOrders(order);
      const orderDownloadFormatResp = await getOrdersDownloadFormats();
      const order_download_formats =
        orderDownloadFormatResp.payload &&
        orderDownloadFormatResp.payload.length
          ? orderDownloadFormatResp.payload
          : [];
      const orders_for_id = {
        [id]: order,
      };
      dispatch(updateOrders(orders_for_id));
      dispatch(setOrderDownloadFormats(order_download_formats));
      resolver(true);
    } catch (err) {
      console.log(err);
      resolver(false);
    } finally {
      dispatch(actions.busy.remove(FETCHING_ORDERS));
    }
  };

export const createEntity =
  (payload, history, onCustomerAdded) => (dispatch, getState) => {
    const { busy } = getState();
    if (busy.includes(SAVING_NEW_ENTITY)) {
      return;
    }
    dispatch(actions.busy.add(SAVING_NEW_ENTITY));
    Promise.all([saveNewEntity(payload)])
      .then(([new_order]) => {
        if (new_order.status !== ResponseCodes.SUCCESS) {
          const err =
            new_order?.err?.violations[0]?.message ||
            'Unable to Create a New Customer';
          toastError(err);
          // Handle backend errors
          console.log(new_order);
        } else {
          onCustomerAdded(new_order.payload);
          dispatch(storeNewOrderEntityCreated(new_order.payload));
          dispatch(hideEntityCreateModal(dispatch));
        }
      })
      .finally(() => {
        dispatch(actions.busy.remove(SAVING_NEW_ENTITY));
      });
  };
