/* eslint-disable sonarjs/cognitive-complexity */

import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useKeycloak } from '@react-keycloak/web';
import { faCouch } from '@fortawesome/free-solid-svg-icons';
import { actions, Message } from '@naadi/framework';
import { getCurrentUserDetail } from '../services/user';
import { getState } from '../store';
import { getCurrentUserOrgAccess } from '../services/auth';
import { v4 as uuidv4 } from 'uuid';
const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(o => o.user);

  const { keycloak, initialized } = useKeycloak();
  const loading = useRef(false);
  const backoff = useRef(0);

  const extractOrg = useCallback(res => {
    let org = null;
    const orgs = [];
    const org_users = {};
    for (let o of res.orgs) {
      if (o.active && o.approved && !o.deleted) {
        let obj = {
          uuid: o.uuid,
          name: o.name,
          global_org_id: o.global_org_id,
          image_url: o.image_url,
        };
        if (!org) {
          org = obj;
        }
        orgs.push(obj);
      }
    }
    orgs.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    for (let ou of res.org_users || []) {
      const org_user = {
        org_id: ou.org_id,
        org_user_id: ou.uuid,
        first_name: ou.first_name,
        last_name: ou.last_name,
        login_ids: (ou.logins || []).map(val => val.login_id),
      };
      org_users[`${ou.org_id}`] = org_user;
    }
    return [org, orgs, org_users];
  }, []);

  const setProfile = useCallback(
    async user => {
      const token = keycloak.token;
      const keycloakResp = await keycloak.loadUserProfile();
      try {
        const res = await getCurrentUserDetail(token);
        if (res && res.orgs && res.user) {
          const [org, orgs, org_users] = extractOrg(res);
          const matchOrg = (
            user === null || !user || !user.org
              ? []
              : orgs.filter(val => val.uuid === user.org.uuid)
          )[0];
          const _user = {
            first_name: res.user.first_name,
            user_id: res.user.user_id,
            token,
            org: matchOrg ? matchOrg : org || {},
            orgs,
            org_users,
            authenticated: true,
            access: {},
            username: keycloakResp.username,
            login_session_id: user?.login_session_id
              ? user?.login_session_id
              : uuidv4(),
          };
          if (
            _user.org &&
            _user.org.uuid &&
            res.user.active &&
            res.user.approved &&
            !res.user.deleted
          ) {
            const orgId = _user.org.uuid;
            const userOrgAccess = await getCurrentUserOrgAccess(
              _user.org,
              _user.org_users[`${orgId}`],
            );
            if (userOrgAccess.status === 200) {
              _user.access[`${orgId}`] = userOrgAccess.payload;
              const _branches = userOrgAccess.payload.map(val => ({
                branch_id: val.branch_id,
                branch_name: val.branch_name,
                org_id: val.org_id,
              }));
              _user.branches = _branches || [];
              _user.branch = _branches[0] || {};
              if (
                user &&
                user.branch &&
                user.branch.branch_id &&
                user.org.uuid === _user.org.uuid
              ) {
                const existingBranch = _user.branches.filter(
                  val => val.branch_id === user.branch.branch_id,
                );
                if (existingBranch.length > 0) {
                  _user.branch = existingBranch[0];
                }
              }
            }
            dispatch(actions.user.set(_user));
            // localStorage.setItem('user', JSON.stringify(_user));
            // localStorage.setItem('token', _user.token);
          } else if (orgs.length === 0) {
            dispatch(actions.user.set(_user));
          } else {
            debugger;
            console.log('user is not valid!');
            keycloak.logout();
            return false;
          }
          return true;
        } else {
          console.log('unable to load user data!');
          keycloak.logout();
          return false;
        }
      } catch (err) {
        if (err && err.response && err.response.status === 401) {
          if (keycloak.authenticated) {
            //keycloak.logout();
          } else {
            console.log(err);
          }
        } else {
          console.log(err);
        }
        return false;
      }
    },
    [keycloak, dispatch, extractOrg],
  );

  useEffect(() => {
    if (!initialized) return;
    const updateBackoff = isSuccess => {
      if (isSuccess === true) {
        backoff.current = 0;
      } else {
        backoff.current++;
      }
      if (backoff.current > 50) {
        backoff.current = 5;
      }
    };
    const timeout = ms => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const fn = async () => {
      try {
        if (loading.current === true) return;
        loading.current = true;
        if (backoff.current > 0) {
          await timeout(backoff.current * 1000);
        }
        if (
          !user ||
          !user.authenticated ||
          user.authenticated === false ||
          keycloak.authenticated === false
        ) {
          if (keycloak.authenticated) {
            if (keycloak.profile && user && user.authenticated === true) {
              updateBackoff(await setProfile(user));
            } else {
              try {
                updateBackoff(await setProfile(user));
              } catch (err) {
                console.log(err);
              }
            }
          } else {
            try {
              if (keycloak.isTokenExpired()) {
                console.log('The Token has expired hence logging out');

                keycloak.logout();
                updateBackoff(false);
              }
            } catch (err) {
              keycloak.logout();
              updateBackoff(false);
            }
          }
        }
      } finally {
        loading.current = false;
      }
    };
    fn();
  }, [dispatch, initialized, keycloak, setProfile, user]);
  useEffect(() => {
    //console.log(keycloak.authenticated);
    const stateToken = getState().token;
    if (keycloak.token && keycloak.token !== stateToken) {
      dispatch(actions.token.set(keycloak.token));
    }
    try {
      if (initialized && keycloak.isTokenExpired()) {
        keycloak.logout();
      }
    } catch (err) {
      keycloak.login();
    }
    //console.log(keycloak);
  }, [keycloak, initialized, dispatch]);

  return (
    <Fragment>
      {user ? (
        { ...children }
      ) : (
        <Message heading={<FormattedMessage id='RELAX' />} icon={faCouch}>
          <FormattedMessage id='GETTING-THINGS-READY' />
        </Message>
      )}
    </Fragment>
  );
};

PrivateRoute.propTypes = {};

export default PrivateRoute;
