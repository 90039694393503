import React, { useRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Menu from '../../../assets/icons/icon-menu.svg';

import { toggleSideMenu } from '../../../store/app/nav/actions';
import useParentWidth from '../../../hooks/useParentWidth';
import { getScanStateClass } from '../../../helpers/tracktrace/orderItemHelper';
import { DigitalPodIcon } from '../../../assets/icons/nav-icons';

const DIGITAL_POD_BASE_PATH = '/app/dpodexternal';
const DIGITAL_POD_HOME = `${DIGITAL_POD_BASE_PATH}/home`;

const Header = ({
  onClickMenu,
  scanState,
  history,
  location,
  mode,
  scrollTop,
}) => {
  const dispatch = useDispatch();
  const [scanStateClass, setScanStateClass] = useState('bg-white');
  //const history = useHistory();

  const container = useRef();

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    setScanStateClass(getScanStateClass(scanState));
  }, [scanState]);

  const handleSideMenuToggle = useCallback(() => {
    dispatch(toggleSideMenu());
  }, [dispatch]);

  const onClick = useCallback(() => {
    if (onClickMenu) {
      onClickMenu();
    } else {
      history.push(DIGITAL_POD_HOME);
    }
  }, [history, onClickMenu]);

  useParentWidth(container);

  useEffect(() => {
    setScrollY(scrollTop);
  }, [scrollTop, setScrollY]);

  return (
    <div
      ref={container}
      className={`flex-row header navbar-expand-lg d-flex track-trace-header p-3 py-4 pt-3 ${
        scrollY > 150 ? ' sub-header position-fixed' : 'position-absolute'
      } ${scanStateClass} `}
      style={{ zIndex: 10, top: '0px' }}
    >
      <button
        className='p-0 border-0 '
        style={{ backgroundColor: 'transparent', minWidth: '32px' }}
        data-toggle='offcanvas'
        onClick={handleSideMenuToggle}
        type='button'
      >
        <img src={Menu} alt='Menu' />
      </button>
      <div
        className='page-header cursor-pointer d-flex align-items-center'
        onClick={onClick}
      >
        <div className='page-title d-flex align-items-center'>
          <DigitalPodIcon fill={'#000'} />
          <div className='ps-2'>DIGITAL POD</div>
        </div>
      </div>
    </div>
  );
};

Header.prototype = {
  onClickMenu: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  scanState: PropTypes.string,
};

export default Header;
