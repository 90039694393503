import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
import { cloneDeep } from 'lodash';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('TRACKTRACE_PIPELINE_STATE', type, verb);

export const TYPES = {
  PIPELINE_MODE: 'PIPELINE_MODE',
  PIPELINE_GROUP: 'PIPELINE_GROUP',
  SORT_DIRECTION: 'SORT_DIRECTION',
  BREADCRUMB: 'BREADCRUMB',
};
export const togglePipelineMode = createAction(
  getActionName(TYPES.PIPELINE_MODE, 'MERGE'),
);
export const setPipelineMode = createAction(
  getActionName(TYPES.PIPELINE_MODE, 'SET'),
);

export const setPipelineGroup = createAction(
  getActionName(TYPES.PIPELINE_GROUP, 'SET'),
);
export const setSortDirection = createAction(
  getActionName(TYPES.SORT_DIRECTION, 'SET'),
);
export const toggleSortDirection = createAction(
  getActionName(TYPES.SORT_DIRECTION, 'TOGGLE'),
);

export const DEFAULT = {
  pipeline_mode: {},
  pipeline_group: 'ORDER',
  sort_direction: 'desc',
  breadcrumb: [],
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(togglePipelineMode, (state, { payload }) => {
    const pipelineMode = cloneDeep(state.pipeline_mode || {});
    pipelineMode[`${payload}`] = pipelineMode[`${payload}`] !== true;
    return Update(state, {
      pipeline_mode: { $set: pipelineMode },
    });
  });
  builder.addCase(setPipelineMode, (state, { payload }) => {
    const pipelineMode = cloneDeep(state.pipeline_mode || {});
    pipelineMode[`${payload.mode}`] = payload.pipeline === true;
    return Update(state, {
      pipeline_mode: { $set: pipelineMode },
    });
  });
  builder.addCase(setPipelineGroup, (state, { payload }) => {
    return Update(state, {
      pipeline_group: { $set: payload },
    });
  });
  builder.addCase(toggleSortDirection, state => {
    const sortDir = state.sort_direction === 'desc' ? 'asc' : 'desc';
    return Update(state, {
      sort_direction: { $set: sortDir },
    });
  });
  builder.addCase(setSortDirection, (state, { payload }) => {
    const sortDir = payload === 'desc' ? 'desc' : 'asc';
    return Update(state, {
      sort_direction: { $set: sortDir },
    });
  });
});

export default reducer;
