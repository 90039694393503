import React from 'react';
import PropTypes from 'prop-types';

export const ChevronDownIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='8'
      fill='none'
      viewBox='0 0 12 8'
    >
      <path fill='#007BFF' d='M1.41.59L6 5.17 10.59.59 12 2 6 8 0 2 1.41.59z' />
    </svg>
  );
};

export const CalendarIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='23'
      fill='none'
      viewBox='0 0 20 23'
    >
      <path
        fill='#323232'
        fillRule='evenodd'
        d='M17 2.143h1c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2v-16c0-1.1.9-2 2-2h1v-2h2v2h10v-2h2v2zm-15 18h16v-13H2v13z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export const SearchIcon = ({ is_active }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
    >
      <path
        fill={is_active ? '#027AFF' : '#000'}
        fillRule='evenodd'
        d='M11.71 11h.79l4.99 5L16 17.49l-5-4.99v-.79l-.27-.28A6.471 6.471 0 016.5 13 6.5 6.5 0 1113 6.5c0 1.61-.59 3.09-1.57 4.23l.28.27zM2 6.5C2 8.99 4.01 11 6.5 11S11 8.99 11 6.5 8.99 2 6.5 2 2 4.01 2 6.5z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

SearchIcon.propTypes = {
  is_active: PropTypes.bool,
};
