/* eslint-disable prettier/prettier */
import React from 'react';

export const KebabIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='12'
    fill='none'
    viewBox='0 0 18 12'
  >
    <path
      fill='#000'
      fillRule='evenodd'
      d='M0 2V0h18v2H0zm0 5h18V5H0v2zm0 5h18v-2H0v2z'
      clipRule='evenodd'
    ></path>
  </svg>
);
export const OrdersIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='22'
    fill='none'
    viewBox='0 0 22 22'
  >
    <path
      fill='#000'
      fillRule='evenodd'
      d='M18.344 21.855c1.958-.507 3.323-2.248 3.323-4.24a4.39 4.39 0 00-2.533-3.952V3.403c0-1.879-1.55-3.402-3.46-3.403H3.46C1.55.001.001 1.524 0 3.404v12.965c.001 1.88 1.55 3.403 3.46 3.404h9.869c.997 1.734 3.056 2.59 5.015 2.082zm-3.09-17.252c0 .332-.273.601-.61.601H4.49a.606.606 0 01-.61-.6c0-.332.273-.601.61-.601h10.153c.337 0 .61.269.61.6zm-.61 2.923H4.49a.606.606 0 00-.61.601c0 .332.273.6.61.6h10.153c.337 0 .61-.268.61-.6 0-.332-.273-.6-.61-.6zM4.49 11.048h10.153c.337 0 .61.27.61.6 0 .333-.273.601-.61.601H4.49a.606.606 0 01-.61-.6c0-.332.273-.6.61-.6zm7.308 3.524H4.491a.606.606 0 00-.61.6c0 .332.273.601.61.601h7.308c.337 0 .61-.269.61-.6 0-.332-.273-.601-.61-.601zm5.41-1.338c.236 0 .471.02.704.056V3.404c-.002-1.216-1.003-2.2-2.24-2.203H3.46c-1.235.002-2.236.987-2.239 2.203v12.965c.003 1.216 1.004 2.2 2.24 2.203h9.4a4.13 4.13 0 01-.11-.957c.004-2.42 1.998-4.38 4.458-4.38zm1.555 3.78c.337 0 .61.27.61.6 0 .333-.273.601-.61.601h-1.555a.606.606 0 01-.61-.6v-1.758c0-.215.116-.413.304-.52a.62.62 0 01.611 0 .599.599 0 01.305.52v1.157h.945z'
      clipRule='evenodd'
    ></path>
  </svg>
);
export const BrandIcon = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='16' cy='16' r='3' fill='#007BFF' />
    <circle cx='16' cy='3' r='3' fill='#007BFF' />
    <circle cx='16' cy='29' r='3' fill='#007BFF' />
    <circle cx='3' cy='16' r='3' fill='#007BFF' />
    <circle cx='3' cy='3' r='3' fill='#007BFF' />
    <circle cx='1' cy='31' r='1' fill='#007BFF' />
    <circle cx='29' cy='16' r='3' fill='#007BFF' />
    <circle cx='31' cy='1' r='1' fill='#007BFF' />
    <circle cx='29' cy='29' r='3' fill='#007BFF' />
  </svg>
);

export function BackIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#000'
        d='M15 19l1.41-1.41L11.83 13l-1-.964 1-1.036 4.59-4.59L15 5l-7 7 7 7z'
      ></path>
    </svg>
  );
}

export const OrderUploadIcon = ({ fill }) => {
  const _fill = fill ? fill : '#22B6CF';
  return (
    <svg
      width='18'
      height='22'
      viewBox='0 0 18 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.125 2.75H16.875C17.4844 2.75 18 2.23438 18 1.625C18 1.01562 17.4844 0.5 16.875 0.5H1.125C0.515625 0.5 0 1.01562 0 1.625C0 2.23438 0.515625 2.75 1.125 2.75ZM3.79688 13.25L7.875 8.9375V20.375C7.875 20.9844 8.39062 21.5 9 21.5C9.60938 21.5 10.125 20.9844 10.125 20.375V8.9375L14.2031 13.25C14.3906 13.4844 14.7188 13.625 15 13.625C15.2812 13.625 15.5625 13.5312 15.75 13.2969C16.2188 12.875 16.2656 12.1719 15.7969 11.7031L9.79688 5.32812C9.375 4.85938 8.625 4.85938 8.20312 5.32812L2.20312 11.7031C1.73438 12.1719 1.78125 12.875 2.25 13.2969C2.67188 13.7188 3.375 13.7188 3.79688 13.25Z'
        fill={_fill}
      />
    </svg>
  );
};

export const OrderClientUploadIcon = () => (
  <svg
    width='30'
    height='22'
    viewBox='0 0 30 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25.4531 9.03125C25.4531 8.9375 25.5 8.84375 25.5 8.75C25.5 5.84375 23.1562 3.5 20.25 3.5C19.7344 3.5 19.2188 3.59375 18.7031 3.73438C17.2969 1.8125 15 0.5 12.375 0.5C8.01562 0.5 4.45312 4.0625 4.5 8.42188C1.875 9.35938 0 11.7969 0 14.75C0 18.5 3 21.5 6.75 21.5H23.625C27.1406 21.5 30 18.6406 30 15.125C30 12.2656 28.0781 9.82812 25.4531 9.03125ZM23.625 19.25H6.75C4.26562 19.25 2.25 17.2344 2.25 14.75C2.25 12.875 3.46875 11.1875 5.25 10.5312L6.75 10.0156V8.23438C6.79688 5.1875 9.32812 2.75 12.375 2.75C14.1562 2.75 15.8438 3.59375 16.9219 5.09375L17.8594 6.35938L19.3594 5.89062C21.6562 5.1875 23.3438 7.10938 23.2031 8.9375L23.1094 10.6719L24.7969 11.1875C26.5781 11.75 27.75 13.2969 27.75 15.125C27.75 17.4219 25.9219 19.25 23.625 19.25ZM18.3281 11.7031L16.125 13.9062V8C16.125 7.39062 15.6094 6.875 15 6.875C14.3906 6.875 13.875 7.39062 13.875 8V13.9062L11.6719 11.7031C11.25 11.2812 10.5 11.2812 10.0781 11.7031C9.65625 12.125 9.65625 12.875 10.0781 13.2969L14.2031 17.4219C14.2969 17.5156 14.4375 17.6094 14.5781 17.6562C14.7188 17.7031 14.8594 17.75 15 17.75C15.1406 17.75 15.2812 17.7031 15.4219 17.6562C15.5625 17.6094 15.7031 17.5156 15.7969 17.4219L19.9219 13.2969C20.3438 12.875 20.3438 12.125 19.9219 11.7031C19.5 11.2812 18.75 11.2812 18.3281 11.7031Z'
      fill='#DF9E04'
    />
  </svg>
);

export const OrderAddFromItem = () => (
  <svg
    width='18'
    height='24'
    viewBox='0 0 18 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.875 11.625C4.26562 11.625 3.75 12.1406 3.75 12.75C3.75 13.3594 4.26562 13.875 4.875 13.875C5.48438 13.875 6 13.3594 6 12.75C6 12.1406 5.48438 11.625 4.875 11.625ZM4.875 16.125C4.26562 16.125 3.75 16.6406 3.75 17.25C3.75 17.8594 4.26562 18.375 4.875 18.375C5.48438 18.375 6 17.8594 6 17.25C6 16.6406 5.48438 16.125 4.875 16.125ZM13.5 16.5H8.25C7.82812 16.5 7.5 16.8281 7.5 17.25C7.5 17.6719 7.82812 18 8.25 18H13.5C13.9219 18 14.25 17.6719 14.25 17.25C14.25 16.8281 13.9219 16.5 13.5 16.5ZM15 3H12.6562C12.3281 1.26562 10.8281 0 9 0C7.17188 0 5.67188 1.26562 5.34375 3H3C1.35938 3 0 4.35938 0 6V21C0 22.6406 1.35938 24 3 24H15C16.6406 24 18 22.6406 18 21V6C18 4.35938 16.6406 3 15 3ZM9 2.25C9.60938 2.25 10.125 2.76562 10.125 3.375C10.125 3.98438 9.60938 4.5 9 4.5C8.39062 4.5 7.875 3.98438 7.875 3.375C7.875 2.76562 8.39062 2.25 9 2.25ZM15.75 21C15.75 21.4219 15.4219 21.75 15 21.75H3C2.57812 21.75 2.25 21.4219 2.25 21V6C2.25 5.57812 2.57812 5.25 3 5.25H3.84375C3.79688 5.48438 3.75 5.71875 3.75 6V6.75C3.75 7.17188 4.07812 7.5 4.5 7.5H13.5C13.9219 7.5 14.25 7.17188 14.25 6.75V6C14.25 5.71875 14.2031 5.48438 14.1562 5.25H15C15.4219 5.25 15.75 5.57812 15.75 6V21ZM13.5 12H8.25C7.82812 12 7.5 12.3281 7.5 12.75C7.5 13.1719 7.82812 13.5 8.25 13.5H13.5C13.9219 13.5 14.25 13.1719 14.25 12.75C14.25 12.3281 13.9219 12 13.5 12Z'
      fill='#F28482'
    />
  </svg>
);

export const PrintCurrentOrderItems = () => (
  <svg
    width='18'
    height='24'
    viewBox='0 0 18 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.875 11.625C4.26562 11.625 3.75 12.1406 3.75 12.75C3.75 13.3594 4.26562 13.875 4.875 13.875C5.48438 13.875 6 13.3594 6 12.75C6 12.1406 5.48438 11.625 4.875 11.625ZM4.875 16.125C4.26562 16.125 3.75 16.6406 3.75 17.25C3.75 17.8594 4.26562 18.375 4.875 18.375C5.48438 18.375 6 17.8594 6 17.25C6 16.6406 5.48438 16.125 4.875 16.125ZM13.5 16.5H8.25C7.82812 16.5 7.5 16.8281 7.5 17.25C7.5 17.6719 7.82812 18 8.25 18H13.5C13.9219 18 14.25 17.6719 14.25 17.25C14.25 16.8281 13.9219 16.5 13.5 16.5ZM15 3H12.6562C12.3281 1.26562 10.8281 0 9 0C7.17188 0 5.67188 1.26562 5.34375 3H3C1.35938 3 0 4.35938 0 6V21C0 22.6406 1.35938 24 3 24H15C16.6406 24 18 22.6406 18 21V6C18 4.35938 16.6406 3 15 3ZM9 2.25C9.60938 2.25 10.125 2.76562 10.125 3.375C10.125 3.98438 9.60938 4.5 9 4.5C8.39062 4.5 7.875 3.98438 7.875 3.375C7.875 2.76562 8.39062 2.25 9 2.25ZM15.75 21C15.75 21.4219 15.4219 21.75 15 21.75H3C2.57812 21.75 2.25 21.4219 2.25 21V6C2.25 5.57812 2.57812 5.25 3 5.25H3.84375C3.79688 5.48438 3.75 5.71875 3.75 6V6.75C3.75 7.17188 4.07812 7.5 4.5 7.5H13.5C13.9219 7.5 14.25 7.17188 14.25 6.75V6C14.25 5.71875 14.2031 5.48438 14.1562 5.25H15C15.4219 5.25 15.75 5.57812 15.75 6V21ZM13.5 12H8.25C7.82812 12 7.5 12.3281 7.5 12.75C7.5 13.1719 7.82812 13.5 8.25 13.5H13.5C13.9219 13.5 14.25 13.1719 14.25 12.75C14.25 12.3281 13.9219 12 13.5 12Z'
      fill='#DF9E04'
    />
  </svg>
);
