import React, { memo, useState } from 'react';

import useMobile from '../../../hooks/useMobile';
import StatusBox from '../../../components/common/status-box';
import { SelectContext } from '../../../components/common/select-context';
import { useSelector } from 'react-redux';
import Status from '../../../components/packing/common/status';
const WoProgress = memo(({ onClick, woResult }) => {
  return (
    <div className='w-100 pb-2'>
      {woResult && woResult.uuid && (
        <Status
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          label={''}
          completed={woResult.processed_qty}
          total={woResult.total_qty}
        />
      )}
    </div>
  );
});

const WoMatSubHeader = ({
  onCtxTypeSelect,
  onCtxValueSelect,
  ctx,
  woResult,
}) => {
  const [isMobile] = useMobile();
  const [ctxTypes] = useState(['WO']);
  const { busy } = useSelector(state => state.tracktracestateless);
  return (
    <div
      className={`row sub-header  mx-1 pb-4 bg-white`}
      style={{ minHeight: '106px' }}
    >
      <div className='col-sm-5 col-xs-12 col-lg-6 p-0 d-inline-block'>
        <div className={isMobile ? 'pe-5' : ''}>
          <div
            className='order-select ms-4 position-relative d-flex align-content-center flex-column'
            style={{ zIndex: 9 }}
          >
            <SelectContext
              types={ctxTypes}
              onCtxTypeSelect={onCtxTypeSelect}
              onCtxValueSelect={onCtxValueSelect}
              ctxType={ctx ? ctx.type : null}
              ctxValue={ctx ? ctx.value : null}
              showBusy={busy && busy.length > 0}
            />
          </div>
        </div>
      </div>
      <div className='col-sm-7 col-lg-6 col-xs-12 p-0 d-inline-block '>
        <div className='px-32'>
          <div className='d-flex w-100  pb-2'>
            <WoProgress label={'Progress'} onClick={null} woResult={woResult} />
          </div>
          <div className='d-flex justify-content-start'>
            <div className='track-trace-status-box-wrapper'>
              <StatusBox
                onClick={null}
                count={
                  woResult.id
                    ? woResult.total_board_qty - woResult.processed_board_qty
                    : 0
                }
                label={'BOARDS PENDING'}
                color={'blue'}
              ></StatusBox>
            </div>
            <div className='track-trace-status-box-wrapper'>
              <StatusBox
                onClick={null}
                count={
                  woResult.id ? woResult.total_qty - woResult.processed_qty : 0
                }
                label={'PANELS PENDING'}
                color={'blue'}
              ></StatusBox>
            </div>
            <div className='track-trace-status-box-wrapper'>
              <StatusBox
                onClick={null}
                count={woResult.rejected_qty || 0}
                label={'PANELS REJECTED'}
                color={'red'}
              ></StatusBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
WoMatSubHeader.propTypes = {};
export default WoMatSubHeader;
