import React, { Fragment, useState, useCallback, useEffect } from 'react';
import '../../scss/components/_dropdown.scss';
import IconMenu from '../../assets/icons/icon-menu.svg';
import { toggleSideMenu } from '../../store/app/nav/actions';
import ArrowIcon from '../../assets/icons/arrow-icon.svg';
import ScreenLoader from '../../components/common/screen-loader';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import IconAddBox from '../../components/packing/common/icon-add-box';
import { useHistory } from 'react-router-dom';
import NewBoxMenu from './newbox-menu';
import useMobile from '../../hooks/useMobile';
import { actions } from '@naadi/framework';
import { toastError, toastMessage } from '../../helpers/packing/packingHelper';
import {
  deleteWorkstation,
  fetchWorkstations,
  createWorkstation,
} from '../../services/workstation';
import {
  closeConfirmScreen,
  displayConfirmScreen,
} from '../../store/app/screenoverlay/actions';
import NaadiConfirmScreen from '../../components/common/naadi-confirm';
import ScreenModal from '../../components/common/screen-modal';
import BottomDrawerContent from './bottom-drawer-menu';
import { getState } from '../../store';
import { ResponseCodes } from '@naadi/framework';
const TXT_COL = 'rgba(50, 50, 50, 0.4)';

const WorkStationHeader = () => {
  const dispatch = useDispatch();
  const handleMenuToggle = () => {
    dispatch(toggleSideMenu());
  };
  return (
    <Fragment>
      <div
        className='flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 '
        style={{ zIndex: 10, width: '100%' }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          type='button'
        >
          <img
            src={IconMenu}
            alt='Menu'
            onClick={() => handleMenuToggle()}
          ></img>
        </button>
        <div className='ms-2 page-header'>
          <div className='page-title d-flex align-items-center'>
            <div className='ps-2'>Workstations</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ImageDisplay = () => {
  return (
    <Fragment>
      <div style={{ height: '210px', background: 'white' }}></div>
      <div style={{ textAlign: 'center' }}>
        <img src={ArrowIcon} alt=''></img>
      </div>
      <div style={{ height: '24px' }}></div>
      <div
        style={{
          font: 'Roboto',
          fontSize: '16px',
          color: TXT_COL,
          background: 'white',
          textAlign: 'center',
        }}
      >
        Tap on + to add an workstation
      </div>
    </Fragment>
  );
};

const BoxDisplay = ({
  workstationUuid,
  workstationName,
  isMobile,
  isTab,
  menuOpen,
  setMenuOpen,
  delWorkstation,
  markInactive,
  setTitleText,
  setWorkstationId,
}) => {
  const history = useHistory();
  return (
    <div
      className='col-12 col-sm-6 col-md-6  col-lg-4 col-xl-4'
      style={{
        paddingLeft: '16px',
        paddingBottom: '16px',
        marginTop: '0px',
      }}
    >
      <div
        className='border rounded shadow-sm px-4 py-3 bg-white'
        style={{ height: '92px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            className='cursor-pointer'
            style={{ paddingTop: '8px' }}
            onClick={() =>
              history.push(
                `/app/workstations/newworkstation?uuid=` + workstationUuid,
              )
            }
          >
            {workstationName}
          </div>
          <div className='d-flex'>
            <NewBoxMenu
              id={workstationUuid}
              box={true}
              isMobile={isMobile}
              isTab={isTab}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              delWorkstation={delWorkstation}
              markInactive={markInactive}
              titleText={workstationName}
              setTitleText={setTitleText}
              setWorkstationId={setWorkstationId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Workstation = () => {
  const [isMobile, isTab] = useMobile();
  const history = useHistory();
  const [workstationList, setWorkstationList] = useState([]);
  const [workstationId, setWorkstationId] = useState();
  const [titleText, setTitleText] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const fetchWorkstation = useCallback(
    async oid => {
      const req = {
        page: 0,
        size: 100,
        req_payload: {
          deleted: [false],
          active: [true],
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_WORKSTATION'));
        const fetchOp = await fetchWorkstations(req);
        if (fetchOp && fetchOp.status !== 200) {
          return;
        }
        setWorkstationList(fetchOp.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_WORKSTATION'));
      }
    },
    [dispatch],
  );
  useEffect(() => {
    fetchWorkstation();
  }, [fetchWorkstation]);
  /*const delWorkstation = useCallback(
    async oid => {
      try {
        dispatch(actions.busy.add('DELETE_WORKSTATION'));
        const deleteDF = await deleteWorkstation(oid);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Delete Workstation');
        }
        toastMessage({
          type: 'success',
          message: 'Workstation Removed Successfully',
        });
        fetchWorkstation();
      } finally {
        dispatch(actions.busy.remove('DELETE_WORKSTATION'));
      }
    },
    [dispatch, fetchWorkstation],
  );*/
  const delWorkstation = useCallback(
    (id, workstationName) => {
      const _confirmDeleteWS = async () => {
        const { busy } = getState();
        try {
          if (busy.includes('DELETE_WORKSTATION')) {
            return;
          }

          dispatch(actions.busy.add('DELETE_WORKSTATION'));

          const result = await deleteWorkstation(id);
          if (result.status !== ResponseCodes.SUCCESS) {
            const err =
              result.err && result.err.err
                ? result.err.err
                : 'Unable to Delete the Workstation';
            dispatch(closeConfirmScreen());
            dispatch(
              displayConfirmScreen({
                content: confirmScreen('error', err),
                boundingClass: null,
                boundingStyle: null,
              }),
            );
            return;
          }
          dispatch(closeConfirmScreen());
          fetchWorkstation();
          toastMessage({
            type: 'success',
            message: 'Workstation deleted',
          });
          fetchWorkstation();
        } finally {
          dispatch(actions.busy.remove('DELETE_WORKSTATION'));
        }
      };

      const cancelDeleteWS = () => {
        dispatch(closeConfirmScreen());
      };
      const confirmScreen = (state, error) => {
        const secondaryButton = (
          <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
        );
        return (
          <NaadiConfirmScreen
            message={
              <div>
                Do you really want to delete {workstationName} Workstation?
              </div>
            }
            state={state}
            primaryButton={<div>DELETE</div>}
            primaryAction={_confirmDeleteWS}
            secondaryButton={secondaryButton}
            secondaryAction={cancelDeleteWS}
            error={error}
          />
        );
      };
      dispatch(
        displayConfirmScreen({
          content: confirmScreen('initial', null),
          boundingClass: null,
          boundingStyle: null,
        }),
      );
    },
    [dispatch, fetchWorkstation],
  );
  const markInactive = useCallback(
    async oid => {
      const req = {
        active: false,
        approved: true,
        deleted: false,
        req_payload: {
          uuid: oid,
        },
      };
      try {
        dispatch(actions.busy.add('MARK_INACTIVE'));
        const deleteDF = await createWorkstation(req);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Mark as Inactive');
        }
        toastMessage({
          type: 'success',
          message: 'Workstation Marked as Inactive',
        });
        fetchWorkstation();
      } finally {
        dispatch(actions.busy.remove('MARK_INACTIVE'));
      }
    },
    [dispatch, fetchWorkstation],
  );
  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ScreenModal />
      <ToastContainer />
      <div>
        <div className='container-fluid position-relative px-0'>
          <BottomDrawerContent
            isMobile={isMobile}
            isTab={isTab}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            title={titleText}
            delWorkstation={delWorkstation}
            markInactive={markInactive}
            id={workstationId}
          />
          <WorkStationHeader />
          <div style={{ background: 'white', marginLeft: '50px' }}>
            <div
              style={{
                paddingTop: '48px',
                paddingLeft: '16px',
                paddingRight: '16px',
              }}
            >
              <div className='w-100'>
                <div className='row'>
                  {workstationList &&
                    workstationList.map(op => {
                      return (
                        <BoxDisplay
                          key={op.uuid}
                          workstationUuid={op.uuid}
                          workstationName={op.instance_name}
                          isMobile={isMobile}
                          isTab={isTab}
                          setMenuOpen={setMenuOpen}
                          delWorkstation={delWorkstation}
                          markInactive={markInactive}
                          setTitleText={setTitleText}
                          setWorkstationId={setWorkstationId}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            {(!workstationList || workstationList.length === 0) && (
              <ImageDisplay />
            )}
          </div>
        </div>
      </div>
      <button className='floating-btn'>
        <IconAddBox
          onClick={() => history.push(`/app/workstations/newworkstation`)}
        />
      </button>
    </div>
  );
};

export default Workstation;
