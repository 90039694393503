import React, { useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as XLSX from 'xlsx';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setCSVData,
  setOrderSrcId,
  setOrderSrcType,
} from '../../../store/order/ordersList/actions';
import {
  getSourcesForOrder,
  resetGroupByCols,
} from '../../../store/order/bom/bomDataDuck';
import { last } from 'lodash';
import {
  OrderAddFromItem,
  OrderClientUploadIcon,
  OrderUploadIcon,
} from '../../../assets/icons/orders-icon';
import {
  getOrderSrcClientService,
  getOrderSrcFromClientService,
} from '../../../services/order';
import { actions, ResponseCodes } from '@naadi/framework';
import {
  toastError,
  toastMessage,
} from '../../../helpers/packing/packingHelper';
import { getState } from '../../../store';
import { expandOrderSrcService } from '../../../services/bom';

export default function AddNewBOMSlectionComponent({
  order_id,
  showModal,
  hideBomMenu,
  setShowCsvEditModal,
}) {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    hideBomMenu();
  }, [hideBomMenu]);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  /* eslint-disable  sonarjs/cognitive-complexity */
  const onDesignToolUploadClick = useCallback(
    async event => {
      const { busy } = getState();
      if (busy.includes('FETCH_SRC_FROM_DESIGN_TOOL')) {
        return;
      }
      try {
        dispatch(actions.busy.add('FETCH_SRC_FROM_DESIGN_TOOL'));
        const clientFilter = {
          page: 0,
          size: 20,
          req_payload: {
            client_type: ['DESIGN_TOOL', 'IMOS'],
            deleted: [false],
          },
        };
        const clientListResp = await getOrderSrcClientService(clientFilter);
        if (clientListResp.status !== ResponseCodes.SUCCESS) {
          const err =
            clientListResp.err && clientListResp.err.err
              ? clientListResp.err.err
              : 'Unable to Fetch the Client Detail';
          toastError(err);
          return;
        }
        const designToolClients = clientListResp.payload;
        if (designToolClients.length === 0) {
          toastError('There are No Design Tool Clients Registered for the Org');
          return;
        }
        const errList = [];
        for (let i = 0; i < designToolClients.length; i++) {
          const designToolClient = designToolClients[`${i}`];
          const addOrderSrcResp = await getOrderSrcFromClientService(
            designToolClient.uuid,
            order_id,
          );
          if (addOrderSrcResp.status !== 200) {
            const err =
              addOrderSrcResp.err && addOrderSrcResp.err.err
                ? addOrderSrcResp.err.err
                : 'Unable to Connect to the Client';
            //toastError(err);
            //break;
            errList.push(err);
            continue;
          }
          if (
            !addOrderSrcResp.payload.import_job_id ||
            addOrderSrcResp.payload.import_job_id.length === 0
          ) {
            const expandResult = await expandOrderSrcService(
              addOrderSrcResp.payload.uuid,
            );
            if (expandResult.status !== ResponseCodes.SUCCESS) {
              toastError('Order Fetched From Design Tool, Validation Failed');
            } else {
              toastMessage({
                type: 'success',
                message: 'Order Fetched from Design Tool',
              });
            }
          } else {
            toastMessage({
              type: 'success',
              message: 'Order Fetched from Design Tool',
            });
          }

          dispatch(getSourcesForOrder(dispatch, order_id, true));
          handleClose();
          errList.splice(0, errList.length);
          break;
        }
        if (errList.length > 0) {
          errList.forEach(err => {
            toastError(err);
          });
        }
      } finally {
        dispatch(actions.busy.remove('FETCH_SRC_FROM_DESIGN_TOOL'));
      }
    },
    [dispatch, handleClose, order_id],
  );
  /* eslint-enable  sonarjs/cognitive-complexity */
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const onUploadClick = useCallback(event => {
    hiddenFileInput.current.click();
  }, []);

  // process CSV data
  /* eslint-disable sonarjs/cognitive-complexity */
  const processData = useCallback(
    (dataString, fileName) => {
      /* eslint-disable */
      const dataStringLines = dataString.split(/\r\n|\n/);
      let headers = dataStringLines[0].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
      );
      /* eslint-enable */
      if (!last(headers).length) {
        headers.pop();
      }

      const list = [];
      for (let i = 1; i < dataStringLines.length; i++) {
        /* eslint-disable */
        const row = dataStringLines[`${i}`].split(
          /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
        );
        /* eslint-enable */
        while (row.length > headers.length && row.length > 0) {
          row.pop();
        }
        //row.unshift(i);
        if (headers && row.length > 0) {
          const cols = { 0: i + 1 };
          for (let j = 0; j < headers.length; j++) {
            let d = row[`${j}`] || '';
            if (d.length > 0) {
              if (d[0] === '"') d = d.substring(1, d.length - 1);
              if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
            }
            cols['' + (j + 1)] = d;
            /*if (headers[`${j}`]) {
              obj[headers[`${j}`]] = d;
            }*/
          }

          // remove the blank rows

          //cols.unshift('' + (i + 1));
          list.push(cols);
        }
      }
      headers.unshift('#');
      // prepare columns list from headers
      const columns = headers.map((c, index) => ({
        Header: c,
        accessor: '' + index,
        id: index,
      }));
      dispatch(
        setCSVData({
          list,
          columns,
          file_name: fileName,
          column_headers: headers,
        }),
      );
      dispatch(setOrderSrcType({ type: 'UPLOAD' }));
      //dispatch(showCSVTableModal(dispatch));
      setShowCsvEditModal(order_id);
      dispatch(resetGroupByCols([]));
      dispatch(setOrderSrcId({ order_src_id: null }));
      hideBomMenu();
    },
    [dispatch, hideBomMenu, order_id, setShowCsvEditModal],
  );
  /* eslint-enable  sonarjs/cognitive-complexity */

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = useCallback(
    event => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = evt => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary', raw: true });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname + ''];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        processData(data, file.name);
      };
      reader.readAsBinaryString(file);
    },
    [processData],
  );

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      show={showModal}
      onHide={handleClose}
      dialogClassName={'naadi-react-modal'}
      centered
    >
      <Modal.Header className='border-0 p-4'>
        <div className='fs-24'>Add New BOM</div>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-4 text-center'>
            <div className='clickable'>
              <div className='modal-circle pink-circle'>
                <div className='order-upload-icon-placement'>
                  <OrderAddFromItem />
                </div>
              </div>
              <div className='btn-label pt-4 px-2'>Add from Item List</div>
            </div>
          </div>

          <div className='col-4 text-center'>
            <div className='clickable' onClick={onDesignToolUploadClick}>
              <div className='modal-circle yellow-circle'>
                <div className='order-upload-icon-placement'>
                  <OrderClientUploadIcon />
                </div>
              </div>
              <div className='btn-label pt-4 px-2'>Fetch from Design Tool</div>
            </div>
          </div>
          <div className='col-4 text-center'>
            <div className='clickable' onClick={onUploadClick}>
              <div className='modal-circle blue-circle'>
                <div className='order-upload-icon-placement'>
                  <OrderUploadIcon />
                </div>
              </div>
              <div className='btn-label pt-4 px-2'>Upload CSV File</div>
              <input
                type='file'
                accept='.csv,.txt'
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

AddNewBOMSlectionComponent.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  order_id: PropTypes.string,
};
