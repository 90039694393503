import React, { Fragment, useState, useCallback, useEffect } from 'react';
import '../../scss/components/_dropdown.scss';
import IconMenu from '../../assets/icons/icon-menu.svg';
import { toggleSideMenu } from '../../store/app/nav/actions';
import ArrowIcon from '../../assets/icons/arrow-icon.svg';
import ScreenLoader from '../../components/common/screen-loader';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import IconAddBox from '../../components/packing/common/icon-add-box';
import { useHistory } from 'react-router-dom';
import NewBoxMenu from './newbox-menu';
import useMobile from '../../hooks/useMobile';
import { actions } from '@naadi/framework';
import { toastError, toastMessage } from '../../helpers/packing/packingHelper';
import {
  deleteStation,
  fetchStations,
  createStation,
} from '../../services/station';
import BottomDrawerContent from './bottom-drawer-menu';
const TXT_COL = 'rgba(50, 50, 50, 0.4)';

const StationHeader = () => {
  const dispatch = useDispatch();
  const handleMenuToggle = () => {
    dispatch(toggleSideMenu());
  };
  return (
    <Fragment>
      <div
        className='flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 '
        style={{ zIndex: 10, width: '100%' }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          type='button'
        >
          <img
            src={IconMenu}
            alt='Menu'
            onClick={() => handleMenuToggle()}
          ></img>
        </button>
        <div className='ms-2 page-header'>
          <div className='page-title d-flex align-items-center'>
            <div className='ps-2'>Stations</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ImageDisplay = () => {
  return (
    <Fragment>
      <div style={{ height: '210px', background: 'white' }}></div>
      <div style={{ textAlign: 'center' }}>
        <img src={ArrowIcon} alt=''></img>
      </div>
      <div style={{ height: '24px' }}></div>
      <div
        style={{
          font: 'Roboto',
          fontSize: '16px',
          color: TXT_COL,
          background: 'white',
          textAlign: 'center',
        }}
      >
        Tap on + to add an station
      </div>
    </Fragment>
  );
};

const BoxDisplay = ({
  stationUuid,
  stationName,
  isMobile,
  isTab,
  menuOpen,
  setMenuOpen,
  delStation,
  markInactive,
  setTitleText,
  setStationName,
  setStationId,
}) => {
  const history = useHistory();
  return (
    <div
      className='col-12 col-sm-6 col-md-6  col-lg-4 col-xl-4'
      style={{
        paddingLeft: '16px',
        paddingBottom: '16px',
        marginTop: '0px',
      }}
    >
      <div
        className='border rounded shadow-sm px-4 py-3 bg-white'
        style={{ height: '92px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            className='cursor-pointer'
            style={{ paddingTop: '8px' }}
            onClick={() =>
              history.push(`/app/stations/newstation?uuid=` + stationUuid)
            }
          >
            {stationName}
          </div>
          <div className='d-flex'>
            <NewBoxMenu
              id={stationUuid}
              box={true}
              isMobile={isMobile}
              isTab={isTab}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              delStation={delStation}
              markInactive={markInactive}
              titleText={stationName}
              setTitleText={setTitleText}
              setStationName={setStationName}
              setStationId={setStationId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Station = () => {
  const [isMobile, isTab] = useMobile();
  const history = useHistory();
  const [stationList, setStationList] = useState([]);
  const [titleText, setTitleText] = useState();
  const [stationName, setStationName] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [stationId, setStationId] = useState();
  const dispatch = useDispatch();

  const FetchStation = useCallback(
    async oid => {
      const req = {
        page: 0,
        size: 100,
        req_payload: {
          deleted: [false],
          active: [true],
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_STATION'));
        const fetchSt = await fetchStations(req);
        if (fetchSt && fetchSt.status !== 200) {
          return;
        }
        setStationList(fetchSt.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_STATION'));
      }
    },
    [dispatch],
  );
  useEffect(() => {
    FetchStation();
  }, [FetchStation]);
  const delStation = useCallback(
    async oid => {
      try {
        dispatch(actions.busy.add('DELETE_STATION'));
        const deleteDF = await deleteStation(oid);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Delete Station');
        }
        toastMessage({
          type: 'success',
          message: 'Station Removed Successfully',
        });
        FetchStation();
      } finally {
        dispatch(actions.busy.remove('DELETE_STATION'));
      }
    },
    [dispatch, FetchStation],
  );
  const markInactive = useCallback(
    async oid => {
      const req = {
        active: false,
        approved: true,
        deleted: false,
        req_payload: {
          uuid: oid,
        },
      };
      try {
        dispatch(actions.busy.add('MARK_INACTIVE'));
        const deleteDF = await createStation(req);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Mark as Inactive');
        }
        toastMessage({
          type: 'success',
          message: 'Station Marked as Inactive',
        });
        FetchStation();
      } finally {
        dispatch(actions.busy.remove('MARK_INACTIVE'));
      }
    },
    [dispatch, FetchStation],
  );
  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ToastContainer />
      <BottomDrawerContent
        isMobile={isMobile}
        isTab={isTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        title={titleText}
        delStation={delStation}
        markInactive={markInactive}
        id={stationId}
      />
      <div>
        <div className='container-fluid position-relative px-0'>
          <StationHeader />
          <div style={{ background: 'white', marginLeft: '50px' }}>
            <div
              style={{
                paddingTop: '48px',
                paddingLeft: '16px',
                paddingRight: '16px',
              }}
            >
              <div className='w-100'>
                <div className='row'>
                  {stationList &&
                    stationList.map(op => {
                      return (
                        <BoxDisplay
                          key={op.uuid}
                          stationUuid={op.uuid}
                          stationName={op.name || stationName}
                          isMobile={isMobile}
                          isTab={isTab}
                          delStation={delStation}
                          markInactive={markInactive}
                          titleText={titleText}
                          setTitleText={setTitleText}
                          setStationName={setStationName}
                          menuOpen={menuOpen}
                          setMenuOpen={setMenuOpen}
                          setStationId={setStationId}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            {(!stationList || stationList.length === 0) && <ImageDisplay />}
          </div>
        </div>
      </div>
      <button className='floating-btn'>
        <IconAddBox onClick={() => history.push(`/app/stations/newstation`)} />
      </button>
    </div>
  );
};

export default Station;
