"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.get = void 0;

var _index = _interopRequireDefault(require("./index"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var get = function get(locale) {
  return (0, _index.default)({
    url: "".concat(process.env.PUBLIC_URL, "/lang/").concat(locale, ".json"),
    prepended: false
  });
};

exports.get = get;