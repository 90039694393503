import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
import { cloneDeep } from 'lodash';
const SHIPMENT_NAME = 'Shipment';
const SHIPMENT_KEY = 'SHIPMENT';
export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}
export const TYPES = {
  CURRENT_STATION: 'CURRENT_STATION',
  BRANCH_STATIONS: 'BRANCH_STATIONS',
  BRANCH_CURRENT_STATION: 'BRANCH_CURRENT_STATION',
  STATION_CTX: 'STATION_CTX',
  UPDATE_CACHE: 'UPDATE_CACHE',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  PENDING_SYNC: 'PENDING_SYNC',
};
const getActionName = (type, verb) =>
  actionTypeName('DIGITALPOD_STATION_STATE', type, verb);
export const setStationCtxType = createAction(
  getActionName(TYPES.STATION_CTX, 'TYPESET'),
);
export const setStationCtxValue = createAction(
  getActionName(TYPES.STATION_CTX, 'VALSET'),
);

export const resetDPodStationState = createAction(
  getActionName('RESET', 'RESET'),
);
export const setDPodCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'SET'),
);

export const resetDPodCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'RESET'),
);

export const setDPodBranchStations = createAction(
  getActionName(TYPES.BRANCH_STATIONS, 'SET'),
);

export const setDPodBranchCurrentStation = createAction(
  getActionName(TYPES.BRANCH_CURRENT_STATION, 'SET'),
);

export const setDPodStationCtxType = createAction(
  getActionName(TYPES.STATION_CTX, 'TYPESET'),
);
export const setDPodStationCtxValue = createAction(
  getActionName(TYPES.STATION_CTX, 'VALSET'),
);

export const updateDPodStationCache = createAction(
  getActionName(TYPES.UPDATE_CACHE, 'SET'),
);

export const updateDPodStationLocation = createAction(
  getActionName(TYPES.UPDATE_LOCATION, 'MERGE'),
);

export const appendDPodPendingSync = createAction(
  getActionName(TYPES.PENDING_SYNC, 'APPEND'),
);

export const removeDPodPendingSync = createAction(
  getActionName(TYPES.PENDING_SYNC, 'DELETE'),
);

export const resetDPodPendingSync = createAction(
  getActionName(TYPES.PENDING_SYNC, 'RESET'),
);
export const setDPodRefreshCache = createAction(
  getActionName(TYPES.PENDING_SYNC, 'REFRESH'),
);

export const DEFAULT = {
  current_station: null,
  branch_stations: {},
  ctx: {
    type: { label: 'Shipment', value: 'SHIPMENT' },
    value: {},
  },
  station_location: {},
  station_cache: {
    order_map: {},
    result: [],
  },
  pending_sync: [],
  refresh_cache: false,
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetDPodStationState, state => {
    return Update(state, {
      current_station: { $set: null },
      branch_stations: { $set: {} },
      ctx: {
        $set: {
          type: { label: SHIPMENT_NAME, value: SHIPMENT_KEY },
          value: {},
        },
      },
      station_location: { $set: {} },
      station_cache: {
        $set: {
          order_map: {},
          result: [],
        },
      },
    });
  });
  builder.addCase(resetDPodCurrentStation, state => {
    const branch_stations = cloneDeep(state.branch_stations);
    const current_station = state.current_station;
    if (current_station && branch_stations[`${current_station.branch_id}`]) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    return Update(state, {
      current_station: { $set: null },
      branch_stations: { $set: branch_stations },
    });
  });
  builder.addCase(setDPodCurrentStation, (state, { payload }) => {
    const branch_stations = cloneDeep(state.branch_stations);
    let ctx = cloneDeep(state.ctx) || {};
    const current_station = state.current_station;
    if (
      !payload &&
      current_station &&
      branch_stations[`${current_station.branch_id}`]
    ) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    if (
      current_station &&
      ctx &&
      ctx.value &&
      ctx.value.branch_id !== current_station.branch_id
    ) {
      ctx = {
        type: { label: SHIPMENT_NAME, value: SHIPMENT_KEY },
        value: {},
      };
    }
    if (payload && payload.branch_id) {
      branch_stations[`${payload.branch_id}`] = payload;
    }

    return Update(state, {
      current_station: { $set: payload },
      branch_stations: { $set: branch_stations },
      ctx: { $set: ctx },
    });
  });

  builder.addCase(setDPodBranchCurrentStation, state => {
    const branch_stations = state.branch_stations;
    const user = state.user;
    let current_station = null;
    if (
      user &&
      user.branch &&
      user.branch.branch_id &&
      branch_stations[user.branch.branch_id]
    ) {
      current_station = branch_stations[user.branch.branch_id];
    }
    return Update(state, {
      current_station: { $set: current_station },
    });
  });
  builder.addCase(setDPodStationCtxType, (state, { payload }) => {
    const _payload =
      payload && payload.label && payload.value
        ? payload
        : { label: SHIPMENT_NAME, value: SHIPMENT_KEY };
    const _ctx = cloneDeep(state.ctx);
    if (
      !_payload ||
      !_payload.value ||
      (_ctx.type && _ctx.type.value !== _payload.value)
    ) {
      _ctx.value = {};
    }
    _ctx.type = _payload;
    return Update(state, {
      ctx: {
        $set: _ctx,
      },
    });
  });
  builder.addCase(setDPodStationCtxValue, (state, { payload }) => {
    const _ctx = cloneDeep(state.ctx);
    _ctx.value = payload;
    return Update(state, {
      ctx: {
        $set: _ctx,
      },
    });
  });

  builder.addCase(updateDPodStationLocation, (state, { payload }) => {
    return Update(state, {
      station_location: { $merge: payload },
    });
  });
  builder.addCase(updateDPodStationCache, (state, { payload }) => {
    const p = payload
      ? payload
      : {
          stat: {},
          order_map: {},
          result: [],
        };
    return Update(state, {
      station_cache: { $set: p },
    });
  });
  builder.addCase(appendDPodPendingSync, (state, { payload }) => {
    let pending_sync = cloneDeep(state.pending_sync) || [];
    if (
      payload &&
      payload.scan_ref &&
      payload?.ctx?.type?.value &&
      payload?.ctx?.value?.key
    ) {
      let matchFound = false;
      pending_sync = pending_sync.map(val => {
        if (val.scan_ref === payload.scan_ref) {
          matchFound = true;
          return payload;
        }
        return val;
      });
      if (!matchFound) {
        pending_sync.push(payload);
      }
    }
    return Update(state, {
      pending_sync: { $set: pending_sync },
    });
  });
  builder.addCase(removeDPodPendingSync, (state, { payload }) => {
    let pending_sync = cloneDeep(state.pending_sync) || [];
    if (payload && payload.scan_ref) {
      pending_sync = pending_sync.filter(
        val => val.scan_ref !== payload.scan_ref,
      );
    }
    return Update(state, {
      pending_sync: { $set: pending_sync },
    });
  });
  builder.addCase(resetDPodPendingSync, state => {
    return Update(state, {
      pending_sync: { $set: [] },
    });
  });
  builder.addCase(setDPodRefreshCache, (state, { payload }) => {
    return Update(state, {
      refresh_cache: { $set: payload === true },
    });
  });
});

export default reducer;
