import React from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useBlockLayout,
} from 'react-table';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSticky } from 'react-table-sticky';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '../../../assets/icons';

// Be sure to pass our updateMyData and the skipPageReset option
export default function BOMTable({
  columns,
  data,
  updateMyData,
  skipPageReset,
  addNewRow,
  onRowDelete,
  onRowEdit,
  onRowCopy,
  defaultColumn,
  hiddenColumns,
}) {
  const memo_data = React.useMemo(() => data, [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: memo_data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      initialState: {
        hiddenColumns: hiddenColumns,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useBlockLayout,
    useSticky,
  );
  const onDropdownSelect = data => {
    setPageSize(Number(data));
  };

  return (
    <div className='sticky-table'>
      <div className='tableWrap mb-3 '>
        <div
          className='table sticky'
          {...getTableProps()}
          style={{ maxHeight: '45vh' }}
        >
          <div className='header'>
            {headerGroups.map((headerGroup, idx) => (
              <div
                className='tr mx-auto'
                {...headerGroup.getHeaderGroupProps()}
                key={`t-header-row-${idx}`}
              >
                {headerGroup.headers.map((column, col_idx) => (
                  <div
                    key={`t-header-${col_idx}-${column}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={column.isSorted ? 'active th' : 'th'}
                  >
                    {column.render('Header')}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className='body' {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <div
                  {...row.getRowProps()}
                  className='tr mx-auto'
                  key={`t-body-row-${i}-${row.values['ITEM-EXTERNAL-REFERENCE']}`}
                >
                  {row.cells.map((cell, cell_idx) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className='td'
                        key={`t-body-row-cell-${cell_idx}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell', {
                          onRowDelete: onRowDelete,
                          onRowEdit: onRowEdit,
                          onRowCopy: onRowCopy,
                          row_index: i,
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div className='tr mx-auto' role='row' key={`t-body-row-new-row`}>
              <div className='td' role='cell'>
                <div className='text-button my-10' onClick={addNewRow}>
                  Add New
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.length > 10 ? (
        <Container fluid>
          <Row>
            <Col md={2} sm={6}>
              <Pagination size='md'>
                <Pagination.First
                  disabled={!canPreviousPage}
                  onClick={() => gotoPage(0)}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
            <Col md={2} sm={6}>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </Col>
            <Col md={2} sm={6}>
              <Dropdown className='page-size-selector'>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id='dropdown-page-size-components'
                >
                  <div className=''>
                    <span className='me-2'>Showing {pageSize}</span>
                    <ChevronDownIcon />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[10, 20, 30, 40, 50].map(pageSize_val => (
                    <Dropdown.Item
                      onSelect={onDropdownSelect}
                      active={pageSize_val === pageSize}
                      eventKey={pageSize_val}
                      key={`page-size-${pageSize_val}`}
                    >
                      Showing {pageSize_val}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
      ) : null}
    </div>
  );
}

BOMTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  updateMyData: PropTypes.func,
  skipPageReset: PropTypes.any,
  addNewRow: PropTypes.func,
  onRowDelete: PropTypes.func,
  onRowEdit: PropTypes.func,
  onRowCopy: PropTypes.func,
  defaultColumn: PropTypes.object,
  hiddenColumns: PropTypes.array,
};

// Create an editable cell renderer
export const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const [show_input, setInputState] = React.useState(false);

  const editable_rows = useSelector(state => state.bom_data.editable_rows);

  const onChange = e => {
    setValue(e.target.value);
  };
  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    setInputState(editable_rows[`${index}`]);
  }, [editable_rows, index]);

  return (
    <input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={true}
      disabled={!show_input}
      className='table-editable-input'
    />
  );
};

EditableCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  column: PropTypes.object,
  updateMyData: PropTypes.func,
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className='clickable'
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));
