import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addScreen } from '../../../store/app/screenoverlay/actions';
import { hasCncOperation } from '../../../helpers/tracktrace/orderItemHelper';
import Status from '../../../components/packing/common/status';

const StatusBox = memo(({ count, icon, label, type, onClick }) => {
  const className = ['items-count-wraper'];
  if (onClick) {
    className.push('cursor-pointer');
  }
  switch (type) {
    case 'INFO':
      className.push('info-count-wrapper');
      break;
    case 'SUCCESS':
      className.push('success-count-wrapper');
      break;
    case 'ERROR':
      className.push('error-count-wrapper');
      break;
    case 'WARN':
      className.push('warn-count-wrapper');
      break;
    case 'DISABLED':
      className.push('extra-count-wrapper');
      break;
    default:
  }
  return (
    <div className={className.join(' ')} onClick={onClick}>
      <div className='fs-36'>
        <span>{count}</span>
        {icon && <img src={icon} alt={label} className='pl-4p' />}
      </div>
      <div className='label fs-12 pl-4p' style={{ whiteSpace: 'nowrap' }}>
        {label}
      </div>
    </div>
  );
});

StatusBox.propTypes = {
  count: PropTypes.any,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default StatusBox;
const checkIfHasContext = ctx => {
  return ctx && ctx.value && ctx.value.key ? true : false;
};
export const Progress = memo(
  ({
    onClick,
    label,
    pipelineMode,
    processed,
    total,
    current_station,
    statusActions,
  }) => {
    return (
      <div className='w-100 pb-2'>
        <Status
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          label={label && label.length > 0 ? label : 'Progress'}
          completed={pipelineMode ? '-' : processed || 0}
          total={pipelineMode ? '-' : total}
          statusActions={statusActions}
        />
      </div>
    );
  },
);

export const PendingAndProcessedStatus = memo(
  ({ showOrderItemDetailCB, scannedItems, appendScannedItemsCb, syncCb }) => {
    const { current_station } = useSelector(state => state.stationstate);
    const dispatch = useDispatch();
    const { order_items } = useSelector(state => state.tracktrace);
    const [total, setTotal] = useState(0);
    useEffect(() => {
      const count = scannedItems.filter(val =>
        hasCncOperation(val.order_item, current_station),
      ).length;
      setTotal(count);
    }, [current_station, scannedItems, order_items, setTotal]);

    const addProcessedPartsOverlay = useCallback(() => {
      const overlay = {
        header: 'Items',
        screen: <div>Implement the ProcessedItemMain</div>,
      };
      dispatch(addScreen(overlay));
    }, [dispatch]);
    return (
      <Fragment>
        <StatusBox
          style={{ minWidth: '74px' }}
          count={total}
          label={'TOTAL'}
          type={'INFO'}
          onClick={addProcessedPartsOverlay}
        />
      </Fragment>
    );
  },
);

PendingAndProcessedStatus.propTypes = {
  showOrderItemDetailCB: PropTypes.func.isRequired,
  scannedItems: PropTypes.array.isRequired,
  appendScannedItemsCb: PropTypes.func.isRequired,
};

/* eslint-disable sonarjs/cognitive-complexity */
export const ProcessedStatus = memo(
  ({
    pipelineMode,
    dPodResult,
    current_station,
    ctx,
    statusType,
    labelType,
  }) => {
    const dispatch = useDispatch();
    const [count, setCount] = useState(0);
    const [label, setLabel] = useState('-');
    const [hasContext, setHasContext] = useState(checkIfHasContext(ctx));
    useEffect(() => {
      switch (statusType) {
        case 'ITEM_TOTAL':
          setCount(dPodResult?.stat?.item_total || 0);
          setLabel('Total Items');
          break;
        case 'BOX_TOTAL':
          setCount(dPodResult?.stat?.box_total || 0);
          setLabel('Boxes');
          break;
        case 'ITEM_PENDING':
          const itemPending =
            (dPodResult?.stat?.item_total || 0) -
            (dPodResult?.stat?.item_processed || 0);
          setCount(itemPending);
          setLabel('Pending');
          break;
        case 'BOX_PENDING':
          const boxPending =
            (dPodResult?.stat?.box_total || 0) -
            (dPodResult?.stat?.box_processed || 0);
          setCount(boxPending);
          setLabel('Pending');
          break;
        case 'SHIPMENT_ITEM_COUNT':
          setCount(dPodResult?.stat?.total_shipped || 0);
          setLabel('Current');
          break;
        case 'SHIPMENT_PENDING_COUNT':
          const pendingCount =
            (dPodResult?.result?.length || 0) -
            (dPodResult?.stat?.total_processed || 0);
          setCount(pendingCount);
          setLabel('Pending');
          break;
        case 'CTX_TOTAL':
          const ctxTotalCount = dPodResult?.result?.length || 0;
          setCount(ctxTotalCount);
          setLabel('Total');
          break;

        case 'SHIPMENT_PENDING_BOXES':
        default:
          setCount(0);
      }
    }, [setCount, statusType, dPodResult, setLabel]);

    const addProcessedPartsOverlay = useCallback(() => {
      if (!hasContext) {
        return;
      }
      const overlay = {
        header: statusType,
        screen: <div>TODO - ProcessedItemsMain</div>,
      };
      dispatch(addScreen(overlay));
    }, [hasContext, statusType, dispatch]);
    useEffect(() => {
      setHasContext(checkIfHasContext(ctx));
    }, [ctx]);
    return (
      <Fragment>
        {hasContext === false && <></>}
        {hasContext === true && (
          <StatusBox
            style={{ minWidth: '74px' }}
            count={pipelineMode ? '-' : hasContext ? count : '-'}
            label={hasContext ? label : '-'}
            type={labelType}
            onClick={addProcessedPartsOverlay}
          />
        )}
      </Fragment>
    );
  },
);
/* eslint-enable sonarjs/cognitive-complexity */
ProcessedStatus.propTypes = {
  pipelineMode: PropTypes.string.isRequired,
  dPodResult: PropTypes.object.isRequired,
  current_station: PropTypes.object.isRequired,
  ctx: PropTypes.object.isRequired,
  statusType: PropTypes.string.isRequired,
  labelType: PropTypes.string.isRequired,
};

export const RejectedStatus = memo(
  ({
    showOrderItemDetailCB,
    pipelineMode,
    setScanStateCb,
    scannedItems,
    appendScannedItemsCb,
    syncCb,
  }) => {
    //const { ctx } = useSelector(state => state.stationstate);
    const dispatch = useDispatch();
    const [processed, setProcessed] = useState(0);
    //const [hasContext, setHasContext] = useState(checkIfHasContext(ctx));
    useEffect(() => {
      const count = scannedItems.filter(
        val => val.status === 'REJECTED',
      ).length;
      setProcessed(count);
    }, [scannedItems, setProcessed]);

    const addProcessedPartsOverlay = useCallback(() => {
      setScanStateCb();
      const overlay = {
        header: 'Rejected Items',
        screen: <div>TODO - ProcessedItemsMain</div>,
      };
      dispatch(addScreen(overlay));
    }, [setScanStateCb, dispatch]);
    return (
      <Fragment>
        <StatusBox
          count={pipelineMode ? '-' : processed}
          label='REJECTED'
          type={'ERROR'}
          onClick={addProcessedPartsOverlay}
        />
      </Fragment>
    );
  },
);

RejectedStatus.propTypes = {
  pipelineMode: PropTypes.string.isRequired,
  dPodResult: PropTypes.object.isRequired,
  current_station: PropTypes.object.isRequired,
  ctx: PropTypes.object.isRequired,
  statusType: PropTypes.string.isRequired,
  labelType: PropTypes.string.isRequired,
};
