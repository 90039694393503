import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) => actionTypeName('USERS_STATE', type, verb);

export const TYPES = {
  USERS_FILTER: 'USERS_FILTER',
  USERS_INVITED_FILTER: 'USERS_ACTIVE_FILTER',
  USERS_ACTIVE_FILTER: 'USERS_ACTIVE_FILTER',
  USERS_INACTIVE_FILTER: 'USERS_INACTIVE_FILTER',
  USERS_SHOWMORE_FILTER: 'USERS_SHOWMORE_FILTER',
  USERS_CREATED_MESSAGE: 'USERS_CREATED_MESSAGE',
  ERROR_SOUND_CONTROL: 'ERROR_SOUND_CONTROL',
  WARNING_SOUND_CONTROL: 'WARNING_SOUND_CONTROL',
  SUCCESS_SOUND_CONTROL: 'SUCCESS_SOUND_CONTROL',
};

export const setUsersFilter = createAction(
  getActionName(TYPES.USERS_FILTER, 'SET'),
);

export const setUsersActiveFilter = createAction(
  getActionName(TYPES.USERS_ACTIVE_FILTER, 'SET'),
);
export const setUsersInactiveFilter = createAction(
  getActionName(TYPES.USERS_INACTIVE_FILTER, 'SET'),
);
export const setUsersShowmoreFilter = createAction(
  getActionName(TYPES.USERS_SHOWMORE_FILTER, 'SET'),
);
export const setUsersCreatedmessage = createAction(
  getActionName(TYPES.USERS_CREATED_MESSAGE, 'SET'),
);
export const setErrorSoundControl = createAction(
  getActionName(TYPES.ERROR_SOUND_CONTROL, 'SET'),
);
export const setWarningSoundControl = createAction(
  getActionName(TYPES.WARNING_SOUND_CONTROL, 'SET'),
);
export const setSuccessSoundControl = createAction(
  getActionName(TYPES.SUCCESS_SOUND_CONTROL, 'SET'),
);

export const DEFAULT = {
  active: true,
  search_term: '',
  inactive: false,
  showmore: false,
  createdmessage: '',
  errorsoundcontrol: false,
  warningsoundcontrol: false,
  successsoundcontrol: false,
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setUsersFilter, (state, { payload }) => {
    return Update(state, {
      search_term: { $set: payload },
    });
  });
  builder.addCase(setUsersActiveFilter, (state, { payload }) => {
    return Update(state, {
      active: { $set: payload },
    });
  });
  builder.addCase(setUsersInactiveFilter, (state, { payload }) => {
    return Update(state, {
      inactive: { $set: payload },
    });
  });
  builder.addCase(setUsersShowmoreFilter, (state, { payload }) => {
    return Update(state, {
      showmore: { $set: payload },
    });
  });
  builder.addCase(setUsersCreatedmessage, (state, { payload }) => {
    return Update(state, {
      createdmessage: { $set: payload },
    });
  });
  builder.addCase(setErrorSoundControl, (state, { payload }) => {
    return Update(state, {
      errorsoundcontrol: { $set: payload },
    });
  });
  builder.addCase(setWarningSoundControl, (state, { payload }) => {
    return Update(state, {
      warningsoundcontrol: { $set: payload },
    });
  });
  builder.addCase(setSuccessSoundControl, (state, { payload }) => {
    return Update(state, {
      successsoundcontrol: { $set: payload },
    });
  });
});

export default reducer;
