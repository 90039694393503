import { fetchConnectedPrinters } from '../../services/org';
import { addPrinter } from '../../store/printers/connectedprinters/reducer';
import moment from 'moment';

export const refreshConnectedPrinters = (dispatch, interval) => {
  const intervalId = setInterval(() => {
    fetchConnectedPrinters({ deleted: [false] }).then(result => {
      if (result.status === 200) {
        result.payload.forEach(val => {
          dispatch(addPrinter(val));
        });
      }
      const val = {
        printer_id: 'OFFLINE_PDF_PRINTER',
        name: 'PDF Download',
        selected: true,
      };
      dispatch(addPrinter(val));
      //console.log(result);
    });
  }, interval);
  return () => clearInterval(intervalId);
};
export const getHumanizedDuration = printer => {
  if (!printer.connected_on) {
    return null;
  }
  const lastConnectedTime = moment().diff(
    moment(printer.connected_on),
    'minutes',
  );
  return moment.duration(lastConnectedTime, 'minutes').humanize();
};

export const getConnectedPrinters = async (dispatch, defaultPrinter) => {
  const reqData = {
    deleted: [false],
  };
  const resp = await fetchConnectedPrinters(reqData);
  if (resp.status !== 200) {
    console.log(resp);
    return [];
  }
  const printers = resp.payload;
  if (defaultPrinter && defaultPrinter.printer_id) {
    dispatch(addPrinter(defaultPrinter));
  }
  printers.forEach(val => {
    dispatch(addPrinter(val));
  });
  if (defaultPrinter && defaultPrinter.printer_id !== 'OFFLINE_PDF_PRINTER') {
    dispatch(
      addPrinter({
        printer_id: 'OFFLINE_PDF_PRINTER',
        name: 'PDF Download',
        selected: false,
      }),
    );
  }

  return printers;
};

export const isPrinterConnected = printer => {
  let returnVal = true;
  if (!printer) {
    returnVal = false;
  } else if (printer.printer_id === 'OFFLINE_PDF_PRINTER') {
    returnVal = true;
  } else if (!printer.connected_on) {
    returnVal = false;
  } else {
    const now = new Date().getTime();
    const conncted = new Date(printer.connected_on).getTime();
    if (now - conncted > 60000) {
      returnVal = false;
    }
  }

  return returnVal;
};
