import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import backBtn from '../../assets/icons/backBtn.svg';
//import { useDispatch } from 'react-redux';
//import { toggleSideMenu } from '../../store/app/nav/actions';
//import IconMenu from '../../assets/icons/icon-menu.svg';

import NewBoxMenu from './newbox-menu';
const StationHeader = ({
  headertext,
  stationUuid,
  isMobile,
  isTab,
  setMenuOpen,
  delStation,
  markInactive,
  titleText,
  setTitleText,
  stationName,
  setStationName,
  setStationId,
}) => {
  /*const dispatch = useDispatch();
  const handleMenuToggle = () => {
    dispatch(toggleSideMenu());
  };*/
  const history = useHistory();
  return (
    <Fragment>
      <div
        className='flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 '
        style={{ zIndex: 10, width: '100%' }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          type='button'
        >
          <img alt='' src={backBtn} onClick={() => history.goBack()} />
        </button>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div className='ms-2 page-header'>
            <div className='page-title d-flex align-items-center'>
              <div className='ps-2'>
                {stationName ? stationName : 'Create New Station'}
              </div>
            </div>
          </div>

          <NewBoxMenu
            id={stationUuid}
            box={false}
            isMobile={isMobile}
            isTab={isTab}
            setMenuOpen={setMenuOpen}
            delStation={delStation}
            markInactive={markInactive}
            titleText={headertext}
            setTitleText={setTitleText}
            setStationName={setStationName}
            setStationId={setStationId}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default StationHeader;
