import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import { setGroupByCols } from '../../../store/order/bom/bomDataDuck';

function ChevronDownIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='8'
      fill='none'
      viewBox='0 0 12 8'
    >
      <path fill='#007BFF' d='M1.41.59L6 5.17 10.59.59 12 2 6 8 0 2 1.41.59z' />
    </svg>
  );
}

const BomColumnSelection = props => {
  const dispatch = useDispatch();
  const [is_dropdown_open, setDropdownState] = useState(false);
  const group_by_cols = useSelector(state => state.bom_data.group_by_cols);
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display ${
          is_dropdown_open ? 'active' : ''
        }`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );

  const onDropdownSelect = data => {
    dispatch(setGroupByCols(data));
  };

  const onDropdownToggle = status => {
    setDropdownState(status);
  };
  const hideFilters = props.hideFilters || [];
  const filters = props.filters.filter(
    val => hideFilters.indexOf(val.Header) === -1,
  );
  return (
    <div className='d-flex position-relative'>
      <Dropdown
        className='page-size-selector'
        onToggle={onDropdownToggle}
        flip={'false'}
        drop={'down'}
      >
        <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
          <span className={`btn-label text-primary pe-2`}>Select column</span>
          <ChevronDownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu
          className='overflow-auto  dropdown'
          style={{ maxWidth: '300px', maxHeight: 'calc(100vh - 300px)' }}
          align={{ sm: 'right' }}
        >
          {filters.map(filter => (
            <Dropdown.Item
              onClick={() => {
                onDropdownSelect(filter.Header);
              }}
              disabled={group_by_cols.includes(filter.Header)}
              key={filter.Header}
              eventKey={filter.Header}
              className='text-uppercase'
            >
              {filter.Header}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

BomColumnSelection.propTypes = {
  filters: PropTypes.array.isRequired,
  hideFilters: PropTypes.array,
};

export default BomColumnSelection;
