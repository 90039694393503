export const sortBy = f => (x, y) => {
  const aval = x[`${f}`];
  const bval = y[`${f}`];
  if (!isNaN(aval) && !isNaN(bval)) {
    return parseInt(aval) - parseInt(bval);
  }
  const a = (aval + '').toLowerCase();
  const b = (bval + '').toLowerCase();
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export default sortBy;
