"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.objectToParams = exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

require("../configs/url");

var _excluded = ["prepended", "headers", "method"];

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var service = function service(_ref) {
  var _ref$prepended = _ref.prepended,
      prepended = _ref$prepended === void 0 ? true : _ref$prepended,
      _ref$headers = _ref.headers,
      headers = _ref$headers === void 0 ? {} : _ref$headers,
      _ref$method = _ref.method,
      method = _ref$method === void 0 ? 'GET' : _ref$method,
      rest = _objectWithoutProperties(_ref, _excluded);

  var params = {};

  if (prepended) {
    rest.url = BASE_URL + rest.url;
  }

  if (method.toUpperCase() === 'GET' && rest.data) {
    params = _objectSpread(_objectSpread({}, params), rest.data);
  }

  rest.url += objectToParams(params);
  return (0, _axios.default)(_objectSpread(_objectSpread({}, rest), {}, {
    method: method,
    headers: headers
  })).then(function (response) {
    return response.data;
  });
};

var objectToParams = function objectToParams(o) {
  var s = '';

  for (var k in o) {
    var v = o[k.toString()];
    s += s ? '&' : '?';
    s += k + '=' + encodeURIComponent(v);
  }

  return s;
};

exports.objectToParams = objectToParams;
var _default = service;
exports.default = _default;