import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import '../../../scss/components/_dropdown.scss';
import BillOfMaterialsComponent from './billOfOrder';

import ScreenLoader from '../../../components/common/screen-loader';
import { ToastContainer } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import AddNewBomComponent from '../create_order_views/addNewBom';
import backBtn from '../../../assets/icons/backBtn.svg';
import { useDispatch, useSelector } from 'react-redux';
import ScreenModal from '../../../components/common/screen-modal';
import { ActionWithSubMenu } from '../../../components/order/order-improvement/actions-menu';
import { releaseOrderToProductionAsync } from '../../../store/order/bom/bomDataDuck';
import { cloneDeep, isEqual } from 'lodash';
import {
  cancelOrder,
  closeOrder,
  //reopenOrder,
  updateOrderState,
  //getSourcesForOrderSync,
} from '../../../store/order/bom/bomDataDuck';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useMobile from '../../../hooks/useMobile';
import {
  deleteOrderSrcService,
  recallOrderSrcService,
  getOrderSrcFormatDownloadUrlService,
} from '../../../services/order';
import { getBomDetailsBomId } from '../../../services/bom';
import {
  closeConfirmScreen,
  displayConfirmScreen,
} from '../../../store/app/screenoverlay/actions';

import NaadiConfirmScreen from '../../../components/common/naadi-confirm';
import { getSourcesForOrder } from '../../../store/order/bom/bomDataDuck';
import { downloadOrderAttachment } from '../../../helpers/order/orderHelper';
import { fetchOrderDetails } from '../../../store/order/ordersList/actions';
import BottomDrawerContent from './bottom-drawer-menu';
import { NaadiSmallLogoIcon } from '../../../assets/icons/naadi-small-logo';
import { getResponseErrorMessage } from '../../../helpers/common/helper';
import { getState } from '../../../store';
import { actions, ResponseCodes } from '@naadi/framework';
import { FaAngleUp } from 'react-icons/fa';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  toastError,
  toastMessage,
  toastWarning,
} from '../../../helpers/packing/packingHelper';
import {
  getOrderSrcClientService,
  getOrderSrcFromClientService,
} from '../../../services/order';
import { expandOrderSrcService } from '../../../services/bom';
const INPROD = 'In Production';
const SELORD = 'Please Select Orders';
const SELBOM = 'Please Select BOM';
const REOPEN = 'Reopen Order';
const ActionConfirmModal = ({
  closeCb,
  confirmCb,
  orderName,
  confirmText,
  actionText,
}) => {
  const [isMobile] = useMobile();
  return (
    <Modal
      centered={!isMobile}
      size={'md'}
      show={true}
      onHide={() => {
        closeCb();
      }}
      dialogClassName='track-trace-dialog'
    >
      <Modal.Header className='px-32' style={{ borderBottom: '0px' }}>
        <Modal.Title>Order {orderName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{confirmText}</div>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: '0px' }}>
        <div className='d-flex w-100'>
          <div className='flex-1 text-right pe-2 ps-6'>
            <Button
              variant='outline-primary'
              size='lg '
              className='w-100'
              onClick={e => {
                closeCb();
              }}
            >
              Cancel
            </Button>
          </div>
          <div className='flex-1 text-left w-100 ps-2 pe-6'>
            <Button
              type='submit'
              className='ms-2 w-100'
              size='lg'
              onClick={e => {
                confirmCb();
              }}
            >
              {actionText}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
const ChildOrderManagementHeader = ({
  selectedOrders,
  selectedBoms,
  selectedBomOrd,
  order_id,
  order_details,
  setOrderStatusConfirm,
  setOrderLoaderCb,
  setBomLoaderCb,
  menuActionText,
  setMenuActionText,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const bom_data = useSelector(state => state.bom_data);

  const cancelSelectedOrders = useCallback(async () => {
    dispatch(closeConfirmScreen());
    for (let i = 0; i < selectedOrders.length; i++) {
      const selectedOrder = selectedOrders[`${i}`];
      setOrderLoaderCb(selectedOrder, true, '');

      const cancelResp = await new Promise(resolve => {
        dispatch(cancelOrder(selectedOrder, resolve, true));
      });

      setOrderLoaderCb(selectedOrder, false, cancelResp);
    }
  }, [dispatch, selectedOrders, setOrderLoaderCb]);

  const onCancelOrder = useCallback(() => {
    if (!selectedOrders || selectedOrders.length <= 0) {
      toastWarning(SELORD);
      return;
    }

    setMenuActionText('Cancel Order');
    const cancelCancel = () => {
      dispatch(closeConfirmScreen());
    };
    const confirmScreen = (state, error) => {
      const secondaryButton = (
        <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
      );
      return (
        <NaadiConfirmScreen
          message={<div>Do you really want to Cancel this Order?</div>}
          state={state}
          primaryButton={<div>Cancel Order</div>}
          primaryAction={cancelSelectedOrders}
          secondaryButton={secondaryButton}
          secondaryAction={cancelCancel}
          error={error}
        />
      );
    };
    dispatch(
      displayConfirmScreen({
        content: confirmScreen('initial', null),
        boundingClass: null,
        boundingStyle: null,
      }),
    );
  }, [selectedOrders, setMenuActionText, cancelSelectedOrders, dispatch]);

  const onCloseOrder = useCallback(() => {
    const closeSelectedOrders = async () => {
      dispatch(closeConfirmScreen());
      for (const selectedOrder of selectedOrders) {
        setOrderLoaderCb(selectedOrder, true, '');
        const closeResp = await new Promise(resolve => {
          dispatch(closeOrder(selectedOrder, resolve, true));
        });
        setOrderLoaderCb(selectedOrder, false, closeResp);
      }
    };

    if (!selectedOrders || selectedOrders.length <= 0) {
      toastWarning(SELORD);
      return;
    }

    setMenuActionText('Close Order');

    const cancelClose = () => {
      dispatch(closeConfirmScreen());
    };
    const confirmScreen = (state, error) => {
      const secondaryButton = (
        <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
      );
      return (
        <NaadiConfirmScreen
          message={<div>Do you really want to Close this Order?</div>}
          state={state}
          primaryButton={<div>Close Order</div>}
          primaryAction={closeSelectedOrders}
          secondaryButton={secondaryButton}
          secondaryAction={cancelClose}
          error={error}
        />
      );
    };
    dispatch(
      displayConfirmScreen({
        content: confirmScreen('initial', null),
        boundingClass: null,
        boundingStyle: null,
      }),
    );
  }, [dispatch, selectedOrders, setOrderLoaderCb, setMenuActionText]);

  const functionSelectedOrders = useCallback(
    async status => {
      for (let i = 0; i < selectedOrders.length; i++) {
        const selectedOrder = selectedOrders[`${i}`];
        setOrderLoaderCb(selectedOrder, true, '');

        const reopenResp = await new Promise(resolve => {
          dispatch(updateOrderState(selectedOrder, status, resolve, true));
        });

        setOrderLoaderCb(selectedOrder, false, reopenResp);
      }
    },
    [dispatch, selectedOrders, setOrderLoaderCb],
  );

  const confirmCb = useCallback(async () => {
    dispatch(closeConfirmScreen());
    await functionSelectedOrders('CREATED');
  }, [dispatch, functionSelectedOrders]);

  const onReopenOrder = useCallback(() => {
    if (!selectedOrders || selectedOrders.length <= 0) {
      toastWarning(SELORD);
      return;
    }

    setMenuActionText(REOPEN);

    const cancelReopen = () => {
      dispatch(closeConfirmScreen());
    };
    const confirmScreen = (state, error) => {
      const secondaryButton = (
        <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
      );
      return (
        <NaadiConfirmScreen
          message={<div>Do you really want to Reopen this Order?</div>}
          state={state}
          primaryButton={<div>Reopen</div>}
          primaryAction={confirmCb}
          secondaryButton={secondaryButton}
          secondaryAction={cancelReopen}
          error={error}
        />
      );
    };
    dispatch(
      displayConfirmScreen({
        content: confirmScreen('initial', null),
        boundingClass: null,
        boundingStyle: null,
      }),
    );
  }, [dispatch, selectedOrders, setMenuActionText, confirmCb]);

  const onInProduction = useCallback(() => {
    if (!selectedOrders || selectedOrders.length <= 0) {
      toastWarning(SELORD);
      return;
    }

    setMenuActionText(INPROD);

    const cancelInproduction = () => {
      dispatch(closeConfirmScreen());
    };
    const confirmScreen = (state, error) => {
      const secondaryButton = (
        <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
      );
      return (
        <NaadiConfirmScreen
          message={
            <div>Do you really want to set Inproduction this Order?</div>
          }
          state={state}
          primaryButton={<div>Inproduction</div>}
          primaryAction={() => {
            dispatch(closeConfirmScreen());
            functionSelectedOrders('PRODUCTION');
          }}
          secondaryButton={secondaryButton}
          secondaryAction={cancelInproduction}
          error={error}
        />
      );
    };
    dispatch(
      displayConfirmScreen({
        content: confirmScreen('initial', null),
        boundingClass: null,
        boundingStyle: null,
      }),
    );
  }, [dispatch, selectedOrders, setMenuActionText, functionSelectedOrders]);

  const onOnHold = useCallback(() => {
    if (!selectedOrders || selectedOrders.length <= 0) {
      toastWarning(SELORD);
      return;
    }

    setMenuActionText('on Hold');

    const cancelOnHold = () => {
      dispatch(closeConfirmScreen());
    };
    const confirmScreen = (state, error) => {
      const secondaryButton = (
        <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
      );
      return (
        <NaadiConfirmScreen
          message={<div>Do you really want to set OnHold this Order?</div>}
          state={state}
          primaryButton={<div>OnHold</div>}
          primaryAction={() => {
            dispatch(closeConfirmScreen());
            functionSelectedOrders('HOLD');
          }}
          secondaryButton={secondaryButton}
          secondaryAction={cancelOnHold}
          error={error}
        />
      );
    };
    dispatch(
      displayConfirmScreen({
        content: confirmScreen('initial', null),
        boundingClass: null,
        boundingStyle: null,
      }),
    );
  }, [dispatch, selectedOrders, setMenuActionText, functionSelectedOrders]);
  const refreshOrderSrcList = useCallback(
    orderId => {
      if (orderId && orderId.length > 0) {
        dispatch(getSourcesForOrder(dispatch, orderId, false));
      }
    },
    [dispatch],
  );
  const refreshOrderSrcListAll = useCallback(
    async showLoader => {
      for (let i = 0; i < selectedOrders.length; i++) {
        const selectedOrder = selectedOrders[`${i}`];
        refreshOrderSrcList(selectedOrder);
      }
    },
    [selectedOrders, refreshOrderSrcList],
  );

  const releaseToProduction = useCallback(async () => {
    //Loop through the selected orders and release to production
    //console.log('Release');
    if (!selectedOrders || selectedOrders.length <= 0) {
      toastWarning(SELORD);
      return;
    }
    setMenuActionText('Release to Production');
    let releaseResp = {};
    for (let i = 0; i < selectedOrders.length; i++) {
      const selectedOrder = selectedOrders[`${i}`];
      if (selectedBomOrd.find(val => val.order_id === selectedOrder)) {
        setOrderLoaderCb(selectedOrder, true, '');
        releaseResp = await releaseOrderToProductionAsync(
          selectedOrder,
          null,
          true,
          dispatch,
          null,
          bom_data,
        );
        setOrderLoaderCb(selectedOrder, false, releaseResp);
      }
      //releaseResp use the response to populate the status
    }
    refreshOrderSrcListAll();
  }, [
    dispatch,
    selectedOrders,
    bom_data,
    setOrderLoaderCb,
    selectedBomOrd,
    refreshOrderSrcListAll,
    setMenuActionText,
  ]);
  const refreshAllOrderSrcList = useCallback(() => {
    if (order_details && order_details.order_id) {
      refreshOrderSrcList(order_details.order_id);
    }
    order_details.child_orders.forEach(childOrder => {
      refreshOrderSrcList(childOrder.uuid);
    });
  }, [refreshOrderSrcList, order_details]);

  const recallOrderSrcHandler = useCallback(
    order_src => {
      if (!selectedBoms || selectedBoms.length === 0) {
        toastError(SELBOM);
        return;
      }
      setMenuActionText('Recall BOM');
      const _confirmRecallOrderSrc = async () => {
        dispatch(closeConfirmScreen());
        toastMessage({
          type: 'success',
          message: 'BOM Recall Initiated',
        });
        const recalledBomPromises = [];
        for (let i = 0; i < selectedBoms.length; i++) {
          const bomId = selectedBoms[`${i}`];
          setBomLoaderCb(bomId, true, {});
          const recallPromise = recallOrderSrcService(bomId);
          recalledBomPromises.push({
            bomId: bomId,
            promise: recallPromise,
          });
        }
        for (let i = 0; i < recalledBomPromises.length; i++) {
          const recallPromise = recalledBomPromises[`${i}`];
          const tm = {
            type: 'success',
            message: 'Bom Recalled Successfully',
          };
          try {
            const result = await recallPromise.promise;
            if (result.status !== ResponseCodes.SUCCESS) {
              const err =
                result.err && result.err.err
                  ? result.err.err
                  : 'Unable to Recall the Order Src';
              tm.type = 'error';
              tm.message = err;
            } else {
              refreshOrderSrcList(result.payload?.order_id);
            }
          } catch (err) {
            console.log(err);
            tm.type = 'error';
            tm.message = '' + err;
          } finally {
            setBomLoaderCb(recallPromise.bomId, false, tm);
          }
        }

        //console.log(result);
      };

      const cancelRecallOrderSrc = () => {
        dispatch(closeConfirmScreen());
      };
      const confirmScreen = (state, error) => {
        const secondaryButton = (
          <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
        );
        return (
          <NaadiConfirmScreen
            message={<div>Do you really want to recall this BOM?</div>}
            state={state}
            primaryButton={<div>RECALL</div>}
            primaryAction={_confirmRecallOrderSrc}
            secondaryButton={secondaryButton}
            secondaryAction={cancelRecallOrderSrc}
            error={error}
          />
        );
      };
      dispatch(
        displayConfirmScreen({
          content: confirmScreen('initial', null),
          boundingClass: null,
          boundingStyle: null,
        }),
      );
    },
    [
      dispatch,
      refreshOrderSrcList,
      selectedBoms,
      setBomLoaderCb,
      setMenuActionText,
    ],
  );

  const deleteOrderSrcHandler = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    selectedBoms => {
      if (!selectedBoms || selectedBoms.length === 0) {
        toastError(SELBOM);
        return;
      }
      setMenuActionText('Delete BOM');
      const _confirmDeleteOrderSrc = async () => {
        dispatch(closeConfirmScreen());
        toastMessage({
          type: 'success',
          message: 'BOM Deletion Initiated',
        });
        const deletedBomPromises = [];
        for (let i = 0; i < selectedBoms.length; i++) {
          const bomId = selectedBoms[`${i}`];
          setBomLoaderCb(bomId, true, {});
          const resultPromise = deleteOrderSrcService(bomId);
          deletedBomPromises.push({
            bomId: bomId,
            promise: resultPromise,
          });
        }
        for (let i = 0; i < deletedBomPromises.length; i++) {
          const resultPromise = deletedBomPromises[`${i}`];
          const tm = {
            type: 'success',
            message: 'Bom Deleted Successfully',
          };
          try {
            const result = await resultPromise.promise;
            if (result.status !== ResponseCodes.SUCCESS) {
              const err =
                result.err && result.err.err
                  ? result.err.err
                  : 'Unable to Delete the Order Src';
              tm.type = 'error';
              tm.message = err;
            }
          } finally {
            setBomLoaderCb(resultPromise.bomId, false, tm);
          }
        }
        refreshOrderSrcListAll();
      };

      const cancelDeleteOrderSrc = () => {
        dispatch(closeConfirmScreen());
      };
      const confirmScreen = (state, error) => {
        const secondaryButton = (
          <div>{state === 'success' ? 'CLOSE' : 'CANCEL'}</div>
        );
        return (
          <NaadiConfirmScreen
            message={<div>Do you really want to delete this BOM?</div>}
            state={state}
            primaryButton={<div>DELETE</div>}
            primaryAction={_confirmDeleteOrderSrc}
            secondaryButton={secondaryButton}
            secondaryAction={cancelDeleteOrderSrc}
            error={error}
          />
        );
      };
      dispatch(
        displayConfirmScreen({
          content: confirmScreen('initial', null),
          boundingClass: null,
          boundingStyle: null,
        }),
      );
    },
    [dispatch, setBomLoaderCb, refreshOrderSrcListAll, setMenuActionText],
  );

  const onDesignToolUploadClick = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async orderId => {
      const { busy } = getState();
      if (busy.includes('FETCH_SRC_FROM_DESIGN_TOOL' + orderId)) {
        return;
      }
      try {
        dispatch(actions.busy.add('FETCH_SRC_FROM_DESIGN_TOOL' + orderId));
        const clientFilter = {
          page: 0,
          size: 20,
          req_payload: {
            client_type: ['DESIGN_TOOL', 'IMOS'],
            deleted: [false],
          },
        };
        const clientListResp = await getOrderSrcClientService(clientFilter);
        if (clientListResp.status !== ResponseCodes.SUCCESS) {
          const err =
            clientListResp.err && clientListResp.err.err
              ? clientListResp.err.err
              : 'Unable to Fetch the Client Detail';
          toastError(err);
          return;
        }
        const designToolClients = clientListResp.payload;
        if (designToolClients.length === 0) {
          toastError('There are No Design Tool Clients Registered for the Org');
          return;
        }
        const errList = [];
        for (let i = 0; i < designToolClients.length; i++) {
          const designToolClient = designToolClients[`${i}`];
          const addOrderSrcResp = await getOrderSrcFromClientService(
            designToolClient.uuid,
            orderId,
          );
          if (addOrderSrcResp.status !== 200) {
            const err =
              addOrderSrcResp.err && addOrderSrcResp.err.err
                ? addOrderSrcResp.err.err
                : 'Unable to Connect to the Client';
            //toastError(err);
            //break;
            errList.push(err);
            continue;
          }
          if (
            !addOrderSrcResp.payload.import_job_id ||
            addOrderSrcResp.payload.import_job_id.length === 0
          ) {
            const expandResult = await expandOrderSrcService(
              addOrderSrcResp.payload.uuid,
            );
            if (expandResult.status !== ResponseCodes.SUCCESS) {
              toastError('Order Fetched From Design Tool, Validation Failed');
            } else {
              toastMessage({
                type: 'success',
                message: 'Order Fetched from Design Tool',
              });
            }
          } else {
            toastMessage({
              type: 'success',
              message: 'Order Fetched from Design Tool',
            });
          }

          dispatch(getSourcesForOrder(dispatch, order_id, true));
          errList.splice(0, errList.length);
          break;
        }
        if (errList.length > 0) {
          errList.forEach(err => {
            toastError(err);
          });
        }
      } finally {
        dispatch(actions.busy.remove('FETCH_SRC_FROM_DESIGN_TOOL' + orderId));
      }
    },
    [dispatch, order_id],
  );
  const fetchAllBOM = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity

    async selOrders => {
      setMenuActionText('Fetch BOM');
      if (!selectedOrders || selectedOrders.length === 0) {
        toastError('Please Select Order');
        return;
      }
      dispatch(closeConfirmScreen());
      toastMessage({
        type: 'success',
        message: 'Design Tool BOM Fetching Initiated',
      });
      const fetchOrderPromises = [];
      for (let i = 0; i < selOrders.length; i++) {
        const orderId = selectedOrders[`${i}`];
        setOrderLoaderCb(orderId, true, {});

        const resultPromise = onDesignToolUploadClick(orderId);
        fetchOrderPromises.push({
          orderId: orderId,
          promise: resultPromise,
        });
      }
      for (let i = 0; i < fetchOrderPromises.length; i++) {
        const resultPromise = fetchOrderPromises[`${i}`];
        const tm = {
          type: 'success',
          message: 'Design Tool Bom Fetched Successfully',
        };
        try {
          const result = await resultPromise.promise;
          if (result.status !== ResponseCodes.SUCCESS) {
            const err =
              result.err && result.err.err
                ? result.err.err
                : 'Unable to Fetch Design Tool BOM';
            tm.type = 'error';
            tm.message = err;
          }
        } catch (err) {
          console.log(err);
        } finally {
          setOrderLoaderCb(resultPromise.orderId, false, {});
        }
      }
    },
    [
      dispatch,
      onDesignToolUploadClick,
      setOrderLoaderCb,
      selectedOrders,
      setMenuActionText,
    ],
  );
  const [isMobile, isTab] = useMobile();
  const [menuClick, setMenuClick] = useState('main');
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    let canceltitle = '';
    let cancelonClick = '';
    let prodtitle = '';
    let prodonClick = '';
    if (
      order_details.status === 'CLOSED' ||
      order_details.status === 'CANCELLED'
    ) {
      canceltitle = REOPEN;
      cancelonClick = onReopenOrder;
    }
    if (order_details.status !== 'CLOSED') {
      canceltitle = 'Close order';
      cancelonClick = onCloseOrder;
    }
    if (order_details.status === 'PRODUCTION') {
      prodtitle = 'on Hold';
      prodonClick = onOnHold;
    }
    if (order_details.status !== 'PRODUCTION') {
      prodtitle = INPROD;
      prodonClick = onInProduction;
    }
    switch (menuActionText) {
      case 'Close Order':
        canceltitle = REOPEN;
        cancelonClick = onReopenOrder;
        break;
      case REOPEN:
        canceltitle = 'Close order';
        cancelonClick = onCloseOrder;
        break;
      case INPROD:
        prodtitle = 'On Hold';
        prodonClick = onOnHold;
        break;
      case 'on Hold':
        prodtitle = INPROD;
        prodonClick = onInProduction;
        break;
      default:
    }
    setMenuList([
      {
        title: 'BOM ACTIONS',
        children: [
          {
            title: 'FETCH',
            onClick: () => {
              fetchAllBOM(selectedOrders);
            },
          },
          {
            title: 'RECALL',
            onClick: () => {
              recallOrderSrcHandler(selectedBoms);
            },
          },
          {
            title: 'DOWNLOAD',
            onClick: () => {
              setMenuActionText('Download BOM');
            },
          },
          {
            title: 'DELETE',
            onClick: () => {
              deleteOrderSrcHandler(selectedBoms);
            },
          },
        ],
      },
      {
        title: 'ORDER ACTIONS',
        children: [
          {
            key: 'releasetoprod',
            title: 'Release to production',
            onClick: releaseToProduction,
          },

          {
            key: 'canceltitle',
            title: canceltitle,
            onClick: cancelonClick,
          },
          {
            key: 'prodtitle',
            title: prodtitle,
            onClick: prodonClick,
          },
          {
            key: 'cancelord',
            title: 'Cancel order',
            onClick: onCancelOrder,
          },
        ],
      },
    ]);
  }, [
    order_id,
    selectedBoms,
    order_details,
    refreshOrderSrcList,
    onCancelOrder,
    onReopenOrder,
    onOnHold,
    onCloseOrder,
    onInProduction,
    releaseToProduction,
    deleteOrderSrcHandler,
    recallOrderSrcHandler,
    refreshOrderSrcListAll,
    refreshAllOrderSrcList,
    fetchAllBOM,
    selectedOrders,
    menuActionText,
    setMenuActionText,
  ]);

  return (
    <Fragment>
      <div
        className='flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 '
        style={{ zIndex: 10, width: '100%' }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          type='button'
        >
          <img alt='' src={backBtn} onClick={() => history.goBack()} />
        </button>
        <div className='ms-2 page-header'>
          <div className='page-title d-flex align-items-center d-flex'>
            <div className='ps-2'>
              {isMobile || isTab ? 'Child Orders' : 'Child Order  Management'}
            </div>
            <BottomDrawerContent
              isMobile={isMobile}
              isTab={isTab}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              title={'Actions'}
              menuList={menuList}
              menuClick={menuClick}
              setMenuClick={setMenuClick}
            />
            <div style={{ width: '20px' }}></div>
            <div>
              <div
                style={{
                  fontSize: '12px',
                  background: 'rgba(2, 122, 255, 0.10)',
                  width: 'auto',
                  padding: '5px',
                  paddingLeft: isMobile || isTab ? '10px' : '',
                  paddingRight: isMobile || isTab ? '10px' : '',
                  cursor: 'pointer',
                }}
              >
                {isMobile || isTab ? (
                  <div
                    onClick={() => setMenuOpen(true)}
                    style={{
                      color: '#006EED',
                      textAlign: 'center',
                      padding: '5px',
                    }}
                  >
                    {'ACTIONS'}
                  </div>
                ) : (
                  <ActionWithSubMenu title={'ACTIONS'} menuList={menuList} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ChildOrderManagement = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [order_id, setOrderId] = useState('');
  const history = useHistory();
  const [showBomMenu, setShowBomMenu] = useState({});
  const [showCsvEditModal, setShowCsvEditModal] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedBoms, setSelectedBoms] = useState([]);
  const [selectedBomOrd, setSelectedBomOrd] = useState([]);
  const [isMobile, isTab] = useMobile();
  const order_details_map = useSelector(
    state => state.ordersList.orders_details,
  );
  const [order_details, setOrderDetails] = useState({});
  const [menuActionText, setMenuActionText] = useState('ACTIONS');
  const [orderStatusConfirm, setOrderStatusConfirm] = useState({
    show: false,
  });

  const loadingState = useRef({
    order_detail: false,
    customer: false,
    parent_order: false,
    order_id: null,
  });
  const [bomLoader, setBomLoader] = useState({});
  const [orderLoader, setOrderLoader] = useState({});
  const [currentOrderLoader, setCurrentOrderLoader] = useState({});
  const [checked, setChecked] = React.useState(true);
  useEffect(() => {
    setBomLoader({});
    setOrderLoader({});
  }, [menuActionText]);
  useEffect(() => {
    const isBusy =
      orderLoader[`${order_id}`] && orderLoader[`${order_id}`].busy;
    const actionMessage =
      orderLoader[`${order_id}`] && orderLoader[`${order_id}`].msg
        ? orderLoader[`${order_id}`].msg
        : {};
    setCurrentOrderLoader({ isBusy: isBusy, actionMessage: actionMessage });
  }, [orderLoader, order_id, setCurrentOrderLoader]);
  useEffect(() => {
    setTimeout(() => {
      const urlSearchParams = new URLSearchParams(location.search);
      const _params = Object.fromEntries(urlSearchParams.entries());
      const _order_id = _params.id;
      if (_order_id !== order_id) {
        loadingState.current.order_detail = false;
        loadingState.current.order_id = '';
        setOrderId(_order_id);
      }
    }, 100);
  }, [location.search, setOrderId, order_id]);
  useEffect(() => {
    const fn = async () => {
      if (loadingState.current.order_detail === true) {
        return;
      }
      if (loadingState.current.order_id === order_id) {
        return;
      }
      try {
        loadingState.current.order_detail = true;
        //loadingState.current.order_id = order_id;
        const resolveState = await new Promise(resolve => {
          dispatch(fetchOrderDetails(dispatch, order_id, resolve));
        });
        if (resolveState === true) {
          loadingState.current.order_id = order_id;
        }
      } finally {
        loadingState.current.order_detail = false;
      }
    };
    fn();
  }, [order_id, dispatch]);
  useEffect(() => {
    setOrderDetails(order_details_map[order_id + ''] || {});
  }, [order_details_map, order_id, setOrderDetails]);

  const addRemoveOrder = useCallback(
    (checked, orderId) => {
      if (!orderId || orderId.length === 0) {
        return;
      }
      const selOrd = cloneDeep(selectedOrders);
      const orderIndex = selOrd.indexOf(orderId);
      if (checked && orderIndex === -1) {
        selOrd.push(orderId);
      } else if (!checked && orderIndex >= 0) {
        selOrd.splice(orderIndex, 1);
      }
      if (!isEqual(selOrd, selectedOrders)) {
        setSelectedOrders(selOrd);
      }
    },
    [setSelectedOrders, selectedOrders],
  );
  const checkAll = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    check => {
      const selOrd = cloneDeep(selectedOrders);
      if (check) {
        if (order_details) {
          if (selOrd.indexOf(order_details.uuid) === -1)
            selOrd.push(order_details.uuid);
          if (
            order_details.child_orders &&
            order_details.child_orders.length > 0
          ) {
            order_details.child_orders.forEach(child_order => {
              if (selOrd.indexOf(child_order.uuid) === -1)
                return selOrd.push(child_order.uuid);
              else return selOrd;
            });
          }
        }
      } else {
        if (selectedOrders && selectedOrders.length > 0) {
          selOrd.splice(0, selOrd.length);
        }
      }
      if (!isEqual(selOrd, selectedOrders)) {
        setSelectedOrders(selOrd);
      }
    },
    [setSelectedOrders, selectedOrders, order_details],
  );
  const setBomLoaderCb = useCallback(
    (bomId, busy, msg) => {
      setBomLoader(val => {
        const bomVal = val[`${bomId}`] || {
          busy: false,
          msg: msg,
        };
        const newVal = { ...val };
        bomVal.busy = busy === true;
        bomVal.msg = msg;
        newVal[`${bomId}`] = bomVal;
        return newVal;
      });
    },
    [setBomLoader],
  );

  const setOrderLoaderCb = useCallback(
    (orderId, busy, msg) => {
      setOrderLoader(val => {
        const orderVal = val[`${orderId}`] || {
          busy: false,
          msg: msg,
        };
        const newVal = { ...val };
        orderVal.busy = busy === true;
        orderVal.msg = msg;
        newVal[`${orderId}`] = orderVal;
        return newVal;
      });
    },
    [setOrderLoader],
  );
  const downloadOrderSrcAttachment = useCallback(
    async order_src => {
      if (!selectedBoms || selectedBoms.length === 0) {
        toastError(SELBOM);
        return;
      }
      const downloadOrderSrcAttachmentAsync =
        (bomId, resolver) => async (dispatch, getState) => {
          const resolve = status1 => {
            if (resolver) {
              resolver(status1);
            }
          };
          let status = {
            type: 'success',
            message: '',
            err: '',
          };
          try {
            const downloadResp = await getBomDetailsBomId(bomId);
            await downloadOrderAttachment(
              downloadResp.payload[0].attachment_id,
            );
            //refreshOrderSrcList(downloadResp.payload?.order_id);
            if (downloadResp.status !== 200) {
              status.type = 'error';
              status.err = getResponseErrorMessage(
                downloadResp,
                'Unable to Download',
              );
              return;
            }
            status.message = 'Bom Downloaded';
          } finally {
            resolve(status);
          }
        };
      for (let i = 0; i < selectedBoms.length; i++) {
        const bomId = selectedBoms[`${i}`];
        setBomLoaderCb(bomId, true, '');
        const result = await new Promise(resolve => {
          dispatch(downloadOrderSrcAttachmentAsync(bomId, resolve));
        });

        setBomLoaderCb(bomId, false, result);
      }
    },
    [dispatch, selectedBoms, setBomLoaderCb],
  );

  const downloadOrderSrcFormatAttachment = useCallback(
    async order_src => {
      if (!selectedBoms || selectedBoms.length === 0) {
        toastError(SELBOM);
        return;
      }
      const downloadOrderSrcFormatAttachmentAsync =
        (bomId, resolver) => async (dispatch, getState) => {
          const resolve = status2 => {
            if (resolver) {
              resolver(status2);
            }
          };
          let status = {
            type: 'success',
            message: '',
            err: '',
          };
          try {
            const orderSrcFormatResp =
              await getOrderSrcFormatDownloadUrlService(bomId, null);
            if (orderSrcFormatResp.status !== ResponseCodes.SUCCESS) {
              const err =
                orderSrcFormatResp.err && orderSrcFormatResp.err.err
                  ? orderSrcFormatResp.err.err
                  : 'Unable to create the attachment to download';
              toastError(err);
              return;
            }
            await downloadOrderAttachment(orderSrcFormatResp.payload);

            if (orderSrcFormatResp.status !== 200) {
              status.type = 'error';
              status.err = getResponseErrorMessage(
                orderSrcFormatResp,
                'Unable to Download',
              );
              return;
            }
            status.message = 'Bom Downloaded';
          } finally {
            resolve(status);
          }
        };
      for (let i = 0; i < selectedBoms.length; i++) {
        const bomId = selectedBoms[`${i}`];
        setBomLoaderCb(bomId, true, '');
        const result = await new Promise(resolve => {
          dispatch(downloadOrderSrcFormatAttachmentAsync(bomId, resolve));
        });

        setBomLoaderCb(bomId, false, result);
      }
    },
    [dispatch, selectedBoms, setBomLoaderCb],
  );

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className='clickable'
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden', marginRight: '50px' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ScreenModal />
      <ToastContainer />

      {showBomMenu && showBomMenu.order_id && (
        <div>
          <AddNewBomComponent
            order_id={showBomMenu.order_id}
            showModal={true}
            hideBomMenu={() => {
              setShowBomMenu({});
            }}
            setShowCsvEditModal={orderId => {
              setShowCsvEditModal({ order_id: orderId, mode: 'ADD' });
            }}
          />
        </div>
      )}
      {orderStatusConfirm.show && (
        <ActionConfirmModal
          closeCb={orderStatusConfirm.closeCb}
          confirmCb={orderStatusConfirm.confirmCb}
          orderName={orderStatusConfirm.orderName}
          confirmText={orderStatusConfirm.confirmText}
          actionText={orderStatusConfirm.actionText}
        />
      )}
      <div>
        <div className='container-fluid position-relative px-0'>
          <ChildOrderManagementHeader
            selectedOrders={selectedOrders}
            selectedBoms={selectedBoms}
            selectedBomOrd={selectedBomOrd}
            order_id={order_id}
            order_details={order_details}
            setOrderStatusConfirm={setOrderStatusConfirm}
            setOrderLoaderCb={setOrderLoaderCb}
            setBomLoaderCb={setBomLoaderCb}
            menuActionText={menuActionText}
            setMenuActionText={setMenuActionText}
          />

          <Fragment>
            <div
              style={{
                background: 'white',
                paddingLeft: '20px',
                width: isMobile || isTab ? '' : '568px',
              }}
            >
              <div style={{ height: '20px' }}></div>
              <div
                className='row position-relative'
                style={{
                  paddingBottom: '5px',
                }}
              >
                <div className='d-flex' style={{ margin: 'inherit' }}>
                  <input
                    type='checkbox'
                    id='selectAll'
                    name='selectAll'
                    checked={!checked}
                    onChange={e => {
                      let check = e.target.checked;
                      setChecked(!check);
                      checkAll(checked);
                    }}
                  />
                  <div style={{ width: '10px' }}></div>

                  <div>Select All</div>
                </div>
                <div style={{ height: '20px' }}></div>
                <div
                  className='d-flex'
                  style={{ justifyContent: 'space-between', margin: 'inherit' }}
                >
                  <div className='d-flex'>
                    <input
                      type='checkbox'
                      id={`ordchk${order_details.uuid}`}
                      name={`ordchk${order_details.uuid}`}
                      checked={selectedOrders.indexOf(order_details.uuid) >= 0}
                      onChange={e => {
                        let checked = e.target.checked;
                        addRemoveOrder(checked, order_details.uuid);
                      }}
                    />

                    <div style={{ width: '10px' }}></div>

                    <div>{order_details.ref_code + '(Parent Order)'}</div>
                  </div>

                  {currentOrderLoader.isBusy && <NaadiSmallLogoIcon />}
                  {!currentOrderLoader.isBusy && (
                    <div
                      className={`text-button clickable`}
                      onClick={() => {
                        setShowBomMenu({ order_id: order_details.uuid });
                      }}
                    >
                      Add BOM
                    </div>
                  )}
                </div>
                {currentOrderLoader &&
                  currentOrderLoader.actionMessage &&
                  currentOrderLoader.actionMessage.type && (
                    <div
                      style={{ textAlign: 'right', margin: 'inherit' }}
                      className={`py-1 ${
                        currentOrderLoader.actionMessage.type === 'success'
                          ? 'success'
                          : 'error'
                      }`}
                    >
                      {currentOrderLoader.actionMessage.type === 'success'
                        ? currentOrderLoader.actionMessage.type
                        : currentOrderLoader.actionMessage.err +
                          ' - ' +
                          currentOrderLoader.actionMessage.type}
                    </div>
                  )}
                <BillOfMaterialsComponent
                  order_id={order_details.uuid}
                  release_count={0}
                  showCsvEditModal={
                    showCsvEditModal &&
                    showCsvEditModal.order_id === order_details.uuid
                      ? showCsvEditModal.mode
                      : null
                  }
                  showBomMenu={() => {
                    setShowBomMenu({ order_id: order_details.uuid });
                  }}
                  setShowCsvEditModal={() => {
                    setShowCsvEditModal({
                      order_id: order_details.uuid,
                      mode: 'EDIT',
                    });
                  }}
                  closeCsvEditModal={() => {
                    setShowCsvEditModal(null);
                  }}
                  selectedBoms={selectedBoms}
                  setSelectedBoms={setSelectedBoms}
                  selectedBomOrd={selectedBomOrd}
                  setSelectedBomOrd={setSelectedBomOrd}
                  setSelectedBomsCb={(bomIds, addOrder) => {
                    if (addOrder) {
                      addRemoveOrder(addOrder, order_details.uuid);
                      setTimeout(() => {
                        setSelectedBoms(bomIds);
                      }, 500);
                    } else {
                      setSelectedBoms(bomIds);
                    }
                  }}
                  orderSelectedTrigger={
                    selectedOrders.indexOf(order_details.uuid) >= 0
                  }
                  bomLoader={bomLoader}
                  setBomLoader={setBomLoaderCb}
                />
              </div>
              <div
                className='row position-relative'
                style={{
                  paddingBottom: '5px',
                }}
              >
                {order_details &&
                  order_details.child_orders &&
                  order_details.child_orders.length > 0 &&
                  order_details.child_orders.map(child_order => {
                    const isBusy =
                      orderLoader[`${child_order.uuid}`] &&
                      orderLoader[`${child_order.uuid}`].busy;
                    const actionMessage =
                      orderLoader[`${child_order.uuid}`] &&
                      orderLoader[`${child_order.uuid}`].msg
                        ? orderLoader[`${child_order.uuid}`].msg
                        : {};
                    return (
                      <div className='position-relative' key={child_order.uuid}>
                        <div
                          className='row'
                          style={{
                            paddingBottom: '5px',
                          }}
                        >
                          <div
                            className='d-flex'
                            style={{
                              justifyContent: 'space-between',
                              margin: 'inherit',
                            }}
                          >
                            <div className='d-flex'>
                              <input
                                type='checkbox'
                                id={`ordchk${child_order.uuid}`}
                                name={`ordchk${child_order.uuid}`}
                                checked={selectedOrders.find(
                                  O => O === child_order.uuid,
                                )}
                                onChange={e => {
                                  let checked = e.target.checked;
                                  addRemoveOrder(checked, child_order.uuid);
                                }}
                              />
                              <div style={{ width: '10px' }}></div>
                              {child_order.ref_code}{' '}
                            </div>
                            <div className='justify-content-end'>
                              {isBusy && <NaadiSmallLogoIcon />}
                              {!isBusy && (
                                <div
                                  className={`text-button clickable`}
                                  onClick={() => {
                                    setShowBomMenu({
                                      order_id: child_order.uuid,
                                    });
                                  }}
                                  style={{ margin: 'inherit' }}
                                >
                                  Add BOM
                                </div>
                              )}
                            </div>
                          </div>
                          {actionMessage.type &&
                            actionMessage.type === 'success' && (
                              <div
                                style={{
                                  textAlign: 'right',
                                  margin: 'inherit',
                                }}
                                className={`success`}
                              >
                                {actionMessage.type}
                              </div>
                            )}
                          {actionMessage.type &&
                            actionMessage.type === 'error' && (
                              <div
                                style={{
                                  textAlign: 'right',
                                  margin: 'inherit',
                                }}
                                className={`error`}
                              >
                                {actionMessage.err + '-' + actionMessage.type}
                              </div>
                            )}
                          <BillOfMaterialsComponent
                            order_id={child_order.uuid}
                            release_count={0}
                            showCsvEditModal={
                              showCsvEditModal &&
                              showCsvEditModal.order_id === child_order.uuid
                                ? showCsvEditModal.mode
                                : null
                            }
                            setShowCsvEditModal={() => {
                              setShowCsvEditModal({
                                order_id: child_order.uuid,
                                mode: 'EDIT',
                              });
                            }}
                            showBomMenu={() => {
                              setShowBomMenu({
                                order_id: child_order.uuid,
                              });
                            }}
                            closeCsvEditModal={() => {
                              setShowCsvEditModal(null);
                            }}
                            selectedBoms={selectedBoms}
                            setSelectedBoms={setSelectedBoms}
                            selectedBomOrd={selectedBomOrd}
                            setSelectedBomOrd={setSelectedBomOrd}
                            setSelectedBomsCb={(bomIds, addOrder) => {
                              if (addOrder) {
                                addRemoveOrder(addOrder, child_order.uuid);
                                setTimeout(() => {
                                  setSelectedBoms(bomIds);
                                }, 500);
                              } else {
                                setSelectedBoms(bomIds);
                              }
                            }}
                            orderSelectedTrigger={
                              selectedOrders.indexOf(child_order.uuid) >= 0
                            }
                            bomLoader={bomLoader}
                            setBomLoader={setBomLoaderCb}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Fragment>
          {menuActionText && menuActionText === 'Download BOM' ? (
            <div className='d-flex floating-div' style={{ right: '140px' }}>
              <button
                className='btn btn-outline-primary btn-md btn-save-changes'
                style={{ background: 'white', marginRight: '10px' }}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              <button className='btn btn-primary btn-save-changes'>
                <Dropdown className='page-size-selector'>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id='dropdown-custom-components'
                  >
                    Download <FaAngleUp />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey='1'
                      onClick={() => downloadOrderSrcAttachment(selectedBoms)}
                    >
                      Attachment
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey='2'
                      onClick={() =>
                        downloadOrderSrcFormatAttachment(selectedBoms, null)
                      }
                    >
                      Default Format
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </button>
            </div>
          ) : (
            <div className='d-flex floating-div' style={{ right: '60px' }}>
              <button
                className='btn btn-outline-primary btn-md btn-save-changes'
                style={{ background: 'white' }}
                onClick={() => history.goBack()}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChildOrderManagement;
