import React from 'react';

export const SortAscending = () => (
  <div style={{ width: '32px', height: '32px' }}>
    <svg
      width='8'
      height='4'
      viewBox='0 0 8 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.55078 3.57422C3.78516 3.82812 4.19531 3.82812 4.42969 3.57422L6.92969 1.07422C7.10547 0.898438 7.16406 0.625 7.06641 0.390625C6.96875 0.15625 6.75391 0 6.5 0H1.5C1.24609 0 1.01172 0.15625 0.914062 0.390625C0.816406 0.625 0.875 0.898438 1.05078 1.07422L3.55078 3.57422Z'
        fill='black'
      />
    </svg>
  </div>
);

export const SortDescending = () => (
  <div style={{ width: '32px', height: '32px' }}>
    <svg
      width='8'
      height='4'
      viewBox='0 0 8 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.44922 0.425781C4.21484 0.171875 3.80469 0.171875 3.57031 0.425781L1.07031 2.92578C0.894531 3.10156 0.835938 3.375 0.933594 3.60937C1.03125 3.84375 1.24609 4 1.5 4H6.5C6.75391 4 6.98828 3.84375 7.08594 3.60938C7.18359 3.375 7.125 3.10156 6.94922 2.92578L4.44922 0.425781Z'
        fill='black'
      />
    </svg>
  </div>
);
