import useMobile from '../../../hooks/useMobile';
import React, { Fragment, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const WoMaterialFilter = ({ onFilterUpdate }) => {
  const [isMobile] = useMobile();
  const [searchKeyword, setSearchKeyword] = useState('');

  const updateSearchKeyWord = useDebouncedCallback(
    term => {
      onFilterUpdate(term);
    },
    500,
    { leading: false, trailing: true },
  );
  useEffect(() => {
    updateSearchKeyWord(searchKeyword);
  }, [searchKeyword, updateSearchKeyWord]);
  return (
    <Fragment>
      <div className='row'>
        <div className='col-12 col-lg-6 d-flex align-items-center'>
          <div className='d-flex justify-content-start pt-2'>
            <div
              className=' '
              style={{
                paddingLeft: isMobile ? '40px' : '55px',
                paddingRight: 0,
                maxWidth: '500px',
              }}
            >
              <input
                type='text'
                id='search_field'
                value={searchKeyword}
                onChange={e => setSearchKeyword(e.target.value)}
                className='searchBox'
                placeholder='Tap here to search'
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

WoMaterialFilter.propTypes = {};
export default WoMaterialFilter;
