import React, { useState } from 'react';

import useMobile from '../../../hooks/useMobile';
import { MaterialImage } from '../../cutsmart/common';
import { PrinterSelector } from '../../order/labelprint/PrinterSelector';

import { useDispatch } from 'react-redux';
import { setCgPrinter } from '../../../store/cutguide/cutguide';
const PatternSubHeader = ({ pattern, woMat, printers, selectedPrinter }) => {
  const dispatch = useDispatch();
  const [isMobile] = useMobile();
  // eslint-disable-next-line
  const [dropDownTrigger, setDropDownTrigger] = useState(0);
  return (
    <div className={`row  mx-1 pb-4`}>
      <div className='col-sm-5 col-xs-12 col-lg-6 p-0 d-inline-block'>
        <div className={isMobile ? 'pe-5' : ''}>
          <div
            className='ms-4 position-relative d-flex align-content-center flex-column'
            style={{ zIndex: 9 }}
          >
            <div>
              <div className='d-flex'>
                <div
                  className='position-relative align-self-start'
                  style={{ width: '72px', height: '72px' }}
                >
                  <MaterialImage
                    material={woMat}
                    onClick={null}
                    width={'68px'}
                    height={'68px'}
                  />
                </div>
                <div className='flex-1'>
                  <div className='pt-2' style={{ fontSize: '36px' }}>
                    Pattern {pattern.pattern_index}
                  </div>
                  <div className='d-flex pt-3'>
                    <div className='tertiary-text-color'>MATERIAL:</div>
                    <div
                      style={{ whiteSpace: 'nowrap', textOverflow: 'eliipsis' }}
                    >
                      {woMat.material}
                    </div>
                  </div>
                  <div className='d-flex pt-1'>
                    <div className='tertiary-text-color'>DIM: </div>
                    <div className='' style={{ whiteSpace: 'nowrap' }}>
                      {pattern.length} x {pattern.width}
                    </div>
                    <div className='ps-2 tertiary-text-color'>GRAIN:</div>
                    <div className=''>{pattern.grain > 0 ? 'Yes' : 'No'}</div>
                  </div>
                  <div className='d-flex pt-1'>
                    <div className='tertiary-text-color'>PRESSED LOCATION:</div>
                    <div
                      style={{ whiteSpace: 'nowrap', textOverflow: 'eliipsis' }}
                    >
                      {pattern.pressed_location}
                    </div>
                  </div>

                  <div className='d-flex pt-1'>
                    <div className='tertiary-text-color'>REMARKS:</div>
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'eliipsis',
                      }}
                    >
                      {pattern.comment}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-sm-7 col-lg-6 col-xs-12 p-0 d-inline-block pe-3'>
        <div className='d-flex justify-content-end'>
          <PrinterSelector
            printers={printers}
            triggerDropDown={dropDownTrigger}
            onPrintCb={printer => {
              dispatch(
                setCgPrinter({
                  printer_id: printer.printer_id,
                  org_id: printer.org_id,
                  name: printer.name,
                }),
              );
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div className='px-3 py-2 d-flex' style={{ paddingTop: '8px' }}>
              <div
                className={
                  'text-nowrap select-printer-text overflow-hidden text-overflow-ellipses'
                }
                style={{ maxWidth: '120px' }}
              >
                {selectedPrinter ? selectedPrinter.name : 'Select a Printer'}
              </div>
            </div>
          </PrinterSelector>
        </div>
      </div>
    </div>
  );
};
PatternSubHeader.propTypes = {};
export default PatternSubHeader;
