export const filterReasons = (rejectReasons, rejectReasonState) => {
  return rejectReasons.flatMap(group =>
    group.reasons.filter(reason => {
      const groupKey = `${reason.group}|${reason.reason}`;
      return rejectReasonState.some(cs => cs.groupKey === groupKey);
    }),
  );
};
export const mapReasons = (filteredReasons, rejectReasonState) => {
  return filteredReasons.map((reason, i) => {
    const rReason = rejectReasonState[`${i}`];
    return rReason?.checked
      ? {
          group: reason.group,
          reason: reason.reason,
          confirm_checklist: reason.confirm_checklist,
          blame_operations: rReason?.blame_operations?.filter(
            val => rReason.blame_operation_map[`${val}`] === true,
          ),
          rework_operations: rReason?.rework_operations?.filter(
            val => rReason.rework_operation_map[`${val}`] === true,
          ),
          fields: rReason?.extra_fields?.map((field, i) => ({
            field: field.field,
            val: rReason?.extra_field_value_map[`${field.field}`] || '',
            img: rReason?.images,
          })),
          img: rReason?.images,
        }
      : null;
  });
};
