import React, { useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
const convertVal = (val, mult) => {
  return Math.round(val * mult);
};
const getCoord = (part, pMult, optimizationType) => {
  let val = { x: part.x, y: part.y, length: part.width, width: part.length };
  if (part.rotated === false) {
    val = { x: part.x, y: part.y, length: part.length, width: part.width };
  }
  val.x = convertVal(val.x, pMult);
  val.y = convertVal(val.y, pMult);
  val.length = convertVal(val.length, pMult);
  val.width = convertVal(val.width, pMult);
  return val;
};
const roundOffNumber = (val, maxWidth, ctx) => {
  let v = Math.round(val * 10.0) / 10.0;
  if (ctx.measureText(v + '').width + 10 < maxWidth) {
    return v + '';
  }
  v = Math.round(val);
  if (ctx.measureText(v + '').width < maxWidth) {
    return v + '';
  } else {
    return '';
  }
};

const getDimensionFontSize = ({ l, w, pMult = 1 }) => {
  let lSize = 24;
  const m = Math.min(l, w) * pMult;
  const largestSize = Math.max(l, w) * pMult;
  const resp = {
    hideL: false,
    hideW: false,
    size: 24,
  };
  if (m > 140) {
    lSize = 24;
  } else if (m > 80) {
    lSize = 16;
  } else if (m > 50) {
    lSize = 14;
  } else if (m > 30) {
    lSize = 10;
  } else if (m > 10) {
    lSize = 8;
  } else {
    lSize = 5;
  }
  if (largestSize > 50 && lSize < 14) {
    lSize = 14;
    if (l > w) {
      resp.hideW = true;
    } else {
      resp.hideL = true;
    }
  }
  resp.size = lSize;
  return resp;
};

const getPartNameFontSize = ({ l, w, pMult }) => {
  let lSize = 24;
  const m = Math.min(l, w) * pMult;
  if (m > 140) {
    lSize = 16;
  } else if (m > 80) {
    lSize = 12;
  } else if (m > 50) {
    lSize = 10;
  } else if (m > 30) {
    lSize = 8;
  } else {
    lSize = 5;
  }
  return lSize;
};

/* eslint-disable sonarjs/cognitive-complexity */
const fillPartDimension = ({
  ctx,
  part,
  coord,
  vOffset = 0,
  fillStyle = '#fff',
  pMult = 1,
  largeFont,
  displayTemplate,
  index,
}) => {
  const cutoffSize = 40;
  if (coord.width < cutoffSize && coord.length < cutoffSize) {
    return;
  }
  ctx.textAlign = 'left';
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';
  ctx.fillStyle = fillStyle;
  ctx.font = '24px Roboto';
  if (largeFont === true) {
    ctx.font = '24px Roboto';
  }
  let _fontSize = getDimensionFontSize({
    l: part.length,
    w: part.width,
    pMult,
  });
  let fontSize = _fontSize.size;
  ctx.font = fontSize + 'px Roboto';
  //const dimension = part.length + ' x ' + part.width;

  let partName = part.part_name || '';
  let partCode = part.part_code || '';
  if (
    displayTemplate &&
    displayTemplate.fields &&
    displayTemplate.fields.length > 0
  ) {
    const displayValues = displayTemplate.fields.map(field => {
      if (part.feature_values && part.feature_values[`${field.field_name}`]) {
        return part.feature_values[`${field.field_name}`];
      } else if (
        field.field_name === 'code' ||
        field.field_name === 'part_code' ||
        field.field_name === 'CODE'
      ) {
        return part.part_code;
      } else if (
        field.field_name === 'name' ||
        field.field_name === 'part_name'
      ) {
        return part.part_name;
      }
      return '';
    });
    if (displayValues.length === 1) {
      partCode = displayValues[0];
    } else {
      partName = displayValues[0];
      partCode = displayValues[1];
    }
  }
  //const l = Math.round(part.length * 10) / 10.0 + '';
  //const w = Math.round(part.width * 10) / 10.0 + '';

  let lText = roundOffNumber(
    part.length,
    Math.max(coord.length, coord.width),
    ctx,
  );
  let wText = roundOffNumber(
    part.width,
    Math.max(coord.length, coord.width),
    ctx,
  );
  if (part.rotated) {
    const temp = lText;
    lText = wText;
    wText = temp;
  }
  _fontSize = getDimensionFontSize({ l: part.width, w: part.length, pMult });
  fontSize = _fontSize.size;
  let _lText = lText;
  let _wText = wText;
  if (_fontSize.hideW || _fontSize.hideL) {
    if (coord.width >= coord.length) {
      _lText = '';
      _wText = lText + ' x ' + wText;
    } else {
      _lText = lText + ' x ' + wText;
      _wText = '';
    }
  }

  ctx.fillText(_lText, coord.x + coord.length / 2, coord.y + fontSize - 2);
  ctx.save();
  ctx.translate(coord.x, coord.y + coord.width / 2);
  ctx.rotate(-Math.PI / 2);
  ctx.font = fontSize + 'px Roboto';
  ctx.fillText(_wText, 8, fontSize - 2);
  ctx.restore();

  ctx.font = '14px Roboto';
  //for large text with 3 lines
  if (coord.length * pMult >= 20 && coord.length >= coord.width) {
    //for smaller dimensions with only 2 lines
    const partNameX = coord.x + coord.length / 2;
    const partNameY = coord.y + coord.width / 2;
    //const dimX = coord.x + coord.length / 2;
    //const dimY = coord.y + coord.width / 2 + 8;
    fontSize = getPartNameFontSize({ l: part.length, w: part.width, pMult });
    let trimmed = false;
    while (ctx.measureText(partName).width > coord.length - 30) {
      trimmed = true;
      partName = partName.substring(0, partName.length - 3);
      if (partName.length < 4) {
        break;
      }
    }
    while (ctx.measureText(partCode).width > coord.length - 30) {
      if (partCode.length < 8) {
        break;
      }
      partCode = partCode.substring(3);
    }
    if (trimmed) {
      partName += '.';
    }
    ctx.font = fontSize + 'px Roboto';
    ctx.fillText(partCode, partNameX, partNameY + 8);
    ctx.fillText(partName, partNameX, partNameY + fontSize + 10);
    //ctx.fillText(dimension, dimX, dimY);
  } else if (coord.width * pMult >= 20 && coord.width > coord.length) {
    ctx.save();
    ctx.translate(coord.x + coord.length / 2 - 5, coord.y + coord.width / 2);
    ctx.rotate(-Math.PI / 2);
    fontSize = getPartNameFontSize({ l: part.width, w: part.length, pMult });
    let trimmed = false;
    while (ctx.measureText(partName).width > coord.width - 30) {
      trimmed = true;
      partName = partName.substring(0, partName.length - 3);
      if (partName.length < 4) {
        break;
      }
    }
    if (trimmed) {
      partName += '.';
    }

    while (ctx.measureText(partCode).width > coord.width - 30) {
      if (partCode.length < 8) {
        break;
      }
      partCode = partCode.substring(3);
    }

    ctx.font = fontSize + 'px Roboto';
    ctx.fillText(partCode, 0, 8);
    ctx.fillText(partName, 0, fontSize + 10);
    //ctx.fillText(dimension, 0, 10);
    ctx.restore();
  }
  //ctx.fillText('L:' + part.length, lCoord.x, lCoord.y);
  //ctx.fillText('W:' + part.width, wCoord.x, wCoord.y);
};
/* eslint-enable sonarjs/cognitive-complexity */

const drawPart = ({
  ctx,
  part,
  pMult,
  width,
  height,
  optimizationType,
  largeFont,
  displayTemplate,
  index,
}) => {
  const isProcessed = part.processed_qty > 0;
  const isRejected = part.rejected_qty > 0;
  const coord = getCoord(part, pMult, optimizationType);
  ctx.beginPath();
  ctx.rect(coord.x, coord.y, coord.length, coord.width);
  ctx.fillStyle = isProcessed ? '#008A50' : '#0B1623';
  if (isRejected) {
    ctx.fillStyle = '#C00000';
  }
  ctx.fill();
  ctx.beginPath();
  ctx.rect(coord.x, coord.y, coord.length, coord.width);
  ctx.lineWidth = '2';
  ctx.strokeStyle = '#fff';
  ctx.stroke();
  //drawDiagLines({ ctx, coord });
  fillPartDimension({
    ctx,
    part,
    coord,
    pMult,
    largeFont,
    displayTemplate,
    index,
  });
};
const highlightPart = ({ ctx, part, pMult, optimizationType }) => {
  const coord = getCoord(part, pMult, optimizationType);
  ctx.beginPath();
  ctx.rect(coord.x, coord.y, coord.length, coord.width);
  ctx.lineWidth = '5';
  ctx.strokeStyle = '#FFC700';
  ctx.stroke();
};
const drawRemnant = ({
  ctx,
  remnant,
  pMult,
  width,
  height,
  optimizationType,
  largeFont,
  index,
}) => {
  const coord = getCoord(remnant, pMult, optimizationType);
  ctx.beginPath();
  ctx.rect(coord.x, coord.y, coord.length, coord.width);
  ctx.fillStyle = '#C0C0C0';
  ctx.fill();
  ctx.beginPath();
  ctx.rect(coord.x, coord.y, coord.length, coord.width);
  ctx.lineWidth = '2';
  ctx.strokeStyle = '#fff';
  ctx.stroke();
  fillPartDimension({
    ctx,
    part: remnant,
    coord,
    fillStyle: '#000',
    pMult,
    largeFont,
    index,
  });
};

const getPatternClickMatch = (
  canvas,
  pMult,
  optimizationType,
  clickCoord,
  parts,
) => {
  if (!parts) {
    return null;
  }
  const clickMatch = parts.filter(part => {
    const coord = getCoord(part, pMult, optimizationType);
    let returnVal = false;
    if (
      clickCoord.x > coord.x &&
      clickCoord.y > coord.y &&
      clickCoord.x < coord.x + coord.length &&
      clickCoord.y < coord.y + coord.width
    ) {
      returnVal = true;
    }
    return returnVal;
  });
  if (clickMatch.length === 0) {
    return null;
  }
  return clickMatch[0];
};

/* eslint-disable sonarjs/cognitive-complexity */
const drawPattern = ({
  canvas,
  pattern,
  width,
  height,
  optimizationType,
  currentPart,
  onPartClick,
  largeFont,
  displayTemplate,
}) => {
  const ctx = canvas.getContext('2d');
  const patternRatio = (pattern.length * 1.0) / pattern.width;
  const canvasRatio = (width * 1.0) / height;
  const pMult =
    patternRatio > canvasRatio
      ? (width * 1.0) / pattern.length
      : (height * 1.0) / pattern.width;
  ctx.beginPath();

  const pLength = convertVal(pattern.length, pMult);
  const pWidth = convertVal(pattern.width, pMult);
  //console.log(displayTemplate);
  if (optimizationType === 'DEFAULT' || pattern.rotated === true) {
    ctx.rect(0, 0, pWidth, pLength);
  } else {
    ctx.rect(0, 0, pLength, pWidth);
  }

  ctx.fillStyle = '#F28482';
  ctx.fill();
  if (pattern.parts) {
    pattern.parts.forEach((part, index) => {
      const _part = cloneDeep(part);
      _part.feature_values = _part.feature_values ? _part.feature_values : {};
      _part.feature_values._PART_INDEX = index + 1 + '';
      drawPart({
        ctx,
        part: _part,
        pMult,
        width,
        height,
        optimizationType,
        largeFont,
        displayTemplate,
        index,
      });
    });
  }
  if (pattern.remnants) {
    pattern.remnants.forEach((remnant, index) => {
      const _remnant = cloneDeep(remnant);
      _remnant.feature_values = _remnant.feature_values
        ? _remnant.feature_values
        : {};
      _remnant.feature_values._PART_INDEX = index + 1 + '';
      drawRemnant({
        ctx,
        remnant: _remnant,
        pMult,
        optimizationType,
        largeFont,
        index,
      });
    });
  }
  if (currentPart && currentPart.uuid) {
    highlightPart({ ctx, part: currentPart, pMult, optimizationType });
  }

  const clickEvenHandler = e => {
    const clickCoord = getMousePos(canvas, e);
    const partMatch = getPatternClickMatch(
      canvas,
      pMult,
      optimizationType,
      clickCoord,
      pattern.parts,
    );
    if (onPartClick && partMatch) {
      onPartClick(partMatch, 'PART');
      return;
    }
    const remnantsMatch = getPatternClickMatch(
      canvas,
      pMult,
      optimizationType,
      clickCoord,
      pattern.remnants,
    );
    if (onPartClick && remnantsMatch) {
      onPartClick(remnantsMatch, 'REMNANT');
    }
  };
  if (onPartClick) {
    canvas.addEventListener('click', clickEvenHandler);
    return clickEvenHandler;
  }
  return null;
  //console.log('Add Handler');
};
/* eslint-enable sonarjs/cognitive-complexity */

const getMousePos = (canvas, evt) => {
  const rect = canvas.getBoundingClientRect(), // abs. size of element
    scaleX = canvas.width / rect.width, // relationship bitmap vs. element for x
    scaleY = canvas.height / rect.height; // relationship bitmap vs. element for y

  return {
    x: (evt.clientX - rect.left) * scaleX, // scale mouse coordinates after they have
    y: (evt.clientY - rect.top) * scaleY, // been adjusted to be relative to element
  };
};
export const PatternCanvas = ({
  props,
  pattern,
  optimizationType,
  currentPart,
  onPartClick,
  largeFont,
  displayTemplate,
}) => {
  const canvasRef = useRef(null);
  const [initialized] = useState({
    init: true,
    template: displayTemplate || { fields: [], features: [] },
  });

  useEffect(() => {
    if (!pattern || !pattern.length || !initialized.init) {
      return;
    }
    const canvas = canvasRef.current;
    canvas.style.width = '100%';
    let height = canvas.offsetWidth;

    //console.log(canvas.offsetWidth, height);
    canvas.style.height = '' + height;
    canvas.width = canvas.offsetWidth;
    canvas.height = height;
    const clickEvenHandler = drawPattern({
      canvas,
      pattern,
      width: canvas.offsetWidth,
      height: height,
      optimizationType,
      currentPart,
      onPartClick,
      largeFont,
      displayTemplate: initialized.template,
    });
    return () => {
      if (clickEvenHandler) {
        canvas.removeEventListener('click', clickEvenHandler);
      }
    };
  }, [
    pattern,
    currentPart,
    optimizationType,
    onPartClick,
    largeFont,
    initialized,
  ]);
  return (
    <>
      {initialized.init ? <canvas ref={canvasRef} {...props} /> : <div></div>}
    </>
  );
};
export default PatternCanvas;
