// icon:info-circled | Radix Icons https://icons.radix-ui.com/ | WorkOS
import * as React from 'react';

export const IconInfoCircled = props => {
  return (
    <svg fill='none' viewBox='0 0 15 15' height='1em' width='1em' {...props}>
      <path
        fill='blue'
        fillRule='evenodd'
        d='M7.5.877a6.623 6.623 0 100 13.246A6.623 6.623 0 007.5.877zM1.827 7.5a5.673 5.673 0 1111.346 0 5.673 5.673 0 01-11.346 0zm6.423-3a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM6 6h1.5a.5.5 0 01.5.5V10h1v1H6v-1h1V7H6V6z'
        clipRule='evenodd'
      />
    </svg>
  );
};
export const IconInfoCircledWhite = props => {
  return (
    <svg
      fill='none'
      viewBox='0 0 15 15'
      height='1.3em'
      width='1.3em'
      {...props}
    >
      <circle cx='7.5' cy='7.5' r='6' fill='white' />
      <path
        fill='black'
        fillRule='evenodd'
        d='M7.5.877a6.623 6.623 0 100 13.246A6.623 6.623 0 007.5.877zM1.827 7.5a5.673 5.673 0 1111.346 0 5.673 5.673 0 01-11.346 0zm6.423-3a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM6 6h1.5a.5.5 0 01.5.5V10h1v1H6v-1h1V7H6V6z'
        clipRule='evenodd'
      />
    </svg>
  );
};
export const PatternShowing = props => {
  return (
    <svg
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 1.5C0 0.6875 0.65625 0 1.5 0H5.5C6.3125 0 7 0.6875 7 1.5V5C7 5.84375 6.3125 6.5 5.5 6.5H1.5C0.65625 6.5 0 5.84375 0 5V1.5ZM0 9C0 8.1875 0.65625 7.5 1.5 7.5H5.5C6.3125 7.5 7 8.1875 7 9V12.5C7 13.3438 6.3125 14 5.5 14H1.5C0.65625 14 0 13.3438 0 12.5V9ZM9.5 0H14.5C15.3125 0 16 0.6875 16 1.5V2.5C16 3.34375 15.3125 4 14.5 4H9.5C8.65625 4 8 3.34375 8 2.5V1.5C8 0.6875 8.65625 0 9.5 0ZM8 6.5C8 5.6875 8.65625 5 9.5 5H14.5C15.3125 5 16 5.6875 16 6.5V7.5C16 8.34375 15.3125 9 14.5 9H9.5C8.65625 9 8 8.34375 8 7.5V6.5ZM9.5 10H14.5C15.3125 10 16 10.6875 16 11.5V12.5C16 13.3438 15.3125 14 14.5 14H9.5C8.65625 14 8 13.3438 8 12.5V11.5C8 10.6875 8.65625 10 9.5 10Z'
        fill='#027AFF'
      />
    </svg>
  );
};
