import { useLayoutEffect } from 'react';

const useParentWidth = (ref, deps = [], foo = undefined) => {
  useLayoutEffect(() => {
    if (ref.current) {
      new ResizeObserver(() => {
        if (ref.current) {
          const width = ref.current.parentElement.offsetWidth;
          ref.current.style.width = (foo ? foo(width) : width) + 'px';
        }
      }).observe(ref.current.parentElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...deps]);
};

export default useParentWidth;
