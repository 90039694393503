"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPES = exports.DEFAULT = void 0;
var TYPES = {
  SET: 'USER_SET',
  RESET: 'USER_RESET'
};
exports.TYPES = TYPES;
var DEFAULT = null;
exports.DEFAULT = DEFAULT;