import React from 'react';

export const CloseIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='5'
      height='5'
      fill='none'
      viewBox='0 0 5 5'
    >
      <path
        fill='#027AFF'
        d='M5 .504L4.496 0 2.5 1.996.504 0 0 .504 1.996 2.5 0 4.496.504 5 2.5 3.004 4.496 5 5 4.496 3.004 2.5 5 .504z'
      />
    </svg>
  );
};
