import service from './index';
import { getState } from '../store';

export const getLinkOperation = req => {
  const {
    user: { org },
  } = getState();

  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operations/filter`,
    data: req,
  });
};

export const getLinkWorkstation = req => {
  const {
    user: { org },
  } = getState();

  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationinstance/filter`,
    data: req,
  });
};

export const fetchStations = req => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stations/filter`,
    data: req,
  });
};

export const createStation = req => {
  const {
    user: { org },
  } = getState();
  req.req_payload.org_id = org.uuid;
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stations/update`,
    data: req,
  });
};

export const deleteStation = tid => {
  const {
    user: { org },
  } = getState();

  return service({
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stations/id/${tid}`,
    method: 'DELETE',
  });
};
