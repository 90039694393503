import React, { Fragment } from 'react';

const BreadcrumbSeparator = () => {
  return (
    <div className='standard-icon-wrp'>
      <svg
        width='7'
        height='11'
        viewBox='0 0 7 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.4375 0.90625C0.203125 1.09375 0.179688 1.46875 0.390625 1.67969L3.90625 5.5L0.390625 9.32031C0.179688 9.53125 0.203125 9.90625 0.4375 10.0938L1 10.6094C1.23438 10.8203 1.58594 10.7969 1.79688 10.5625L6.13281 5.82812C6.29688 5.64062 6.29688 5.35938 6.13281 5.17188L1.79688 0.4375C1.58594 0.203125 1.23438 0.179688 1 0.390625L0.4375 0.90625Z'
          fill='#006EED'
        />
      </svg>
    </div>
  );
};
export const DigitalPodBreadCrumb = ({ modes }) => {
  return (
    <Fragment>
      <div className=''>
        <div className='pt-4 d-flex align-items-center font-rubik text-primary'>
          <div className='d-flex align-items-center px-2'>
            {modes.map((mode, index) => {
              return (
                <Fragment key={index}>
                  {index > 0 && <BreadcrumbSeparator />}
                  <div className={`cursor-pointer `} onClick={mode.onSelect}>
                    {mode.label}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
