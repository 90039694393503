import { createAction, createReducer } from '@reduxjs/toolkit';
import Update from 'immutability-helper';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}
const getActionName = (type, verb) => {
  return actionTypeName('WO_ORDER_STATE', type, verb);
};

export const TYPES = {
  WO_FILTER_DATE_RANGE: 'WO_FILTER_DATE_RANGE',
  WO_ORDER_CTX_TYPE: 'WO_ORDER_CTX_TYPE',
  WO_ORDER_FILTER: 'WO_ORDER_FILTER',
};

export const setWoCustomDateRange = createAction(
  getActionName(TYPES.WO_FILTER_DATE_RANGE, 'SET'),
);

export const setWoOrderCtxType = createAction(
  getActionName(TYPES.WO_ORDER_CTX_TYPE, 'SET'),
);

export const setWoFilter = createAction(
  getActionName(TYPES.WO_ORDER_FILTER, 'SET'),
);

export const DEFAULT = {
  wo_order_ctx_type: 'ORDER',
  wo_filter_custom_date_range: {
    preset: 'today',
    from: null,
    to: null,
  },
  wo_filter: {
    wo_name: '',
    status: '',
    sort: {
      field: '',
      asc: false,
    },
  },
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setWoCustomDateRange, (state, { payload }) => {
    return Update(state, {
      wo_filter_custom_date_range: {
        $set: payload ? payload : { from: null, to: null, present: 'today' },
      },
    });
  });
  builder.addCase(setWoOrderCtxType, (state, { payload }) => {
    return Update(state, {
      wo_order_ctx_type: { $set: payload },
    });
  });
  builder.addCase(setWoFilter, (state, { payload }) => {
    return Update(state, {
      wo_filter: { $set: payload },
    });
  });
});

export default reducer;
