import React from 'react';
import PropTypes from 'prop-types';

const StatusBox = ({ count, icon, label, onClick, color = 'blue' }) => {
  const className = ['items-count-wraper'];

  if (onClick) {
    className.push('cursor-pointer');
  }

  switch (color) {
    case 'yellow':
      className.push('box-count-wrapper');
      break;
    case 'grey':
      className.push('extra-count-wrapper');
      break;
    case 'green':
      className.push('box-count-wrapper-green');
      break;
    case 'red':
      className.push('error-count-wrapper');
      break;
    default:
  }

  return (
    <div className={className.join(' ')} onClick={onClick}>
      <div className='fs-36'>
        <span>{count + ''}</span>
        {icon && <img src={icon} alt={label} className='ps-4p' />}
      </div>
      <div className='label fs-12 ps-4p'>{label + ''}</div>
    </div>
  );
};

StatusBox.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['blue', 'yellow', 'grey', 'green', 'red']),
};

export default StatusBox;
