import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import BottomDrawer from '../../components/template/bottomdrawer';
import CustomHookModal from './editquery-modal';
import { DeleteIcon } from '../../assets/icons';

const Mapping = ({
  selectWorkstation,
  mapform,
  onMapChange,
  isMobile,
  isTab,
  featOpMapping,
  setFeatOpMapping,
}) => {
  const Class_RECT = 'rectangle-input';
  const [showHookModal, setShowHookModal] = useState(false);
  const [hookValue, setHookValue] = useState('');

  const closeCallback = () => {
    setShowHookModal(false);
  };

  const addFeatOp = op_code => {
    setFeatOpMapping(prevState => {
      if (prevState && prevState[`${op_code}`]) {
        return {
          ...prevState,
          [op_code]: [...prevState[`${op_code}`], ''],
        };
      } else {
        return {
          ...prevState,
          [op_code]: [''],
        };
      }
    });
  };

  const deleteFeatOp = (op_code, valueIndex) => {
    setFeatOpMapping(prevState => {
      const updatedOpCodes = { ...prevState };

      if (updatedOpCodes[`${op_code}`]) {
        updatedOpCodes[`${op_code}`] = updatedOpCodes[`${op_code}`].filter(
          (_, i) => i !== valueIndex,
        );
      }

      return updatedOpCodes;
    });
  };

  return (
    <div
      className='bg-white'
      style={{ alignContent: 'left', marginLeft: '40px' }}
    >
      {isMobile && showHookModal ? (
        <BottomDrawer
          entity={''}
          drawerTitle=''
          handleClose={closeCallback}
          popUp={showHookModal && isMobile}
          drawerBody={
            <CustomHookModal
              showModal={showHookModal}
              closeCallback={closeCallback}
              isMobile={isMobile}
              isTab={isTab}
              hookValue={hookValue}
              setHookValue={setHookValue}
              mapform={mapform}
            />
          }
        />
      ) : (
        showHookModal && (
          <CustomHookModal
            showModal={showHookModal}
            closeCallback={closeCallback}
            isMobile={isMobile}
            isTab={isTab}
            hookValue={hookValue}
            setHookValue={setHookValue}
            mapform={mapform}
          />
        )
      )}

      <div style={{ height: '27px' }}></div>
      <Form style={{ padding: '0px' }}>
        <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
          Feature Operation Mapping
        </div>

        {selectWorkstation &&
          selectWorkstation.operations &&
          selectWorkstation.operations.length > 0 &&
          selectWorkstation.operations.map(operation => {
            return (
              <div key={operation.uuid}>
                <div>
                  <div
                    className='row'
                    style={{
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      width: '320px',
                    }}
                  >
                    <div className='col-10'>
                      {operation.operation.operation_code}
                    </div>
                    <div
                      className='col-2'
                      style={{
                        textAlign: 'right',
                        color: '#006EED',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        addFeatOp(operation.operation.operation_code)
                      }
                    >
                      Add+
                    </div>
                  </div>
                  {featOpMapping &&
                    Object.entries(featOpMapping).map(([index, list]) => {
                      return (
                        operation.operation.operation_code === index && (
                          <div key={index}>
                            {list.map((value, valueIndex) => (
                              <div key={valueIndex} className='d-flex'>
                                <div
                                  className={Class_RECT}
                                  style={{
                                    marginBottom: '20px',
                                    marginRight: isMobile ? '0px' : '28px',
                                  }}
                                >
                                  <div className='m-0 p-0'>
                                    <label
                                      className='lbl-input lbl-padding'
                                      style={{ marginLeft: '16px' }}
                                    ></label>

                                    <Form.Control
                                      id={
                                        operation.operation.operation_code +
                                        valueIndex.toString()
                                      }
                                      className='txt-input primary'
                                      type='text'
                                      autoComplete='off'
                                      name={
                                        operation.operation.operation_code +
                                        valueIndex.toString()
                                      }
                                      placeholder='Enter Value Here'
                                      value={
                                        value ||
                                        mapform[
                                          `${
                                            operation.operation.operation_code +
                                            valueIndex.toString()
                                          }`
                                        ]
                                      }
                                      onChange={onMapChange}
                                      style={{ marginLeft: '16px' }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className='text-right pr-2'
                                  style={{
                                    width: '40px',
                                    height: '50px',
                                    alignContent: 'space-around',
                                  }}
                                >
                                  <DeleteIcon
                                    onClick={() =>
                                      deleteFeatOp(index, valueIndex)
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            );
          })}
      </Form>
    </div>
  );
};

export default Mapping;
