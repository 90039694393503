export const PROFILE_NAME = 'PROFILE_NAME';
export const PROFILE_DESC = 'PROFILE_DESC';

export const profileName = name => {
  return {
    type: PROFILE_NAME,
    payload: name,
  };
};

export const profileDescription = description => {
  return {
    type: PROFILE_DESC,
    payload: description,
  };
};
