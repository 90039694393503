export const isBoxItem = item => {
  let returnVal = false;
  if (!item) {
    returnVal = false;
  } else if (!item.item_definition) {
    returnVal = false;
  } else if (
    item.item_definition.definition.BOXITEM &&
    item.item_definition.definition.BOXITEM.val
  ) {
    returnVal = true;
  }
  return returnVal;
};

export const isSerializedItem = item => {
  if (!item) return false;
  if (!item.item_definition) return false;
  if (!item.item_definition.definition) return false;
  if (!item.item_definition.definition.INVENTORY_TYPE) return false;
  if (!item.item_definition.definition.INVENTORY_TYPE.val === 'SERIALIZED')
    return true;
  return (
    item.item_definition.definition.INVENTORY_TYPE?.value?.val === 'SERIALIZED'
  );
};

export const getAssemblyParentUUID = item => {
  if (!item) return null;
  if (item.assembly_parents === null || item.assembly_parents.length === 0) {
    return null;
  }
  const assemblyParentUUID = item.assembly_parents[0];
  if (assemblyParentUUID === undefined) {
    return null;
  }
  return assemblyParentUUID;
};
export const getAssemblyParentItemId = (item, items, boxItems) => {
  if (!item) return null;
  if (item.assembly_parents === null || item.assembly_parents.length === 0) {
    return null;
  }
  const assemblyParentUUID = item.assembly_parents[0];
  if (assemblyParentUUID === undefined) {
    return null;
  }
  const boxItem = boxItems.find(val => val.uuid === assemblyParentUUID);
  if (boxItem !== undefined) {
    return boxItem.item_id;
  }
  const parentItem = items.find(val => val.uuid === assemblyParentUUID);
  if (parentItem !== undefined) {
    return parentItem.item_id;
  }
  return null;
};

export const getAssemblyParentBox = (item, itemsList) => {
  if (!item) return null;
  if (item.assembly_parent === undefined) return null;
  if (item.assembly_parents === null || item.assembly_parents.length === 0) {
    return null;
  }
  const assemblyParentUUID = item.assembly_parents[0];
  const assemblyParentBox = itemsList.boxesItems.find(
    val => val.uuid === assemblyParentUUID,
  );
  if (assemblyParentBox === undefined || assemblyParentBox === null) {
    return null;
  }
  return assemblyParentBox;
};
