import React, { useCallback } from 'react';
import * as XLSX from 'xlsx';
import { last } from 'lodash';
//import { downloadOrderAttachment } from '../../helpers/order/orderHelper';
import { DeleteIcon } from '../../assets/icons';
const SPBETWN = 'space-between';
const SEL_FILE = 'Select File';

/*const downloadAttachment = async attachment_id => {
  await downloadOrderAttachment(attachment_id);
};*/

const AddFile = ({ onChangeCB, samplefiles, setHeaderList }) => {
  const hiddenFileInput = React.useRef(null);
  const processData = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async dataString => {
      const dataStringLines = dataString.split(/\r\n|\n/);
      /* eslint-disable */
      const headers = dataStringLines[0].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
      );
      /* eslint-enable */
      if (!last(headers).length) {
        headers.pop();
      }
      setHeaderList(headers);
    },
    [setHeaderList],
  );
  const handleChange = useCallback(
    event => {
      const file = event;
      const reader = new FileReader();
      reader.onload = evt => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname + ''];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        processData(data, file.name);
      };
      reader.readAsBinaryString(file);
    },
    [processData],
  );
  const onUploadClick = useCallback(event => {
    hiddenFileInput.current.click();
  }, []);

  const handleImageUpload = useCallback(
    async (event, filetype) => {
      const files = event;
      const readFileData = async file => {
        return await new Promise(resolve => {
          const reader = new FileReader();
          reader.onload = evt => {
            const baseURL = reader.result;
            resolve(baseURL);
          };
          reader.readAsDataURL(file);
        });
      };
      const fileData = await readFileData(files);
      const jrxmlData = fileData.split(';base64,')[1];
      onChangeCB(samplefiles, jrxmlData, files.name);
      handleChange(files);
    },
    [onChangeCB, samplefiles, handleChange],
  );

  const DeleteFile = () => {
    setHeaderList(null);
    onChangeCB(samplefiles, null, null);
  };

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: SPBETWN, maxWidth: '668px' }}
      >
        <div>Sample File</div>
        {!(
          samplefiles &&
          samplefiles.length > 0 &&
          samplefiles[0].file_name
        ) && (
          <div
            style={{ color: '#006EED', cursor: 'pointer' }}
            onClick={onUploadClick}
          >
            <input
              id='uploadImage'
              type='file'
              accept={'.csv'}
              ref={hiddenFileInput}
              style={{ display: 'none' }}
              onChange={e => handleImageUpload(e.target.files[0])}
            />
            {SEL_FILE}
          </div>
        )}
      </div>
      <div style={{ height: '20px' }}></div>
      {samplefiles && samplefiles.length > 0 && samplefiles[0].file_name ? (
        <div
          style={{
            maxWidth: '668px',
            display: 'flex',
            justifyContent: SPBETWN,
            borderBottom: '1px solid',
            paddingBottom: '8px',
            borderColor: '#DEDEDE',
          }}
        >
          {samplefiles[0].file_name}
          <div
            style={{
              width: '64px',
              display: 'flex',
              justifyContent: SPBETWN,
              cursor: 'pointer',
            }}
            onClick={() => DeleteFile()}
          >
            <div>
              <DeleteIcon />
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            maxWidth: '668px',
            display: 'flex',
            justifyContent: SPBETWN,
            borderBottom: '1px solid',
            paddingBottom: '8px',
            borderColor: '#DEDEDE',
          }}
        >
          <div
            style={{
              width: '668px',
              color: 'rgba(50, 50, 50, 0.4)',
              textAlign: 'center',
            }}
          >
            Select a file to add
          </div>
        </div>
      )}
    </div>
  );
};

const SampleFile = ({
  cattype,
  isMobile,
  templates,
  onChangeCb,
  addnewSubTemplate,
  samplefiles,
  headerList,
  setHeaderList,
}) => {
  return (
    <div
      className='bg-white'
      style={{
        alignContent: 'left',
        marginLeft: isMobile ? '30px' : '40px',
        marginRight: '30px',
      }}
    >
      <AddFile
        onChangeCB={onChangeCb}
        samplefiles={samplefiles}
        setHeaderList={setHeaderList}
      />

      <div style={{ height: '24px' }}></div>
    </div>
  );
};

export default SampleFile;
