import { getState } from '../../store';
import { updateBulkOperationStatus } from '../../services/order';
import { getResponseErrorMessage } from '../common/helper';
import { uploadScanImages } from '../tracktrace/orderItemHelper';
import { actions } from '@naadi/framework';
import { cloneDeep, isEqual } from 'lodash';
export const NO_SHIPMENT = 'NO_SHIPMENT';
export const MODES = {
  SHIPMENT_LISTING: 'SHIPMENT_LISTING',
  SHIPMENT_ORDER_LISTING: 'SHIPMENT_ORDER_LISTING',
  SHIPMENT_ITEM_LISTING: 'SHIPMENT_ITEM_LISTING',
  SHIPMENT_ITEM_DETAIL: 'SHIPMENT_ITEM_DETAIL',
  PENDING_SYNC_LISTING: 'PENDING_SYNC_LISTING',
};
const isInShippingContext = (ctxId, ctxType, dPodItem) => {
  let result = true;
  if (dPodItem.shipment_id === NO_SHIPMENT) {
    result = false;
  }
  if (ctxType === 'SHIPMENT' && ctxId !== dPodItem.shipment_id) {
    result = false;
  }
  return result;
};

export const getCtxOrdersFromResult = (podResult, ctx) => {
  const result = podResult.result;
  const orderIds = [];
  const populateOrderFromBox = box => {
    const boxItems = box.box_items || [];
    boxItems.forEach(boxItem => {
      if (boxItem.box_item_type === 'BOX' && boxItem.box_box_item) {
        populateOrderFromBox(boxItem.box_box_item);
      } else if (boxItem.box_item_type === 'ORDER' && boxItem.order_box_item) {
        const orderId = boxItem.order_box_item?.order_id;
        if (orderId && orderIds.indexOf(orderId) === -1) {
          orderIds.push(orderId);
        }
      }
    });
  };
  result.forEach(dPodItem => {
    if (dPodItem.type === 'BOX') {
      populateOrderFromBox(dPodItem.box);
    } else if (dPodItem.type === 'ORDER_ITEM') {
      const orderId = dPodItem.order_item?.order_id;
      if (orderId && orderIds.indexOf(orderId) === -1) {
        orderIds.push(orderId);
      }
    }
  });
  return orderIds;
};

export const preparePendingItemMap = ctxPendingSync => {
  const boxSyncMap = {};
  const orderItemSyncMap = {};
  (ctxPendingSync || []).forEach(pSync => {
    if (pSync.scanItem && pSync.scanItem.type === 'BOX' && pSync.scanItem.box) {
      boxSyncMap[`${pSync.scanItem.box.uuid}`] = pSync;
    }
    if (
      pSync.scanItem &&
      pSync.scanItem.type === 'ORDER_ITEM' &&
      pSync.scanItem.order_item
    ) {
      orderItemSyncMap[`${pSync.scanItem.order_item.uuid}`] = pSync;
    }
  });
  return {
    boxSyncMap: boxSyncMap,
    orderItemSyncMap: orderItemSyncMap,
  };
};

/* eslint-disable sonarjs/cognitive-complexity */
export const calculateDPodStat = (podResult, ctx, ctxPendingSync) => {
  const { boxSyncMap, orderItemSyncMap } =
    preparePendingItemMap(ctxPendingSync);
  const shipmentMap = podResult?.shipments || {};
  if (!shipmentMap.NO_SHIPMENT) {
    shipmentMap.NO_SHIPMENT = {
      uuid: NO_SHIPMENT,
      code: NO_SHIPMENT,
      name: NO_SHIPMENT,
    };
  }
  const ctxType = ctx?.type?.value;
  const ctxId = ctx?.value?.key;
  const result = podResult.result;
  podResult.ctx_type = ctxType;
  podResult.ctx_id = ctxId;

  const stat = {
    box_shipped: 0,
    box_shipped_processed: 0,
    box_processed: 0,
    box_total: 0,
    item_shipped: 0,
    item_shipped_processed: 0,
    item_processed: 0,
    item_total: 0,
    item_direct_shipped: 0,
    item_direct_processed: 0,
    total_shipped: 0, //lineitems shipped
    total_processed: 0, //lineitems count that have been processed
  };
  const orderItemSummary = (orderItem, syncPendingStatus) => {
    if (!orderItem) {
      return {
        skip_operation: true,
        qty: orderItem.qty,
        processed: 0,
        processed_qty: 0,
        rejected: 0,
        reject_qty: 0,
      };
    }
    const r = {
      processed: true,
      skip_operation: true,
      qty: orderItem.qty,
      processed_qty: orderItem.qty,
      reject_qty: 0,
    };
    (orderItem?._operation_status || []).forEach(os => {
      if (!os.skip_operation) {
        r.skip_operation = false;
        r.processed_qty = Math.min(r.qty, os.qty);
        r.reject_qty = Math.max(r.reject_qty, os.reject_qty);
      }
    });
    if (
      syncPendingStatus === 'OI_PENDING_S' ||
      syncPendingStatus === 'BOX_PENDING_S'
    ) {
      r.processed_qty = r.qty;
      r.reject_qty = 0;
    } else if (
      syncPendingStatus === 'OI_PENDING_R' ||
      syncPendingStatus === 'BOX_PENDING_R'
    ) {
      r.processed_qty = 0;
      r.reject_qty = r.qty;
    }
    if (!r.skip_operation && (r.reject_qty > 0 || r.processed_qty < r.qty)) {
      r.processed = false;
    }
    if (r.reject_qty > 0) {
      r.reject_qty = 1;
      r.processed_qty = 0;
    }
    if (r.processed_qty >= r.qty) {
      r.processed_qty = 1;
    } else {
      r.processed = false;
    }
    return r;
  };
  const boxItemSummary = (box, pSyncBox) => {
    const boxItems = box.box_items || [];
    const r = {
      processed: false,
      skip_operation: true,
      qty: 0,
      processed_qty: 0,
      reject_qty: 0,
      item_count: 0,
    };
    boxItems.forEach(boxItem => {
      if (boxItem.box_item_type === 'BOX' && boxItem.box_box_item) {
        const _pSyncBox = boxSyncMap[`${boxItem.box_box_item.uuid}`];
        const boxResult = boxItemSummary(boxItem.box_box_item, _pSyncBox);
        if (!boxResult.skip_operation) {
          r.skip_operation = false;
          r.qty += boxResult.qty;
          r.processed_qty += boxResult.processed_qty;
          r.reject_qty += boxResult.reject_qty;
          r.item_count += boxResult.item_count;
        }
      } else if (boxItem.box_item_type === 'ORDER' && boxItem.order_box_item) {
        const pSyncOrderItem =
          orderItemSyncMap[`${boxItem.order_box_item?.uuid}`];
        const syncPendingStatusBox = !pSyncBox
          ? ''
          : pSyncBox.reject
          ? 'BOX_PENDING_R'
          : 'BOX_PENDING_S';
        const syncPendingStatusOrderItem = !pSyncOrderItem
          ? ''
          : pSyncOrderItem.reject
          ? 'OI_PENDING_R'
          : 'OI_PENDING_S';
        const syncPendingStatus =
          syncPendingStatusOrderItem === ''
            ? syncPendingStatusBox
            : syncPendingStatusOrderItem;
        const ooiResult = orderItemSummary(
          boxItem.order_box_item,
          syncPendingStatus,
        );
        if (!ooiResult.skip_operation) {
          r.skip_operation = false;
          r.qty += ooiResult.qty;
          r.processed_qty += ooiResult.processed_qty;
          r.reject_qty += ooiResult.reject_qty;
          r.item_count++;
        }
      } else {
        r.skip_operation = true;
        r.processed = true;
      }
    });
    if (!r.skip_operation && r.processed_qty >= r.qty) {
      r.processed = true;
    }
    return r;
  };

  result.forEach(dPodItem => {
    if (dPodItem.type === 'BOX') {
      const _pSyncBox = boxSyncMap[`${dPodItem.box?.uuid}`];
      const boxResult = boxItemSummary(dPodItem.box, _pSyncBox);
      dPodItem.skip_operation = false;
      dPodItem.qty = boxResult.qty;
      dPodItem.processed_qty = boxResult.processed_qty;
      dPodItem.reject_qty = boxResult.reject_qty;
      dPodItem.item_count = boxResult.item_count;
      dPodItem.processed = boxResult.processed;
      dPodItem.total_shipped++;
      if (!dPodItem.skip_operation) {
        stat.box_total++;
        stat.item_total += boxResult.item_count;
        stat.box_processed += boxResult.processed ? 1 : 0;
        stat.item_processed += boxResult.processed_qty;
        if (isInShippingContext(ctxId, ctxType, dPodItem)) {
          stat.box_shipped++;
          stat.box_shipped_processed += boxResult.processed ? 1 : 0;
          stat.item_shipped += dPodItem.item_count;
          stat.item_shipped_processed += boxResult.processed_qty;
          stat.total_shipped++;
        }
        if (boxResult.processed) {
          stat.total_processed++;
        }
      }
    } else if (dPodItem.type === 'ORDER_ITEM') {
      const boxResult = orderItemSummary(dPodItem.order_item);
      dPodItem.skip_operation = boxResult.skip_operation;
      dPodItem.qty = boxResult.qty;
      dPodItem.processed_qty = boxResult.processed_qty;
      dPodItem.reject_qty = boxResult.reject_qty;
      dPodItem.item_count = 1;
      stat.item_total++;
      stat.item_processed += boxResult.processed_qty;
      stat.item_direct_shipped++;
      if (
        isInShippingContext(ctxId, ctxType, dPodItem) &&
        dPodItem.shipment_code !== NO_SHIPMENT
      ) {
        stat.item_shipped++;
        stat.item_shipped_processed += boxResult.processed_qty;
        stat.total_shipped++;
        stat.item_direct_processed += boxResult.processed_qty;
      }
      if (boxResult.processed) {
        stat.total_processed++;
      }
    }
  });
  podResult.stat = stat;
  return podResult;
};

export const getItemFromScanCode = (podResult, scanCode) => {
  const matchOrderItem = orderItem => {
    if (
      orderItem.code === scanCode ||
      (orderItem.scan_alternates || []).indexOf(scanCode) >= 0
    ) {
      return {
        type: 'ORDER_ITEM',
        scanCode: scanCode,
        order_item: orderItem,
      };
    }
    return null;
  };
  const findInBox = box => {
    if (!box || !box.box_items) {
      return null;
    }
    if (box.code === scanCode) {
      return {
        type: 'BOX',
        scanCode: box.code,
        box: box,
      };
    }
    for (let i = 0; i < box.box_items.length; i++) {
      const bi = box.box_items[`${i}`];
      if (bi.box_item_type === 'BOX') {
        const boxMatch = findInBox(bi.box_box_item);
        if (boxMatch) {
          return boxMatch;
        }
      } else if (bi.box_item_type === 'ORDER') {
        const itemMatch = matchOrderItem(bi.order_box_item);
        if (itemMatch) {
          return itemMatch;
        }
      }
    }
    return null;
  };
  return podResult.result
    .map(item => {
      if (item.type === 'BOX') {
        return findInBox(item.box);
      } else if (item.type === 'ORDER_ITEM') {
        return matchOrderItem(item.order_item);
      } else {
        return null;
      }
    })
    .filter(val => val)[0];
};

export const scanOrderItems = async (
  orderItems,
  manualEntry,
  rejection,
  images,
  dispatch,
  current_station,
  collaborator,
) => {
  //const { org, branch } = getState().user;
  const { station_location } = getState().stationstate;
  const location =
    (station_location ? station_location[current_station.uuid] : '') || '';
  const rejectmode = false;
  if (!current_station || !current_station.uuid) {
    return {
      error: 'Select the Station Before Scanning',
      success: false,
    };
  }
  if (current_station.operations.length === 0) {
    return {
      error: 'There are no operations in the selected station',
      success: false,
    };
  }
  try {
    dispatch(actions.busy.add('DIGITAL_POD_SCAN'));
    const rejected = rejection
      ? rejection.rejected === true
      : rejectmode === true;
    if (orderItems.length === 0) {
      return {
        error: 'Atleast One Item has to be selected',
        success: false,
      };
    }

    let operationCodes = current_station.operations;
    const orderIds = orderItems
      .map(val => val.order_id)
      .filter((val, index, arr) => arr.indexOf(val) === index);
    const orderImageAttachments = {};
    for (let i = 0; i < orderIds.length; i++) {
      const imageAttachments = await uploadScanImages(images, orderIds[`${i}`]);
      if (!imageAttachments.success) {
        return {
          error: imageAttachments.err,
          success: false,
        };
      }
      const orderId = orderIds[`${i}`];
      orderImageAttachments[`${orderId}`] = imageAttachments;
    }

    const reqList = orderItems.map(match => {
      const images = orderImageAttachments[`${match.order_id}`]
        ? orderImageAttachments[`${match.order_id}`].images
        : [];
      return {
        scan_station: current_station.station_code,
        //scan_id: getUuid(dispatch),
        scan_code: match.uuid,
        pod: current_station.uuid,
        pod_name: current_station.instance_name,
        manual_entry: manualEntry,
        scan_context_type: 'ORDER',
        scan_context_1: match.order_id,
        scan_context_2: match.uuid,
        scan_context_3: match.code,
        station_operation_codes: current_station.operations,
        req_payload: {
          order_item_id: match.uuid,
          scan_record_id: match.uuid,
          status: rejected ? 'REJECTED' : 'SUCCESS',
          operation_codes: operationCodes,
          processed_on: new Date(),
          rejected: rejected,

          comment: rejection && rejection.comment ? rejection.comment : '',
          location: location,
          images: images,
        },
        rejection_list: rejection && rejection.reasons ? rejection.reasons : [],
      };
    });

    const operationStatusResp = await updateBulkOperationStatus(
      {
        req_payload: reqList,
      },
      collaborator,
    );
    if (operationStatusResp.status !== 200) {
      return {
        error: getResponseErrorMessage(operationStatusResp, 'Unable to Update'),
        success: false,
      };
    }
    return {
      success:
        !operationStatusResp.payload.err ||
        operationStatusResp.payload.err.length === 0,
      payload: operationStatusResp.payload,
    };
  } finally {
    dispatch(actions.busy.remove('DIGITAL_POD_SCAN'));
  }
};

export const getBoxTypeResult = (shipmentResult, item, ctxShipmentId) => {
  for (let i = 0; i < shipmentResult.result.length; i++) {
    const val = shipmentResult.result[`${i}`];
    if (val.type === 'BOX' && val.box && val.box.id === item.box.id) {
      return val;
    }
  }
  return null;
};
export const getBoxFromResult = (dPodResult, box) => {
  const resultBox = {
    box: box,
    item: null,
  };
  const getBox = b => {
    if (!b || !b.box_items) {
      return null;
    }
    for (let j = 0; j < b.box_items.length; j++) {
      const bi = b.box_items[`${j}`];
      if (
        bi.box_item_type === 'BOX' &&
        bi.box_item_id === box.uuid &&
        bi.box_box_item
      ) {
        return bi.box_box_item;
      }
    }
    return null;
  };
  for (let i = 0; i < dPodResult.result.length; i++) {
    const val = dPodResult.result[`${i}`];
    if (val.type === 'BOX' && val.box) {
      if (val.box && val.box.uuid === box.uuid) {
        resultBox.box = val.box;
        resultBox.item = val;
        break;
      } else {
        const b = getBox(val.box);
        if (b) {
          resultBox.box = b;
          resultBox.item = val;
          break;
        }
      }
    }
  }
  return { ...resultBox.box, item: resultBox.item };
};

export const getOrderItemFromResult = (dPodResult, orderItem) => {
  const result = {
    orderItem: orderItem,
    item: null,
  };
  const getOrderItem = box => {
    if (!box || !box.box_items) {
      return null;
    }
    for (let j = 0; j < box.box_items.length; j++) {
      const bi = box.box_items[`${j}`];
      if (
        bi.box_item_type === 'ORDER' &&
        bi.box_item_id === orderItem.uuid &&
        bi.order_box_item
      ) {
        return bi.order_box_item;
      }
    }
    return null;
  };
  for (let i = 0; i < dPodResult.result.length; i++) {
    const val = dPodResult.result[`${i}`];
    if (val.type === 'ORDER_ITEM' && val.order_item) {
      if (val.order_item && val.order_item.uuid === orderItem.uuid) {
        result.orderItem = val.order_item;
        result.item = val;
        break;
      }
    } else if (val.type === 'BOX') {
      const oi = getOrderItem(val.box);
      if (oi) {
        result.orderItem = oi;
        result.item = val;
        break;
      }
    }
  }
  return { ...result.orderItem, item: result.item };
};

export const mergeOrderItemUpdateToResult = (
  dPodResult,
  updatedResult,
  orderItemId,
  orderId,
) => {
  if (!orderItemId) {
    return { modified: false, dPodResult: dPodResult };
  }
  const _dPodResult = cloneDeep(dPodResult);
  const _updatedResult = cloneDeep(updatedResult);

  const matchAndFillBox = (updatedBox, origBox, level) => {
    for (let k = 0; k < updatedBox.box_items.length; k++) {
      const updatedBi = updatedBox.box_items[`${k}`];
      if (
        updatedBi.box_item_type === 'ORDER' &&
        updatedBi.box_item_id !== orderItemId
      ) {
        continue;
      }
      let biFound = false;
      for (let l = 0; l < origBox.box_items.length; l++) {
        const origBi = origBox.box_items[`${l}`];
        if (origBi.uuid === updatedBi.uuid) {
          biFound = true;
          origBi.qty = updatedBi.qty;
          if (updatedBi.box_item_type === 'BOX') {
            matchAndFillBox(
              updatedBi.box_box_item,
              origBi.box_box_item,
              level + 1,
            );
          } else if (
            updatedBi.box_item_type === 'ORDER' &&
            updatedBi.order_box_item.uuid === origBi.order_box_item.uuid
          ) {
            origBi.order_box_item = updatedBi.order_box_item;
          }
        }
      }
      if (biFound === false) {
        origBox.box_items.push(updatedBi);
        updatedBox.box_items.splice(k, 1);
        k--;
      }
    }
  };

  const removeOrderItemFromBox = box => {
    if (!box.box_items) {
      return;
    }
    for (let k = 0; k < box.box_items.length; k++) {
      const bi = box.box_items[`${k}`];
      if (bi.box_item_type === 'ORDER') {
        if (bi.order_box_item && bi.order_box_item.uuid === orderItemId) {
          box.box_items.splice(k, 1);
          k--;
          continue;
        }
      } else if (bi.box_item_type === 'BOX') {
        removeOrderItemFromBox(bi.box_box_item);
      }
    }
  };

  //Loop through both the results and add missing result as well as update the missing order items
  for (let i = 0; i < _updatedResult.length; i++) {
    const updatedDPodItem = _updatedResult[`${i}`];
    let found = false;
    for (let j = 0; j < _dPodResult.result.length; j++) {
      const dPodItem = _dPodResult.result[`${j}`];
      if (dPodItem.order_id !== orderId) {
        continue;
      }
      if (
        dPodItem.type === updatedDPodItem.type &&
        dPodItem.shipment_id === updatedDPodItem.shipment_id
      ) {
        if (updatedDPodItem.type === 'ORDER_ITEM') {
          if (
            updatedDPodItem.order_item &&
            dPodItem.order_item &&
            updatedDPodItem.order_item.uuid === dPodItem.order_item.uuid
          ) {
            dPodItem.order_item = updatedDPodItem.order_item;
            dPodItem.qty = updatedDPodItem.qty;
            dPodItem.shipment_box_item_id =
              updatedDPodItem.shipment_box_item_id;
            dPodItem.shipment_box_item_updated =
              updatedDPodItem.shipment_box_item_updated;
            found = true;
          }
        } else if (updatedDPodItem.type === 'BOX') {
          if (
            updatedDPodItem.box &&
            dPodItem.box &&
            updatedDPodItem.box.uuid === dPodItem.box.uuid
          ) {
            matchAndFillBox(updatedDPodItem.box, dPodItem.box, 0);
            found = true;
          }
        } else {
          //until we find a match we assume that we only have above two types
          found = true;
        }
      }
    }
    if (found === false) {
      _dPodResult.result.push(updatedDPodItem);
      _updatedResult.splice(i, 1);
      i--;
    }
  }

  //Loop through the result and remove the order item that no longer exists in updated result
  for (let i = 0; i < _dPodResult.result.length; i++) {
    const dPodItem = _dPodResult.result[`${i}`];
    if (
      dPodItem.order_id !== orderId ||
      (dPodItem.type === 'ORDER_ITEM' &&
        dPodItem.order_item.uuid !== orderItemId)
    ) {
      continue;
    }
    let found = false;
    for (let j = 0; j < updatedResult.length; j++) {
      const updatedDPodItem = updatedResult[`${j}`];
      if (
        dPodItem.type === updatedDPodItem.type &&
        dPodItem.shipment_id === updatedDPodItem.shipment_id
      ) {
        if (updatedDPodItem.type === 'ORDER_ITEM') {
          if (
            updatedDPodItem.order_item &&
            dPodItem.order_item &&
            updatedDPodItem.order_item.uuid === dPodItem.order_item.uuid
          ) {
            found = true;
          }
        } else if (updatedDPodItem.type === 'BOX') {
          if (
            updatedDPodItem.box &&
            dPodItem.box &&
            updatedDPodItem.box.uuid === dPodItem.box.uuid
          ) {
            matchAndFillBox(updatedDPodItem.box, updatedDPodItem.box, 0);
            found = true;
          }
        } else {
          //until we find a match we assume that we only have above two types
          found = true;
        }
      }
    }
    if (found === false) {
      if (dPodItem.type === 'ORDER_ITEM') {
        _dPodResult.result.splice(i, 1);
        i--;
        continue;
      } else if (dPodItem.type === 'BOX') {
        removeOrderItemFromBox(dPodItem.box);
      }
    }
    if (
      dPodItem.type === 'BOX' &&
      (!dPodItem.box ||
        !dPodItem.box.box_items ||
        dPodItem.box.box_items.length === 0)
    ) {
      _dPodResult.result.splice(i, 1);
      i--;
    }
  }

  if (isEqual(dPodResult, _dPodResult)) {
    return { modified: false, dPodResult: dPodResult };
  }
  return { modified: true, dPodResult: _dPodResult };
};

export const getCtxPendingItems = (ctx, pendingList) => {
  if (!ctx?.type?.value || !ctx?.value?.key || !pendingList) {
    return [];
  } else {
    return pendingList.filter(
      val =>
        val?.ctx?.type?.value === ctx?.type?.value &&
        val?.ctx?.value?.key === ctx?.value?.key,
    );
  }
};
