import { createAction, createReducer } from '@reduxjs/toolkit';

export const addPrinters = createAction('ADD_PRINTERS');
export const addPrinter = createAction('ADD_PRINTER');
export const selectPrinter = createAction('SELECT_PRINTER');

export const DEFAULT = {
  printers: [],
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(addPrinters, (state, action) => {
    if (!action.payload) return;
    state.printers = [];
    state.printers = action.payload;
    state.printers.forEach(val => {
      val.selected = false;
    });
  });
  builder.addCase(addPrinter, (state, action) => {
    if (!action.payload) return;
    const printers = state.printers;
    const printer = action.payload;
    const printerMatchIndex = printers.findIndex(
      val => val.printer_id === printer.printer_id,
    );
    if (printerMatchIndex === -1) {
      printer.selected = false;
      printers.push(printer);
    } else {
      const matchPrinter = printers[parseInt(printerMatchIndex)];
      if (printer.selected === undefined) {
        printer.selected = matchPrinter.selected;
      }
      printers[parseInt(printerMatchIndex)] = printer;
    }
    if (printer.selected === true) {
      printers
        .filter(
          val => val.printer_id !== printer.printer_id && val.selected === true,
        )
        .forEach(val => {
          val.selected = false;
        });
    }
  });
});
export default reducer;
