import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}
export const TYPES = {
  REPORT_RESULT: 'REPORT_RESULT',
  ADD_NOTE: 'ADD_NOTE',
  REPORT_CONFIGS: 'REPORT_CONFIGS',
};

const getActionName = (type, verb) =>
  actionTypeName('REPORT_RESULT', type, verb);

export const setReportResult = createAction(
  getActionName(TYPES.REPORT_RESULT, 'SET'),
);

export const addReportNote = createAction(
  getActionName(TYPES.ADD_NOTE, 'PUSH'),
);

export const resetReportNote = createAction(
  getActionName(TYPES.ADD_NOTE, 'RESET'),
);
export const deleteReportNote = createAction(
  getActionName(TYPES.ADD_NOTE, 'DELETE'),
);
export const setReportConfigs = createAction(
  getActionName(TYPES.REPORT_CONFIGS, 'SET'),
);
export const DEFAULT = {
  report_result: {
    report_id: null,
    result: [],
    page: 0,
  },
  notes: [],
  report_configs: [],
};

export const resetReportResult = () => dispatch => {
  dispatch(setReportResult(DEFAULT.report_result));
  dispatch(resetReportNote());
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setReportResult, (state, { payload }) => {
    return Update(state, {
      report_result: { $set: payload },
    });
  });
  builder.addCase(addReportNote, (state, { payload }) => {
    return Update(state, {
      notes: { $push: [payload] },
    });
  });
  builder.addCase(deleteReportNote, (state, { index }) => {
    return Update(state, {
      notes: { $splice: [[index, 1]] },
    });
  });
  builder.addCase(resetReportNote, state => {
    return Update(state, {
      notes: { $set: [] },
    });
  });
  builder.addCase(setReportConfigs, (state, { payload }) => {
    return Update(state, {
      report_configs: { $set: payload },
    });
  });
});

export default reducer;
