import React from 'react';
import BottomDrawer from '../../components/template/bottomdrawer';

const BottomDrawerContent = ({
  isMobile,
  isTab,
  menuOpen,
  setMenuOpen,
  title,
  delWorkstation,
  markInactive,
  id,
}) => {
  return (
    <BottomDrawer
      entity={''}
      drawerTitle=''
      handleClose={() => setMenuOpen(false)}
      popUp={menuOpen && (isMobile || isTab)}
      drawerBody={
        <div
          style={{
            margin: 'auto',
            padding: '30px',
            position: 'relative',
          }}
        >
          <div style={{ fontSize: '20px', textAlign: 'center' }}>{title}</div>
          <div style={{ height: '32px' }}></div>
          <div>
            <div
              style={{
                color: '#006EED',
                height: '20px',
                textAlign: 'center',
                paddingTop: '12px',
                cursor: 'pointer',
              }}
              onClick={() => {
                markInactive(id);
                setMenuOpen(false);
              }}
            >
              Mark as Inactive
            </div>
            <div
              className='operation-checklist-line'
              style={{
                borderColor: '#C4C4C4',
              }}
            ></div>
            <div
              style={{
                color: '#C00000',
                height: '20px',
                textAlign: 'center',
                paddingTop: '12px',
                cursor: 'pointer',
              }}
              onClick={() => {
                delWorkstation(id);
                setMenuOpen(false);
              }}
            >
              Delete
            </div>
          </div>
        </div>
      }
    />
  );
};

export default BottomDrawerContent;
