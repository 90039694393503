import { TYPES } from './const';
import { createAction } from '@reduxjs/toolkit';

export const resetBoxAndItems = createAction('RESET_ALL');
export const addItem = createAction(TYPES.ADD_ITEM);
export const editItemAction = createAction(TYPES.EDIT_ITEM);
export const openBox = createAction(TYPES.OPEN_BOX);
export const closeBox = createAction(TYPES.CLOSE_BOX);
export const toggleDetailsView = createAction('TOGGLE_DETAILS_VIEW');
export const toggleExtra = createAction(TYPES.TOGGLE_EXTRA);

export const editItem = (value, item) => dispatch => {
  dispatch(
    editItemAction({
      quantity: value,
      item,
    }),
  );
};

export const saveBoxHistory = payload => ({
  type: TYPES.BOX_HISTORY,
  payload,
});
