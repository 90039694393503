import React, { Fragment } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

export const EditIcon = ({ onClick }) => {
  return (
    <div onClick={onClick} className='cursor-pointer'>
      <svg
        width='14'
        height='13'
        viewBox='0 0 14 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.35938 8.09375L9.48438 0.96875C10.0703 0.382812 11.0312 0.382812 11.6172 0.96875L12.5312 1.88281C12.6016 1.95312 12.6719 2.04688 12.7188 2.11719C13.1172 2.70312 13.0469 3.5 12.5312 4.01562L5.40625 11.1406C5.38281 11.1641 5.33594 11.1875 5.3125 11.2344C5.07812 11.4219 4.82031 11.5625 4.53906 11.6562L1.70312 12.4766C1.51562 12.5469 1.30469 12.5 1.16406 12.3359C1 12.1953 0.953125 11.9844 1 11.7969L1.84375 8.96094C1.9375 8.63281 2.125 8.32812 2.35938 8.09375ZM2.92188 9.28906L2.38281 11.1172L4.21094 10.5781C4.35156 10.5312 4.49219 10.4609 4.60938 10.3438L9.97656 4.97656L8.5 3.52344L3.15625 8.89062C3.13281 8.89062 3.13281 8.91406 3.10938 8.9375C3.01562 9.03125 2.96875 9.14844 2.92188 9.28906Z'
          fill='#001714'
        />
      </svg>
    </div>
  );
};
export const DeleteIcon = ({ onClick }) => {
  return (
    <div onClick={onClick} className='cursor-pointer'>
      <svg
        width='12'
        height='13'
        viewBox='0 0 12 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.6875 2.375H8.95312L8.15625 1.03906C7.94531 0.710938 7.57031 0.5 7.17188 0.5H4.82812C4.42969 0.5 4.05469 0.710938 3.84375 1.03906L3.04688 2.375H1.3125C1.00781 2.375 0.75 2.63281 0.75 2.9375C0.75 3.24219 1.00781 3.5 1.3125 3.5H1.5L1.99219 11.4453C2.03906 12.0312 2.53125 12.5 3.11719 12.5H8.88281C9.46875 12.5 9.96094 12.0312 10.0078 11.4453L10.5 3.5H10.6875C10.9922 3.5 11.25 3.24219 11.25 2.9375C11.25 2.63281 10.9922 2.375 10.6875 2.375ZM4.82812 1.625H7.17188L7.64062 2.375H4.35938L4.82812 1.625ZM8.88281 11.375H3.11719L2.625 3.5H9.375L8.88281 11.375Z'
          fill='#001714'
        />
      </svg>
    </div>
  );
};

export const CopyIcon = ({ onClick }) => {
  return (
    <div onClick={onClick} className='cursor-pointer'>
      <svg
        width='13'
        height='13'
        viewBox='0 0 13 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.7656 2.16406L11.3359 0.734375C11.1953 0.59375 11.0078 0.5 10.7969 0.5H6.97656C6.13281 0.5 5.47656 1.17969 5.47656 2V8C5.5 8.84375 6.15625 9.5 7 9.5H11.5C12.3203 9.5 13 8.84375 13 8V2.70312C13 2.49219 12.9062 2.30469 12.7656 2.16406ZM11.875 8C11.875 8.21094 11.6875 8.375 11.5 8.375H6.97656C6.76562 8.375 6.60156 8.21094 6.60156 8V2.02344C6.60156 1.8125 6.76562 1.64844 6.97656 1.64844H9.97656L10 2.75C10 3.17188 10.3281 3.5 10.75 3.5H11.8516V8H11.875ZM7.375 11C7.375 11.2109 7.1875 11.375 7 11.375H2.47656C2.26562 11.375 2.10156 11.2109 2.10156 11V5.02344C2.10156 4.8125 2.28906 4.64844 2.47656 4.64844H4.75V3.5H2.47656C1.65625 3.5 0.976562 4.17969 0.976562 5L1 11C1 11.8438 1.65625 12.5 2.5 12.5H7C7.82031 12.5 8.5 11.8438 8.5 11V10.25H7.375V11Z'
          fill='#001714'
        />
      </svg>
    </div>
  );
};

export const SelectedOptimizationIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 0.5C3.84375 0.5 0.5 3.84375 0.5 8C0.5 12.1562 3.84375 15.5 8 15.5C12.1562 15.5 15.5 12.1562 15.5 8C15.5 3.84375 12.1562 0.5 8 0.5ZM11.625 6.625L7.625 10.625C7.4375 10.7812 7.21875 10.875 7 10.875C6.78125 10.875 6.5625 10.7812 6.375 10.625L4.375 8.625C4.03125 8.28125 4.03125 7.71875 4.375 7.375C4.71875 7.03125 5.28125 7.03125 5.625 7.375L7 8.75L10.375 5.375C10.7188 5.03125 11.2812 5.03125 11.625 5.375C11.9688 5.71875 11.9688 6.28125 11.625 6.625Z'
        fill='#00C06F'
      />
    </svg>
  );
};

export const NoMaterialIcon = ({ onClick }) => {
  return (
    <div className={`${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.15625 8.03125C8.41797 8.03125 7.84375 8.63281 7.84375 9.34375C7.84375 10.082 8.41797 10.6562 9.15625 10.6562C9.86719 10.6562 10.4688 10.082 10.4688 9.34375C10.4688 8.63281 9.86719 8.03125 9.15625 8.03125ZM17.2227 5.625H6.72266C5.76562 5.625 4.97266 6.41797 4.97266 7.375V16.125C4.97266 17.1094 5.76562 17.875 6.72266 17.875H17.2227C18.1797 17.875 18.9727 17.1094 18.9727 16.125V7.375C18.9727 6.41797 18.207 5.625 17.2227 5.625ZM17.6602 15.9609L13.9141 10.875C13.832 10.7383 13.6953 10.6562 13.5312 10.6562C13.3398 10.6562 13.2031 10.7383 13.0938 10.875L10.1953 14.8125L9.18359 13.5547C9.07422 13.4453 8.9375 13.3633 8.77344 13.3633C8.60938 13.3633 8.47266 13.4453 8.36328 13.5547L6.3125 16.125L6.28516 7.375C6.28516 7.15625 6.50391 6.9375 6.72266 6.9375H17.2227C17.4688 6.9375 17.6602 7.15625 17.6602 7.375V15.9609Z'
          fill='#323232'
          fillOpacity='0.4'
        />
        <rect
          x='0.5'
          y='0.5'
          width='23'
          height='23'
          rx='3.5'
          stroke='#C0C0C0'
        />
      </svg>
    </div>
  );
};

export const WoTableHeader = ({ cols, colMap }) => {
  const borderColor = '#C0C0C0';
  const borderStyle = `2px solid ${borderColor}`;
  return (
    <Fragment>
      <div
        className='rubik-font d-flex bg-white pt-2 pb-1 px-3 '
        style={{
          zIndex: '1',
          position: 'sticky',
          top: '-30px',
          borderBottom: borderStyle,
          fontWeight: 'bold',
          color: '#323232',
          fontSize: '12px',
        }}
      >
        {cols.map(col => {
          const colVal = colMap[`${col}`] || { width: 0 };
          return (
            <Fragment key={col}>
              <div
                style={{ width: colVal.width + 'px' }}
                className={`d-flex px-2 align-items-center justify-content-${colVal.align}`}
              >
                {colVal.label}
              </div>
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

export const RowAction = ({ actionLabel, actions }) => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className='clickable'
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
  return (
    <Fragment>
      <Dropdown className='page-size-selector position-relative'>
        <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
          <div
            className='d-flex justify-content-center cursor-pointer dropdown-content'
            style={{ padding: '6px' }}
          >
            <div className='me-2 primary-btn-color'>{actionLabel}</div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {actions &&
            actions.map(action => {
              return (
                <Fragment key={action.key}>
                  <Dropdown.Item
                    className='cursor-pointer'
                    eventKey={action.key}
                    onClick={() => {
                      if (action.onClick) {
                        action.onClick();
                      }
                    }}
                  >
                    {action.label}
                  </Dropdown.Item>
                </Fragment>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export const MaterialImage = ({
  material,
  onClick,
  width = '24px',
  height = '24px',
}) => {
  return (
    <Fragment>
      {(!material.images || material.images.length === 0) && (
        <NoMaterialIcon onClick={() => (onClick ? onClick(material) : null)} />
      )}
      {material.images && material.images.length > 0 && (
        <div
          style={{
            width: width,
            height: height,
            border: '1px solid #C0C0C0',
            borderRadius: '2px',
            overflow: 'hidden',
          }}
          onClick={() => (onClick ? onClick(material) : null)}
        >
          <img
            alt={material.material}
            width='100%'
            src={
              (material.image_updated ? 'data:image/png;base64,' : '') +
              material.images[0]
            }
            style={{ objectFit: 'contain' }}
          />
        </div>
      )}
    </Fragment>
  );
};

export const ConfigureMaterialTableHeader = ({ cols, colMap }) => {
  return (
    <Fragment>
      <div
        className='font-weight-bold d-flex table mb-0 position-relative'
        style={{
          zIndex: '1',
          position: 'sticky',
          top: '0px',
          borderBottom: '2px solid #C0C0C0',
          fontWeight: 'bold',
          color: '#323232',
          fontSize: '12px',
        }}
      >
        {cols.map(col => {
          const colVal = colMap[`${col}`] || { width: 0 };
          return (
            <Fragment key={col}>
              <div
                style={{ width: colVal.width + 'px' }}
                className={`d-flex px-2 align-items-center justify-content-${colVal.align}`}
              >
                {colVal.label}
              </div>
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};
