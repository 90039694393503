import { DEFAULT } from './const';
import { createReducer } from '@reduxjs/toolkit';
import {
  addItem,
  editItemAction,
  openBox,
  closeBox,
  toggleDetailsView,
  resetBoxAndItems,
} from './actions';

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetBoxAndItems, state => {
    state.items = [];
    state.visible = false;
    state.openedBoxId = null;
    state.openedBoxCode = null;
    state.isEditable = false;
    state.detailsView = false;
  });
  builder.addCase(addItem, (state, action) => {
    state.items.push(action.payload);
  });
  builder.addCase(openBox, (state, action) => {
    if (action.payload.deleted === true) {
      state.visible = false;
      state.openedBoxId = null;
      state.openedBoxCode = null;
      state.isEditable = false;
    } else {
      state.visible = true;
      state.openedBoxId = action.payload.uuid;
      state.openedBoxCode = action.payload.code;
      state.isEditable = action.payload.isEditable;
    }
  });
  builder.addCase(closeBox, (state, action) => {
    state.items = [];
    state.visible = false;
    state.openedBoxId = null;
    state.openedBoxCode = null;
    state.isEditable = false;
  });
  builder.addCase(editItemAction, (state, action) => {
    const item = state.items.find(
      val => val.item_uuid === action.payload.item.uuid,
    );
    if (item !== undefined) {
      item.qty = action.payload.qty;
    }
  });
  builder.addCase(toggleDetailsView, (state, action) => {
    state.detailsView = !state.detailsView;
  });
});

export default reducer;
