"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _const = require("./const");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var remove = function remove(busy, ind) {
  var new_busy = _toConsumableArray(busy);

  new_busy.splice(ind, 1);
  return new_busy;
};

var reducer = function reducer() {
  var busy = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _const.DEFAULT;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  var t = busy.indexOf(type);

  if (t >= 0) {
    return remove(busy, t);
  }

  switch (action.type) {
    case _const.TYPES.ADD:
      {
        return [].concat(_toConsumableArray(busy), [action.key]);
      }

    case _const.TYPES.REMOVE:
      {
        var k = busy.indexOf(action.key);

        if (k >= 0) {
          return remove(busy, k);
        }

        return _toConsumableArray(busy);
      }

    default:
      {
        return _toConsumableArray(busy);
      }
  }
};

var _default = reducer;
exports.default = _default;