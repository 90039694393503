import React, { Fragment, useState, useCallback, useEffect } from 'react';
import '../../scss/components/_dropdown.scss';
import IconMenu from '../../assets/icons/icon-menu.svg';
import { toggleSideMenu } from '../../store/app/nav/actions';
import ArrowIcon from '../../assets/icons/arrow-icon.svg';
import ScreenLoader from '../../components/common/screen-loader';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import IconAddBox from '../../components/packing/common/icon-add-box';
import { useHistory } from 'react-router-dom';
import NewBoxMenu from './newbox-menu';
import useMobile from '../../hooks/useMobile';
import { actions } from '@naadi/framework';
import { toastError, toastMessage } from '../../helpers/packing/packingHelper';
import {
  deleteOperation,
  fetchOperations,
  createOperation,
} from '../../services/operation';
import BottomDrawerContent from './bottom-drawer-menu';
const TXT_COL = 'rgba(50, 50, 50, 0.4)';

const OperationHeader = () => {
  const dispatch = useDispatch();
  const handleMenuToggle = () => {
    dispatch(toggleSideMenu());
  };
  return (
    <Fragment>
      <div
        className='flex-row header navbar-expand-lg d-flex bg-white p-3 py-4 '
        style={{ zIndex: 10, width: '100%' }}
      >
        <button
          className='p-0 border-0 bg-white'
          data-toggle='offcanvas'
          type='button'
        >
          <img
            src={IconMenu}
            alt='Menu'
            onClick={() => handleMenuToggle()}
          ></img>
        </button>
        <div className='ms-2 page-header'>
          <div className='page-title d-flex align-items-center'>
            <div className='ps-2'>Operations</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ImageDisplay = () => {
  return (
    <Fragment>
      <div style={{ height: '210px', background: 'white' }}></div>
      <div style={{ textAlign: 'center' }}>
        <img src={ArrowIcon} alt=''></img>
      </div>
      <div style={{ height: '24px' }}></div>
      <div
        style={{
          font: 'Roboto',
          fontSize: '16px',
          color: TXT_COL,
          background: 'white',
          textAlign: 'center',
        }}
      >
        Tap on + to add an operation
      </div>
    </Fragment>
  );
};

const BoxDisplay = ({
  operationUuid,
  operationName,
  isMobile,
  isTab,
  menuOpen,
  setMenuOpen,
  delOperation,
  markInactive,
  setTitleText,
  setOperationId,
}) => {
  const history = useHistory();
  return (
    <div
      className='col-12 col-sm-6 col-md-6  col-lg-4 col-xl-4'
      style={{
        paddingLeft: '16px',
        paddingBottom: '16px',
        marginTop: '0px',
      }}
    >
      <div
        className='border rounded shadow-sm px-4 py-3 bg-white'
        style={{ height: '92px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            className='cursor-pointer'
            style={{ paddingTop: '8px' }}
            onClick={() =>
              history.push(`/app/operations/newoperation?uuid=` + operationUuid)
            }
          >
            {operationName}
          </div>
          <div className='d-flex'>
            <NewBoxMenu
              id={operationUuid}
              box={true}
              isMobile={isMobile}
              isTab={isTab}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              delOperation={delOperation}
              markInactive={markInactive}
              titleText={operationName}
              setTitleText={setTitleText}
              setOperationId={setOperationId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Operation = () => {
  const [isMobile, isTab] = useMobile();
  const history = useHistory();
  const [operationList, setOperationList] = useState([]);
  const [operationId, setOperationId] = useState();
  const [titleText, setTitleText] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const FetchOperation = useCallback(
    async oid => {
      const req = {
        approved: true,
        active: true,
        deleted: false,
        page: 0,
        size: 100,
        sort: [{ column: 'operation_code', asc: true }],
        req_payload: {
          deleted: [false],
          active: [true],
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_OPERATION'));
        const fetchOp = await fetchOperations(req);
        if (fetchOp && fetchOp.status !== 200) {
          return;
        }
        setOperationList(fetchOp.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_OPERATION'));
      }
    },
    [dispatch],
  );
  useEffect(() => {
    FetchOperation();
  }, [FetchOperation]);
  const delOperation = useCallback(
    async oid => {
      try {
        dispatch(actions.busy.add('DELETE_OPERATION'));
        const deleteDF = await deleteOperation(oid);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Delete Operation');
        }
        toastMessage({
          type: 'success',
          message: 'Operation Removed Successfully',
        });
        FetchOperation();
      } finally {
        dispatch(actions.busy.remove('DELETE_OPERATION'));
      }
    },
    [dispatch, FetchOperation],
  );
  const markInactive = useCallback(
    async oid => {
      const req = {
        active: false,
        approved: true,
        deleted: false,
        req_payload: {
          uuid: oid,
        },
      };
      try {
        dispatch(actions.busy.add('MARK_INACTIVE'));
        const deleteDF = await createOperation(req);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Mark as Inactive');
        }
        toastMessage({
          type: 'success',
          message: 'Operation Marked as Inactive',
        });
        FetchOperation();
      } finally {
        dispatch(actions.busy.remove('MARK_INACTIVE'));
      }
    },
    [dispatch, FetchOperation],
  );
  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ToastContainer />

      <div>
        <div className='container-fluid position-relative px-0'>
          <BottomDrawerContent
            isMobile={isMobile}
            isTab={isTab}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            title={titleText}
            delOperation={delOperation}
            markInactive={markInactive}
            id={operationId}
          />
          <OperationHeader />
          <div style={{ background: 'white', marginLeft: '50px' }}>
            <div
              style={{
                paddingTop: '48px',
                paddingLeft: '16px',
                paddingRight: '16px',
              }}
            >
              <div className='w-100'>
                <div className='row'>
                  {operationList &&
                    operationList.map(op => {
                      return (
                        <BoxDisplay
                          key={op.uuid}
                          operationUuid={op.uuid}
                          operationName={op.name}
                          isMobile={isMobile}
                          isTab={isTab}
                          setMenuOpen={setMenuOpen}
                          delOperation={delOperation}
                          markInactive={markInactive}
                          setTitleText={setTitleText}
                          setOperationId={setOperationId}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            {(!operationList || operationList.length === 0) && <ImageDisplay />}
          </div>
        </div>
      </div>
      <button className='floating-btn'>
        <IconAddBox
          onClick={() => history.push(`/app/operations/newoperation`)}
        />
      </button>
    </div>
  );
};

export default Operation;
