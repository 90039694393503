import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { updateStationLocation } from '../../store/tracktrace/stationstate';
import * as XLSX from 'xlsx';
import {
  TrackTraceRejectIcon,
  TrackTraceSuccessIcon,
} from '../../assets/icons/icon-tracktrace';
import { trackTraceScan } from '../../helpers/tracktrace/orderItemHelper';
import useMobile from '../../hooks/useMobile';
import { UploadIcon } from '../../assets/icons/common-icons';
import { getBoxItemsWithFilter } from '../../services/packing';
import { toastResponseError } from '../../helpers/common/helper';

export const UploadScanCodes = ({
  scannedItems,
  appendScannedItemsCb,
  syncCb,
}) => {
  const [scanCodesToUpload, setScanCodesToUpload] = useState([]);
  //eslint-disable-next-line
  const [isMobile, isTab] = useMobile();
  const hiddenFileInput = React.useRef(null);
  const onUploadClick = useCallback(event => {
    hiddenFileInput.current.click();
  }, []);
  const handleChange = useCallback(
    event => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = evt => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname + ''];
        /* Convert array of arrays */
        const dataString = XLSX.utils.sheet_to_csv(ws, { header: 0 });
        const dataStringLines = dataString.split(/\r\n|\n/);
        const scanCodes = [];
        dataStringLines.forEach(val => {
          //eslint-disable-next-line
          const row = val.split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
          row.forEach(col => {
            if (!col || col.length === 0) {
              return;
            }
            if (scanCodes.indexOf(col) === -1) {
              scanCodes.push(col);
            }
          });
        });
        setScanCodesToUpload(scanCodes);
      };
      reader.readAsBinaryString(file);
    },
    [setScanCodesToUpload],
  );
  return (
    <Fragment>
      <div
        className='cursor-pointer'
        style={{
          maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        onClick={onUploadClick}
      >
        {!isTab && <UploadIcon />}
        {isTab && <div>Upload Scan Codes</div>}
      </div>
      <input
        type='file'
        accept='.csv'
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      {scanCodesToUpload.length > 0 && (
        <ScanCodesUploadModal
          scanCodes={scanCodesToUpload}
          show={scanCodesToUpload.length > 0}
          closeCb={() => {
            setScanCodesToUpload([]);
          }}
          scannedItems={scannedItems}
          appendScannedItemsCb={appendScannedItemsCb}
          syncCb={syncCb}
        />
      )}
    </Fragment>
  );
};
UploadScanCodes.propTypes = {
  appendScannedItemsCb: PropTypes.func.isRequired,
  scannedItems: PropTypes.array.isRequired,
};

const closeModal = closeCb => {
  if (closeCb) {
    closeCb();
  }
};
const ScanCodesUploadModal = ({
  show,
  closeCb,
  scanCodes,
  scannedItems,
  appendScannedItemsCb,
  syncCb,
}) => {
  const dispatch = useDispatch();
  const [upload, setUpload] = useState(false);
  const { current_station } = useSelector(state => state.stationstate);
  const [uploadCodes, setUploadCodes] = useState([]);
  useEffect(() => {
    setUploadCodes(
      scanCodes.map(val => {
        return {
          code: val,
          checked: true,
          scanned: false,
          errors: [],
          items: [],
        };
      }),
    );
  }, [scanCodes]);
  const getBoxList = useCallback(async codes => {
    if (!codes || codes.length === 0) {
      return {};
    }
    const filter = {
      page: 0,
      size: codes.length,
      req_payload: {
        deleted: [false],
        box_code: [...codes],
      },
    };
    const boxItemResp = await getBoxItemsWithFilter(filter);
    if (boxItemResp.status !== 200) {
      toastResponseError(boxItemResp, 'Unable to Fetch the Box Detail');
      return null;
    }
    const boxItemMap = {};
    boxItemResp.payload.forEach(val => {
      if (val.box_item_type === 'ORDER') {
        if (!boxItemMap[`${val.box_item_id}`]) {
          boxItemMap[`${val.box_item_id}`] = [];
        }
        boxItemMap[`${val.box_item_id}`].push(val.box_item_id);
      }
    });
    return boxItemMap;
  }, []);

  const scanRecords = useCallback(
    async codes => {
      let boxCodeMap = null;
      if (current_station.allow_box_scan) {
        boxCodeMap = await getBoxList(codes);
      }
      if (boxCodeMap === null) {
        return;
      }
      for (let i = 0; i < codes.length; i++) {
        const uploadCode = codes[`${i}`];
        let boxScanCodes = [uploadCode.code];
        if (boxCodeMap[`${uploadCode.code}`]) {
          boxScanCodes = [...boxCodeMap[`${uploadCode.code}`]];
        }
        const processedStat = [];
        for (let j = 0; j < boxScanCodes.length; j++) {
          const boxScanCode = boxScanCodes[`${j}`];
          const resp = await trackTraceScan(
            boxScanCode,
            true,
            { rejected: false },
            [],
            true,
            dispatch,
            scannedItems,
            appendScannedItemsCb,
            syncCb,
          );
          if (resp.challenge === true) {
            return;
          }
          processedStat.push(resp);
        }
        const finalStat = {
          success: true,
          errors: [],
        };
        processedStat.forEach(val => {
          if (!val.success) {
            finalStat.success = false;
          }
          if (val.error && val.error != null) {
            finalStat.errors.push(val.error);
          }
        });
        setUploadCodes(uCodes => {
          for (let j = 0; j < uCodes.length; j++) {
            const uCode = uCodes[`${j}`];
            uCode.scanned = finalStat.success;
            uCode.errors = finalStat.error;
          }
          return uCodes;
        });
      }
      setUpload(false);
    },
    [
      dispatch,
      setUploadCodes,
      setUpload,
      current_station,
      getBoxList,
      scannedItems,
      appendScannedItemsCb,
      syncCb,
    ],
  );
  return (
    <Fragment>
      <Modal
        centered={true}
        size={'lg'}
        show={show}
        onHide={closeCb}
        dialogClassName='naadi-modal-mobile'
      >
        <Modal.Header style={{ borderBottom: '0px' }} closeButton>
          <div className='d-flex align-items-center' style={{ height: '32px' }}>
            <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
              Upload Codes
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            className='row'
            style={{ maxHeight: '80vh', overflowY: 'scroll' }}
          >
            {uploadCodes &&
              uploadCodes.map(uploadCode => {
                return (
                  <div className='col-12 d-flex' key={uploadCode.code}>
                    <div style={{ width: '30px', padding: '8px' }}>
                      <input
                        type='checkbox'
                        className='main-box-checkbox cursor-pointer'
                        value={uploadCode.code}
                        onChange={e => {
                          if (upload) return;
                          const newCodes = [...uploadCodes];
                          newCodes.forEach(val => {
                            if (val.code === uploadCode.code) {
                              val.checked = !val.checked;
                            }
                          });
                          setUploadCodes(newCodes);
                        }}
                        disabled={upload}
                        checked={uploadCode.checked}
                      />
                    </div>
                    <div
                      className='d-flex align-items-center'
                      style={{ padding: '6px' }}
                    >
                      {uploadCode.code}
                    </div>
                    <div
                      className='d-flex track-trace-card-status-icons'
                      style={{
                        top: 'initial',
                        right: 'initial',
                        position: 'initial',
                      }}
                    >
                      <div
                        className={`status-icon cursor-pointer  ${
                          uploadCode.scanned ? 'selected success' : ''
                        }`}
                      >
                        <TrackTraceSuccessIcon />
                      </div>

                      <div
                        className={`status-icon cursor-pointer ${
                          uploadCode?.errors?.length > 0 ? 'selected error' : ''
                        }`}
                      >
                        <TrackTraceRejectIcon />{' '}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className='track-trace-location-sel-action-wrp'>
            {!upload && (
              <div
                className='btn btn-outline-primary me-2'
                onClick={() => closeModal(closeCb)}
              >
                Cancel
              </div>
            )}
            {!upload && current_station && current_station.uuid && (
              <div
                className='btn btn-primary '
                onClick={() => {
                  if (current_station && current_station.uuid) {
                    setUpload(true);
                  }
                  scanRecords(uploadCodes);
                }}
              >
                Confirm
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
ScanCodesUploadModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  scannedItems: PropTypes.array.isRequired,
  appendScannedItemsCb: PropTypes.func.isRequired,
};

export const LocationSelectText = () => {
  const [showLocationModal, setShowLocationModal] = useState(false);

  const { current_station, station_location } = useSelector(
    state => state.stationstate,
  );
  const [location, setLocation] = useState('Click Here');
  useEffect(() => {
    const CLICK_HERE = 'Add Location';
    let _location = CLICK_HERE;
    if (
      station_location &&
      current_station &&
      current_station.uuid &&
      station_location[`${current_station.uuid}`]
    ) {
      _location = station_location[`${current_station.uuid}`] || CLICK_HERE;
      setLocation(CLICK_HERE);
    }
    if (_location === '') {
      _location = CLICK_HERE;
    }
    setLocation(_location);
  }, [current_station, station_location]);
  return (
    <Fragment>
      <div
        style={{
          maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        onClick={() => {
          if (current_station && current_station.uuid) {
            setShowLocationModal(true);
          }
        }}
      >
        {location}
      </div>
      {showLocationModal === true && (
        <LocationSelectModal
          show={showLocationModal}
          closeCb={() => {
            setShowLocationModal(false);
          }}
        />
      )}
    </Fragment>
  );
};
LocationSelectText.propTypes = {};

const LocationSelectModal = ({ show, closeCb }) => {
  const dispatch = useDispatch();
  const { station_location, current_station } = useSelector(
    state => state.stationstate,
  );
  const [location, setLocation] = useState('');
  useEffect(() => {
    if (
      !station_location ||
      !current_station ||
      !current_station.uuid ||
      !station_location[`${current_station.uuid}`]
    ) {
      setLocation('');
    } else {
      setLocation(station_location[`${current_station.uuid}`] || '');
    }
  }, [station_location, current_station]);
  return (
    <Fragment>
      <Modal
        centered={true}
        size={'lg'}
        show={show}
        onHide={closeCb}
        dialogClassName='naadi-modal-mobile'
      >
        <Modal.Header style={{ borderBottom: '0px' }} closeButton>
          <div className='d-flex align-items-center' style={{ height: '32px' }}>
            <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
              Change Location
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className='input-container'>
              <label className='mb-0 formLabel w-100 text-center'>
                Location
              </label>
              <input
                id='reportPresetName'
                name='preset'
                type='text'
                className='input-fields'
                value={location}
                style={{ textAlign: 'center' }}
                onChange={e => setLocation(e.target.value)}
              />
            </div>
          </div>
          <div className='track-trace-location-sel-action-wrp'>
            <div
              className='btn btn-outline-primary me-2'
              onClick={() => closeModal(closeCb)}
            >
              Cancel
            </div>
            {current_station && current_station.uuid && (
              <div
                className='btn btn-primary '
                onClick={() => {
                  if (current_station && current_station.uuid) {
                    dispatch(
                      updateStationLocation({
                        [current_station.uuid]: location,
                      }),
                    );
                    closeCb();
                  }
                }}
              >
                Confirm
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
LocationSelectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
};
export default LocationSelectModal;
