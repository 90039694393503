import service from './index';
import { getState } from '../store';
import { toastError } from '../helpers/packing/packingHelper';

export const getCoreMaterialList = async (term, page, size) => {
  const reqPaylad = {
    term: term,
    parent_item_code: ['MATERIAL'],
    feature_string_val: {
      MATERIAL_TYPE: ['CORE'],
    },
    latest: [true],
    deleted: [false],
    active: [true],
    skip_definition: true,
  };
  return await getItemList(reqPaylad);
};
export const getItemByCode = async code => {
  return await getItemList(
    {
      code: [code],
      deleted: [false],
      latest: [true],
      active: [true],
    },
    0,
    1,
  );
};
export const getItemList = (filter_params, page, size) => {
  const {
    user: { org, user_id },
  } = getState();
  const _page = page ? page : 0;
  const _size = size ? size : 10;
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/items/filter`,
    data: {
      user_id,
      page: _page,
      size: _size,
      org_id: org.uuid,
      req_payload: {
        ...filter_params,
      },
    },
  });
};

export const getUserStationInstance = () => {
  const {
    user: { org, branch },
  } = getState();
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  return service({
    method: 'GET',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationinstance/filter/${branchId}`,
    data: {},
  });
};

export const getStationInstances = (filter_params, page, size) => {
  const {
    user: { org, user_id },
  } = getState();
  const _page = page ? page : 0;
  const _size = size ? size : 10;
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/stationinstance/filter`,
    data: {
      user_id,
      page: _page,
      size: _size,
      org_id: org.uuid,
      req_payload: {
        ...filter_params,
      },
    },
  });
};

export const getAllOperations = () => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/operations/filter`,
    data: {
      user_id,
      page: 0,
      size: 1000,
      org_id: org.uuid,
      req_payload: {
        deleted: [false],
      },
    },
  });
};
export const generateMaterialCode = payload => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-item-service2/v1/naadi/items2/${org.uuid}/items/material/generate`,
    data: {
      req_payload: payload,
    },
  });
};

export const generateEdgeCode = payload => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-item-service2/v1/naadi/items2/${org.uuid}/items/edge/generate`,
    data: {
      req_payload: payload,
    },
  });
};

export const createItem = (payload, active = true) => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/items/`,
    data: {
      active: active,
      deleted: false,
      approved: true,
      req_payload: payload,
    },
  });
};
export const createFeatureItem = (itemUuid, featurePayload) => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/items/features/${itemUuid}/update`,
    data: {
      user_id,
      active: true,
      deleted: false,
      approved: true,
      req_payload: featurePayload,
    },
  });
};

export const printItemLabels = reqdata => {
  const user = getState().user;
  const itemcode = getState().itemsList.label_print_records;
  const { org, branch } = user;
  if (!reqdata || !itemcode.code) {
    toastError('There are No Labels to Print');
    return;
  }
  if (branch && branch.branch_id) {
    reqdata.req_payload.branch_id = branch.branch_id;
  }
  return service({
    method: 'POST',
    url: `/naadi-item-service2/v1/naadi/items2/${org.uuid}/items/printlabel/${itemcode.code}`,
    data: reqdata,
  });
};
export const getUomList = (filter_params, page, size) => {
  const {
    user: { org, user_id },
  } = getState();
  const _page = page ? page : 0;
  const _size = size ? size : 10;
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-item-service/v1/naadi/item/${org.uuid}/uom/filter`,
    data: {
      user_id,
      page: _page,
      size: _size,
      org_id: org.uuid,
      req_payload: {
        ...filter_params,
      },
    },
  });
};
