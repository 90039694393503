import service from './index';
import PropTypes from 'prop-types';
import { getState } from '../store';

export const createUser = req_payload => {
  const data = {
    req_payload,
  };
  return service({
    url: `/naadi-app/api/user/auth/create/user`,
    method: 'POST',
    skip_auth: true,
    data,
  });
};

export const createOrgUserLogin = data => {
  const {
    user: { org },
  } = getState();
  return service({
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/orguser/create?base64=true`,
    method: 'POST',
    data,
  });
};

export const fetchOrgUserLogin = data => {
  const {
    user: { org },
  } = getState();
  return service({
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/orguserlogin/filter`,
    method: 'POST',
    data,
  });
};

export const verifyUserLogin = loginUuid => {
  const {
    user: { org },
  } = getState();
  return service({
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/orguserlogin/verify/${loginUuid}`,
    method: 'PUT',
    data: {},
  });
};
export const verifyingUser = loginDt => {
  const {
    user: { org },
  } = getState();
  return service({
    url: `/naadi-org-service/v1/naadi/org/${org.uuid}/orguser/logins/${loginDt}/enable`,
    method: 'PUT',
    data: {},
  });
};

export const fetchVerifiedUser = loginDt => {
  return service({
    url: `/naadi-auth-service/v1/naadi/auth/userlogins/name/${loginDt}?fetch_enabled=true`,
    method: 'GET',
    headers: { Accept: 'application/json' },
  });
};

export const deleteUser = (data, id) => {
  const {
    user: { org, user_id },
  } = getState();
  data.user_id = user_id;
  return service({
    method: 'DELETE',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/orguser/${id}?user_id=${user_id}`,
    data,
  });
};
export const getCurrentUserDetail = token => {
  return service({
    url: `/naadi-app/naadi-web-service2/api/web/v1/user/currentuserdetail`,
    token,
  });
};
export const UserCustomConfigTypes = {
  REPORT_CUSTOM_COLUMNS: 'REPORT_CUSTOM_COLUMNS',
};
export const getUserCustomConfig = (config_type, config_key) => {
  return service({
    method: 'POST',
    url: `/naadi-app/api/web/v1/user/currentuserconfig/get`,
    data: {
      req_payload: {
        config_type: config_type,
        config_key: config_key,
      },
    },
  });
};
getUserCustomConfig.PropTypes = {
  config_type: PropTypes.string.isRequired,
  config_key: PropTypes.string.isRequired,
};
export const updateUserCustomConfig = (config_type, config_key, config_val) => {
  return service({
    method: 'POST',
    url: `/naadi-app/api/web/v1/user/currentuserconfig/update`,
    data: {
      active: true,
      approved: true,
      deleted: false,
      req_payload: {
        config_type: config_type,
        config_key: config_key,
        config_val: config_val,
      },
    },
  });
};

updateUserCustomConfig.PropTypes = {
  config_type: PropTypes.string.isRequired,
  config_key: PropTypes.string.isRequired,
  config_val: PropTypes.object.isRequired,
};
