import React, { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOrderProgressGroup,
  setOrderProgressGroup2,
  setOrderProgressGroup3,
  setOrderProgressItemType,
} from '../../../store/order/orderstate/orderstate';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDebouncedCallback } from 'use-debounce';
import { GROUPS } from '../../../helpers/order/progressHelper';
import Switch from 'react-switch';

const ChevronDownIcon = memo(() => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='8'
      fill='none'
      viewBox='0 0 12 8'
    >
      <path fill='#007BFF' d='M1.41.59L6 5.17 10.59.59 12 2 6 8 0 2 1.41.59z' />
    </svg>
  );
});

const ProgressItemTypeFilterDropdown = memo(() => {
  const dispatch = useDispatch();
  const { order_progress_item_type } = useSelector(state => state.orderstate);
  const [filters] = useState([
    {
      key: 'ALL',
      label: 'All',
    },
    {
      key: 'PART',
      label: 'Part',
    },
    {
      key: 'HARDWARE',
      label: 'Hardware',
    },
    {
      key: 'BOARD',
      label: 'Board',
    },
    {
      key: 'PART,BOARD',
      label: 'Part & Board',
    },
  ]);
  const [selectedItemType, setSelectedItemType] = useState({
    key: 'ALL',
    label: 'All',
  });
  useEffect(() => {
    const filterVal =
      filters.filter(val => val.key === order_progress_item_type)[0] || {};
    setSelectedItemType(filterVal);
  }, [filters, order_progress_item_type, setSelectedItemType]);

  const onDropdownSelect = data => {
    dispatch(setOrderProgressItemType(data.key));
  };

  return (
    <ProgressFilterDropDown
      filters={filters}
      selectedVal={selectedItemType}
      onDropdownSelect={onDropdownSelect}
    />
  );
});
const groupFilterList = [
  {
    key: GROUPS.ORDER.key,
    label: GROUPS.ORDER.label,
  },
  {
    key: GROUPS.PARENT_ORDER.key,
    label: GROUPS.PARENT_ORDER.label,
  },
  {
    key: GROUPS.CUSTOMER.key,
    label: GROUPS.CUSTOMER.label,
  },
  {
    key: GROUPS.ORDER_EXTERNAL_REFERENCE.key,
    label: GROUPS.ORDER_EXTERNAL_REFERENCE.label,
  },
  {
    key: GROUPS.SECONDARY_CUSTOMER.key,
    label: GROUPS.SECONDARY_CUSTOMER.label,
  },
];
ProgressItemTypeFilterDropdown.propTypes = {};
const ProgressGroupFilterDropdown = memo(() => {
  const dispatch = useDispatch();
  const { order_progress_group } = useSelector(state => state.orderstate);
  const [filters] = useState(groupFilterList);
  const [selectedGroup, setSelectedGroup] = useState({
    key: GROUPS.PARENT_ORDER.key,
    label: GROUPS.PARENT_ORDER.label,
  });
  useEffect(() => {
    const filterVal =
      filters.filter(val => val.key === order_progress_group)[0] || {};
    setSelectedGroup(filterVal);
  }, [filters, order_progress_group]);

  const onDropdownSelect = data => {
    dispatch(setOrderProgressGroup(data.key));
  };

  return (
    <ProgressFilterDropDown
      filters={filters}
      selectedVal={selectedGroup}
      onDropdownSelect={onDropdownSelect}
    />
  );
});

ProgressGroupFilterDropdown.propTypes = {};

const ProgressGroup2FilterDropdown = memo(() => {
  const dispatch = useDispatch();
  const { order_progress_group, order_progress_group2 } = useSelector(
    state => state.orderstate,
  );
  const [filters, setFilters] = useState([
    { key: GROUPS.NONE.key, label: GROUPS.NONE.label },
    ...groupFilterList,
  ]);
  const [selectedGroup, setSelectedGroup] = useState({
    key: GROUPS.NONE.key,
    label: GROUPS.NONE.label,
  });
  useEffect(() => {
    const filterVal =
      filters.filter(val => val.key === order_progress_group2)[0] || {};
    setSelectedGroup(filterVal);
  }, [filters, order_progress_group2]);
  useEffect(() => {
    setFilters([
      { key: GROUPS.NONE.key, label: GROUPS.NONE.label },
      ...groupFilterList.filter(val => {
        return val.key !== order_progress_group;
      }),
    ]);
  }, [setFilters, order_progress_group]);

  const onDropdownSelect = data => {
    dispatch(setOrderProgressGroup2(data.key));
  };

  return (
    <ProgressFilterDropDown
      filters={filters}
      selectedVal={selectedGroup}
      onDropdownSelect={onDropdownSelect}
    />
  );
});

const ProgressGroup3FilterDropdown = memo(() => {
  const dispatch = useDispatch();
  const { order_progress_group, order_progress_group2, order_progress_group3 } =
    useSelector(state => state.orderstate);
  const [filters, setFilters] = useState([
    { key: GROUPS.NONE.key, label: GROUPS.NONE.label },
    ...groupFilterList,
  ]);
  const [selectedGroup, setSelectedGroup] = useState({
    key: GROUPS.NONE.key,
    label: GROUPS.NONE.label,
  });
  useEffect(() => {
    const filterVal =
      filters.filter(val => val.key === order_progress_group3)[0] || {};
    setSelectedGroup(filterVal);
  }, [filters, order_progress_group3]);
  useEffect(() => {
    const f = [{ key: GROUPS.NONE.key, label: GROUPS.NONE.label }];
    if (
      [GROUPS.ORDER.key, GROUPS.PARENT_ORDER.key].indexOf(
        order_progress_group,
      ) === -1 &&
      [GROUPS.ORDER.key, GROUPS.PARENT_ORDER.key].indexOf(
        order_progress_group2,
      ) === -1
    ) {
    } else if (
      [order_progress_group, order_progress_group2].indexOf(
        GROUPS.PARENT_ORDER.key,
      ) === -1
    ) {
      groupFilterList
        .filter(val => {
          return (
            val.key !== order_progress_group &&
            val.key !== order_progress_group2
          );
        })
        .forEach(val => {
          f.push(val);
        });
    } else {
      f.push({ key: GROUPS.CUSTOMER.key, label: GROUPS.CUSTOMER.label });
    }
    setFilters(f);
  }, [setFilters, order_progress_group, order_progress_group2]);

  const onDropdownSelect = data => {
    dispatch(setOrderProgressGroup3(data.key));
  };

  return (
    <ProgressFilterDropDown
      filters={filters}
      selectedVal={selectedGroup}
      onDropdownSelect={onDropdownSelect}
    />
  );
});

const ProgressFilterDropDown = memo(
  ({ filters, selectedVal, onDropdownSelect }) => {
    const [is_dropdown_open, setDropdownState] = useState(false);
    const CustomToggle = React.forwardRef(
      (
        { children, onClick },
        ref, //eslint-disable-line
      ) => (
        <div //eslint-disable-line
          className={`title-sm cursor-pointer page-size-display ${
            is_dropdown_open ? 'active' : ''
          }`}
          ref={ref}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
        </div>
      ),
    );

    const onDropdownToggle = status => {
      setDropdownState(status);
    };

    return (
      <div className='d-flex'>
        <div id='track-trace-group-filter-wrp' style={{ maxWidth: '300px' }}>
          <Dropdown
            id='track-trace-group-filter'
            className='page-size-selector'
            onToggle={onDropdownToggle}
            drop={'right'}
            flip={'false'}
          >
            <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
              <span className={`btn-label text-primary pe-2 `}>
                {selectedVal.key ? selectedVal.label : 'Select'}
              </span>
              <ChevronDownIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu align={{ sm: 'right' }}>
              {filters.map(filter => (
                <Dropdown.Item
                  onClick={e => onDropdownSelect(filter)}
                  key={filter.key}
                  eventKey={filter.key}
                  active={filter.key === selectedVal.key}
                  className='track-trace-grp-dropdown-item'
                >
                  {filter.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  },
);
ProgressFilterDropDown.propTypes = {};

export const ProgressViewModes = ({
  filterCb,
  onDownloadCb,
  order_progress_completed,
  onProgressCompletedChange,
}) => {
  //const [isMobile] = useMobile();
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState('');
  const updateSearchKeyWord = useDebouncedCallback(
    term => {
      if (filterCb) {
        filterCb(term);
      }
    },
    500,
    { leading: false, trailing: true },
  );
  useEffect(() => {
    updateSearchKeyWord(searchKeyword);
  }, [searchKeyword, updateSearchKeyWord]);
  return (
    <Fragment>
      <div className={'row pt-2'}>
        <div className={'col-12 col-sm-6 d-flex'}>
          <div className='justify-content-start align-items-center primary-text-color'>
            <div className=''>
              <div
                className='text-label ps-1'
                onClick={e => dispatch(setOrderProgressItemType('ALL'))}
              >
                ITEM TYPE
              </div>
              <div className='position-relative ' style={{ maxWidth: '300px' }}>
                <ProgressItemTypeFilterDropdown />
              </div>
            </div>
          </div>
          <div className='justify-content-start align-items-center primary-text-color ps-2'>
            <div className=''>
              <div
                className='text-label ps-1'
                onClick={e => dispatch(setOrderProgressGroup(null))}
              >
                GROUP BY
              </div>
              <div className='position-relative ' style={{ maxWidth: '300px' }}>
                <ProgressGroupFilterDropdown />
              </div>
            </div>
          </div>
          <div className='justify-content-start align-items-center primary-text-color ps-2'>
            <div className=''>
              <div
                className='text-label ps-1'
                onClick={e => dispatch(setOrderProgressGroup2(GROUPS.NONE.key))}
              >
                GROUP BY 2
              </div>
              <div className='position-relative ' style={{ maxWidth: '300px' }}>
                <ProgressGroup2FilterDropdown />
              </div>
            </div>
          </div>
          <div className='justify-content-start align-items-center primary-text-color ps-2'>
            <div className=''>
              <div
                className='text-label ps-1'
                onClick={e => dispatch(setOrderProgressGroup2(GROUPS.NONE.key))}
              >
                GROUP BY 3
              </div>
              <div className='position-relative ' style={{ maxWidth: '300px' }}>
                <ProgressGroup3FilterDropdown />
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-6'>
          <div className='d-flex justify-content-end'>
            <div className='btn btn-outline-primary' onClick={onDownloadCb}>
              Download
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='d-flex justify-content-start'>
          <div
            className=' '
            style={{
              paddingTop: '16px',
              paddingRight: 0,
              maxWidth: '500px',
            }}
          >
            <input
              type='text'
              id='search_field'
              value={searchKeyword}
              onChange={e => setSearchKeyword(e.target.value)}
              className='searchBox'
              placeholder='Tap here to search'
            />
          </div>
          <div className='d-flex justify-content-end align-items-end p-2 w-100'>
            <div className='pe-2'>
              {order_progress_completed === true ? 'Completed' : 'Pending'}
            </div>
            <Switch
              onChange={onProgressCompletedChange}
              checked={order_progress_completed}
              boxShadow={'0px 0px 2px #027AFF'}
              height={18}
              width={32}
              handleDiameter={16}
              offColor={'#fff'}
              onColor={'#007bff'}
              checkedIcon={false}
              borderRadius={37}
              uncheckedIcon={false}
            ></Switch>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
