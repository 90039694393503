"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _actions = _interopRequireDefault(require("./busy/actions"));

var _actions2 = _interopRequireDefault(require("./lang/actions"));

var _actions3 = _interopRequireDefault(require("./user/actions"));

var _actions4 = _interopRequireDefault(require("./token/actions"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var actions = {
  busy: _actions.default,
  lang: _actions2.default,
  user: _actions3.default,
  token: _actions4.default
};
var _default = actions;
exports.default = _default;