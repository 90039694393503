"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.set = exports.default = void 0;

var _const = require("./const");

var set = function set(token) {
  return {
    token: token,
    type: _const.TYPES.SET
  };
};

exports.set = set;
var actions = {
  set: set
};
var _default = actions;
exports.default = _default;