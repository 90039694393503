import { ResponseCodes, actions } from '@naadi/framework';
import { toastError, toastMessage } from '../packing/packingHelper';
import { cloneDeep } from 'lodash';
import { archiveCurrentOrder } from '../../store/packing/orderarchive/actions';
import { addPrinters } from '../../store/printers/connectedprinters/reducer';
import { getState } from '../../store';
import PDFMerger from 'pdf-merger-js/browser';
import { getAttachmentSignedUrlService } from '../../services/upload';
import FileSaver from 'file-saver';

export const mergePdfFiles = async pdfFiles => {
  if (!pdfFiles || pdfFiles.length === 0) {
    return null;
  }
  const merger = new PDFMerger();
  for (let i = 0; i < pdfFiles.length; i++) {
    await merger.add(pdfFiles[`${i}`]);
  }
  await merger.setMetadata({
    producer: 'pdf-merger-js based script',
  });
  const mergedPdf = await merger.saveAsBlob();
  return URL.createObjectURL(mergedPdf);
};

export const getResponseErrorMessage = (response, defaultErr) => {
  if (!response) {
    return null;
  }
  if (response.status === ResponseCodes.SUCCESS) {
    return null;
  }
  if (!response.err) {
    return defaultErr;
  }

  if (response.err.err) {
    return response.err.err;
  }
  return response.err;
};

export const toastResponseError = (response, defaultErr) => {
  const errMessage = getResponseErrorMessage(response, defaultErr);
  if (errMessage !== null) {
    toastError(errMessage);
  }
};

export const selectOrg = (org_id, branch_id, dispatch) => {
  const user = getState().user;
  if (!user) return;
  if (!user.org || user.org.uuid !== org_id) {
    const orgs = user.orgs || [];
    const org = orgs.filter(val => val.uuid === org_id);
    if (org && org.length > 0) {
      const _user = cloneDeep(user);
      _user.org = org[0];
      _user.branch = null;
      _user.branches = [];
      dispatch(archiveCurrentOrder());
      dispatch(addPrinters([]));
      debugger;
      dispatch(actions.user.set(_user));
    }
  }
};

export const getOverlayStyle = () => {
  const { screens } = getState().screenoverlay;
  const overlayStyle = {
    backgroundColor: '#fff',
    minHeight: '100%',
  };
  const screenStyle = {};
  const showOverlay = screens && screens.length > 0;
  if (showOverlay) {
    screenStyle['display'] = 'none';
    screenStyle['height'] = '0px';
    screenStyle['width'] = '0px';
    overlayStyle['display'] = 'block';
  } else {
    overlayStyle['display'] = 'none';
    overlayStyle['minHeight'] = '0px';
    overlayStyle['minWidth'] = '0px';
    screenStyle['display'] = 'block';
  }
  return {
    screenStyle: screenStyle,
    overlayStyle: overlayStyle,
  };
};

export const downloadAttachment = async (attachment_id, fileName) => {
  const attachmentResp = await getAttachmentSignedUrlService(attachment_id);
  if (attachmentResp.status !== ResponseCodes.SUCCESS) {
    toastMessage({
      type: 'error',
      message: 'Unable to Fetch the Attachment From Server',
      autoClose: 5000,
    });
    return;
  }
  const attachment = attachmentResp.payload;
  //console.log(attachment.signed_url);
  FileSaver.saveAs(
    attachment.signed_url,
    fileName ? fileName : attachment.file_name,
  );
  //console.log(attachmentSignedResponse);
};
