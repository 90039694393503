import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { FaAngleDown } from 'react-icons/fa';
import BottomDrawer from '../../components/template/bottomdrawer';
import BottomDrawerStations from './bottom-drawer-stations';
import CustomHookModal from './editquery-modal';
import GreyClose from '../../assets/icons/grey-close.svg';
import Select from '../../components/bot/select-upload';
import { getLinkWorkstation } from '../../services/operation';
import { cloneDeep } from 'lodash';
import SearchIcon from '../../assets/icons/searchIcon.svg';
const WorkstationsFilter = ({
  labelText,
  selectWorkstation,
  setSelectWorkstation,
  topDrillList,
  setTopDrillList,
}) => {
  const workstationFilter = useCallback(term => {
    return new Promise((resolve, reject) => {
      const workstationBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (term) workstationBody.req_payload.term = term.toUpperCase();
      getLinkWorkstation(workstationBody).then(val => {
        if (val.status === 200) {
          resolve(val.payload);
        } else {
          reject([]);
        }
      }, reject);
    });
  }, []);
  return (
    <div style={{ width: '320px' }}>
      <label className='lbl-input lbl-padding' style={{ marginLeft: '16px' }}>
        STATION
      </label>
      <Select
        selVal={
          selectWorkstation ? selectWorkstation.instance_name : 'Select Station'
        }
        label={labelText}
        getOptions={workstationFilter}
        labelField='instance_name'
        valueField='uuid'
        onSelect={sel => {
          setSelectWorkstation(sel);
        }}
        search
      />
    </div>
  );
};

const BotInputCNC = ({
  bottype,
  botnameFocus,
  setBotNameFocus,
  botnameError,
  setBotNameError,
  selectWorkstation,
  setSelectWorkstation,
  form,
  setForm,
  onChange,
  errors,
  isMobile,
  isTab,
  topDrillList,
  setTopDrillList,
  workstationDrawer,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const Class_RECT = 'rectangle-input';
  const Class_RECT_Highlight = 'rectangle-input highlight';
  const Class_RECT_Error = 'rectangle-input error';
  const [showHookModal, setShowHookModal] = useState(false);
  const [hookValue, setHookValue] = useState('');
  const [workstationOpen, setWorkstationOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const closeCallback = setShowHookModal => {
    setShowHookModal(false);
  };

  const workstationData = () => {
    return (
      <div>
        <div style={{ fontSize: '20px', textAlign: 'center' }}>
          LINK A STATION
        </div>
        <div style={{ height: '30px' }}></div>
        <div>
          <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
          <input
            type='text'
            id='search_field'
            className='searchModal'
            style={{
              border: '0px !important',
              color: 'rgba(50, 50, 50, 0.4)',
              fontStyle: 'italic',
            }}
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder='Type here to search'
          />
        </div>
        {workstationDrawer &&
          workstationDrawer.length > 0 &&
          workstationDrawer.map(row => (
            <div key={row.uuid}>
              <div
                key={row.uuid}
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  setSelectWorkstation(row);
                  setWorkstationOpen(false);
                }}
              >
                {row.instance_name}
              </div>
              <div
                className='operation-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
      </div>
    );
  };
  return (
    <div
      className='bg-white'
      style={{
        alignContent: 'left',
        marginLeft: '40px',
      }}
    >
      {isMobile && showHookModal ? (
        <BottomDrawer
          entity={''}
          drawerTitle=''
          handleClose={() => closeCallback(setShowHookModal)}
          popUp={showHookModal && isMobile}
          drawerBody={
            <CustomHookModal
              showModal={showHookModal}
              closeCallback={() => closeCallback(setShowHookModal)}
              isMobile={isMobile}
              isTab={isTab}
              hookValue={hookValue}
              setHookValue={setHookValue}
              form={form}
              bottype={bottype}
            />
          }
        />
      ) : (
        showHookModal && (
          <CustomHookModal
            showModal={showHookModal}
            closeCallback={() => closeCallback(setShowHookModal)}
            isMobile={isMobile}
            isTab={isTab}
            hookValue={hookValue}
            setHookValue={setHookValue}
            form={form}
            bottype={bottype}
          />
        )
      )}

      <BottomDrawerStations
        isMobile={isMobile}
        isTab={isTab}
        itemOpen={workstationOpen}
        setItemOpen={setWorkstationOpen}
        itemData={workstationData()}
      />

      <div style={{ height: '27px' }}></div>
      <Form style={{ padding: '0px' }}>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='row m-0 p-0'>
            <div className='col-md-10 col-sm-10 col-10 m-0 p-0'>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                BOT TYPE
              </label>

              <Form.Control
                id='bottype'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='bottype'
                placeholder='Enter Value Here'
                value={bottype}
                disabled
                isInvalid={!!errors.bottype}
                onChange={onChange}
                style={{ marginLeft: '16px' }}
              />
            </div>
          </div>
        </div>
        <div
          className={
            botnameError
              ? Class_RECT_Error
              : botnameFocus
              ? Class_RECT_Highlight
              : Class_RECT
          }
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='row m-0 p-0'>
            <div className='col-md-10 col-sm-10 col-10 m-0 p-0'>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                BOT NAME
              </label>

              <Form.Control
                id='botname'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='botname'
                placeholder='Enter Value Here'
                value={form.botname}
                isInvalid={!!errors.botname}
                onFocus={() => {
                  setBotNameFocus(botnameFocus => true);
                  setBotNameError(botnameError => false);
                  errors.botname = '';
                }}
                onBlur={() => {
                  setBotNameFocus(botnameFocus => false);
                }}
                onChange={onChange}
                style={{ marginLeft: '16px' }}
              />
              {errors.botname && (
                <Form.Control.Feedback type='invalid'>
                  <div style={{ height: '8px' }}></div>
                  {errors.botname}
                </Form.Control.Feedback>
              )}
            </div>
            <div
              className='col-md-2 col-sm-2 col-2 password-image'
              style={{ paddingTop: '15px', textAlignLast: 'right' }}
            >
              <img src={GreyClose} alt=''></img>
            </div>
          </div>
        </div>

        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          {!(isMobile || isTab) && (
            <WorkstationsFilter
              labelText={'Add+'}
              selectWorkstation={selectWorkstation}
              setSelectWorkstation={setSelectWorkstation}
              topDrillList={topDrillList}
              setTopDrillList={setTopDrillList}
            />
          )}
          {(isMobile || isTab) && (
            <div>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                STATION
              </label>
              <div onClick={() => setWorkstationOpen(true)}>
                {' '}
                <div
                  className='row'
                  style={{ width: '320px', paddingLeft: '15px' }}
                >
                  <div className='col-10'>
                    {selectWorkstation
                      ? selectWorkstation.instance_name
                      : 'Select Station'}
                  </div>
                  <div className='col-2 m-0 p-0' style={{ textAlign: 'right' }}>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '30px' }}>
              Enable Trace
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='enabletrace'
              name='enabletrace'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.enabletrace = !newVal.enabletrace;
                  return newVal;
                });
              }}
              value={true}
              checked={form.enabletrace === true}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '30px' }}>
              Enable Curl
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='enablecurl'
              name='enablecurl'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.enablecurl = !newVal.enablecurl;
                  return newVal;
                });
              }}
              value={true}
              checked={form.enablecurl === true}
            />
          </div>
        </div>
        <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
          Configuration
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Machine Type
            </label>

            <Form.Control
              id='machine_type'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='machine_type'
              placeholder='Enter Value Here'
              value={form.machine_type}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Machine Name
            </label>

            <Form.Control
              id='machine_name'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='machine_name'
              placeholder='Enter Value Here'
              value={form.machine_name}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>

        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Serial Port In
            </label>

            <Form.Control
              id='serial_port_in'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='serial_port_in'
              placeholder='Enter Value Here'
              value={form.serial_port_in}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Serial Port Out
            </label>

            <Form.Control
              id='serial_port_out'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='serial_port_out'
              placeholder='Enter Value Here'
              value={form.serial_port_out}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Baudrate
            </label>

            <Form.Control
              id='baudrate'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='baudrate'
              placeholder='Enter Value Here'
              value={!isNaN(form.baudrate) ? form.baudrate : ''}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Read Timeout MS
            </label>

            <Form.Control
              id='read_timeout_ms'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='read_timeout_ms'
              placeholder='Enter Value Here'
              value={!isNaN(form.read_timeout_ms) ? form.read_timeout_ms : ''}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Queue Folder
            </label>

            <Form.Control
              id='queue_folder'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='queue_folder'
              placeholder='Enter Value Here'
              value={form.queue_folder}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Program Suffix Length
            </label>

            <Form.Control
              id='program_suffix_length'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='program_suffix_length'
              placeholder='Enter Value Here'
              value={form.program_suffix_length}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Barcode Split Token
            </label>

            <Form.Control
              id='barcodr_split_token'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='barcodr_split_token'
              placeholder='Enter Value Here'
              value={form.barcodr_split_token}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Program Path
            </label>

            <Form.Control
              id='program_path'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='program_path'
              placeholder='Enter Value Here'
              value={form.program_path}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Program Target Path
            </label>

            <Form.Control
              id='program_target_path'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='program_target_path'
              placeholder='Enter Value Here'
              value={form.program_target_path}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Program File Extension
            </label>

            <Form.Control
              id='program_file_ext'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='program_file_ext'
              placeholder='Enter Value Here'
              value={form.program_file_ext}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default BotInputCNC;
