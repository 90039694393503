import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import IconPrint from '../../../components/packing/common/icon-print';
import { cloneDeep, isEqual } from 'lodash';
import { addPrinter } from '../../../store/printers/connectedprinters/reducer';
import {
  getHumanizedDuration,
  isPrinterConnected,
  refreshConnectedPrinters,
} from '../../../helpers/printers/printerHelper';
import { MinusCircleIcon, PlusCircleIcon } from '../../../assets/icons';
import {
  toastError,
  toastSuccess,
} from '../../../helpers/packing/packingHelper';
import { actions } from '@naadi/framework';
import {
  mergePdfFiles,
  toastResponseError,
} from '../../../helpers/common/helper';
import FileSaver from 'file-saver';
import { printItemLabels } from '../../../services/items';
const SELECT_PRINTER = 'Select a Printer';

export const PrinterSelection = ({ printers, module }) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const onDropdownToggle = status => {
    setDropdownOpen(status);
  };
  const getSelectedPrinter = useCallback(() => {
    const _printer = printers.filter(val => val.selected === true);
    return _printer && _printer.length > 0 ? _printer[0] : null;
  }, [printers]);
  const [selectedPrinter, setSelectedPrinter] = useState(getSelectedPrinter());
  useEffect(() => {
    return refreshConnectedPrinters(dispatch, 30000);
  }, [dispatch]);
  useEffect(() => {
    setSelectedPrinter(getSelectedPrinter());
  }, [getSelectedPrinter]);

  /* eslint-disable sonarjs/no-identical-functions */
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display ${
          isDropdownOpen ? 'active' : ''
        }`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );

  const renderPrinterCard = useCallback(printer => {
    const lastConnected = getHumanizedDuration(printer);
    const lastConnectedMessage =
      printer.printer_id === 'OFFLINE_PDF_PRINTER'
        ? 'Pdf Download'
        : lastConnected
        ? 'Connected ' + lastConnected + ' ago'
        : 'Printer Not Connected';
    const connected =
      printer.printer_id === 'OFFLINE_PDF_PRINTER' ||
      isPrinterConnected(printer);
    const printerStatusTextClass = connected
      ? 'printer-status-text success'
      : 'printer-status-text failure';
    const printerStatusClass = connected
      ? 'printer-connected printer-status'
      : 'printer-status';
    return (
      <div
        className={`printer-select-wrp ${printer.selected ? 'selected' : ''}`}
      >
        <div className='dropdown-printer-name'>{printer.name}</div>
        <div className='printer-last-connected'>
          <span>{lastConnectedMessage}</span>
          <span> . </span>
          <span className={printerStatusTextClass}>
            {connected ? 'ONLINE' : 'OFFLINE'}
          </span>
        </div>
        <div className={printerStatusClass}>
          <div></div>
        </div>
      </div>
    );
  }, []);
  useEffect(() => {
    if (selectedPrinter && selectedPrinter.printer_id) {
      const printer = cloneDeep(selectedPrinter);
      printer.selected = true;
      dispatch(addPrinter(printer));
    }
  }, [dispatch, selectedPrinter]);
  /* eslint-enable sonarjs/no-identical-functions */
  const onDropdownSelect = useCallback(
    printer => {
      if (!printer) return;
      setSelectedPrinter(printer);
    },
    [setSelectedPrinter],
  );
  return (
    <div className='d-flex' style={{ zIndex: 1 }}>
      <div className='d-flex'>
        <Dropdown
          className='page-size-selector'
          onToggle={onDropdownToggle}
          drop={'down'}
        >
          <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
            <div className={`d-flex btn-label text-primary print-text`}>
              <div className={'text-nowrap select-printer-text'}>
                {module === 'Track-N-trace-group-select'
                  ? ''
                  : selectedPrinter
                  ? selectedPrinter.name
                  : SELECT_PRINTER}
              </div>
              <div className='print-icon ms-2'>
                <IconPrint
                  onClick={() => {}}
                  fill={
                    isPrinterConnected(selectedPrinter) ? '#007BFF' : '#CCCCCC'
                  }
                />
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className='dropdown'
            align={{ sm: 'right' }}
            flip={false}
            style={{ height: '200px' }}
          >
            {printers.map(printer => (
              <Dropdown.Item
                onClick={() => onDropdownSelect(printer)}
                disabled={false}
                key={printer.printer_id}
                eventKey={printer.name}
                className={`printer-select-box order-label-print-filter-selector ${
                  printer.selected === true ? 'selected' : ''
                }`}
              >
                {renderPrinterCard(printer)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export const offlinePrinter = {
  printer_id: 'OFFLINE_PDF_PRINTER',
  name: 'PDF Download',
};

export const handlePrint = async (
  availablePrinters,
  dispatch,
  printer,
  qty,
) => {
  const selectedPrinter = availablePrinters.filter(
    val => val.selected === true,
  );
  const _printers =
    printer && printer.printer_id
      ? [printer]
      : selectedPrinter.length > 0
      ? selectedPrinter
      : availablePrinters.filter(
          val => val.printer_id === 'OFFLINE_PDF_PRINTER',
        );
  if (!_printers || _printers.length === 0) {
    toastError(SELECT_PRINTER);
    return {
      error: SELECT_PRINTER,
      success: false,
    };
  }
  const _printer = _printers[0];
  const reqdata = {
    req_payload: { count: qty, printer_id: _printer.printer_id },
  };
  try {
    dispatch(actions.busy.add('PRINT_ITEM_LABELS'));
    const printLabelResp = await printItemLabels(reqdata);
    if (printLabelResp.status !== 200) {
      toastResponseError(printLabelResp, 'Unable to Print Label');
      return {
        error: 'Unable to Print Label',
        success: false,
      };
    }
    const images = printLabelResp.payload;
    if (_printer.printer_id === 'OFFLINE_PDF_PRINTER' && images.length > 0) {
      let imageUrl = images[0];
      if (images.length > 1) {
        imageUrl = await mergePdfFiles(images);
      }
      FileSaver.saveAs(imageUrl, 'Item_Labels.pdf');
    }
    toastSuccess(images.length + ' labels printed');
    return {
      error: images.length + ' labels printed',
      success: true,
    };
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(actions.busy.remove('PRINT_ITEM_LABELS'));
  }
};
const PrintLabelModel = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const { printers } = useSelector(state => state.connectedPrinters);
  const [availablePrinters, setAvailablePrinters] = useState([]);
  useEffect(() => {
    const _offlinePrinter = [cloneDeep(offlinePrinter)];
    const _printers = printers.filter(
      val => val.printer_id !== 'OFFLINE_PDF_PRINTER',
    );
    _offlinePrinter.selected =
      printers.filter(
        val =>
          val.printer_id === 'OFFLINE_PDF_PRINTER' && val.selected === true,
      ).length > 0;
    const _availablePrinters = [..._offlinePrinter, ..._printers];
    if (!isEqual(availablePrinters, _availablePrinters)) {
      setAvailablePrinters(_availablePrinters);
    }
  }, [printers, setAvailablePrinters, availablePrinters]);

  const handlePrintCb = useCallback(
    async printer => {
      return handlePrint(availablePrinters, dispatch, printer, qty);
    },
    [availablePrinters, dispatch, qty],
  );
  const onSubmit = e => {
    e.preventDefault();
    setQty(parseInt(qty));
  };
  const handleChange = e => {
    let value = e.target.value;
    if (value === '') {
      setQty('');
    }
    value = parseInt(e.target.value);
    if (!isNaN(value)) {
      setQty(value);
    }
  };
  return (
    <Modal centered={true} show={showModal} onHide={() => setShowModal(false)}>
      <div className='p-3'>
        <Modal.Header className='border-0 p-4 d-flex  justify-content-between'>
          <div className='fs-24'>Print Item Labels</div>
          <div className='text-primary'>
            <PrinterSelection printers={availablePrinters} />
          </div>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <div className='fs-23'>
            <div className='d-flex justify-content-center mt-3 align-items-center gap-4'>
              <div>Qty</div>
              <div onClick={() => qty > 1 && setQty(qty - 1)}>
                <MinusCircleIcon />
              </div>

              <div
                style={{ width: '50px' }}
                className='text-primary text-center'
              >
                <form onSubmit={onSubmit}>
                  <input
                    className='form-control text-center'
                    type='text'
                    min={0}
                    name='qty'
                    id='qty'
                    value={qty}
                    onChange={handleChange}
                  />
                </form>
              </div>
              <div onClick={() => setQty(qty + 1)}>
                <PlusCircleIcon />
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className='d-flex justify-content-end align-items-center gap-3 m-2'>
          <div
            className='btn  btn-outline-primary '
            onClick={() => {
              handlePrintCb({
                printer_id: 'OFFLINE_PDF_PRINTER',
              });
            }}
          >
            Download
          </div>
          <div
            onClick={() => setShowModal(false)}
            className='btn btn-outline-primary '
          >
            Close
          </div>
          <div onClick={() => handlePrintCb()} className='btn btn-primary '>
            Print
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PrintLabelModel;
